import * as React from 'react';
// import PropTypes from 'prop-types';

import { Body, Title } from '@web/components/DialerActivity/components/TextStyles';

function PhoneBankPaused() {
  return (
    <>
      <Title>Your phone bank admin has paused the phone bank.</Title>
      <Body style={{ marginTop: 16 }}>
        Please contact them to find out when it will resume again.
      </Body>
    </>
  );
}

PhoneBankPaused.propTypes = {};

PhoneBankPaused.defaultProps = {};

export default PhoneBankPaused;
