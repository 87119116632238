import * as React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import sendError, { addBreadcrumb } from '@web/utils/sendError';

import { message } from 'antd';
import StatusBarButton from '../StatusBarButton';

import { connectingToVoter, VOLUNTEER_HANG_UP } from '@web/reducers/dialerReducer/dialerMachine';
import { machineTransition } from '@web/reducers/dialerReducer';
import useHangUp from '@web/components/DialerActivity/pages/InCall/useHangUp';
import useIsMounted from '@web/hooks/useIsMounted';
import { useDispatch, useSelector } from 'react-redux';

function HangUpButton({ bgColor }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { callData, currentState, voter_call_id } = useSelector(state => state.dialer);
  const { checkConferenceIdle, handleHangUp } = useHangUp();
  const isMounted = useIsMounted();

  const callIdRef = React.useRef(voter_call_id);
  const currentStateRef = React.useRef(currentState);

  // Necessary to prevent stale values
  React.useEffect(() => {
    callIdRef.current = voter_call_id;
    currentStateRef.current = currentState;
  }, [currentState, voter_call_id]);

  function hangUp() {
    if (loading) {
      return;
    }
    // This is the volunteer call id
    const callId = callData?.call_id;
    // MTS - I need to keep track of the value at the time the function was called not
    // the current value, which could be different.
    const voterCallId = callIdRef?.current;
    setLoading(true);
    const url = `/api/v2/volunteer_voice_calls/${callId}/voter_voice_call`;
    axios
      .delete(url)
      .then(res => {
        handleHangUp(VOLUNTEER_HANG_UP, voterCallId);
      })
      .catch(async err => {
        // See https://github.com/outvote/web-app/issues/7561 for details
        if (err?.response?.status === 422 && currentStateRef?.current === connectingToVoter) {
          const conferenceIdle = await checkConferenceIdle();
          // The user is in an out of sync state with the backend and we need to transition them
          // back to idle
          if (conferenceIdle) {
            addBreadcrumb({
              category: 'dialer',
              message:
                'Dialer - Transitioning user back to idle from 422 hang up in connectingToVoter',
            });
            return dispatch(machineTransition(VOLUNTEER_HANG_UP));
          }
        }

        sendError('Dialer - Unable to hang up call', {
          request: err?.request,
          response: err?.response,
        });

        // MTS - Only show the error message if the component is still mounted
        if (isMounted()) {
          message.error('An error occurred while hanging up the call. Please try again.');
        }
      })
      .finally(() => {
        if (isMounted()) {
          setLoading(false);
        }
      });
  }

  return (
    <StatusBarButton bgColor={bgColor} disabled={loading} loading={loading} onClick={hangUp}>
      Hang Up
    </StatusBarButton>
  );
}

HangUpButton.propTypes = {
  bgColor: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

HangUpButton.defaultProps = {
  bgColor: 'yellow',
  loading: false,
};

export default HangUpButton;
