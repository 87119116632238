import { List } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Note from '../Note';
import Loading from '../common/Loading';
import IdVotersListItem from './IdVotersListItem';

const SectionTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  color: ${({ theme }) => theme.fonts.black};
  font-size: 22px;
  margin-bottom: 10px;
`;

const ListContainer = styled.div`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 5px;
`;

const ItemContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  padding-left: 15px;
  padding-right: 15px;
`;

// A list of possible results given certain IdVoters search parameters
// These results are passed in from the previous page (or you could pass in the form parameters and load them here either way). People may want to sort and filter them in the future so you may need to re-request anyway.
const IdVotersList = ({
  loading,
  show,
  voters,
  isFriend,
  userActivityId,
  activityId,
  campaignId,
  contactId = 0,
  onListItemClick,
}) => {
  const { t } = useTranslation();
  if (!show) return null;

  const hasVoters = voters && voters.length > 0;

  return (
    <div className="voter-search-list p-3">
      <SectionTitle>{t('idvoters.results.title')}</SectionTitle>
      <ListContainer>
        <List>
          {hasVoters &&
            voters.map(voter => (
              <ItemContainer key={voter.voterbase_id}>
                <IdVotersListItem
                  isFriend={isFriend}
                  userActivityId={userActivityId}
                  activityId={activityId}
                  campaignId={campaignId}
                  voter={voter}
                  contactId={contactId}
                  onListItemClick={onListItemClick}
                />
              </ItemContainer>
            ))}
          {loading && <Loading centered />}
        </List>
      </ListContainer>

      {!loading && !hasVoters && (
        <Note title={t('idvoters.results.no_results_title')}>
          {t('idvoters.results.no_results_message')}
        </Note>
      )}
    </div>
  );
};

IdVotersList.propTypes = {
  activityId: PropTypes.number,
  campaignId: PropTypes.number,
  contactId: PropTypes.number,
  isFriend: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onListItemClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  userActivityId: PropTypes.number,
  voters: PropTypes.arrayOf(PropTypes.shape({})),
};

IdVotersList.defaultProps = {
  activityId: null,
  campaignId: null,
  contactId: null,
  onListItemClick: null,
  userActivityId: null,
  voters: null,
};

export default IdVotersList;
