import { Checkbox } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { convertLinks } from '@web/utils/string';
import { Link } from 'react-router-dom';
import theme from '@web/styles/theme';
import pxToRem from '@web/utils/pxToRem';

const OptInSection = styled.div`
  flex-basis: 100% !important;
  margin: 2rem 0;
  padding: 0 1rem;
`;

// Using theme color is difficult because antd checkbox uses pseudo elements and things get complicated
// it's best to keep this simple if possible
const StyledCheckbox = styled(Checkbox)`
  &&& {
    .ant-checkbox-inner {
      transform: scale(1.2);
      background-color: ${({ $bgColor }) =>
        $bgColor ?? theme.formDefaultColors.fieldBackgroundColor};
      border-color: ${({ $outlineColor }) =>
        $outlineColor ?? theme.formDefaultColors.fieldOutlineColor};
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${({ $outlineColor }) =>
        $outlineColor ?? theme.formDefaultColors.fieldOutlineColor};
      border-color: ${({ $outlineColor }) =>
        $outlineColor ?? theme.formDefaultColors.fieldOutlineColor};
      ::after {
        border-color: ${({ $bgColor }) => $bgColor ?? theme.formDefaultColors.fieldBackgroundColor};
      }
    }

    &:hover {
      .ant-checkbox-checked:after {
        border: none;
      }
    }

    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus {
      border-color: ${({ $outlineColor }) =>
        $outlineColor ?? theme.formDefaultColors.fieldOutlineColor} !important;
    }
  }
`;

const PrivacyPolicyLink = styled(Link)`
  &&& {
    margin-top: 16px;
    display: block;

    color: ${({ $color }) => $color ?? theme.formDefaultColors.fontColor};
    font-size: ${pxToRem(11)};
    text-decoration: underline;

    &:hover {
      color: ${theme.colors.coral};
    }
  }
`;

const OptInCheckboxField = ({ activity, values, handleChange }) => {
  const { t } = useTranslation();
  const optInCheckboxTermsText = useMemo(
    () =>
      convertLinks(
        activity?.terms || t('survey.opt-in-default-terms', { campaign: activity.campaign.name }),
      ),
    [activity.campaign.name, activity?.terms, t],
  );

  const termsAndPolicyLink = useMemo(
    () => activity?.terms_link || 'https://www.impactive.io/client-terms-and-privacy-policy',
    [activity?.terms_link],
  );

  const { form_field_outline_color, form_field_background_color, main_font_color } =
    activity.settings ?? {};

  return (
    <OptInSection>
      <StyledCheckbox
        name="opt_in"
        checked={values['opt_in']}
        onChange={handleChange}
        $bgColor={form_field_background_color}
        $outlineColor={form_field_outline_color}
      >
        <span
          style={{ color: main_font_color ?? theme.formDefaultColors.fontColor }}
          dangerouslySetInnerHTML={{ __html: optInCheckboxTermsText }}
        />
      </StyledCheckbox>
      <PrivacyPolicyLink as="a" $color={main_font_color} href={termsAndPolicyLink} target="_blank">
        Terms & Privacy Policy
      </PrivacyPolicyLink>
    </OptInSection>
  );
};

OptInCheckboxField.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({ name: PropTypes.string }),
    settings: PropTypes.shape({
      form_field_background_color: PropTypes.string,
      form_field_font_color: PropTypes.string,
      form_field_outline_color: PropTypes.string,
      main_font_color: PropTypes.string,
      secondary_color: PropTypes.string,
    }),
    terms: PropTypes.string,
    terms_link: PropTypes.string,
  }),
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default OptInCheckboxField;
