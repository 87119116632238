import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PhoneFilled } from '@ant-design/icons';

const StyledTextWithIcon = styled.div`
  align-items: center;
  display: flex;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.black};
  font-size: 14px;
  margin-left: 12px;
  text-align: center;
  text-transform: uppercase;
`;

function TextWithIcon({ children }) {
  const iconProps = { fontSize: 10, marginTop: 1, transform: 'rotate(90deg)' };
  return (
    <StyledTextWithIcon>
      <PhoneFilled style={iconProps} /> <Title>{children}</Title>
    </StyledTextWithIcon>
  );
}

TextWithIcon.propTypes = {
  children: PropTypes.any,
};

TextWithIcon.defaultProps = {
  children: null,
};

export default TextWithIcon;
