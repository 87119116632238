import * as React from 'react';
import axios from 'axios';

import { message } from 'antd';

import { addBreadcrumb } from '@web/utils/sendError';
import {
  connectingToVoter,
  OUT_OF_SYNC_VOTER_HANG_UP,
} from '@web/reducers/dialerReducer/dialerMachine';
import { machineTransition } from '@web/reducers/dialerReducer';
import sendError from '@web/utils/sendError';
import { useDispatch, useSelector } from 'react-redux';

// MTS - The purpose of this hook is to add a layer of protection
// around the hang up transitions during periods of high latency in
// order to prevent hanging up on a current voter via a previous
// voter's hang up event. Further explaination can be found here:
// https://github.com/outvote/web-app/issues/7327

function useHangUp() {
  const dispatch = useDispatch();
  const { activityId, currentState, voter_call_id } = useSelector(state => state.dialer);
  const currentStateRef = React.useRef(null);

  // MTS - This is absolutely required. For some reason I am receiving stale values
  // for currentState. I've tried modifying the function to use `React.useCallback`
  // as well as passing the `currentState` from the component that called the function.
  React.useEffect(() => {
    currentStateRef.current = currentState;
  }, [currentState]);

  async function handleHangUp(transition, voterCallId) {
    if (voter_call_id && voter_call_id !== voterCallId) {
      sendError('Dialer - Invalid hang up prevented', {
        currentState: currentStateRef.current,
        voter_call_id,
        voterCallId,
      });
      return null;
    }

    // it is safe to let this pass through
    return dispatch(machineTransition(transition));
  }

  async function handleVoterHangUp(transition, voterCallId) {
    // For details on this check please see: https://github.com/outvote/web-app/issues/7561
    if (currentStateRef?.current === connectingToVoter) {
      const conferenceIdle = await checkConferenceIdle();
      if (conferenceIdle) {
        addBreadcrumb({
          category: 'Dialer',
          message:
            'Attempting to recover from voter_left while in connectingToVoter -- missed voter_joined event',
        });
        // Further details can be found in this comment:
        // https://github.com/outvote/web-app/issues/7561#issuecomment-1095360091
        handleHangUp(OUT_OF_SYNC_VOTER_HANG_UP, voterCallId);
        return message.error(
          'We had a connection issue with the server. Please try queuing another call.',
        );
      } else {
        sendError(
          'Dialer | The conference is not in an idle state after missing the voter_joined event.',
        );
        return handleHangUp(OUT_OF_SYNC_VOTER_HANG_UP, voterCallId);
      }
    } else {
      // Let the hangup go through
      return handleHangUp(transition, voterCallId);
    }
  }

  async function checkConferenceIdle() {
    try {
      const url = `/api/v2/activities/${activityId}/volunteer_conference`;
      const result = await axios(url);
      const conferenceState = result?.data?.state;
      addBreadcrumb({
        category: 'Dialer',
        message: `Current conference state: ${conferenceState}`,
      });
      return conferenceState === 'idle';
    } catch (err) {
      return false;
    }
  }

  return { checkConferenceIdle, handleHangUp, handleVoterHangUp };
}

export default useHangUp;
