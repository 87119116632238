import * as React from 'react';
import * as yup from 'yup';
import dayjs from 'dayjs';

import {
  dateFormat,
  phoneRegExp,
} from '@web/components/VoterRegistration/components/common/utils/schema';
import { useTranslation } from 'react-i18next';

export const ContactFormSchema = t =>
  yup.object().shape({
    address: yup.string().required(t('idvoters.validation.address')),
    dateOfBirth: yup
      .date()
      .typeError(t('idvoters.validation.valid_dob'))
      .required(t('idvoters.validation.dob'))
      .min(dayjs().subtract(120, 'year'), t('idvoters.validation.valid_dob'))
      .max(dayjs().subtract(15, 'year'), t('idvoters.validation.max_date', { age: 16 })),
    email: yup
      .string()
      .email(t('check_registration.invalid_email'))
      .required(t('idvoters.validation.emailRequired')),
    firstName: yup.string().required(t('idvoters.validation.firstname')),
    lastName: yup.string().required(t('idvoters.validation.lastname')),
    phone: yup
      .string()
      .matches(phoneRegExp, t('check_registration.invalid_phone'))
      .required(t('idvoters.validation.phoneRequired')),
  });

export const ReferralFormSchema = t =>
  yup.object().shape({
    address: yup.string().required(t('idvoters.validation.address')),
    dateOfBirth: yup
      .date()
      .typeError(t('idvoters.validation.valid_dob'))
      .required(t('idvoters.validation.dob'))
      .min(dayjs().subtract(120, 'year'), t('idvoters.validation.valid_dob'))
      .max(dayjs(), t('idvoters.validation.no_future_date')),
    email: yup
      .string()
      .email(t('check_registration.invalid_email'))
      .required(t('idvoters.validation.emailRequired')),
    firstName: yup.string().required(t('idvoters.validation.firstname')),
    lastName: yup.string().required(t('idvoters.validation.lastname')),
    phone: yup
      .string()
      .matches(phoneRegExp, t('check_registration.invalid_phone'))
      .required(t('idvoters.validation.phoneRequired')),
  });

export const updateAddressFormFields = t =>
  yup.object().shape({
    address: yup.string().required(t('idvoters.validation.address')),
  });

export const normalFlowFields = t =>
  yup.object().shape({
    showOnLeaderboard: yup.bool(),
    smsOptIn: yup.bool(),
    terms: yup
      .bool()
      .required(t('idvoters.validation.terms'))
      .oneOf([true], t('idvoters.validation.terms')),
  });

export const headcountFields = yup.object().shape({
  emailOptIn: yup.bool(),
  smsOptIn: yup.bool(),
});

// Convert "YYYY-MM-DD" to "MM/DD/YYYY"
function getDateValue(date) {
  if (date) {
    return dayjs(date).format('MM/DD/YYYY');
  }
  return '';
}

function useContactForm(initialContact = {}) {
  const [contact, setContact] = React.useState(initialContact);
  const { t } = useTranslation();

  const contactFormInitialValues = {
    address: contact?.address || '',
    city: contact?.city || '',
    countyName: contact?.county_name || '',
    dateOfBirth: getDateValue(contact?.date_of_birth),
    email: contact?.email || '',
    firstName: contact?.first_name || '',
    lastName: contact?.last_name || '',
    phone: contact?.phone || '',
    stateAbbrev: contact?.state_abbrev || '',
    unitNumber: contact?.unit_number || '',
    zipCode: contact?.zip_code || '',
  };

  const updateAddressFormInitialValues = {
    address: contact?.address || '',
    city: contact?.city || '',
    countyName: contact?.county_name || '',
    stateAbbrev: contact?.state_abbrev || '',
    stateId: contact?.stateId || null,
    unitNumber: contact?.unit_number || '',
    zipCode: contact?.zip_code || '',
  };

  const headcountValues = {
    emailOptIn: true,
    smsOptIn: false,
    termsAndConditions: true,
  };

  const normalFlowValues = {
    showOnLeaderboard: true,
    smsOptIn: true,
    terms: true,
  };

  return {
    contact,
    dateFormat,
    initialValues: {
      contactForm: { ...contactFormInitialValues, ...normalFlowValues },
      contactFormHeadcount: { ...contactFormInitialValues, ...headcountValues },
      updateAddressForm: updateAddressFormInitialValues,
    },
    schemas: {
      contactForm: ContactFormSchema(t).concat(normalFlowFields(t)),
      contactFormHeadcount: ContactFormSchema(t).concat(headcountFields),
      referralFormHeadcount: ReferralFormSchema(t),
      updateAddressForm: updateAddressFormFields(t),
    },
    setContact,
  };
}

export default useContactForm;
