import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { message } from 'antd';

import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import Graph from '../icons/Graph';
import QRCodeShare from '../QRCodeShare';
import useIsMobile from '@web/components/VoterRegistration/hooks/useIsMobile';
import Button from '@web/components/VoterRegistration/newComponents/Button';
import { fanOut } from '@web/services/api';

const StyledShare = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  ${smallBreakpoint} {
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.trueBlack};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
`;

const EntriesContent = styled(Container)`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px 24px;
  align-items: center;
  gap: 32px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid rgba(57, 62, 91, 0.1);
  box-shadow: 7px 6px 20px 0px rgba(0, 0, 0, 0.05);

  ${smallBreakpoint} {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
  }
`;

const StyledGraph = styled(Graph)`
  min-width: 156px;
`;

const TrackContainer = styled(Container)`
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;

  ${smallBreakpoint} {
    flex-direction: column;
  }
`;

const TrackText = styled(Text)`
  font-weight: 600;
`;

const StatsText = styled(Text)``;

const StyledButton = styled(Button)`
  margin-top: 10px;
  text-decoration: underline;
`;

const FanOutShare = ({ activity, team, handleClose, shareLink }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { contactFormResult } = useSelector(state => state.voterRegistration);

  useEffect(() => {
    // if shareLink provide, this component will be used for FanOut share in sweepstakes, so we don't need to send auth email, otherwise we provide the necessary data and the hook will be triggered
    if (shareLink) return;

    fanOut
      .sendAuthEmail({
        activity_id: activity?.id,
        activity_slug: activity?.slug,
        campaign_id: activity?.campaign?.id,
        campaign_slug: activity?.campaign?.slug,
        email: contactFormResult.email,
        phone: contactFormResult.phone,
        referral_link: contactFormResult.referral_link,
      })
      .catch(error => {
        message.error(
          error?.response?.data?.message || error?.message || t('common.something_went_wrong'),
        );
        console.error(error);
      });
  }, [
    activity,
    contactFormResult.email,
    contactFormResult.phone,
    contactFormResult.referral_link,
    shareLink,
    t,
  ]);

  return (
    <StyledShare>
      <QRCodeShare activity={activity} team={team} shareLink={shareLink} isFanOut />
      <EntriesContent>
        <StyledGraph />
        <TrackContainer>
          <TrackText>{t('check_registration.referral.keepTrack')}</TrackText>
          <StatsText>{t('check_registration.referral.textedLink')}</StatsText>
        </TrackContainer>
      </EntriesContent>
      {isMobile && (
        <StyledButton onClick={handleClose} variant="link">
          {t('check_registration.referral.close')}
        </StyledButton>
      )}
    </StyledShare>
  );
};

FanOutShare.propTypes = {
  activity: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  shareLink: PropTypes.string,
  team: PropTypes.object,
};

FanOutShare.defaultProps = {
  team: null,
};

export default FanOutShare;
