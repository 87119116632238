import * as React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';

function IconButton({ children, additionalStyles, ...rest }) {
  return (
    <Button style={{ height: 40, padding: 0, width: 40, ...(additionalStyles ?? {}) }} {...rest}>
      {children}
    </Button>
  );
}

IconButton.propTypes = {
  additionalStyles: PropTypes.object,
  children: PropTypes.any.isRequired,
};

IconButton.defaultProps = {};

export default IconButton;
