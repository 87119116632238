import React from 'react';
import PropTypes from 'prop-types';

import DesktopHero from './components/DesktopHero';
import MobileHero from './components/MobileHero';

import useMedia from '@web/hooks/useMedia';

const ActivityHero = props => {
  const isMobile = useMedia('(max-width: 600px)');

  if (isMobile) {
    return <MobileHero {...props} />;
  }

  return <DesktopHero {...props} />;
};

ActivityHero.propTypes = {
  activity: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  goals: PropTypes.object.isRequired,
  handleGetReferral: PropTypes.func.isRequired,
  handleGetStarted: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
};

export default ActivityHero;
