import { message } from 'antd';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import S3Upload from 'react-s3-uploader/s3upload';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import theme from '@admin/styles/theme';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const notDropzoneProps = ['onFinish', 'onDrop', 's3Url', 'filename', 'host', 'upload'];
const UPLOAD_ENDPOINT = '/api/v2/activities/upload_file.json';

export const imagesTypes = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/webp': ['.webp'],
};

const Wrapper = styled.div`
  position: relative;
  width: fit-content;
`;

const Content = styled.div`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

const ManageButtonsWrapper = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background: red;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: flex-end;
  padding: 5px 5px 0 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin: 4px;
`;

const IconButton = styled.button`
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 4px;
  border: 1px ${theme.colors.teal60} solid;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border-color: ${theme.colors.teal};
  }
`;

/**
 * ImpactiveS3Uploader is based on DropzoneS3Uploader component https://www.npmjs.com/package/react-dropzone-s3-uploader
 *
 * for handling file rejections use `onDropRejected` prop.
 * `onError` will only be triggered for promise rejections.
 */
function ImpactiveImageUpload(props) {
  const dropzoneRef = React.createRef();
  const { s3Url, children, onDrop, maxSize, shouldShowManageButtons, ...dropzoneProps } = props;

  const [showManageButtons, setShowManageButtons] = useState(false);

  const handleProgress = useCallback(
    (progress, textState, file) => {
      props.onProgress?.(progress, textState, file);
    },
    [props],
  );

  const handleError = useCallback(
    (err, file) => {
      props.onError?.(err, file);
    },
    [props],
  );

  const handleFinish = useCallback(
    (info, file) => {
      const uploadedFile = {
        file,
        ...info,
      };
      props.onFinish?.(uploadedFile);
    },
    [props],
  );

  const handleDrop = useCallback(
    (files, rejectedFiles) => {
      const options = {
        contentDisposition: 'auto',
        files,
        onError: handleError,
        onFinishS3Put: handleFinish,
        onProgress: handleProgress,
        s3path: '',
        server: UPLOAD_ENDPOINT,
        signingUrl: '',
        signingUrlHeaders: {
          Authorization: localStorage.getItem('_outvote_web__token'),
        },
        signingUrlQueryParams: { uploadType: 'file' },
        uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
        ...props.upload,
      };
      new S3Upload(options);
      props.onDrop?.(files, rejectedFiles);
    },
    [handleError, handleFinish, handleProgress, props],
  );

  const handleDropRejected = useCallback(
    rejectedFiles => {
      props.onDropRejected?.(rejectedFiles);

      const [{ errors, file }] = rejectedFiles;
      // Fallback file rejection handler (display failure toast message)
      // message context will be available when file param is present
      if (!props.onDropRejected) {
        const context = file && errors[0]?.message;
        message.error(`File upload error. ${context}`);
      }
    },
    [props],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: props.accept,
    disabled: props.disabled,
    maxSize: props.maxSize,
    onDrop: handleDrop,
    onDropRejected: handleDropRejected,
    // useFsAccessApi needs to be disabled, otherwise only the first allowed MIME type is selected by default
    // https://github.com/react-dropzone/react-dropzone/issues/1191#issuecomment-1121245916
    useFsAccessApi: false,
  });

  notDropzoneProps.forEach(prop => delete dropzoneProps[prop]);

  const onDelete = useCallback(() => {
    props.onFinish?.(null);
    setShowManageButtons(false);
  }, [props]);

  return (
    <Wrapper
      onMouseOver={() => shouldShowManageButtons && setShowManageButtons(true)}
      onMouseLeave={() => shouldShowManageButtons && setShowManageButtons(false)}
    >
      <Content $disabled={props.disabled} ref={dropzoneRef}>
        <div {...getRootProps({ ...dropzoneProps })}>
          <input {...getInputProps()} />
          {children}
        </div>
      </Content>
      {showManageButtons && (
        <ManageButtonsWrapper onMouseOver={() => setShowManageButtons(true)}>
          <ButtonsWrapper>
            <IconButton type="button" onClick={onDelete}>
              <DeleteOutlined />
            </IconButton>
            <IconButton type="button" onClick={open}>
              <EditOutlined />
            </IconButton>
          </ButtonsWrapper>
        </ManageButtonsWrapper>
      )}
    </Wrapper>
  );
}

ImpactiveImageUpload.propTypes = {
  accept: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  // Default styles for react-dropzone, allow styling with styled-components
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  maxSize: PropTypes.number,
  onDrop: PropTypes.func,
  onDropRejected: PropTypes.func,
  onError: PropTypes.func,
  onFinish: PropTypes.func,
  onProgress: PropTypes.func,

  s3Url: PropTypes.string,
  shouldShowManageButtons: PropTypes.bool,
  style: PropTypes.object,
  // Passed to react-s3-uploader
  upload: PropTypes.object.isRequired,
};

ImpactiveImageUpload.defaultProps = {
  accept: {},
  className: 'react-dropzone-s3-uploader',
  disabled: false,
  s3Url: 'https://outvote-static-assets.s3.amazonaws.com',
  shouldShowManageButtons: false,
  style: {
    border: 'dashed 2px #999',
    borderRadius: 5,
    cursor: 'pointer',
    height: 200,
    overflow: 'hidden',
    position: 'relative',
    width: 200,
  },
  upload: {},
};

export default ImpactiveImageUpload;
