import * as React from 'react';
import PropTypes from 'prop-types';

import BackButton from '../Button/BackButton';
import { ButtonRow, StyledVotingInformation } from '.';
import { Description, Title } from '../TextStyles';
import DotLoader from '@web/components/DotLoader';
import Message from './Message';
import Share from './Share';

import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function VotingInformation({ activity, handleClose, team }) {
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const { t } = useTranslation();
  const registered = true;

  if (!contactFormResult?.voting_info) {
    return <DotLoader centered color="black" />;
  }

  const description = (
    <Trans
      components={[<br />]}
      i18nKey="voting_information.descriptionND"
      values={{ state: t('states.ND') }}
    />
  );

  return (
    <StyledVotingInformation>
      <Title>{t('voting_information.title')}</Title>
      <Description>{description}</Description>
      <Message registered={registered} isNorthDakota activity={activity} team={team} />
      <Share activity={activity} team={team} />
      <ButtonRow>
        <BackButton onClick={handleClose} showIcon={false}>
          {t('common.close')}
        </BackButton>
      </ButtonRow>
    </StyledVotingInformation>
  );
}

VotingInformation.propTypes = {
  activity: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
};

VotingInformation.defaultProps = {};

export default VotingInformation;
