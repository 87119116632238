import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@web/components/VoterRegistration/newComponents/Button';
import ShareIcon from '@web/components/VoterRegistration/newComponents/icons/Share';

import sendError from '@web/utils/sendError';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)`
  &&& {
    border-color: ${({ theme }) => theme.colors.coral};
    background-color: ${({ theme }) => theme.colors.coral};
    color: #fff;
    height: 48px;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    max-width: 280px;
  }
  svg {
    margin-right: 0.5rem;
  }
`;

function MobileShare({ activity, referralLink, team }) {
  const { t } = useTranslation();
  const isPartner = false;

  function handleShare() {
    const shareData = {
      text: isPartner ? team.action_page_description : activity.description,
      title: isPartner ? team.action_page_title : activity.title,
      url: referralLink,
    };
    if (navigator?.canShare && navigator.canShare(shareData) && navigator.share) {
      navigator.share(shareData).catch(err => {
        if (err.toString().includes('AbortError')) {
          // MTS - The share api throws this error when a user decides not to share.
          // It isn't a real error.
          return null;
        }
        sendError('Voter Registration: User encountered error while sharing', { err });
      });
    }
  }

  return (
    <StyledButton onClick={handleShare}>
      <ShareIcon /> {t('button.share')}
    </StyledButton>
  );
}

MobileShare.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    description: PropTypes.string,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  referralLink: PropTypes.string.isRequired,
  team: PropTypes.shape({
    action_page_description: PropTypes.string,
    action_page_title: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

MobileShare.defaultProps = {};

export default MobileShare;
