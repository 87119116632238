import * as React from 'react';
import axios from 'axios';
import sendError from '@web/utils/sendError';
import { message } from 'antd';

function useActivityScript(campaignId, activityId) {
  const [loading, setLoading] = React.useState(true);
  const [dialog, setDialog] = React.useState(null);
  const [scriptId, setScriptId] = React.useState(null);

  React.useEffect(() => {
    // in case this re-runs, set loading back to true
    setLoading(true);
    const url = `/api/v2/campaigns/${campaignId}/activities/${activityId}/scripts`;

    if (!campaignId || !activityId) {
      message.error('An error has occurred, please reload the page.');
      return sendError('Tried to call useActivityScript hook without required arguments', {
        error: 'manual error',
      });
    }

    axios(url)
      .then(({ data }) => {
        if (data?.data?.length) {
          setDialog(data.data[0]?.dialog);
          setScriptId(data.data[0]?.id);
        }
      })
      .catch(err => sendError('error fetching activity scripts', err))
      .finally(setLoading(false));
    // eslint-disable-next-line
  }, [campaignId, activityId]);

  return { dialog, scriptId, scriptLoading: loading };
}

export default useActivityScript;
