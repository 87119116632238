import React, { useState } from 'react';

import { Select } from 'antd';
import { ErrorMessage } from 'formik';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const { Option } = Select;

const SelectWrapper = styled.div`
  margin: ${({ margin }) => margin ?? 'unset'};
`;

const Label = styled.div`
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 0.875rem;
  ${({ theme }) => css`
    color: ${theme.colors.blue60};
    font-family: ${theme.fonts.bold};
  `}

  ${({ bold, theme }) =>
    bold &&
    css`
      font-weight: 600;
      text-transform: none;
      color: ${theme.colors.black};
    `}

  ${({ required, theme }) =>
    required &&
    css`
      ::after {
        content: '*';
        color: ${theme.colors.coral};
      }
    `}
`;

const outlined = value => css`
  min-width: 240px;
  > div {
    padding: 4px 11px;
    height: 42px;
    border-color: ${({ theme }) => theme.colors.gray};
    border-radius: 6px;
  }
  color: ${({ theme }) => (value ? theme.colors.black : theme.colors.blackL72)};
  font-size: 1rem;
  font-weight: 400;

  svg {
    color: ${({ theme }) => theme.colors.blue60};
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.teal60};
    svg {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

const standard = (open, value) => css`
  .ant-select-selection-item {
    &:after {
      content: none;
    }
  }
  > div {
    display: flex;
    align-items: center;
    padding: 0 1.3rem;
    border: 0;
    height: 72px;
    font-size: 1rem;
    background: ${({ theme }) => (open ? theme.colors.teal20 : theme.colors.snow)};
    min-width: 135px;
    max-width: 250px;
    span[title] {
      color: ${({ theme }) => (value ? theme.colors.black : theme.colors.blackL72)};
      opacity: 0.6;
    }
  }
  svg {
    color: ${({ theme }) => theme.colors.blue};
    opacity: 0.6;
  }
  &:hover {
    svg {
      opacity: 1;
    }
    > div {
      background: ${({ theme }) => theme.colors.teal20};
      span[title] {
        color: ${({ theme }) => theme.colors.blue};
        opacity: 1;
      }
    }
  }
`;

const StyledSelect = styled(Select)`
  &&& {
    ${({ type, open, value }) => `
      width: 100%;
      ${type === 'outlined' ? outlined(value) : standard(open, value)};
    `}

    background-color: transparent;

    .ant-select-selection-placeholder {
      color: #757575;
    }
  }
`;

const StyledErrorMessage = styled(ErrorMessage).attrs({ component: 'div' })`
  color: #ea5156;
  font-size: 12px;
`;

const OptionContent = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const SelectComponent = ({
  label,
  value,
  options,
  onChange,
  name,
  placeholder,
  mode,
  labelProps,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <SelectWrapper margin={rest.margin}>
      {label && <Label {...labelProps}>{label}</Label>}
      <StyledSelect
        name={name}
        placeholder={placeholder}
        value={value}
        open={visible}
        onChange={onChange}
        onDropdownVisibleChange={setVisible}
        mode={mode}
        {...rest}
      >
        {options.map((item, index) => (
          <Option key={index} value={item.value} label={item.label}>
            <OptionContent>
              {item.icon}
              {item.label}
            </OptionContent>
          </Option>
        ))}
      </StyledSelect>
      {name && <StyledErrorMessage name={name} />}
    </SelectWrapper>
  );
};

SelectComponent.propTypes = {
  label: PropTypes.string,
  labelProps: PropTypes.object,
  margin: PropTypes.string,
  mode: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
};

SelectComponent.defaultProps = { labelProps: {}, type: 'outlined' };

export default SelectComponent;
