import axios from 'axios';

const userReport = {
  findOrCreateReport: async ({ campaign_id, activity_id, contact_id = null }, user_report) => {
    return axios.post(`/api/v2/client/campaigns/${campaign_id}/user_reports/find_or_create`, {
      activity_id,
      contact_id,
      user_report,
    });
  },

  getBlankReport: async ({ campaign_id, activity_id }) => {
    return axios.get(
      `/api/v2/client/campaigns/${campaign_id}/user_reports/blank_report?activity_id=${activity_id}`,
    );
  },

  update: async ({ campaign_id, report_id }, user_report) => {
    return axios.put(`/api/v2/client/campaigns/${campaign_id}/user_reports/${report_id}`, {
      user_report,
    });
  },

  updateOrCreateReport: async ({ campaign_id, activity_id }, user_report) => {
    return axios.post(`/api/v2/client/campaigns/${campaign_id}/user_reports/update_or_create`, {
      activity_id,
      user_report,
    });
  },
};

export default userReport;
