import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';
import TaggingsList from './TaggingsList';
import CustomFieldsList from './CustomFieldsList';
import ImpactiveButton from '../../ImpactiveButton';

import theme from '@web/styles/theme';

const FillReportContainer = styled.div`
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  margin-right: 30px;
  position: sticky;
  top: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  z-index: 2;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.5rem;
  margin-top: 16px;
`;

const Divider = styled.div`
  height: 1px;
  background: rgba(196, 196, 196, 0.3);
  margin: 16px 0px;
`;

const ScrollableContainer = styled.div`
  padding-right: 30px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 32px;
  padding: 0 30px 30px 0;
  position: sticky;
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
`;

const SubmitButton = styled(ImpactiveButton)`
  width: 100%;
`;

const ArchiveWrapper = styled.div`
  margin: 20px 0;

  > div {
    display: flex;
    align-items: center;
    font-family: ${({ theme, regular }) => (regular ? theme.fonts.regular : theme.fonts.semibold)};
    color: ${({ theme }) => theme.colors.black};
    font-size: 1rem;
    font-weight: 400;
  }
`;
const StyledSelect = styled(Select)`
  &&& {
    > div {
      color: ${({ theme }) => theme.colors.dimGray};
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      &:hover {
        border-color: unset;
      }

      min-width: 50px;
      display: flex;
      flex-direction: row-reverse;
      border: none;

      > span {
        margin-top: 2px;
        display: flex;
        align-items: center;
      }
    }
  }
`;

const StyledLink = styled.a`
  font-family: ${theme.fonts.regular};
  color: ${theme.colors.black};
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    color: ${theme.colors.dodgerBlue};
    text-decoration: none;
  }
`;

const FillReportContent = ({
  archived,
  automaticallyArchiveThread,
  customizations,
  disableSubmitButton,
  setAutomaticallyArchiveThread,
  setUserReport,
  taggings,
  loading,
  submitReport,
  initialUserReport,
  userActivityId,
  reportActivityId,
  reportActivityName,
  isNewReportActivity,
}) => {
  const { t } = useTranslation();

  const activityUrl = isNewReportActivity
    ? `${window.location.origin}/add_report`
    : `${window.location.origin}/activities/${userActivityId}`;
  return (
    <FillReportContainer>
      <TitleWrapper>
        <Title>Fill report</Title>
        {userActivityId && userActivityId !== reportActivityId && (
          <StyledLink target="blank" href={activityUrl}>
            {reportActivityName}
          </StyledLink>
        )}
        <Divider />
      </TitleWrapper>
      <ScrollableContainer>
        {taggings && taggings.length !== 0 && (
          <TaggingsList
            taggings={taggings}
            initialUserReport={initialUserReport}
            setUserReportTaggings={updatedTaggings =>
              setUserReport({ customizations, taggings: updatedTaggings })
            }
          />
        )}

        <CustomFieldsList
          customizations={customizations}
          setUserReportCustomizations={updatedCustomFields => {
            setUserReport({ customizations: updatedCustomFields, taggings });
          }}
        />
      </ScrollableContainer>
      <ButtonsWrapper>
        <Divider />
        {!archived && (
          <ArchiveWrapper>
            <div>
              {t('inbox.archive_conversation_question')}

              <StyledSelect
                value={automaticallyArchiveThread}
                onChange={setAutomaticallyArchiveThread}
              >
                <Select.Option value>ON</Select.Option>
                <Select.Option value={false}>OFF</Select.Option>
              </StyledSelect>
            </div>
            <span>{t('inbox.archive_conversation')}</span>
          </ArchiveWrapper>
        )}

        <SubmitButton
          block={false}
          children={t('common.send_to_campaign')}
          disabled={disableSubmitButton}
          key="submit"
          size="medium"
          loading={loading}
          onClick={submitReport}
        />
      </ButtonsWrapper>
    </FillReportContainer>
  );
};

FillReportContent.propTypes = {
  archived: PropTypes.bool,
  automaticallyArchiveThread: PropTypes.bool,
  customizations: PropTypes.array.isRequired,
  disableSubmitButton: PropTypes.bool.isRequired,
  initialUserReport: PropTypes.object.isRequired,
  isNewReportActivity: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  reportActivityId: PropTypes.number,
  reportActivityName: PropTypes.string,
  setAutomaticallyArchiveThread: PropTypes.func,
  setUserReport: PropTypes.func.isRequired,
  submitReport: PropTypes.func.isRequired,
  taggings: PropTypes.array.isRequired,
  userActivityId: PropTypes.number,
};

export default FillReportContent;
