import React from 'react';

const CloseDrawer = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none" {...props}>
      <rect width={36} height={36} fill="#23293E" fillOpacity={0.06} rx={18} />
      <path
        fill="#707070"
        fillRule="evenodd"
        d="m18.007 19.794 5.42 5.42 1.787-1.785-5.421-5.421 5.42-5.421-1.785-1.786-5.421 5.42-5.421-5.42-1.786 1.786 5.42 5.42-5.42 5.422 1.786 1.786 5.42-5.421Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

CloseDrawer.propTypes = {};

CloseDrawer.defaultProps = {};

export default CloseDrawer;
