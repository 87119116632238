import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import Ticket from '@web/components/FanOut/components/icons/Ticket';
import TwoRecruits from '@web/components/FanOut/components/icons/TwoRecruits';
import Button from '@web/components/VoterRegistration/newComponents/Button';
import { media } from '@web/styles/theme';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
  margin-top: 16px;
  flex-wrap: wrap;

  ${smallBreakpoint} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Box = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  flex: 1;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  align-self: stretch;

  ${smallBreakpoint} {
    align-self: stretch;
    padding: 16px;
  }
`;

const LeftBox = styled(Box)`
  flex-direction: row;
  gap: 48px;
  flex: 0.6;

  ${media.s} {
    flex: 1;
    gap: 30px;
  }
`;

const RightBox = styled(Box)``;

const InnerBox = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 14px;
  align-items: flex-start;
  align-self: stretch;
`;

const Section = styled(InnerBox)`
  flex-direction: column;
`;

const Column = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const IconBox = styled.div`
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  /* height: 60px; */
`;

const BasicText = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;

const Title = styled(BasicText)`
  font-weight: 700;
  text-transform: uppercase;
`;
const StyledTicket = styled(Ticket)`
  height: 60px;
  /* scale: 0.88; */
`;

const Counter = styled(BasicText)`
  color: #121212;
  font-size: 2.47581rem;
  font-weight: 600;
  text-shadow: 3.6894371509552px 11.06831169128418px 44.27324676513672px rgba(0, 0, 0, 0.08);
`;

const StyledButton = styled(Button)`
  text-align: center;
  font-size: 1rem;
  font-weight: 700;

  &&& {
    height: 64px;
    max-width: 250px;

    ${smallBreakpoint} {
      max-width: unset;
    }
  }
`;

const EntriesBoxes = ({
  openFriendSearchModal,
  myStats,
  myStatsIsLoading,
  votingPlanReferralLink,
  votingPlanReferralLinkLoading,
}) => {
  const { t } = useTranslation();

  const openVotingPlan = useCallback(() => {
    window.open(votingPlanReferralLink, '_blank');
  }, [votingPlanReferralLink]);

  return (
    <Wrapper>
      <LeftBox>
        <Column>
          <Title>{t('fan_out.my_activity.entries_earned', { count: myStats.entries })}</Title>
          <IconBox>
            <StyledTicket isMyActivity />
            <Counter>{myStatsIsLoading ? <Spin /> : myStats.entries}</Counter>
          </IconBox>
        </Column>
        <Column>
          <Title>{t('fan_out.my_activity.recruits', { count: myStats.recruits })}</Title>
          <IconBox>
            <TwoRecruits />
            <Counter>{myStatsIsLoading ? <Spin /> : myStats.recruits}</Counter>
          </IconBox>
        </Column>
      </LeftBox>
      <RightBox>
        <Title>{t('fan_out.my_activity.more_entries')}</Title>
        <InnerBox>
          <Section>
            <StyledButton onClick={openFriendSearchModal} variant="fan_out">
              {t('fan_out.my_activity.look_up_button')}
            </StyledButton>
            <BasicText>{t('fan_out.my_activity.friend_description')}</BasicText>
          </Section>
          <Section>
            <StyledButton
              onClick={openVotingPlan}
              variant="fan_out"
              disabled={votingPlanReferralLinkLoading}
            >
              {t('fan_out.my_activity.voting_plan_button')}
            </StyledButton>
            <BasicText>{t('fan_out.my_activity.voting_plan_description')}</BasicText>
          </Section>
        </InnerBox>
      </RightBox>
    </Wrapper>
  );
};

EntriesBoxes.propTypes = {
  myStats: PropTypes.shape({
    entries: PropTypes.number,
    recruits: PropTypes.number,
    status: PropTypes.string,
  }).isRequired,
  myStatsIsLoading: PropTypes.bool.isRequired,
  openFriendSearchModal: PropTypes.func.isRequired,
  votingPlanReferralLink: PropTypes.string,
  votingPlanReferralLinkLoading: PropTypes.bool,
};

EntriesBoxes.defaultProps = {};

export default EntriesBoxes;
