import React, { useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getLocalizedOnboardingData } from '@web/utils/other';
import { setCampaign } from '@web/reducers/oneCampaign';
import { users as userAPI } from '@web/services/api';
import { AuthContext } from '@web/utils/context';
import ImpactiveButton from '@web/components/ImpactiveButton';
import { Alert, message } from 'antd';

const Flex = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  gap: 60px;
  > button {
    white-space: nowrap;
  }
`;

const StyledAlert = styled(Alert)`
  &&& {
    background-color: #fa8c161a;
    border-color: #fa8c1680;
    border-radius: 6px;
    padding: 13px 16px;

    .anticon {
      color: #fa8c16;
    }
  }
`;

/**
 * This alert is shown when the user is viewing content for a campaign, that is not currently selected in oneCampaign.
 *  For example, when being redirected from a share link.
 */
const SwitchCampaignAlert = ({ campaign, onSwitchCampaign }) => {
  const [firstShown, setFirstShown] = useState(false);

  const { t } = useTranslation();
  const user = useContext(AuthContext);
  const selectedCampaign = useSelector(state => state.oneCampaign.selectedCampaign);

  const joined = campaign?.user_campaign?.joined;

  const dispatch = useDispatch();

  const changeCampaign = data => {
    dispatch(setCampaign(data));
    setFirstShown(true);
  };

  const onSwitchClick = () => {
    const localizedCampaign = getLocalizedOnboardingData(campaign);

    if (!joined) {
      userAPI
        .followCampaign({
          campaign_id: campaign?.id,
          campaign_name: campaign?.name,
          campaign_slug: campaign?.slug,
        })
        .then(() => {
          const joinedCampaign = {
            ...localizedCampaign,
            user_campaign: {
              ...localizedCampaign.user_campaign,
              joined: true,
            },
          };
          changeCampaign(joinedCampaign);
          onSwitchCampaign && onSwitchCampaign(joinedCampaign);
        })
        .catch(err => message.error(err.message || t('common.something_went_wrong')));
    } else {
      changeCampaign(localizedCampaign);
      onSwitchCampaign && onSwitchCampaign(localizedCampaign);
    }

    setFirstShown(true);
  };

  const [showModal, setShowModal] = useState(true);
  useEffect(() => {
    //if a userr was on that campaign in the first place, or switches to it, we do not need to show the alert anymore
    if (selectedCampaign?.id === campaign?.id) {
      setShowModal(false);
      setFirstShown(true);
    }
    //do not show the alert if the user has already seen it, or if something did not load yet
    else if (firstShown || !user || !selectedCampaign || !campaign || !campaign?.active) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }, [user, selectedCampaign, campaign, firstShown, showModal]);

  if (!showModal) return null;

  return (
    <StyledAlert
      type="warning"
      showIcon
      message={
        <Flex>
          {t(
            joined
              ? 'campaign.switch_alert.message_switch'
              : 'campaign.switch_alert.message_switch_and_join',
            { campaign: campaign?.name },
          )}
          <ImpactiveButton onClick={onSwitchClick}>
            {t('campaign.switch_alert.switch')}
          </ImpactiveButton>
        </Flex>
      }
      onClose={() => setFirstShown(true)}
      closable
    />
  );
};

SwitchCampaignAlert.propTypes = {
  campaign: PropTypes.object,
  onSwitchCampaign: PropTypes.func,
};

export default SwitchCampaignAlert;
