import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckboxIcon from '../../icons/CheckboxIcon';
import { connect, getIn } from 'formik';
import { ErrorText } from '../sharedStyles';
import { Field } from 'formik';
import { vrDefaultColors } from '@web/styles/theme';

const CheckboxWrapper = styled.label`
  cursor: pointer;
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-gap: 0 1rem;
  padding-top: 2px;
  margin-bottom: 0;
  margin-top: 0.4rem;
  margin-right: 2px;
`;

const HiddenInput = styled(Field)`
  display: none;
`;

const Content = styled.span`
  transform: translateY(-4px);
`;

const Text = styled.span`
  color: ${({ $color }) => $color ?? vrDefaultColors.fontColor};
  font-size: 12px;
  &&& {
    a {
      color: ${({ $color }) => $color ?? vrDefaultColors.fontColor};
    }
  }
`;

function Checkbox({
  children,
  checked,
  formik,
  name,
  onClick,
  buttonColor,
  bgColor,
  fontColor,
  ...rest
}) {
  const [isTouched, setIsTouched] = React.useState(false);
  const error = getIn(formik.errors, name);
  const hasError = Boolean(isTouched && error);

  function handleClick(val) {
    setIsTouched(true);
    onClick(val);
  }

  return (
    <>
      <CheckboxWrapper htmlFor={name} onClick={handleClick} {...rest}>
        <HiddenInput name={name} type="checkbox" />
        <CheckboxIcon
          bgColor={bgColor}
          outlineColor={buttonColor}
          checked={checked || Boolean(formik.values[name])}
          hasError={hasError}
          style={{ cursor: 'pointer' }}
        />
        <Content>
          <Text $color={fontColor}>{children}</Text>
        </Content>
      </CheckboxWrapper>
      {hasError && <ErrorText>{error}</ErrorText>}
    </>
  );
}

Checkbox.propTypes = {
  bgColor: PropTypes.string,
  buttonColor: PropTypes.string,
  checked: PropTypes.bool,
  children: PropTypes.any.isRequired,
  fontColor: PropTypes.string,
  formik: PropTypes.shape({
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {};

export default connect(Checkbox);
