import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { animated, useSpring } from 'react-spring';
import { largeBreakpoint } from '../breakpoints';
import { vrDefaultColors } from '@web/styles/theme';

const Wrapper = styled.div`
  background-color: ${({ $isFanOut, $progressColor }) =>
    $isFanOut ? '#f0fafb' : `${$progressColor ?? vrDefaultColors.buttonColor}40`};
  border-radius: ${({ $height }) => $height / 2}px;
  display: flex;
  height: ${({ $height }) => $height}px;
  margin-bottom: 20px;
  margin-right: ${({ $isIframe }) => ($isIframe ? '0' : '30px')};
  overflow: hidden;
  ${largeBreakpoint} {
    margin-right: 0;
    margin-bottom: 25px;
  }
`;

const StyledProgressBar = styled(animated.div)`
  background-color: ${({ $isFanOut, theme, $progressColor }) =>
    $isFanOut ? theme.colors.newBlue : $progressColor ?? vrDefaultColors.progressBarColor};
  border-radius: ${({ $height }) => $height / 2}px;
  height: ${({ $height }) => $height}px;
`;

function ProgressBar({ height, progress, isFanOut, progressColor, isIframe }) {
  const width = Math.ceil(progress * 100);
  const style = useSpring({
    width: `${width || 0}%`,
  });

  const errorMsg = `<ProgressBar /> progress prop requires a range between 0 and 1, received: ${progress}`;
  if (progress < 0 || progress > 1) {
    console.error(errorMsg);
    return null;
  }

  return (
    <Wrapper
      $isIframe={isIframe}
      $isFanOut={isFanOut}
      $progressColor={progressColor}
      $height={height}
    >
      <StyledProgressBar
        $height={height}
        $isFanOut={isFanOut}
        style={style}
        $progressColor={progressColor}
      />
    </Wrapper>
  );
}

ProgressBar.propTypes = {
  height: PropTypes.number,
  isFanOut: PropTypes.bool,
  isIframe: PropTypes.bool,
  progress: PropTypes.number.isRequired,
  progressColor: PropTypes.string,
};

ProgressBar.defaultProps = {
  height: 7,
};

export default ProgressBar;
