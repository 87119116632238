import * as React from 'react';
import PropTypes from 'prop-types';

function ShareIcon(props) {
  return (
    <svg viewBox="0 0 17 19" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.0679 12.625V14.875C14.0679 15.5078 13.5405 16 12.9429 16H3.94287C3.31006 16 2.81787 15.5078 2.81787 14.875V12.625C2.81787 12.0273 2.29053 11.5 1.69287 11.5C1.06006 11.5 0.567871 12.0273 0.567871 12.625V14.875C0.567871 16.7734 2.04443 18.25 3.94287 18.25H12.9429C14.8062 18.25 16.3179 16.7734 16.3179 14.875V12.625C16.3179 12.0273 15.7905 11.5 15.1929 11.5C14.5601 11.5 14.0679 12.0273 14.0679 12.625ZM7.63428 0.601562L3.13428 5.10156C2.67725 5.52344 2.67725 6.26172 3.13428 6.68359C3.55615 7.14062 4.29443 7.14062 4.71631 6.68359L7.31787 4.11719V11.5C7.31787 12.1328 7.81006 12.625 8.44287 12.625C9.04053 12.625 9.56787 12.1328 9.56787 11.5V4.11719L12.1343 6.68359C12.5562 7.14062 13.2944 7.14062 13.7163 6.68359C14.1733 6.26172 14.1733 5.52344 13.7163 5.10156L9.21631 0.601562C8.79443 0.144531 8.05615 0.144531 7.63428 0.601562Z"
        fill="#E5E5E5"
      />
    </svg>
  );
}

ShareIcon.propTypes = {
  height: PropTypes.number,
};

ShareIcon.defaultProps = {
  height: 17,
};

export default ShareIcon;
