import React from 'react';

const Facebook = props => (
  <svg
    width="9"
    height="18"
    viewBox="0 0 9 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.23333 18V9.55388H0V6.51287H2.23333V3.91545C2.23333 1.87438 3.55257 0 6.59237 0C7.82314 0 8.73324 0.11799 8.73324 0.11799L8.66153 2.95778C8.66153 2.95778 7.73337 2.94874 6.72053 2.94874C5.62433 2.94874 5.44871 3.45391 5.44871 4.29237V6.51287H8.74866L8.60508 9.55388H5.44871V18H2.23333Z"
      fill="currentColor"
    />
  </svg>
);

export default Facebook;
