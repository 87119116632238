import PropTypes from 'prop-types';
import styled from 'styled-components';

const MainContainer = styled.div`
  display: flex;
  padding: 40px 25px 45px;
  position: relative;
  width: 100%;

  background-image: linear-gradient(180deg, rgba(35, 41, 62, 0.3) 0%, rgba(35, 41, 62, 0.7) 100%),
    url(${props => props.backgroundImage});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

MainContainer.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
};

MainContainer.defaultProps = {};

export default MainContainer;
