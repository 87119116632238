import { useCallback, useEffect, useContext } from 'react';

import { message } from 'antd';

import {
  removeSelectedCampaign,
  setJoinedCampaigns,
  setCampaign,
  setLoading,
  setLastCampaignId,
} from '@web/reducers/oneCampaign';
import { users as usersAPI, campaigns as campaignsAPI, user as userAPI } from '@web/services/api';
import { AuthContext } from '../utils/context';
import { getLocalizedOnboardingData } from '../utils/other';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Provider to handle all changes related to a One Campaign feature
const useOneCampaign = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();

  const { selectedCampaign, loading } = useSelector(state => state.oneCampaign);

  // The server call API when the user selects a new campaign to update the id of the last campaign the user selected.
  const updateUserLastCampaignId = useCallback(() => {
    //Newly registered users won`t have neither selected campaign nor last_campaign_id
    //So we have to set loading to false and let the use in that case, and thenr select a campaign.
    if (!selectedCampaign && !user?.last_campaign_id) {
      dispatch(setLoading(false));
      return;
    }

    // Prevent error when campaign is not loaded yet
    // Disable API call if not required. This hook is called on each page, so this feature is called each time during the initial rendering,
    // but we don't need to update the last campaign ID if it's the same as the previous one.
    if (!selectedCampaign || (!loading && selectedCampaign.id === user?.last_campaign_id)) return;

    userAPI
      .updateUserLastCampaignId({ lastCampaignId: selectedCampaign.id })
      .then(({ data }) => {
        dispatch(setLastCampaignId(data.data.last_campaign_id));
        dispatch(setLoading(false));
      })
      .catch(() => message.error(t('common.something_went_wrong')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedCampaign, t]);

  // Get all campaign data.
  // When the user selects a campaign from the list, there is only some data, but we need to get everything
  const getFullCampaignData = useCallback(
    async (id, joinedCampaigns) => {
      let data = await campaignsAPI.getCampaign({ id }).then(({ data: { data } }) => data);

      //if user leaves the current campaign, we should remove it from state.oneCampaign.selectedCampaign
      //and fallback to first campaign from the list, or remove it entirely
      if (!data.user_campaign.joined && joinedCampaigns.length > 0) {
        data = await campaignsAPI
          .getCampaign({ id: joinedCampaigns[0].campaign_id })
          .then(({ data: { data } }) => data);
      } else if (!data.user_campaign.joined) {
        dispatch(removeSelectedCampaign());
        return;
      }

      const localizedCampaign = getLocalizedOnboardingData(data);
      dispatch(setCampaign(localizedCampaign));
    },
    [dispatch],
  );

  const getJoinedCampaigns = useCallback(() => {
    // Prevent error when user is not logged in
    if (!user || selectedCampaign) return;

    usersAPI.getUserCampaigns(1, 5000).then(({ data: { data: campaigns } }) => {
      // Save campaigns in redux
      dispatch(setJoinedCampaigns(campaigns));
      // MTS - Adding this check to prevent calling getFullCampaignData without a campaign id.
      // We're getting an error in Cypress, I'm not sure if this could happen to a user.
      if (user?.last_campaign_id && campaigns.length > 0) {
        const campaign = campaigns.find(e => e.campaign_id === user.last_campaign_id);

        const campaignId =
          !!campaign && user.last_campaign_id ? user.last_campaign_id : campaigns[0].campaign_id;

        // If the custom object does not have the ID of the last selected campaign, we set the first campaign in the list of joined campaigns.
        getFullCampaignData(campaignId, campaigns ?? []);
      }
    });
  }, [user, selectedCampaign, dispatch, getFullCampaignData]);

  // // Get all campaigns users have joined
  useEffect(() => {
    getJoinedCampaigns();
  }, [getJoinedCampaigns]);

  useEffect(() => {
    updateUserLastCampaignId();
  }, [updateUserLastCampaignId]);
};

export default useOneCampaign;
