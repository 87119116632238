import React, { useMemo, useRef, useState } from 'react';

import ImpactiveCarousel from '../common/shared/Carousel';
import NavArrows from './NavArrows';
import ActivityPreviewModal from './ActivityPreviewModal';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { friendlyTypeNameFor } from '../../constants/activityTypes';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  margin-top: 10px;
`;

const Slide = styled.div`
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  padding-top: 30px;
  gap: 10px;
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
`;

const ActivityType = styled.div`
  color: ${({ theme }) => theme.colors.blue30};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const ActivityTitle = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.semibold};
  margin-bottom: 6px;
  font-size: 18px;
  line-height: 1.25;
`;

const ActivityWrapper = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

const RecentlyParticipatedActionsCarousel = ({ activities }) => {
  const carouselRef = useRef(null);
  const { t } = useTranslation();

  const [selectedAction, setSelectedAction] = useState(null);

  const carouselData = useMemo(
    () =>
      activities.reduce((result, value, index, array) => {
        if (index % 8 === 0) result.push(array.slice(index, index + 8));
        return result;
      }, []),
    [activities],
  );

  const renderNavArrows = carouselData.length > 1;

  const createActivityPreview = userActivity => {
    const {
      activity: { type, title },
    } = userActivity;

    return (
      <ActivityWrapper key={userActivity.id} onClick={() => setSelectedAction(userActivity)}>
        <ActivityType>{t(`activity.types.${type}`, friendlyTypeNameFor(type))}</ActivityType>
        <ActivityTitle>{title}</ActivityTitle>
      </ActivityWrapper>
    );
  };

  return (
    <>
      <Wrapper>
        <ImpactiveCarousel autoplaySpeed={10000} arrows ref={carouselRef}>
          {carouselData.map((i, index) => (
            <Slide key={index}>{i.map(createActivityPreview)}</Slide>
          ))}
        </ImpactiveCarousel>
        {renderNavArrows && (
          <NavArrows
            next={() => carouselRef.current?.next()}
            prev={() => carouselRef.current?.prev()}
          />
        )}
      </Wrapper>
      <ActivityPreviewModal
        visible={!!selectedAction}
        onCancel={() => setSelectedAction(null)}
        activity={selectedAction?.activity}
        completed={selectedAction?.completed}
        userActivity={selectedAction}
      />
    </>
  );
};

RecentlyParticipatedActionsCarousel.propTypes = { activities: PropTypes.array.isRequired };

RecentlyParticipatedActionsCarousel.defaultProps = {};

export default RecentlyParticipatedActionsCarousel;
