import * as React from 'react';
import PropTypes from 'prop-types';

import Button from '@web/components/Headcount/components/Button';

import theme from '@web/components/Headcount/theme';

function ExternalButtonLink({ href, ...rest }) {
  return (
    <Button
      as="a"
      href={href}
      rel="noopener noreferrer"
      style={{ color: theme.colors.bgGray, textDecoration: 'none' }}
      target="_blank"
      {...rest}
    />
  );
}

ExternalButtonLink.propTypes = {
  href: PropTypes.string.isRequired,
};

ExternalButtonLink.defaultProps = {};

export default ExternalButtonLink;
