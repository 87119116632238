import { node, string } from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import icon from '../../images/outvote-logo.png';
import { media, colors } from '../../styles/theme';
import MaxWidthContainer from '../common/MaxWidthContainer';

import HamburgerMenu from './HamburgerMenu';

const StyledAppHeader = styled.nav`
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 0px 3px 3px 0px ${({ theme }) => theme.colors.boxShadow};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${colors.borderGray};
  padding: 1em;
  height: 63px;
  position: sticky;
  max-height: 63px;
  top: 0;
  z-index: 4;
  ${media.ns} {
    display: none;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled.img`
  display: block;
  height: 30px;
  margin-right: 10px;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 16px;
  ${media.ns} {
    font-size: 20px;
  }
`;

const Extra = styled.div`
  display: flex;
  flex: 1;
  padding-left: 1em;
  align-items: center;
`;

const AppHeader = ({ extra, title }) => (
  <StyledAppHeader>
    <MaxWidthContainer className="flexbox">
      <TitleWrapper>
        <div>
          <NavLink style={{ display: 'flex' }} to="/frontline">
            <StyledIcon src={icon} alt="" />
          </NavLink>
        </div>
        <Title>{title}</Title>
      </TitleWrapper>
      <Extra>{extra}</Extra>
      {/* only shows up in mobile */}
      <HamburgerMenu />
    </MaxWidthContainer>
  </StyledAppHeader>
);

AppHeader.propTypes = {
  extra: node,
  title: string,
};

AppHeader.defaultProps = {
  extra: null,
};

export default AppHeader;
