import { Spin } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import useHasContacts from '../../hooks/useHasContacts';
import SyncContactsModal from '../ContactSync/components/SyncContactsModal';

import styled, { css } from 'styled-components';
import ImpactiveButton from '../ImpactiveButton';

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  padding: 2rem;
  line-height: 1.5rem;
  margin-bottom: 3rem;
`;

const Text = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blue};
    font-family: ${theme.fonts.normal};
    font-size: 1rem;
  `};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 2rem;
  margin: 1rem 0;
`;

const SyncContacts = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('titles.settings'));
  const { hasContacts, loading } = useHasContacts();
  const [syncContactsModalVisible, setSyncContactsModalVisible] = useState(false);

  return (
    <>
      <Title>{t('contact.sync.header')}</Title>
      <Wrapper>
        <Text>{t('contact.sync.what')}</Text>
        <Text>{t('contact.sync.privacy')}</Text>
        <br />
        {loading && <Spin />}
        {!loading && (
          <ImpactiveButton size="medium" onClick={() => setSyncContactsModalVisible(true)}>
            {hasContacts ? t('contact.sync.resync_contacts') : t('contact.sync.sync_contacts')}
          </ImpactiveButton>
        )}

        <SyncContactsModal
          syncContactsModalVisible={syncContactsModalVisible}
          setSyncContactsModalVisible={setSyncContactsModalVisible}
        />
      </Wrapper>
    </>
  );
};

export default SyncContacts;
