import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MediaPreview from 'web/components/common/MediaPreview';
import Title from 'web/components/common/Title';
import { media } from 'web/styles/theme';
import { truncate } from 'web/utils/string';

const Wrapper = styled.div`
  align-items: center;
  flex: 1;

  ${media.nxl} {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderGray}`};
  }
`;

const StyledTitle = styled(Title)`
  margin-top: 24px;
`;

const Media = styled.div`
  margin-top: 12px;
`;

const Info = styled.div``;

const Description = styled.div`
  color: ${({ theme }) => theme.colors.black};
`;

const SeeMoreButton = styled(Button)`
  &&& {
    padding-left: 0;
    margin-bottom: 16px;
    font-family: ${({ theme }) => theme.fonts.boldItalic};
    font-size: 16px;
  }
`;

const Highlights = styled.div`
  margin: 16px 0;
`;

const HighlightItem = styled.div`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 8px;
`;

const HighlightIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 12px;
`;

const TRUNCATE_LENGTH = 250;

const CampaignAbout = ({ campaign }) => {
  const { t } = useTranslation();

  const { highlights, description, public_join_video_url, url_hero_img } = campaign;
  const isDescriptionLong = description && description.length > TRUNCATE_LENGTH;

  const [descriptionExpanded, setDescriptionExpanded] = React.useState(false);
  const handleSeeMore = () => setDescriptionExpanded(i => !i);

  let mediaUrl = public_join_video_url;
  let mediaContentType = 'video';

  if (!mediaUrl) {
    //Default to hero image
    mediaUrl = url_hero_img;
    mediaContentType = 'image';
  }

  return (
    <Wrapper>
      <StyledTitle>{t('campaign.about')}</StyledTitle>
      <Media>
        <MediaPreview mediaUrl={mediaUrl} mediaContentType={mediaContentType} />
      </Media>
      <Info>
        <Description>
          {descriptionExpanded ? description : truncate(description, TRUNCATE_LENGTH)}
        </Description>
        {descriptionExpanded && (
          <Highlights>
            {highlights &&
              highlights.map(({ title, img_url }) => {
                return (
                  <HighlightItem key={title}>
                    <HighlightIcon src={img_url} alt={title} />
                    <span>{title}</span>
                  </HighlightItem>
                );
              })}
          </Highlights>
        )}
        {isDescriptionLong && (
          <SeeMoreButton type="link" onClick={handleSeeMore}>
            {descriptionExpanded ? t('campaign.see_less') : t('campaign.see_more')}
          </SeeMoreButton>
        )}
      </Info>
    </Wrapper>
  );
};

CampaignAbout.propTypes = {
  campaign: PropTypes.shape({
    description: PropTypes.string,
    highlights: PropTypes.array,
    public_join_video_url: PropTypes.string,
    url_hero_img: PropTypes.string,
  }).isRequired,
};

export default CampaignAbout;
