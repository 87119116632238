import React, { useState, useCallback, useEffect } from 'react';

import ActivityDetailCore from './ActivityDetailCore';
import OnboardingModal from 'web/components/common/OnboardingModal';

import { useParams } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { user, campaigns as campaignAPI } from '../../services/api';

import './activity-detail.scss';

const ActivityDetail = () => {
  const { t } = useTranslation();

  // Extract userActivityId from params as a number
  const { userActivityId: userActivityIdParam } = useParams();
  const userActivityId = parseInt(userActivityIdParam);

  const [userActivity, setUserActivity] = useState({});
  const [campaign, setCampaign] = useState(null); // Used for showing welcome modal

  const [loading, setLoading] = useState(true);

  const loadActivity = useCallback(() => {
    setLoading(true);
    user.getUserActivity(userActivityId).then(({ data: { data: userActivityData } }) => {
      const {
        activity: { campaign: campaignData },
      } = userActivityData;

      setUserActivity(userActivityData);
      campaignAPI.getCampaign({ id: campaignData.id }).then(res => {
        setCampaign(res.data.data);
        setLoading(false);
      });
    });
  }, [userActivityId]);

  // load activity on init
  useEffect(() => {
    loadActivity();
  }, [loadActivity]);

  // Extract relevant info
  const {
    activity,
    activity: { campaign: { name: campaignName = '' } = {} } = {},
    completed,
    header_title: headerTitle,
  } = userActivity;

  // Set document title based on campaign name
  useDocumentTitle(campaignName);

  return (
    <>
      <ActivityDetailCore
        activity={activity}
        appContainerDataTestId={'logged_in_activity_detail'}
        campaign={campaign}
        completed={completed}
        headerTitle={headerTitle}
        loading={loading}
        title={t('activity.header_title')}
        setCampaign={setCampaign}
        userActivity={userActivity}
      />
      <OnboardingModal campaign={campaign} />
    </>
  );
};

ActivityDetail.propTypes = {};

export default ActivityDetail;
