import React, { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { getAndClearRedirectAfterLoginURL } from 'web/services/session';
import { identify } from '../../services/analytics';
import { auth } from '../../services/api';
import { AuthContext } from '../../utils/context';
import Loading from '../common/Loading';
import { setAppLanguage } from '../i18n';
import { ImpactiveButton } from '@admin/components/helpers/ImpactiveButtons';
import styled from 'styled-components';

const MobileLoginWrapper = styled.div`
  padding: 4em;
  text-align: center;
`;

const Spacer = styled.div`
  margin: 8px 0;
`;

const ContinueLink = styled.span`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

/**
 * Magic link claim page. This is where user lands upon clicking on magic links.
 *
 * Special case: for iOS devices we render a small "Open in Impactive App" button so that
 * they can be redirected to use mobile app instead of being logged into Safari or other browser.
 *
 */
const PasswordlessTokenLoginPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { token } = useParams();
  const [loading, setLoading] = React.useState(true);

  useDocumentTitle(t('titles.sign_in'));
  const { setAuth } = useContext(AuthContext);

  useEffect(() => {
    if (!token) {
      history.push('/auth/login/email');
      return;
    }

    // This condition fixes a bug where token from path is stringified to 'null'
    // I didn't find any obvious reason why this is happening, probably a combination of race conditions
    // and route redirects all over the place that'd require further debugging.
    // Relevant issue here: https://github.com/outvote/web-app/issues/7516
    if (token === 'null') return;

    // Unless we are on a mobile device, proceed with login
    if (!isMobile) {
      handleToken(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToken = token => {
    auth
      .verifyMagicLink(token)
      .then(res => {
        const {
          headers: { authorization: bearerHeader },
          data: { data: user },
        } = res;
        const { locale, destination, is_campaign_admin, can_admin_accounts } = user;

        setAuth(bearerHeader);
        setAppLanguage(locale);
        identify(user.id);

        const canViewAdmin = is_campaign_admin || can_admin_accounts;

        switch (destination) {
          case 'login':
            if (canViewAdmin) {
              window.location.href = '/admin';
            } else {
              history.push('/frontline');
            }
            break;

          case 'signup':
            history.push('/frontline');
            break;

          // New signup flow
          case 'campaign-signup':
            history.push('/new-campaign');
            break;

          default:
            // If it's none of the above, use the `redirectAfterLoginURL` or fallback to the campaign slug
            const redirectURL = getAndClearRedirectAfterLoginURL();
            history.push(redirectURL || `/campaigns/${destination}`);
            break;
        }
      })
      .catch(() => {
        // Catching failed auth requests here so that they don't get reported to sentry
        // NOTE: It's fine to fail silently here because the error will be handled by the
        // axios.interceptor 401 handler that will display the "Session expired" message
      })
      .finally(() => setLoading(false));
  };

  // Use following schema/host for expo (simulator) debugging. If you want to debug on a physical device
  // you'll need to change the IP address to your local network IP address instead.
  // const hostname = "exp://127.0.0.1:19000/--";
  const hostname = `outvote://${document.location.hostname}`;

  return (
    <div className="d-flex flex-row justify-content-center">
      {isMobile ? (
        <MobileLoginWrapper>
          <ImpactiveButton href={`${hostname}/auth/magic-link/${token}`}>
            Open in Impactive App
          </ImpactiveButton>
          <Spacer>or</Spacer>
          <ContinueLink onClick={() => handleToken(token)}>continue in browser</ContinueLink>
        </MobileLoginWrapper>
      ) : (
        loading && <Loading />
      )}
    </div>
  );
};

PasswordlessTokenLoginPage.propTypes = {};

export default PasswordlessTokenLoginPage;
