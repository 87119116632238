const ContactRegistrationStatusTypes = {
  DIFFERENT_ADDRESS: 'Different Address',
  REFERRAL_LINK: 'Referral Link',
  REGISTERED: 'Registered',
  UNABLE_TO_VOTE: 'Unable to Vote',
  UNCONFIRMED: 'Unconfirmed',
  UNREGISTERED: 'Unregistered',
};

export default ContactRegistrationStatusTypes;
