import { FacebookOutlined, MailOutlined, TwitterOutlined, UploadOutlined } from '@ant-design/icons';
import { message, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import Clipboard from 'react-clipboard.js';
import { useTranslation } from 'react-i18next';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import ImpactiveButton from '../ImpactiveButton';
import DoneCheckmark from './DoneCheckmark';
import {
  GetStarted,
  SubHeader,
  ThreeXImpact,
  ThankYouDescription,
  ShareSection,
  ButtonsRow,
  ShareButtonInner,
} from './styles';

const ThankYou = ({ handleNativeShare, shareLink }) => {
  const { t } = useTranslation();
  const shareNowVisible = !!navigator.share;

  return (
    <GetStarted>
      <SubHeader>{t('ga_runoff.thank_you')}</SubHeader>
      <DoneCheckmark height={60} width={60} />
      <ThankYouDescription style={{ paddingTop: '15px' }}>
        {t('ga_runoff.you_will_receive')}
      </ThankYouDescription>
      <ThreeXImpact>{t('ga_runoff.3x_impact')}</ThreeXImpact>
      <ThankYouDescription>{t('ga_runoff.3x_impact_description')}</ThankYouDescription>
      <ShareSection>
        <ButtonsRow fullWithOnMobile>
          {shareNowVisible && (
            <ImpactiveButton onClick={handleNativeShare}>
              <UploadOutlined /> {t('voting_information.share.share_now')}
            </ImpactiveButton>
          )}

          {!shareNowVisible && (
            <Clipboard
              component="span"
              data-clipboard-text={shareLink}
              onSuccess={() => message.info(t('common.copied_to_clipboard'))}
            >
              <Tooltip placement="top" title={shareLink}>
                <ImpactiveButton>{t('voting_information.share.copy_share_link')}</ImpactiveButton>
              </Tooltip>
            </Clipboard>
          )}

          <FacebookShareButton url={shareLink}>
            <ShareButtonInner>
              <FacebookOutlined /> {t('voting_information.share.facebook')}
            </ShareButtonInner>
          </FacebookShareButton>

          <TwitterShareButton url={shareLink}>
            <ShareButtonInner>
              <TwitterOutlined /> {t('voting_information.share.twitter')}
            </ShareButtonInner>
          </TwitterShareButton>

          <ImpactiveButton
            onClick={() => window.open(`mailto:?body=${encodeURIComponent(shareLink)}`, '_blank')}
          >
            <MailOutlined /> {t('voting_information.share.email')}
          </ImpactiveButton>
        </ButtonsRow>
      </ShareSection>
    </GetStarted>
  );
};

ThankYou.propTypes = {
  handleNativeShare: PropTypes.func.isRequired,
  shareLink: PropTypes.string.isRequired,
};

export default ThankYou;
