// MTS - This helper is used to parse the address returned from 'react-google-autocomplete';

function parseAddress(place) {
  // Address an issue where the place returned from react-google-autocomplete is undefined.
  if (!place) {
    return {};
  }

  function findObject(key) {
    return place.address_components?.find(obj => obj.types.includes(key));
  }

  function getStreetNumber() {
    const streetNumberObj = findObject('street_number');
    return streetNumberObj?.short_name || '';
  }

  function getStreetName() {
    const streetNameObj = findObject('route');
    return streetNameObj?.short_name || '';
  }

  function getCity() {
    let cityObj;
    cityObj = findObject('locality');
    // fallback for when locality does not work, example Brooklyn is a
    // sublocality
    if (!cityObj) {
      cityObj = findObject('sublocality');
    }
    return cityObj?.short_name || '';
  }

  function getState() {
    const stateObj = findObject('administrative_area_level_1');
    return stateObj?.short_name || '';
  }

  function getCounty() {
    const countyObj = findObject('administrative_area_level_2');
    return countyObj?.short_name || '';
  }

  function getZipCode() {
    const zipCodeObj = findObject('postal_code');
    return zipCodeObj?.short_name || '';
  }

  const street_number = getStreetNumber();
  const street_name = getStreetName();
  return {
    address: `${street_number} ${street_name}`.trim(),
    city: getCity(),
    county_name: getCounty(),
    state_abbrev: getState(),
    street_name,
    street_number,
    zip_code: getZipCode(),
  };
}

export default parseAddress;
