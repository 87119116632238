import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { animated, useSpring } from 'react-spring';
import { largeBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';

const Wrapper = styled.div`
  background-color: #f0fafb;
  border-radius: ${({ $height }) => $height / 2}px;
  display: flex;
  height: ${({ $height }) => $height}px;
  margin-bottom: 20px;
  overflow: hidden;
  ${largeBreakpoint} {
    margin-bottom: 36px;
  }
`;

const StyledProgressBar = styled(animated.div)`
  background-color: ${({ theme }) => theme.colors.newBlue};
  border-radius: ${({ $height }) => $height / 2}px;
  height: ${({ $height }) => $height}px;
`;

const ProgressBar = ({ height, progress }) => {
  const width = Math.ceil(progress * 100);
  const style = useSpring({
    width: `${width || 0}%`,
  });

  const errorMsg = `<ProgressBar /> progress prop requires a range between 0 and 1, received: ${progress}`;
  if (progress < 0 || progress > 1) {
    console.error(errorMsg);
    return null;
  }

  return (
    <Wrapper $height={height}>
      <StyledProgressBar $height={height} style={style} />
    </Wrapper>
  );
};

ProgressBar.propTypes = {
  height: PropTypes.number,
  progress: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  height: 7,
};

export default ProgressBar;
