import React from 'react';
import PropTypes from 'prop-types';

const VotingPlan = props => {
  const { type, isMobile } = props;

  if (type === 'primary') {
    return isMobile ? (
      <svg xmlns="http://www.w3.org/2000/svg" width={186} height={14} fill="none" {...props}>
        <path
          stroke="#FFD54F"
          strokeLinecap="round"
          strokeWidth={4}
          d="M2 12C59.623 4.094 163.778-3.811 184 8.047"
        />
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" width={312} height={18} fill="none" {...props}>
        <path
          stroke="#FFD54F"
          strokeLinecap="round"
          strokeWidth={4}
          d="M2 16C99.515 4.932 275.778-6.136 310 10.466"
        />
      </svg>
    );
  } else if (type === 'secondary') {
    return isMobile ? (
      <svg xmlns="http://www.w3.org/2000/svg" width={151} height={14} fill="none" {...props}>
        <path
          stroke="#FFD54F"
          strokeLinecap="round"
          strokeWidth={4}
          d="M2 12C48.541 4.094 132.667-3.811 149 8.047"
        />
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" width={251} height={18} fill="none" {...props}>
        <path
          stroke="#FFD54F"
          strokeLinecap="round"
          strokeWidth={4}
          d="M2 16C80.202 4.932 221.556-6.136 249 10.466"
        />
      </svg>
    );
  } else return null;
};

VotingPlan.propTypes = {
  isMobile: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary']),
};

VotingPlan.defaultProps = {
  type: 'primary',
};

export default VotingPlan;
