import React, { useCallback, useMemo } from 'react';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import TablePagination from '@admin/components/helpers/TablePagination';

import { getIndividualLeaderboardColumns, getTeamLeaderboardColumns } from './getColumns';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@web/styles/theme';

const StyledTable = styled(ReactTable)`
  background: white;
  border-radius: 40px;
  overflow: scroll;
  border: solid ${theme.colors.trueBlack} 2px;

  .rt-resizable-header-content {
    color: black;
    font-weight: 700;
    padding: 10px;
  }

  div[role='rowgroup'] {
    border-bottom: none !important;
  }

  div[role='gridcell'] {
    padding: 20px;
    color: ${theme.colors.trueBlack};
    font-weight: 600;
  }

  .pagination-bottom {
    > div {
      padding: 10px 20px 15px 20px;
    }
  }

  .rt-noData {
    left: 60px;
    top: 100px;
    transform: none;
  }
`;

const LeaderboardTable = ({ controls }) => {
  const handleFetchData = useCallback(
    state => controls.setPaginationConfig({ page: state.page, perPage: state.pageSize }),
    [controls],
  );

  const isIndividual = useMemo(() => controls.type === 'individual', [controls.type]);

  return (
    <StyledTable
      data={controls.leaderboard}
      columns={isIndividual ? getIndividualLeaderboardColumns() : getTeamLeaderboardColumns()}
      loading={false}
      showPageJump={false}
      minRows={10}
      showPaginationTop={false}
      pageSizeOptions={[10, 25, 50, 100]}
      manual
      sortable={false}
      page={controls.paginationConfig.page}
      onFetchData={handleFetchData}
      pageSize={controls.paginationConfig.perPage}
      PaginationComponent={paginationProps => (
        <TablePagination {...paginationProps} scrollToTop canNext={controls.hasMore} />
      )}
      noDataText={
        isIndividual
          ? 'No one has garnered a referral yet.'
          : "Teams haven't garnered any referrals yet."
      }
    />
  );
};

LeaderboardTable.propTypes = {
  controls: PropTypes.shape({
    hasMore: PropTypes.bool,
    leaderboard: PropTypes.array,
    paginationConfig: PropTypes.shape({
      page: PropTypes.number,
      perPage: PropTypes.number,
    }),
    setPaginationConfig: PropTypes.func,
    setType: PropTypes.func,
    type: PropTypes.string,
  }),
};

export default LeaderboardTable;
