import styled from 'styled-components';

export const FormContainer = styled.div`
  max-width: 590px;
  margin-top: 24px;
`;

export const Heading = styled.h4`
  color: #000;
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  margin: 8px 0 10px;
`;

export const CheckboxWrapper = styled.div`
  margin-bottom: 12px;
`;

export const SubmitWrapper = styled.div`
  margin-top: 32px;
`;
