import styled from 'styled-components';

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 3rem;
  line-height: 58px;
  margin-bottom: 20px;
`;

export const Description = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;
  line-height: 28px;
  margin-bottom: 16px;
  margin-top: 35px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
`;
