import React from 'react';

const Copy = props => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0443 8.45621C11.2252 7.63744 10.1144 7.17749 8.9563 7.17749C7.79815 7.17749 6.68741 7.63744 5.8683 8.45621L2.7793 11.5442C1.96018 12.3633 1.5 13.4743 1.5 14.6327C1.5 15.7911 1.96018 16.9021 2.7793 17.7212C3.59842 18.5403 4.70939 19.0005 5.8678 19.0005C7.02621 19.0005 8.13718 18.5403 8.9563 17.7212L10.5003 16.1772"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.95703 11.5443C9.77614 12.3631 10.8869 12.823 12.045 12.823C13.2032 12.823 14.3139 12.3631 15.133 11.5443L18.222 8.4563C19.0412 7.63718 19.5013 6.52621 19.5013 5.3678C19.5013 4.20939 19.0412 3.09842 18.222 2.2793C17.4029 1.46018 16.2919 1 15.1335 1C13.9751 1 12.8642 1.46018 12.045 2.2793L10.501 3.8233"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Copy;
