import { message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { users } from '../../../services/api';
import Text from '../../common/Text';
import ContactApproval from './ContactApproval';
import GoogleAuthButton from './GoogleAuthButton';
import GoogleContacts from './GoogleContacts';
import ImportSuccess from './ImportSuccess';
import Loading from './Loading';
import SyncWrapper from './SyncWrapper';

import useUserLocked from './useUserLocked';

const Header = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 22px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  margin-bottom: 10px;
`;

const PrivacyText = styled.div`
  margin-top: 10px;
  font-size: 10px;
`;

const ContactSyncInternal = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [selectedMyContacts, setSelectedMyContacts] = React.useState([]);
  const [selectedOtherContacts, setSelectedOtherContacts] = React.useState([]);
  const [selectedGmailContacts] = React.useState([]);
  const selectedGoogleContacts = selectedMyContacts.concat(selectedOtherContacts);

  const { lockedToCampaign } = useUserLocked();
  const [googleUser, setGoogleUser] = useState(null);

  function incrementStep(steps) {
    const count = Number(steps) || 1;
    return setCurrentStep(currentStep + count);
  }

  function decrementStep(steps) {
    const count = Number(steps) || 1;
    setCurrentStep(currentStep - count);
  }

  React.useEffect(() => {
    if (currentStep === 3) {
      syncContacts();
    }
    // eslint-disable-next-line
  }, [currentStep]);

  const syncContacts = () => {
    let allContacts = selectedGoogleContacts.concat(selectedGmailContacts);
    // backend has a weird requirement for email structure
    allContacts = allContacts.map(contact => ({ ...contact, emails: [{ email: contact.email }] }));
    users
      .uploadEmailContacts(allContacts)
      .then(res => {
        incrementStep();
      })
      .catch(() => {
        message.error(t('errors.unexpected'));
      });
  };

  // if (checkApprovalLoading) {
  //   return <Spin />;
  // }

  return (
    <>
      <SyncWrapper>
        <Header>
          {`${currentStep === 2 ? t('contact.sync.header_other') : t('contact.sync.header')}`}
        </Header>

        {/* Step 0  */}
        {currentStep === 0 && (
          <>
            <Text>{t('contact.sync.what')}</Text>
            <GoogleAuthButton
              googleUser={googleUser}
              incrementStep={incrementStep}
              setGoogleUser={user => setGoogleUser(user)}
            />
            <PrivacyText>{t('contact.sync.privacy')}</PrivacyText>
          </>
        )}

        {/* Step 1 - show/select my google contacts*/}
        {currentStep === 1 && (
          <GoogleContacts
            step={currentStep}
            type="my"
            decrementStep={decrementStep}
            incrementStep={incrementStep}
            selectedContacts={selectedMyContacts}
            setSelectedContacts={setSelectedMyContacts}
            maxHeight="calc(70vh - 8rem)"
          />
        )}

        {/* Step 2 - show/select other google contacts*/}
        {currentStep === 2 && (
          <GoogleContacts
            step={currentStep}
            type="other"
            decrementStep={decrementStep}
            incrementStep={incrementStep}
            selectedContacts={selectedOtherContacts}
            setSelectedContacts={setSelectedOtherContacts}
            maxHeight="calc(70vh - 8rem)"
          />
        )}

        {/* Step 2 - select gmail contacts*/}
        {/* {currentStep === 2 && (
            <GmailContacts
              decrementStep={decrementStep}
              incrementStep={incrementStep}
              filterEmails={selectedGoogleContacts
                .map(({ email }) => email.toLowerCase())
                .concat([googleUser.email])}
              selectedGmailContacts={selectedGmailContacts}
              setSelectedGmailContacts={setSelectedGmailContacts}
            />
          )} */}

        {/* Step 3 - send all of the contacts to the backend */}
        {currentStep === 3 && <Loading />}

        {/* Step 4 - send all of the contacts to the backend */}
        {currentStep === 4 && (
          <ContactApproval
            contactsToApprove={selectedGoogleContacts.concat(selectedGmailContacts)}
            decrementStep={decrementStep}
            lockedToCampaign={lockedToCampaign}
            onSuccess={incrementStep}
          />
        )}

        {/* Step 5 - send all of the contacts to the backend */}
        {currentStep === 5 && (
          <ImportSuccess count={selectedGoogleContacts.length + selectedGmailContacts.length} />
        )}
      </SyncWrapper>
    </>
  );
};

ContactSyncInternal.propTypes = {};

export default ContactSyncInternal;
