import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';

import activityRoutes from '@web/services/api/activities';
import { message } from 'antd';

const useLeaderboard = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialType = queryParams.get('type') || 'individual';
  const params = useParams();

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(initialType);
  const [hasMore, setHasMore] = useState(false);

  const [leaderboard, setLeaderboard] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({ page: 0, perPage: 10 });

  const [publicCampaign, setPublicCampaign] = useState(null);
  const [publicActivity, setPublicActivity] = useState(null);

  const getData = useCallback(
    type => {
      const api =
        type === 'individual'
          ? activityRoutes.getVoterRegistrationContactLeaderboard
          : activityRoutes.getVoterRegistrationTeamLeaderboard;

      api({
        activitySlug: params.activitySlug,
        campaignSlug: params.campaignSlug,
        page: paginationConfig.page + 1,
        perPage: paginationConfig.perPage,
      })
        .then(({ data }) => {
          setLeaderboard(data.data);
          setPublicActivity(data.activity);
          setPublicCampaign(data.campaign);
          setHasMore(!!data.links.next);
          setLoading(false);
        })
        .catch(() => {
          message.error('Something went wrong, please try again');
          setLoading(false);
        });
    },
    [paginationConfig.page, paginationConfig.perPage, params.activitySlug, params.campaignSlug],
  );

  useEffect(() => {
    getData(type);
  }, [getData, type]);

  // Update leaderboard every 5min
  useEffect(() => {
    const interval = setInterval(() => {
      getData(type);
    }, 300000);

    return () => clearInterval(interval);
  }, [getData, type]);

  const changeUrl = data => {
    const params = new URLSearchParams({ type: data });
    history.replace({ pathname: location.pathname, search: params.toString() });
    setPaginationConfig({ page: 0, perPage: 10 });
    setType(data);
  };

  return {
    activity: publicActivity,
    campaign: publicCampaign,
    hasMore,
    leaderboard,
    loading,
    paginationConfig,
    setPaginationConfig,
    setType: changeUrl,
    type,
  };
};

export default useLeaderboard;
