import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  joinedCampaigns: [],
  lastCampaignId: null,
  loading: true,
  selectedCampaign: null,
};

const oneCampaign = createSlice({
  initialState,
  name: 'oneCampaign',
  reducers: {
    init: () => initialState,

    removeSelectedCampaign: state => {
      state.selectedCampaign = null;
    },

    setCampaign: (state, action) => {
      state.selectedCampaign = action.payload;
    },

    setJoinedCampaigns: (state, action) => {
      state.joinedCampaigns = action.payload;
    },

    setLastCampaignId: (state, action) => {
      state.lastCampaignId = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  init,
  removeSelectedCampaign,
  setCampaign,
  setJoinedCampaigns,
  setLoading,
  setLastCampaignId,
} = oneCampaign.actions;

export default oneCampaign.reducer;
