import React from 'react';

import Clipboard from 'react-clipboard.js';
import { message, Tooltip } from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';
import ActivityButton from './ActivityButton';
import {
  ActivityWrapper,
  ActivityBody,
  ActivityType,
  Body,
  Date,
  GeneralActivityData,
  MediaContentWrapper,
  Media,
  MediaWrapper,
  Title,
} from './sharedStyledComponents';

import PropTypes from 'prop-types';
import { dateToAge, truncate } from '../../utils/string';
import { useTranslation } from 'react-i18next';
import { friendlyTypeNameFor } from '../../constants/activityTypes';

const ActivityPreview = ({ activity, completed, userActivity, onActivityActionButtonPress }) => {
  const { t } = useTranslation();

  const activityDescriptionTruncated = truncate(activity.description, 50);
  const activityTitleTruncated = truncate(activity.title, 50);
  const closed = activity.aasm_state === 'closed';

  const shareActivityLink = `${window.location.origin}/campaigns/${activity.campaign.slug}?activityId=${userActivity?.id}`;

  //set it to friendly english name, if not found in translations

  return (
    <ActivityWrapper>
      <ActivityBody>
        <GeneralActivityData>
          <div>
            <ActivityType>
              {t(`activity.types.${activity.type}`, friendlyTypeNameFor(activity.type))}
            </ActivityType>
            <Title>{activityTitleTruncated}</Title>
          </div>
          <div>
            <Body>{activityDescriptionTruncated}</Body>
            <Date>
              {dateToAge(activity.published_at)}
              {closed && ` (${t('campaign.activity.closed')})`}
            </Date>
          </div>

          <ActivityButton
            activity={activity}
            completed={completed}
            onActivityActionButtonPress={onActivityActionButtonPress}
            userActivity={userActivity}
          />
        </GeneralActivityData>

        <MediaContentWrapper>
          <Clipboard
            component="span"
            className="anticon"
            onClick={e => e.stopPropagation()}
            data-clipboard-text={shareActivityLink}
            onSuccess={() => message.info(t('activity.share.copied_to_clipboard'))}
          >
            <Tooltip placement="top" title={t('button.share')}>
              <ShareAltOutlined style={{ cursor: 'pointer' }} />
            </Tooltip>
          </Clipboard>

          {!!activity.media_url && (
            <MediaWrapper>
              <Media src={activity.media_url} />
            </MediaWrapper>
          )}
        </MediaContentWrapper>
      </ActivityBody>
    </ActivityWrapper>
  );
};

ActivityPreview.propTypes = {
  activity: PropTypes.object.isRequired,
  completed: PropTypes.bool.isRequired,
  onActivityActionButtonPress: PropTypes.func,
  userActivity: PropTypes.shape({ id: PropTypes.number }),
};

export default ActivityPreview;
