import { setCurrentAnswers } from '@web/reducers/dialerReducer';
import useActivityScript from './useActivityScript';
import { useDispatch, useSelector } from 'react-redux';

function useAnswers(activity) {
  const dispatch = useDispatch();
  const { dialog, scriptId, scriptLoading } = useActivityScript(
    activity?.campaign?.id,
    activity?.id,
  );
  const { currentAnswers } = useSelector(state => state?.dialer);

  function setAnswers(newAnswers) {
    dispatch(setCurrentAnswers(newAnswers));
  }

  return {
    answers: currentAnswers,
    loading: scriptLoading,
    questions: dialog?.items || [],
    scriptId,
    setAnswers,
  };
}

export default useAnswers;
