import { getButtonText } from '../../utils/other';

const getStartActivityLink = ({
  activityId,
  activityType,
  campaignId,
  campaignSlug,
  userActivityId,
}) => {
  if (!activityType) return;

  switch (activityType) {
    case 'TestimonialActivity':
      // Takes user to testimonial page where they can upload photo/video and add a note.
      return `/activities/${userActivityId}/testimonial`;

    case 'DialerActivity':
      // Takes user to testimonial page where they can upload photo/video and add a note.
      return `/activities/${userActivityId}/phone-bank`;

    case 'TextAFriendActivity':
      // Opens up contacts page with select boxes, pick a template, edit the template, open 1 by 1 or bulk using mailto, manually confirm alert)
      // NOTE: on webapp we are emailing, not texting, so endpoint below will differ
      return `/activities/${userActivityId}/email_friends`;

    case 'TextAListActivity':
      // ListMessage - p2p texting action, you get the list of people, you hit send manually until all messages are sent.
      return `/activities/${userActivityId}/p2p`;

    // MTS - Added this as a bugfix on 4/13/22
    // Slack conversation: https://impactiveinc.slack.com/archives/C02PM6WH8N4/p1649879650553749
    case 'RegistrationActivity':
      return `/campaigns/${campaignSlug}/activities/${activityId}`;

    case 'IdVotersActivity':
      // IdVotersScreen -> takes user to the search screen, pick the person, fill out outreach report for the voter in VoterInfoScreen
      return {
        pathname: '/voters',
        state: {
          activityId,
          campaignId,
          userActivityId,
        },
      };

    default:
      // ShareActivity and WebActivity have their own handlers.
      return;
  }
};

const getButtonCopy = ({ activityType, completed, closed, t, button_text }) => {
  let buttonCopy = t('button.start');

  if (activityType) {
    if (closed) return t('button.closed');

    if (completed) return getButtonText(activityType, completed);

    if (button_text) {
      const i18nCode = `button.${button_text.toLowerCase().replace(' ', '_')}`; // "Visit Site" --> "button.visit_site"
      const translated_button_text = t(i18nCode);

      return translated_button_text === i18nCode ? button_text : translated_button_text;
    }
  }

  return buttonCopy;
};

export { getStartActivityLink, getButtonCopy };
