import { bool } from 'prop-types';
import React from 'react';

const Instagram = ({ isMobile, ...props }) => {
  return isMobile ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path fill="url(#b)" d="M0 0h40v40H0z" />
        <path fill="url(#c)" d="M0 0h40v40H0z" />
        <path
          fill="#fff"
          d="M20.001 4.375c-4.243 0-4.776.019-6.442.094-1.664.077-2.8.34-3.793.726a7.65 7.65 0 0 0-2.768 1.802 7.67 7.67 0 0 0-1.803 2.768c-.388.993-.651 2.13-.726 3.792-.074 1.667-.094 2.2-.094 6.443 0 4.244.019 4.775.094 6.441.077 1.664.34 2.8.726 3.793.4 1.027.934 1.9 1.802 2.768a7.652 7.652 0 0 0 2.767 1.803c.995.386 2.13.65 3.793.726 1.667.076 2.2.094 6.443.094 4.243 0 4.774-.018 6.44-.094 1.664-.076 2.801-.34 3.795-.726a7.643 7.643 0 0 0 2.766-1.803 7.67 7.67 0 0 0 1.804-2.768c.384-.994.648-2.13.726-3.792.075-1.667.094-2.198.094-6.442 0-4.243-.02-4.776-.094-6.443-.078-1.663-.342-2.798-.726-3.792A7.669 7.669 0 0 0 33 6.997a7.633 7.633 0 0 0-2.767-1.802c-.996-.386-2.132-.65-3.795-.726-1.667-.075-2.198-.094-6.442-.094H20ZM18.6 7.191H20c4.172 0 4.667.015 6.314.09 1.524.07 2.35.324 2.901.538.73.283 1.25.621 1.796 1.169a4.85 4.85 0 0 1 1.17 1.796c.213.55.468 1.377.537 2.9.075 1.648.091 2.143.091 6.313s-.016 4.664-.09 6.311c-.07 1.524-.325 2.35-.539 2.9a4.831 4.831 0 0 1-1.169 1.796 4.832 4.832 0 0 1-1.796 1.168c-.55.215-1.377.47-2.9.539-1.648.075-2.143.091-6.315.091-4.172 0-4.666-.016-6.313-.091-1.524-.07-2.35-.325-2.902-.539a4.84 4.84 0 0 1-1.797-1.168 4.844 4.844 0 0 1-1.169-1.796c-.214-.55-.469-1.377-.538-2.9-.075-1.648-.09-2.143-.09-6.315 0-4.173.015-4.665.09-6.312.07-1.524.324-2.35.538-2.901a4.84 4.84 0 0 1 1.17-1.797 4.847 4.847 0 0 1 1.796-1.17c.551-.214 1.378-.468 2.902-.538 1.441-.065 2-.085 4.912-.088v.004Zm9.742 2.594a1.875 1.875 0 1 0 0 3.751 1.875 1.875 0 0 0 0-3.751Zm-8.34 2.19a8.025 8.025 0 1 0 .001 16.05 8.025 8.025 0 0 0-.002-16.05Zm0 2.817a5.208 5.208 0 1 1 0 10.416 5.208 5.208 0 0 1 0-10.416Z"
        />
      </g>
      <rect width={39} height={39} x={0.5} y={0.5} stroke="#fff" strokeOpacity={0.2} rx={5.5} />
      <defs>
        <radialGradient
          id="b"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(0 -39.643 36.8711 0 10.625 43.08)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD5" />
          <stop offset={0.1} stopColor="#FD5" />
          <stop offset={0.5} stopColor="#FF543E" />
          <stop offset={1} stopColor="#C837AB" />
        </radialGradient>
        <radialGradient
          id="c"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(3.47805 17.37593 -71.62425 14.33664 -6.7 2.881)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3771C8" />
          <stop offset={0.128} stopColor="#3771C8" />
          <stop offset={1} stopColor="#60F" stopOpacity={0} />
        </radialGradient>
        <clipPath id="a">
          <rect width={40} height={40} fill="#fff" rx={6} />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63a5.876 5.876 0 0 0-2.126 1.384A5.855 5.855 0 0 0 .63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.301.8.774 1.526 1.384 2.126.6.61 1.325 1.083 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558a5.898 5.898 0 0 0 2.126-1.384 5.86 5.86 0 0 0 1.384-2.126c.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913a5.89 5.89 0 0 0-1.384-2.126A5.847 5.847 0 0 0 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0Zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85 0 3.204-.015 3.585-.074 4.85-.061 1.17-.256 1.805-.421 2.227a3.81 3.81 0 0 1-.899 1.382c-.388.397-.86.703-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421a3.716 3.716 0 0 1-1.379-.899 3.644 3.644 0 0 1-.9-1.38c-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03Zm0 3.678a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324ZM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4Zm7.846-10.405a1.441 1.441 0 0 1-2.88 0 1.44 1.44 0 1 1 2.88 0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Instagram.propTypes = {
  isMobile: bool,
};

Instagram.defaultProps = {};

export default Instagram;
