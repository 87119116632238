import { SearchOutlined } from '@ant-design/icons';

import { Input, List } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import { getLocalizedOnboardingData } from 'web/utils/other';
import { usePaginatedApi } from '../../hooks/usePaginatedApi';
import Container from '../Container';
import Loading from '../common/Loading';
import Option from './Option';

const SearchContainer = styled.div``;

const SuggestionMessage = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.semibold};
  color: black;
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 8px;
  border: 2px dashed #a9a9a9;
`;

const SearchInput = styled(Input)`
  input {
    padding-left: 4px !important;
  }
`;

/**
 * Search component used for fetching and displaying infinite paginated list of campaigns or users.
 *
 * @param apiEndpoint - The endpoint that will be called on search
 * @param resourceId - The resource id (optional)
 * @param additionalAPIParams - Additional parameters that will be passed to api call
 * @param searchable - indicates whether search input should be shown
 * @param searchPlaceholder - The placeholder text for search input
 * @param onSearchCallback - Function will be called whenever query changes
 * @param noEmptyQuerying [default: false] - Flag which determines whether to make initial empty query or not
 */
const Search = ({
  additionalAPIParams = {},
  addRankIndex,
  apiEndpoint,
  resourceId,
  searchable,
  searchPlaceholder,
  onSearchCallback,
  noEmptyQuerying,
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState(null);
  const [shouldLoadItems, setShouldLoadItems] = useState(false);
  const [
    hasMore,
    initialLoading,
    items,
    loadMore,
    loading,
    // eslint-disable-next-line
    _setItems,
    resetPagination,
  ] = usePaginatedApi(apiEndpoint, resourceId, {
    ...additionalAPIParams,
    query,
  });

  // delay querying backend while user is still typing
  const debouncedHandleSearch = debounce(e => {
    const { value } = e.target;
    setQuery(value);
  }, 200);

  const handleSearch = e => {
    e.persist();
    debouncedHandleSearch(e);
  };

  // Reset pagination when query or external additionalAPIParams change
  useEffect(() => {
    // Callback function to indicate query to parent component
    if (onSearchCallback) onSearchCallback(query);

    // Don't make empty query if flag prop was passed and query, only remove previous results
    if (noEmptyQuerying && !query) {
      resetPagination();
      return;
    }

    resetPagination();
    setShouldLoadItems(true);
    // eslint-disable-next-line
  }, [query, additionalAPIParams]);

  // Load more results when the flag to do so is set
  useEffect(() => {
    if (shouldLoadItems) {
      loadMore();
      setShouldLoadItems(false);
    }
  }, [loadMore, shouldLoadItems]);

  const showResultsList = items && items.length > 0;
  const showNoResultsMessage = searchable && !initialLoading && (!items || !items.length);

  return (
    <>
      {searchable && (
        <SearchContainer>
          <SearchInput
            name="outvote-query"
            prefix={<SearchOutlined />}
            onChange={handleSearch}
            placeholder={searchPlaceholder || t('search.default_placeholder')}
            size="large"
            allowClear
          />
        </SearchContainer>
      )}

      {loading && <Loading centered />}

      {showResultsList && (
        <Container>
          <InfiniteScroll
            hasMore={!loading && hasMore}
            initialLoad={false}
            loadMore={loadMore}
            pageStart={0}
            threshold={0}
          >
            <List
              locale={{ emptyText: <></> }}
              dataSource={items.map(campaign => getLocalizedOnboardingData(campaign))}
              renderItem={(option, index) => (
                <Option option={option} index={index + 1} addRankIndex={addRankIndex} />
              )}
            />
          </InfiniteScroll>
        </Container>
      )}

      {showNoResultsMessage && (
        <Container>
          <SuggestionMessage>
            {t('search.no_results')} "<em>{query}</em>"
          </SuggestionMessage>
        </Container>
      )}
    </>
  );
};

Search.propTypes = {
  additionalAPIParams: PropTypes.shape({}),
  addRankIndex: PropTypes.bool,
  apiEndpoint: PropTypes.func.isRequired,
  noEmptyQuerying: PropTypes.bool,
  onSearchCallback: PropTypes.func,
  resourceId: PropTypes.number,
  searchable: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  searchWithinZip: PropTypes.func,
};

Search.defaultProps = {
  additionalAPIParams: {},
  addRankIndex: false,
  noEmptyQuerying: false,
  onSearchCallback: null,
  resourceId: null,
  searchable: true,
  searchPlaceholder: '',
};

export default Search;
