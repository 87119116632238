import styled from 'styled-components';

import { media } from '@web/styles/theme';

export const SectionTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  color: ${({ theme }) => theme.fonts.black};
  font-size: 16px;
  margin-bottom: 10px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3.5rem 18% 0;
  max-width: 1700px;

  ${media.nxl} {
    padding: 3.5rem 5% 0;
  }
`;

export const FieldsWrapper = styled.div`
  display: grid;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: ${({ $twoColumns }) => ($twoColumns ? '1fr 1fr' : '1fr 1fr 1fr')};
  gap: 20px;
  ${media.nxl} {
    grid-template-columns: 1fr;
  }
`;

export const StickyFooter = styled.div`
  width: 100%;
  position: sticky;
  background: ${({ theme }) => theme.colors.white};
  bottom: 0;
  padding: 1rem 0;
`;
