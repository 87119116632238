import PropTypes from 'prop-types';
import styled from 'styled-components';

const CampaignProfileImage = styled.img`
  border: 6px solid
    ${({ $isMobile, theme }) => ($isMobile ? theme.colors.white : theme.colors.black)};
  border-radius: ${({ size }) => size / 2}px;
  height: ${({ size }) => size}px;
  object-fit: cover;
  width: ${({ size }) => size}px;
  z-index: 1;
`;

CampaignProfileImage.propTypes = {
  $isMobile: PropTypes.bool,
  size: PropTypes.number.isRequired,
};

CampaignProfileImage.defaultProps = {
  $isMobile: false,
};

export default CampaignProfileImage;
