import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import SweepstakeCard from '@web/components/FanOut/components/SweepstakeCard';
import { fanOut } from '@web/services/api';
import { toObject, usePaginatedApi } from '@web/hooks/usePaginatedApi';
import Loading from '@web/components/common/Loading';
import Layout from '@web/components/VoterRegistration/newComponents/Layout';
import Modal from '@web/components/VoterRegistration/newComponents/Modal';
import Share from '@web/components/VoterRegistration/flows/HelpOthers/Share';
import useFlows from '@web/components/VoterRegistration/hooks/useFlows';
import useIsMobile from '@web/components/VoterRegistration/hooks/useIsMobile';
import { useTrackFanOut } from '@web/components/FanOut/hooks/useFanOutAmplitude';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.whiteSmoke};
  width: 100%;
  display: flex;
  flex: 1;
  padding: 40px 48px;
  flex-direction: column;

  ${smallBreakpoint} {
    padding: 20px;
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.5rem; /* 116.667% */

  ${smallBreakpoint} {
    font-size: 2rem;
    line-height: 2.25rem; /* 112.5% */
  }
`;

const SweepstakesContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  ${smallBreakpoint} {
    margin-top: 20px;
  }
`;

const LoadingBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const AllSweepstakes = ({ team, activity, referrer }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [shareLink, setShareLink] = React.useState('');
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const { ...flowProps } = useFlows();

  const phone = useMemo(() => contactFormResult?.phone, [contactFormResult?.phone]);
  const campaign_id = useMemo(() => activity.campaign.id, [activity.campaign.id]);

  useTrackFanOut({ activity, name: 'FAN_OUT_ALL_SWEEPSTAKES', team });

  const {
    hasMore,
    items: sweepstakesState,
    loadMore,
    loading,
    initialLoading,
  } = toObject(
    usePaginatedApi(fanOut.getAllSweepstakes, null, {
      campaign_id,
      phone,
    }),
  );

  useEffect(() => {
    if (initialLoading && !loading) {
      loadMore();
    }
  }, [initialLoading, loadMore, loading]);

  const closeHandler = useCallback(() => setShareLink(''), []);

  return (
    <Wrapper>
      <Title>{t('fan_out.sweepstakes.title')}</Title>
      <Modal
        closeModal={closeHandler}
        modalOpen={!!shareLink}
        secondaryCloseButton={isMobile}
        isFanOut
      >
        <Layout>
          <Share
            activity={activity}
            team={team}
            handleClose={closeHandler}
            isFanOut
            referrer={referrer}
            shareLink={shareLink}
            {...flowProps}
          />
        </Layout>
      </Modal>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        threshold={0}
        loadMore={loadMore}
        hasMore={!loading && hasMore}
      >
        <SweepstakesContainer>
          {sweepstakesState.map(sweepstake => (
            <SweepstakeCard
              key={sweepstake.id}
              sweepstake={sweepstake}
              team={team}
              setShareLink={setShareLink}
              activity={activity}
            />
          ))}
          {loading && (
            <LoadingBox>
              <Loading />
            </LoadingBox>
          )}
        </SweepstakesContainer>
      </InfiniteScroll>
    </Wrapper>
  );
};

AllSweepstakes.propTypes = {
  activity: PropTypes.object.isRequired,
  referrer: PropTypes.object,
  team: PropTypes.object,
};

AllSweepstakes.defaultProps = {
  team: null,
};

export default AllSweepstakes;
