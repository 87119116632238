export const excuseVBMStates = [
  'AL',
  'AR',
  'CT',
  'DE',
  'IN',
  'KY',
  'LA',
  'MS',
  'MO',
  'NH',
  'SC',
  'TN',
  'TX',
  'WV',
];

export const automaticVBMStates = ['WA', 'OR', 'CA', 'UT', 'NV', 'CO', 'HI', 'VT', 'DC'];
