import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import withPermit from 'web/hocs/withPermit';
import { useDocumentTitle } from 'web/hooks/useDocumentTitle';
import { track } from 'web/services/analytics';
import { search } from 'web/services/api';
import { media } from 'web/styles/theme';

import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import CategoryView from '../CategoryView/CategoryView';
import Search from '../Search';
import MaxWidthContainer from '../common/MaxWidthContainer';
import Title from '../common/Title';
import ZipProvider from '../common/ZipProvider';
import JoinCode from './JoinCode';

/**
 * Explore page shows campaign users if user is locked into
 * a certain campaign, otherwise it shows campaigns
 */

const JoinCodeWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;

  ${media.sm} {
    justify-content: center;
  }
`;

const PaddedTitle = styled(Title)`
  display: inline;
  padding-right: 12px;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  margin-top: 1rem;
`;

const Explore = () => {
  const { t } = useTranslation();
  const title = t('titles.explore');
  useDocumentTitle(title);

  const [query, setQuery] = useState();
  // Search results can be narrowed down by providing ZIP code
  const [searchWithinZip, setSearchWithinZip] = useState();

  // Track the page view
  useEffect(() => {
    track('VIEW_SEARCH_SCREEN');
  }, []);

  const additionalAPIParams = useMemo(() => {
    return searchWithinZip ? { zip_code: searchWithinZip } : undefined;
  }, [searchWithinZip]);

  return (
    <>
      <AppHeader title={title} />
      <AppContainer>
        <MaxWidthContainer>
          <JoinCodeWrapper>
            <JoinCode />
          </JoinCodeWrapper>
          <div>
            <PaddedTitle>{t('explore.title')}</PaddedTitle>
            <ZipProvider zip={searchWithinZip} setZip={setSearchWithinZip} />
          </div>
          <Description>{t('explore.page_description')}</Description>
          <Search
            apiEndpoint={search.searchCampaignsAndActivities}
            additionalAPIParams={additionalAPIParams}
            searchable
            searchPlaceholder={t('explore.search_placeholder')}
            onSearchCallback={setQuery}
            noEmptyQuerying={!searchWithinZip}
          />

          {!query && !searchWithinZip && <CategoryView setZip={setSearchWithinZip} />}
        </MaxWidthContainer>
      </AppContainer>
    </>
  );
};

Explore.propTypes = {};

const hasPermission = ({ isLockedToCampaign }) => !isLockedToCampaign;
export default withPermit('titles.explore', hasPermission)(Explore);
