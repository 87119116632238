import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { track } from '../../../../services/analytics';
import { users } from '../../../../services/api';
import ImpactiveButton from '../../../ImpactiveButton';
import ScriptMessageInput from './ScriptMessageInput';
import buildEmail from './buildEmail';
import {
  Top,
  TopLeft,
  Title,
  EmailInfo,
  EmailInfoLabel,
  TopRight,
  Step,
  SkipToNextLink,
  Section,
  SectionTitle,
  ActionButtons,
} from './styles';

// Helper component which is rendered when contacts are selected, and individual emails are ready to be sent
const IndividualSendingScreen = ({
  contactList,
  scripts,
  userActivity,
  googleUser,
  skippedInAppEmail,
  onCompleted,
  onShowActivityCompletedModal,
}) => {
  const { t } = useTranslation();
  const [contactIndex, setContactIndex] = useState(0);
  const contact = contactList[contactIndex] || null; // shorthand for accessing current contact
  const [askedOnce, setAskedOnce] = useState(false);
  const [messageBody, setMessageBody] = useState('');
  const [scriptId, setScriptId] = useState(null);

  const mailToLink = useMemo(() => {
    if (!contact || !contact.email) {
      return '';
    }

    const subject = encodeURI(t('common.new_outvote_message'));
    const encodedBody = encodeURIComponent(messageBody);
    return `mailto:${contact.email}?subject=${subject}&body=${encodedBody}`;
  }, [contact, messageBody, t]);

  React.useEffect(() => {
    track('VIEW_SEND_MESSAGE_SCREEN');
  }, []);

  const handleSkipToNext = useCallback(() => {
    setContactIndex(current => current + 1);
  }, []);

  // Callback when send email to current contact (if any, and updates indexes for the next contact)
  const handleSendMessage = ({ manuallySending = true }) => {
    // Store the sent message
    users.updateMessageSent(contact.id, {
      message: {
        activity_id: userActivity.activity.id,
        activity_script_id: scriptId,
        body: messageBody, //need to interpolate too?
        lock: false,
        rate_limit: false,
        type: 'EmailMessage',
        user_activity_id: userActivity.id,
        visible: true,
      },
    });

    setTimeout(() => {
      // handling next contact needs to happen in setTimeout fn, otherwise the link is changed before it's open.
      setContactIndex(contactIndex => contactIndex + 1);
    }, 0);

    // prompt user to mark activity as completed. googleUser will be present when
    // a user authorizes Impactive "send on behalf of" permission and we shouldn't
    // show the modal in this case.
    if (!userActivity.completed && !askedOnce && manuallySending) {
      onShowActivityCompletedModal();
      setAskedOnce(true);
    }
  };

  function handleSendEmail() {
    const email = buildEmail({
      from: `${googleUser.name} <${googleUser.email}>`,
      message: messageBody,
      subject: 'New Impactive Message',
      to: contact.email,
    });
    window.gapi.client.gmail.users.messages
      .send({
        resource: {
          raw: email,
        },
        userId: 'me',
      })
      .then(res => {
        handleSendMessage({ manuallySending: false });
      })
      .catch(err => console.error({ err }));
  }

  const completed = contactIndex >= contactList.length;

  useEffect(() => {
    if (completed) {
      onCompleted();
    }
  }, [completed, onCompleted]);

  if (!contact) {
    return null;
  }

  const hasNoEmail = !contact.email;
  const contactName = `${contact.first_name || ''} ${contact.last_name || ''}`;

  return (
    <>
      <Top>
        <TopLeft>
          <Title>{t('activity.f2f.emailing.title_with_name', { name: contactName })}</Title>
          {googleUser && googleUser.email && (
            <EmailInfo>
              <EmailInfoLabel>{t('activity.f2f.emailing.from')}:</EmailInfoLabel>
              {googleUser.email}
            </EmailInfo>
          )}
          {!hasNoEmail && (
            <EmailInfo>
              <EmailInfoLabel>{t('activity.f2f.emailing.to')}:</EmailInfoLabel>
              {contact.email}
            </EmailInfo>
          )}
          {hasNoEmail && <p className="text-error">{t('activity.f2f.emailing.email_missing')}</p>}
        </TopLeft>
        <TopRight>
          <Step>
            {t('activity.f2f.emailing.email_counter', {
              count: contactList.length,
              index: contactIndex + 1,
            })}
          </Step>
          <SkipToNextLink onClick={handleSkipToNext}>
            {t('activity.f2f.emailing.skip_to_next_person')}
          </SkipToNextLink>
        </TopRight>
      </Top>

      <Section>
        <ScriptMessageInput
          disabled={hasNoEmail}
          scripts={scripts}
          message={messageBody}
          setMessage={setMessageBody}
          setScriptId={setScriptId}
          userActivity={userActivity}
          contact={contact}
        />
      </Section>

      <Section>
        <SectionTitle>{t('activity.f2f.emailing.ready_to_send')}</SectionTitle>
        <div>{t('activity.f2f.emailing.actions_description')}</div>
        <ActionButtons>
          {!skippedInAppEmail && (
            <ImpactiveButton
              size="large"
              disabled={hasNoEmail || !messageBody}
              onClick={handleSendEmail}
            >
              {t('activity.f2f.emailing.send_with_outvote')}
            </ImpactiveButton>
          )}
          <a target="_blank" rel="noreferrer" href={mailToLink}>
            <ImpactiveButton
              size="large"
              secondary
              disabled={hasNoEmail || !messageBody}
              onClick={handleSendMessage}
            >
              {t('activity.f2f.emailing.open_in_mail_app')}
            </ImpactiveButton>
          </a>
        </ActionButtons>
      </Section>
    </>
  );
};

IndividualSendingScreen.propTypes = {
  contactList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  googleUser: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  onCompleted: PropTypes.func.isRequired,
  onShowActivityCompletedModal: PropTypes.func.isRequired,
  scripts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skippedInAppEmail: PropTypes.bool,
  userActivity: PropTypes.shape({
    activity: PropTypes.shape({
      campaign: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
    completed: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default IndividualSendingScreen;
