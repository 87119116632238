import * as React from 'react';
import PropTypes from 'prop-types';

function Star({ fill, ...rest }) {
  return (
    <svg viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M6.47559 0.358692C6.63124 -0.0890883 7.2645 -0.0890864 7.42015 0.358694L8.80381 4.33931L13.0172 4.42517C13.4911 4.43483 13.6868 5.0371 13.3091 5.3235L9.95084 7.86952L11.1712 11.9032C11.3085 12.3569 10.7961 12.7292 10.407 12.4584L6.94787 10.0513L3.48872 12.4584C3.0996 12.7292 2.58728 12.3569 2.72455 11.9032L3.94489 7.86952L0.586679 5.3235C0.208913 5.0371 0.404605 4.43483 0.878567 4.42517L5.09193 4.33931L6.47559 0.358692Z"
        fill={fill}
      />
    </svg>
  );
}

Star.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
};

Star.defaultProps = {
  fill: '#52C41A',
  height: 13,
};

export default Star;
