import React, { useState } from 'react';

// This hook returns the number of items that can be shown on one slide, depending on the size of the screen.
function useElementsPerSlide(templates, ref, elementWidth = 0) {
  const [scripts, setScripts] = useState([]);
  const [elementsPerSlide, setElementsPerSlide] = useState(1);
  const [wrapperWidth, setWrapperWidth] = useState();

  // Get slider wrapper width
  const handleResize = React.useCallback(() => {
    setWrapperWidth(ref?.current?.clientWidth ?? elementWidth);
  }, [elementWidth, ref]);

  // Handle screen resize
  React.useEffect(() => {
    // Set initial width
    setWrapperWidth(ref?.current?.clientWidth ?? elementWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [elementWidth, handleResize, ref]);

  React.useEffect(() => {
    // Get the number of items on a slide
    const proportion = Math.floor(wrapperWidth / (elementWidth ?? 257));

    // We need this because if the number of elements is greater than 1, we have to display 2 rows of elements
    const elementsPerSlide = proportion === 1 ? 1 : proportion * 2;

    // Formatting an array from an array with all scripts to an array with arrays with the number of scripts that can be displayed on a slide
    const newArr = templates.reduce(function (result, value, index, array) {
      if (index % elementsPerSlide === 0) result.push(array.slice(index, index + elementsPerSlide));
      return result;
    }, []);

    setScripts(newArr);
    setElementsPerSlide(elementsPerSlide);
  }, [elementWidth, templates, wrapperWidth]);

  return { elementsPerSlide, scripts };
}

export default useElementsPerSlide;
