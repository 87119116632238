import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FriendLookUpForm from '@web/components/FanOut/components/FriendLookUpForm';
import { Description, Title } from '@web/components/VoterRegistration/newComponents/TextStyles';
import useContactForm from '@web/components/VoterRegistration/hooks/useContactForm';

const FriendLookUp = props => {
  const { t } = useTranslation();
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const { initialValues, schemas } = useContactForm(contactFormResult);

  return (
    <>
      <Title>{t('fan_out.friend_look_up.title')}</Title>
      <Description>{t('fan_out.friend_look_up.description')}</Description>
      <FriendLookUpForm
        initialValues={initialValues.friendLookUpForm}
        schema={schemas.friendLookUpForm}
        {...props}
      />
    </>
  );
};

FriendLookUp.propTypes = {
  activity: PropTypes.object.isRequired,
  setSelectedFlow: PropTypes.func.isRequired,
  team: PropTypes.object,
};

FriendLookUp.defaultProps = {};

export default FriendLookUp;
