import * as React from 'react';
import { user } from '@web/services/api';
import sendError from '@web/utils/sendError';

function useFetchActivity(userActivityId) {
  const [activity, setActivity] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (userActivityId) {
      setLoading(true);
      user
        .getUserActivity(userActivityId)
        .then(({ data }) => {
          setActivity(data.data.activity);
        })
        .catch(err => {
          sendError('Error fetching dialer activity', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userActivityId]);

  return { activity, loading };
}

export default useFetchActivity;
