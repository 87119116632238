import * as React from 'react';

function SecondPlaceIcon() {
  return (
    <svg
      width="122"
      height="116"
      viewBox="0 0 122 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.7481 4.79784L88.1116 30.9464L88.1269 30.9616L114.275 57.3251C114.616 57.6682 114.616 58.2215 114.275 58.5645L88.1269 84.9281L88.1116 84.9433L61.7481 111.092C61.4051 111.432 60.8518 111.432 60.5087 111.092L34.1452 84.9433L34.13 84.9281L7.98143 58.5645C7.64116 58.2215 7.64115 57.6682 7.98143 57.3251L34.1376 30.954L60.5087 4.79783C60.8518 4.45756 61.4051 4.45756 61.7481 4.79784Z"
        fill="url(#paint0_radial_428_2017)"
        stroke="white"
        stroke-width="5.28"
      />
      <path
        d="M61.1586 72.4575L61.053 72.2463C61.4226 72.0879 62.7954 71.3487 63.4818 70.8207C69.0786 67.0191 74.9394 61.8447 74.9394 54.8223C74.9394 47.8527 69.8706 43.1535 62.2146 43.1535C54.5586 43.1535 49.5426 47.4303 49.5426 53.6079C49.5426 55.1919 49.8066 56.0367 49.965 56.4591L57.1458 55.1391C57.0402 54.8751 56.9874 54.3999 56.9874 53.9247C56.9874 51.3375 58.9938 49.6479 62.0034 49.6479C65.3298 49.6479 67.3362 51.8127 67.3362 55.0335C67.3362 62.0031 58.0434 67.1247 49.8066 73.3551V78.3711H75.6258V72.3519H63.8514C62.5314 72.3519 61.4754 72.4047 61.1586 72.4575Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_428_2017"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.5954 22.2025) rotate(57.6927) scale(171.576 171.576)"
        >
          <stop stop-color="#869299" />
          <stop offset="1" stop-color="#BED1DC" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default SecondPlaceIcon;
