import { LeftOutlined } from '@ant-design/icons';
import { Input, List } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import defaultCampaignProfile from '../../../../../assets/images/web/default-campaign-profile.png';
import { toObject, usePaginatedApi } from '../../../hooks/usePaginatedApi';
import { users } from '../../../services/api';
import { media } from '../../../styles/theme';
import { replaceDefaultCampaignImage } from '@web/utils/string';

import ContactListItem from '../../Contacts/ContactListItem';
import ImpactiveButton from '../../ImpactiveButton';
import FilterButton from '../../common/FilterButton';
import Loading from '../../common/Loading';

const Wrapper = styled.div`
  padding-bottom: 120px;
`;

const BackLink = styled.a`
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  cursor: pointer;
  margin-bottom: 20px;
`;

const CampaignHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 38px;
`;

const CampaignAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 15px;
  flex: none;
`;

const CampaignName = styled.h2`
  font-size: 28px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  letter-spacing: 0.015em;
  margin: 0;
`;

const Title = styled.h3`
  font-size: 23px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  letter-spacing: 0.015em;
  margin-bottom: 8px;
`;

const Section = styled.div`
  margin-top: 30px;
`;

const SectionTitle = styled.h4`
  font-size: 17px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

const Filters = styled.div`
  margin: 0 -7px;

  > * {
    margin: 0.3em 0.5em;
  }
`;

const SearchInput = styled(Input)`
  && {
    font-size: 16px;
    padding: 5px 1em;
  }
`;

const SelectAllLabel = styled.label`
  display: inline-block;
  margin: 0 0 20px 24px;
  font-size: 16px;
  cursor: pointer;

  input {
    margin-right: 16px;
  }
`;

const SuggestionMessage = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.semibold};
  color: black;
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 8px;
  border: 2px dashed #a9a9a9;
`;

const FixedBottom = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 30px;
  padding: 0 16px;
  ${media.ns} {
    margin-left: 240px;
  }
`;

const ActionBar = styled.div`
  max-width: 820px;
  width: 100%;
  margin: 0 auto;
  background: #e6f7ff;
  border-radius: 7px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
  padding: 20px 25px 20px 33px;
  line-height: 1.35em;

  @media screen and (min-width: 800px) {
    display: flex;
    align-items: center;
  }
`;

const ActionButtons = styled.div`
  flex: none;
  margin-top: 16px;

  @media screen and (min-width: 800px) {
    margin-top: 0;
    margin-left: 30px;
  }
`;

const ActionButtonsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4px -6px;

  > * {
    flex: none;
    width: 196px;
    margin: 4px 6px;
  }

  @media screen and (min-width: 800px) {
    flex-direction: column;
  }

  ${media.xl} {
    flex-direction: row;
  }
`;

const ContactSelection = ({
  userActivity = {},
  selectedContacts,
  setSelectedContacts,
  onGoBack,
  onSendIndividual,
  onSendBulk,
}) => {
  const { t } = useTranslation();
  const {
    id: userActivityId,
    activity: { campaign },
    activity,
  } = userActivity;

  // Contact filters
  const [contactsQuery, setContactsQuery] = useState(null);
  const [voterFrequency, setVoterFrequency] = useState(activity?.autofilters?.voter_frequency);
  const [unmatched, setUnmatched] = useState(undefined);
  const [inDistrict, setInDistrict] = useState(activity?.autofilters?.in_district);
  const [inSwingDistrict, setInSwingDistrict] = useState(undefined);
  const [isSuggested] = useState(undefined);
  const [partyAffiliation, setPartyAffiliation] = useState(activity?.autofilters?.party);

  // Paginator for contacts
  const contactsPaginator = toObject(
    usePaginatedApi(users.getContacts, null, {
      in_district: inDistrict || undefined,
      in_swing_district: inSwingDistrict || undefined,
      is_suggested: isSuggested || undefined,
      party_affiliation: partyAffiliation || undefined,
      search: contactsQuery,
      unmatched: unmatched || undefined,
      user_activity_id: userActivityId,
      voter_frequency: voterFrequency,
    }),
  );

  const allSelected = selectedContacts.length === contactsPaginator.items.length;

  const allFiltersUnchecked = () => {
    const filters = [
      contactsQuery,
      voterFrequency,
      unmatched,
      inDistrict,
      inSwingDistrict,
      isSuggested,
      partyAffiliation,
    ];
    return !filters.some(query => query);
  };

  // Infinite scrolling for contacts
  const [shouldLoadContacts, setShouldLoadContacts] = useState(false);

  // contact handling helper functions
  const debouncedHandleSearch = debounce(e => {
    const { value } = e.target;
    setContactsQuery(value);
  }, 200);

  const handleSearch = e => {
    e.persist();
    debouncedHandleSearch(e); // Debounced contact search - delay querying backend while user is still typing
  };

  const isContactSelected = contactId => {
    return selectedContacts.some(contact => contact.id === contactId);
  };

  const toggleContactSelection = contactId => {
    const newSelection = isContactSelected(contactId)
      ? selectedContacts.filter(contact => contact.id !== contactId)
      : selectedContacts.concat(contactsPaginator.items.find(contact => contact.id === contactId));

    setSelectedContacts(newSelection);
  };

  const toggleAllContactsSelection = () => {
    // if the selected list was not empty, deselect all elements, otherwise select all
    const newSelection = !allSelected ? contactsPaginator.items : [];
    setSelectedContacts(newSelection);
  };

  // Handling filters
  const handleFrequencyChange = frequency => {
    // allow toggling filter off if the same button is pressed again
    const toFrequency = frequency !== voterFrequency ? frequency : undefined;
    setVoterFrequency(toFrequency);
  };

  const handlePartyChange = party => {
    // allow toggling filter off if the same button is pressed again
    const toParty = partyAffiliation !== party ? party : undefined;
    setPartyAffiliation(toParty);
  };

  // Reset pagination on query change or filters change
  useEffect(() => {
    contactsPaginator.resetPagination();
    setShouldLoadContacts(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contactsQuery,
    voterFrequency,
    unmatched,
    inDistrict,
    inSwingDistrict,
    isSuggested,
    partyAffiliation,
  ]);

  // Load more contacts if the flag is set
  useEffect(() => {
    if (shouldLoadContacts) {
      contactsPaginator.loadMore();
      setShouldLoadContacts(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadContacts]);

  // If contacts are still loading or there are contacts, show them.
  const showContacts =
    contactsPaginator.initialLoading ||
    (contactsPaginator.items && contactsPaginator.items.length > 0);

  // If there aren't contacts to show but some filters are checked, suggest clearing filters.
  const showTryClearingFilters =
    contactsPaginator.items && contactsPaginator.items.length === 0 && !allFiltersUnchecked();

  // If we have gotten to this point, there are no contacts to show,
  // so if all filters are unchecked, link to the sync contacts page.
  const showSuggestSyncContacts = allFiltersUnchecked();

  return (
    <Wrapper>
      <BackLink className="d-flex flex-row align-items-center" onClick={onGoBack}>
        <LeftOutlined />
        <div className="ml-2">{t('common.back')}</div>
      </BackLink>
      <CampaignHeader>
        <CampaignAvatar
          src={replaceDefaultCampaignImage(campaign.url_profile_img) || defaultCampaignProfile}
          alt={campaign.name}
        />
        <CampaignName>{campaign.name}</CampaignName>
      </CampaignHeader>
      <Title>{t('activity.f2f.contact_selection.title')}</Title>
      <div>{t('activity.f2f.contact_selection.description')}</div>
      <Section>
        <SectionTitle>{t('filters.filter_by')}:</SectionTitle>
        <Filters className="d-flex flex-row flex-wrap">
          {!userActivity.activity.campaign.no_labels && (
            <FilterButton
              active={partyAffiliation === 'Democrat'}
              icon="democrat"
              onClick={() => handlePartyChange('Democrat')}
            >
              {t('filters.democrat')}
            </FilterButton>
          )}
          {!userActivity.activity.campaign.no_labels && (
            <FilterButton
              active={partyAffiliation === 'Republican'}
              icon="republican"
              onClick={() => handlePartyChange('Republican')}
            >
              {t('filters.republican')}
            </FilterButton>
          )}
          <FilterButton
            active={inDistrict}
            icon="inDistrict"
            onClick={() => setInDistrict(!inDistrict)}
          >
            {t('filters.in_district')}
          </FilterButton>
          <FilterButton
            active={inSwingDistrict}
            icon="inSwing"
            onClick={() => setInSwingDistrict(!inSwingDistrict)}
          >
            {t('filters.in_swing')}
          </FilterButton>
          <FilterButton
            active={voterFrequency === 'super'}
            icon="super"
            onClick={() => handleFrequencyChange('super')}
          >
            {t('filters.super_voter')}
          </FilterButton>
          <FilterButton
            active={voterFrequency === 'frequent'}
            icon="frequent"
            onClick={() => handleFrequencyChange('frequent')}
          >
            {t('filters.frequent')}
          </FilterButton>
          <FilterButton
            active={voterFrequency === 'infrequent'}
            icon="infrequent"
            onClick={() => handleFrequencyChange('infrequent')}
          >
            {t('filters.infrequent')}
          </FilterButton>
          <FilterButton
            active={unmatched}
            icon="unmatched"
            onClick={() => setUnmatched(!unmatched)}
          >
            {t('filters.unmatched')}
          </FilterButton>
        </Filters>
      </Section>
      <Section>
        <SectionTitle>{t('activity.f2f.contact_selection.search_gmail_contacts')}:</SectionTitle>
        <SearchInput
          placeholder={t('activity.f2f.contact_selection.type_name')}
          onChange={handleSearch}
        />
      </Section>
      {showContacts && (
        <Section>
          {contactsPaginator.items.length > 0 && (
            <SelectAllLabel>
              <input type="checkbox" checked={allSelected} onChange={toggleAllContactsSelection} />
              {t('activity.f2f.contact_selection.select_all_contacts')}
            </SelectAllLabel>
          )}
          <div>
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              threshold={0}
              loadMore={contactsPaginator.loadMore}
              hasMore={!contactsPaginator.loading && contactsPaginator.hasMore}
              useWindow
            >
              <List>
                {contactsPaginator.items.map(contact => (
                  <ContactListItem
                    key={contact.id}
                    noLabels={userActivity.activity.campaign.no_labels}
                    contact={contact}
                    withSelection
                    selected={isContactSelected(contact.id)}
                    onClickHandler={() => toggleContactSelection(contact.id)}
                  />
                ))}
              </List>
              {contactsPaginator.loading && <Loading centered />}
            </InfiniteScroll>
          </div>
        </Section>
      )}
      {!showContacts && showTryClearingFilters && (
        <SuggestionMessage>
          {t('activity.f2f.contact_selection.try_clearing_filters')}
        </SuggestionMessage>
      )}
      {!showContacts && !showTryClearingFilters && showSuggestSyncContacts && (
        <SuggestionMessage>
          <Link to={'/settings'}>{t('activity.f2f.contact_selection.suggest_sync_contacts')}</Link>
        </SuggestionMessage>
      )}
      <FixedBottom>
        <ActionBar>
          <div>{t('activity.f2f.contact_selection.action_description')}</div>
          <ActionButtons>
            <ActionButtonsInner>
              <ImpactiveButton
                size="large"
                disabled={!selectedContacts.length}
                onClick={onSendIndividual}
              >
                {t('activity.f2f.contact_selection.email_separately')}
              </ImpactiveButton>
              <ImpactiveButton
                size="large"
                disabled={selectedContacts.length < 2}
                onClick={onSendBulk}
              >
                {t('activity.f2f.contact_selection.email_everyone')}
              </ImpactiveButton>
            </ActionButtonsInner>
          </ActionButtons>
        </ActionBar>
      </FixedBottom>
    </Wrapper>
  );
};

ContactSelection.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  onSendBulk: PropTypes.func.isRequired,
  onSendIndividual: PropTypes.func.isRequired,
  selectedContacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedContacts: PropTypes.func.isRequired,
  userActivity: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default ContactSelection;
