import React from 'react';

const Location = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M7.44514 14.9093C3.35863 8.98505 2.6001 8.37705 2.6001 6.1998C2.6001 3.21746 5.01775 0.799805 8.0001 0.799805C10.9824 0.799805 13.4001 3.21746 13.4001 6.1998C13.4001 8.37705 12.6416 8.98505 8.55506 14.9093C8.28689 15.2967 7.71328 15.2966 7.44514 14.9093ZM8.0001 8.4498C9.24274 8.4498 10.2501 7.44245 10.2501 6.1998C10.2501 4.95716 9.24274 3.9498 8.0001 3.9498C6.75745 3.9498 5.7501 4.95716 5.7501 6.1998C5.7501 7.44245 6.75745 8.4498 8.0001 8.4498Z"
        fill="#23293E"
        fillOpacity={0.3}
      />
    </svg>
  );
};

Location.propTypes = {};

Location.defaultProps = {};

export default Location;
