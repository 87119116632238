import { List } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';
import { media } from '../../styles/theme';
import ActivityPreview from '../Activity/ActivityPreview';
import ActivityPreviewModal from '../Activity/ActivityPreviewModal';
import Loading from '../common/Loading';
import ListEmpty from './ListEmpty';

const StyledList = styled(List)`
  .ant-list-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: -12px;
  }
`;

const ActivityItem = styled(List.Item)`
  flex: 0 100%;
  &&& {
    border-bottom: 0;
    padding: 12px 12px 0px 0;
  }

  // Show 2 items per row on xlarge screens
  ${media.xxl} {
    flex: 0 50%;
  }
`;

const ActivityList = ({ hasMore, initialLoading, items, loadMore, loading }) => {
  const [selectedAction, setSelectedAction] = useState(null);
  // MTS - We may need to move the scroll ref at some point. I'm unable to
  // see if this causes a negative UX or not when switching between lists.
  const [scrollRef, setScrollRef] = useState(null);

  // Check to see if <InfiniteScroll /> should be loaded
  const renderScroll = initialLoading || (items && items.length > 0);

  // Individual userActivity render function
  const renderItem = item => {
    const { activity, completed } = item;
    return (
      <ActivityItem
        onClick={() => setSelectedAction(item)}
        key={item.id}
        data-testid="activity-item"
      >
        <ActivityPreview activity={activity} completed={completed} userActivity={item} />
      </ActivityItem>
    );
  };

  return (
    <div ref={setScrollRef}>
      {renderScroll && (
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          threshold={0}
          loadMore={loadMore}
          hasMore={!loading && hasMore}
          useWindow
          getScrollParent={() => scrollRef}
        >
          <StyledList dataSource={items} renderItem={renderItem}>
            {loading && <Loading centered />}
          </StyledList>
        </InfiniteScroll>
      )}
      {!renderScroll && <ListEmpty />}

      <ActivityPreviewModal
        activity={selectedAction?.activity}
        completed={selectedAction?.completed}
        onCancel={() => setSelectedAction(null)}
        userActivity={selectedAction}
        visible={!!selectedAction}
      />
    </div>
  );
};

ActivityList.propTypes = {
  hasMore: PropTypes.bool.isRequired,
  initialLoading: PropTypes.bool.isRequired,
  items: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
};

export default ActivityList;
