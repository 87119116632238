import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../../styles/theme';
import ContactSyncInternal from './ContactSyncInternal';

const SyncContactsModal = ({ syncContactsModalVisible, setSyncContactsModalVisible }) => {
  return (
    <Modal
      destroyOnClose
      onCancel={() => setSyncContactsModalVisible(false)}
      visible={syncContactsModalVisible}
      maskClosable
      footer={null}
      width={720}
      bodyStyle={{ background: theme.colors.background }}
    >
      <ContactSyncInternal />
    </Modal>
  );
};

SyncContactsModal.propTypes = {
  setSyncContactsModalVisible: PropTypes.func.isRequired,
  syncContactsModalVisible: PropTypes.bool.isRequired,
};

export default SyncContactsModal;
