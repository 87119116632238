import { List } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './voter-search-list-item.scss';
import styled from 'styled-components';

const { Item } = List;

const ItemContents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Info = styled.div`
  display: flex;
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  min-width: 120px;
  max-width: 200px;
  margin-right: 10px;
`;
const Location = styled.div`
  min-width: 120px;
  max-width: 200px;
  margin-right: 10px;
`;
const Age = styled.div`
  min-width: 120px;
  max-width: 200px;
  margin-right: 10px;
  color: ${({ theme }) => theme.fonts.gray};
`;

const ViewFile = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.semibolditalic};
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const IdVotersListItem = ({
  voter,
  contactId,
  isFriend,
  activityId,
  userActivityId,
  campaignId,
  onListItemClick,
}) => {
  const { t } = useTranslation();
  const {
    'vb.tsmart_city': city,
    'vb.tsmart_first_name': firstName,
    'vb.tsmart_last_name': lastName,
    'vb.tsmart_state': state,
    'vb.voterbase_age': age,
  } = voter;

  return (
    <Item>
      <ItemContents>
        <Info>
          <Name>{`${firstName} ${lastName}`}</Name>
          <Location>{`${city}, ${state}`}</Location>
          {!!age && <Age>{t('idvoters.results.years_old', { age })}</Age>}
        </Info>
      </ItemContents>
      <ViewFile
        onClick={() => {
          onListItemClick(voter);
        }}
      >
        {t('idvoters.results.view_file')}
      </ViewFile>
    </Item>
  );
};

IdVotersListItem.propTypes = {
  activityId: PropTypes.number,
  campaignId: PropTypes.number,
  contactId: PropTypes.number,
  isFriend: PropTypes.bool.isRequired,
  onListItemClick: PropTypes.func.isRequired,
  userActivityId: PropTypes.number,
  voter: PropTypes.shape({
    'vb.tsmart_city': PropTypes.string,
    'vb.tsmart_first_name': PropTypes.string,
    'vb.tsmart_last_name': PropTypes.string,
    'vb.tsmart_state': PropTypes.string,
    'vb.voterbase_age': PropTypes.string,
  }).isRequired,
};

IdVotersListItem.defaultProps = {
  activityId: null,
  campaignId: null,
  contactId: null,
  userActivityId: null,
};

export default IdVotersListItem;
