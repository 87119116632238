import { message } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import logo from '../../images/app-logo-primary.png';
import { identify } from '../../services/analytics';
import { auth, campaigns, users } from '../../services/api';
import { AuthContext } from '../../utils/context';
import Loading from '../common/Loading';
import { setAppLanguage } from '../i18n';
import { getRedirectAfterLoginURL, getAndClearRedirectAfterLoginURL } from 'web/services/session';
import { replaceDefaultCampaignImage } from '@web/utils/string';

import EmailAuth from './Components/EmailAuth';
import './auth.scss';

const EmailAuthPage = ({ isSignup }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { campaign_slug } = useParams();

  const title = isSignup ? t('titles.sign_up') : t('titles.sign_in');
  useDocumentTitle(title);

  const { setAuth } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState(null);

  const provider = 'email';

  useEffect(() => {
    if (!isSignup) {
      // Only have campaign headers when signing up
      setLoading(false);
      return;
    }

    if (!campaign_slug) {
      setLoading(false);
      return;
    }

    campaigns
      .getPublicCampaign({ id: campaign_slug })
      .then(({ data: { data } }) => {
        setCampaign(data);
        setLoading(false);
      })
      .catch(e => {
        setCampaign(null);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirection = async user => {
    const canViewAdmin = user.is_campaign_admin || user.can_admin_accounts;
    // If the user can access admin and no redirectURL is set - go to admin
    const hasRedirectURL = !!getRedirectAfterLoginURL();
    if (canViewAdmin && !hasRedirectURL) {
      // hard reload (switch to) admin react app
      window.location.href = '/admin';
      return;
    }

    // Join the campaign if auth happened on campaign screen
    // this will only be relevant for signup action that is currently disabled
    if (campaign_slug && campaign) {
      await users.followCampaign({
        campaign_id: campaign.id,
        campaign_name: campaign.name,
        campaign_slug: campaign.slug,
      });
    }

    // redirect after login if URL is stored
    const redirectURL = getAndClearRedirectAfterLoginURL();
    if (redirectURL) {
      history.push(redirectURL);
      return;
    }

    // Fallback - throw them to frontline
    history.push('/frontline');
  };

  const onLogInHandler = (email, password) => {
    return auth
      .signIn(email, password, provider)
      .then(data => {
        const { token, user } = data;
        setAuth(token);
        setAppLanguage(user.locale);
        identify(user.id);

        // Redirect after email/password based login
        return handleRedirection(user);
      })
      .catch(unsetAuthHandler);
  };

  // Signup with email/password isn't surfaced in the web UI anymore
  const onSignUpHandler = (email, password) => {
    // throw new Error('Unsupported email/password signup');
    return auth
      .signUp(email, password, provider)
      .then(data => {
        const { token, user } = data;
        setAuth(token);
        setAppLanguage(user.locale);
        identify(user.id);

        // Redirect after email/password based signup
        // TODO: validate redirection logic when/if this is no longer hidden in the UI
        return handleRedirection(user);
      })
      .catch(unsetAuthHandler);
  };

  const onSubmitClick = (email, password) => {
    isSignup ? onSignUpHandler(email, password) : onLogInHandler(email, password);
  };

  const unsetAuthHandler = ({ response }) => {
    // unset auth token
    setAuth(null);

    // display error message
    const { data: { error, message: errorMessage } = {} } = response;
    message.error(errorMessage || error || t('errors.action_failed'));
  };

  const onBackClick = () => {
    if (!isSignup) {
      history.push(`/auth/login`);
      return;
    }

    if (campaign_slug && campaign) {
      history.push(`/auth/signup/${campaign_slug}`);
      return;
    }

    history.push(`/auth/signup`);
  };

  const onOppositeClick = () => {
    !isSignup ? history.push(`/auth/signup`) : history.push(`/auth/login`);
  };

  if (loading) {
    return (
      <div className="d-flex flex-row justify-content-center">
        <Loading />
      </div>
    );
  }

  const isCampaignPage = campaign && campaign.name;
  const showCampaignImg = campaign && replaceDefaultCampaignImage(campaign.url_profile_img);

  return (
    <div className="h-100 bg-auth-cyan d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex flex-row justify-content-center my-4">
        <img src={logo} alt="site-logo" height="27" />
      </div>

      <div className="card fixed-auth">
        {!!showCampaignImg && (
          <div className="d-flex flex-row justify-content-center my-4">
            <img
              style={{ maxHeight: '80px' }}
              src={showCampaignImg}
              className="img-fluid"
              alt={campaign.name}
              height="80"
            />
          </div>
        )}

        {!!isCampaignPage && <div className="pb-3 text-center">{campaign.name}</div>}

        <div className="card-title bolder">Enter your email and password</div>

        <EmailAuth onSubmitClick={onSubmitClick}></EmailAuth>

        <div className="line-break w-100 my-4"></div>

        <div className="d-flex flex-row align-items-center justify-content-center text-center">
          <div className="text-underline pointer" onClick={onBackClick}>
            {isSignup ? t('auth.back_to_sign_up') : t('auth.back_to_sign_in')}
          </div>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center mt-3 text-center">
          <div className="pr-2">
            {isSignup ? t('auth.existing_account_question') : t('auth.no_account_question')}
          </div>
          <div className="text-underline pointer" onClick={onOppositeClick}>
            {isSignup ? t('auth.login') : t('auth.sign_up')}
          </div>
        </div>
      </div>
    </div>
  );
};

EmailAuthPage.propTypes = {
  isSignup: PropTypes.bool,
};

EmailAuthPage.defaultProps = {};

export default EmailAuthPage;
