import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import ProgressBar from '@web/components/FanOutVotingPlan/components/ProgressBar';
import PageTitle from '@web/components/FanOutVotingPlan/components/PageTitle';
import {
  getInitialQuestionValues,
  getValidationQuestionsSchema,
  handleErrorMessage,
} from '@web/components/FanOutVotingPlan/utils';
import QuestionFields from './QuestionFields';
import ButtonsSection from '@web/components/FanOutVotingPlan/components/ButtonsSection';
import { activities } from '@web/services/api';
import { Wrapper, Content } from '../styles';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';

const PageTitleDescription = styled.div`
  color: ${({ theme }) => theme.colors.blackL42};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */

  display: flex;
  width: 100%;
  gap: 20px;
  flex-direction: column;

  span {
    font-weight: 700;
    gap: 15px;
  }
`;

const WhichCandidatesRepresent = ({
  progress,
  partyQuestions,
  currentPage: { nextButtonText, nextIcon, prevButtonText, prevIcon },
  onNextPage,
  onPreviousPage,
  setVPAnswers,
  activity,
  referralHash,
  formVPAnswers,
  VPAnswers,
  setContactId,
}) => {
  const { t } = useTranslation();

  const initialValues = getInitialQuestionValues(partyQuestions);
  const validationSchema = getValidationQuestionsSchema(partyQuestions);

  const onSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      const partyAnswers = values.question_answers.map(
        ({ activity_question_id, value, possible_answers }) => ({
          activity_question_id,
          party: possible_answers.find(answer => answer.key === value)?.position
            ? 'Republican'
            : 'Democrat',
          value,
        }),
      );
      const questionAnswers = [...VPAnswers, ...partyAnswers].map(
        ({ activity_question_id, value }) => ({
          activity_question_id,
          value,
        }),
      );

      activities
        .submitSurveyActivityForm({
          campaignSlug: activity.campaign.slug,
          formSlug: activity.survey_activity_form.slug,
          questionAnswers,
          referralHash,
          surveyFormData: formVPAnswers,
        })
        .then(({ data }) => {
          amplitudeTrack({ activity, name: 'FAN_OUT_VOTING_PLAN_FORM_SUBMISSION' });
          setVPAnswers(prevAnswers => [...prevAnswers, ...partyAnswers]);
          setContactId(data.data.contact_id);
          onNextPage();
        })
        .catch(err => message.error(handleErrorMessage(err)))
        .finally(() => setSubmitting(false));
    },
    [setVPAnswers, onNextPage, activity, referralHash, formVPAnswers, VPAnswers, setContactId],
  );

  return (
    <Wrapper>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Wrapper>
              <Content>
                <ProgressBar progress={progress} />
                <PageTitle
                  icon={'🗳'}
                  title={t('fan_out.voting_plan.which_candidates_represent.which')}
                  descriptionComponent={
                    <PageTitleDescription>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t('fan_out.voting_plan.which_candidates_represent.description.0'),
                        }}
                      />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t('fan_out.voting_plan.which_candidates_represent.description.1'),
                        }}
                      />
                    </PageTitleDescription>
                  }
                />
                <QuestionFields
                  questions={partyQuestions}
                  handleChange={handleChange}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </Content>
            </Wrapper>
            <ButtonsSection
              showPreviousButton={false}
              nextButtonProps={{
                disabled: isSubmitting,
                htmlType: 'submit',
                icon: nextIcon,
                text: nextButtonText,
              }}
              prevButtonProps={{
                disabled: isSubmitting,
                icon: prevIcon,
                onClick: onPreviousPage,
                text: prevButtonText,
              }}
            />
          </Form>
        )}
      />
    </Wrapper>
  );
};

WhichCandidatesRepresent.propTypes = {
  activity: PropTypes.object.isRequired,
  currentPage: PropTypes.shape({
    nextButtonText: PropTypes.string,
    nextIcon: PropTypes.node,
    prevButtonText: PropTypes.string,
    prevIcon: PropTypes.node,
  }).isRequired,
  first3Questions: PropTypes.array.isRequired,
  formAttributes: PropTypes.object.isRequired,
  formVPAnswers: PropTypes.object.isRequired,
  onNextPage: PropTypes.func.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  partyQuestions: PropTypes.array.isRequired,
  progress: PropTypes.number,
  referralHash: PropTypes.string.isRequired,
  referrer: PropTypes.shape({
    fullname: PropTypes.string,
    id: PropTypes.number,
  }),
  setContactId: PropTypes.func.isRequired,
  setVPAnswers: PropTypes.func.isRequired,
  sortedActivityQuestions: PropTypes.array.isRequired,
  VPAnswers: PropTypes.array.isRequired,
};

WhichCandidatesRepresent.defaultProps = {
  progress: 0,
};

export default WhichCandidatesRepresent;
