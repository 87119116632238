import { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { track } from '@web/services/analytics';

export const amplitudeTrack = ({ name, activity, team }) => {
  const { utm_campaign, utm_source, utm_term, utm_content, utm_medium } = queryString.parse(
    window.location.search,
  );

  track(name, {
    activity_id: activity?.id,
    activity_title: activity?.title,
    activity_type: activity?.type,
    campaign_id: activity?.campaign?.id,
    campaign_name: activity?.campaign?.name,
    team_id: team?.id,
    team_name: team?.name,
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    utm_term,
  });
};

export const useTrackFanOut = ({ name, activity, team }) => {
  const { search } = useLocation();
  const { utm_campaign, utm_source, utm_term, utm_content, utm_medium } = queryString.parse(search);

  useEffect(() => {
    track(name, {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      team_id: team?.id,
      team_name: team?.name,
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
      utm_term,
    });
  }, [name, activity, team, utm_campaign, utm_source, utm_term, utm_content, utm_medium]);
};
