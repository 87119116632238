import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import theme, { colors, media } from '@web/styles/theme';
import { isDark } from '@web/utils/other';

const ProgressBar = styled.div`
  background-color: ${props => props.backgroundColor};
  border-radius: 6px;
  height: 12px;
  width: ${props => props.value}%;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const TextWrapper = styled.span`
  transform: translateY(-1px);
  color: ${({ $color }) => (isDark($color) ? theme.colors.white : theme.colors.black)};
  ${media.ns} {
    transform: none;
  }
`;

const IndicatorNumber = styled.div`
  align-items: center;
  background: ${props => props.backgroundColor};
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 13px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 26px;
  justify-content: center;
  padding: 0 6px;
  font-size: 1rem;
  text-align: center;
  width: 60px;

  ${media.ns} {
    border-radius: 14px;
    height: 28px;
    font-size: 1.2rem;
    padding: 0 8px;
  }
`;

const IndicatorLabel = styled.div`
  text-align: center;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};

  ${media.ns} {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const Indicator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -8px;
  left: -37px;
  z-index: ${({ indicatorPriority }) => (indicatorPriority ? 3 : 2)};
`;

const ProgressWrapper = styled.div`
  display: inline-flex;
  position: absolute;
  left: 0;
  top: 10px;
  width: ${({ withIndicator }) => (withIndicator ? `calc(100% + 38px)` : `100%`)};
`;

export const ProgressContainer = styled.div`
  position: relative;
  height: 46px;

  ${media.sm} {
    margin: 14px;
  }
`;

// Use this for a background placeholder progress line that will be displayed below other lines
export const ProgressPlaceholder = () => {
  return (
    <ProgressWrapper>
      <ProgressBar backgroundColor={colors.mediumLightGray} value={100} />
    </ProgressWrapper>
  );
};

export const Progress = ({ backgroundColor, label, value, indicatorPriority }) => {
  if (!value) return null;

  // Caps the value for css purposes to the [0, 100] range
  const paintValue = Math.min(Math.max(value, 0), 100);

  return (
    <ProgressWrapper withIndicator>
      <ProgressBar backgroundColor={backgroundColor} value={paintValue} />
      <Indicator value={paintValue} indicatorPriority={indicatorPriority}>
        <IndicatorNumber backgroundColor={backgroundColor}>
          <TextWrapper $color={backgroundColor}>
            <Bold>{value}</Bold>%
          </TextWrapper>
        </IndicatorNumber>

        {label && <IndicatorLabel>{label}</IndicatorLabel>}
      </Indicator>
    </ProgressWrapper>
  );
};

Progress.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  indicatorPriority: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
};

Progress.defaultProps = {
  indicatorPriority: false,
};
