import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AnswerItem from '@web/components/DialerActivity/components/ScriptBlock/AnswerItem';
import Loading from '@web/components/common/Loading';

import useCallOutcomeQuestions from './useCallOutcomeQuestions';

const AnswerSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  &&& {
    color: ${({ theme }) => theme.colors.darkBlue};
    font-family: ${({ theme }) => theme.fonts.semibold};
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 20px;
    text-align: center;
  }
`;

function CallOutcome({ activity }) {
  const { answers, handleSubmit, loading } = useCallOutcomeQuestions(activity.campaign.id);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Title>Why did the call end?</Title>
      <AnswerSection>
        {answers.map(answer => (
          <AnswerItem
            disabled={loading}
            key={answer.reference}
            onPress={() => handleSubmit(answer)}
          >
            {answer.label}
          </AnswerItem>
        ))}
      </AnswerSection>
    </>
  );
}

CallOutcome.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

CallOutcome.defaultProps = {};

export default CallOutcome;
