export const questions = [
  {
    answers: [
      'Our team developed the voting history look-up tool to encourage people to learn about their voting record, about voting, and about how they can help their friends and neighbors remember to vote. It’s important that all of us make our voice heard this election. When we all vote, we win!',
      'You and your friends can check your registration, find polling locations, and make a voting plan at <a target="_blank" href="https://www.votehq.org/en">https://www.votehq.org/en</a>',
    ],
    title: 'Why was this tool created and what is it for?',
  },
  {
    answers: [
      'Yes! Voting history is publicly available information in every state. Who and what you voted for are, of course, private. :)',
      'Your friends, partner, boss, ex and neighbor can all see whether or not you voted. But who you voted for will always be secret.',
    ],
    title: 'Is this legal?',
  },
  {
    answers: [
      'Your results may not accurately reflect your voting history if you have recently moved.',
    ],
    title: 'What if I recently moved?',
  },
  {
    answers: [
      'This site is powered by publicly available vote history data, and while records are accurate for most people, there may be gaps. Your results may not accurately reflect your voting history if you have recently moved or if multiple people in your state share the same name and birthdate.',
      'This tool is intended for general information purposes only and is not an official source of voter history.',
      'Check with your local board of elections office or other official sources with any questions about your vote history.',
      'Go to <a target="_blank" href="https://iwillvote.com/">https://iwillvote.com/</a> to be directed to official sources to look up the status of your 2024 ballot and historic data. ',
    ],
    title: 'How accurate is the data?',
  },
  {
    answers: [
      'Double check to be sure your entries don’t have typos.',
      'We’ve compiled publicly available data on vote history. There may be gaps or inaccuracies, and we apologize for any errors.',
      'We encourage you to check with official sources to verify your voter registration, at <a target="_blank" href="https://iwillvote.com/">iwillvote.com</a>',
    ],
    title: 'What if I can’t find myself in the database?',
  },
  {
    answers: [
      'No. Who you voted for is secret, and <strong>not</strong> available in any public or private database.',
      'Whether you vote is public record. Anyone entering the correct information to look up a voter will be able to see their vote history.',
    ],
    title: 'Can anyone find out who I voted for?',
  },
  {
    answers: [
      'If you were under 18 in previous elections, the tool will not display a voting record.',
    ],
    title: 'What if I’m too young to have voted in the past?',
  },
  {
    answers: [
      'Data for early in-person and mail-in voting in 2024 will be posted here as we receive them.',
      'We are not election officials and this tool shouldn’t be used as the definitive authority on whether your vote was cast in 2024. Check with your local board of elections office or other official sources with any questions about the status of your 2024 vote. Go to <a target="_blank" href="https://iwillvote.com">https://iwillvote.com/</a> to be directed to official sources to look up the status of your 2024 ballot. ',
    ],
    title: 'I voted early in the 2024 election but this tool says I haven’t, did my vote count?',
  },
];

export const myVoteScoreQuestions = [
  {
    answers: [
      'Our team developed the voting history look-up tool to encourage people to learn about their voting record, about voting, and about how they can help their friends and neighbors remember to vote. It’s important that all of us make our voice heard this election. When we all vote, we win!',
      'You and your friends can check your registration, find polling locations, and make a voting plan at <a target="_blank" href="https://www.votehq.org">https://www.votehq.org</a>',
    ],
    title: 'Why was this tool created and what is it for? ',
  },
  {
    answers: [
      'Your results may not accurately reflect your voting history if you have recently moved.',
    ],
    title: 'What if I recently moved?',
  },
  {
    answers: [
      'Yes! Voting history is publicly available information in every state. Who and what you voted for are, of course, private. :)',
    ],
    title: 'Is this legal?',
  },
  {
    answers: [
      'If you were under 18 in previous elections, those elections will not be included in calculating your vote score.',
    ],
    title: 'What if I’m too young to have voted in the past?',
  },
  {
    answers: [
      'Data for early in-person and mail-in voting in 2024 will be posted here as we receive them.',
      'This tool shouldn’t be used as the definitive authority on whether your vote was cast in 2024. Check with your local board of elections office or other official sources with any questions about the status of your 2024 vote.',
    ],
    title: 'I voted early in the 2024 election but this tool says I haven’t, did my vote count?',
  },
  {
    answers: [
      'This site is powered by publicly available vote history data, and while records are accurate for most people, there may be gaps. Your results may not accurately reflect your voting history if you have recently moved.',
      'This tool is intended for general information purposes only and is not an official source of voter history.',
      'Check with your local board of elections office or other official sources with any questions about your vote history.',
      'Go to <a target="_blank" href="https://www.votehq.org">https://www.votehq.org</a> to be directed to official sources to look up the status of your 2024 ballot and historic data.',
    ],
    title: 'How accurate is the data?',
  },
  {
    answers: [
      'Double check to be sure your entries don’t have typos.',
      'We’ve compiled publicly available data on vote history. There may be gaps or inaccuracies, and we apologize for any errors.',
      'We encourage you to check with official sources to verify your voter registration, at <a target="_blank" href="https://www.votehq.org">https://www.votehq.org</a>',
    ],
    title: 'What if I can’t find myself in the database?',
  },
  {
    answers: [
      'No. Who or what you voted for is secret, and <strong>not</strong> available in any public or private database.',
    ],
    title: 'Can anyone find out who I voted for?',
  },
];
