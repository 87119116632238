import { message } from 'antd';
import React from 'react';
import { transformConnections } from '../../../services/google/contacts';

function useGoogleContacts(contactsType = 'my') {
  const [contacts, setContacts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    let promise;
    if (contactsType === 'my') {
      promise = window.gapi.client.people.people.connections.list({
        pageSize: 2000,
        personFields: 'emailAddresses,names,photos',
        resourceName: 'people/me',
      });
    } else if (contactsType === 'other') {
      promise = window.gapi.client.people.otherContacts.list({
        pageSize: 1000,
        readMask: 'emailAddresses,names',
      });
    }
    promise
      .then(response => {
        let contacts;
        if (contactsType === 'my') {
          const { connections } = response.result;
          contacts = (connections && transformConnections(connections)) || [];
        } else if (contactsType === 'other') {
          const { otherContacts: resOtherContacts } = response.result;
          contacts = (resOtherContacts && transformConnections(resOtherContacts)) || [];
        }

        if (!contacts || !contacts.length) {
          setLoading(false);
          return;
        }
        setLoading(false);
        setContacts(contacts);
      })
      .catch(response => {
        setLoading(false);
        const {
          error: { message: errorMessage },
        } = response.result;
        message.error(errorMessage);
      });
  }, [contactsType]);

  return { contacts, loading };
}

export default useGoogleContacts;
