import React from 'react';
import styled, { css } from 'styled-components';

import Header from '../components/Header';
// import Footer from '../components/Footer';

import { media } from '@web/styles/theme';
import { SubHeader, Header as Title } from '../components/CheckVoterScoreForm';

// Do not remove, we will probably use it again
// import { message } from 'antd';
// import { useHistory } from 'react-router-dom';
// import SelectVoter from '../components/SelectPeople';
// import CheckVoterScoreForm from '../components/CheckVoterScoreForm';
// import ACRoutes from '@web/services/api/accelerateChange';
// import useUtmParams from '@web/components/AccelerateChange/useUtmParams';

const PageWrapper = styled.div`
  color: black;

  width: 100%;
  min-height: 100vh;
  background-color: ${({ color }) => color ?? '#dfdfdf'};

  display: flex;
  flex-direction: column;

  transition: background-color 0.3s ease-in-out;

  * {
    font-family: Gill Sans;
  }
`;

const InnerContainer = styled.div`
  width: 868px;
  margin: 24px auto 0;
  padding-bottom: 64px;

  ${({ $isLanding }) =>
    $isLanding &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      flex: 1;
      gap: 10px;

      > h2 {
        font-size: 36px;
      }

      > h3 {
        font-size: 18px;
      }
    `}

  ${media.nlg} {
    padding: 0 16px;
    width: 100%;
  }
`;

const SearchVoter = () => {
  // Do not remove, we will probably use it again
  // const history = useHistory();
  // const [voterData, setVoterData] = useState(null);
  // const [voterSearchInfo, setVoterSearchInfo] = useState(null);

  // const params = useUtmParams();

  // const onVoterDataSubmit = async (data, { setSubmitting }) => {
  //   const { city, state_abbrev, first_name, last_name, dob } = data;

  //   let [month, day, year] = dob.split('/');

  //   ACRoutes.searchVoters(
  //     first_name,
  //     last_name,
  //     undefined,
  //     city,
  //     state_abbrev,
  //     `${year}-${month}-${day}`,
  //   )
  //     .then(data => {
  //       if (data?.data?.length > 1) {
  //         setVoterData(data.data);
  //         setVoterSearchInfo({ city, name: `${first_name} ${last_name}` });
  //       } else if (data?.data?.length === 1) {
  //         history.push(`/my_vote_score/${data.data[0].voterbase_id}${params.formattedParams}`);
  //       } else {
  //         message.info('Voters not found');
  //       }
  //     })
  //     .catch(error => message.error(error?.response?.data?.message || error?.message))
  //     .finally(() => setSubmitting(false));
  // };

  return (
    <PageWrapper>
      <Header />
      <InnerContainer $isLanding>
        <Title>Thank you for checking your Vote Score, and being a voter, this year.</Title>
        <SubHeader>
          It will take several months for state departments of elections to update the vote history
          with Election Day 2024 voting records, and access to the VoteScore tool will be paused for
          the time being.
        </SubHeader>
        {/* Do not remove, we will probably use it again */}
        {/* {voterData && (
          <SelectVoter
            voters={voterData}
            city={voterSearchInfo?.city}
            name={voterSearchInfo?.name}
            checkAgain={() => setVoterData(null)}
          />
        )}
        {!voterData && <CheckVoterScoreForm handleSubmit={onVoterDataSubmit} />} */}
      </InnerContainer>
      {/* <Footer /> */}
    </PageWrapper>
  );
};

export default SearchVoter;
