import i18n from '../../components/i18n';

const authErrorMessage = error => {
  const GENERIC_ERROR_MESSAGE = i18n.t('auth.google.login_failed');
  const ACCESS_DENIED_MESSAGE = i18n.t('auth.google.access_denied');

  switch (error) {
    case 'popup_closed_by_user':
      return GENERIC_ERROR_MESSAGE;
    case 'access_denied':
      return ACCESS_DENIED_MESSAGE;
    case 'immediate_failed':
      return GENERIC_ERROR_MESSAGE;
    case 'idpiframe_initialization_failed':
      return null;
    default:
      return GENERIC_ERROR_MESSAGE;
  }
};

export { authErrorMessage };
