import PropTypes from 'prop-types';
import { DoubleRightOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import { getFullName } from './utils';
import FillReport from './FillReport';
import { media } from '../../styles/theme';

import { useMedia } from '../../hooks';
const Container = styled.div`
  padding: 0 0 0 30px;
  max-height: 100vh;
  min-width: 300px;
  max-width: 460px;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-color: ${({ theme }) => theme.colors.black};
  overflow-y: auto;

  @media screen and (max-width: 1400px) {
    position: fixed;
    box-shadow: 0px 1px 14px rgb(0 0 0 / 6%);
    background: white;
    z-index: 999;
    height: 100%;
    top: 0;
    right: 0;
    width: 85%;
  }
  ${media.s} {
    z-index: 999;
    width: 100%;
  }
`;

const InfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 30px 30px 0;
  flex-wrap: wrap-reverse;
`;

const ContactName = styled.div`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.semibold};
  color: ${({ theme }) => theme.colors.black};
`;

const NameAndInfo = styled.div`
  margin-right: 10px;
  button {
    margin-top: 10px;
  }
`;

const InfoButtonContainer = styled.div`
  background: ${({ showRightSideBar }) => (showRightSideBar ? 'rgba(233, 234, 236)' : 'none')};
  cursor: pointer;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  margin-top: 15px;
  &:hover {
    background: rgba(233, 234, 236);
  }
`;

const Divider = styled.div`
  height: 1px;
  background: rgba(196, 196, 196, 0.3);
  margin: 16px 0px;
`;
const ReportingSidebar = ({
  archived,
  archiveThread,
  contact,
  canRegister,
  onRegisterClick,
  campaignId,
  activityId,
  showRightSideBar,
  toggleSidebar,
  userActivityId,
  reportActivityId,
}) => {
  const {
    first_name: contactFirstName,
    last_name: contactLastName,
    id: contactId,
    contact_email: contactEmail,
  } = contact;

  const showToggleButton = useMedia('(max-width: 1400px)');

  return (
    <Container>
      {showToggleButton && (
        <InfoButtonContainer showRightSideBar onClick={() => toggleSidebar(false)}>
          <DoubleRightOutlined />
        </InfoButtonContainer>
      )}
      <InfoSection>
        <NameAndInfo>
          <ContactName>{getFullName(contactFirstName, contactLastName)}</ContactName>
          <div>{contactEmail}</div>
        </NameAndInfo>
      </InfoSection>
      <Divider />
      {activityId && (
        <FillReport
          archived={archived}
          contactId={contactId}
          campaignId={campaignId}
          activityId={activityId}
          archiveThread={archiveThread}
          userActivityId={userActivityId}
          reportActivityId={reportActivityId}
        />
      )}
    </Container>
  );
};

ReportingSidebar.propTypes = {
  activityId: PropTypes.number,
  archived: PropTypes.bool,
  archiveThread: PropTypes.func,
  campaignId: PropTypes.number,
  canRegister: PropTypes.bool,
  contact: PropTypes.object,
  onRegisterClick: PropTypes.func.isRequired,
  reportActivityId: PropTypes.number,
  showRightSideBar: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  userActivityId: PropTypes.number,
};

ReportingSidebar.defaultProps = {
  contact: {},
};
export default ReportingSidebar;
