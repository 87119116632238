import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Link from '@web/components/Headcount/components/Link';
import Text from '@web/components/Headcount/components/Text';

import { useTranslation } from 'react-i18next';

const StyledTermsAndConditions = styled.div`
  margin-top: ${({ theme }) => theme.spacing.tight};
`;

const StyledLink = styled.div`
  margin-top: ${({ theme }) => theme.spacing.tight};
  font-size: 0.75rem; // 12px;
`;

function TermsAndConditions({ activity, campaign, team }) {
  const { t } = useTranslation();
  const cannabisRegistrationEnabled = activity?.campaign?.cannabis_registration_enabled;

  function getTerms() {
    if (team?.terms) {
      return {
        terms: team.terms,
        termsLink: team?.terms_link || activity?.terms_link || campaign?.terms_link,
      };
    }
    if (activity?.terms) {
      return {
        terms: activity.terms,
        termsLink: null,
      };
    }
    return {
      terms: campaign.terms,
      termsLink: null,
    };
  }

  function replaceURLsWithLinks(terms) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;

    return terms?.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url.replace('http://', '').replace('https://', '') + '</a>';
    });
  }

  const terms = getTerms();
  const termsText = replaceURLsWithLinks(terms.terms);

  if (cannabisRegistrationEnabled) {
    terms.termsLink = null;
  }

  return (
    <StyledTermsAndConditions>
      <Text
        as="span"
        color="textDarkGray"
        dangerouslySetInnerHTML={{ __html: termsText }}
        variant="small"
      ></Text>
      {terms?.termsLink && (
        <StyledLink>
          <Link href={terms.termsLink} target="_blank" rel="noopener noreferrer">
            {t('check_registration.headcount.termsAndConditions')}
          </Link>
        </StyledLink>
      )}
    </StyledTermsAndConditions>
  );
}

TermsAndConditions.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      cannabis_registration_enabled: PropTypes.bool,
    }),
    terms: PropTypes.string,
    terms_link: PropTypes.string,
  }),
  campaign: PropTypes.shape({
    terms: PropTypes.string,
    terms_link: PropTypes.string,
  }).isRequired,
  team: PropTypes.shape({
    terms: PropTypes.string,
    terms_link: PropTypes.string,
  }),
};

export default TermsAndConditions;
