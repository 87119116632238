import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@web/components/Headcount/components/Button';

const StyledHref = styled.a`
  :link {
    text-decoration: none;
  }
`;

const StlyedButton = styled(Button)`
  width: 100%;
`;

function LinkButton({ href, ...rest }) {
  return (
    <StyledHref target="_blank" rel="noopener noreferrer" href={href}>
      <StlyedButton {...rest} />
    </StyledHref>
  );
}

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
};

export default LinkButton;
