export const friendlyTypeNameFor = type => {
  switch (type) {
    case 'DialerActivity':
      return 'Phone Bank';

    case 'RegistrationActivity':
      return 'Voter Registration';

    case 'TestimonialActivity':
    case 'stories':
      return 'Video Story';

    case 'TextAFriendActivity':
    case 'friend_banks':
      return 'Friend to Friend Texting';

    case 'TextAListActivity':
    case 'text_banks':
      return 'Peer to Peer Texting';

    case 'ShareActivity':
    case 'social_sharing':
      return 'Social Sharing';

    case 'SurveyActivity':
    case 'build_list':
      return 'Build List';

    case 'FormActivity':
    case 'surveys':
      return 'Surveys';

    case 'WebActivity':
    case 'webpage':
      return 'Open a Webpage';

    case 'NewsActivity':
      return 'Make an Announcement';

    case 'IdVotersActivity':
    case 'ReportActivity':
    case 'canvassing':
      return 'Shared List Canvassing';

    case 'AssignedCanvassingActivity':
      return 'Assigned List Canvassing';

    case 'BroadcastActivity':
    case 'broadcast':
      return 'Send a Broadcast SMS';

    case 'AutomatedTextActivity':
    case 'Automated Texting':
      return 'Setup an Automated Text';

    case 'ReminderActivity':
    case 'reminder_activities':
      return 'Push Notification';

    case 'EmailReminderActivity':
    case 'email_reminder_activities':
      return 'Email Reminder';

    case 'DirectMessageActivity':
    case 'direct_message_activities':
      return 'Direct Message';

    default:
      return '';
  }
};
