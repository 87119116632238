import { useEffect, useState } from 'react';

/**
 * Attaches internal event handler to detect window visibility changes. Returns result of `window.visible`
 *
 */
const useWindowVisibility = () => {
  const [state, setState] = useState(!document.hidden);

  const handler = () => {
    setState(!document.hidden);
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', handler);

    // Cleanup
    return () => {
      window.removeEventListener('visibilitychange', handler);
    };
  });

  return state;
};

export default useWindowVisibility;
