import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import FanOutDesktopLayout from '@web/components/FanOut/components/DesktopLayout';
import FanOutMobileLayout from '@web/components/FanOut/components/MobileLayout';
import useIsMobile from '@web/components/VoterRegistration/hooks/useIsMobile';

const FanOutActivity = ({
  activity,
  modalVoterOpen,
  modalReferralOpen,
  openVoterModal,
  openReferralModal,
  myStats,
  statsLoading,
}) => {
  const isMobile = useIsMobile();

  const FanOutLayout = useMemo(
    () => (isMobile ? FanOutMobileLayout : FanOutDesktopLayout),
    [isMobile],
  );

  return (
    <FanOutLayout
      activity={activity}
      modalVoterOpen={modalVoterOpen}
      modalReferralOpen={modalReferralOpen}
      openVoterModal={openVoterModal}
      openReferralModal={openReferralModal}
      myStats={myStats}
      statsLoading={statsLoading}
    />
  );
};

FanOutActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  modalReferralOpen: PropTypes.bool.isRequired,
  modalVoterOpen: PropTypes.bool.isRequired,
  myStats: PropTypes.shape({
    entries: PropTypes.number,
    recruits: PropTypes.number,
  }).isRequired,
  openReferralModal: PropTypes.func.isRequired,
  openVoterModal: PropTypes.func.isRequired,
  statsLoading: PropTypes.bool.isRequired,
  team: PropTypes.object,
};

FanOutActivity.defaultProps = {
  team: null,
};

export default FanOutActivity;
