import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Body, Title } from '@web/components/DialerActivity/components/TextStyles';
import Button from '@web/components/DialerActivity/components/Button';

const StyledMessageBox = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.whiteSmokeLighter};
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  max-width: 612px;
  padding: 24px;
`;

const StyledTitle = styled(Title)`
  line-height: 26px;
  margin-bottom: 10px;
  margin-top: 6px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

function MessageBox({ handleEditPress, loading, submitResponses }) {
  return (
    <StyledMessageBox>
      <StyledTitle>
        Your call has ended.
        <br />
        Do you need to edit your answers?
      </StyledTitle>
      <Body style={{ marginBottom: 26, maxWidth: '100%' }}>
        You will not be able to edit answers beyond this screen.
      </Body>
      <StyledButton disabled={loading} onClick={submitResponses} style={{ marginBottom: 4 }}>
        I'm All Done
      </StyledButton>
      <StyledButton disabled={loading} onClick={handleEditPress} variant="gray">
        I Need to Edit
      </StyledButton>
    </StyledMessageBox>
  );
}

MessageBox.propTypes = {
  handleEditPress: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitResponses: PropTypes.func.isRequired,
};

MessageBox.defaultProps = {};

export default MessageBox;
