import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const IconContainer = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue};

  :hover {
    opacity: 1;
    color: #c4c4c4;
  }
  font-size: 20px;
  margin-top: -10px;
`;

const NavArrows = ({ prev, next }) => {
  return (
    <Container>
      <IconContainer onClick={prev}>
        <ArrowLeftOutlined />
      </IconContainer>
      <IconContainer onClick={next}>
        <ArrowRightOutlined />
      </IconContainer>
    </Container>
  );
};

NavArrows.propTypes = {
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
};

NavArrows.defaultProps = {};

export default NavArrows;
