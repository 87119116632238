import theme from '@web/styles/theme';
import { ErrorMessage, getIn } from 'formik';
import { bool, func, node, object, string, any, shape } from 'prop-types';
import React, { useCallback, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import styled, { css } from 'styled-components';
import 'react-phone-input-2/lib/style.css';

import ErrorText from '@web/components/VoterRegistration/newComponents/Input/ErrorText';

const Wrapper = styled.div``;

const PrefixWrapper = styled.div`
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  left: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${({ $focused, $outlineColor, $focusedColor }) => {
      if ($focused) {
        return $focusedColor ?? 'black';
      }

      if ($focusedColor) {
        return `${$outlineColor}80`;
      }

      return $outlineColor ?? theme.colors.gray;
    }};
  }
`;

export const Label = styled.div`
  margin-bottom: 5px;
  text-transform: ${({ $textTransform }) => $textTransform ?? 'uppercase'};
  font-size: 0.875rem;
  ${({ theme, $labelFontColor }) => css`
    color: ${$labelFontColor ?? theme.colors.black};
    font-family: ${theme.fonts.bold};
  `}
`;

const Required = styled.span`
  color: red;
  margin-left: 3px;
`;

export const StyledErrorMessage = styled(ErrorMessage).attrs({ component: 'div' })`
  color: #ea5156;
  font-size: 12px;
  margin-top: 4px;
`;

const StyledField = styled(PhoneInput)`
  &&& {
    font-family: unset;
    input {
      border: 1px solid
        ${({ $outlineColor, $hasError }) =>
          $hasError ? theme.colors.red : $outlineColor ?? theme.colors.gray};
      background-color: ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster};
      border-radius: 6px;
      padding: 10px 14px 12px;
      padding-left: ${({ $withPrefix }) => ($withPrefix ? '40px' : '10px')};
      color: ${({ $fontColor }) => $fontColor ?? theme.colors.black};
      width: 100%;
      height: 42px;
      font-size: 14px;

      -webkit-background-clip: text !important;
      background-clip: text !important;
      &:-webkit-autofill:first-line {
        color: ${({ $fontColor }) => $fontColor ?? theme.colors.black};
      }
      &:-webkit-autofill {
        color: ${({ $fontColor }) => $fontColor ?? theme.colors.black};
        -webkit-text-fill-color: ${({ $fontColor }) => $fontColor ?? theme.colors.black};
      }
      box-shadow: 0 0 0 30px ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster}
        inset !important;
      -webkit-box-shadow: 0 0 0 30px
        ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster} inset !important;

      :hover {
        border: 1px solid
          ${({ theme, $outlineColor, $isSurvey }) =>
            $isSurvey ? theme.colors.dodgerBlue : $outlineColor ?? theme.colors.dodgerBlue};
      }

      &:focus-visible {
        outline: 0;
        border-right-width: 1px !important;
        border: 1px solid
          ${({ $hasError, theme, $outlineColor, $isSurvey }) =>
            $hasError
              ? theme.colors.red
              : $isSurvey
              ? theme.colors.dodgerBlue
              : $outlineColor ?? theme.colors.dodgerBlue};
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }

      ::placeholder {
        color: ${({ $outlineColor }) => $outlineColor ?? theme.colors.blackL72};
      }

      ${({ $isFanOut }) =>
        $isFanOut &&
        css`
          margin-top: 2rem;
          border-radius: 4px;
        `}

      .ant-input-prefix {
        margin-right: 12px;
      }
    }
  }
`;

const PhoneNumberInput = ({
  name,
  value,
  label,
  placeholder,
  onChange,
  prefixIcon,
  shouldFocusIcon,
  isRequired,
  labelStyles,
  isFanOut,
  isSurvey,
  formik,
  backgroundColor,
  fontColor,
  labelFontColor,
  outlineColor,
  textTransform,
  focusedColor,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  const handleChange = useCallback(
    (val, _, event) => {
      const valWithMask = event.target.value;
      if (valWithMask.startsWith('+1')) {
        onChange(val);
      } else {
        onChange(`1${val}`);
      }
    },
    [onChange],
  );
  const error = getIn(formik?.errors, name);
  const touched = getIn(formik?.touched, name);
  const hasError = touched && error;

  return (
    <Wrapper>
      <Label $labelFontColor={labelFontColor} $textTransform={textTransform} style={labelStyles}>
        {label}
        {isRequired && <Required>*</Required>}
      </Label>
      <div style={{ position: 'relative' }}>
        <StyledField
          country="us"
          inputProps={{ name }}
          placeholder={placeholder}
          onlyCountries={['us']}
          value={value}
          buttonStyle={{ display: 'none' }}
          defaultMask="+1 (...) ...-...."
          onChange={handleChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          $withPrefix={!!prefixIcon}
          $outlineColor={outlineColor}
          $backgroundColor={backgroundColor}
          $fontColor={fontColor}
          $isFanOut={isFanOut}
          $isSurvey={isSurvey}
          $hasError={hasError}
          {...rest}
        />
        {prefixIcon && (
          <PrefixWrapper
            $focusedColor={focusedColor}
            $outlineColor={outlineColor}
            $focused={shouldFocusIcon ? focused : false}
          >
            {prefixIcon}
          </PrefixWrapper>
        )}
      </div>
      {isFanOut ? (
        hasError && <ErrorText children={error} />
      ) : (
        <StyledErrorMessage data-testid={`${name}-error`} name={name} />
      )}
    </Wrapper>
  );
};

PhoneNumberInput.propTypes = {
  backgroundColor: string,
  focusedColor: string,
  fontColor: string,
  formik: shape({
    errors: any.isRequired,
    handleBlur: func.isRequired,
    touched: any.isRequired,
  }).isRequired,
  isFanOut: bool,
  isRequired: bool,
  isSurvey: bool,
  label: string,
  labelFontColor: string,
  labelStyles: object,
  name: string.isRequired,
  onChange: func,
  outlineColor: string,
  placeholder: string,
  prefixIcon: node,
  shouldFocusIcon: bool,
  textTransform: string,
  value: string,
};

export default PhoneNumberInput;
