import { message, Modal } from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { users as usersAPI } from '@web/services/api';
import { ArrowDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useModal } from 'web/hooks';
import { getLocalizedOnboardingData } from 'web/utils/other';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { usePaginatedApi } from '../../hooks/usePaginatedApi';
import { track } from '../../services/analytics';
import { campaigns, users } from '../../services/api';
import { media } from '../../styles/theme';
import { AuthContext } from '../../utils/context';
import { interpolateMessage } from '../../utils/other';
import Container from '../Container';
import CheckRegistrationModal from './CheckRegistrationModal';
import Message from './Message';
import ScriptsSection from './ScriptsSection';
import ReportingSidebar from './ReportingSidebar';
import { checkForProfanity, getPersonFromMessageThread } from './utils';
import MessageInput from './MessageInput';
import { useMedia } from '../../hooks';
import MessageThreadHeader from './MessageThreadHeader';
import MessageThreadLoading from './MessageThreadLoading';

const { confirm } = Modal;

const CenteredContainer = styled(Container)`
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  border-right: 1px solid ${({ theme }) => theme.colors.borderGray};
`;

const Messages = styled.div`
  flex: 1;
  padding: 1.8rem 1.8rem 1rem;
`;

const ThreadAndRightPanel = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  ${media.ns} {
    display: grid;
    grid-template-columns: ${({ showRightSideBar }) => (showRightSideBar ? '68% 32%' : '100%')};
    @media (max-width: 1400px) {
      grid-template-columns: 100%;
    }
  }
  height: 100%;
`;

const ScrollSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

const BottomArrowContainer = styled.div`
  position: absolute;
  bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #54dde8;
`;

const resolveEndpointByType = type => {
  if (
    type === 'peer_to_peer' ||
    type === 'friend_to_friend' ||
    type === 'broadcast' ||
    type === 'team' ||
    type === 'canvass'
  )
    return {
      send: users.updateMessageSent,
    };
  if (type === 'user_to_user')
    return {
      send: users.updateUserMessageSent,
    };

  throw new Error('Unknown message type provided.');
};

/* Helper function that determines type of the response message based on thread type, contactable user type and latest message type.
    * For User to User messages always respond with InternalMessage
        - TeamMessage: that if the thread type is team,
    * For User to Contact messages
        * if the contact belongs to a User they are a "Friend" and we use EmailMessage
        * if the contact does not belong to a User they are a Campaign's Contact & last message received was:
            - InviteMessage: response should be of EmailMessage type
            - SmsMessage: response should be of EmailMessage type
            - CanvasserMessage: respond with TwilioMessage if we have a number for them, otherwise the button should be disabled.
            - TwilioMessage, BroadcastMessage: copy the type of last message received
  */
export const resolveResponseMessageType = (threadType, contactableType, messageList) => {
  const isFriend = contactableType === 'User';

  // user to user case
  if (threadType === 'user_to_user') {
    return 'InternalMessage';
  }

  // user to team chat
  if (threadType === 'team') {
    return 'TeamMessage';
  }

  // user to friend contact
  if (isFriend) {
    return 'EmailMessage';
  }

  // user to contact, which is not a friend. Return type is based on the rules above
  const [latestMessage] = messageList;
  const latestMessageType = (latestMessage && latestMessage.type) || null;

  if (latestMessageType === 'InviteMessage' || latestMessageType === 'SmsMessage') {
    return 'EmailMessage';
  }

  if (latestMessageType === 'CanvasserMessage') {
    return 'TwilioMessage';
  }

  // TwillioMessage, BroadcastMessage should stay the same
  if (latestMessageType === 'TwilioMessage' || latestMessageType === 'BroadcastMessage') {
    return latestMessageType;
  }

  // Unhandled type
  return null;
};

// There are several different types of threads:
// 1. One will be between users and other users. When messaging these people we will create a direct message between users. (InternalMessage)
// 2. One will be between users and their phone contacts. When messaging these people, we will jump out to personal emails on web. (SmsMessage)
// 3. One will be between users and contacts that belong to campaigns. When messaging these people, we will use the standard TwilioMessage endpoint. (TwilioMessage, CanvasserMessage). For CanvasserMessage depending on if we have phone we can send a TwilioMessage, if not we can open email.
// Message types are: SmsMessage, TwilioMessage, InviteMessage, CavasserMessage, InternalMessage

const MessageThread = ({ handleThreadsRefresh, messageThreadId, setMessageThreadParams }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const [refreshMessageThreads, setRefreshMessageThreads] = useState(false);

  // right sidebar
  const [showRightSideBar, setShowRightSideBar] = useState();

  // New message state
  const [messageContent, setMessageContent] = useState('');
  const [scriptId, setScriptId] = useState(null);
  const [profaneWordDetected, setProfaneWordDetected] = useState(false);

  // Messages list, pagination and infiniteScroll
  const scrollRef = useRef({});

  const overflowSidebar = useMedia(`(min-width: 1400px)`);

  const lastMessageRef = useRef(null);
  const messageInputRef = useRef(null);
  const lastScriptRef = useRef(null);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);

  const [
    // eslint-disable-next-line
    _hasMore,
    // eslint-disable-next-line
    _initialLoading,
    messages,
    loadMore,
    loading,
    // eslint-disable-next-line
    _setMessages,
    resetPagination,
    metadata,
    // eslint-disable-next-line
    _cancelPendingRequests,
    error,
  ] = usePaginatedApi(users.getUserMessageThread, messageThreadId, null, true);

  const {
    archived,
    campaign_id: campaignId,
    activity_id: activityId,
    user_activity_id: userActivityId,
    opt_in_form_id: optInFormId,
    kind: threadType,
    p2p_free_response_disabled,
    is_canvasser_action,
    has_canvasser_permissions,
  } = metadata;

  const person = useMemo(
    () => (user && getPersonFromMessageThread(metadata, user.id)) || {},
    [metadata, user],
  );
  const contactable_type = (metadata.contact && metadata.contact.contactable_type) || '';
  const contact_has_phone = (metadata.contact && metadata.contact.hasPhone) || false;
  const contactId = metadata?.contact?.id || person?.id || metadata?.team?.id;

  const { email = '', name: threadName = '', first_name = '', last_name = '' } = person;

  // Error handling
  useEffect(() => {
    if (error?.status === 403)
      message.error(
        `Your account does not have access to this feature. Upgrade to a Professional or Enterprise plan to access this feature.`,
      );
  }, [error]);

  // Check if need to show report sidebar by default
  useEffect(() => {
    setShowRightSideBar(threadType !== 'team' && overflowSidebar);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overflowSidebar, threadType]);

  // If another messagethread is clicked on in the Inbox, change the message thread
  useEffect(() => {
    resetPagination();
    setShouldRefetchMessages(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageThreadId]);

  const [shouldrefetchMessages, setShouldRefetchMessages] = useState(true);

  // Callback effect when messages need to be (re)fetched
  useEffect(() => {
    if (shouldrefetchMessages) {
      loadMore();
      setShouldRefetchMessages(false);
    }
  }, [loadMore, shouldrefetchMessages]);

  const handleSelectTemplate = (id, message) => {
    setScriptId(id);
    handleInput(interpolateMessage(message, person));

    // Scroll the message text into view once the template is selected
    // NOTE: smooth scrolling is flaky here and stops mid scroll sometimes so we use the default option.
    setTimeout(() => messageInputRef?.current?.scrollIntoView(), 0);
  };

  // Update document title when threadName changes
  const title = threadName ? `${t('inbox.messages')} · ${threadName}` : t('inbox.messages');
  useDocumentTitle(title);

  // Outreach report and response scripts are loaded inside modals, we only keep toggles here
  const [refreshing, setRefreshing] = useState(false);

  const [templates, setTemplates] = useState(null);

  // Callback effect to scroll to the latest message when initial messages page loads
  useEffect(() => {
    // don't scroll anything into view until we loaded templates first (it'd just scroll away)
    if (!templates) return;

    const isInitialMessagesLoad = metadata?.current_page === 1;
    if (isInitialMessagesLoad) {
      // Needed to add setTimeout to allow time for the templates section to be displayed first
      //
      // NOTE: we cannot use { behavior: 'smooth' } here because pagination will try to load more
      // items before the animation triggers
      setTimeout(() => lastMessageRef?.current?.scrollIntoView(), 0);
    }
  }, [metadata, templates]);

  useEffect(() => {
    // Load templates
    if (campaignId && activityId) {
      campaigns
        .getActivityScripts(campaignId, activityId, {
          contact_id: contactId,
          script_type: 'response',
        })
        .then(({ data: { data } }) => {
          setTemplates(
            data.map(({ id, name, script }) => ({
              id,
              message: script,
              name,
            })),
          );
        });
    } else if (optInFormId) {
      campaigns.getScripts(messageThreadId).then(({ data: { data } }) => {
        const cleanTemplates = data
          .map(({ script_type: scriptType, name, script, id }) => {
            if (scriptType === 'response')
              return {
                id,
                message: script,
                name,
              };

            return null;
          })
          .filter(i => i); // clear nulls

        setTemplates(cleanTemplates);
      });
    } else {
      setTemplates([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, activityId]);

  const [campaign, setCampaign] = useState(null);
  useEffect(() => {
    // Load campaign
    if (campaignId) {
      campaigns.getCampaign({ id: campaignId }).then(({ data: { data } }) => {
        const localizedData = getLocalizedOnboardingData(data);

        setCampaign(localizedData);
      });
    } else {
      setCampaign(null);
    }
  }, [campaignId]);

  const [openCheckRegistrationModal, closeCheckRegistrationModal] =
    useModal(CheckRegistrationModal);

  // Custom scroll handler for loading more messages beacuse InfiniteScroll is broken for reverse lists
  const handleMessagesScroll = ({ target: { scrollTop } }) => {
    if (scrollTop < 5 && !loading) {
      loadMore();
    }

    // a quick hack to move scroll a tiny bit down - this enables fast scroll
    // otherwise users needed to scroll down and back up again to load more messages
    if (scrollTop === 0) scrollRef.current.scrollTo(0, 1);

    // Determine whether to show "scroll to bottom" button based on current div scroll location
    setShowScrollToBottom(
      scrollRef.current.scrollHeight - scrollRef.current.scrollTop >
        scrollRef.current.clientHeight + 300,
    );
  };

  const handleRefresh = () => {
    setRefreshing(true);
    resetPagination();
    setShouldRefetchMessages(true);
    setRefreshing(false);
  };

  const isEmailResponseType =
    resolveResponseMessageType(threadType, contactable_type, messages) === 'EmailMessage';
  const isUndefinedResponseType =
    resolveResponseMessageType(threadType, contactable_type, messages) === null;

  // User is not contactable if we don't have sufficient contact info for resolved response type
  const isNotContactable = isUndefinedResponseType || (isEmailResponseType && !email);

  const constructMailToLink = () => {
    const subject = encodeURI(t('common.new_outvote_message'));
    const trimmedMessage = messageContent.trim();
    const encodedBody = encodeURI(trimmedMessage);

    // don't create partial mailto links
    if (!email || !trimmedMessage) return '';

    return `mailto:${email}?subject=${subject}&body=${encodedBody}`;
  };

  // This block is to determine if the contact was transfered between campaign members
  // and allows us to preserve conversation history and the original number that was
  // used.
  function getStickyNumber() {
    let stickyNumber = null;
    if (messages && messages.length) {
      const lastMessage = messages[messages.length - 1];

      const { from_me, sender_raw, receiver_raw } = lastMessage;
      stickyNumber = from_me ? sender_raw : receiver_raw;
    }
    return stickyNumber;
  }

  const trackSendByType = type => {
    if (type === 'BroadcastMessage') {
      track('SEND_BROADCAST_MESSAGE');
    }
    if (type === 'TwilioMessage') {
      track('SEND_TWILIO_MESSAGE');
    }
    if (type === 'InternalMessage') {
      track('SEND_INTERNAL_MESSAGE');
    }
    if (type === 'EmailMessage') {
      track('SEND_EMAIL_MESSAGE');
    }
  };

  const showConfirmModal = async () => {
    const answerPromise = new Promise((resolve, reject) => {
      confirm({
        cancelText: t('common.no'),
        okText: t('common.yes'),
        onCancel() {
          resolve('no');
        },
        onOk() {
          resolve('yes');
        },
        title: t('inbox.did_you_send'),
      });
    });

    // Pass answer promise down to caller for awaiting
    return answerPromise;
  };

  const pressSend = async () => {
    // For email responses we need to wait for user to confirm that the email was sent before continuing
    if (isEmailResponseType) {
      const answer = await showConfirmModal();
      if (answer === 'no') return;
    }

    const trimmedMessage = messageContent.trim();
    if (trimmedMessage.length === 0) return;

    const type = resolveResponseMessageType(threadType, contactable_type, messages);

    const useStickyNumber = type === 'TwilioMessage';

    const messageInner = {
      activity_script_id: scriptId,
      body: trimmedMessage,
      campaign_id: campaignId,
      lock: false,
      rate_limit: false,
      sender_raw: useStickyNumber ? getStickyNumber() : undefined,
      type,
      visible: true,
    };

    if (activityId) {
      messageInner.activity_id = activityId;
    }

    if (userActivityId) {
      messageInner.user_activity_id = userActivityId;
    }

    if (type === 'TeamMessage') {
      messageInner.message_thread_id = messageThreadId;
    }

    if (optInFormId && !activityId) {
      messageInner.script_id = scriptId;
      messageInner.opt_in_form_id = optInFormId;
      delete messageInner.activity_script_id;
    }

    resolveEndpointByType(threadType)
      .send(contactId, {
        message: messageInner,
      })
      .then(() => {
        setScriptId(null);
        trackSendByType(type);
        message.success(t('inbox.message_sent'));

        const isTextMessage = type !== 'EmailMessage';
        if (isTextMessage) {
          // It's a text message thread, so add to msgs for smoother UX
          addSentFromMe(trimmedMessage);
          return;
        }

        setMessageContent('');
        resetPagination();
        setShouldRefetchMessages(true);
      })
      .catch(e => {
        if (e?.response?.data?.message) message.error(t('inbox.message_error'));
        else message.error(t('inbox.sending_failed'));
      });
  };

  const addSentFromMe = msg => {
    const timeNow = moment().toISOString();
    const newMsg = {
      aasm_state: 'sent',
      body: msg,
      created_at: timeNow,
      from_me: true,
      read_at: null,
      received_at: null,
      receiver_raw: '',
      sender_raw: '',
      sent_at: timeNow,
      type: 'TwilioMessage',
    };

    messages.unshift(newMsg);
    setMessageContent(''); // Force UI re-render
    lastMessageRef.current.scrollIntoView();
  };

  const updateContact = useCallback(
    payload => {
      users.updateContact(contactId, payload).then(() => {
        resetPagination();
        setShouldRefetchMessages(true);
      });
    },
    [contactId, resetPagination],
  );

  const toggleSidebar = useCallback(value => {
    setShowRightSideBar(value);
  }, []);

  const handleRegisterClick = useCallback(() => {
    if (campaign && person) {
      openCheckRegistrationModal({
        campaignId: campaign.id,
        contactId: person.id,
        onSelectTemplate: (template, step) => {
          if (step?.slug === 'send_vbm_automatic_info') {
            updateContact({ requested_absentee_form: 'sent_automatic_info' });
          }
          setScriptId(step.id);
          setMessageContent(interpolateMessage(template, person, user, campaign));
          closeCheckRegistrationModal();
        },
        onUpdateContact: payload => {
          updateContact(payload);
          closeCheckRegistrationModal();
        },
      });
    }
  }, [
    openCheckRegistrationModal,
    closeCheckRegistrationModal,
    user,
    campaign,
    person,
    updateContact,
  ]);

  // Thread archiving
  const archiveThread = async () => {
    usersAPI.archiveThread(messageThreadId, true).then(data => {
      if (data.data) {
        setRefreshMessageThreads(true);
        message.success(t('inbox.thread_archived'));
      } else {
        message.error(t('inbox.archiving_failed'));
      }
    });
  };

  const reassignToAdmin = messageThreadId => {
    usersAPI
      .reassignToAdmin({ messageThreadId })
      .then(() => setRefreshMessageThreads(true))
      .catch(err =>
        message.error(err?.response?.data?.message || t('common.something_went_wrong')),
      );
  };

  const reassignAllToAdmin = userActivityId => {
    usersAPI
      .reassignAllToAdmin({ userActivityId })
      .then(() => setRefreshMessageThreads(true))
      .catch(err =>
        message.error(err?.response?.data?.message || t('common.something_went_wrong')),
      );
  };

  // Close thread and refresh messages
  useEffect(() => {
    if (refreshMessageThreads) {
      handleThreadsRefresh();
      setMessageThreadParams(null);
      setRefreshMessageThreads(false);
    }
  }, [handleThreadsRefresh, refreshMessageThreads, setMessageThreadParams]);

  const handleInput = useCallback(
    message => {
      const withProfanity = checkForProfanity(message, threadType);
      setMessageContent(message);
      setProfaneWordDetected(withProfanity);
    },
    [threadType],
  );

  const disabled_canvasser_twilio =
    is_canvasser_action &&
    (!has_canvasser_permissions || contactable_type === 'User' || !contact_has_phone);

  const sendDisabled =
    isNotContactable ||
    !messageContent.trim().length ||
    disabled_canvasser_twilio ||
    profaneWordDetected;

  const textDisabled =
    (threadType === 'peer_to_peer' && p2p_free_response_disabled) || disabled_canvasser_twilio;

  const showFillReportButton =
    !showRightSideBar && threadType !== 'user_to_user' && activityId !== null;

  return (
    <Fragment>
      <ThreadAndRightPanel showRightSideBar={showRightSideBar}>
        <CenteredContainer>
          <MessageThreadHeader
            archived={archived}
            teamName={metadata?.team?.name}
            firstName={first_name}
            lastName={last_name}
            refreshing={refreshing}
            setMessageThreadParams={setMessageThreadParams}
            toggleSidebar={toggleSidebar}
            showRightSideBar={showRightSideBar}
            handleRefresh={handleRefresh}
            reassignToAdmin={reassignToAdmin}
            reassignAllToAdmin={reassignAllToAdmin}
            showFillReportButton={showFillReportButton}
            archiveThread={archiveThread}
            kind={threadType}
            currentThreadId={messageThreadId}
            currentActivityId={userActivityId}
          />
          <ScrollSection ref={scrollRef} onScroll={handleMessagesScroll}>
            <Messages>
              {loading && <MessageThreadLoading />}
              {/* Improvised reverse infinite scroll */}
              {messages
                ?.map((item, index) => {
                  // Attach lastMessageRef to the last message (list will be reversed)
                  const ref = index === 0 ? lastMessageRef : undefined;
                  return <Message key={index} ref={ref} {...item} />;
                })
                .reverse()}
            </Messages>
            <MessageInput
              ref={messageInputRef}
              constructMailToLink={constructMailToLink}
              isEmailResponseType={isEmailResponseType}
              messageContent={messageContent}
              pressSend={pressSend}
              sendDisabled={sendDisabled}
              setMessageContent={handleInput}
              textDisabled={textDisabled}
              profaneWordDetected={profaneWordDetected}
            />
            {templates?.length > 0 && (
              <ScriptsSection
                textDisabled={textDisabled}
                templates={templates}
                onOpenScripts={() => {
                  lastScriptRef?.current?.scrollIntoView({ behavior: 'smooth' });
                }}
                onSelectTemplate={handleSelectTemplate}
              />
            )}
            {/* This div needs to be below scripts container */}
            <div ref={lastScriptRef} />

            {showScrollToBottom && (
              <BottomArrowContainer
                onClick={() => {
                  lastScriptRef.current.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                <ArrowDownOutlined />
              </BottomArrowContainer>
            )}
          </ScrollSection>
        </CenteredContainer>
        {showRightSideBar && (
          <ReportingSidebar
            archived={archived}
            archiveThread={archiveThread}
            threadType={threadType}
            activityId={activityId}
            contact={person}
            toggleSidebar={toggleSidebar}
            canRegister={
              contactId &&
              threadType !== 'user_to_user' &&
              get(campaign, 'registration_feature_enabled')
            }
            campaignId={campaignId}
            reportActivityId={campaign?.report_activity_id}
            onRegisterClick={handleRegisterClick}
            userActivityId={userActivityId}
          />
        )}
      </ThreadAndRightPanel>
    </Fragment>
  );
};

MessageThread.propTypes = {
  handleThreadsRefresh: PropTypes.func.isRequired,
  messageThreadId: PropTypes.number.isRequired,
  setMessageThreadParams: PropTypes.func.isRequired,
};

export default MessageThread;
