import axios from 'axios';
import routes from './routes';

const { campaigns: route, contacts } = routes;

const campaign = {
  confirmMyAddress: async ({ campaign_id, id, address_info, h }) => {
    return axios.post(`${contacts}/${id}/confirm_address`, {
      campaign_id: campaign_id,
      h,
      supplied_voting_form: address_info,
    });
  },
  confirmMyRecord: async ({
    campaign_id,
    contact_id,
    contact_referral_id,
    user_referral_id,
    voter,
    opt_in,
    email,
    phone,
    h,
    first_name,
    last_name,
  }) => {
    return axios.post(`${contacts}/confirm_my_record`, {
      campaign_id,
      contact_id,
      contact_referral_id,
      email,
      first_name,
      h,
      last_name,
      opt_in,
      phone,
      user_referral_id,
      voter,
    });
  },

  confirmUnregistered: async ({
    campaign_id,
    contact_id,
    contact_referral_id,
    user_referral_id,
    opt_in,
    email,
    phone,
    h,
    first_name,
    last_name,
    registration_status,
  }) => {
    return axios.post(`${contacts}/confirm_unregistered`, {
      campaign_id,
      contact_id,
      contact_referral_id,
      email,
      first_name,
      h,
      last_name,
      opt_in,
      phone,
      registration_status,
      user_referral_id,
    });
  },

  findCampaign: async code => {
    return axios.get(`/api/v2/campaigns/find_by_code?join_code=${code}`);
  },

  gaRegister: async ({ campaign_slug, contact_path, phone }) => {
    return axios.post(`${route}/${campaign_slug}/activities/ga_register`, { contact_path, phone });
  },

  getActivityScripts: async (campaign_id, activity_id, params = {}) => {
    return axios.get(`${route}/${campaign_id}/activities/${activity_id}/scripts`, {
      params: { page: 1, per_page: 100, ...params },
    });
  },

  getCampaign: async ({ id }) => {
    return axios.get(`${route}/${id}`);
  },

  getCampaignActivities: async ({ id, page, perPage, ...rest }, axiosHeaders) => {
    return axios.get(`${route}/${id}/activities`, {
      params: { key: 'activities', page, per_page: perPage || 20, ...rest },
      ...axiosHeaders,
    });
  },

  getCampaignLeaderboard: async ({ id, per_page = 30, page = 1, ...rest }, axiosHeaders) => {
    return axios.get(`${route}/${id}/leaderboard`, {
      params: {
        page,
        per_page,
        ...rest,
      },
      ...axiosHeaders,
    });
  },

  getCategories: async () => axios.get('/api/v2/client/categories'),

  getCategoryCampaigns: async category_id => {
    return axios.get(`/api/v2/client/categories/${category_id}/campaigns/`);
  },

  getCauses: async () => axios.get('/api/v2/client/causes'),

  getMyRecord: async ({ id, h }) => {
    return axios.get(`${contacts}/${id}/my_record`, { params: { h: h } });
  },

  getPublicCampaign: async ({ id }) => {
    return axios.get(`${route}/${id}/public_show`);
  },

  getScripts: async message_thread_id => {
    return axios.get(`/api/v2/client/message_threads/${message_thread_id}/scripts`, {
      params: { page: 1, per_page: 50 },
    });
  },

  getStateInfo: async ({ state_abbrev }) => {
    return axios.post(`${contacts}/voting_info`, { state_abbrev: state_abbrev });
  },

  getUserContactListContacts: async ({ id, per_page = 100, ...rest } = {}, axiosHeaders) => {
    return axios.get(`/api/v2/client/activities/${id}/contact_list_contacts`, {
      params: { per_page, ...rest },
      ...axiosHeaders,
    });
  },

  submitPledge: async ({
    campaign_id,
    contact_id,
    contact_referral_id,
    user_referral_id,
    voter,
    opt_in,
    email,
    phone,
    h,
    first_name,
    last_name,
    state_abbrev,
  }) => {
    return axios.post(`${contacts}/submit_pledge`, {
      campaign_id,
      contact_id,
      contact_referral_id,
      email,
      first_name,
      h,
      last_name,
      opt_in,
      phone,
      state_abbrev,
      user_referral_id,
      voter,
    });
  },
};

export default campaign;
