import { message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import logo from '../../images/app-logo-primary.png';
import { auth, campaigns } from '../../services/api';
import ImpactiveButton from '../ImpactiveButton';
import Loading from '../common/Loading';
import { replaceDefaultCampaignImage } from '@web/utils/string';

const PasswordlessConfirmPage = ({ isSignup }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { campaign_slug } = useParams();

  const title = isSignup ? t('titles.sign_up') : t('titles.sign_in');
  useDocumentTitle(title);

  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState(null);
  const [inFlight, setInFlight] = useState(null);

  const {
    location: { state },
  } = history;
  const email = state && state.email;

  useEffect(() => {
    // If there's no email, send them back
    if (!email) {
      onBackClick();
      return;
    }

    if (!isSignup) {
      // Only have campaign headers when signing up
      setLoading(false);
      return;
    }

    if (!campaign_slug) {
      setLoading(false);
      return;
    }

    campaigns
      .getPublicCampaign({ id: campaign_slug })
      .then(({ data: { data } }) => {
        setCampaign(data);
        setLoading(false);
      })
      .catch(e => {
        setCampaign(null);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResendClick = () => {
    if (inFlight) {
      return;
    }

    setInFlight(true);

    if (!isSignup) {
      auth.sendMagicLinkEmail(email, 'login').then(onResendSuccess).catch(onResendFail);
      return;
    }

    if (campaign_slug && campaign) {
      auth
        .sendMagicLinkEmail(email, campaign_slug, campaign_slug)
        .then(onResendSuccess)
        .catch(onResendFail);
      return;
    }

    auth.sendMagicLinkEmail(email, 'signup').then(onResendSuccess).catch(onResendFail);
    return;
  };

  const onResendFail = () => {
    // fake debounce, so they can't click spam
    // todo: add a real debounce when we have time on resend click
    setTimeout(() => {
      setInFlight(false);
      message.error(t('errors.default'));
    }, 2000);
  };

  const onResendSuccess = () => {
    setTimeout(() => {
      setInFlight(false);
      message.success(t('auth.resend_success'));
    }, 2000);
  };

  const onBackClick = () => {
    if (!isSignup) {
      // on login now, so nav to sign up
      history.push(`/auth/login`);
      return;
    }

    if (campaign_slug && campaign) {
      history.push(`/auth/signup/${campaign_slug}`);
      return;
    }

    history.push(`/auth/signup`);
  };

  if (loading) {
    return (
      <div className="d-flex flex-row justify-content-center">
        <Loading />
      </div>
    );
  }

  const isCampaignPage = campaign && campaign.name;
  const showCampaignImg = campaign && replaceDefaultCampaignImage(campaign.url_profile_img);

  return (
    <div className="h-100 bg-auth-cyan d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex flex-row justify-content-center my-4">
        <img src={logo} alt="site-logo" height="27" />
      </div>

      <div className="card fixed-auth">
        {!!showCampaignImg && (
          <div className="d-flex flex-row justify-content-center my-4">
            <img
              style={{ maxHeight: '80px' }}
              src={showCampaignImg}
              className="img-fluid"
              alt={campaign.name}
              height="80"
            />
          </div>
        )}

        {!!isCampaignPage && <div className="pb-3 text-center">{campaign.name}</div>}

        <div className="card-title bolder">{t('auth.check-your-inbox')}</div>

        {!isSignup && (
          <>
            <div className="text-center">If {email} is associated with an existing account</div>
            <div className="text-center">we'll send a one-time link to sign in</div>
          </>
        )}

        {!!isSignup && (
          <>
            <div className="text-center">A confirmation link has been sent to {email}</div>
            <div className="text-center">Click the link to complete your sign up process.</div>
          </>
        )}

        <div className="text-center my-4">Didn't get an email?</div>

        <ImpactiveButton
          htmlType="submit"
          size="large"
          onClick={onResendClick}
          disabled={!!inFlight}
          loading={!!inFlight}
        >
          Resend Email
        </ImpactiveButton>

        <div className="line-break w-100 mt-4 mb-5"></div>

        <div className="d-flex flex-row align-items-center justify-content-center text-center">
          <div className="text-underline pointer" onClick={onBackClick}>
            {isSignup ? t('auth.back_to_sign_up') : t('auth.back_to_sign_in')}
          </div>
        </div>
      </div>
    </div>
  );
};

PasswordlessConfirmPage.propTypes = {
  isSignup: PropTypes.bool,
};

export default PasswordlessConfirmPage;
