import * as React from 'react';
import PropTypes from 'prop-types';

function Share({ fill, ...props }) {
  return (
    <svg viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.851"
        d="M10.2334 12.0555C9.76669 12.0555 9.45558 11.6666 9.45558 11.2777C9.45558 10.8888 9.84447 10.4999 10.2334 10.4999C10.6222 10.4999 11.0111 10.8888 11.0111 11.2777C11.0111 11.6666 10.7 12.0555 10.2334 12.0555ZM3.38891 8.39991C2.61114 8.39991 1.98891 7.77769 1.98891 6.99991C1.98891 6.22214 2.61114 5.59991 3.38891 5.59991C4.16669 5.59991 4.78891 6.22214 4.78891 6.99991C4.78891 7.77769 4.16669 8.39991 3.38891 8.39991ZM10.2334 1.94436C10.7 1.94436 11.0111 2.33325 11.0111 2.72214C11.0111 3.1888 10.6222 3.49991 10.2334 3.49991C9.84447 3.49991 9.45558 3.11102 9.45558 2.72214C9.45558 2.33325 9.76669 1.94436 10.2334 1.94436ZM10.2334 9.41102C9.76669 9.41102 9.3778 9.56658 9.06669 9.79991L5.80002 7.46658C5.8778 7.15547 5.8778 6.84436 5.80002 6.53325L9.06669 4.19991C9.3778 4.43325 9.76669 4.5888 10.2334 4.5888C11.2445 4.5888 12.1 3.73325 12.1 2.72214C12.1 1.71102 11.2445 0.855469 10.2334 0.855469C9.22225 0.855469 8.36669 1.71102 8.36669 2.72214C8.36669 2.87769 8.36669 3.11102 8.44447 3.26658L5.33336 5.52214C4.94447 4.89991 4.16669 4.51102 3.38891 4.51102C1.98891 4.51102 0.900024 5.59991 0.900024 6.99991C0.900024 8.39991 1.98891 9.4888 3.38891 9.4888C4.24447 9.4888 4.94447 9.09991 5.41114 8.47769L8.52225 10.7332C8.44447 10.8888 8.44447 11.0444 8.44447 11.2777C8.44447 12.2888 9.30002 13.1444 10.3111 13.1444C11.3222 13.1444 12.1778 12.2888 12.1778 11.2777C12.1 10.1888 11.3222 9.41102 10.2334 9.41102Z"
        fill={fill || 'white'}
      />
    </svg>
  );
}

Share.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
};

Share.defaultProps = {
  height: 14,
};

export default Share;
