import React from 'react';

import { List } from 'antd';
import { Link } from 'react-router-dom';
import Note from '@web/components/Note';
import ImpactiveButton from '@web/components/ImpactiveButton';
import VotersListItem from './VotersListItem';
import ContentWrapper from '../../../../components/ContentWrapper';
import { StickyFooter } from '../../../../components/shared';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { media } from '@web/styles/theme';

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  > button {
    width: 15%;
  }
`;

const Action = styled.div`
  height: 40%;

  margin-left: 3rem;

  ${media.nxl} {
    margin-left: 0;
    margin-top: 1rem;
  }
`;

const LinkButton = styled(Link)`
  background: ${({ theme }) => theme.colors.darkBlack};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  padding: 10px 20px;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  &:hover,
  &:active {
    opacity: 0.5;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const VotersList = React.forwardRef(
  ({ voters, showVoterModal, selectVoter, selectedVoterId, onSelectClick }, ref) => {
    const { t } = useTranslation();

    if (!voters) return null;

    const hasVoters = voters.length > 0;

    return (
      <div ref={ref}>
        <ContentWrapper
          title={hasVoters ? t('add_report.find_voter.select_contact') : ''}
          subtitle={`${voters.length} ${t('add_report.find_voter.matches')}`}
        >
          {hasVoters ? (
            <>
              <List bordered={false}>
                {voters.map(item => (
                  <VotersListItem
                    key={item.voterbase_id}
                    voter={item}
                    selectVoter={selectVoter}
                    showVoterModal={showVoterModal}
                    selectedVoterId={selectedVoterId}
                  />
                ))}
              </List>
              <StickyFooter>
                <ButtonWrapper>
                  <ImpactiveButton onClick={onSelectClick} disabled={!selectedVoterId}>
                    Select
                  </ImpactiveButton>
                </ButtonWrapper>
              </StickyFooter>
            </>
          ) : (
            <Note
              title={t('idvoters.results.no_results_title')}
              actions={
                <Action>
                  <LinkButton to={'add-report/new-voter-report'}>Add Contact Manually</LinkButton>
                </Action>
              }
            >
              {t('idvoters.results.no_results_message')}
            </Note>
          )}
        </ContentWrapper>
      </div>
    );
  },
);

VotersList.propTypes = {
  onSelectClick: PropTypes.func.isRequired,
  selectedVoterId: PropTypes.string,
  selectVoter: PropTypes.func.isRequired,
  showVoterModal: PropTypes.func.isRequired,
  voters: PropTypes.arrayOf(PropTypes.object),
};

export default VotersList;
