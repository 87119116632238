import React from 'react';

import { statFormatted } from '@web/utils/other';

export const getIndividualLeaderboardColumns = () => [
  {
    accessor: 'referrer_first_name',
    Cell: row => {
      return (
        <>
          {row.original.place}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {row.value} {row.original.referrer_last_name?.[0]}.
        </>
      );
    },
    Header: 'Referrer',
    width: '34%',
  },
  {
    accessor: 'team_name',
    Cell: row => row.value,
    Header: 'Team',
    width: '33%',
  },
  {
    accessor: 'referrals_count',
    Cell: row => statFormatted(row.value),
    Header: 'Referrals',
    width: '33%',
  },
];

export const getTeamLeaderboardColumns = () => [
  {
    accessor: 'team_name',
    Cell: row => (
      <>
        {row.original.place}
        &nbsp;&nbsp;&nbsp;&nbsp;{row.value}
      </>
    ),
    Header: 'Team',
    width: '34%',
  },
  {
    accessor: 'team_referrals_count',
    Cell: row => statFormatted(row.value),
    Header: 'Referrals from Team Link',
    width: '33%',
  },
  {
    accessor: 'contact_referrals_count',
    Cell: row => statFormatted(row.value),
    Header: "Referrals from Individuals' Links",
    width: '33%',
  },
];
