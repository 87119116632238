import React from 'react';

const SentIcon = () => (
  <svg width="18" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.79541 10.3066L5.35266 13.0216L11.0647 6.49359L9.93516 5.50659L5.14716 10.9786L2.70441 9.11409L1.79541 10.3066ZM16.3147 6.49359L15.1852 5.50659L10.4084 10.9658L9.84366 10.5143L8.90616 11.6858L10.5914 13.0343L16.3147 6.49359Z"
      fill="#23293E"
    />
  </svg>
);

const ErrorIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.09199 3.13795C8.98348 2.9439 8.82518 2.78231 8.6334 2.66983C8.44163 2.55734 8.22332 2.49805 8.00099 2.49805C7.77867 2.49805 7.56036 2.55734 7.36859 2.66983C7.17681 2.78231 7.01851 2.9439 6.90999 3.13795L2.15699 11.6399C2.05067 11.8301 1.9959 12.0448 1.99811 12.2626C2.00032 12.4805 2.05943 12.694 2.16959 12.882C2.27974 13.0699 2.43711 13.2258 2.6261 13.3342C2.81509 13.4426 3.02912 13.4998 3.24699 13.4999H12.751C12.9689 13.4999 13.1831 13.4429 13.3722 13.3346C13.5614 13.2263 13.7189 13.0704 13.8291 12.8824C13.9394 12.6944 13.9986 12.4808 14.0009 12.2629C14.0031 12.0449 13.9484 11.8302 13.842 11.6399L9.09199 3.13795ZM8.74999 10.7499C8.74999 10.9489 8.67097 11.1396 8.53032 11.2803C8.38967 11.4209 8.1989 11.4999 7.99999 11.4999C7.80108 11.4999 7.61031 11.4209 7.46966 11.2803C7.32901 11.1396 7.24999 10.9489 7.24999 10.7499C7.24999 10.551 7.32901 10.3603 7.46966 10.2196C7.61031 10.079 7.80108 9.99995 7.99999 9.99995C8.1989 9.99995 8.38967 10.079 8.53032 10.2196C8.67097 10.3603 8.74999 10.551 8.74999 10.7499ZM7.49999 8.49995V5.99995C7.49999 5.86734 7.55267 5.74016 7.64644 5.6464C7.74021 5.55263 7.86738 5.49995 7.99999 5.49995C8.1326 5.49995 8.25978 5.55263 8.35355 5.6464C8.44731 5.74016 8.49999 5.86734 8.49999 5.99995V8.49995C8.49999 8.63256 8.44731 8.75974 8.35355 8.8535C8.25978 8.94727 8.1326 8.99995 7.99999 8.99995C7.86738 8.99995 7.74021 8.94727 7.64644 8.8535C7.55267 8.75974 7.49999 8.63256 7.49999 8.49995Z"
      fill="#F5222D"
    />
  </svg>
);

export { SentIcon, ErrorIcon };
