import { SyncOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, fonts, media, sizes } from '@web/styles/theme';
import InboxFilters from './InboxFilters';
import FiltersSelect from './FiltersSelect';

const Container = styled.div`
  border-bottom: 1px solid ${colors.borderGray};
  @media screen and (min-width: 1600px) {
    padding: 2rem 2.75rem 2rem 3.75rem;
  }
  ${media.xxl} {
    padding: 1rem 1.75rem 1rem 2.75rem;
  }
  @media (max-width: ${sizes.xxl}) and (min-width: 900px) {
    padding: 2rem 2.75rem 2rem 3.75rem;
  }
  padding: 20px 25px;
`;

const Title = styled.div`
  font-size: 30px;
  color: ${colors.black};
  font-family: ${fonts.bold};
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InfoIcon = styled(InfoCircleOutlined)`
  && {
    color: ${colors.cyan};
    cursor: pointer;
    font-size: 16px;
    margin-left: 8px;
    vertical-align: middle;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InboxHeader = ({ onFilterChange, refreshing, onRefresh, filter }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>
        <div>
          <span tabIndex={0}>{t('inbox.header')}</span>
          <Tooltip placement="right" trigger="hover" title={t('inbox.description')}>
            <InfoIcon role="tooltip" tabIndex={0} aria-label={t('inbox.description')} />
          </Tooltip>
        </div>
        <ButtonsWrapper>
          <FiltersSelect onFilterChange={onFilterChange} filter={filter} />
          <Button
            data-testid="refresh-button"
            type="link"
            icon={<SyncOutlined />}
            disabled={refreshing}
            onClick={onRefresh}
            aria-label="Refresh messages"
          />
        </ButtonsWrapper>
      </Title>

      <InboxFilters onFilterChange={onFilterChange} filter={filter} />
    </Container>
  );
};

InboxHeader.propTypes = {
  filter: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  refreshing: PropTypes.bool,
};

InboxHeader.defaultProps = {};

export default InboxHeader;
