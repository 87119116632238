import * as React from 'react';
import PropTypes from 'prop-types';

import { Body, Title } from '@web/components/DialerActivity/components/TextStyles';
import BackButton from '../../components/BackButton';
import HeadphonesIcon from '@web/components/DialerActivity/icons/Headphones';
import NextButton from '../../components/NextButton';
import Page, { CopyWrapper, PageCopy } from '../../components/Page';
import { useTranslation } from 'react-i18next';

function Headphones({ onBack, onNext }) {
  const { t } = useTranslation();

  return (
    <Page>
      <HeadphonesIcon size={118} />
      <PageCopy>
        <CopyWrapper>
          <Title>{t('activity.phone_bank.grab_headphones')}</Title>
          <Body>{t('activity.phone_bank.grab_headphones_description')}</Body>
        </CopyWrapper>
      </PageCopy>
      <NextButton onClick={onNext} />
      <BackButton onClick={onBack}>{t('common.cancel')}</BackButton>
    </Page>
  );
}

Headphones.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

Headphones.defaultProps = {};

export default Headphones;
