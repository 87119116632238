import React from 'react';

import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.dimGray2};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 14px;
`;

const BreadcrumbContainer = ({ urlStack }) => {
  return (
    <Breadcrumb separator=">">
      {urlStack.map((item, index) => (
        <Breadcrumb.Item key={item.url ?? item.name}>
          <StyledLink
            as={urlStack.length === index + 1 ? 'span' : Link}
            to={{ pathname: item.url, state: item.state }}
          >
            {item.name}
          </StyledLink>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

BreadcrumbContainer.propTypes = {
  urlStack: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      state: PropTypes.object,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default BreadcrumbContainer;
