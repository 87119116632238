import React, { useState } from 'react';

import ImpactiveModal from '@web/components/common/ImpactiveModal';
import ActivityButton from './ActivityButton';
import { ActivityType, Body, Media, Title, Date } from './sharedStyledComponents';
import ActivityCompletedModal from '../ActivityDetail/ActivityCompletedModal';

import PropTypes from 'prop-types';
import { friendlyTypeNameFor } from '../../constants/activityTypes';
import { convertLinks, dateToAge } from '../../utils/string';
import { useTranslation } from 'react-i18next';

const ActivityPreviewModal = ({
  activity,
  completed,
  onActivityActionButtonPress,
  onCancel,
  userActivity,
  visible,
}) => {
  const { t } = useTranslation();
  const [activityCompletedModalVisible, setActivityCompletedModalVisible] = useState(false);

  if (!visible) return null;

  const closed = activity.aasm_state === 'closed';
  return (
    <>
      <ImpactiveModal visible={visible} onCancel={onCancel} width={700}>
        {activity.media_url && <Media src={activity.media_url} isModal />}

        <div>
          <ActivityType>{friendlyTypeNameFor(activity.type)}</ActivityType>
          <Title>{activity.title}</Title>
          <Date>
            {dateToAge(activity.published_at)}
            {closed && ` (${t('campaign.activity.closed')})`}
          </Date>
        </div>

        <Body
          isModal
          dangerouslySetInnerHTML={{
            __html: convertLinks(activity.description),
          }}
        />

        <ActivityButton
          activity={activity}
          setActivityCompletedModalVisible={() => {
            setActivityCompletedModalVisible(true);
          }}
          completed={completed}
          userActivity={userActivity}
          onActivityActionButtonPress={onActivityActionButtonPress}
          isModal
        />
      </ImpactiveModal>
      {/* Modal will be shown for manually confirming completion of WebActivity and ShareActivity */}
      {activityCompletedModalVisible && (
        <ActivityCompletedModal
          campaignName={activity.campaign.name}
          campaignId={activity.campaign.id}
          activityTitle={activity.title}
          activityId={activity.id}
          userActivityId={userActivity?.id}
          onModalClosedCallback={() => {
            setActivityCompletedModalVisible(false);
          }}
        />
      )}
    </>
  );
};

ActivityPreviewModal.propTypes = {
  activity: PropTypes.object,
  completed: PropTypes.bool,
  isFeatured: PropTypes.bool,
  onActivityActionButtonPress: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  userActivity: PropTypes.object,
  visible: PropTypes.bool.isRequired,
};

export default ActivityPreviewModal;
