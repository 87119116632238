import * as React from 'react';
// import PropTypes from 'prop-types';

function Calendar(props) {
  return (
    <svg height="16" viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.640747 14.4688C0.640747 14.7799 0.892114 15.0312 1.20325 15.0312H14.1407C14.4519 15.0312 14.7032 14.7799 14.7032 14.4688V7.08594H0.640747V14.4688ZM14.1407 2.23438H11.1876V1.10938C11.1876 1.03203 11.1243 0.96875 11.047 0.96875H10.0626C9.98528 0.96875 9.922 1.03203 9.922 1.10938V2.23438H5.422V1.10938C5.422 1.03203 5.35872 0.96875 5.28137 0.96875H4.297C4.21965 0.96875 4.15637 1.03203 4.15637 1.10938V2.23438H1.20325C0.892114 2.23438 0.640747 2.48574 0.640747 2.79688V5.89062H14.7032V2.79688C14.7032 2.48574 14.4519 2.23438 14.1407 2.23438Z"
        fill="#23293E"
      />
    </svg>
  );
}

Calendar.propTypes = {};

Calendar.defaultProps = {};

export default Calendar;
