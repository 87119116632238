import React, { useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled, { css } from 'styled-components';

import i18n, { setAppLanguage } from '@web/components/i18n';

const Wrapper = styled.div`
  display: flex;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 10px;
  border: solid 1px black;
  border-radius: 6px;
`;

const StyledButton = styled.button`
  width: 50px;
  ${({ $active }) =>
    $active
      ? css`
          background-color: black;
          color: white;
          border: none;
        `
      : css`
          background-color: transparent;
          color: black;
          border: none;
        `}
`;

const LanguageToggle = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const defaultLanguage = useMemo(() => {
    const defaultValue = query.get('language');
    if (!['en', 'es'].includes(defaultValue)) {
      return 'en';
    }
    return defaultValue;
  }, [query]);

  const [activeLanguage, setActiveLanguage] = useState(defaultLanguage);

  useEffect(() => {
    if (i18n.language !== activeLanguage) {
      const url = new URL(window.location.href);
      url.searchParams.set('language', activeLanguage);
      history.replace(`${pathname}?${url.searchParams.toString()}`);
      setAppLanguage(activeLanguage, true);
    }
  }, [activeLanguage, history, pathname]);

  return (
    <Wrapper>
      <StyledButton $active={activeLanguage === 'en'} onClick={() => setActiveLanguage('en')}>
        EN
      </StyledButton>
      <StyledButton $active={activeLanguage === 'es'} onClick={() => setActiveLanguage('es')}>
        ES
      </StyledButton>
    </Wrapper>
  );
};

export default LanguageToggle;
