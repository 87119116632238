import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import styled from 'styled-components';
import { media } from '@web/styles/theme';

import CampaignActionLink from './components/CampaignActionLink';
import placeholder from './assets/actionPlaceholder.png';

const StyledCampaignActionCard = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  padding: 15px 15px 25px;
  box-shadow: 0px 14px 44px rgba(89, 99, 134, 0.11);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;

const ActionMedia = styled.div`
  width: 100%;
  border-radius: 16px;
`;

const ActionImage = styled.img`
  border-radius: 16px;
  border-radius: 16px;
  width: 100%;
  height: fit-content;

  width: 100%;
  height: 60vw;
  object-fit: cover;

  ${media.lg} {
    height: 30vh;
  }
`;

const ActionDetails = styled.div`
  padding: 15px 15px 0;
  height: fit-content;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ActionTitle = styled.p`
  font-weight: 600;
  font-size: 1.2em;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.blackL25};
  margin: 15px 0px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActionDescription = styled.p`
  margin-bottom: 40px;
  color: #717171;
  flex-grow: 1;

  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const ActionFooter = styled.div`
  margin-top: auto;
  display: grid;
  grid-template-columns: 1fr 140px;
  gap: 10px;
`;

const ActionDate = styled.p`
  float: left;
  color: ${({ theme }) => theme.colors.mediumGray};
  opacity: 0.72;
  margin: 0;
  line-height: 42px;
  font-size: 0.8rem;
`;

const CampaignActionCard = ({ campaign, item, joinCampaign }) => {
  const { _type: type, completed } = item;
  const isUserActivity = type === 'user_activities';
  const userActivityId = isUserActivity ? item.id : null;
  const activity = isUserActivity ? item.activity : item;
  const closed = activity.aasm_state === 'closed';
  const publishedAt = activity.published_at
    ? dayjs(activity.published_at).format('MM[.]DD[.]YYYY')
    : '';

  if (activity.type === 'DialerActivity') {
    return null;
  }

  return (
    <StyledCampaignActionCard style={{ opacity: closed ? 0.5 : 1.0 }}>
      <ActionMedia>
        <ActionImage
          src={activity.media_url ? activity.media_url : placeholder}
          alt={activity.title}
        />
      </ActionMedia>

      <ActionDetails>
        <ActionTitle>{activity.title}</ActionTitle>
        <ActionDescription>{activity.description}</ActionDescription>
        <ActionFooter>
          <ActionDate>{publishedAt}</ActionDate>
          <CampaignActionLink
            activity={activity}
            campaign={campaign}
            completed={completed}
            joinCampaign={joinCampaign}
            userActivityId={userActivityId}
          />
        </ActionFooter>
      </ActionDetails>
    </StyledCampaignActionCard>
  );
};

CampaignActionCard.propTypes = {
  campaign: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  joinCampaign: PropTypes.func.isRequired,
};

export default CampaignActionCard;
