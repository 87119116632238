import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from '@web/components/Headcount/components/Text';
import CircledCheckmarkIcon from '@web/components/Headcount/components/icons/CircledCheckmarkIcon';

import { useTranslation } from 'react-i18next';

const BannerWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const AbsoluteWrapper = styled.div`
  animation: 0.5s ease-out 0.5s 1 slideInFromTop forwards,
    0.5s ease-out 10s 1 slideOutToTop forwards;
  display: flex;
  flex-direction: row;
  justify-content: center;
  opacity: 0;
  position: absolute;
  top: -120px;
  width: 100%;

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-200%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideOutToTop {
    0% {
      opacity: 1;
      visibility: visible;
    }
    99% {
      height: auto;
      visibility: visible;
    }
    100% {
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
`;

const StyledBanner = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.88);
  border-radius: 12px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  gap: 8px;
  height: auto;
  justify-content: center;
  padding: 16px 24px;
  text-align: center;
  width: 342px;
`;

const StyledText = styled(Text)`
  line-height: 1.2;
`;

function Banner({ activity }) {
  const { t } = useTranslation();

  const isCannabis = React.useMemo(
    () => activity?.campaign?.cannabis_registration_enabled,
    [activity?.campaign.cannabis_registration_enabled],
  );

  const isHeadcountMtvEnabled = React.useMemo(
    () => activity?.campaign?.headcount_mtv_enabled,
    [activity?.campaign?.headcount_mtv_enabled],
  );
  const isHeadcountRctEnabled = React.useMemo(
    () => activity?.campaign?.headcount_rct_enabled,
    [activity?.campaign?.headcount_rct_enabled],
  );

  if (isHeadcountMtvEnabled || isHeadcountRctEnabled) return null;

  return (
    <BannerWrapper>
      <AbsoluteWrapper>
        <StyledBanner>
          <StyledText color="white" variant="f2">
            {isCannabis
              ? t('check_registration.headcount.cannabisBanner')
              : t('check_registration.headcount.banner')}
          </StyledText>
          <CircledCheckmarkIcon />
        </StyledBanner>
      </AbsoluteWrapper>
    </BannerWrapper>
  );
}

Banner.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      cannabis_registration_enabled: PropTypes.bool,
      headcount_mtv_enabled: PropTypes.bool,
      headcount_rct_enabled: PropTypes.bool,
    }),
  }),
};

Banner.defaultProps = {};

export default Banner;
