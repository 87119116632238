import * as React from 'react';
// import PropTypes from 'prop-types';
import { Title } from '@web/components/DialerActivity/components/TextStyles';

function PhoneBankEnded() {
  return <Title>The phone bank has ended.</Title>;
}

PhoneBankEnded.propTypes = {};

PhoneBankEnded.defaultProps = {};

export default PhoneBankEnded;
