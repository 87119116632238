import { bool } from 'prop-types';
import React from 'react';

const Ticket = ({ isFriendLookUp, isMyActivity, ...props }) => {
  return isFriendLookUp ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_290_27498)">
        <path
          d="M7.3685 31.0126L20.0495 43.6936C20.2652 43.9093 20.6169 43.9112 20.8345 43.6936L43.3638 21.1642C43.5795 20.9486 43.5814 20.5969 43.3638 20.3793L30.6828 7.69827C30.4671 7.48261 30.1155 7.48067 29.8979 7.69827L7.3685 30.2277C7.1509 30.4453 7.15284 30.7969 7.3685 31.0126ZM35.6993 27.088C35.3107 28.8638 33.0123 28.8657 32.6257 27.088C32.5985 26.9675 32.5985 26.8432 32.6257 26.7227C33.0142 24.947 35.3126 24.945 35.6993 26.7227C35.7245 26.8451 35.7245 26.9695 35.6993 27.088ZM32.4294 23.8182C32.0409 25.5939 29.7425 25.5959 29.3558 23.8182C29.3286 23.6977 29.3286 23.5734 29.3558 23.4529C29.7444 21.6771 32.0428 21.6752 32.4294 23.4529C32.4527 23.5734 32.4547 23.6997 32.4294 23.8182ZM28.9653 20.3541C28.5767 22.1298 26.2783 22.1318 25.8917 20.3541C25.8645 20.2336 25.8645 20.1093 25.8917 19.9888C26.2803 18.213 28.5787 18.2111 28.9653 19.9888C28.9886 20.1093 28.9906 20.2355 28.9653 20.3541ZM25.6955 17.0842C25.3069 18.86 23.0085 18.8619 22.6219 17.0842C22.5947 16.9638 22.5947 16.8394 22.6219 16.719C23.0105 14.9432 25.3089 14.9412 25.6955 16.719C25.7188 16.8394 25.7207 16.9657 25.6955 17.0842Z"
          fill="#A65F3E"
        />
        <path
          d="M6.78754 30.4286L19.4686 43.1096C19.6842 43.3253 20.0359 43.3272 20.2535 43.1096L42.7829 20.5802C42.9985 20.3646 43.0005 20.0129 42.7829 19.7953L30.1018 7.11429C29.8862 6.89863 29.5345 6.89669 29.3169 7.11429L6.78754 29.6437C6.56994 29.8613 6.57188 30.2129 6.78754 30.4286ZM35.1183 26.504C34.7297 28.2798 32.4313 28.2817 32.0447 26.504C32.0175 26.3836 32.0175 26.2592 32.0447 26.1388C32.4333 24.363 34.7317 24.361 35.1183 26.1388C35.1436 26.2612 35.1436 26.3855 35.1183 26.504ZM31.8485 23.2342C31.4599 25.01 29.1615 25.0119 28.7749 23.2342C28.7477 23.1137 28.7477 22.9894 28.7749 22.8689C29.1634 21.0932 31.4618 21.0912 31.8485 22.8689C31.8718 22.9894 31.8737 23.1157 31.8485 23.2342ZM28.3844 19.7701C27.9958 21.5458 25.6974 21.5478 25.3108 19.7701C25.2836 19.6496 25.2836 19.5253 25.3107 19.4048C25.6993 17.629 27.9977 17.6271 28.3844 19.4048C28.4077 19.5253 28.4096 19.6516 28.3844 19.7701ZM25.1145 16.5002C24.726 18.276 22.4275 18.278 22.0409 16.5002C22.0137 16.3798 22.0137 16.2554 22.0409 16.135C22.4295 14.3592 24.7279 14.3573 25.1145 16.135C25.1378 16.2554 25.1398 16.3817 25.1145 16.5002Z"
          fill="#FFD54F"
        />
        <path
          opacity={0.8}
          d="M13.0933 32.1123L19.4193 25.7864M16.1009 35.1199L20.5831 30.6377M23.6839 27.5369L24.6242 26.5965"
          stroke="#4E342E"
          strokeWidth={1.95115}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          opacity={0.5}
          d="M18.5201 38.1211L19.103 37.5382L20.9098 39.3451L20.327 39.928L18.5201 38.1211ZM23.4064 33.2348L23.9893 32.6519L25.7961 34.4588L25.2133 35.0417L23.4064 33.2348ZM24.4633 32.1779L25.0462 31.595L26.853 33.4019L26.2702 33.9847L24.4633 32.1779ZM19.6683 36.9729L20.8341 35.8071L22.6409 37.614L21.4752 38.7797L19.6683 36.9729ZM25.5338 31.1074L26.8938 29.7474L28.7007 31.5542L27.3407 32.9142L25.5338 31.1074ZM21.3528 35.2884L22.9071 33.7341L24.7139 35.541L23.1597 37.0953L21.3528 35.2884Z"
          fill="#6D4C41"
        />
        <path
          d="M29.4918 6.93943L6.61074 29.8205C6.49028 29.9409 6.49222 30.1333 6.61074 30.2518L8.54582 32.1869L23.0104 17.7223C22.5461 17.5377 22.1536 17.1142 22.0273 16.4439C22.0098 16.3526 22.0098 16.2554 22.0273 16.1641C22.3168 14.7361 23.8283 14.4292 24.6424 15.2432C24.792 15.3928 24.9047 15.5677 24.9766 15.7522L31.8562 8.87257L29.9212 6.93749C29.8065 6.81897 29.6122 6.81898 29.4918 6.93943Z"
          fill="#E2A610"
        />
        <path
          opacity={0.5}
          d="M28.7132 15.5771L32.2551 12.0353L35.4977 15.2779L31.9559 18.8198L28.7132 15.5771Z"
          fill="#6D4C41"
        />
        <path
          opacity={0.8}
          d="M33.8877 20.2979L35.6499 18.5357M35.1156 21.5257L37.89 18.7513"
          stroke="#4E342E"
          strokeWidth={0.731681}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_290_27498">
          <rect
            width={35.1695}
            height={35.1695}
            fill="white"
            transform="translate(0.25264 25.123) rotate(-45)"
          />
        </clipPath>
      </defs>
    </svg>
  ) : isMyActivity ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={68}
      height={68}
      viewBox="0 0 68 68"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_414_5631)">
        <path
          d="M9.90917 41.8833L27.0587 59.0328C27.3503 59.3245 27.8259 59.3271 28.1202 59.0328L58.5884 28.5646C58.88 28.273 58.8827 27.7974 58.5884 27.5031L41.4389 10.3536C41.1472 10.0619 40.6716 10.0593 40.3774 10.3536L9.90917 40.8218C9.61489 41.1161 9.61752 41.5916 9.90917 41.8833ZM48.223 36.5758C47.6975 38.9773 44.5892 38.9799 44.0663 36.5758C44.0296 36.4129 44.0296 36.2447 44.0663 36.0818C44.5918 33.6803 47.7001 33.6777 48.223 36.0818C48.2572 36.2474 48.2572 36.4155 48.223 36.5758ZM43.801 32.1537C43.2755 34.5553 40.1672 34.5579 39.6443 32.1537C39.6075 31.9908 39.6075 31.8227 39.6443 31.6598C40.1698 29.2583 43.2781 29.2556 43.801 31.6598C43.8325 31.8227 43.8351 31.9935 43.801 32.1537ZM39.1162 27.469C38.5907 29.8705 35.4824 29.8731 34.9595 27.469C34.9227 27.3061 34.9227 27.1379 34.9595 26.975C35.485 24.5735 38.5933 24.5709 39.1162 26.975C39.1477 27.1379 39.1503 27.3087 39.1162 27.469ZM34.6941 23.0469C34.1686 25.4484 31.0603 25.4511 30.5375 23.0469C30.5007 22.884 30.5007 22.7159 30.5375 22.553C31.063 20.1514 34.1713 20.1488 34.6941 22.553C34.7257 22.7159 34.7283 22.8866 34.6941 23.0469Z"
          fill="#A65F3E"
        />
        <path
          d="M9.12352 41.0932L26.2731 58.2428C26.5647 58.5344 27.0403 58.5371 27.3346 58.2428L57.8028 27.7746C58.0944 27.4829 58.097 27.0074 57.8028 26.7131L40.6532 9.56355C40.3616 9.2719 39.886 9.26927 39.5917 9.56355L9.12352 40.0318C8.82925 40.326 8.83187 40.8016 9.12352 41.0932ZM47.4374 35.7858C46.9119 38.1873 43.8036 38.1899 43.2807 35.7858C43.2439 35.6228 43.2439 35.4547 43.2807 35.2918C43.8062 32.8903 46.9145 32.8876 47.4374 35.2918C47.4715 35.4573 47.4715 35.6255 47.4374 35.7858ZM43.0153 31.3637C42.4898 33.7652 39.3815 33.7678 38.8587 31.3637C38.8219 31.2008 38.8219 31.0326 38.8587 30.8697C39.3842 28.4682 42.4925 28.4656 43.0153 30.8697C43.0469 31.0326 43.0495 31.2034 43.0153 31.3637ZM38.3305 26.6789C37.805 29.0804 34.6967 29.0831 34.1739 26.6789C34.1371 26.516 34.1371 26.3479 34.1739 26.185C34.6994 23.7834 37.8077 23.7808 38.3305 26.185C38.3621 26.3479 38.3647 26.5186 38.3305 26.6789ZM33.9085 22.2569C33.383 24.6584 30.2747 24.661 29.7518 22.2569C29.715 22.094 29.715 21.9258 29.7518 21.7629C30.2773 19.3614 33.3856 19.3588 33.9085 21.7629C33.94 21.9258 33.9427 22.0966 33.9085 22.2569Z"
          fill="#FFD54F"
        />
        <path
          opacity={0.8}
          d="M17.6509 43.3691L26.2059 34.8141M21.7182 47.4365L27.7798 41.3749M31.9732 37.1814L33.2449 35.9097"
          stroke="#4E342E"
          strokeWidth={3.07847}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          opacity={0.5}
          d="M24.9888 51.4951L25.777 50.7069L28.2206 53.1504L27.4323 53.9387L24.9888 51.4951ZM31.5969 44.887L32.3851 44.0988L34.8287 46.5423L34.0404 47.3306L31.5969 44.887ZM33.0262 43.4577L33.8145 42.6694L36.258 45.113L35.4698 45.9012L33.0262 43.4577ZM26.5416 49.9423L28.1181 48.3658L30.5616 50.8093L28.9852 52.3858L26.5416 49.9423ZM34.474 42.0099L36.3132 40.1707L38.7567 42.6143L36.9175 44.4535L34.474 42.0099ZM28.8196 47.6643L30.9216 45.5623L33.3652 48.0058L31.2632 50.1078L28.8196 47.6643Z"
          fill="#6D4C41"
        />
        <path
          d="M39.8282 9.32708L8.88442 40.2709C8.72152 40.4338 8.72415 40.6939 8.88442 40.8541L11.5014 43.4711L31.0629 23.9096C30.435 23.66 29.9042 23.0872 29.7334 22.1807C29.7098 22.0572 29.7098 21.9258 29.7334 21.8023C30.1249 19.8711 32.1691 19.456 33.27 20.5569C33.4723 20.7592 33.6247 20.9957 33.7219 21.2453L43.0258 11.9414L40.4089 9.32445C40.2538 9.16418 39.9911 9.16418 39.8282 9.32708Z"
          fill="#E2A610"
        />
        <path
          opacity={0.5}
          d="M38.7764 21.0098L43.5663 16.2199L47.9515 20.6051L43.1616 25.395L38.7764 21.0098Z"
          fill="#6D4C41"
        />
        <path
          opacity={0.8}
          d="M45.7729 27.3926L48.1561 25.0095M47.4335 29.0531L51.1855 25.3011"
          stroke="#4E342E"
          strokeWidth={1.15443}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_414_5631">
          <rect
            width={47.5624}
            height={47.5624}
            fill="white"
            transform="translate(0.286133 33.918) rotate(-45)"
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#A65F3E"
          d="m8.142 34.509 14.143 14.143a.62.62 0 0 0 .875 0l25.127-25.127a.62.62 0 0 0 0-.875L34.144 8.507a.62.62 0 0 0-.876 0L8.142 33.634a.62.62 0 0 0 0 .875Zm31.597-4.377c-.434 1.98-2.997 1.983-3.428 0a.921.921 0 0 1 0-.407c.433-1.98 2.996-1.983 3.428 0a.995.995 0 0 1 0 .407Zm-3.647-3.647c-.434 1.98-2.997 1.983-3.428 0a.92.92 0 0 1 0-.407c.433-1.98 2.996-1.983 3.428 0a1.03 1.03 0 0 1 0 .407Zm-3.864-3.863c-.433 1.98-2.997 1.982-3.428 0a.924.924 0 0 1 0-.408c.434-1.98 2.997-1.982 3.428 0 .026.135.028.276 0 .408Zm-3.647-3.647c-.433 1.98-2.996 1.983-3.427 0a.921.921 0 0 1 0-.407c.433-1.98 2.996-1.983 3.427 0 .027.134.029.275 0 .407Z"
        />
        <path
          fill="#FFD54F"
          d="M7.492 33.859 21.635 48a.62.62 0 0 0 .876 0l25.126-25.126a.62.62 0 0 0 0-.875L33.494 7.857a.62.62 0 0 0-.875 0L7.492 32.983a.62.62 0 0 0 0 .876Zm31.597-4.377c-.433 1.98-2.997 1.982-3.428 0a.924.924 0 0 1 0-.408c.433-1.98 2.997-1.982 3.428 0a.995.995 0 0 1 0 .408Zm-3.647-3.647c-.433 1.98-2.996 1.982-3.428 0a.92.92 0 0 1 0-.408c.434-1.98 2.997-1.982 3.428 0a1.03 1.03 0 0 1 0 .408ZM31.58 21.97c-.434 1.98-2.997 1.983-3.428 0a.924.924 0 0 1 0-.407c.433-1.98 2.997-1.983 3.428 0a1.03 1.03 0 0 1 0 .407Zm-3.647-3.646c-.433 1.98-2.997 1.982-3.428 0a.924.924 0 0 1 0-.408c.433-1.98 2.997-1.982 3.428 0 .026.135.028.275 0 .408Z"
        />
        <path
          stroke="#4E342E"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={3}
          d="m14.525 35.735 7.056-7.055M17.88 39.09l4.998-5m3.459-3.458 1.049-1.048"
          opacity={0.8}
        />
        <path
          fill="#6D4C41"
          d="m20.577 42.438.65-.65 2.015 2.015-.65.65-2.015-2.016Zm5.45-5.45.65-.65 2.015 2.015-.65.65-2.015-2.015Zm1.178-1.179.65-.65 2.016 2.015-.65.65-2.015-2.015Zm-5.347 5.348 1.3-1.3 2.015 2.015-1.3 1.3-2.015-2.015Zm6.541-6.542 1.517-1.517 2.015 2.016-1.517 1.516-2.015-2.015Zm-4.663 4.663 1.734-1.733 2.015 2.015-1.733 1.733-2.016-2.015Z"
          opacity={0.5}
        />
        <path
          fill="#E2A610"
          d="M32.814 7.662 7.295 33.18a.34.34 0 0 0 0 .481l2.158 2.159 16.132-16.133c-.518-.205-.955-.678-1.096-1.425a.84.84 0 0 1 0-.312c.323-1.593 2.009-1.935 2.916-1.027.167.167.293.362.373.567l7.673-7.672-2.158-2.159a.337.337 0 0 0-.48.003Z"
        />
        <path
          fill="#6D4C41"
          d="m31.947 17.296 3.95-3.95 3.617 3.616-3.95 3.95-3.617-3.616Z"
          opacity={0.5}
        />
        <path
          stroke="#4E342E"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={1.125}
          d="m37.717 22.559 1.965-1.966m-.596 3.335 3.095-3.094"
          opacity={0.8}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.205 27.941 27.941.206 55.676 27.94 27.941 55.677z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Ticket.propTypes = {
  isFriendLookUp: bool,
  isMyActivity: bool,
};

Ticket.defaultProps = {
  isFriendLookUp: false,
  isMyActivity: false,
};

export default Ticket;
