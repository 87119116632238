import axios from 'axios';
import routes from './routes';

const {
  campaigns: campaigns_route,
  user_activities: user_activities_route,
  user: user_route,
} = routes;

const user = {
  getProfile: async () => {
    return axios.get(user_route);
  },
  getPublicActivity: async (campaignSlug, activitySlug) => {
    return axios.get(`${campaigns_route}/${campaignSlug}/activities/${activitySlug}/show`);
  },
  getTeams: async () => {
    return axios.get(`${user_route}/teams`);
  },
  getUserActivities: async ({ page, perPage, ...rest }, axiosHeaders) => {
    return axios.get(user_activities_route, {
      params: { key: 'activities', page, per_page: perPage || 20, ...rest },
      ...axiosHeaders,
    });
  },

  getUserActivity: async id => {
    return axios.get(`${user_activities_route}/${id}`);
  },
  inspect: async () => {
    return axios.get(`${user_route}/inspect`);
  },
  lookupUserActivity: async (campaignSlug, activitySlug) => {
    return axios.get(
      `${routes.campaigns}/${campaignSlug}/activities/${activitySlug}/my_user_activity`,
    );
  },

  lookupUserActivityManual: ({ campaign_slug, activity_id, auth_token, force_create = false }) => {
    // track('SIGN_UP_THROUGH_ACTIVITY_PAGE');
    return {
      headers: { Authorization: auth_token },
      method: 'get',
      url: `${user_route}/campaigns/${campaign_slug}/activities/${activity_id}?force_create=${force_create}`,
    };
  },

  unlockAccount: async () => {
    return axios.post(`${user_route}/unlock`);
  },

  updateProfile: async ({
    firstName,
    lastName,
    teamName,
    phone = null,
    zipCode = null,
    locale = null,
    lockedCampaignId = null,
    referralHash = null,
    profileUrl,
  }) => {
    return axios.put(user_route, {
      user: {
        first_name: firstName,
        last_name: lastName,
        phone,
        profile_url: profileUrl,
        supplied_zip_code: zipCode,
        team_name: teamName,
        // add locale param only if supplied
        ...(locale && { locale }),
        // add referral_id param only if supplied
        ...(referralHash && { referral_hash: referralHash }),
        // add locked campaign id param only if supplied
        ...(lockedCampaignId && {
          locked_campaign_id: lockedCampaignId,
          onboarded_campaign_id: lockedCampaignId,
        }),
      },
    });
  },

  updateProfileOptions: ({ first_name, last_name, locale, zip, auth_token, onboarding_viewed }) => {
    return {
      data: {
        user: {
          first_name,
          last_name,
          locale,
          onboarding_viewed,
          supplied_zip_code: zip,
        },
      },
      headers: { Authorization: auth_token },
      method: 'put',
      url: '/api/v2/client/user',
    };
  },

  updateUserActivity: async (id, body) => {
    return axios.put(`${user_activities_route}/${id}`, { user_activity: body });
  },

  updateUserLastCampaignId: async ({ lastCampaignId }) =>
    axios.put(user_route, {
      user: {
        last_campaign_id: lastCampaignId,
      },
    }),

  userActivityAddPerform: async userActivityId => {
    return axios.post(`${user_activities_route}/${userActivityId}/add_perform`, {});
  },

  userActivityTrackClick: async userActivityId => {
    return axios.post(`${user_activities_route}/${userActivityId}/track_click`, {});
  },
};

export default user;
