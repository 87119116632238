import React from 'react';

const ExitLink = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M6.66667 4V5.33333H3.33333V12.6667H10.6667V9.33333H12V13.3333C12 13.5101 11.9298 13.6797 11.8047 13.8047C11.6797 13.9298 11.5101 14 11.3333 14H2.66667C2.48986 14 2.32029 13.9298 2.19526 13.8047C2.07024 13.6797 2 13.5101 2 13.3333V4.66667C2 4.48986 2.07024 4.32029 2.19526 4.19526C2.32029 4.07024 2.48986 4 2.66667 4H6.66667ZM14 2V7.33333H12.6667V4.27533L7.47133 9.47133L6.52867 8.52867L11.7233 3.33333H8.66667V2H14Z"
        fill="black"
      />
    </svg>
  );
};

ExitLink.propTypes = {};

ExitLink.defaultProps = {};

export default ExitLink;
