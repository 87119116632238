import React from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import GoogleLogin from 'react-google-login';
import { authErrorMessage as googleAuthErrorMessage } from 'web/services/google/auth';

import googleIconImage from '@assets/images/web/google-icon.png';
import ImpactiveButton from '@web/components/ImpactiveButton';
import { Form, Formik } from 'formik';
import FormField from '@web/components/common/shared/FormField';
import theme from '@web/styles/theme';
import { useMedia } from '@web/hooks';
import { GrayText } from './Components/TextComponents';

const GmailButton = styled(Button)`
  width: 100%;
  margin-top: ${({ isMobile }) => (isMobile ? '0' : '15px')};
  height: 52px;

  font-weight: 600;
`;

const Flex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const HorFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.blue06};
`;

const Google = () => (
  <img
    src={googleIconImage}
    width={25}
    height={25}
    alt="google-icon"
    style={{ marginRight: '8px' }}
  />
);

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const AuthMenu = ({ onGoogleLogin, onPasswordlessLogin }) => {
  const { t } = useTranslation();
  const isMobile = useMedia('(max-width: 1000px)');

  const handleGoogleSuccess = response => {
    const { googleId, profileObj, tokenId } = response;
    const { email } = profileObj;
    onGoogleLogin(email, tokenId, googleId);
  };

  const handleGoogleFailure = response => {
    const { error } = response;
    const errorMessage = googleAuthErrorMessage(error);

    if (errorMessage) {
      message.error(errorMessage);
    }
  };

  return (
    <>
      <Flex>
        <Formik
          onSubmit={onPasswordlessLogin}
          validateOnBlur
          validationSchema={validationSchema}
          render={({ values, isSubmitting, isValid, handleChange }) => (
            <Form>
              <Flex>
                <FormField
                  label={t('auth.email.email_address')}
                  placeholder={t('placeholders.default_email')}
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  labelProps={{ bold: true }}
                />
                <ImpactiveButton
                  size="full-width"
                  htmlType="submit"
                  disabled={!isValid || isSubmitting}
                >
                  {t('auth.sign_up')}
                </ImpactiveButton>
              </Flex>
            </Form>
          )}
        />
        {isMobile && (
          <HorFlex>
            <Divider />
            <GrayText>{t('common.or')}</GrayText>
            <Divider />
          </HorFlex>
        )}
        <GoogleLogin
          clientId={process.env.GOOGLE_CLIENT_ID}
          theme="dark"
          onSuccess={handleGoogleSuccess}
          onFailure={handleGoogleFailure}
          cookiePolicy="single_host_origin"
          render={renderProps => (
            <GmailButton
              type="default"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              isMobile={isMobile}
            >
              {Google()} {t('auth.auth_modal.continue_with_google')}
            </GmailButton>
          )}
        />
        <GrayText>
          <p>
            {t('auth.existing_account_question')} <a href="/auth/login">Sign In</a>
          </p>
        </GrayText>
      </Flex>
    </>
  );
};

AuthMenu.propTypes = {
  onGoogleLogin: PropTypes.func.isRequired,
  onPasswordlessLogin: PropTypes.func.isRequired,
};

AuthMenu.defaultProps = {};

export default AuthMenu;
