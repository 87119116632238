import * as React from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '@web/utils/context';
import { Redirect, useParams } from 'react-router-dom';

import PageLayout from '../../components/PageLayout';
import QueueCallsSelection from './QueueCallsSelection';

function LandingPage({ activity }) {
  let { userActivityId } = useParams();
  const { user } = React.useContext(AuthContext);
  const authorizedQueuers = activity?.dial_authorizer_user_ids || [];
  const baseRoute = `/activities/${userActivityId}/phone-bank`;

  const userCanQueueCalls = authorizedQueuers.includes(user?.id);

  // check to see if the user is authorized to queue calls
  if (userCanQueueCalls) {
    return (
      <PageLayout>
        <QueueCallsSelection activity={activity} />
      </PageLayout>
    );
  }

  return <Redirect to={`${baseRoute}/preview-script`} />;
}

LandingPage.propTypes = {
  activity: PropTypes.any.isRequired,
};

LandingPage.defaultProps = {};

export default LandingPage;
