import { fonts } from '@web/styles/theme';
/* eslint-disable */
const styles = {
  colors: {
    // grays
    bgGray: '#E5E5E5',
    bgGray2: '#B3B3B3',
    borderGray: '#D2D2D2',
    textGray: '#9C9C9C',
    textDarkGray: '#424242',

    black: '#000',
    blue: '#4680D9',
    green: '#00FF00',
    red: '#DF2222',
    teal: '#00FFFF',
    purple: '#FF00FF',
    white: '#fff',
    yellow: '#FFFDAB',
  },
  fonts,
  spacing: {
    tight: '.5rem',
    normal: '1rem',
    wide: '1.5rem',
    wider: '4rem',
  },
};

export default styles;
