import { sortByDisplayOrderFn } from '@web/utils/other';

export const ageRanges = t => [
  {
    label: t('idvoters.labels.age_all'),
    value: '18-255',
  },
  {
    label: '18-28',
    value: '18-28',
  },
  {
    label: '25-35',
    value: '25-35',
  },
  {
    label: '30-45',
    value: '30-45',
  },
  {
    label: '40-60',
    value: '40-60',
  },
  {
    label: t('idvoters.labels.age_55_up'),
    value: '55-150',
  },
];

export const formatTags = tags =>
  tags
    .map(tagging => ({
      ...tagging.tag,
      id: tagging.id,
      tag_id: tagging.tag_id,
      value: tagging.value,
    }))
    .sort(sortByDisplayOrderFn);
export const formatCustomFields = customFields =>
  customFields
    .map(field => ({
      ...field.custom_field,
      custom_field_id: field.custom_field_id,
      id: field.id,
      value: field.value,
    }))
    .sort(sortByDisplayOrderFn);

export const updateReportData = (report, tags, customFields) => ({
  customizations_attributes: report.customizations.map(item => {
    const existing = customFields.find(x => x.custom_field_id === item.custom_field_id);
    return { custom_field_id: item.custom_field_id, id: item.id, value: existing?.value };
  }),
  taggings_attributes: report.taggings.map(item => {
    const existing = tags.find(x => x.tag_id === item.tag_id);
    return { id: item.id, tag_id: item.custom_field_id, value: existing?.value };
  }),
});

// We need to merge blank report data with existing report data, but they may have different values, so I'm merging them by ID
export const mergeBlankDataWithInitialData = (blank, initial) => {
  const ids = new Set(initial.map(d => d.ID));
  return [...initial, ...blank.filter(d => !ids.has(d.ID))];
};
