import theme from '@web/styles/theme';
import { Input } from 'antd';
import { ErrorMessage } from 'formik';
import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

export const Label = styled.div`
  margin-bottom: 5px;
  text-transform: ${({ $textTransform }) => $textTransform ?? 'uppercase'};
  font-size: 0.875rem;

  color: ${({ $labelFontColor }) => $labelFontColor ?? theme.colors.black};
  font-family: ${theme.fonts.bold};
`;

export const StyledErrorMessage = styled(ErrorMessage).attrs({ component: 'div' })`
  color: #ea5156;
  font-size: 12px;
  margin-top: 4px;
`;

const StyledField = styled(Input)`
  &&& {
    border: 1px solid ${({ $outlineColor }) => $outlineColor ?? theme.colors.gray};
    background-color: ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster};
    border-radius: 6px;
    padding: 10px 14px 12px;
    color: ${({ $fontColor }) => $fontColor ?? theme.colors.black};
    width: 100%;
    height: 42px;

    &:hover {
      border: 1px solid #40a9ff;
    }

    &:focus-visible {
      outline: 0;
      border-right-width: 1px !important;
      border: 1px solid #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    svg {
      color: ${({ $outlineColor }) => $outlineColor ?? theme.colors.gray};
    }

    ::placeholder {
      color: ${({ $outlineColor }) => $outlineColor ?? theme.colors.blackL72};
    }

    input {
      background-color: ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster};
      color: ${({ $fontColor }) => $fontColor ?? theme.colors.black};

      ::placeholder {
        color: ${({ $outlineColor }) => $outlineColor ?? theme.colors.blackL72};
      }

      -webkit-background-clip: text !important;
      background-clip: text !important;
      -webkit-autofill:first-line {
        color: ${({ $fontColor }) => $fontColor ?? theme.colors.black};
      }

      box-shadow: 0 0 0 30px ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster}
        inset !important;
      -webkit-box-shadow: 0 0 0 30px
        ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster} inset !important;
    }

    .ant-input-prefix {
      margin-right: 12px;
    }
  }
`;

const FormInput = ({
  name,
  value,
  label,
  placeholder,
  backgroundColor,
  fontColor,
  labelFontColor,
  outlineColor,
  textTransform,
  ...rest
}) => {
  return (
    <Wrapper>
      <Label $textTransform={textTransform} $labelFontColor={labelFontColor}>
        {label}
      </Label>
      <StyledField
        {...rest}
        $outlineColor={outlineColor}
        $backgroundColor={backgroundColor}
        $fontColor={fontColor}
        name={name}
        value={value}
        placeholder={placeholder}
      />
      <StyledErrorMessage data-testid={`${name}-error`} name={name} />
    </Wrapper>
  );
};

FormInput.propTypes = {
  backgroundColor: string,
  fontColor: string,
  label: string,
  labelFontColor: string,
  name: string.isRequired,
  outlineColor: string,
  placeholder: string,
  textTransform: string,
  value: string,
};

export default FormInput;
