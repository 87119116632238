import { Formik } from 'formik';
import { Form, FormItem, Input, Radio, Select } from 'formik-antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import states from 'web/data/states';
import * as yup from 'yup';
import './voter-search-form.scss';
import ImpactiveButton from '../ImpactiveButton';

const Name = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Location = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
`;

const SectionTitle = styled.div`
  color: ${({ theme }) => theme.fonts.black};
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 16px;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  padding-left: 16px;
`;

const IdVotersForm = ({
  firstName = '',
  lastName = '',
  isFriend,
  userActivityId,
  activityId,
  campaignId,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const IdVotersFormSchema = yup.object().shape({
    ageRange: yup.string().required(t('idvoters.validation.age')),
    city: yup.string(),
    firstName: yup.string().required(t('idvoters.validation.firstname')),
    lastName: yup.string().required(t('idvoters.validation.lastname')),
    stateAbbv: yup.string().required(t('idvoters.validation.state')),
    zipCode: yup.number(t('idvoters.validation.zip')),
  });

  return (
    <Formik
      initialValues={{
        ageRange: '18-255',
        city: '',
        firstName: firstName || '',
        lastName: lastName || '',
        stateAbbv: '',
        zipCode: '',
      }}
      validationSchema={IdVotersFormSchema}
      onSubmit={onSubmit}
    >
      <Form className="voter-search-form">
        <Name>
          <FormItem name="firstName" className="form-input px-3">
            <label htmlFor="firstName">
              <SectionTitle>{t('idvoters.labels.firstname')}</SectionTitle>
            </label>
            <Input name="firstName" placeholder={t('idvoters.labels.firstname')} id="firstName" />
          </FormItem>
          <FormItem name="lastName" className="form-input px-3">
            <label htmlFor="lastName">
              <SectionTitle>{t('idvoters.labels.lastname')}</SectionTitle>
            </label>
            <Input name="lastName" placeholder={t('idvoters.labels.lastname')} id="lastName" />
          </FormItem>
        </Name>
        <FormItem name="ageRange" className="px-3 py-2">
          <label htmlFor="ageRange">
            <SectionTitle>{t('idvoters.labels.age')}</SectionTitle>
          </label>
          <Radio.Group
            className="flex-row justify-content-between flex-wrap"
            id="ageRange"
            name="ageRange"
            defaultValue="18-255"
            buttonStyle="solid"
          >
            <Radio value="18-255">{t('idvoters.labels.age_all')}</Radio>
            <Radio value="18-28">18-28</Radio>
            <Radio value="25-35">25-35</Radio>
            <Radio value="30-45">30-45</Radio>
            <Radio value="40-60">40-60</Radio>
            <Radio value="55-150">{t('idvoters.labels.age_55_up')}</Radio>
          </Radio.Group>
        </FormItem>
        <Location>
          <FormItem name="stateAbbv" className="form-input px-3">
            <label htmlFor="stateAbbv">
              <SectionTitle>{t('idvoters.labels.state')}</SectionTitle>
            </label>
            <Select
              className="state-select"
              name="stateAbbv"
              id="state"
              placeholder={t('idvoters.placeholders.state')}
              showSearch
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {states.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem name="city" className="form-input px-3">
            <label htmlFor="city">
              <SectionTitle>{t('idvoters.labels.city')}</SectionTitle>
            </label>
            <Input name="city" placeholder={t('idvoters.placeholders.city')} id="city" />
          </FormItem>
          <FormItem name="zipCode" className="form-input px-3">
            <label htmlFor="zipCode">
              <SectionTitle>{t('idvoters.labels.zip')}</SectionTitle>
            </label>
            <Input name="zipCode" placeholder={t('idvoters.labels.zip')} id="zipCode" />
          </FormItem>
        </Location>
        <ButtonContainer>
          <ImpactiveButton htmlType="submit">{t('idvoters.search')}</ImpactiveButton>
        </ButtonContainer>
        <div className="my-3 flex-row justify-content-between align-items-center">
          {!isFriend && activityId && (
            <div className="mx-3">
              <Link
                to={{
                  pathname: '/voter/newVoterReport',
                  state: {
                    activityId,
                    campaignId,
                    userActivityId,
                  },
                }}
              >
                {t('idvoters.add_new')}
              </Link>
            </div>
          )}
        </div>
      </Form>
    </Formik>
  );
};

IdVotersForm.propTypes = {
  activityId: PropTypes.number,
  campaignId: PropTypes.number,
  firstName: PropTypes.string,
  isFriend: PropTypes.bool,
  lastName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  userActivityId: PropTypes.number,
};

IdVotersForm.defaultProps = {
  activityId: null,
  campaignId: null,
  firstName: '',
  isFriend: false,
  lastName: '',
  userActivityId: null,
};

export default IdVotersForm;
