import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import CalendarIcon from '@web/components/Headcount/components/icons/CalendarIcon';
import ClipboardIcon from '@web/components/Headcount/components/icons/ClipboardIcon';
import CheckmarkIcon from '@web/components/Headcount/components/icons/CheckmarkIcon';
import EnvelopeIcon from '@web/components/Headcount/components/icons/EnvelopeIcon';
import Text from '@web/components/Headcount/components/Text';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import sendError from '@web/utils/sendError';
import i18n from '@web/components/i18n';
import 'dayjs/locale/es';
import moment from 'moment';

// MTS - This is required due to a strange bug that will show up in Firefox
// and mobile versions of Chrome.
dayjs.extend(customParseFormat);
dayjs.extend(localeData);

// To keep the 2nd item in the row aligned when image widths are different
const IconArea = styled.div`
  width: 3rem;
`;

const StyledDateItem = styled.li`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.wide};
`;

function DateItem({ color, date, type }) {
  let formattedDate;
  const { t } = useTranslation();

  try {
    const currentLocale = moment.locale();
    dayjs.locale(i18n.language);
    formattedDate = dayjs(date, 'MM-DD-YY').format("MMM D, 'YY");
    formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1).toLowerCase();
    dayjs.locale(currentLocale);
  } catch (err) {
    sendError(`Voter Registration: Error formatting date: ${date}`, err);
  }

  function getTypeDetails() {
    const iconStyle = { height: '2rem', marginRight: '1rem' };
    switch (type) {
      case 'lastDayVBM':
        return {
          icon: <EnvelopeIcon color={color} style={iconStyle} />,
          text: t('check_registration.headcount.iconLabels.lastDayVBM'),
        };
      case 'lastDayAbsenteeBallot':
        return {
          icon: <EnvelopeIcon color={color} style={iconStyle} />,
          text: t('check_registration.headcount.iconLabels.lastDayAbsenteeBallot'),
        };
      case 'earlyVotingStart':
        return {
          icon: <CalendarIcon color={color} style={iconStyle} />,
          text: t('check_registration.headcount.iconLabels.earlyVotingStart'),
        };
      case 'lastRegDay':
        return {
          icon: <ClipboardIcon color={color} style={iconStyle} />,
          text: t('check_registration.headcount.iconLabels.lastRegDay'),
        };
      case 'electionDay':
        return {
          icon: <CheckmarkIcon color={color} style={iconStyle} />,
          text: t('check_registration.headcount.iconLabels.electionDay'),
        };
      default:
        return null;
    }
  }

  const typeDetails = getTypeDetails();

  if (!date || !formattedDate || !typeDetails) {
    return null;
  }

  return (
    <StyledDateItem>
      <IconArea>{typeDetails.icon}</IconArea>
      <Text style={{ flex: 1 }}>{typeDetails.text}</Text>
      <Text variant="f3">{formattedDate}</Text>
    </StyledDateItem>
  );
}

DateItem.propTypes = {
  color: PropTypes.string,
  date: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'earlyVotingStart',
    'electionDay',
    'lastRegDay',
    'lastDayVBM',
    'lastDayAbsenteeBallot',
  ]).isRequired,
};

DateItem.defaultProps = {};

export default DateItem;
