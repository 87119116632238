import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DesktopSubscriptionSelection from '@web/components/common/SubscriptionSelection/desktop';
import { plans } from '@web/components/common/SubscriptionSelection/plansInfo';
import PaymentModal from './Components/PaymentModal';

const PlanSelectStep = ({ campaign }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const onPlanSelect = slug => {
    setSelectedPlan(plans.find(plan => plan.slug === slug));
  };

  const onCancel = () => {
    setSelectedPlan(null);
  };

  return (
    <>
      <DesktopSubscriptionSelection activeIndex={1} onPlanSelect={onPlanSelect} plans={plans} />
      {selectedPlan && (
        <PaymentModal
          campaign={campaign}
          onClose={onCancel}
          plan={selectedPlan}
          visible={!!selectedPlan}
        />
      )}
    </>
  );
};

PlanSelectStep.propTypes = {
  campaign: PropTypes.object,
};

export default PlanSelectStep;
