import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { Description, Title } from '@web/components/VoterRegistration/newComponents/TextStyles';
import { largeBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import Button from '@web/components/VoterRegistration/newComponents/Button';
import ArrowBack from '@web/components/FanOut/components/icons/ArrowBack';
import VBFriendInfo from './VBFriendInfo';

const VBFriendInfoWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const LoadingBox = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const NoRecruitsText = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  text-align: center;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 60px;

  ${largeBreakpoint} {
    justify-content: space-between;
    flex-direction: row;
    margin-top: 58px;
  }
`;

const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  gap: 1rem;
  :last-of-type {
    justify-content: flex-end;
  }
  ${largeBreakpoint} {
    flex-direction: row;
    justify-content: flex-start;
    button {
      max-width: 220px;
    }
  }
`;

const StyledLink = styled(Link)`
  :hover,
  :focus,
  .active {
    text-decoration: none;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    height: 64px;
  }
`;

const StyledLinkButton = styled(Button)`
  svg {
    margin-right: 0.375rem;
  }

  &&& {
    height: 64px;
    border: 1px solid ${({ theme }) => theme.colors.blackL72};
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const FriendLookUpResult = ({ handleClose, previousPage }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { friendLookUpResult } = useSelector(state => state.voterRegistration);

  return (
    <>
      <Title>{t('fan_out.friend_look_up.look_up_title')}</Title>
      <Description>{t('fan_out.friend_look_up.look_up_description')}</Description>
      <VBFriendInfoWrapper>
        {friendLookUpResult.map((voter, index) => (
          <VBFriendInfo key={index} voter={voter}></VBFriendInfo>
        ))}
        {friendLookUpResult.length === 0 && (
          <LoadingBox>
            <NoRecruitsText>{t('fan_out.friend_look_up.no_results')}</NoRecruitsText>
          </LoadingBox>
        )}
      </VBFriendInfoWrapper>
      <ButtonRow>
        <ButtonGroup>
          <StyledLinkButton onClick={previousPage} variant="outline" isFanOut>
            <ArrowBack />
            {t('fan_out.friend_look_up.search_again')}
          </StyledLinkButton>
        </ButtonGroup>
        <ButtonGroup>
          <StyledButton onClick={handleClose} variant="fan_out" isFanOut>
            <StyledLink to={{ search, state: 'sweepstakes' }}>
              {t('fan_out.friend_look_up.view_active_sweepstakes')}
            </StyledLink>
          </StyledButton>
        </ButtonGroup>
      </ButtonRow>
    </>
  );
};

FriendLookUpResult.propTypes = {
  activity: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  setSelectedFlow: PropTypes.func.isRequired,
  team: PropTypes.object,
};

FriendLookUpResult.defaultProps = {};

export default FriendLookUpResult;
