import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AnswerBlock from './AnswerBlock';
import AnswerItem from './AnswerItem';

import useInterpolation from '@web/components//DialerActivity/useInterpolation';

const StyledScriptBlock = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 22px;
`;

const TextSection = styled.div``;

function ScriptBlock({
  answersDisabled,
  connectedContact,
  onAnswerSelect,
  options,
  selectedAnswerRef,
  stem,
  ...rest
}) {
  const { interpolated } = useInterpolation(stem, connectedContact);
  const hasOptions = options?.length > 0;

  if (!options) {
    return null;
  }

  const paragraphs = interpolated.split('\n').filter(el => el !== '');

  return (
    <StyledScriptBlock>
      <TextSection>
        {paragraphs.map((el, i) => (
          <Text key={i}>{el}</Text>
        ))}
      </TextSection>
      <AnswerBlock>
        {options.map(option => (
          <AnswerItem
            disabled={answersDisabled}
            key={option.reference}
            onPress={() => onAnswerSelect(option)}
            selected={selectedAnswerRef === option.reference}
          >
            {option.label}
          </AnswerItem>
        ))}
        {!hasOptions && (
          <Text style={{ marginTop: 16, textAlign: 'center' }}>No Response Needed</Text>
        )}
      </AnswerBlock>
    </StyledScriptBlock>
  );
}

ScriptBlock.propTypes = {
  answersDisabled: PropTypes.bool,
  connectedContact: PropTypes.object.isRequired,
  onAnswerSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any),
  selectedAnswerRef: PropTypes.string,
  stem: PropTypes.string.isRequired,
};

ScriptBlock.defaultProps = {
  answersDisabled: false,
  onAnswerSelect: () => null,
  selectedAnswerRef: null,
};

export default ScriptBlock;
