import map from 'lodash/map';
import React from 'react';
import ModalManager from './ModalManager';

const ModalContainer = () => {
  const [modals, setModals] = React.useState({});

  React.useEffect(() => {
    ModalManager.addChangeListener(setModals);
    return () => {
      ModalManager.removeChangeListener(setModals);
    };
  }, [setModals]);

  return (
    <>
      {map(modals, ({ component: Component, props = {} }, key) => (
        <Component key={key} {...props} onClose={() => ModalManager.closeModal(key)} />
      ))}
    </>
  );
};

export default ModalContainer;
