import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FormField from '@web/components/common/shared/FormField';
import Select from '@web/components/common/shared/Select';
import ImpactiveButton from '@web/components/ImpactiveButton';
import { Formik } from 'formik';
import { GrayText, Header, PrimaryText } from '../TextComponents';

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 48px;
  justify-content: flex-end;
  > * {
    max-width: 200px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
`;

const OrgName = styled.div`
  margin: 16px 0;
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  > * {
    width: 100%;
  }

  // on mobile display vertically
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const WelcomeStep = ({
  onNext,
  values,
  dirty,
  errors,
  handleChange,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  isValid,
}) => {
  const { t } = useTranslation();

  const selectOnChange = (value, name, setValue, setTouched) => {
    setValue(name, value);
    setTouched(name);
  };

  const options = [
    { label: 'Advocacy Organization', value: 'Advocacy Organization' },
    { label: 'Nonprofit', value: 'Nonprofit' },
    { label: 'Union', value: 'Union' },
    { label: 'Electoral Campaign', value: 'Electoral Campaign' },
    { label: 'Party/Committee', value: 'Party/Committee' },
    { label: 'Consultant', value: 'Consultant' },
    { label: 'Other', value: 'Other' },
  ];

  const isStepInfoComplete = !errors.org_name && !errors.website;

  return (
    <Wrapper>
      <Flex>
        <div>
          <Header>{t('frontline.welcome')} 👋</Header>
          <PrimaryText>{t('auth.tell-us-a-bit')}</PrimaryText>
        </div>
        <OrgName>
          <FormField
            name="org_name"
            label="Organization Name"
            placeholder="Impactive"
            value={values.org_name}
            onChange={handleChange}
            labelProps={{ bold: true, required: true }}
          />
          <GrayText>{t('auth.choose-carefully-this')}</GrayText>
        </OrgName>
        <SideBySide>
          <FormField
            name="website"
            label="Organization Website"
            placeholder="www.website.com"
            value={values.website}
            onChange={handleChange}
            labelProps={{ bold: true, required: true }}
          />
          <Select
            name="org_type"
            label="Organization Type"
            placeholder="Select Type..."
            options={options}
            value={values.org_type}
            onChange={value => selectOnChange(value, 'org_type', setFieldValue, setFieldTouched)}
            labelProps={{ bold: true }}
          />
        </SideBySide>
        <ButtonContainer>
          <ImpactiveButton
            size="full-width"
            disabled={!dirty || isSubmitting || !isStepInfoComplete}
            onClick={onNext}
          >
            Next →
          </ImpactiveButton>
        </ButtonContainer>
      </Flex>
    </Wrapper>
  );
};

WelcomeStep.propTypes = {
  onNext: PropTypes.func.isRequired,
  ...Formik.propTypes,
};

export default WelcomeStep;
