import theme from '@web/styles/theme';
import styled from 'styled-components';

export const Card = styled.div`
  background-color: ${theme.colors.white};
  border-radius: 5px;
  padding: 30px;
  width: 100%;
  box-shadow: 0 1px 15px 1px rgb(69 65 78 / 8%);
`;
