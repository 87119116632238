import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import AuthPage from './AuthPage';
import EmailAuthPage from './EmailAuthPage';
import PasswordReset from './PasswordReset';
import PasswordlessConfirmPage from './PasswordlessConfirmPage';
import PasswordlessPage from './PasswordlessPage';
import PasswordlessTokenLoginPage from './PasswordlessTokenLoginPage';
import './auth.scss';
import AdminPasswordless from './AdminSignup/pages/AdminPasswordless';
import AdminLinkSent from './AdminSignup/pages/AdminLinkSent';

const Auth = ({ match }) => {
  if (match.path === '/auth' && match.isExact === true) {
    return <Redirect to={`/auth/login`} />;
  }

  return (
    <div className="bg-auth-cyan" style={{ height: '100%', width: '100%' }}>
      <Switch>
        <Route exact path="/auth/magic-link/:token" component={PasswordlessTokenLoginPage} />
        <Route exact path="/auth/forgot-password" component={PasswordReset} />

        <Route exact path="/auth/login" component={() => <AuthPage />} />
        <Route exact path="/auth/login/email" component={() => <EmailAuthPage />} />
        <Route exact path="/auth/login/passwordless" component={() => <PasswordlessPage />} />
        <Route
          exact
          path="/auth/login/passwordless/confirm"
          component={() => <PasswordlessConfirmPage />}
        />

        <Route exact path="/auth/signup" component={() => <AuthPage isSignup />} />
        <Route exact path="/auth/signup/email" component={() => <EmailAuthPage isSignup />} />
        <Route
          exact
          path="/auth/signup/passwordless"
          component={() => <PasswordlessPage isSignup />}
        />
        <Route
          exact
          path="/auth/signup/passwordless/confirm"
          component={() => <PasswordlessConfirmPage isSignup />}
        />

        <Route exact path="/auth/signup/:campaign_slug" component={() => <AuthPage isSignup />} />
        <Route
          exact
          path="/auth/signup/:campaign_slug/email"
          component={() => <EmailAuthPage isSignup />}
        />
        <Route
          exact
          path="/auth/signup/:campaign_slug/passwordless"
          component={() => <PasswordlessPage isSignup />}
        />
        <Route
          exact
          path="/auth/signup/:campaign_slug/passwordless/confirm"
          component={() => <PasswordlessConfirmPage isSignup />}
        />

        {/* New signup flow */}
        <Route exact path="/auth/campaign-signup" component={() => <AdminPasswordless />} />
        <Route exact path="/auth/campaign-signup/confirm" component={() => <AdminLinkSent />} />

        <Route path="*" component={() => <AuthPage />} />
      </Switch>
    </div>
  );
};

Auth.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    path: PropTypes.string,
  }),
};

export default withRouter(Auth);
