import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@web/components/DialerActivity/components/Button';
import Header from '@web/components/DialerActivity/components/Header';
import PageLayout from '../../components/PageLayout';
import { Body, Title } from '@web/components/DialerActivity/components/TextStyles';

import useQueueCalls from './useQueueCalls';

const Calls = styled.span`
  color: ${({ theme }) => theme.colors.darkTeal};
  font-family: ${({ theme }) => theme.fonts.blackItalic};
  font-size: 76px;
  line-height: 1;
`;

const CallsArea = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.whiteSmokeLighter};
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px 0;
  padding: 10px;
`;

const CallsTitle = styled.span`
  color: #979797;
  font-family: ${({ theme }) => theme.fonts.boldItalic};
  font-size: 15px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

const MetricWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

function QueueCalls({ activity }) {
  const { callsQueued, handleClick, totalCalls } = useQueueCalls(activity.id);
  const topStyle = { textAlign: 'left' };

  return (
    <PageLayout>
      <Header activity={activity} backLink={''} />
      <Title style={topStyle}>Queue Calls</Title>
      <Body style={topStyle}>
        Tap the button below repeatedly to queue 100 calls at a time for the phone banking session.
      </Body>
      <CallsArea style={{ marginBottom: 0 }}>
        <MetricWrapper>
          <Calls>{totalCalls}</Calls>
          <CallsTitle>Calls placed this phone bank</CallsTitle>
        </MetricWrapper>
      </CallsArea>
      <CallsArea>
        <MetricWrapper>
          <Calls>{callsQueued}</Calls>
          <CallsTitle>Calls Queued</CallsTitle>
        </MetricWrapper>
      </CallsArea>
      <Button onClick={handleClick}>Queue Calls</Button>
    </PageLayout>
  );
}

QueueCalls.propTypes = {
  activity: PropTypes.any.isRequired,
};

QueueCalls.defaultProps = {};

export default QueueCalls;
