import { Button, Input } from 'antd';
import styled from 'styled-components';
import { colors, fonts } from './../../styles/theme';

const Wrapper = styled.div`
  display: block;
  margin: 0 auto;
  padding: 2em 0 1em 0;
  max-width: 420px;
  text-align: center;
`;

const Header = styled.h2`
  text-align: center;
  color: ${colors.black};
  font-size: 1.7em;
  margin: 1em 0 1em 0;
`;

const SubHeader = styled.h3`
  text-align: center;
  font-family: ${fonts.bold};
  font-size: 0.8em;
  text-transform: uppercase;
  color: ${colors.black};
  opacity: 0.8;
`;

const FooterText = styled.h4`
  color: ${colors.black};
  font-size: 0.8em;
  line-height: 1.4em;
  opacity: 0.55;
  padding: 2em 4em;
  text-align: center;
`;

const StyledInput = styled(Input)`
  && {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid gray;
    border-radius: 0;
    margin: 1em 0 1em 0;
    outline: none;
    box-shadow: none !important;
  }
`;

const StyledButton = styled(Button)`
  font-family: ${fonts.bold};
  text-transform: uppercase;
  margin: 1em 0 1em 0;
  && {
    display: block;
    font-size: 1em;
    margin-left: auto;
    margin-right: auto;
  }
`;

const LocaleInput = styled.div`
  text-align: start;
  margin: 0.8em 0;
`;

export { Wrapper, Header, SubHeader, FooterText, StyledInput, StyledButton, LocaleInput };
