import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Input, Upload } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components';
import AppContainer from 'web/components/AppContainer';
import AppHeader from 'web/components/AppHeader';
import Container from 'web/components/Container';
import BackButton from 'web/components/common/BackButton';
import { track } from 'web/services/analytics';
import { user } from 'web/services/api';
import theme from 'web/styles/theme';
import { TestimonialMediaPreview, uploadMedia, validateFile } from './TestimonialHelpers';
import './testimonial-activity.scss';

const { TextArea } = Input;

const Content = styled.div`
  margin-bottom: 1rem;
`;

const Title = styled.div`
  margin: 1rem 0;
  text-align: center;
  font-family: ${theme.fonts.semibold};
  color: black;
  font-size: 1.75em;
`;

const Description = styled.div`
  margin: 1em 0;
`;

const Subtitle = styled.div`
  font-family: ${theme.fonts.semibold};
  font-size: 1.25em;
  color: black;
`;

const Preview = styled.div`
  margin: 1rem 0;
`;

const TestimonialActivity = () => {
  const { t } = useTranslation();
  const { userActivityId } = useParams();
  const [userActivity, setUserActivity] = useState({});
  const {
    header_title,
    activity: {
      _type: activityType,
      title,
      description,
      campaign: { slug: campaignSlug } = {},
    } = {},
  } = userActivity; // extract activity type

  // Form data
  const [testimonialNote, setTestimonialNote] = useState('');
  const [mediaPreview, setMediaPreview] = useState(null);
  const [testimonialNoteURL, setTestimonialNoteURL] = useState(null);
  const [testimonialNoteType, setTestimonialNoteType] = useState(null);

  // Spinners
  const [loading, setLoading] = useState(true); // when loading initial data
  const [submitting, setSubmitting] = useState(false); // when submitting final testimony

  // Upload handling
  const [uploadButtonText, setUploadButtonText] = useState(t('activity.testimonial.choose_file'));
  const [uploading, setUploading] = useState(false);

  const loadData = useCallback(async () => {
    try {
      // First fetch userActivity data
      const {
        data: { data: activity },
      } = await user.getUserActivity(userActivityId);

      const { personal_note, personal_note_type, personal_note_url } = activity;

      // Set data
      setUserActivity(activity);
      // Previously set data
      setTestimonialNote(personal_note);
      setTestimonialNoteType(personal_note_type);
      setTestimonialNoteURL(personal_note_url);
      setMediaPreview(personal_note_url);

      setLoading(false);
    } catch (err) {
      message.error(t('errors.activity_loading_failed'));
    }
    // eslint-disable-next-line
  }, [userActivityId]);

  useEffect(() => {
    loadData();
  }, [loadData, userActivityId]);

  const handleUpload = ({ file: { name, originFileObj, percent, response, status } }) => {
    // File upload onProgress callback
    if (status === 'uploading') {
      setUploading(true);
      setUploadButtonText(`${t('common.uploading')} ${Math.round(percent)}%`);
    }

    // Upload is finished
    if (status === 'done') {
      const { url, type } = response;

      setTestimonialNoteURL(url);
      setTestimonialNoteType(type);
      setMediaPreview(originFileObj);

      setUploading(false);
      setUploadButtonText(t('activity.testimonial.change_selection'));
    }

    // Upload error occured
    if (status === 'error') {
      message.error(`${name} t('activity.testimonial.file_upload_failed')`);

      setUploading(false);
      setUploadButtonText(t('activity.testimonial.file_upload_again'));
    }
  };

  // Activity submit, should only be available when media is uploaded.
  const handleSubmit = async () => {
    if (!testimonialNoteURL || !testimonialNoteType) {
      message.error(t('activity.testimonial.provide_image'));
      return;
    }

    try {
      setSubmitting(true);

      track('COMPLETED_ACTIVITY', {
        activity_id: userActivity.activity.id,
        activity_title: userActivity.activity.title,
        campaign_id: userActivity.activity.campaign.id,
        campaign_name: userActivity.activity.campaign.name,
      });

      await user.updateUserActivity(userActivity.id, {
        completed: true,
        personal_note: testimonialNote.trim(),
        personal_note_type: testimonialNoteType,
        personal_note_url: testimonialNoteURL,
      });

      await user.userActivityAddPerform(userActivity.id);

      message.success(t('common.submitted_successfully'));
    } catch (e) {
      message.error(t('errors.action_failed'));
    } finally {
      setSubmitting(false);
    }
  };

  // Redirect if activity type doesn't match this action
  if (activityType && activityType !== 'testimonial_activities') {
    message.error(t('errors.activity_type_mismatch'));
    return <Redirect to={`/activities/${userActivityId}`} />;
  }

  const HeaderLink = <Link to={`/campaigns/${campaignSlug}`}>{header_title}</Link>;

  if (loading) {
    return (
      <>
        <AppHeader title={t('activity.testimonial.header_title')} extra={HeaderLink} />
        <AppContainer>
          <Container>{t('common.loading')}</Container>
        </AppContainer>
      </>
    );
  }

  return (
    <>
      <AppHeader title={t('activity.testimonial.header_title')} extra={HeaderLink} />
      <AppContainer>
        <div className="default-page-max-width p-3">
          <BackButton />
          <Title>{title}</Title>
          <Description>{description}</Description>

          <Preview>
            <Subtitle>{t('activity.testimonial.preview')}</Subtitle>
            {!mediaPreview && <Content>{t('activity.testimonial.preview_description')}</Content>}
            <Upload
              name="body"
              listType={mediaPreview ? 'picture' : 'picture-card'}
              accept="image/*,video/*"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={request => uploadMedia(request)}
              beforeUpload={validateFile}
              onChange={handleUpload}
            >
              {mediaPreview && (
                <Button size={'small'} loading={uploading} type={'default'}>
                  {uploadButtonText}
                </Button>
              )}
              <TestimonialMediaPreview file={mediaPreview} contentType={testimonialNoteType} />
              {!mediaPreview && (
                <Button loading={uploading} type={'link'}>
                  <PlusOutlined /> {uploadButtonText}
                </Button>
              )}
            </Upload>
          </Preview>

          <Subtitle>{t('activity.testimonial.note')}</Subtitle>
          <TextArea
            placeholder={t('activity.testimonial.note_description')}
            onChange={e => setTestimonialNote(e.target.value)}
            value={testimonialNote}
          />

          <Button
            style={{ marginTop: '1rem' }}
            type="primary"
            block
            loading={submitting}
            disabled={!mediaPreview || uploading}
            onClick={handleSubmit}
          >
            {t('common.submit')}
          </Button>
        </div>
      </AppContainer>
    </>
  );
};

TestimonialActivity.propTypes = {};

export default TestimonialActivity;
