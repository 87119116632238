import React from 'react';

import { ConfigProvider, Empty } from 'antd';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import store from '@web/reducers';
import theme from '../styles/theme';
import { ThemeProvider } from 'styled-components';

import ActivityDetail from './ActivityDetail';
import Auth from './Auth';
import AuthenticatedRoute from './Routes/AuthenticatedRoute';
import AuthProvider from './AuthProvider';
import CampaignDetail from './CampaignDetail';
import CampaignLeaderboard from './CampaignLeaderboard';
import CheckRegistration from './CheckRegistration';
import ConfirmAddress from './ConfirmAddress';
import ContactSync from './ContactSync';
import Contacts from './Contacts';
import DialerActivity from './DialerActivity';
import Explore from './Explore';
import FrontLine from './FrontLine';
import GaOptionsPage from './GeorgiaSpecialLanding/OptionsPage';
import GeorgiaSpecialLanding from './GeorgiaSpecialLanding/index';
import Hq from './Hq';
import i18n from './i18n';
import IdVoters from './IdVotersActivity/IdVoters';
import Inbox from './Inbox';
import ModalContainer from './ModalContainer';
import NewVoterReport from './IdVotersActivity/NewVoterReport';
import NotFound from './404/NotFound';
import OptInFormWeb from './OptInFormWeb';
import PledgeToVote from './PledgeToVote';
import PublicActivityDetail from './PublicActivityDetail';
import TeamActivityPage from './TeamActivityPage';
import Settings from './Settings';
import StateProvider from './StateProvider';
import StateVotingInformation from './StateVotingInformation';
import TeamDetail from './TeamDetail';
import TestimonialActivity from './ActivityDetail/TestimonialActivity';
import TextAFriendActivity from './ActivityDetail/TextAFriendActivity';
import TextAListActivity from './ActivityDetail/TextAListActivity';
import UnauthenticatedRoute from './Routes/UnauthenticatedRoute';
import VoterMatch from './IdVotersActivity/VoterMatch';
import VotingInformation from './VotingInformation';
import AddReport from './AddReport';
import ChooseVoter from './AccelerateChange/pages/ChooseVoter';
import AdminPlanSelect from './Auth/AdminSignup/pages/AdminPlanSelect';
import VoterRegistrationPublicLeaderboard from './VoterRegistrationPublicLeaderboard';
import SearchVoter from './VoteScore/pages/SearchVoter';
import DidMyFriendVoteFAQs from './DidMyFriendVoteFAQs';

const customizeRenderEmpty = () => (
  <Empty description={i18n.t('common.no_results')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
);

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <AuthProvider>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <StateProvider>
            <Switch>
              <UnauthenticatedRoute path="/auth" component={Auth} />
              <UnauthenticatedRoute
                exact
                path="/campaigns/:campaignId"
                component={CampaignDetail}
              />
              <AuthenticatedRoute exact path="/leaderboard" component={CampaignLeaderboard} />
              <UnauthenticatedRoute exact path="/teams/:teamId(\d+)" component={TeamDetail} />
              <Route exact path="/campaigns/:campaignId/ready_to_vote" component={PledgeToVote} />
              <Route
                exact
                path="/campaigns/:campaignId/check_registration"
                component={CheckRegistration}
              />
              <Route
                exact
                path="/campaigns/:campaignId/contacts/:contactId/confirm_address"
                component={ConfirmAddress}
              />
              <Route
                exact
                path="/campaigns/:campaignId/contacts/:contactId/voting_information"
                component={VotingInformation}
              />
              <Route
                exact
                path="/campaigns/:campaignId/contacts/:contactId/state_voting_information"
                component={StateVotingInformation}
              />
              {/* Public page allows both activity slug and activity id */}
              <UnauthenticatedRoute
                exact
                path="/campaigns/:campaignSlug/activities/:activitySlug/teams/:teamSlug"
                component={TeamActivityPage}
              />
              <UnauthenticatedRoute
                exact
                path="/:campaignSlug/:activitySlug/leaderboard"
                component={VoterRegistrationPublicLeaderboard}
              />
              <UnauthenticatedRoute
                exact
                path="/campaigns/:campaignSlug/activities/:activitySlug"
                component={PublicActivityDetail}
              />
              {/* Private activity page is actually user_activity page, these need to be numberic ids */}
              <AuthenticatedRoute
                exact
                path="/activities/:userActivityId(\d+)"
                component={ActivityDetail}
              />
              <AuthenticatedRoute
                path="/activities/:userActivityId/p2p"
                component={TextAListActivity}
              />
              <AuthenticatedRoute
                path="/activities/:userActivityId/phone-bank"
                component={DialerActivity}
              />
              <AuthenticatedRoute
                path="/activities/:userActivityId/email_friends"
                component={TextAFriendActivity}
              />
              <AuthenticatedRoute
                path="/activities/:userActivityId/testimonial"
                component={TestimonialActivity}
              />
              <AuthenticatedRoute exact path="/voters" component={IdVoters} />
              <AuthenticatedRoute exact path="/voter/view" component={VoterMatch} />
              <UnauthenticatedRoute
                exact
                path="/campaigns/:campaignId/georgia_runoff"
                component={GeorgiaSpecialLanding}
              />
              <UnauthenticatedRoute
                exact
                path="/campaigns/:campaignId/georgia_runoff/options"
                component={GaOptionsPage}
              />
              <UnauthenticatedRoute
                exact
                path="/campaigns/:campaignId/georgia_runoff/options/:contactPath"
                component={GaOptionsPage}
              />
              <UnauthenticatedRoute
                exact
                path="/join/:campaignId/:formId"
                component={OptInFormWeb}
              />
              {/* uses passed state via location */}
              <AuthenticatedRoute exact path="/voter/newVoterReport" component={NewVoterReport} />
              <UnauthenticatedRoute path="/explore" component={Explore} />
              <AuthenticatedRoute path="/settings" component={Settings} />
              <AuthenticatedRoute path="/contacts" component={Contacts} />

              <AuthenticatedRoute path="/frontline" component={FrontLine} />
              <AuthenticatedRoute exact path="/headquarters" component={Hq} />
              <AuthenticatedRoute exact path="/messages" component={Inbox} />
              <AuthenticatedRoute path="/messages/:messageThreadId" component={Inbox} />

              <UnauthenticatedRoute
                exact
                path="/my_vote_score/faqs"
                render={props => <DidMyFriendVoteFAQs type="myVoteScore" {...props} />}
              />

              <UnauthenticatedRoute
                exact
                path="/did_my_friend_vote/faqs"
                render={props => <DidMyFriendVoteFAQs type="didMyFriendVote" {...props} />}
              />

              <UnauthenticatedRoute
                exact
                path="/did_they_vote/faqs"
                render={props => <DidMyFriendVoteFAQs type="didTheyVote" {...props} />}
              />

              <UnauthenticatedRoute exact path="/my_vote_score" component={SearchVoter} />
              {/*
                Do not remove, we will probably use it again
                <UnauthenticatedRoute
                  exact
                  path="/my_vote_score/:voterbaseId"
                  component={VoterScoreInfo}
                />
              */}

              <UnauthenticatedRoute exact path="/did_my_friend_vote" component={ChooseVoter} />
              {/*
                Do not remove, we will probably use it again
                <UnauthenticatedRoute
                  exact
                  path="/did_my_friend_vote/:voterbaseId"
                  component={VoterInfo}
                />
              */}

              <UnauthenticatedRoute
                exact
                path="/did_they_vote"
                render={props => <ChooseVoter didTheyVoteFlow {...props} />}
              />
              {/*
                Do not remove, we will probably use it again
                <UnauthenticatedRoute
                  exact
                  path="/did_they_vote/:voterbaseId"
                  render={props => <VoterInfo didTheyVoteFlow {...props} />}
                />
              */}

              {/* Contact Sync */}
              <AuthenticatedRoute path="/sync-contacts" component={ContactSync} />
              <AuthenticatedRoute path="/add-report" component={AddReport} />

              {/* New signup flow*/}
              <AuthenticatedRoute path="/new-campaign" component={AdminPlanSelect} />

              <Route component={NotFound} />
            </Switch>
            <ModalContainer />
          </StateProvider>
        </ConfigProvider>
      </AuthProvider>
    </Provider>
  </ThemeProvider>
);

export default App;
