import { List } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/theme';

export const ArrowWrapper = styled.div`
  margin-left: 1em;
`;

export const Causes = styled.div`
  cursor: default;
  padding-left: 55px;
  &&& {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

export const Cause = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: ${colors.darkBlue};
  border: 1px solid ${colors.teal};
  padding: 6px 8px;
  margin: 4px 6px 0 0;
  border-radius: 6px;
`;

export const Row = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const StyledListItem = styled(({ isIndented, ...rest }) => <List.Item {...rest} />)`
  flex-direction: column;
  align-items: baseline;
  cursor: pointer;
  margin: 0 15px 0 15px;
  &&& {
    flex-wrap: nowrap;
    ${({ isIndented }) => isIndented && `padding-left: 35px; margin-right: 0`}
  }
`;

export const StyledMeta = styled(List.Item.Meta)`
  h4 {
    font-family: ${({ theme }) => theme.fonts.semibold};
    font-size: 17px;
    letter-spacing: 0.32px;
    line-height: 24px;
  }
  .ant-list-item-meta-avatar {
    align-self: center;
  }
  .ant-list-item-meta-content {
    width: 100%;
  }
`;
