import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ActivityCTA from './ActivityCTA';
import ActivityGoals from './ActivityGoals';
import BoxLogo from './BoxLogo';
import CampaignProfileImage from './CampaignProfileImage';
import MainContainer from './MainContainer';

const StyledActivityCTA = styled(ActivityCTA)`
  margin-bottom: 0;
`;

const StyledCampaignProfileImage = styled(CampaignProfileImage)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledMainContainer = styled(MainContainer)`
  height: 448px;
  margin-bottom: 130px;
`;

const StyledActivityGoals = styled(ActivityGoals)`
  margin-bottom: 80px;
`;

const TeamName = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`;

const TeamPageTitle = styled.h1`
  text-align: center;
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const PROFILE_IMG_SIZE = 216;

function DesktopHero({
  activity,
  campaign,
  goals,
  handleGetReferral,
  handleGetStarted,
  referrer,
  team,
}) {
  // The + 3 at the end comes from half of the border size.
  const profileOffset = (PROFILE_IMG_SIZE / 2) * -1 + 3;
  return (
    <>
      <StyledMainContainer backgroundImage={team.hero_img_url}>
        <BoxLogo />
        <StyledCampaignProfileImage
          alt="campaign-profile"
          size={PROFILE_IMG_SIZE}
          src={team.profile_img_url}
          style={{ bottom: profileOffset }}
        />
      </StyledMainContainer>

      <TeamName>{team.name}</TeamName>
      <TeamPageTitle>{team.action_page_title}</TeamPageTitle>

      <StyledActivityGoals activity={activity} campaign={campaign} goals={goals} />

      <StyledActivityCTA
        activity={activity}
        campaign={campaign}
        handleGetStarted={handleGetStarted}
        handleGetReferral={handleGetReferral}
        referrer={referrer}
      />
    </>
  );
}

DesktopHero.propTypes = {
  activity: PropTypes.shape({
    hero_img_url: PropTypes.string.isRequired,
  }).isRequired,
  campaign: PropTypes.object.isRequired,
  goals: PropTypes.object.isRequired,
  handleGetReferral: PropTypes.func.isRequired,
  handleGetStarted: PropTypes.func.isRequired,
  referrer: PropTypes.object,
  team: PropTypes.object.isRequired,
};

DesktopHero.defaultProps = {};

export default DesktopHero;
