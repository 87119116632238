import * as React from 'react';
// import PropTypes from 'prop-types';

function Phone(props) {
  return (
    <svg height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.2392 3.04648L12.3671 1.17617C12.2351 1.04353 12.0781 0.938294 11.9052 0.866499C11.7324 0.794704 11.5471 0.757768 11.3599 0.757813C10.9784 0.757813 10.6199 0.907227 10.3509 1.17617L8.33645 3.19062C8.20381 3.32268 8.09857 3.47965 8.02678 3.6525C7.95498 3.82535 7.91805 4.01068 7.91809 4.19785C7.91809 4.5793 8.06751 4.93789 8.33645 5.20684L9.8095 6.67988C9.46469 7.43989 8.9853 8.13129 8.39446 8.7207C7.8051 9.31298 7.11377 9.79412 6.35364 10.141L4.88059 8.66797C4.74853 8.53533 4.59157 8.43009 4.41872 8.3583C4.24587 8.2865 4.06053 8.24956 3.87337 8.24961C3.49192 8.24961 3.13333 8.39902 2.86438 8.66797L0.84817 10.6807C0.715369 10.813 0.610039 10.9702 0.53824 11.1434C0.466442 11.3165 0.429592 11.5022 0.429811 11.6896C0.429811 12.0711 0.579225 12.4297 0.84817 12.6986L2.71672 14.5672C3.14563 14.9979 3.73801 15.2422 4.34622 15.2422C4.47454 15.2422 4.59758 15.2316 4.71887 15.2105C7.0884 14.8203 9.4386 13.56 11.3353 11.665C13.2302 9.77188 14.4888 7.42344 14.8843 5.04688C15.0038 4.3209 14.763 3.57383 14.2392 3.04648Z"
        fill="#23293E"
      />
    </svg>
  );
}

Phone.propTypes = {};

Phone.defaultProps = {};

export default Phone;
