import { Buffer } from 'buffer';

function buildEmail({ bcc = '', from = '', subject, message, to }) {
  // MTS - This is the crazy way we have to format an email
  const str = [
    'Content-Type: text/plain; charset="UTF-8"\n',
    'MIME-Version: 1.0\n',
    'Content-Transfer-Encoding: 7bit\n',
    'To: ',
    to,
    '\n',
    'Bcc: ',
    bcc,
    '\n',
    'From: ',
    from,
    '\n',
    'Subject: ',
    subject,
    '\n\n',
    message,
  ].join('');

  const encodedMail = new Buffer(str).toString('base64').replace(/\+/g, '-').replace(/\//g, '_');
  return encodedMail;
}

export default buildEmail;
