import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Contact from '../Contact';
import StatusBar from '../StatusBar';
import TextWithIcon from '../TextWithIcon';

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

function CallEnded({ activity }) {
  return (
    <>
      <StatusBar bgColor="red">
        <TextWithIcon>Call Ended</TextWithIcon>
      </StatusBar>
      <Content>
        <Contact activity={activity} />
      </Content>
    </>
  );
}

CallEnded.propTypes = {
  activity: PropTypes.object.isRequired,
};

CallEnded.defaultProps = {};

export default CallEnded;
