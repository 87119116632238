import React from 'react';

import Metric from './Metric';
import ImpactiveLink from '../common/Link';
// Hide for now
// import ImpactiveButton from '@web/components/ImpactiveButton';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { kFormatter } from '../../utils/string';
import { useTranslation } from 'react-i18next';

import top1 from '@web/../../assets/images/web/top1.png';
import top2 from '@web/../../assets/images/web/top2.png';
import top3 from '@web/../../assets/images/web/top3.png';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  padding: 12px;
  height: fit-content;
`;

const TeamInfo = styled.div`
  margin: 20px;
`;

// Hide for now
// const TeamLeader = styled.div`
//   color: ${({ theme }) => theme.colors.blackL32};
//   font-size: 0.725rem;
//   margin-right: 10px;
// `;

const Metrics = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledMetric = styled(Metric)`
  margin: 5px 0;
  > div {
    &:first-of-type {
      font-family: ${({ theme }) => theme.fonts.bold};
      font-size: 1.5rem;
    }
    &:last-of-type {
      font-family: ${({ theme }) => theme.fonts.regular};
      font-size: 0.725rem;
    }
  }
`;

const TopSection = styled.div`
  height: 115px;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  background: ${({ theme }) => theme.colors.blue};
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 64px;
    height: 100%;
    border-radius: 8px 0 0 8px;
    background: ${({ theme }) => theme.colors.coral};
    clip-path: polygon(0 0, 100% 0, 55% 100%, 0 100%);
  }
`;

// Hide for now
// const LeadersWrapper = styled.div`
//   margin-top: 20px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;

const PositionImage = styled.img`
  max-width: 75px;
  max-height: 75px;
  object-fit: contain;
  position: relative;
  z-index: 2;
`;

const Points = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.875rem;
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.5rem;
  margin-left: 2rem;
  > div {
    font-size: 1.5rem;
  }
`;

/**
 * Displays Team with rank overlay flag.
 *   campaignId - if provided will add campaign_id query param for TeamDetail link
 *   ...rest - covers className in case Team is wrapped by a styled-component
 */
const Team = ({ team, campaignId, joinTeam, ...rest }) => {
  const { t } = useTranslation();
  const {
    id,
    name: teamName,
    points,
    messages,
    members,
    // joined,
    // team_leader: { first_name: leaderFirstName, last_name: leaderLastName },
  } = team;

  // Construct team link
  let teamLink = `/teams/${id}`;
  teamLink += campaignId ? `?campaign_id=${campaignId}` : '';

  const placeImgs = {
    1: top1,
    2: top2,
    3: top3,
  };

  return (
    <Wrapper {...rest}>
      <TopSection>
        <PositionImage src={placeImgs[`${rest.place}`]} />
        <Points>
          <span>{kFormatter(points)}</span>
          <div>pts</div>
        </Points>
      </TopSection>

      <TeamInfo>
        <ImpactiveLink $isTitle to={teamLink}>
          {teamName}
        </ImpactiveLink>
        {/* Not ready on the backend side
        <LeadersWrapper>
          <TeamLeader>
            {t('teams.team_leader')}: {leaderFirstName} {leaderLastName}
          </TeamLeader>
          {joinTeam && (
            <ImpactiveButton disabled={joined} size="medium" onClick={() => joinTeam(team)}>
              {joined ? t('campaign.joined') : t('campaign.join')}
            </ImpactiveButton>
          )}
        </LeadersWrapper> */}
        <hr />
        <Metrics>
          <StyledMetric
            large
            label={t('teams.member', { count: members })}
            value={kFormatter(members)}
          />
          {/* TODO: CHANGE TO ACTIONS VALUE
          Not ready on the backend side
          <StyledMetric
            large
            label={t('teams.actions_metric_label', { count: points })}
            value={kFormatter(points)}
          /> */}
          <StyledMetric large label={t('teams.messages_metric_label')} value={messages} />
        </Metrics>
      </TeamInfo>
    </Wrapper>
  );
};

Team.propTypes = {
  campaignId: PropTypes.number,
  joinTeam: PropTypes.func,
  team: PropTypes.shape({
    actions: PropTypes.number,
    id: PropTypes.number,
    joined: PropTypes.bool,
    members: PropTypes.number,
    messages: PropTypes.number,
    name: PropTypes.string,
    points: PropTypes.number,
    profile_url: PropTypes.string,
    rank: PropTypes.number,
    reports: PropTypes.number,
    team_leader: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }).isRequired,
};

Team.defaultProps = {
  campaignId: null,
};

export default React.memo(Team);
