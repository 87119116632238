import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { toObject, usePaginatedApi } from '../../../hooks/usePaginatedApi';
import { users } from '../../../services/api';
import ImpactiveButton from '../../ImpactiveButton';
import Text from '../../common/Text';
import StepButtons from './StepButtons';

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

const StepButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

function ContactApproval({ contactsToApprove, decrementStep, lockedToCampaign, onSuccess }) {
  const { t } = useTranslation();
  const [approvalLoading, setApprovalLoading] = React.useState(false);

  const contactsPaginator = toObject(
    usePaginatedApi(users.getContacts, null, {
      // MTS - We need to pass all:true here because we have to pull all of the user's
      // contacts in order to set the approvals.
      all: true,
      per_page: 750,
    }),
  );

  React.useEffect(() => {
    contactsPaginator.loadMore();
    // eslint-disable-next-line
  }, []);

  const { items, loading } = contactsPaginator;

  function handleApprovals() {
    setApprovalLoading(true);
    const syncItems = items
      .filter(item => item.email)
      .filter(item =>
        contactsToApprove.find(({ email }) => email.toLowerCase() === item.email.toLowerCase()),
      );

    const syncIds = syncItems.map(({ id }) => id);
    users
      .updateContactApprovals(syncIds)
      .then(res => {
        setApprovalLoading(false);
        onSuccess();
      })
      .catch(err => {
        setApprovalLoading(false);
        message.error(t('contact.sync.syncing_error'));
      });
  }

  const contactCount = contactsToApprove.length;
  const syncButtonText = t('contact.sync.sync_message', {
    count: contactCount,
  });
  const disabled = loading || approvalLoading || contactCount === 0;
  const noContacts = contactCount === 0;
  return (
    <>
      <Text>{noContacts ? t('contact.sync.no_contacts') : t('contact.sync.start')}</Text>

      {/* MTS - This is a little weird, but we just reset the steps back to 0 if everything failed */}
      {noContacts && (
        <Button type="primary" onClick={() => decrementStep(3)}>
          {t('common.try_again')}
        </Button>
      )}

      {!noContacts && (
        <>
          <ButtonWrapper>
            <ImpactiveButton
              autoFocus
              disabled={disabled}
              icon={<CloudUploadOutlined />}
              loading={loading || approvalLoading}
              onClick={handleApprovals}
              size="large"
              type="primary"
            >
              {syncButtonText}
            </ImpactiveButton>
          </ButtonWrapper>
          <StepButtonWrapper>
            <StepButtons
              disabled={disabled}
              goBack={() => decrementStep(2)}
              buttonType={'link'}
              hideForward
            />
          </StepButtonWrapper>
        </>
      )}
    </>
  );
}

ContactApproval.propTypes = {
  contactsToApprove: PropTypes.array,
  decrementStep: PropTypes.func.isRequired,
  lockedToCampaign: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

ContactApproval.defaultProps = {
  appApproval: false,
};

export default ContactApproval;
