import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ImpactiveLink from '../Link';
import styled from 'styled-components';
import { media } from '@web/styles/theme';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import TeamLeaderboardCard from './components/TeamLeaderboardCard';

import activityRoutes from '@web/services/api/activities';

import team1 from './components/team1.png';
import team2 from './components/team2.png';
import team3 from './components/team3.png';

const IMAGES = [team1, team2, team3];

const StyledTeamLeaderboard = styled.div`
  padding: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.salmon};
  transform: skewY(1.3deg);
`;

const Container = styled.div`
  transform: skewY(-1.3deg);
  padding: 50px 25px 140px;
  margin: 0 auto;

  ${media.lg} {
    padding: 180px 25px;
  }

  ${media.xl} {
    padding: 180px 0 210px;
    width: 80%;
  }

  ${media.xxxl} {
    width: 60%;
  }
`;

const StyledLink = styled(ImpactiveLink)`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.blue};
`;

const LoadingSpin = styled.div`
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;

const SectionTitle = styled.h3`
  font-size: 3em;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  margin-bottom: 60px;
`;

const ActionsList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  row-gap: 20px;

  ${media.lg} {
    grid-template-columns: 1fr 1fr;
    row-gap: 40px;
  }

  ${media.xxl} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const EmptyMessage = styled.div`
  text-align: center;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.white};
  margin: 120px 0 80px;
`;

const TeamLeaderboard = ({ activity, campaign }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState(null);

  const campaignSlug = useMemo(
    () => campaign?.slug ?? activity?.campaign?.slug,
    [activity?.campaign?.slug, campaign?.slug],
  );

  useEffect(() => {
    setLoading(true);

    activityRoutes
      .getVoterRegistrationTeamLeaderboard({
        activitySlug: activity.slug,
        campaignSlug: campaignSlug,
        page: 1,
        perPage: 3,
      })
      .then(({ data }) => {
        setTeams(data.data);
      })
      .catch(error => {
        message.error(t('errors.default'));
        console.error(error);
        setTeams([]);
      })
      .then(() => {
        setLoading(false);
      });
  }, [activity.id, activity.slug, campaign, campaignSlug, t]);

  const leaderboardLink = useMemo(
    () => `${window.location.origin}/${campaignSlug}/${activity.slug}/leaderboard?type=team`,
    [activity.slug, campaignSlug],
  );

  const isEmpty = useMemo(() => !loading && teams?.length === 0, [teams?.length, loading]);

  if (isEmpty) {
    return null;
  }

  return (
    <StyledTeamLeaderboard>
      <Container>
        <SectionTitle>{t('campaign.leaderboard.top_teams')}</SectionTitle>

        {loading && (
          <LoadingSpin>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </LoadingSpin>
        )}

        {isEmpty && <EmptyMessage>{t('campaign.leaderboard.empty_teams')}</EmptyMessage>}

        <ActionsList>
          {!loading &&
            teams &&
            teams.map((team, index) => (
              <TeamLeaderboardCard
                activity={activity}
                key={index}
                team={team}
                img={team.place <= 3 ? IMAGES[team.place - 1] : null}
              />
            ))}
        </ActionsList>
        {!isEmpty && (
          <StyledLink as="a" target="_blank" rel="noreferrer" href={leaderboardLink}>
            {t('campaign.leaderboard.view_full_leaderboard')}
          </StyledLink>
        )}
      </Container>
    </StyledTeamLeaderboard>
  );
};

TeamLeaderboard.propTypes = {
  activity: PropTypes.object,
  campaign: PropTypes.object,
};

export default TeamLeaderboard;
