import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ActivityGoals from './ActivityGoals';
import BoxLogo from './BoxLogo';
import CampaignProfileImage from './CampaignProfileImage';
import { Title } from './TextStyles';
import JoinButton from '@web/components/common/JoinButton';
import { replaceDefaultCampaignImage } from '@web/utils/string';
import MainContainer from './MainContainer';
import { ShareAltOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

const Top = styled.div`
  flex: 1;
  margin-bottom: 20px;
`;

const StyledMainContainer = styled(MainContainer)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-bottom: 60px;
  padding-bottom: 44px;
`;

const GetStartedButton = styled(JoinButton)`
  font-family: ${({ theme }) => theme.fonts.blackItalic};
  &&& {
    height: 56px;
    font-size: 18px;
    max-width: initial;
  }
`;

const ShareButton = styled(JoinButton)`
  &&& {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.darkBlue};
    display: flex;
    height: 56px;
    margin: 0 0 0 16px;
    width: 56px;
  }
`;

const StyledActivityGoals = styled(ActivityGoals)`
  margin-top: 24px;
  margin-bottom: 30px;
`;

const ButtonRow = styled.div`
  align-items: center;
  display: flex;
  margin-top: 22px;
`;

function MobileHero({ activity, campaign, goals, handleGetReferral, handleGetStarted }) {
  const { t } = useTranslation();
  return (
    <>
      <StyledMainContainer backgroundImage={activity.mobile_hero_img_url}>
        <Top>
          <BoxLogo />
        </Top>
        <CampaignProfileImage
          alt="campaign-profile"
          $isMobile
          size={140}
          src={replaceDefaultCampaignImage(campaign.url_profile_img)}
        />
        <Title>{activity.title}</Title>
        <StyledActivityGoals activity={activity} campaign={campaign} goals={goals} isMobile />
        <ButtonRow>
          <GetStartedButton joined={false} onClick={handleGetStarted}>
            {t('campaign.get_started')}
          </GetStartedButton>
          <ShareButton onClick={handleGetReferral}>
            <ShareAltOutlined style={{ color: '#fff' }} />
          </ShareButton>
        </ButtonRow>
      </StyledMainContainer>
    </>
  );
}

MobileHero.propTypes = {
  activity: PropTypes.shape({
    description: PropTypes.string.isRequired,
    mobile_hero_img_url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  campaign: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string.isRequired,
    url_profile_img: PropTypes.string.isRequired,
  }).isRequired,
  goals: PropTypes.object.isRequired,
  handleGetReferral: PropTypes.func.isRequired,
  handleGetStarted: PropTypes.func.isRequired,
};

MobileHero.defaultProps = {};

export default MobileHero;
