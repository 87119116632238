import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { message } from 'antd';

import theme from '@web/styles/theme';

import GeneralCardWrapper from '@web/components/common/Cards/GeneralCardWrapper';
import pxToRem from '@web/utils/pxToRem';
import { GrayText, Header } from '../../TextComponents';
import { getLines, ModalWithNoPadding } from './modalUtils';
import { default as PaymentCompleted } from './PaymentCompleted';
import PaymentForm from './PaymentForm';
import PaymentLine from './PaymentLine';

import { billing } from '@admin/api/billing';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const SubHeader = styled.div`
  color: ${theme.colors.teal};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 20px;
`;
const SubTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 9px;
`;

const StyledGrayText = styled(GrayText)`
  color: ${theme.colors.blackL32};
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 100%;
  height: 100%;
`;

const StyledCardWrapper = styled(GeneralCardWrapper)`
  width: 100%;
  max-width: 45%;
  height: 100%;
  border-radius: 16px;
  padding: ${pxToRem(50)};
`;

const LeftContainer = styled.div`
  padding: ${pxToRem(40)};
  padding-top: ${pxToRem(30)};

  width: 100%;
  height: 100%;
  > * {
    width: 100%;
  }
`;

const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 400px;
`;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const PaymentModal = ({ campaign, visible, onClose, plan }) => {
  const { t } = useTranslation();

  const [paymentComplete, setPaymentComplete] = useState(false);
  const priceLines = getLines(plan);
  const [clientSecret, setClientSecret] = useState(null);

  const addPaymentMethod = useCallback(async () => {
    const {
      data: { client_secret },
    } = await billing.addPaymentMethod({ campaignId: campaign.id });
    setClientSecret(client_secret);
  }, [campaign]);

  useEffect(() => {
    addPaymentMethod();
  }, [addPaymentMethod]);

  const handleSuccess = async ({ setupIntent: { payment_method: paymentMethodId } }) => {
    // Set the processed payment method as a default here
    await billing.setDefaultPaymentMethod({
      campaignId: campaign.id,
      paymentMethodId,
    });

    billing
      .createStripeSubscription({
        campaignId: campaign.id,
        plan: plan.slug,
      })
      .then(() => {
        setPaymentComplete(true);
      })
      .catch(e => {
        message.error(`Failed to start subscription. Please contact our support.`);
      });
  };

  //`Elements` instance that was used to create the Payment Element
  const appearance = {
    rules: {
      '.Input': {
        border: '1px solid #d9d9d9',
      },
      '.Input:focus': {
        boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
        outline: 'none !important',
      },
      '.Input:hover': {
        border: '1px solid #40a9ff',
      },
    },
    theme: 'none',
    variables: {
      borderRadius: '6px',
      fontLineHeight: '1.5715',
      fontSizeBase: '14px',
    },
  };

  return (
    <ModalWithNoPadding
      visible={visible}
      onCancel={!paymentComplete && onClose}
      width={1080}
      footer={null}
      closable={!paymentComplete}
    >
      {!paymentComplete ? (
        <Flex>
          <LeftContainer>
            <Header>{t('auth.start-your-subscription')}</Header>
            <SubHeader>
              {plan.name} {t('auth.membership')}
            </SubHeader>
            <FieldsContainer>
              {clientSecret && (
                <Elements
                  stripe={stripePromise}
                  options={{
                    appearance,
                    clientSecret,
                  }}
                >
                  <PaymentForm onSuccess={handleSuccess} />
                </Elements>
              )}
            </FieldsContainer>
          </LeftContainer>
          <StyledCardWrapper color="#FAFAFA">
            <SubTitle>{t('auth.then-pay-for-what-you-need')}</SubTitle>
            <StyledGrayText>{t('auth.payment-info')}</StyledGrayText>
            <VerticalFlex>
              {priceLines.map(line => (
                <PaymentLine {...line} />
              ))}
            </VerticalFlex>
          </StyledCardWrapper>
        </Flex>
      ) : (
        <PaymentCompleted campaign={campaign} />
      )}
    </ModalWithNoPadding>
  );
};

PaymentModal.propTypes = {
  campaign: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default PaymentModal;
