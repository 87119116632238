import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

const StyledRadioGroup = styled(Radio.Group)`
  &&& {
    .ant-radio-checked .ant-radio-inner {
      border-color: ${({ theme }) => theme.colors.black} !important;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background-color: ${({ theme }) => theme.colors.black}!important;
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: ${({ theme }) => theme.colors.dodgerBlue} !important;
    }

    .ant-radio-inner {
      border-color: ${({ theme, $hasError }) => $hasError && theme.colors.red} !important;
    }

    :focus :active .ant-radio-inner {
      box-shadow: ${({ $hasError }) =>
        $hasError ? 'none' : '0px 0px 0px 2px rgba(24, 144, 255, 0.2)'} !important;
    }
  }
`;

const StyledRadio = styled(Radio)`
  &&& {
    font-family: ${({ theme }) => theme.fonts.regular};
    color: ${({ selected, theme }) => (selected ? theme.colors.black : theme.colors.blackL42)};
    margin-right: 1.5rem;

    :hover .ant-radio-inner {
      border-color: ${({ theme }) => theme.colors.dodgerBlue} !important;
    }

    :focus :active .ant-radio-inner {
      box-shadow: ${({ $hasError }) =>
        $hasError ? 'none' : '0px 0px 0px 2px rgba(24, 144, 255, 0.2)'} !important;
    }
  }
`;

const RadioGroup = ({ radioButtons, name, setFieldValue, value, ...rest }) => {
  const onChange = useCallback(
    ({ target: { value } }) => {
      setFieldValue(name, value);
    },
    [name, setFieldValue],
  );

  return (
    <StyledRadioGroup onChange={onChange} {...rest}>
      {radioButtons.map((item, index) => (
        <StyledRadio key={index} value={item.value} selected={value === item.value}>
          {item.label}
        </StyledRadio>
      ))}
    </StyledRadioGroup>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  radioButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default RadioGroup;
