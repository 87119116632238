import React, { useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import PageTitle from '@web/components/FanOutVotingPlan/components/PageTitle';
import { getTableText } from '@web/components/FanOutVotingPlan/utils';
import theme from '@admin/styles/theme';
import {
  smallBreakpoint,
  small800Breakpoint,
} from '@web/components/VoterRegistration/newComponents/breakpoints';
import { useIs800 } from '@web/components/VoterRegistration/hooks/useIsMobile';

const { Panel } = Collapse;

const Wrapper = styled.div`
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
  padding: 40px;
  gap: 28px;
  border: 2px solid ${theme.colors.darkBlue};
  box-shadow: 4px 4px 0px 0px ${theme.colors.darkBlue};

  ${smallBreakpoint} {
    padding: 40px 40px 30px;
  }

  ${small800Breakpoint} {
    padding: 24px 20px 10px;
    border: unset;
    box-shadow: unset;
    gap: 8px;
    margin-bottom: 0;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;

  ${small800Breakpoint} {
    margin-top: 20px;
  }
`;

const PartyCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const PartyColor = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 3px;
  background: ${({ $type }) => ($type === 'Democrat' ? '#90B9F6' : '#FC9188')};
`;

const PartyPercentage = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.25rem; /* 100% */

  ${small800Breakpoint} {
    font-size: 1.25rem;
    line-height: 1.5rem; /* 120% */
  }

  span {
    color: ${({ theme }) => theme.colors.blackL32};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem; /* 125% */

    ${small800Breakpoint} {
      font-size: 0.875rem;
    }
  }
`;

const LineWrapper = styled.div`
  display: flex;
  padding: 3px;
  gap: 3px;
  border-radius: 10px;
  border: 1px solid rgba(35, 41, 62, 0.06);
  height: 28px;
  width: 100%;
`;

const Line = styled(animated.div)`
  width: 50%;
  height: 20px;
  border-radius: 6px;
  background: ${({ $type }) => ($type === 'Democrat' ? '#90b9f6' : '#FC9188')};
  box-shadow: 0px 4px 7px 0px
    ${({ $type }) =>
      $type === 'Democrat' ? 'rgba(87, 134, 255, 0.22)' : 'rgba(251, 118, 107, 0.22)'};
`;

const CollapseAntdStyles = createGlobalStyle`
    &&& {
    .ant-collapse-content {
      background: ${({ theme }) => theme.colors.transparent}!important;
      border-top: 0px !important;
      padding: 0px !important;
    }
    .ant-collapse-header {
      padding: 0px !important;
    }
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  &&& {
    margin: 16px 0;
    border-radius: 0.5rem;
    overflow: hidden;
  }
`;

const StyledPanel = styled(Panel)`
  padding: 0;
  border: none;
`;

const ResultsText = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  text-align: center;
  font-size: 1rem;
  line-height: 1.25rem; /* 125% */
  text-decoration-line: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.coral};
  }

  svg {
    transition: transform 0.3s ease-in-out;
    margin: 0 0px 5px 5px;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DisclaimerText = styled.div`
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.blackL32};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;

  display: flex;
  flex-direction: column;
  gap: 10px;

  div {
    font-family: ${({ theme }) => theme.fonts.italic};
    font-size: 0.85rem;
    line-height: 1rem;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

const TableMainTitle = styled.div`
  display: flex;
  flex: 0.5;
  background: ${({ $type }) => ($type === 'Democrat' ? '#90b9f6' : '#FC9188')};
  box-shadow: 0px 4px 7px 0px
    ${({ $type }) =>
      $type === 'Democrat' ? 'rgba(87, 134, 255, 0.22)' : 'rgba(251, 118, 107, 0.22)'};
  border-radius: 8px;
  align-items: center;
  justify-content: center;

  margin-top: 16px;
  padding: 10px 0;

  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
`;

const TableTitle = styled.div`
  display: flex;
  flex: 0.2;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-bottom: 8px;
  padding-top: 5px;
`;

const TableCard = styled.div`
  display: flex;
  flex: 0.5;
  background: ${({ $type }) =>
    $type === 'Democrat' ? 'rgba(87, 134, 255, 0.22)' : 'rgba(251, 118, 107, 0.22)'};
  border-radius: 8px;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
`;

const TitleWrapper = styled.div`
  display: flex;
  background: ${({ $type }) =>
    $type === 'Democrat' ? 'rgba(87, 134, 255, 0.22)' : 'rgba(251, 118, 107, 0.22)'};
  flex-direction: column;
`;

const TitleCard = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: center;
  padding: 5px 5px 0px 5px;
`;

const DescriptionCard = styled(TitleCard)`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 5px;

  ${small800Breakpoint} {
    font-size: 0.875rem;
  }
`;

const ExamplesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
`;

const ExampleCard = styled(DescriptionCard)`
  padding: 0px 5px 5px 5px;
  font-family: ${({ theme }) => theme.fonts.italic};
  text-decoration: ${({ $title }) => ($title ? 'underline' : 'none')};
`;

const WhoRepresents = ({ VPAnswers }) => {
  const { t } = useTranslation();
  const isMobile = useIs800();
  const [percentage, setPercentage] = useState([50, 50]);
  const [expanded, setExpanded] = useState(false);
  const democratStyle = useSpring({ width: `${percentage[0] || 0}%` });
  const republicanStyle = useSpring({ width: `${percentage[1] || 0}%` });

  useEffect(() => {
    // trigger animation
    setTimeout(() => {
      const democrat = VPAnswers.filter(({ party }) => party === 'Democrat').length * 10;
      const republican = VPAnswers.filter(({ party }) => party === 'Republican').length * 10;
      setPercentage([democrat, republican]);
    }, 300);
  }, [VPAnswers]);

  const onCollapseChange = useCallback(keys => setExpanded(!!keys.length), []);
  const tableTranslations = getTableText();

  return (
    <Wrapper>
      <PageTitle icon={'🗳'} title={t('fan_out.voting_plan.results.represents')} />
      <CardWrapper>
        <PartyCard>
          <PartyColor $type={'Democrat'} />
          <PartyPercentage>
            {percentage[0]}% <span>{t('fan_out.voting_plan.results.democrat')}</span>
          </PartyPercentage>
        </PartyCard>
        <PartyCard>
          <PartyColor $type={'Republican'} />
          <PartyPercentage>
            {percentage[1]}% <span>{t('fan_out.voting_plan.results.republican')}</span>
          </PartyPercentage>
        </PartyCard>
      </CardWrapper>
      <LineWrapper>
        <Line $type="Democrat" style={democratStyle} />
        <Line $type="Republican" style={republicanStyle} />
      </LineWrapper>
      <>
        <CollapseAntdStyles />
        <StyledCollapse ghost onChange={onCollapseChange}>
          <StyledPanel
            header={
              <ResultsText>
                {expanded
                  ? t('fan_out.voting_plan.results.hide_details')
                  : t('fan_out.voting_plan.results.show_details')}
                <DownOutlined rotate={expanded ? 180 : 0} />
              </ResultsText>
            }
            key={'1'}
            showArrow={false}
          >
            <>
              <TableWrapper>
                <DisclaimerText>
                  {t('fan_out.voting_plan.results.we_vote')}
                  <span>{t('fan_out.voting_plan.results.please_browse')}</span>
                  <div>{t('fan_out.voting_plan.results.note')}</div>
                </DisclaimerText>
                <SectionWrapper>
                  {!isMobile && <TableTitle />}
                  <TableMainTitle $type={'Democrat'}>
                    {t('fan_out.voting_plan.results.democrat')}
                  </TableMainTitle>
                  <TableMainTitle $type={'Republican'}>
                    {t('fan_out.voting_plan.results.republican')}
                  </TableMainTitle>
                </SectionWrapper>
                {tableTranslations.map(({ title, democrat, republican }) => (
                  <Fragment key={title}>
                    {isMobile && <TableTitle>{title}</TableTitle>}
                    <SectionWrapper>
                      {!isMobile && <TableTitle>{title}</TableTitle>}

                      <TableCard $type={'Democrat'}>
                        <TitleWrapper $type={'Democrat'}>
                          <TitleCard>{democrat.title}</TitleCard>
                          <DescriptionCard>{democrat.description}</DescriptionCard>
                        </TitleWrapper>
                        <ExamplesWrapper $type={'Democrat'}>
                          <ExampleCard $title>{democrat.examples_title}</ExampleCard>
                          {democrat.examples.map((example, d) => (
                            <ExampleCard key={example + d}>• {example}</ExampleCard>
                          ))}
                        </ExamplesWrapper>
                      </TableCard>

                      <TableCard $type={'Republican'}>
                        <TitleWrapper $type={'Republican'}>
                          <TitleCard>{republican.title}</TitleCard>
                          <DescriptionCard>{republican.description}</DescriptionCard>
                        </TitleWrapper>
                        <ExamplesWrapper $type={'Republican'}>
                          <ExampleCard $title>{republican.examples_title}</ExampleCard>
                          {republican.examples.map((example, r) => (
                            <ExampleCard key={example + r}>• {example}</ExampleCard>
                          ))}
                        </ExamplesWrapper>
                      </TableCard>
                    </SectionWrapper>
                  </Fragment>
                ))}
              </TableWrapper>
            </>
          </StyledPanel>
        </StyledCollapse>
      </>
    </Wrapper>
  );
};

WhoRepresents.propTypes = {
  VPAnswers: PropTypes.array.isRequired,
};

WhoRepresents.defaultProps = {};

export default WhoRepresents;
