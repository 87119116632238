import PropTypes from 'prop-types';
import React from 'react';

const DoneCheckmark = ({ fill = '#117916', ...rest }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0.54541C5.67439 0.54541 0.54541 5.67439 0.54541 12C0.54541 18.3255 5.67439 23.4545 12 23.4545C18.3255 23.4545 23.4545 18.3255 23.4545 12C23.4545 5.67439 18.3255 0.54541 12 0.54541ZM12 21.5113C6.74825 21.5113 2.48859 17.2517 2.48859 12C2.48859 6.74825 6.74825 2.48859 12 2.48859C17.2517 2.48859 21.5113 6.74825 21.5113 12C21.5113 17.2517 17.2517 21.5113 12 21.5113ZM15.5821 7.93461H16.7812C16.9474 7.93461 17.0446 8.12382 16.9448 8.25933L11.5602 15.7252C11.2355 16.1778 10.563 16.1778 10.2383 15.7252L7.05251 11.3071C6.95535 11.1715 7.05251 10.9823 7.21871 10.9823H8.41785C8.68121 10.9823 8.92666 11.1102 9.08007 11.3224L10.9005 13.8485L14.9198 8.27467C15.0733 8.0599 15.3213 7.93461 15.5821 7.93461Z"
        fill={fill}
      />
    </svg>
  );
};

DoneCheckmark.propTypes = {
  fill: PropTypes.string,
};

DoneCheckmark.defaultProps = {
  fill: '#117916',
};

export default DoneCheckmark;
