import { ErrorMessage, Field } from 'formik';
import { object, string } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div``;

export const Label = styled.div`
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 0.875rem;
  ${({ theme }) => css`
    color: ${theme.colors.blue60};
    font-family: ${theme.fonts.bold};
  `}

  ${({ bold, theme }) =>
    bold &&
    css`
      font-weight: 600;
      text-transform: none;
      color: ${theme.colors.black};
    `}

  ${({ required, theme }) =>
    required &&
    css`
      ::after {
        content: '*';
        color: ${theme.colors.coral};
      }
    `}
`;

const StyledErrorMessage = styled(ErrorMessage).attrs({ component: 'div' })`
  color: #ea5156;
  font-size: 12px;
`;

const StyledField = styled(Field)`
  &&& {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 10px 14px 12px;
    color: ${({ theme }) => theme.colors.blue};
    width: 100%;
    height: 42px;
    background-color: transparent;

    &:hover {
      border: 1px solid #40a9ff;
    }

    &:focus-visible {
      outline: 0;
      border-right-width: 1px !important;
      border: 1px solid #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }
`;

const FormField = ({ name, value, label, placeholder, labelProps, ...rest }) => (
  <Wrapper>
    <Label {...labelProps}>{label}</Label>
    <StyledField {...rest} name={name} value={value} placeholder={placeholder} />
    <StyledErrorMessage data-testid={`${name}-error`} name={name} />
  </Wrapper>
);

FormField.propTypes = {
  label: string,
  labelProps: object,
  name: string.isRequired,
  placeholder: string,
  value: string,
};

FormField.defaultProps = {
  labelProps: {},
};

export default FormField;
