import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSideHeroImage = styled.img`
  border-radius: ${({ rounded }) => (rounded ? '12px' : '')};
  box-shadow: ${({ boxShadow }) => (boxShadow ? '0px 0px 24px 4px rgba(160, 160, 160, 0.42)' : '')};
  margin-bottom: ${({ margin, theme }) => (margin ? theme.spacing.wide : '')};
  max-width: ${({ maxWidth }) => maxWidth};
  width: 100%;
`;

function SideHeroImage(props) {
  if (!props.src) {
    return null;
  }

  return <StyledSideHeroImage {...props} />;
}

SideHeroImage.propTypes = {
  alt: PropTypes.string.isRequired,
  boxShadow: PropTypes.bool,
  margin: PropTypes.bool,
  maxWidth: PropTypes.string,
  rounded: PropTypes.bool,
  src: PropTypes.string.isRequired,
  url: PropTypes.string,
};

SideHeroImage.defaultProps = {
  boxShadow: false,
  margin: false,
  maxWidth: '720px',
  rounded: false,
};

export default SideHeroImage;
