import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import pxToRem from '@web/utils/pxToRem';
import theme from '@web/styles/theme';

const BoldHeader = styled.div`
  margin: 0;
  padding: 0;
  color: #23293e;
  font-weight: 600;
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(24)};
`;
const PlanSelectionHeader = styled.h1`
  margin: 0 0 0 ${pxToRem(20)};
  padding: 0;
  color: #23293e;
  font-weight: 600;
  font-size: ${pxToRem(32)};
  line-height: ${pxToRem(38)};
`;

const Subtext = styled.div`
  margin: 0;
  padding: 0;

  color: #23293e;

  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(17)};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const VertFlex = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  background-color: ${theme.colors.white};
`;

const PlanHeader = ({ plans }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });

  return (
    <>
      <HeaderContainer>
        <PlanSelectionHeader>Select Plan</PlanSelectionHeader>
      </HeaderContainer>
      {plans.map((item, index) => (
        <HeaderContainer key={item.name}>
          <VertFlex>
            <BoldHeader>{item.name}</BoldHeader>
            <Flex>
              {item.startingAt && <Subtext>starting at</Subtext>}
              <BoldHeader>{formatter.format(item.price)}</BoldHeader>
              <Subtext>/ month</Subtext>
            </Flex>
          </VertFlex>
        </HeaderContainer>
      ))}
    </>
  );
};

PlanHeader.propTypes = {
  activeIndex: PropTypes.number,
  plans: PropTypes.arrayOf(PropTypes.object).isRequired,
};

PlanHeader.defaultProps = {
  activeIndex: null,
};

export default PlanHeader;
