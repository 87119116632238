import axios from 'axios';
import routes from './routes';

const { search: route } = routes;

const search = {
  searchCampaignsAndActivities: async ({ page, query, zip_code }, axiosHeaders) => {
    return axios.get(route, {
      params: { page, per_page: 20, term: query, zip_code },
      ...axiosHeaders,
    });
  },
};

export default search;
