import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from 'web/hooks';

import { track } from '../../services/analytics';

import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';

import MaxWidthContainer from '../common/MaxWidthContainer';
import OneCampaignLoading from '../common/OneCampaignLoading';
import CampaignData from './CampaignData';
import ListController from './ListController';

const FrontLine = () => {
  const { t } = useTranslation();

  useDocumentTitle(t('titles.frontline'));

  // Filter can be used for only showing saved activities, leaving it here in case it's needed again.
  const filter = 'all';

  useEffect(() => {
    // Track the page
    track('VIEW_FRONTLINE_SCREEN');
  }, []);

  return (
    <>
      <AppHeader title={t('titles.campaigns')} />
      <AppContainer grayBackground>
        <OneCampaignLoading>
          <CampaignData />
          <MaxWidthContainer>
            <ListController filter={filter} />
          </MaxWidthContainer>
        </OneCampaignLoading>
      </AppContainer>
    </>
  );
};

FrontLine.propTypes = {};

export default FrontLine;
