import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import VotingPlanButton from '@web/components/FanOutVotingPlan/components/VotingPlanButton';
import {
  smallBreakpoint,
  small800Breakpoint,
} from '@web/components/VoterRegistration/newComponents/breakpoints';
import { useIs800 } from '@web/components/VoterRegistration/hooks/useIsMobile';

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 32px;
  flex-direction: row-reverse;
  justify-content: space-between;

  ${smallBreakpoint} {
    margin-top: 24px;
  }

  ${small800Breakpoint} {
    flex-direction: column;
    gap: 12px;
  }
`;

const ButtonsSection = ({ nextButtonProps, prevButtonProps }) => {
  const isMobile = useIs800();

  const showPreviousButton = useMemo(
    () => prevButtonProps?.onClick && prevButtonProps?.text,
    [prevButtonProps],
  );

  return (
    <ButtonsWrapper>
      <VotingPlanButton {...nextButtonProps} isMobile={isMobile} />
      {showPreviousButton && (
        <VotingPlanButton {...prevButtonProps} type={'back'} isMobile={isMobile} />
      )}
    </ButtonsWrapper>
  );
};

ButtonsSection.propTypes = {
  nextButtonProps: PropTypes.shape({
    disabled: PropTypes.bool,
    htmlType: PropTypes.string,
    icon: PropTypes.node,
    onClick: PropTypes.func,
    text: PropTypes.string,
  }),
  prevButtonProps: PropTypes.shape({
    disabled: PropTypes.bool,
    htmlType: PropTypes.string,
    icon: PropTypes.node,
    onClick: PropTypes.func,
    text: PropTypes.string,
  }),
};

ButtonsSection.defaultProps = {};

export default ButtonsSection;
