import Icon, { StarOutlined, StarFilled } from '@ant-design/icons';

// Inactive icons
import democratIconImage from '@assets/images/democrat-icon-active.png';
import democratIconImageInactive from '@assets/images/democrat-icon-inactive.png';
import frequentIconImageInactive from '@assets/images/frequent-voter-emoji-inactive.png';
import frequentIconImage from '@assets/images/frequent-voter-emoji.png';
import inDistrictIconImage from '@assets/images/in-district-icon-active.png';
import inDistrictIconImageInactive from '@assets/images/in-district-icon-inactive.png';
import infrequentIconImageInactive from '@assets/images/infrequent-voter-emoji-inactive.png';
import infrequentIconImage from '@assets/images/infrequent-voter-emoji.png';
import isSuggestedIconImage from '@assets/images/is-suggested-active.png';
import isSuggestedIconImageInactive from '@assets/images/is-suggested-inactive.png';
import republicanIconImage from '@assets/images/republican-icon-active.png';
import republicanIconImageInactive from '@assets/images/republican-icon-inactive.png';
import unmatchedIconImageInactive from '@assets/images/search-icon-transparent.png';
import unmatchedIconImage from '@assets/images/search-icon.png';
import superIconImageInactive from '@assets/images/super-voter-emoji-inactive.png';

// Active icons
import superIconImage from '@assets/images/super-voter-emoji.png';
import inSwingIconImage from '@assets/images/swing-district-icon-active.png';
import inSwingIconImageInactive from '@assets/images/swing-district-icon-inactive.png';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { fonts } from '../../styles/theme';

// Custom icons for filters
const sourceByIcon = {
  democrat: democratIconImageInactive,
  frequent: frequentIconImageInactive,
  inDistrict: inDistrictIconImageInactive,
  infrequent: infrequentIconImageInactive,
  inSwing: inSwingIconImageInactive,
  isSuggested: isSuggestedIconImageInactive,
  republican: republicanIconImageInactive,
  suggested: StarOutlined, // antd vector image
  super: superIconImageInactive,
  unmatched: unmatchedIconImageInactive,
};

const sourceBySelectedIcon = {
  democrat: democratIconImage,
  frequent: frequentIconImage,
  inDistrict: inDistrictIconImage,
  infrequent: infrequentIconImage,
  inSwing: inSwingIconImage,
  isSuggested: isSuggestedIconImage,
  republican: republicanIconImage,
  suggested: StarFilled, // antd vector image
  super: superIconImage,
  unmatched: unmatchedIconImage,
};

// Resolves image based on passed icon parameter
const resolveImage = (icon, active) => {
  const resolvedIcon = active ? sourceBySelectedIcon[icon] : sourceByIcon[icon];

  // Do not return any icon if unrecognized
  if (!resolvedIcon) {
    return;
  }

  // Antd vector icons need to be wrapped with Icon
  if (typeof resolvedIcon === 'object') {
    return <Icon component={resolvedIcon} style={{ verticalAlign: 'middle' }} />;
  }

  // Otherwise we need to wrap normal image icon as Icon's component
  if (typeof resolvedIcon === 'string') {
    const wrappedImageComponent = () => (
      <img src={resolvedIcon} height={16} width={16} alt="icon" />
    );
    return <Icon component={wrappedImageComponent} style={{ verticalAlign: 'text-bottom' }} />;
  }
};

const FilterButtonInner = styled(Button)`
  font-family: ${fonts.italic};
  width: 175px;
  margin: 0.5em;
`;

const FilterButton = ({ active, icon, children, ...props }) => {
  const resolvedIcon = resolveImage(icon, active);
  const buttonType = active ? 'primary' : 'default';
  return (
    <FilterButtonInner type={buttonType} icon={resolvedIcon} shape="round" {...props}>
      {children}
    </FilterButtonInner>
  );
};

FilterButton.defaultProps = {
  active: false,
  icon: null,
};

FilterButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
};

export default FilterButton;
