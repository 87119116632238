import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import ImpactiveModal from '@web/components/common/ImpactiveModal';
import ImpactiveButton from '@web/components/ImpactiveButton';

const StyledH1 = styled.h1`
  font-size: 2rem;
  margin-bottom: 12px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4rem;
  gap: 1rem;
`;

const ImpactiveConfirmModal = ({ onClose, onSubmit, text, title, confirmText, cancelText }) => {
  return (
    <ImpactiveModal visible onCancel={onClose} width={600}>
      <StyledH1>{title}</StyledH1>
      <p>{text}</p>
      <Flex>
        <ImpactiveButton secondary onClick={onClose}>
          {cancelText || 'No'}
        </ImpactiveButton>
        <ImpactiveButton onClick={onSubmit}>{confirmText || 'Yes'}</ImpactiveButton>
      </Flex>
    </ImpactiveModal>
  );
};

ImpactiveConfirmModal.propTypes = {
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

ImpactiveConfirmModal.defaultProps = {
  cancelText: null,
  confirmText: null,
  text: null,
};

export default ImpactiveConfirmModal;
