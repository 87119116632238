import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import pxToRem from '@web/utils/pxToRem';

const SectionHeader = styled.div`
  font-weight: 600;
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(24)};
  color: #23293e;
`;

const EmptyContainer = styled.div`
  padding: 37px 0 13px 20px;

  background-color: #fafafa;
`;

const PlanSectionHeader = ({ text, optionsCount }) => {
  return (
    <>
      <EmptyContainer>
        <SectionHeader>{text}</SectionHeader>
      </EmptyContainer>
      {Array.from(Array(optionsCount)).map(() => (
        <EmptyContainer />
      ))}
    </>
  );
};

PlanSectionHeader.propTypes = {
  optionsCount: PropTypes.number.isRequired,
  text: PropTypes.node,
};

PlanSectionHeader.defaultProps = {
  text: '',
};

export default PlanSectionHeader;
