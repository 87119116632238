import React from 'react';
import { useTranslation } from 'react-i18next';

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks';
import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import AddManually from './pages/AddManually';
import FindVoters from './pages/FindVoters';
import ViewVoter from './pages/ViewVoter';

const AddReport = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  useDocumentTitle('Add Report');

  return (
    <>
      <AppHeader title={t('add_report.find_voter.header')} />
      <AppContainer>
        <Switch>
          <Route exact path={`${path}`} render={props => <FindVoters {...props} />} />
          <Route
            exact
            path={`${path}/new-voter-report`}
            render={props => <AddManually {...props} />}
          />
          <Route exact path={`${path}/view-voter`} render={props => <ViewVoter {...props} />} />
        </Switch>
      </AppContainer>
    </>
  );
};

export default AddReport;
