import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import Button from '../Button';
import { Description, Title } from '../TextStyles';
import Incentives from './Incentives';
import { capitalize } from '@web/utils/string';
import {
  largeBreakpoint,
  smallBreakpoint,
} from '@web/components/VoterRegistration/newComponents/breakpoints';

// flows
import {
  checkRegistration,
  helpOthers,
  registerToVote,
  fanOutRegistration,
} from '../../hooks/useFlows';
import useIsMobile from '@web/components/VoterRegistration/hooks/useIsMobile';

import { useTranslation } from 'react-i18next';

import { vrDefaultColors } from '@web/styles/theme';

const StyledChooseFlow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'title'
    'right'
    'buttons';

  ${largeBreakpoint} {
    align-items: space-between;
    grid-gap: 0 3rem;
    grid-template-columns: 1fr 448px;
    grid-template-areas:
      'title right'
      'buttons right';
  }
`;

const Right = styled.div`
  border-radius: 24px;
  grid-area: right;
  background: ${({ theme, $bgColor }) => $bgColor ?? theme.colors.white};
  overflow: ${({ $isFanOut }) => ($isFanOut ? 'unset' : 'hidden')};

  ${largeBreakpoint} {
    padding: ${({ $isFanOut }) => ($isFanOut ? '0' : '1rem 1rem 1.5rem')};
    box-shadow: ${({ $isFanOut }) => ($isFanOut ? `unset` : `4px 4px 64px 0px #0000000d`)};
  }
`;

const IncentivesContainer = styled.div`
  margin-top: 1rem;

  ${smallBreakpoint} {
    padding: 0 1rem 1.5rem;
  }

  ${largeBreakpoint} {
    margin-top: ${({ $isFanOutReferrer }) => ($isFanOutReferrer ? '1rem' : '2rem')};
  }
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ $isFanOutReferrer }) => ($isFanOutReferrer ? '0' : '1rem')};
  grid-area: buttons;
  margin-top: 2rem;
  ${largeBreakpoint} {
    flex: 1;
    justify-content: flex-end;
    margin-top: 0;
  }
`;

const Image = styled.img`
  border-radius: 12px;
  max-height: ${({ $isFanOutReferrer, $isIncentives }) =>
    $isFanOutReferrer ? '100%' : $isIncentives ? '174px' : '100%'};
  object-fit: ${({ $isFanOutReferrer }) => ($isFanOutReferrer ? 'scale-down' : 'cover')};
  width: 100%;

  ${({ $isFanOutReferrer }) =>
    $isFanOutReferrer &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.blue};
      box-shadow: 4px 4px 0px 0px ${({ theme }) => theme.colors.blue};
    `}

  ${largeBreakpoint} {
    max-height: ${({ $isIncentives }) => ($isIncentives ? '224px' : '100%')};
    margin-bottom: ${({ $isFanOutReferrer, $isIncentives }) =>
      !$isIncentives ? 0 : $isFanOutReferrer ? '1rem' : '2rem'};
  }
`;

const TitleSection = styled.div`
  margin-bottom: 1.5rem;
  ${largeBreakpoint} {
    margin-bottom: 3rem;
    min-height: 175px;
  }
`;

const UnderlineButton = styled(Button)`
  text-decoration-line: underline;
`;

const IncludesText = styled.div`
  color: ${({ theme }) => theme.colors.trueBlack};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  &&& {
    background: ${({ $color, theme }) => $color || theme.colors.blue};
    border: 1px solid ${({ $color, theme }) => $color || theme.colors.blue};
  }
`;

const OutlineButton = styled(Button)`
  &&& {
    background: ${({ $bgColor }) => $bgColor} !important;
    border: 1px solid ${({ $color, theme }) => $color || theme.colors.blue} !important;
    color: ${({ $color, theme }) => $color || theme.colors.blue} !important;
  }
`;

function ChooseFlow({
  activity,
  nextPage,
  setCurrentPage,
  setIsReferralFlow,
  setSelectedFlow,
  team,
  referrer,
  isFanOut,
  isCommonVRFlow,
}) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { contactFormResult } = useSelector(state => state.voterRegistration);

  const isFanOutReferrer = React.useMemo(() => referrer && isFanOut, [referrer, isFanOut]);
  const isIncentives = React.useMemo(
    () => activity?.activity_incentives_attributes?.length > 0,
    [activity],
  );
  const title = React.useMemo(
    () =>
      isFanOutReferrer
        ? t('check_registration.splash.titleFanOut', {
            name: capitalize(referrer?.fullname || ''),
          })
        : team?.action_page_title || activity.title,
    [activity, team, isFanOutReferrer, referrer, t],
  );
  const description = React.useMemo(
    () => team?.action_page_description || activity.description,
    [activity, team],
  );
  const image = React.useMemo(
    () =>
      isFanOutReferrer
        ? isMobile
          ? activity?.mobile_hero_img_url
          : activity?.hero_img_url
        : team?.profile_img_url || activity.media_url,
    [activity, team, isFanOutReferrer, isMobile],
  );

  function chooseFlow(flow, isReferralFlow) {
    setIsReferralFlow(isReferralFlow);
    setSelectedFlow(flow);
    nextPage();
  }

  const referralLink = React.useMemo(
    () => contactFormResult.referral_link,
    [contactFormResult.referral_link],
  );

  const checkMyRegistration = React.useCallback(() => {
    setSelectedFlow(fanOutRegistration);
  }, [setSelectedFlow]);

  const getMyLink = React.useCallback(() => {
    setIsReferralFlow(true);
    referralLink ? setCurrentPage(2) : nextPage();
  }, [nextPage, referralLink, setCurrentPage, setIsReferralFlow]);

  const buttonColor = React.useMemo(
    () => (isCommonVRFlow && activity?.settings?.button_color) || vrDefaultColors.buttonColor,
    [isCommonVRFlow, activity?.settings?.button_color],
  );

  const pageColor = React.useMemo(
    () =>
      (isCommonVRFlow && activity?.settings?.background_color) || vrDefaultColors.backgroundColor,
    [isCommonVRFlow, activity?.settings?.background_color],
  );

  const fontColor = React.useMemo(
    () => (isCommonVRFlow && activity?.settings?.font_color) || vrDefaultColors.fontColor,
    [isCommonVRFlow, activity?.settings?.font_color],
  );

  return (
    <StyledChooseFlow>
      <TitleSection>
        <Title $fontColor={fontColor} dangerouslySetInnerHTML={{ __html: title }} />
        <Description $fontColor={fontColor}>{description}</Description>
      </TitleSection>
      <ButtonColumn $isFanOutReferrer={isFanOutReferrer}>
        {isFanOutReferrer ? (
          <>
            <Button onClick={checkMyRegistration} isFanOut variant="fan_out">
              {t('check_registration.splash.check_my_registration')}
            </Button>
            <UnderlineButton onClick={getMyLink} variant="link">
              {referralLink
                ? t('check_registration.referral.copyLink')
                : t('check_registration.splash.get_my_link')}
            </UnderlineButton>
          </>
        ) : (
          <>
            <StyledButton
              onClick={() => chooseFlow(checkRegistration, false)}
              variant="blue"
              $color={buttonColor}
              customVariant={activity.settings}
              style={isCommonVRFlow ? { color: pageColor } : null}
            >
              {t('check_registration.splash.already_registered')}
            </StyledButton>
            <OutlineButton
              onClick={() => chooseFlow(registerToVote, false)}
              variant="outline"
              $color={buttonColor}
              $bgColor={pageColor}
            >
              {t('check_registration.splash.not_registered')}
            </OutlineButton>
            {[784, 3876].includes(activity?.campaign?.id) ? (
              <OutlineButton
                onClick={() => {
                  window.location.href = 'https://whenweallvote.org/voting101/pledge/';
                }}
                variant="outline"
                $color={buttonColor}
                $bgColor={pageColor}
              >
                {t('check_registration.splash.volunteer')}
              </OutlineButton>
            ) : (
              <OutlineButton
                onClick={() => chooseFlow(helpOthers, true)}
                variant="outline"
                $color={buttonColor}
                $bgColor={pageColor}
              >
                {t('check_registration.splash.cannot_vote')}
              </OutlineButton>
            )}
          </>
        )}
      </ButtonColumn>
      <Right $bgColor={pageColor} $isFanOut={isFanOut}>
        {image && (
          <Image $isFanOutReferrer={isFanOutReferrer} $isIncentives={isIncentives} src={image} />
        )}
        {isIncentives && !isFanOutReferrer && (
          <IncentivesContainer $isFanOutReferrer={isFanOutReferrer}>
            {isFanOutReferrer && isIncentives && (
              <IncludesText>{t('check_registration.splash.sweepstake_includes')}</IncludesText>
            )}
            <Incentives
              fontColor={fontColor}
              iconColor={fontColor}
              pageColor={pageColor}
              incentives={activity.activity_incentives_attributes}
            />
          </IncentivesContainer>
        )}
      </Right>
    </StyledChooseFlow>
  );
}

ChooseFlow.propTypes = {
  activity: PropTypes.shape({
    activity_incentives_attributes: PropTypes.array,
    campaign: PropTypes.object,
    description: PropTypes.string.isRequired,
    hero_img_url: PropTypes.string,
    media_url: PropTypes.string,
    mobile_hero_img_url: PropTypes.string,
    settings: PropTypes.shape({
      background_color: PropTypes.string,
      button_color: PropTypes.string,
      field_outline_color: PropTypes.string,
      font_color: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
  }),
  isCommonVRFlow: PropTypes.bool,
  isFanOut: PropTypes.bool,
  nextPage: PropTypes.func.isRequired,
  referrer: PropTypes.shape({
    fullname: PropTypes.string,
    id: PropTypes.number,
  }),
  setCurrentPage: PropTypes.func.isRequired,
  setIsReferralFlow: PropTypes.func.isRequired,
  setSelectedFlow: PropTypes.func.isRequired,
  team: PropTypes.shape({
    action_page_description: PropTypes.string,
    action_page_title: PropTypes.string,
    profile_img_url: PropTypes.string,
  }),
};

ChooseFlow.defaultProps = {};

export default ChooseFlow;
