import { Layout } from 'antd';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useMedia } from '@web/hooks';

import { track } from '../../services/analytics';
import { media } from '../../styles/theme';
import Sidebar from './Sidebar';

const { Content } = Layout;

// Added a temporary grayBackground param because we're going to change the background to gray everywhere, but step by step.
// So I added this param until we change it everywhere to make the interface look good on other pages.
const StyledContent = styled(Content)`
  background-color: ${({ theme, $grayBackground }) =>
    $grayBackground ? theme.colors.snow : theme.colors.white};
  min-height: 100vh !important;
  ${media.ns} {
    margin-left: 240px;
  }
`;

const StyledSidebar = styled(Sidebar)`
  // We use the hamburger menu in AppHeader on mobile
  display: none;
  ${media.ns} {
    display: block;
  }
`;

/**
 * AppContainer is wrapper component used in all standard page-like components.
 */
const AppContainer = ({ children, grayBackground, ...rest }) => {
  const notSmall = useMedia('(max-width: 600px)');
  const { search } = useLocation();
  const { utm_campaign, utm_source, utm_medium } = queryString.parse(search);
  if (!!utm_campaign || !!utm_source || !!utm_medium) {
    track('UTM_REFERRAL', {
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_source: utm_source,
    });
  }

  return (
    <div {...rest}>
      <Layout>
        {!notSmall && <StyledSidebar data-testid="sidebar-menu" />}
        <StyledContent $grayBackground={grayBackground}>{children}</StyledContent>
      </Layout>
    </div>
  );
};

AppContainer.propTypes = {
  // eslint-disable-next-line
  children: PropTypes.any.isRequired,
  grayBackground: PropTypes.bool,
};

AppContainer.defaultProps = {
  grayBackground: false,
};

export default AppContainer;
