import { RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GoogleAuthButton from 'web/components/ContactSync/components/GoogleAuthButton';
import Text from 'web/components/common/Text';

function GmailApproval({ googleUser, onSkip, setGoogleUser }) {
  const { t } = useTranslation();
  return (
    <>
      <Text>{t('activity.f2f.gmail_approval.description')}</Text>
      <GoogleAuthButton
        appApproval
        googleUser={googleUser}
        scopes={'https://www.googleapis.com/auth/gmail.send'}
        hideText
        incrementStep={() => null}
        setGoogleUser={setGoogleUser}
      />
      <Button onClick={onSkip} type="default">
        {t('activity.f2f.gmail_approval.skip')} <RightOutlined />{' '}
      </Button>
    </>
  );
}

GmailApproval.propTypes = {
  googleUser: PropTypes.any,
  onSkip: PropTypes.func.isRequired,
  setGoogleUser: PropTypes.func.isRequired,
};

GmailApproval.defaultProps = {
  googleUser: null,
};

export default GmailApproval;
