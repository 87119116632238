import React from 'react';

import ImpactiveModal from '../common/ImpactiveModal';
import ImpactiveButton from '../ImpactiveButton';

import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';

const Title = styled.div`
  ${({ theme }) => css`
    font-size: 2rem;
    font-family: ${theme.fonts.bold};
    color: ${theme.colors.blue};
    margin-bottom: 1rem;
  `}
`;

const Description = styled.div`
  ${({ theme }) => css`
    font-size: 1rem;
    font-family: ${theme.fonts.normal};
    color: ${theme.colors.blue60};
  `}
`;

const Footer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  > button {
    &:last-of-type {
      margin-left: 10px;
    }
  }
`;

const ConfirmDeleteModal = ({ visible, onDelete, onCancel }) => {
  const { t } = useTranslation();
  return (
    <ImpactiveModal visible={visible} onCancel={onCancel}>
      <Title>{t('settings.delete_modal.title')}</Title>

      <Description>{t('settings.delete_modal.description')}</Description>
      <br />
      <Description>{t('settings.delete_modal.warning')}</Description>

      <Footer>
        <ImpactiveButton onClick={onCancel}>{t('common.cancel')}</ImpactiveButton>
        <ImpactiveButton onClick={onDelete} style={{ background: 'red' }}>
          {t('settings.delete_account')}
        </ImpactiveButton>
      </Footer>
    </ImpactiveModal>
  );
};

ConfirmDeleteModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default ConfirmDeleteModal;
