import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { kFormatter } from '../../utils/string';

const Wrapper = styled.div`
  display: flex;
  ${({ detailedView }) => detailedView && 'margin: 12px 0'}
`;

const StyledAvatar = styled(Avatar)`
  margin: 8px 12px 8px 0 !important;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.25;
  margin-left: 4px;
`;

const BoldedName = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const RegularName = styled.div`
  font-size: 18px;
`;

const TeamMember = ({ user, detailedView, ...rest }) => {
  const { t } = useTranslation();
  const {
    first_name: firstName,
    last_name: lastName,
    profile_url: profileUrl,
    actions,
    points,
  } = user;

  // Render different component for name conditionally
  const Name = detailedView ? BoldedName : RegularName;

  // Stats actions & points text (only calculated for detailed view)
  let stats = '';
  if (detailedView) {
    stats +=
      `${kFormatter(actions)} ${t('teams.action', { count: actions })}` +
      ` - ${kFormatter(points)} ${t('teams.point', { count: points })}`;
  }

  // Fallback fullname
  const fullname = firstName || lastName ? `${firstName} ${lastName}` : t('teams.anonymous_user');

  return (
    <Wrapper detailedView {...rest}>
      <StyledAvatar src={profileUrl} size={detailedView ? 70 : 60}>
        {firstName}
      </StyledAvatar>
      <Content>
        <Name>{fullname}</Name>
        {detailedView && <div>{stats}</div>}
      </Content>
    </Wrapper>
  );
};

TeamMember.propTypes = {
  detailedView: PropTypes.bool,
  user: PropTypes.shape({
    actions: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    points: PropTypes.number,
    profile_url: PropTypes.string,
  }).isRequired,
};

TeamMember.defaultProps = {
  detailedView: false,
};

export default TeamMember;
