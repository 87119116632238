import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import { myVoteScoreQuestions, questions } from './questions';
import Question from './components/Question';
import theme, { media } from '@web/styles/theme';

const PageWrapper = styled.div`
  color: black;

  width: 100%;
  min-height: 100vh;
  background-color: ${({ $isMVS }) => ($isMVS ? '#dfdfdf' : '#fff2e1')};

  display: flex;
  flex-direction: column;

  * {
    font-family: ${({ $isMVS }) => ($isMVS ? 'Gill Sans' : theme.fonts.AccelerateChange)};
  }
`;

const InnerContainer = styled.div`
  width: 870px;
  margin: 24px auto 0;
  padding-bottom: 64px;

  ${media.nlg} {
    padding: 0 16px;
    width: 100%;
  }
`;

const DidMyFriendVoteFAQs = ({ type }) => {
  const isMVS = useMemo(() => type === 'myVoteScore', [type]);
  const faqs = useMemo(() => (isMVS ? myVoteScoreQuestions : questions), [isMVS]);

  return (
    <PageWrapper $isMVS={isMVS}>
      <Header />
      <InnerContainer>
        {faqs.map(question => (
          <Question question={question} />
        ))}
      </InnerContainer>
    </PageWrapper>
  );
};

DidMyFriendVoteFAQs.propTypes = {
  type: PropTypes.oneOf(['didMyFriendVote', 'didTheyVote', 'myVoteScore']),
};

export default DidMyFriendVoteFAQs;
