import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { media } from '@web/styles/theme';

const StyledTeamLeaderboardCard = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  padding: 15px;
  box-shadow: 0px 14px 44px rgba(89, 99, 134, 0.11);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr 2fr;

  ${media.lg} {
    padding: 15px 15px 25px;
  }
`;

const TeamImage = styled.img`
  display: block;
  width: 100%;
  border-radius: 16px;
`;

const TeamDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TeamTitle = styled.p`
  font-weight: 600;
  font-size: 1.4em;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.blackL25};
  padding: 24px 9px;
  margin: 0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: top;

  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;

  ${media.lg} {
    padding: 18px 9px;
  }
`;

const TeamStats = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
`;

const TeamStat = styled.div`
  padding: 18px;
  text-align: center;
  background-color: rgba(150, 209, 220, 0.1);
  border-radius: 8px;
`;

const TeamStatNumber = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 1rem;
  font-weight: 600;

  ${media.lg} {
    font-size: 1.1rem;
  }
`;

const TeamStatDescription = styled.div`
  font-size: 0.8rem;
`;

const TeamLeaderboardCard = ({ activity, img, team }) => {
  const { t } = useTranslation();
  const formatter = Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact' });

  const teamUrl = useCallback(() => {
    if (!activity) {
      return null;
    }

    return `/campaigns/${activity.campaign.slug}/activities/${activity.slug}/teams/${team.team_slug}`;
  }, [activity, team]);

  return (
    <StyledTeamLeaderboardCard>
      <TeamImage src={img} alt="placeholder-alt" />
      <TeamDetails>
        <TeamTitle>
          {activity && team.is_public && <Link to={teamUrl}>{team.team_name}</Link>}
          {activity && !team.is_public && team.team_name}
          {!activity && team.team_name}
        </TeamTitle>
        <TeamStats>
          <TeamStat>
            <TeamStatNumber>{formatter.format(team.team_referrals_count)}</TeamStatNumber>
            <TeamStatDescription>
              {t('campaign.leaderboard.cols.team_referrals')}
            </TeamStatDescription>
          </TeamStat>

          <TeamStat>
            <TeamStatNumber>{formatter.format(team.contact_referrals_count)}</TeamStatNumber>
            <TeamStatDescription>
              {t('campaign.leaderboard.cols.individual_referrals')}
            </TeamStatDescription>
          </TeamStat>
        </TeamStats>
      </TeamDetails>
    </StyledTeamLeaderboardCard>
  );
};

TeamLeaderboardCard.propTypes = {
  activity: PropTypes.object,
  img: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
};

export default TeamLeaderboardCard;
