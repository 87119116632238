import * as React from 'react';
import PropTypes from 'prop-types';

import calendarTealImage from './calendar-teal.png';
import calendarYellowImage from './calendar-yellow.png';
import calendarGreenImage from './calendar-green.png';

function getColor(color) {
  switch (color) {
    case 'teal':
      return calendarTealImage;
    case 'green':
      return calendarGreenImage;
    default:
      return calendarYellowImage;
  }
}

// MTS - These exist like this for easy conversion into an svg icon which are currently
// being requested.
function CalendarIcon(props) {
  return <img alt="Calendar Icon" src={getColor(props.color)} {...props} />;
}

CalendarIcon.propTypes = {
  color: PropTypes.oneOf(['teal', 'yellow', 'green']),
};

CalendarIcon.defaultProps = {
  color: 'teal',
};

export default CalendarIcon;
