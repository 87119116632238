import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Clipboard from 'react-clipboard.js';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { message } from 'antd';
import ShareButton from './ShareButton';

// icons
import Facebook from '@web/components/VoterRegistration/newComponents/icons/Facebook';
import LinkedIn from '@web/components/VoterRegistration/newComponents/icons/LinkedIn';
import LinkIcon from '@web/components/VoterRegistration/newComponents/icons/Link';
import Twitter from '@web/components/VoterRegistration/newComponents/icons/Twitter';

import { largeBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import { track } from '@web/services/analytics';
import { useTranslation } from 'react-i18next';

const ButtonArea = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 14px;
  grid-template-columns: 1fr;
  ${largeBreakpoint} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

function DesktopLinks({ activity, referralLink, team }) {
  const { t } = useTranslation();
  return (
    <ButtonArea>
      <FacebookShareButton url={referralLink}>
        <ShareButton>
          <Facebook />
          Facebook
        </ShareButton>
      </FacebookShareButton>
      <TwitterShareButton url={referralLink}>
        <ShareButton>
          <Twitter />
          Twitter
        </ShareButton>
      </TwitterShareButton>
      <LinkedinShareButton url={referralLink}>
        <ShareButton>
          <LinkedIn />
          LinkedIn
        </ShareButton>
      </LinkedinShareButton>
      <Clipboard
        component="div"
        data-clipboard-text={referralLink}
        onSuccess={() => {
          message.info(t('common.copied_to_clipboard'));
          track('VR_COPY_SHARE_LINK', {
            activity_id: activity?.id,
            activity_title: activity?.title,
            activity_type: activity?.type,
            campaign_id: activity?.campaign?.id,
            campaign_name: activity?.campaign?.name,
            team_id: team?.id,
            team_name: team?.name,
          });
        }}
      >
        <ShareButton>
          <LinkIcon />
          {t('check_registration.referral.copyButton')}
        </ShareButton>
      </Clipboard>
    </ButtonArea>
  );
}

DesktopLinks.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  referralLink: PropTypes.string.isRequired,
  team: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

DesktopLinks.defaultProps = {};

export default DesktopLinks;
