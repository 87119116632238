import React, { useEffect, useRef } from 'react';

import { Form, Formik } from 'formik';
import FormField from '@web/components/common/shared/FormField';
import SelectComponent from '@web/components/common/shared/Select';
import ImpactiveButton from '@web/components/ImpactiveButton';
import { FieldsWrapper, StickyFooter } from '../../../components/shared';
import ContentWrapper from '../../../components/ContentWrapper';

import * as yup from 'yup';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ageRanges } from '../../../utils';

import states from 'web/data/states';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 23px;
  > button {
    width: 20%;
    min-width: 170px;
  }
`;

const FindVotersForm = ({ campaignId, onSubmit }) => {
  const { t } = useTranslation();

  const ref = useRef(null);

  const IdVotersFormSchema = yup.object().shape({
    ageRange: yup.string().required(t('idvoters.validation.age')),
    city: yup.string(),
    firstName: yup.string().required(t('idvoters.validation.firstname')),
    lastName: yup.string().required(t('idvoters.validation.lastname')),
    stateAbbv: yup.string().required(t('idvoters.validation.state')),
    zipCode: yup.number(t('idvoters.validation.zip')),
  });

  useEffect(() => {
    ref.current?.resetForm();
  }, [campaignId]);

  return (
    <ContentWrapper title={t('add_report.find_voter.lookup_contact')} showAddManuallyButton>
      <Formik
        initialValues={{
          ageRange: '18-255',
          city: '',
          firstName: '',
          lastName: '',
          stateAbbv: '',
          zipCode: '',
        }}
        ref={ref}
        onSubmit={onSubmit}
        validationSchema={IdVotersFormSchema}
        render={({ isSubmitting, setFieldValue }) => (
          <Form>
            <FieldsWrapper $twoColumns={false}>
              <FormField
                label={t('add_report.form.first_name')}
                name="firstName"
                placeholder={t('add_report.form.first_name')}
              />
              <FormField
                label={t('add_report.form.last_name')}
                name="lastName"
                placeholder={t('add_report.form.last_name')}
              />
              <SelectComponent
                label={t('add_report.form.state')}
                placeholder={t('add_report.form.state')}
                options={states}
                name={'stateAbbv'}
                onChange={val => setFieldValue('stateAbbv', val)}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{
                  minWidth: 'unset',
                }}
              />
              <SelectComponent
                label={t('add_report.form.age_range')}
                placeholder={t('add_report.form.age_range')}
                options={ageRanges(t)}
                onChange={val => setFieldValue('ageRange', val)}
                defaultValue={'18-255'}
                name="ageRange"
                style={{
                  minWidth: 'unset',
                }}
              />
              <FormField
                label={t('add_report.form.city')}
                name="city"
                placeholder={t('add_report.form.city')}
              />
              <FormField
                label={t('add_report.form.zip')}
                name="zipCode"
                placeholder={t('add_report.form.zip')}
              />
            </FieldsWrapper>
            <StickyFooter>
              <ButtonContainer>
                <div>{t('add_report.form.required')}</div>
                <ImpactiveButton type="submit" disabled={!campaignId || isSubmitting}>
                  {t('add_report.buttons.search')}
                </ImpactiveButton>
              </ButtonContainer>
            </StickyFooter>
          </Form>
        )}
      />
    </ContentWrapper>
  );
};

FindVotersForm.propTypes = {
  campaignId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

FindVotersForm.defaultProps = {
  campaignId: null,
  firstName: '',
  lastName: '',
};

export default FindVotersForm;
