import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledCard } from '../styles';
import { RadioGroupFormPartial, InputFieldFormPartial } from './partials';
import theme from '@web/styles/theme';

const Title = styled.div`
  display: inline-block;
  font-weight: bold;
  margin-bottom: 1rem;
  color: ${({ $color }) => $color ?? theme.formDefaultColors.fontColor};
`;

const Required = styled.span`
  padding-left: 4px;
  color: red;
`;

function mapQuestionTypeToFormPartial(type) {
  switch (type) {
    case 'multiple_choice':
      return RadioGroupFormPartial;
    case 'closed':
      return RadioGroupFormPartial;
    case 'short_answer':
      return InputFieldFormPartial;
    default:
      throw new Error('Unknown survey question type');
  }
}

function QuestionCard({
  handleChange,
  index,
  question: { answer_mappings, question: title, question_type: type, required },
  activity,
}) {
  const FormPartialComponent = mapQuestionTypeToFormPartial(type);

  const { section_background_color, main_font_color } = activity.settings ?? {};

  return (
    <StyledCard $background={section_background_color}>
      <Title $color={main_font_color}>
        {title}
        {required && <Required>*</Required>}
      </Title>

      <FormPartialComponent
        activity={activity}
        handleChange={handleChange}
        name={`question_answers[${index}].value`}
        options={answer_mappings}
      />
    </StyledCard>
  );
}

QuestionCard.propTypes = {
  activity: PropTypes.shape({
    settings: PropTypes.shape({
      main_font_color: PropTypes.string,
      section_background_color: PropTypes.string,
    }),
  }),
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number,
  question: PropTypes.shape({
    answer_mappings: PropTypes.object,
    id: PropTypes.number,
    question: PropTypes.string,
    question_type: PropTypes.string,
    required: PropTypes.bool,
  }).isRequired,
};

export default QuestionCard;
