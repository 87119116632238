import * as React from 'react';
// import PropTypes from 'prop-types';

function House(props) {
  return (
    <svg height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.3097 7.8765L9.06929 0.641343C9.01715 0.589108 8.95523 0.547667 8.88706 0.519392C8.8189 0.491117 8.74582 0.476562 8.67202 0.476562C8.59822 0.476562 8.52515 0.491117 8.45698 0.519392C8.38881 0.547667 8.32689 0.589108 8.27476 0.641343L1.03433 7.8765C0.823389 8.08744 0.703857 8.37396 0.703857 8.67279C0.703857 9.2933 1.20835 9.79779 1.82886 9.79779H2.59175V14.9605C2.59175 15.2716 2.84312 15.523 3.15425 15.523H7.54702V11.5855H9.51577V15.523H14.1898C14.5009 15.523 14.7523 15.2716 14.7523 14.9605V9.79779H15.5152C15.814 9.79779 16.1005 9.68002 16.3115 9.46732C16.7492 9.02787 16.7492 8.31595 16.3097 7.8765Z"
        fill="#23293E"
      />
    </svg>
  );
}

House.propTypes = {};

House.defaultProps = {};

export default House;
