import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../styles/theme';

const StyledContact = styled.li`
  align-items: center;
  cursor: ${({ hasCheckbox }) => (hasCheckbox ? 'pointer' : 'initial')};
  display: flex;
  list-style: none;
  padding: 20px;

  ${media.sm} {
    padding: 5px;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Email = styled.div`
  opacity: 0.8;
`;

const Name = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 16px;
`;

const StyledAvatar = styled(Avatar)`
  ${media.sm} {
    display: none !important;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

function Contact({
  email,
  hasCheckbox,
  disabledCheckbox,
  firstName,
  lastName,
  selected,
  toggleContact,
}) {
  function getInitials() {
    let first = '';
    let last = '';
    if (firstName) {
      first = firstName[0].toUpperCase();
    }
    if (lastName) {
      last = lastName[0].toUpperCase();
    }
    return first + last;
  }

  const name = `${firstName || ''} ${lastName || ''}`;
  return (
    <StyledContact
      hasCheckbox={hasCheckbox}
      onClick={() => toggleContact({ email, name }, selected)}
    >
      {hasCheckbox && (
        <Checkbox
          type="checkbox"
          onChange={() => null}
          checked={selected}
          disabled={disabledCheckbox}
        />
      )}
      <StyledAvatar size={50}>{getInitials()}</StyledAvatar>
      <Right>
        {(firstName || lastName) && <Name>{name}</Name>}
        <Email>{email.toLowerCase()}</Email>
      </Right>
    </StyledContact>
  );
}

Contact.propTypes = {
  disabledCheckbox: PropTypes.bool,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  hasCheckbox: PropTypes.bool,
  lastName: PropTypes.string,
  selected: PropTypes.bool,
  toggleContact: PropTypes.func,
};

Contact.defaultProps = {
  disabledCheckbox: false,
  firstName: '',
  hasCheckbox: false,
  lastName: '',
  selected: false,
  toggleContact: () => null,
};

export default Contact;
