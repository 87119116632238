import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Clipboard from 'react-clipboard.js';
import { message } from 'antd';

import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import theme, { colors, media } from 'web/styles/theme';
import { truncate } from '@web/utils/string';
import Entries from '../icons/Entries';
import Button from '@web/components/VoterRegistration/newComponents/Button';
import Copy from '@web/components/FanOut/components/icons/Copy';
import ShareIcon from '@web/components/VoterRegistration/newComponents/icons/Share';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';

const Card = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 12px;

  /* flex: 1 ${({ $isMobile }) => ($isMobile ? '100%' : 'calc(50% - 10px)')}; */
  flex: 0 100%;
  // Show 2 items per row on xlarge screens
  ${media.xl} {
    // calc gap 20px
    flex: 0 calc(50% - 10px);
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  ${smallBreakpoint} {
    gap: 12px;
  }
`;

const LabelsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
`;

const LabelContainer = styled.div`
  display: flex;
  height: 38px;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 40px;

  background: ${({ $entries }) =>
    $entries ? 'rgba(255, 153, 0, 0.20)' : 'rgba(197, 197, 197, 0.27)'};
  opacity: ${({ $active }) => !$active && 0.2};
`;

const Counter = styled.div`
  color: ${({ theme }) => theme.colors.trueBlack};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.875rem; /* 187.5% */
  /* shadow-m */
  text-shadow: 1px 3px 12px rgba(0, 0, 0, 0.08);
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.trueBlack};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.875rem; /* 187.5% */
  /* shadow-m */
  text-shadow: 1px 3px 12px rgba(0, 0, 0, 0.08);
`;

const Title = styled.a`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
  text-decoration: none;
  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.blue};
  }

  ${smallBreakpoint} {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;

const LinkContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  gap: 8px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
`;

const LinkClipboard = styled(Clipboard)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 16px;
  flex: 3;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  border: 1px solid rgba(57, 62, 91, 0.2);
  height: 64px;

  &:hover {
    cursor: pointer;
    border-color: rgba(57, 62, 91, 0.5);
  }

  /* Shadow/Default */
  box-shadow: 8px 8px 32px 0px rgba(0, 41, 64, 0.06);
`;

const LinkText = styled.div`
  color: ${({ theme }) => theme.colors.trueBlack};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
`;

const StyledButton = styled(Button)`
  text-align: center;
  letter-spacing: 0.5px;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.0625rem;
  flex: 1;
  gap: 6px;

  &&& {
    height: 64px;
    min-width: 200px;
    box-shadow: 8px 8px 32px 0px rgba(0, 41, 64, 0.06);
  }
`;

const SweepstakeCard = ({ sweepstake, team, setShareLink, activity }) => {
  const {
    campaign: { slug: campaignSlug },
    description,
    referral_link: link,
    slug: activitySlug,
    title,
    total_fan_out_points: points,
  } = sweepstake;
  const { t } = useTranslation();
  const history = useHistory();

  const showMobileShare = useMemo(() => navigator?.share, []);

  const titleText = useMemo(() => truncate(title, 50), [title]);
  const descriptionText = useMemo(() => truncate(description, 200), [description]);

  const enter = useCallback(() => {
    history.replace(`/campaigns/${campaignSlug}/activities/${activitySlug}`);
    document.title = titleText;
  }, [campaignSlug, activitySlug, history, titleText]);

  const copyLink = useCallback(() => {
    amplitudeTrack({ activity, name: 'FAN_OUT_COPY_SHARE_LINK', team });
    message.success(t('activity.share.copied_to_clipboard'));
  }, [t, activity, team]);

  const openShareModal = useCallback(() => {
    setShareLink(link);
  }, [setShareLink, link]);

  return (
    <Card>
      <TopSection>
        <LabelsWrapper>
          <LabelContainer $entries $active={!!link}>
            <Entries fullColor fill={colors.trueBlack} />
            <Counter>{points || 0}</Counter>
            <Text>{t('fan_out.sweepstakes.entry', { count: points || 0 })}</Text>
          </LabelContainer>
        </LabelsWrapper>
        <Title onClick={enter}>{titleText}</Title>
        <Description>{descriptionText}</Description>
      </TopSection>
      <LinkContainer>
        {link ? (
          <>
            <LinkClipboard component="span" data-clipboard-text={link} onSuccess={copyLink}>
              <Copy />
              <LinkText>{link}</LinkText>
            </LinkClipboard>
            <StyledButton onClick={openShareModal} variant="fan_out">
              {showMobileShare ? (
                <>
                  <ShareIcon fill={theme.colors.blue} />
                  {t('button.share')}
                </>
              ) : (
                <>
                  <Copy fill={theme.colors.blue} />
                  {t('fan_out.sweepstakes.copy')}
                </>
              )}
            </StyledButton>
          </>
        ) : (
          <StyledButton onClick={enter} variant="fan_out">
            {t('fan_out.sweepstakes.enter_sweepstakes')}
          </StyledButton>
        )}
      </LinkContainer>
    </Card>
  );
};

SweepstakeCard.propTypes = {
  activity: PropTypes.object,
  setShareLink: PropTypes.func.isRequired,
  sweepstake: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
    }).isRequired,
    description: PropTypes.string,
    id: PropTypes.number,
    referral_link: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    total_fan_out_points: PropTypes.number,
  }).isRequired,
  team: PropTypes.object,
};

SweepstakeCard.defaultProps = {
  team: null,
};

export default SweepstakeCard;
