import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TwitchContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  width: 100%;
  min-height: 600px;
  overflow: hidden;

  iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

function TwitchEmbed({ mediaUrl }) {
  const [channel, setChannel] = useState(null);
  const [loaded, setLoaded] = useState(false);

  /* eslint-disable no-undef */
  const setupTwitch = () => {
    if (Twitch && !loaded && channel) {
      new Twitch.Embed('twitch-embed', {
        autoplay: false,
        channel: channel,
        // layout: 'video',
      });

      setLoaded(true);
    }
  };
  /* eslint-enable no-undef */

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.twitch.tv/embed/v1.js';
    script.async = true;
    script.onload = () => setupTwitch();

    document.body.appendChild(script);
  });

  useEffect(() => {
    setChannel(mediaUrl);
  }, [mediaUrl]);

  return (
    <div>
      <TwitchContainer id="twitch-embed"></TwitchContainer>
      {false && channel && (
        <iframe
          src={`https://www.twitch.tv/embed/${channel}/chat?parent=${window.location.hostname}`}
          height="500"
          width="350"
          title="twitchEmbed"
        ></iframe>
      )}
    </div>
  );
}

TwitchEmbed.propTypes = {
  mediaUrl: PropTypes.string.isRequired,
};

TwitchEmbed.defaultProps = {};

export default TwitchEmbed;
