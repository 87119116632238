import styled from 'styled-components';

const SIZE = 40;

const IconWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black};
  border: none;
  border-radius: ${SIZE / 2}px;
  cursor: pointer;
  display: flex;
  height: ${SIZE}px;
  justify-content: center;
  margin-left: ${({ theme }) => theme.spacing.tight};
  transition: opacity 200ms ease-out;
  width: ${SIZE}px;
  :hover {
    opacity: 0.7;
  }
`;

export default IconWrapper;
