import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { media } from '@web/styles/theme';

const StyledCampaignImage = styled.div`
  width: 160px;
  height: 160px;
  margin-bottom: 20px;
  border-radius: 215px;
  border: 6px solid ${({ theme }) => theme.colors.white};
  overflow: hidden;

  ${media.lg} {
    width: 215px;
    height: 215px;
    margin-bottom: 40px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CampaignImage = ({ alt, src }) => {
  return (
    <StyledCampaignImage>
      <img alt={alt} src={src} />
    </StyledCampaignImage>
  );
};

CampaignImage.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export default CampaignImage;
