import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../components/Header';
import { Content } from '../../components/shared';
import { addReportBreadcrumb } from '../../components/BreadcrumbData';
import VotersHistoryModal from '../../components/VotersHistoryModal';
import VotersListItem from '../FindVoters/components/VotersList/VotersListItem';
import ContentWrapper from '../../components/ContentWrapper';
import ViewVoterForm from './components/ViewVoterForm';

import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useSendReport from '../../useSendReport';

const ViewVoter = () => {
  const { t } = useTranslation();
  const campaign = useSelector(state => state.oneCampaign.selectedCampaign);
  const isFirstRun = useRef(true);
  const [showVoterModal, setShowVoterModal] = useState(null);

  const [redirect, setRedirect] = useState(false);

  const {
    state: { voter },
  } = useLocation();

  const { tags, customFields, submitReport } = useSendReport(false, voter);

  // If the campaign changes, we need to redirect to the voter search screen
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      setRedirect(true);
    }
  }, [campaign]);

  if (redirect) {
    return <Redirect to={'/add-report'} />;
  }

  return (
    <Content>
      <PageHeader
        title={t('add_report.fill_report.header')}
        description={t('add_report.fill_report.description')}
        breadcrumbData={addReportBreadcrumb('viewVoter')}
      />
      <ContentWrapper title={t('add_report.fill_report.selected_contact')} showSearchAgainButton>
        <VotersListItem voter={voter} showVoterModal={setShowVoterModal} />
      </ContentWrapper>

      <ViewVoterForm
        voter={voter}
        onSubmit={submitReport}
        tags={tags}
        customFields={customFields}
        redirectToFindVotersPage={() => setRedirect(true)}
      />

      <VotersHistoryModal
        closeModal={() => setShowVoterModal(null)}
        voter={showVoterModal}
        visible={!!showVoterModal}
      />
    </Content>
  );
};

export default ViewVoter;
