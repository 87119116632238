import PropTypes from 'prop-types';
import React from 'react';
import * as Icons from './components';

function Icon(props) {
  const { name, ...rest } = props;
  const IconComponent = Icons[name];
  return <IconComponent {...rest} />;
}

export default Icon;

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

Icon.defaultProps = {};
