import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

const ImpactiveLink = styled(Link)`
  align-items: center;
  color: ${({ theme }) => theme.colors.coral};
  display: flex;
  font-family: ${({ theme, $isTitle }) => {
    return $isTitle ? theme.fonts.semibold : theme.fonts.semibolditalic;
  }};
  font-size: ${({ $isTitle }) => ($isTitle ? '1.5rem' : '1rem')};
  > span {
    margin-right: ${({ $withIcon }) => ($withIcon ? '5px' : 0)};
  }
  &:hover {
    text-decoration: none;
  }
`;

ImpactiveLink.propTypes = {
  $isTitle: PropTypes.bool,
  $withIcon: PropTypes.bool,
};

ImpactiveLink.defaultProps = {
  $isTitle: false,
  $withIcon: false,
};

export default ImpactiveLink;
