const routes = {
  activities: '/api/v2/client/campaigns/:campaign_id/activities',
  activity_scripts: '/api/v2/client/campaigns/:campaign_id/activities/:activity_id/scripts',
  auth: '/api/v2/users',
  base: '/api/v2/client',
  campaigns: '/api/v2/client/campaigns',
  campaigns_and_topics: '/api/v2/client/campaigns_and_topics', // NOTE: This endpoint is only used for Explore tab/Leaderboard case now
  contact_lists: '/api/v2/client/contact_lists',
  contacts: '/api/v2/client/contacts',
  general_activities: '/api/v2/client/activities',
  search: '/api/v2/client/search',
  tags: '/api/v2/client/tags',
  teams: '/api/v2/client/teams',
  user: '/api/v2/client/user',
  user_activities: '/api/v2/client/user/activities',
};

export default routes;
