import { CheckCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  color: #096e03;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const CheckIcon = styled(CheckCircleOutlined)`
  && {
    font-size: 20px;
    margin-left: 8px;
    vertical-align: top;
  }
`;

const SuccessText = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    {children}
    <CheckIcon />
  </Wrapper>
);

SuccessText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default SuccessText;
