import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DotLoader from '@web/components/DotLoader';

const StyledStatusBarButton = styled.button`
  align-items: center;
  background-color: ${({ $bgColor }) => $bgColor};
  border: none;
  border-radius: 12px;
  cursor: ${({ disabled, $loading }) => (disabled || $loading ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  height: 24px;
  justify-content: center;
  min-width: 70px;
  padding: 0 20px;
`;

const Text = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.blackItalic};
  font-size: 10.5px;
  letter-spacing: 1.15px;
  text-transform: uppercase;
`;

function StatusBarButton({ bgColor, children, loading, ...rest }) {
  function getColor() {
    switch (bgColor) {
      case 'yellow':
        return '#D89B35';
      case 'darkBlue':
        return '#0F1423';
      case 'teal':
        return '#4299A4';
      default:
        return '#D89B35';
    }
  }
  return (
    <StyledStatusBarButton $bgColor={getColor()} $loading={loading} {...rest}>
      {loading ? <DotLoader /> : <Text>{children}</Text>}
    </StyledStatusBarButton>
  );
}

StatusBarButton.propTypes = {
  bgColor: PropTypes.oneOf(['darkBlue', 'teal', 'yellow']),
  children: PropTypes.any,
  loading: PropTypes.bool.isRequired,
};

StatusBarButton.defaultProps = {
  bgColor: 'darkBlue',
  children: null,
  loading: false,
};

export default StatusBarButton;
