import React from 'react';

import { Carousel } from 'antd';

import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledCarousel = styled(Carousel)`
  &&& {
    .slick-list {
    }
    .slick-dots-bottom {
      bottom: -30px;
      > li {
        width: 5px;
        button {
          background: #c4c4c4;
          opacity: 0.4;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
      .slick-active {
        width: 5px;
        button {
          background: #c4c4c4;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
    }
  }
`;

const ImpactiveCarousel = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledCarousel ref={ref} {...rest}>
    {children}
  </StyledCarousel>
));

ImpactiveCarousel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ImpactiveCarousel;
