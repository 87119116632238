import * as React from 'react';
import PropTypes from 'prop-types';

function Headphones({ size, ...rest }) {
  return (
    <svg height={size} viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="39" cy="39" r="39" fill="#D5ECEF" />
      <path
        d="M23.7961 15.2029C21.0859 15.2029 18.5767 16.0609 16.5249 17.5204V38.004C18.5767 39.4635 21.0859 40.3215 23.7961 40.3215C30.7328 40.3215 36.3554 34.6989 36.3554 27.7622C36.3554 20.8255 30.7328 15.2029 23.7961 15.2029Z"
        fill="#8CC4CA"
      />
      <path d="M36.3556 28.4233H27.1013V62.7962H36.3556V28.4233Z" fill="#8CC4CA" />
      <path
        d="M27.1013 15.6431V39.8812C32.4344 38.4296 36.3556 33.554 36.3556 27.7621C36.3556 21.9703 32.4344 17.0947 27.1013 15.6431Z"
        fill="#4D9DA6"
      />
      <path d="M36.3556 57.5081H27.1013V62.7962H36.3556V57.5081Z" fill="#4D9DA6" />
      <path
        d="M54.2029 15.2029C56.9131 15.2029 59.4223 16.0609 61.4741 17.5204V38.004C59.4223 39.4635 56.9131 40.3215 54.2029 40.3215C47.2662 40.3215 41.6436 34.6989 41.6436 27.7622C41.6436 20.8255 47.2662 15.2029 54.2029 15.2029Z"
        fill="#8CC4CA"
      />
      <path d="M50.8978 28.4233H41.6436V62.7962H50.8978V28.4233Z" fill="#8CC4CA" />
      <path
        d="M50.8978 15.6431V39.8812C45.5647 38.4296 41.6436 33.554 41.6436 27.7621C41.6436 21.9703 45.5647 17.0933 50.8978 15.6431Z"
        fill="#4D9DA6"
      />
      <path d="M50.8978 57.5081H41.6436V62.7962H50.8978V57.5081Z" fill="#4D9DA6" />
    </svg>
  );
}

Headphones.propTypes = {
  size: PropTypes.number,
};

Headphones.defaultProps = {
  size: 78,
};

export default Headphones;
