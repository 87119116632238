import { Avatar } from 'antd';
import styled from 'styled-components';
import { media } from '../../../styles/theme';

export const Container = styled.div`
  margin: 0 auto;
  width: 988px;
  max-width: 100%;
  padding: 0 24px;
`;

export const Wrapper = styled.div`
  // Reset global styles for this template
  color: #000;

  a {
    color: #4680d9;
  }
`;

export const Header = styled.div`
  background: #061240;
  color: #fff;
  padding: 20px 0;
`;

export const HeaderInner = styled(Container)`
  ${media.lg} {
    display: flex;
    align-items: center;
  }
`;

export const TeamInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  ${media.lg} {
    margin-right: 40px;
    margin-bottom: 0;
  }
`;

export const TeamAvatar = styled(Avatar).attrs({ size: 60 })`
  border: 2px solid #fff;
  flex: none;
`;

export const TeamNameWrapper = styled.div`
  margin-left: 16px;
`;

export const TeamNameTop = styled.div`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 1;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  opacity: 0.85;
`;

export const TeamName = styled.div`
  font-size: 30px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  line-height: 1;
  margin-top: 4px;
`;

export const TeamMetrics = styled.div`
  display: flex;
  align-items: center;
`;

export const TeamMetric = styled.div`
  margin-right: 32px;
`;

export const TeamMetricValue = styled.div`
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.light};
  line-height: 1;
`;

export const TeamMetricLabel = styled.div`
  font-size: 10px;
  font-family: ${({ theme }) => theme.fonts.bold};
  letter-spacing: 0.1em;
  line-height: 1.2em;
  margin-top: 2px;
  text-transform: uppercase;
`;

export const TeamDescription = styled.div`
  font-size: 10px;
  font-family: ${({ theme }) => theme.fonts.light};
  line-height: 1;
`;

export const Body = styled.div`
  padding: 32px 0;

  ${media.lg} {
    padding: 40px 0;
  }
`;

export const BodyInner = styled(Container)`
  ${media.lg} {
    display: flex;
  }
`;

export const Sidebar = styled.div`
  margin-bottom: 40px;

  ${media.lg} {
    flex: none;
    width: 220px;
    padding-right: 24px;
    border-right: 1px solid ${({ theme }) => theme.colors.borderGray};
    margin-bottom: 0;
    margin-right: 40px;
  }
`;

export const CampaignNameTop = styled.div`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 1;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  opacity: 0.85;
`;

export const CampaignAvatarAndName = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  ${media.lg} {
    margin-top: 4px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CampaignName = styled.div`
  font-size: 25px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  line-height: 1;
  word-break: break-word;

  ${media.sm} {
    font-size: 18px;
  }
`;

export const CampaignWebsiteUrl = styled.a`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.boldItalic};
  line-height: 12px;
  word-break: break-word;
  display: none;
  margin-top: 4px;

  ${media.lg} {
    display: inline-block;
  }
`;

export const CampaignAvatar = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 100%;
  margin-right: 10px;
  flex: none;

  ${media.lg} {
    width: 140px;
    height: 140px;
    margin-top: 20px;
    margin-right: 0;
  }
`;

export const CampaignDescription = styled.div`
  display: none;
  font-size: 12px;
  line-height: 15px;
  word-break: break-word;
  opacity: 0.85;
  margin-top: 20px;
  width: 160px;

  ${media.lg} {
    display: block;
  }
`;

export const Content = styled.div`
  flex: 1;
  min-width: 0;
  font-size: 14px;
`;

export const StepText = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  letter-spacing: 0.08em;
  line-height: 1;
  text-transform: uppercase;
`;

export const Title = styled.h3`
  color: #000;
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  text-transform: capitalize;
  margin: 10px 0 0;
`;

export const Instruction = styled.div`
  margin-top: 6px;
`;
