import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import HeroImages from '@web/components/FanOut/components/HeroImages';
import SweepstakesEntries from '@web/components/FanOut/components/SweepstakesEntries';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const LeftSide = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 40px 35px 40px 75px;
`;

const RightSide = styled(LeftSide)`
  padding: 40px 75px 40px 35px;
`;

const Layout = ({
  activity,
  modalVoterOpen,
  modalReferralOpen,
  openVoterModal,
  openReferralModal,
  myStats,
  statsLoading,
}) => {
  return (
    <Wrapper>
      <LeftSide>
        <HeroImages activity={activity} />
      </LeftSide>
      <RightSide>
        <SweepstakesEntries
          activity={activity}
          modalVoterOpen={modalVoterOpen}
          modalReferralOpen={modalReferralOpen}
          openVoterModal={openVoterModal}
          openReferralModal={openReferralModal}
          myStats={myStats}
          statsLoading={statsLoading}
        />
      </RightSide>
    </Wrapper>
  );
};

Layout.propTypes = {
  activity: PropTypes.object.isRequired,
  modalReferralOpen: PropTypes.bool.isRequired,
  modalVoterOpen: PropTypes.bool.isRequired,
  myStats: PropTypes.shape({
    entries: PropTypes.number,
    recruits: PropTypes.number,
  }).isRequired,
  openReferralModal: PropTypes.func.isRequired,
  openVoterModal: PropTypes.func.isRequired,
  statsLoading: PropTypes.bool.isRequired,
  team: PropTypes.object,
};

Layout.defaultProps = {};

export default Layout;
