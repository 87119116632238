import { RightOutlined, DownOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { truncate } from '../../utils/string';
import { ArrowWrapper, Row, StyledListItem, StyledMeta } from './common';

const Category = ({ name, description, logo, isExpanded, onClick }) => (
  <StyledListItem onClick={onClick} style={{ margin: '6px 0', padding: 0 }}>
    <Row>
      <div className="flex-row align-items-center">
        <StyledMeta
          avatar={
            <Avatar size="large" src={logo}>
              {name}
            </Avatar>
          }
          title={<span>{name}</span>}
          description={truncate(description, 75)}
        />
      </div>
      <ArrowWrapper>{isExpanded ? <DownOutlined /> : <RightOutlined />}</ArrowWrapper>
    </Row>
  </StyledListItem>
);

Category.propTypes = {
  description: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

Category.defaultProps = {
  isExpanded: false,
  logo: null,
};

export default Category;
