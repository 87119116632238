import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@web/components/Headcount/components/Button';

import { useTranslation } from 'react-i18next';

const StyledBackToStart = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

function BackToStart({ onClick }) {
  const { t } = useTranslation();
  return (
    <StyledBackToStart>
      <Button variant="secondary" onClick={onClick}>
        {t('check_registration.headcount.startOver')}
      </Button>
    </StyledBackToStart>
  );
}

BackToStart.propTypes = {
  onClick: PropTypes.func.isRequired,
};

BackToStart.defaultProps = {};

export default BackToStart;
