import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Container from '../Container';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <h1>
        <b>{t('404.not_found')}</b>
      </h1>
      <Link to="/frontline">{t('404.return_home')}</Link>
    </Container>
  );
};
export default NotFound;
