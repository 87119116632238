import * as React from 'react';
import PropTypes from 'prop-types';

import envelopeTealImage from './envelope-teal.png';
import envelopeYellowImage from './envelope-yellow.png';
import envelopeGreenImage from './envelope-green.png';

function getColor(color) {
  switch (color) {
    case 'teal':
      return envelopeTealImage;
    case 'green':
      return envelopeGreenImage;
    default:
      return envelopeYellowImage;
  }
}

// MTS - These exist like this for easy conversion into an svg icon which are currently
// being requested.
function EnvelopeIcon(props) {
  return <img alt="Envelope Icon" src={getColor(props.color)} {...props} />;
}

EnvelopeIcon.propTypes = {
  color: PropTypes.oneOf(['teal', 'yellow', 'green']),
};

EnvelopeIcon.defaultProps = {
  color: 'teal',
};

export default EnvelopeIcon;
