import facebookCircle from '@assets/images/sapient/facebook-circle.svg';
import instagramCircle from '@assets/images/sapient/instagram-circle.svg';
import twitterCircle from '@assets/images/sapient/twitter-circle.svg';
import React from 'react';
import styled from 'styled-components';
import { media } from '../../styles/theme';

const StyledFooter = styled.footer`
  background-color: #323644;
  flex-shrink: 0;
  padding: 0px 50px 20px;
  ${media.lg} {
    padding: 30px 100px 50px;
  }
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.lg} {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.h6`
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.black};
  font-size: 30px;
  margin-bottom: 30px;
`;

const ContactUs = styled.div`
  text-align: center;
  padding-bottom: 20px;

  ${media.lg} {
    padding-right: 100px;
    padding-bottom: 0px;
  }
`;
const HelpfulLinks = styled.div`
  text-align: center;
  ${media.lg} {
    padding-left: 100px;
  }
`;
const Copyright = styled.div`
  padding-top: 50px;
  text-align: center;

  ${media.lg} {
    padding-top: 100px;
  }
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: 18px;
  font-weight: bold;
`;

const LinkColumnContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LinkColumn = styled.div`
  padding-left: 50px;
  padding-right: 50px;
`;

const LinkContainer = styled.div`
  padding-bottom: 18px;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: 18px;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const UnderlinedLink = styled(StyledLink)`
  text-decoration: underline;
`;

const MarketingSiteFooter = () => {
  return (
    <StyledFooter className="site-footer bg-dark-gray">
      <br />
      <br />
      <Rows>
        <ContactUs className="contact column small-12 medium-6">
          <div className="contact-information">
            <div className="form-header">
              <StyledHeader className="form-title red font-black">Contact Us</StyledHeader>
            </div>
            <Address className="address">
              <Text>240 Elm Street</Text>
              <Text>Somerville, MA</Text>
              <Text>02144</Text>
              <UnderlinedLink href="mailto:support@impactive.io">
                support@impactive.io
              </UnderlinedLink>
            </Address>

            <div className="social-media" style={{ paddingTop: '20px' }}>
              <a href="https://www.facebook.com/joinoutvote/" style={{ paddingRight: '10px' }}>
                <img src={facebookCircle} alt="facebook" height={36} width={36} />
                <span className="icon-facebook"></span>
              </a>
              <a href="https://twitter.com/joinoutvote" style={{ paddingRight: '10px' }}>
                <img src={twitterCircle} alt="twitter" height={36} width={36} />
                <span className="icon-twitter"></span>
              </a>
              <a href="https://www.instagram.com/joinoutvote/">
                <img src={instagramCircle} alt="instagram" height={36} width={36} />
                <span className="icon-instagram"></span>
              </a>
            </div>
          </div>
        </ContactUs>
        <HelpfulLinks>
          <StyledHeader className="form-title red font-black">Helpful Links</StyledHeader>

          {/* <div className="contact-information">
            <div className="form-header">
              <StyledHeader className="form-title red font-black">Helpful Links</StyledHeader>
            </div>
          </div> */}
          <LinkColumnContainer>
            <LinkColumn>
              <LinkContainer className="contact column small-4">
                <StyledLink href="/">For Activists</StyledLink>
              </LinkContainer>

              <LinkContainer className="contact column small-4">
                <StyledLink href="/faqs">FAQs</StyledLink>
              </LinkContainer>

              <LinkContainer className="contact column small-4">
                <StyledLink href="/press">Press</StyledLink>
              </LinkContainer>
            </LinkColumn>

            <LinkColumn>
              <LinkContainer className="contact column small-4">
                <StyledLink href="/campaigns">For Campaigns</StyledLink>
              </LinkContainer>

              <LinkContainer className="contact column small-4">
                <StyledLink href="https://medium.com/outvote/careers/home">Jobs</StyledLink>
              </LinkContainer>

              <LinkContainer className="contact column small-4">
                <StyledLink href="/case_studies">Case Studies</StyledLink>
              </LinkContainer>
            </LinkColumn>

            <LinkColumn>
              <LinkContainer className="contact column small-4">
                <StyledLink href="/about">About</StyledLink>
              </LinkContainer>

              <LinkContainer className="contact column small-4">
                <StyledLink href="/explore">Explore</StyledLink>
              </LinkContainer>

              <LinkContainer className="contact column small-4">
                <StyledLink href="/auth">Login</StyledLink>
              </LinkContainer>
            </LinkColumn>
          </LinkColumnContainer>
        </HelpfulLinks>
      </Rows>
      <Copyright className="column small-12">
        <Text className="copyright">
          &copy; 2020{' '}
          <UnderlinedLink href="/" className="white">
            Impactive.
          </UnderlinedLink>{' '}
          Some rights reserved. See our{' '}
          <UnderlinedLink href="/outvote/terms_of_service" className="white">
            Terms of Service
          </UnderlinedLink>{' '}
          and{' '}
          <UnderlinedLink href="/outvote/privacy" className="white">
            Privacy Policy
          </UnderlinedLink>{' '}
          agreements.
        </Text>
      </Copyright>
    </StyledFooter>
  );
};

export default MarketingSiteFooter;
