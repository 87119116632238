import { message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Header, StyledButton, StyledInput } from '../Shared';

const PasswordEntry = ({ onPasswordSubmit }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const onSubmitHandler = e => {
    if (e) e.preventDefault();

    if (password.length === 0) {
      message.error(t('auth.forgot.enter_new_password'));
      return;
    }

    if (password !== passwordConfirmation) {
      message.error(t('auth.forgot.password_mismatch'));
      return;
    }

    onPasswordSubmit(password);
  };

  return (
    <Wrapper>
      <Header>{t('auth.forgot.entry_header')}</Header>
      <form data-testid="form" onSubmit={onSubmitHandler}>
        <StyledInput
          autoFocus
          type="password"
          placeholder={t('placeholders.new_password')}
          onChange={e => setPassword(e.target.value)}
          value={password}
        />
        <StyledInput
          placeholder={t('placeholders.confirm_password')}
          type="password"
          onChange={e => setPasswordConfirmation(e.target.value)}
          value={passwordConfirmation}
        />
        <StyledButton type="primary" htmlType="submit" disabled={!password}>
          {t('common.submit')}
        </StyledButton>
      </form>
    </Wrapper>
  );
};

PasswordEntry.propTypes = {
  onPasswordSubmit: PropTypes.func.isRequired,
};

export default PasswordEntry;
