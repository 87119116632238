import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { campaigns as campaignsAPI, users as usersAPI } from '@web/services/api';
import { StateContext, AuthContext } from '../utils/context';
import { useWindowVisibility } from '@web/hooks';
import { useSelector } from 'react-redux';

const UNREAD_MESSAGE_THREADS_COUNT_POLLING_INTERVAL = 120000;

const StateProvider = ({ children }) => {
  // Causes (issues) for activity filters throught the app
  const [causes, setCauses] = useState([]);
  const { user } = useContext(AuthContext);
  const { selectedCampaign } = useSelector(state => state.oneCampaign);
  const timerIntervalRef = React.useRef(null);
  const windowVisible = useWindowVisibility();
  const location = useLocation();

  // Unread messages count is used to display number next to Inbox menu item and within the Inbox
  const [unreadMessagesCount, setUnreadMessagesCount] = useState();

  useEffect(() => {
    campaignsAPI
      .getCauses()
      .then(({ data: { data: causes } }) => causes)
      .then(setCauses);
  }, []);

  /**
   * When message is read in between polling for unread counts we want to update counts manually.
   * This function allows for that to happen.
   */
  const decreaseUnreadMessagesCount = React.useCallback(() => {
    // prevent NAN when prev state is undefined
    setUnreadMessagesCount(c => Math.max((c ?? 0) - 1, 0));
  }, [setUnreadMessagesCount]);

  const getUnreadMessagesCount = React.useCallback(async campaignId => {
    return await usersAPI
      .getCurrentUnreadMessageThreadsCount(campaignId)
      .then(({ data }) => {
        return data?.data?.unread_message_threads_count;
      })
      .catch(() => {
        return 0;
      });
  }, []);

  // Poll for unread messages (once every 60 seconds)
  useEffect(() => {
    async function pollForChanges() {
      // Stop polling for messages when user is logged out.
      if (!user) {
        clearInterval(timerIntervalRef.current);
        return;
      }

      // Only refresh when the browser window tab is in focus and campaign is picked
      if (windowVisible && selectedCampaign?.id) {
        const count = await getUnreadMessagesCount(selectedCampaign?.id);
        setUnreadMessagesCount(count);
      }
    }

    // Run it initially
    pollForChanges();

    // Set interval to periodically poll for new messages
    const intervalFn = setInterval(pollForChanges, UNREAD_MESSAGE_THREADS_COUNT_POLLING_INTERVAL);
    timerIntervalRef.current = intervalFn;

    // Set cleanup fn
    return () => clearInterval(intervalFn);
  }, [
    getUnreadMessagesCount,
    setUnreadMessagesCount,
    user,
    windowVisible,
    location,
    selectedCampaign?.id,
  ]);

  return (
    <StateContext.Provider value={{ causes, decreaseUnreadMessagesCount, unreadMessagesCount }}>
      {children}
    </StateContext.Provider>
  );
};

StateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default StateProvider;
