import React from 'react';

import ImpactiveButton from '@web/components/ImpactiveButton';
import { StickyFooter } from '../shared';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 23px;
  > button {
    margin-left: 15px;
  }
`;

const FormFooter = ({ disabled, submitCount, addAnotherReport }) => {
  const { t } = useTranslation();

  return (
    <StickyFooter>
      <ButtonContainer>
        <ImpactiveButton secondary={submitCount > 0} disabled={disabled} type="submit">
          {t(
            submitCount > 0
              ? 'add_report.buttons.update_report'
              : 'add_report.buttons.send_to_campaign',
          )}
        </ImpactiveButton>
        {submitCount > 0 && (
          <ImpactiveButton onClick={addAnotherReport}>
            {t('add_report.buttons.add_another_report')}
          </ImpactiveButton>
        )}
      </ButtonContainer>
    </StickyFooter>
  );
};

FormFooter.propTypes = {
  addAnotherReport: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  submitCount: PropTypes.number.isRequired,
};

export default FormFooter;
