import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Checkbox from '../../Input/Checkbox';

import { Trans, useTranslation } from 'react-i18next';

const StyledOptIns = styled.div`
  background-color: rgba(35, 41, 62, 0.02);
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.trueBlack};
  padding: 0.6rem 1rem 1rem;
  font-size: 12px;
  margin-top: 2rem;
  a {
    color: ${({ theme }) => theme.colors.trueBlack};
    text-decoration: underline;
  }
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.trueBlack};
  font-size: 12px;
  margin: 0;
  margin-top: 4px;
`;

function OptIns({ activity, setFieldValue, team, values, isFanOut }) {
  const { t } = useTranslation();
  const linkProps = {
    rel: 'noopener noreferrer',
    target: '_blank',
  };
  const privacy = t('idvoters.optIns.privacyName');
  const termsName = t('idvoters.optIns.termsName');
  const additionalTerms = t('idvoters.optIns.additionalTerms');
  let termComponents = [
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href="https://www.impactive.io/terms-conditions" {...linkProps} />,
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href="https://www.impactive.io/privacy-policy" {...linkProps} />,
  ];

  let hasAdditionalTerms = false;
  if (team?.terms_link || activity?.terms_link || activity?.campaign?.terms_link) {
    hasAdditionalTerms = true;
    termComponents.push(
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        href={team?.terms_link || activity?.terms_link || activity?.campaign?.terms_link}
        {...linkProps}
      />,
    );
  }

  const { background_color, font_color, button_color } = activity?.settings ?? {};

  const termsText = (
    <Trans
      i18nKey={hasAdditionalTerms ? 'idvoters.optIns.termsWithAdditional' : 'idvoters.optIns.terms'}
      components={termComponents}
      values={{ additionalTerms, privacy, terms: termsName }}
    />
  );

  function getTerms() {
    if (team?.terms) {
      return team.terms;
    }
    if (activity?.terms) {
      return activity.terms;
    }
    if (activity?.campaign?.terms) {
      return activity.campaign.terms;
    }
    return null;
  }

  const terms = getTerms();

  const bgColor = React.useMemo(
    () => (isFanOut ? null : background_color),
    [background_color, isFanOut],
  );
  const fontColor = React.useMemo(() => (isFanOut ? null : font_color), [font_color, isFanOut]);
  const buttonColor = React.useMemo(
    () => (isFanOut ? null : isFanOut ? null : button_color),
    [button_color, isFanOut],
  );

  return (
    <StyledOptIns>
      <Checkbox
        bgColor={bgColor}
        fontColor={fontColor}
        buttonColor={buttonColor}
        checked={values?.terms}
        name="terms"
        onClick={() => setFieldValue('terms', !values?.terms)}
        required
      >
        {termsText}
      </Checkbox>
      {![784, 3876].includes(activity?.campaign?.id) && !isFanOut && (
        <Checkbox
          bgColor={bgColor}
          fontColor={fontColor}
          buttonColor={buttonColor}
          checked={values?.showOnLeaderboard}
          name="showOnLeaderboard"
          onClick={() => setFieldValue('showOnLeaderboard', !values?.showOnLeaderboard)}
        >
          {t('check_registration.show_on_leaderboard')}
        </Checkbox>
      )}
      <Checkbox
        bgColor={bgColor}
        fontColor={fontColor}
        buttonColor={buttonColor}
        checked={values?.smsOptIn}
        name="smsOptIn"
        onClick={() => setFieldValue('smsOptIn', !values?.smsOptIn)}
      >
        {isFanOut
          ? t('idvoters.optIns.fanOutSMS')
          : t('idvoters.optIns.sms', { campaign: activity.campaign.name })}
      </Checkbox>
      {terms && <Text>{terms}</Text>}
    </StyledOptIns>
  );
}

OptIns.propTypes = {
  activity: PropTypes.object.isRequired,
  isFanOut: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  team: PropTypes.object,
  values: PropTypes.object.isRequired,
};

OptIns.defaultProps = {};

export default OptIns;
