import * as React from 'react';
import PropTypes from 'prop-types';

function ShareImage(props) {
  return (
    <svg fill="none" viewBox="0 0 147 117" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M32.369 47.684C31.359 24.84 63.461-3.867 91.137.924c29.232 5.054 54.866 68.502 27.16 89.89-27.706 21.389-84.867-19.963-85.928-43.13Z"
        fill="#6CCBDA"
        fillOpacity=".2"
      />
      <path
        d="M71.07 108.907s-9.925-12.46-6.771-28.26c0 0 24.46 12.244 25.482 27.136l16.941.41s19.357-16.612 39.007-17.491c0 0-3.033 18.205-20.631 20.423l-54.027-2.218Z"
        fill="url(#a)"
      />
      <path
        d="M111.787 105.326s1.112-29.766 33.943-35.58c0 0 3.406 28.505-29.96 35.765l-3.983-.185Z"
        fill="url(#b)"
      />
      <path
        d="m145.718 69.746-33.943 35.58 4.984-.978s28.312-6.039 28.959-34.602Z"
        fill="#96D1DC"
        style={{ mixBlendMode: 'soft-light' }}
      />
      <path
        d="M108.25 1.345c-2.447 2.568 13.393 25.27 19.205 26.018 5.812.748 5.327-13.302 1.455-19.76-3.871-6.46-17.507-9.583-20.66-6.258Z"
        fill="#6CCBDA"
        fillOpacity=".2"
      />
      <path
        d="M21.105 79.092S1.052 69.857 0 36.389c0 0 27.019 3.136 35.661 42.752l-14.556-.05Z"
        fill="url(#c)"
      />
      <path
        d="m1.638 38.412 27.292 40.7-6.884-.733S.738 65.11 1.638 38.412Z"
        fill="#96D1DC"
        style={{ mixBlendMode: 'soft-light' }}
      />
      <path
        d="M31.314 77.02s-14-16.407-30.173-7.378c0 0 10.442 18.254 30.173 7.378Z"
        fill="url(#d)"
      />
      <path
        d="M31.314 77.02s-14-16.407-30.173-7.378c0 0 10.442 18.254 30.173 7.378Z"
        fill="url(#e)"
      />
      <path
        d="m1.142 69.643 28.909 6.459s-14.303 11.345-28.91-6.46Z"
        fill="#96D1DC"
        style={{ mixBlendMode: 'soft-light' }}
      />
      <path
        d="M125.198 107.98s0 7.752-7.359 7.752H29.768c-2.901-.293-4.943-3.871-4.943-6.297 0-.94.495-11.563 1.112-24.26 1.01-20.033 2.214-45.172 2.214-45.172s.455-3.457 5.974-3.84l42.827.444 40.887.424a6.578 6.578 0 0 1 5.519 4.266l1.84 66.683Z"
        fill="url(#f)"
      />
      <path
        d="M125.198 107.98s0 7.752-7.359 7.752H29.768c-2.901-.293-4.943-3.871-4.943-6.297 0-.94.495-11.563 1.112-24.26 1.01-20.033 2.214-45.172 2.214-45.172s.455-3.457 5.974-3.84l42.827.444 40.887.424a6.578 6.578 0 0 1 5.519 4.266l1.84 66.683Z"
        fill="url(#g)"
      />
      <path
        d="M28.666 106.817s62.145 3.488 91.69.395c0 0 2.75-33.72-1.011-65.106 0 0-46.375-3.235-86.807-1.011a524.038 524.038 0 0 0-3.872 65.722Z"
        fill="url(#h)"
      />
      <path
        d="M28.666 106.817s62.145 3.488 91.69.395c0 0 2.75-33.72-1.011-65.106 0 0-46.375-3.235-86.807-1.011a524.038 524.038 0 0 0-3.872 65.722Z"
        fill="url(#i)"
      />
      <path
        d="m28.666 106.816-.849.87s59.294 3.285 93.51 0l-1.011-.445a904.36 904.36 0 0 1-91.65-.425Z"
        fill="url(#j)"
      />
      <path
        style={{ mixBlendMode: 'soft-light' }}
        d="m29.879 75.957 44.192-35.58s-21.156-.375-41.524.687c0 0-1.779 23.794-2.668 34.893Z"
        fill="url(#k)"
      />
      <path d="M27.594 51.353H26.17v3.74l1.223.223.202-3.963Z" fill="url(#l)" />
      <path d="M27.594 51.353H26.17v3.74l1.223.223.202-3.963Z" fill="url(#m)" />
      <path d="m27.283 57.61-1.112.313-.283 2.81 1.223.384.172-3.508Z" fill="url(#n)" />
      <path
        d="M49.36 112.453c-1.525.72-3.432.556-4.825-.551L24.89 96.297c-1.79-1.421-2.077-3.989-.64-5.751 1.449-1.774 4.834-2.499 6.653-1.07l19.985 15.705c1.885 1.481 2.132 4.102.559 5.84-.388.428-1.583 1.195-2.087 1.432Z"
        fill="url(#o)"
      />
      <path
        d="M50.738 111.599c-1.06.654-3.145-.227-4.185-1.388l-14.52-15.063c-1.336-1.492-1.648-4.064-.696-5.76.96-1.709 3.346-2.29 4.704-.788l14.919 16.49c1.407 1.555 2.22 3.446 1.17 5.113-.26.41-1.043 1.18-1.392 1.396Z"
        fill="#BE4A3F"
      />
      <path
        d="M16.945 89.33a1.9 1.9 0 0 1 .225-.105l5.185-2.942 7.498 13.564-5.075 2.715c-.11.105-.234.195-.367.269-1.788.988-4.909-1.233-6.97-4.962-2.061-3.73-2.284-7.551-.496-8.54Z"
        fill="url(#p)"
      />
      <path
        d="M16.945 89.33a1.9 1.9 0 0 1 .225-.105l5.185-2.942 7.498 13.564-5.075 2.715c-.11.105-.234.195-.367.269-1.788.988-4.909-1.233-6.97-4.962-2.061-3.73-2.284-7.551-.496-8.54Z"
        fill="url(#q)"
      />
      <path
        d="M22.355 86.29c.521 5.609 3.01 10.11 7.499 13.564l45.702-8.976-20.646-38.29L22.356 86.29Z"
        fill="url(#r)"
      />
      <path
        d="M77.715 90.33c5.213-2.883 4.566-14.033-1.445-24.905-6.01-10.872-15.11-17.35-20.322-14.468-5.213 2.882-4.566 14.033 1.444 24.905 6.011 10.872 15.11 17.35 20.323 14.467Z"
        fill="url(#s)"
      />
      <path
        d="M77.343 89.67c1.107-.612 1.938-1.678 2.47-3.168.54-1.518.751-3.392.625-5.577-.267-4.592-1.984-9.962-4.837-15.123-2.853-5.161-6.487-9.47-10.234-12.139-1.783-1.269-3.484-2.089-5.055-2.437-1.545-.342-2.889-.205-3.996.406-1.107.61-1.938 1.677-2.47 3.167-.54 1.516-.75 3.393-.624 5.577.111 1.92.476 3.976 1.072 6.096.829 2.95 4.111 4.695 5.771 7.697 1.764 3.193 1.823 7.391 4.028 9.746 1.359 1.449 2.772 2.706 4.2 3.724 1.783 1.269 3.484 2.089 5.056 2.436 1.544.343 2.888.206 3.994-.405Z"
        fill="url(#t)"
      />
      <path
        d="M137.263 72.34c1.789-6.906-2.552-13.906-9.695-15.636-7.143-1.73-14.384 2.467-16.173 9.372-1.789 6.906 2.552 13.906 9.695 15.636 7.143 1.729 14.384-2.467 16.173-9.373Z"
        fill="url(#u)"
      />
      <path
        d="M136.655 71.571c1.632-6.582-2.564-13.196-9.372-14.774-6.809-1.578-13.651 2.479-15.283 9.06-1.632 6.583 2.564 13.197 9.373 14.775 6.808 1.578 13.65-2.479 15.282-9.06Z"
        fill="#23293E"
      />
      <path
        d="M122.76 75.577h2.886v-7.2h2.009l.221-2.417h-2.23v-1.381c0-.566.118-.794.691-.794h1.567v-2.513h-2.002c-2.148 0-3.114.945-3.114 2.761v1.933h-1.54v2.41h1.498l.014 7.201Z"
        fill="#fff"
      />
      <path
        d="M102.277 51.368c1.813-6.998-2.586-14.093-9.826-15.846-7.24-1.752-14.578 2.5-16.39 9.5-1.814 6.998 2.585 14.092 9.824 15.845 7.24 1.753 14.578-2.5 16.392-9.499Z"
        fill="url(#v)"
      />
      <path
        d="M101.659 50.595c1.654-6.67-2.598-13.375-9.499-14.974-6.9-1.599-13.834 2.513-15.488 9.183-1.654 6.671 2.599 13.375 9.499 14.974 6.9 1.6 13.834-2.512 15.488-9.183Z"
        fill="#6CCBDA"
      />
      <path
        d="M83.704 52.27a7.3 7.3 0 0 0 11.22-6.478c.496-.36.925-.804 1.268-1.312-.47.207-.967.343-1.476.407a2.58 2.58 0 0 0 1.142-1.4 5.02 5.02 0 0 1-1.647.62 2.57 2.57 0 0 0-4.392 2.344 7.296 7.296 0 0 1-5.286-2.684 2.574 2.574 0 0 0 .796 3.425 2.525 2.525 0 0 1-1.164-.318 2.57 2.57 0 0 0 2.059 2.547 2.564 2.564 0 0 1-1.159.044 2.568 2.568 0 0 0 2.394 1.778 5.154 5.154 0 0 1-3.755 1.027Z"
        fill="#fff"
      />
      <path
        d="M142.631 26.63c2.057-7.94-2.933-15.988-11.147-17.977-8.213-1.988-16.539 2.837-18.596 10.777-2.056 7.94 2.934 15.989 11.148 17.977 8.213 1.989 16.538-2.836 18.595-10.776Z"
        fill="url(#w)"
      />
      <path
        d="M141.933 25.753c1.877-7.568-2.948-15.174-10.776-16.988-7.829-1.814-15.696 2.85-17.573 10.419-1.876 7.568 2.949 15.173 10.777 16.988 7.828 1.814 15.696-2.85 17.572-10.419Z"
        fill="#F3786C"
      />
      <path
        d="M124.369 19.81h-2.559v8.271h2.559v-8.27ZM123.074 18.734a1.524 1.524 0 0 0 1.421-.933 1.528 1.528 0 1 0-1.421.933ZM128.463 23.747c0-1.162.535-1.855 1.56-1.855.938 0 1.393.664 1.393 1.855v4.332h2.545v-5.227c0-2.217-1.252-3.285-3.007-3.285a2.892 2.892 0 0 0-2.491 1.364V19.82h-2.458v8.267h2.458v-4.339Z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="a"
          x1="84.716"
          y1="82.551"
          x2="126.947"
          y2="116.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23293E" />
          <stop offset="1" stopColor="#96D1DC" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="120.515"
          y1="71.981"
          x2="147.398"
          y2="79.709"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23293E" />
          <stop offset="1" stopColor="#96D1DC" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="34.792"
          y1="72.579"
          x2="-4.079"
          y2="75.635"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23293E" />
          <stop offset="1" stopColor="#96D1DC" />
        </linearGradient>
        <linearGradient
          id="e"
          x1="26.705"
          y1="75.999"
          x2="-3.31"
          y2="73.238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23293E" />
          <stop offset="1" stopColor="#96D1DC" />
        </linearGradient>
        <linearGradient
          id="f"
          x1="75.011"
          y1="115.732"
          x2="75.011"
          y2="36.192"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C257B" />
          <stop offset="1" stopColor="#482C83" />
        </linearGradient>
        <linearGradient
          id="g"
          x1="133.525"
          y1="137.741"
          x2="82.064"
          y2="26.988"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#101A3F" />
          <stop offset="1" stopColor="#273460" />
        </linearGradient>
        <linearGradient
          id="h"
          x1="74.991"
          y1="108.495"
          x2="74.991"
          y2="40.307"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFDFF" />
          <stop offset="1" stopColor="#DBCAFF" />
        </linearGradient>
        <linearGradient
          id="i"
          x1="29.92"
          y1="82.312"
          x2="116.101"
          y2="54.364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9F5FF" />
          <stop offset="1" stopColor="#E6FCFF" />
        </linearGradient>
        <linearGradient
          id="j"
          x1="74.567"
          y1="109.131"
          x2="74.567"
          y2="106.816"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#447184" />
          <stop offset="1" stopColor="#365267" />
        </linearGradient>
        <linearGradient
          id="k"
          x1="51.975"
          y1="75.957"
          x2="51.975"
          y2="40.306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9ACF4" />
          <stop offset="1" stopColor="#E1D1F5" />
        </linearGradient>
        <linearGradient
          id="l"
          x1="25.775"
          y1="55.089"
          x2="24.698"
          y2="52.202"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23293F" />
          <stop offset="1" stopColor="#6DCCDB" />
        </linearGradient>
        <linearGradient
          id="m"
          x1="25.54"
          y1="54.215"
          x2="28.561"
          y2="48.749"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23293F" />
          <stop offset="1" stopColor="#6DCCDB" />
        </linearGradient>
        <linearGradient
          id="n"
          x1="25.272"
          y1="60.142"
          x2="27.799"
          y2="55.088"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23293F" />
          <stop offset="1" stopColor="#6DCCDB" />
        </linearGradient>
        <linearGradient
          id="o"
          x1="39.821"
          y1="114.031"
          x2="36"
          y2="87.57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D1007" />
          <stop offset="1" stopColor="#F3786C" />
        </linearGradient>
        <linearGradient
          id="p"
          x1="23.77"
          y1="103.044"
          x2="22.71"
          y2="82.511"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D1007" />
          <stop offset="1" stopColor="#F3786C" />
        </linearGradient>
        <linearGradient
          id="q"
          x1="20.83"
          y1="104.532"
          x2="26.651"
          y2="88.058"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D1007" />
          <stop offset="0" stopColor="#973026" />
          <stop offset="1" stopColor="#E86154" />
        </linearGradient>
        <linearGradient
          id="r"
          x1="38.103"
          y1="101.399"
          x2="46.747"
          y2="54.065"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D1007" />
          <stop offset="0" stopColor="#973026" />
          <stop offset="1" stopColor="#E86154" />
        </linearGradient>
        <linearGradient
          id="s"
          x1="49.503"
          y1="64.073"
          x2="84.146"
          y2="77.21"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F79C8F" />
          <stop offset="1" stopColor="#CF574F" />
        </linearGradient>
        <linearGradient
          id="t"
          x1="60.913"
          y1="76.645"
          x2="77.934"
          y2="65.393"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D1007" />
          <stop offset="1" stopColor="#F3786C" />
        </linearGradient>
        <linearGradient
          id="u"
          x1="120.478"
          y1="82.837"
          x2="127.409"
          y2="56.652"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".001" stopColor="#181B27" />
          <stop offset="1" stopColor="#23293E" />
        </linearGradient>
        <linearGradient
          id="v"
          x1="82.725"
          y1="60.058"
          x2="94.803"
          y2="36.611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65B5C1" />
          <stop offset="1" stopColor="#6CCBDA" />
        </linearGradient>
        <linearGradient
          id="w"
          x1="123.332"
          y1="38.701"
          x2="133.958"
          y2="8.594"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C26960" />
          <stop offset="1" stopColor="#F3786C" />
        </linearGradient>
        <radialGradient
          id="d"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(11.7758 0 0 11.3842 12.048 68.587)"
        >
          <stop stopColor="#3996F7" />
          <stop offset="1" stopColor="#6840D5" />
        </radialGradient>
      </defs>
    </svg>
  );
}

ShareImage.propTypes = {
  height: PropTypes.number,
};

ShareImage.defaultProps = {
  height: 117,
};

export default ShareImage;
