import React from 'react';

import { List } from 'antd';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const { Item } = List;

const StyledItem = styled(Item)`
  &&& {
    outline-offset: -2px;
    outline: solid
      ${({ theme, $selected }) =>
        $selected ? `3px ${theme.colors.teal}` : `1px ${theme.colors.borderGray}`} !important;
    padding: 20px 24px;
    margin: 12px 0;
    border-radius: ${({ $selected }) => ($selected ? 0 : '5px')};
    cursor: ${({ $preview }) => ($preview ? 'unset' : 'pointer')};

    &:hover {
      ${({ theme, $preview }) =>
        !$preview &&
        css`
          outline: solid 3px ${theme.colors.teal} !important;
        `}
    }
  }
`;

const ItemContents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Info = styled.div`
  display: flex;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  min-width: 120px;
  max-width: 200px;
  margin-right: 30px;
`;
const Location = styled.div`
  min-width: 120px;
  max-width: 200px;
  margin-right: 10px;
`;
const Age = styled.div`
  min-width: 120px;
  max-width: 200px;
  margin-right: 10px;
  color: ${({ theme }) => theme.fonts.gray};
`;

const ViewFile = styled.button`
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.semibolditalic};
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.colors.teal};
  }
`;

const VotersListItem = ({ voter, selectedVoterId, selectVoter, showVoterModal }) => {
  const { t } = useTranslation();
  const {
    'vb.tsmart_city': city,
    'vb.tsmart_first_name': firstName,
    'vb.tsmart_last_name': lastName,
    'vb.tsmart_state': state,
    'vb.voterbase_age': age,
    voterbase_id: voterId,
  } = voter;

  const showVoterHistoryModal = e => {
    e.stopPropagation();
    showVoterModal(voter);
  };

  return (
    <StyledItem
      $selected={selectedVoterId === voterId}
      $preview={!selectVoter}
      onClick={() => selectVoter && selectVoter(voter)}
    >
      <ItemContents>
        <Info>
          <Name>{`${firstName} ${lastName}`}</Name>
          <Location>{`${city}, ${state}`}</Location>
          {!!age && <Age>{t('idvoters.results.years_old', { age })}</Age>}
        </Info>
      </ItemContents>
      <ViewFile onClick={showVoterHistoryModal}>
        {t('add_report.find_voter.voting_history')}
      </ViewFile>
    </StyledItem>
  );
};

VotersListItem.propTypes = {
  selectedVoterId: PropTypes.string,
  selectVoter: PropTypes.func,
  showVoterModal: PropTypes.func.isRequired,
  voter: PropTypes.shape({
    'vb.tsmart_city': PropTypes.string,
    'vb.tsmart_first_name': PropTypes.string,
    'vb.tsmart_last_name': PropTypes.string,
    'vb.tsmart_state': PropTypes.string,
    'vb.voterbase_age': PropTypes.string,
    voterbase_id: PropTypes.string,
  }).isRequired,
};

VotersListItem.defaultProps = {
  selectVoter: null,
};

export default VotersListItem;
