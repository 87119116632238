import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@web/styles/theme';
import IconCircleContainer from '@web/components/common/Icon/IconCircleContainer';
import pxToRem from '@web/utils/pxToRem';

const { colors } = theme;

const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const HorizontalFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Line = styled.div`
  height: 36px;
  width: 19px;
  border-right: 1px dashed ${colors.dimGray3};
`;

const StyledText = styled.div`
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(20)};

  color: ${({ color }) => color};
`;

const VerticalProgressPoints = ({ items, activeIndex }) => (
  <VerticalFlex>
    {items?.map((item, index) => {
      const activeColor = activeIndex === index ? colors.teal : colors.dimGray3;
      return (
        <div key={`progress-item-${index}`}>
          {index !== 0 && <Line />}
          <HorizontalFlex>
            <IconCircleContainer color={activeColor}>
              {activeIndex <= index ? item.icon : '✔️'}
            </IconCircleContainer>
            <StyledText color={activeColor}>{item.text}</StyledText>
          </HorizontalFlex>
        </div>
      );
    })}
  </VerticalFlex>
);

VerticalProgressPoints.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      text: PropTypes.node,
    }),
  ).isRequired,
};

export default VerticalProgressPoints;
