import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { bool, object } from 'prop-types';

import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import ImpactiveButton from '@web/components/ImpactiveButton';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';

const StyledHref = styled.a`
  :link {
    text-decoration: none;
  }
`;

const StyledImpactiveButton = styled(ImpactiveButton)`
  background: ${({ theme }) => theme.colors.transparent};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1rem;
  letter-spacing: 0.03125rem;
  padding: ${({ $isFooter }) => ($isFooter ? '4.5px 13px' : '2px 13px')};
  padding: ${({ $isDrawer }) => $isDrawer && '9px'};
  width: ${({ $isDrawer }) => $isDrawer && '100%'};
  border-radius: 8px;

  &&& {
    color: ${({ theme, $isFooter }) => ($isFooter ? theme.colors.white : theme.colors.blue)};
    width: ${({ $isFooter }) => $isFooter && '148px'};
    ${smallBreakpoint} {
      width: ${({ $isFooter }) => $isFooter && 'calc(100vw - 40px)'};
    }
  }
`;

const DonateButton = ({ $isFooter, $isDrawer, activity, team }) => {
  const { t } = useTranslation();

  return (
    <StyledHref target="_blank" href="https://fanout.vote/donate" rel="noopener noreferrer">
      <StyledImpactiveButton
        $isFooter={$isFooter}
        $isDrawer={$isDrawer}
        secondary
        onClick={() => amplitudeTrack({ activity, name: 'FAN_OUT_OPEN_CLICK_DONATE', team })}
      >
        {t('fan_out.header.donate')}
      </StyledImpactiveButton>
    </StyledHref>
  );
};

DonateButton.propTypes = {
  $isDrawer: bool,
  $isFooter: bool,
  activity: object,
  team: object,
};

DonateButton.defaultProps = {
  $isDrawer: false,
  $isFooter: false,
};

export default DonateButton;
