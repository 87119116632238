import React from 'react';
import PropTypes from 'prop-types';
import QuestionCard from './QuestionCard';

function ActivityQuestions({ activity, questions, handleChange }) {
  return questions.map((question, idx) => (
    <QuestionCard
      activity={activity}
      key={question.id}
      index={idx}
      question={question}
      handleChange={handleChange}
    />
  ));
}

ActivityQuestions.propTypes = {
  activity: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      answer_mappings: PropTypes.object,
      id: PropTypes.number,
      position: PropTypes.number,
      question: PropTypes.string,
      question_type: PropTypes.string,
      required: PropTypes.bool,
    }),
  ).isRequired,
};

export default ActivityQuestions;
