import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Field, Formik } from 'formik';
import { Form, FormItem } from 'formik-antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router-dom';
import { Spin, message } from 'antd';

import { campaigns, optInForms } from 'web/services/api';
import { colors } from 'web/styles/theme';
import i18n from 'web/components/i18n';
import { isMobileOnly } from 'react-device-detect';
import sendError from '@web/utils/sendError';
import { track } from 'web/services/analytics';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  Disclaimer,
  DisclaimerLink,
  InitialContainer,
  OptInForm,
  OptInImg,
  OptInButton,
  Subtitle,
  Title,
  ThankYouContainer,
} from './styles';

import { FacebookShare, TwitterShare, ClipboardShare } from './components/shareButtons';

const ScreenTypes = {
  COLLECT_PHONE: 'collect_phone',
  INITIAL: 'initial',
  THANK_YOU: 'thank_you',
};

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const schema = yup.object().shape({
  phone: yup.string().matches(phoneRegExp, i18n.t('check_registration.invalid_phone')),
});

const OptInFormWeb = props => {
  const { campaignId, formId } = props.match.params; // can be either campaign `id` or `slug`
  const { t } = useTranslation();
  const params = new URLSearchParams(props.location.search);
  // MTS - This is used to let us know if a contact shared this opt-in.
  const trackedLinkKey = params.get('tlkey');

  const [campaign, setCampaign] = useState({});
  const [form, setForm] = useState({});

  const [screenType, setScreenType] = useState(ScreenTypes.INITIAL);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    track(`VIEW_OPT_IN`);
  }, []);

  useEffect(() => {
    const search = trackedLinkKey ? `?tracked_link_key=${trackedLinkKey}` : '';
    setLoading(true);
    campaigns
      .getPublicCampaign({ id: campaignId })
      .then(({ data: { data: campaign } }) => {
        setCampaign(campaign);
      })
      .then(() => optInForms.getFormPublic({ campaign_id: campaignId, form_id: formId, search }))
      .then(res => setForm(res?.data?.data))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [campaignId, formId, trackedLinkKey]);

  const handleSubmitPhone = useCallback(
    (formData, { setSubmitting }) => {
      const { phone } = formData;
      if (!phone) {
        message.error('Phone required.');
        setSubmitting(false);
        return;
      }
      const payload = { phone };
      const url = `/api/v2/campaigns/${campaignId}/opt_in_forms/${formId}/add_contact`;
      if (trackedLinkKey) {
        payload.tracked_link_key = trackedLinkKey;
      }
      return axios
        .post(url, payload)
        .then(() => setScreenType(ScreenTypes.THANK_YOU))
        .catch(error => {
          setSubmitting(false);
          message.error('An error occurred.');
          sendError('An error occurred while joining an opt in', error);
        });
    },
    [campaignId, formId, trackedLinkKey],
  );

  if (loading) {
    return (
      <div className="flex-col justify-content-center align-items-center mt-4">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  const shouldRedirect = campaign && campaign.broadcast_disabled;
  if (shouldRedirect) {
    return <Redirect to={`/campaigns/${campaignId}`} />;
  }

  const numberForLink = campaign.broadcast_number;
  const encodedMessage = form.text_to_join_message.replaceAll(' ', '%20');
  const smsLink = `sms://${numberForLink};?&body=${encodedMessage}`;

  const disclaimerText = t('ga_runoff.junk_text', { campaign: campaign.name });

  return (
    <OptInForm
      style={{
        backgroundColor: screenType === ScreenTypes.THANK_YOU ? colors.darkBlue : colors.whiteSmoke,
      }}
    >
      {screenType === ScreenTypes.INITIAL && (
        <InitialContainer style={{ padding: form.media_url ? '2.5rem' : '2.5rem 3rem' }}>
          {form.media_url && <OptInImg src={form.media_url} alt="Media for opt-in" />}
          <Title>{form.title}</Title>
          <Subtitle>{form.subtitle}</Subtitle>

          <div
            className="flex-col justify-content-center align-items-center mt-4"
            style={{ width: '100%' }}
          >
            {isMobileOnly && (
              <a
                href={smsLink}
                onClick={() => setScreenType(ScreenTypes.THANK_YOU)}
                style={{ width: '100%' }}
              >
                <OptInButton
                  style={{ backgroundColor: form.accent_color }}
                  size="xlarge"
                  type="primary"
                  children={form.button_text}
                  block
                />
              </a>
            )}

            {!isMobileOnly && (
              <OptInButton
                style={{ backgroundColor: form.accent_color }}
                type="primary"
                children={form.button_text}
                size="xlarge"
                onClick={() => {
                  setScreenType(ScreenTypes.COLLECT_PHONE);
                }}
                block
              />
            )}
          </div>
          <Disclaimer>{disclaimerText}</Disclaimer>
          {!!campaign && (
            <div>
              <DisclaimerLink
                target="_blank"
                href={campaign.terms_link || 'https://www.impactive.io/terms-conditions'}
              >
                {'Terms of Service'}
              </DisclaimerLink>
              <DisclaimerLink
                target="_blank"
                href={campaign.privacy_link || 'https://www.impactive.io/privacy-policy'}
              >
                {'Privacy Policy'}
              </DisclaimerLink>
            </div>
          )}
        </InitialContainer>
      )}

      {screenType === ScreenTypes.COLLECT_PHONE && (
        <div>
          <Title>{form.title}</Title>
          <Subtitle>{form.subtitle}</Subtitle>
          <Formik
            initialValues={{ phone: '' }}
            validationSchema={schema}
            onSubmit={handleSubmitPhone}
            render={({ isSubmitting, isValid, submitCount }) => (
              <Form>
                <FormItem name="phone">
                  <label htmlFor="phone">{'Enter Phone Number'}</label>
                  <Field id="phone" name="phone" className="outvote-input" />
                </FormItem>

                <OptInButton
                  type="submit"
                  disabled={isSubmitting || (!isValid && submitCount > 0)}
                  style={{ backgroundColor: form.accent_color }}
                  size="xlarge"
                  block
                >
                  {isSubmitting ? t('ga_runoff.submitting') : t('ga_runoff.submit')}
                </OptInButton>

                <Disclaimer>{disclaimerText}</Disclaimer>
                {!!campaign && (
                  <div>
                    <DisclaimerLink
                      target="_blank"
                      href={campaign.terms_link || 'https://www.impactive.io/terms-conditions'}
                    >
                      {'Terms of Service'}
                    </DisclaimerLink>
                    <DisclaimerLink
                      target="_blank"
                      href={campaign.privacy_link || 'https://www.impactive.io/privacy-policy'}
                    >
                      {'Privacy Policy'}
                    </DisclaimerLink>
                  </div>
                )}
              </Form>
            )}
          />
        </div>
      )}

      {screenType === ScreenTypes.THANK_YOU && (
        <ThankYouContainer style={{ flex: 1 }}>
          <Title>Thanks!</Title>
          <Subtitle>Share with your friends to help make an impact.</Subtitle>

          {form.tracked_link_url && (
            <div className="flex-col justify-content-center align-items-center mt-4">
              <FacebookShare url={form.tracked_link_url}>
                {t('voting_information.share.facebook')}
              </FacebookShare>
              <TwitterShare url={form.tracked_link_url}>
                {t('voting_information.share.twitter')}
              </TwitterShare>
              <ClipboardShare text={form.tracked_link_url}>
                {t('voting_information.share.copy_share_link')}
              </ClipboardShare>
            </div>
          )}
        </ThankYouContainer>
      )}
    </OptInForm>
  );
};

OptInFormWeb.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string.isRequired,
      formId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default OptInFormWeb;
