import React from 'react';

const Graph = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="156"
      height="64"
      viewBox="0 0 156 64"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_468_7433)">
        <path
          d="M-3.18544 56.0967L-4 56.2504V57.0794V83V84H-3H157.5H158.5V83V12V10.8839L157.391 11.006L137.571 13.1878C116.74 15.4808 96.5978 22.0032 78.3773 32.3552C63.8074 40.6332 47.9683 46.4436 31.5016 49.551L-3.18544 56.0967Z"
          stroke="url(#paint0_linear_468_7433)"
          strokeWidth="2"
        />
        <path
          d="M31.7451 50.0336L-3 56.507V91H157.5V12L137.58 14.165C116.947 16.4074 96.9798 22.7974 78.8786 32.9511C64.206 41.1816 48.2839 46.9522 31.7451 50.0336Z"
          fill="url(#paint1_linear_468_7433)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_468_7433"
          x1="77.25"
          y1="12"
          x2="77.25"
          y2="76.2381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD54F" />
          <stop offset="1" stopColor="#FFD54F" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_468_7433"
          x1="86.459"
          y1="13.1127"
          x2="89.9867"
          y2="72.6189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4AB42" />
          <stop offset="0.920156" stopColor="#F8E8BF" stopOpacity="0.34" />
          <stop offset="1" stopColor="#F8E8BF" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_468_7433">
          <rect width="156" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Graph.propTypes = {};

Graph.defaultProps = {};

export default Graph;
