import * as React from 'react';
import PropTypes from 'prop-types';

function Success({ size, ...rest }) {
  return (
    <svg height={size} viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="39" cy="39" r="39" fill="#D5ECEF" />
      <g clipPath="url(#clip0_652:3117)">
        <path
          d="M54.4236 39C54.4236 47.5178 47.5177 54.4238 38.9999 54.4238C30.4821 54.4238 23.5762 47.5178 23.5762 39C23.5762 30.4823 30.4821 23.5763 38.9999 23.5763C47.5177 23.5763 54.4236 30.4823 54.4236 39Z"
          fill="#8CC4CA"
        />
        <path
          d="M54.374 23.8637L35.2661 43.692L38.403 46.715L57.5109 26.8867L54.374 23.8637Z"
          fill="#8CC4CA"
        />
        <path
          d="M50.102 28.2937L35.2659 43.6935L38.4023 46.7158L52.6885 31.8874C52.0006 30.5664 51.1269 29.3564 50.102 28.2937Z"
          fill="#4D9DA6"
        />
        <path
          d="M32.0391 34.5795L29.0232 37.7231L38.4041 46.7229L41.42 43.5792L32.0391 34.5795Z"
          fill="#4D9DA6"
        />
      </g>
      <defs>
        <clipPath id="clip0_652:3117">
          <rect
            width="37.0169"
            height="37.0169"
            fill="white"
            transform="translate(20.4915 20.4916)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

Success.propTypes = {
  size: PropTypes.number,
};

Success.defaultProps = {
  size: 78,
};

export default Success;
