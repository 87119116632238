import React from 'react';

import Loading from './Loading';
import NoCampaign from '@web/components/common/NoCampaign';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Display the loader while processing data for initial one campaign to prevent errors
const OneCampaignLoading = ({ children, centered, ignoreNullCampaign }) => {
  const { selectedCampaign, loading } = useSelector(state => state.oneCampaign);

  if (loading) return <Loading centered={centered} />;

  if (ignoreNullCampaign) return <>{children}</>;

  if (selectedCampaign) return children;

  return <NoCampaign withSkeletons />;
};

OneCampaignLoading.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.node.isRequired,
  ignoreNullCampaign: PropTypes.bool,
};

OneCampaignLoading.defaultProps = {
  centered: true,
  ignoreNullCampaign: false,
};

export default OneCampaignLoading;
