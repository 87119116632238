import * as React from 'react';
import axios from 'axios';
import sendError from '@web/utils/sendError';
import { useDispatch, useSelector } from 'react-redux';
import { machineTransition } from '@web/reducers/dialerReducer';
import { CALL_SUCCESSFUL, CALL_UNSUCCESSFUL } from '@web/reducers/dialerReducer/dialerMachine';

function useCallOutcomeQuestions(campaignId) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [answers, setAnswers] = React.useState([]);
  const callId = useSelector(state => state.dialer.voter_call_id);

  React.useEffect(() => {
    setLoading(true);
    const url = `/api/v2/campaigns/${campaignId}/voice_call_outcome_options`;
    axios(url)
      .then(res => {
        setAnswers(res.data);
      })
      .catch(err => {
        sendError('Error fetching call outcome questions', err, { campaignId });
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  function handleSubmit(answer) {
    setLoading(true);
    const url = `/api/v2/voter_voice_calls/${callId}/voice_call_outcome`;
    const showSurvey = !!answer?.survey;
    if (!callId) {
      sendError('Volunteer Web - Dialer - submitting call outcome without callId');
      return nextScreen(showSurvey);
    }
    axios
      .patch(url, { reference: answer.reference })
      .then(res => {
        // we don't need to do anything here
      })
      .catch(err => {
        sendError('Error submitting voice call outcome', err, { campaignId });
      })
      .finally(() => {
        nextScreen(showSurvey);
        setLoading(false);
      });
  }

  function nextScreen(showSurvey) {
    if (showSurvey) {
      dispatch(machineTransition(CALL_SUCCESSFUL));
    } else {
      dispatch(machineTransition(CALL_UNSUCCESSFUL));
    }
  }

  return { answers, handleSubmit, loading };
}

export default useCallOutcomeQuestions;
