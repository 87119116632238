import React from 'react';
import PropTypes from 'prop-types';

import { Switch } from 'antd';

import styled from 'styled-components';

const Tagging = styled.div`
  display: flex;
  align-items: center;
`;

const SectionTitle = styled.div`
  font-family: ${({ theme, regular }) => (regular ? theme.fonts.regular : theme.fonts.semibold)};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
`;

const Divider = styled.div`
  height: 1px;
  background: rgba(196, 196, 196, 0.3);
  margin: 16px 0px;
`;

const SwitchesWrapper = styled.div``;

const TaggingsList = ({ taggings, initialUserReport, setUserReportTaggings }) => {
  const onSwitchToggle = (selectedSlug, selectionChange) => {
    const updatedTags = taggings.map((tag, index) => {
      if (tag.slug === selectedSlug) {
        // If the user hasn't submitted this tag before, the initial value obtained from backed is null,
        // so we also need to set it to null if this field was changed to true but returned to false before submitting
        // because we need to disable the button
        if (initialUserReport.taggings[index].value === null && selectionChange === false) {
          return {
            ...tag,
            value: null,
          };
        }
        return {
          ...tag,
          value: selectionChange,
        };
      }

      // otherwise leave unchanged
      return tag;
    });
    setUserReportTaggings(updatedTags);
  };

  return (
    <>
      <SwitchesWrapper className="py-3">
        {taggings.map(({ name, value, slug, prompt }) => (
          <Tagging className="py-2" key={slug}>
            <Switch slug={slug} checked={value} onChange={(to, e) => onSwitchToggle(slug, to, e)} />
            <SectionTitle regular={!value} className="pl-3">
              {prompt || name}
            </SectionTitle>
          </Tagging>
        ))}
      </SwitchesWrapper>
      <Divider />
    </>
  );
};

TaggingsList.propTypes = {
  initialUserReport: PropTypes.any.isRequired,
  setUserReportTaggings: PropTypes.func.isRequired,
  taggings: PropTypes.array.isRequired,
};

export default TaggingsList;
