import * as React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

// Pages
import InCall from './pages/InCall';
import LandingPage from './pages/LandingPage';
import NotFound from '@web/components/404/NotFound';
import PreviewScript from './pages/PreviewScript';
import QueueCalls from './pages/QueueCalls';

// Components
import AppContainer from '@web/components/AppContainer';
import Loading from '@web/components/common/Loading';
import ScrollToTop from './components/ScrollToTop';

import useFetchActivity from './useFetchActivity';

function DialerActivity(props) {
  let { userActivityId } = useParams();
  const { activity, loading } = useFetchActivity(userActivityId);
  let { path } = useRouteMatch();

  if (loading || !activity) {
    return (
      <AppContainer>
        <ScrollToTop />
        {loading && <Loading centered />}
      </AppContainer>
    );
  }

  return (
    <AppContainer>
      {/* MTS - This is needed to reset scroll position between route transitions */}
      <ScrollToTop />

      {/* This is a nested router for all routes under /activities/:id/phone-bank */}
      <Switch>
        <Route
          component={props => <LandingPage activity={activity} {...props} />}
          exact
          path={`${path}`}
        />
        <Route
          component={props => <QueueCalls activity={activity} {...props} />}
          exact
          path={`${path}/queue-calls`}
        />
        <Route
          component={props => <PreviewScript activity={activity} {...props} />}
          exact
          path={`${path}/preview-script`}
        />
        <Route
          component={props => <InCall activity={activity} {...props} />}
          path={`${path}/in-call`}
        />
        <Route component={NotFound} />
      </Switch>
    </AppContainer>
  );
}

DialerActivity.propTypes = {
  history: PropTypes.any.isRequired,
};

DialerActivity.defaultProps = {};

export default DialerActivity;
