import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ActivityCTA from './ActivityCTA';
import ActivityGoals from './ActivityGoals';
import BoxLogo from './BoxLogo';
import CampaignProfileImage from './CampaignProfileImage';
import { Description, Title } from './TextStyles';
import MainContainer from './MainContainer';

const Top = styled.div`
  flex: 1;
  margin-bottom: 20px;
`;

const StyledMainContainer = styled(MainContainer)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-bottom: 60px;
  padding-bottom: 44px;
`;

const StyledActivityGoals = styled(ActivityGoals)`
  margin-top: 24px;
  margin-bottom: 30px;
`;

function MobileHero({ activity, campaign, goals, handleGetReferral, handleGetStarted, team }) {
  return (
    <>
      <StyledMainContainer backgroundImage={team.mobile_hero_img_url}>
        <Top>
          <BoxLogo />
        </Top>
        <CampaignProfileImage
          alt="campaign-profile"
          $isMobile
          size={140}
          src={team.profile_img_url}
        />
        {team?.name && <Description>{team.name}</Description>}
        <Title>{team.action_page_title}</Title>
        <StyledActivityGoals activity={activity} campaign={campaign} goals={goals} isMobile />
      </StyledMainContainer>

      <ActivityCTA
        activity={activity}
        campaign={campaign}
        handleGetStarted={handleGetStarted}
        handleGetReferral={handleGetReferral}
      />
    </>
  );
}

MobileHero.propTypes = {
  activity: PropTypes.shape({
    description: PropTypes.string.isRequired,
    mobile_hero_img_url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  campaign: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string.isRequired,
    url_profile_img: PropTypes.string.isRequired,
  }).isRequired,
  goals: PropTypes.object.isRequired,
  handleGetReferral: PropTypes.func.isRequired,
  handleGetStarted: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
};

MobileHero.defaultProps = {};

export default MobileHero;
