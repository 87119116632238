import { Radio, Space } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledErrorMessage } from '@web/components/common/shared/FormInput';
import theme from '@web/styles/theme';

const StyledRadio = styled(Radio)`
  &&& {
    white-space: normal;
    color: ${({ $color }) => $color ?? theme.formDefaultColors.fontColor};
    .ant-radio-inner {
      border-color: ${({ $outlineColor }) =>
        $outlineColor ?? theme.formDefaultColors.fieldOutlineColor};
      background: transparent;
      :after {
        background-color: ${({ $outlineColor }) =>
          $outlineColor ?? theme.formDefaultColors.fieldOutlineColor};
      }
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: ${({ $outlineColor }) =>
        $outlineColor ?? theme.formDefaultColors.fieldOutlineColor} !important;
      background: ${({ $backgroundColor }) =>
        $backgroundColor ?? theme.formDefaultColors.fieldBackgroundColor} !important;
    }
  }
`;

export function RadioGroupFormPartial({ activity, handleChange, name, options }) {
  const answers = useMemo(
    () => Object.entries(options).sort((a, b) => a[1].position - b[1].position),
    [options],
  );

  const { form_field_outline_color, form_field_background_color, main_font_color } =
    activity.settings ?? {};

  return (
    <div>
      <Radio.Group name={name} onChange={handleChange}>
        <Space direction="vertical">
          {answers.map(([key, value]) => (
            <StyledRadio
              $outlineColor={form_field_outline_color}
              $backgroundColor={form_field_background_color}
              $color={main_font_color}
              key={key}
              value={key}
            >
              {value.value}
            </StyledRadio>
          ))}
        </Space>
      </Radio.Group>
      <StyledErrorMessage name={name} />
    </div>
  );
}

RadioGroupFormPartial.propTypes = {
  activity: PropTypes.shape({
    settings: PropTypes.shape({
      form_field_background_color: PropTypes.string,
      form_field_outline_color: PropTypes.string,
      main_font_color: PropTypes.string,
    }),
  }),
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.object,
};
