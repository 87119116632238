import { Button, Progress } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { track } from '../../services/analytics';
import { user } from '../../services/api';
import { getButtonText, verbByType, mobileButtonTextToWeb } from '../../utils/other';
import { dateToAge, truncate } from '../../utils/string';
import ActivityCompletedModal from '../ActivityDetail/ActivityCompletedModal';
import FormActivity from '../ActivityDetail/FormActivity';
import ShareActivity from '../ActivityDetail/ShareActivity';
import WebActivity from '../ActivityDetail/WebActivity';

const ActionButtons = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  flex-direction: row;
  padding-top: 1em;
  padding-bottom: 25px;
`;

const StyledButton = styled(Button)`
  &&& {
    font-family: ${({ theme }) => theme.fonts.boldItalic};
    font-size: 16px;
    height: 44px;
    letter-spacing: 1.4px;
    :hover {
      text-decoration-color: ${({ theme }) => theme.colors.white};
    }
    ${({ $completed, disabled }) =>
      $completed &&
      !disabled &&
      `
      background-color: #949494;
      border-color: #949494;

      &:hover {
        background-color: #a9a9a9;
        border-color: #a9a9a9;
      }

      &:active {
        background-color: #808080;
        border-color: #808080;
      }
    `}
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 14px;
`;

const Header = styled.div``;

const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 10px;
`;

const HeaderDate = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 14px;
  letter-spacing: 0;
  opacity: 0.65;
  padding-bottom: 17px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyTitle = styled.div`
  color: ${({ theme }) => theme.colors.lightBlack};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 30px;
  letter-spacing: 1px;
  padding-bottom: 5px;
`;

const Body = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const MediaWrapper = styled.div`
  max-width: 100%;
  width: 500px;
  height: 0;
  padding-bottom: 100%;
  position: relative;
`;

const Media = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
`;

const StyledActivity = styled.div`
  height: 100%;
  width: 100%;
  max-width: 560px;
  padding: 30px;
`;

const StyledProgressBar = styled(Progress)`
  width: 100%;
  margin: 1em 0;
`;

/**
 *
 * @param {bool} isFeatured - Activity pinned view
 * @param {bool} detailedView - Activity detailed view
 * @param {String} headerTitle
 * @param {object} userActivity
 * @param {bool} completed
 * @param {Activity} activity
 * @param {function} onActivityActionButtonPress - Callback function to execute when CTA was clicked
 */
const Activity = ({
  isFeatured,
  detailedView,
  headerTitle,
  userActivity,
  completed: initialCompleted,
  activity: {
    id: activityId,
    type: activityType,
    aasm_state,
    button_text,
    published_at,
    title: activityTitle,
    description,
    go_to_link,
    share_link,
    share_text,
    media_url: url,
    is_p2p,
    total_contacts,
    contacts_assigned,
    campaign: {
      id: campaignId,
      url_profile_img: profileUrl,
      name: campaignName = '',
      slug: campaignSlug,
    } = {},
  } = {},
  onActivityActionButtonPress,
}) => {
  const { t } = useTranslation();
  const [completed, setCompleted] = useState(initialCompleted);
  const [activityCompletedModalVisible, setActivityCompletedModalVisible] = useState(false);
  const { id: userActivityId } = userActivity || {};

  const headerTitleText =
    completed && !isFeatured
      ? `${t('common.you')} ${verbByType(activityType)} ${campaignName}`
      : `${t('campaign.activity.action')} - ${headerTitle}`;

  const closed = aasm_state === 'closed';

  function getStartLink() {
    if (!activityType) return;

    switch (activityType) {
      case 'TestimonialActivity':
        // Takes user to testimonial page where they can upload photo/video and add a note.
        return `/activities/${userActivityId}/testimonial`;

      case 'TextAFriendActivity':
        // Opens up contacts page with select boxes, pick a template, edit the template, open 1 by 1 or bulk using mailto, manually confirm alert)
        // NOTE: on webapp we are emailing, not texting, so endpoint below will differ
        return `/activities/${userActivityId}/email_friends`;

      case 'TextAListActivity':
        // ListMessage - p2p texting action, you get the list of people, you hit send manually until all messages are sent.
        return `/activities/${userActivityId}/p2p`;

      case 'DialerActivity':
        return `/activities/${userActivityId}/phone-bank`;

      case 'IdVotersActivity':
        // IdVotersScreen -> takes user to the search screen, pick the person, fill out outreach report for the voter in VoterInfoScreen
        return {
          pathname: '/voters',
          state: {
            activityId,
            campaignId,
            userActivityId,
          },
        };

      default:
        // ShareActivity and WebActivity have their own handlers. Keeping the generic link for other activity types
        return `/activities/${userActivityId}`;
    }
  }

  /**
   * Call to action buttons are rendered conditionally based on activityType
   *  - ShareActivity and WebActivity are custom components and are handled directly
   *  - Other userActivities are simple links to corresponding handler page
   *  - Campaign activities (no userActivityId) will act as 'join campaign' button
   **/
  function renderCTA() {
    // Campaign join button for campaign activity
    if (!userActivityId) {
      return (
        <StyledButton type="primary" disabled={closed} onClick={onActivityActionButtonPress}>
          {t('campaign.activity.join_to_take_action')}
        </StyledButton>
      );
    }

    if (activityType === 'ShareActivity') {
      return (
        <div onClick={trackClick}>
          <ShareActivity
            campaignName={campaignName}
            url={share_link}
            message={share_text}
            onShareWindowClose={() => {
              // Prompts user to confirm completed action
              setActivityCompletedModalVisible(true);
            }}
          />
        </div>
      );
    }

    if (activityType === 'FormActivity') {
      return <FormActivity campaignName={campaignName} userActivity={userActivity} />;
    }

    if (activityType === 'WebActivity') {
      return (
        <WebActivity
          goToUrl={go_to_link}
          closed={closed}
          onWebActivityCompleted={() => {
            // Prompts user to confirm completed action
            if (!completed) setActivityCompletedModalVisible(true);
          }}
        >
          <StyledButton
            data-testid="web-activity-start"
            onClick={trackClick}
            type="primary"
            block
            disabled={closed}
            $completed={completed}
          >
            {getButtonText(activityType, completed)}
          </StyledButton>
        </WebActivity>
      );
    }

    return (
      <Link
        data-testid="activity-start-button"
        disabled={closed}
        onClick={trackClick}
        style={{ color: 'white' }}
        to={getStartLink()}
      >
        <StyledButton type="primary" block disabled={closed} $completed={completed}>
          {buttonCopy}
        </StyledButton>
      </Link>
    );
  }

  function renderProgressBar() {
    if (!is_p2p || !total_contacts || contacts_assigned === undefined) {
      return;
    }

    if (contacts_assigned >= total_contacts) {
      return <StyledProgressBar percent={100} />;
    }

    const assigned_percent = Math.floor((contacts_assigned / total_contacts) * 100);

    return <StyledProgressBar percent={assigned_percent} status="active" />;
  }

  let buttonCopy = t('button.start');
  if (activityType) {
    buttonCopy = completed
      ? getButtonText(activityType, completed)
      : mobileButtonTextToWeb(button_text);
    buttonCopy = closed ? t('button.closed') : buttonCopy;
    buttonCopy = !buttonCopy ? t('button.start') : buttonCopy;
  }

  const trackClick = async () => {
    track('START_ACTIVITY', {
      activity_id: activityId,
      activity_title: activityTitle,
      activity_type: activityType,
      campaign_id: campaignId,
      campaign_name: campaignName,
    });
    if (!userActivityId) return;
    user.userActivityTrackClick(userActivityId);
  };

  // Decription is truncated by default, only show entire thing for detailed view
  const activityDescription = detailedView
    ? description
    : truncate(description, isFeatured ? 125 : 200);

  return (
    <StyledActivity isFeatured={isFeatured} style={{ opacity: closed ? 0.5 : 1.0 }}>
      <HeaderContainer>
        <Header>
          <HeaderTitle>{headerTitleText}</HeaderTitle>
          {activityTitle && <BodyTitle>{activityTitle}</BodyTitle>}
          <HeaderDate>
            {dateToAge(published_at)}
            {closed && ` (${t('campaign.activity.closed')})`}
          </HeaderDate>
          {renderProgressBar()}
        </Header>
      </HeaderContainer>
      <BodyContainer>
        {is_p2p}
        {activityDescription && <Body>{activityDescription}</Body>}
      </BodyContainer>
      <ActionButtons>{renderCTA()}</ActionButtons>
      {url && (
        <MediaWrapper>
          <Media src={url} />
        </MediaWrapper>
      )}
      {/* Modal will be shown for manually confirming completion of WebActivity and ShareActivity */}
      {activityCompletedModalVisible && (
        <ActivityCompletedModal
          campaignName={campaignName}
          campaignId={campaignId}
          activityTitle={activityTitle}
          activityId={activityId}
          userActivityId={userActivityId}
          onModalClosedCallback={({ success }) => {
            setActivityCompletedModalVisible(false);
            if (success) {
              // Sets local activity state to completed which updates the UI
              setCompleted(true);
            }
          }}
        />
      )}
    </StyledActivity>
  );
};

Activity.propTypes = {
  activity: PropTypes.shape({}).isRequired,
  completed: PropTypes.bool.isRequired,
  detailedView: PropTypes.bool,
  headerTitle: PropTypes.string,
  isFeatured: PropTypes.bool,
  onActivityActionButtonPress: PropTypes.func,
  userActivity: PropTypes.shape({
    id: PropTypes.number,
  }),
};

Activity.defaultProps = {
  detailedView: false,
  headerTitle: '',
  isFeatured: false,
  onActivityActionButtonPress: null,
  userActivity: null,
};

export default Activity;
