import * as React from 'react';
import PropTypes from 'prop-types';

import checkmarkTealImage from './checkmark-teal.png';
import checkmarkYellowImage from './checkmark-yellow.png';
import checkmarkGreenImage from './checkmark-green.png';

function getColor(color) {
  switch (color) {
    case 'teal':
      return checkmarkTealImage;
    case 'green':
      return checkmarkGreenImage;
    default:
      return checkmarkYellowImage;
  }
}
// MTS - These exist like this for easy conversion into an svg icon which are currently
// being requested.
function CheckmarkIcon(props) {
  return <img alt="Checkmark Icon" src={getColor(props.color)} {...props} />;
}

CheckmarkIcon.propTypes = {
  color: PropTypes.oneOf(['teal', 'yellow', 'green']),
};

CheckmarkIcon.defaultProps = {
  color: 'teal',
};
export default CheckmarkIcon;
