import React, { useState } from 'react';

import ContentWrapper from '../../components/ContentWrapper';
import PageHeader from '../../components/Header';
import { Content } from '../../components/shared';
import AddManuallyForm from './components/AddManuallyForm';
import { addReportBreadcrumb } from '../../components/BreadcrumbData';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useSendReport from '../../useSendReport';
import { Redirect } from 'react-router-dom';

const AddManually = () => {
  const { t } = useTranslation();
  const campaign = useSelector(state => state.oneCampaign.selectedCampaign);

  const [redirect, setRedirect] = useState(false);

  const { tags, customFields, submitReport } = useSendReport(true);

  if (redirect) {
    return <Redirect to={'/add-report'} />;
  }

  return (
    <Content>
      <PageHeader
        breadcrumbData={addReportBreadcrumb('addManually')}
        title={`${t('add_report.find_voter.header')} ${campaign?.name}`}
        description={t('add_report.find_voter.description')}
      />

      <ContentWrapper title={t('add_report.adding_manually.title')} showSearchAgainButton />

      <AddManuallyForm
        campaignId={campaign?.id}
        tags={tags}
        customFields={customFields}
        onSubmit={submitReport}
        redirectToFindVotersPage={() => setRedirect(true)}
      />
    </Content>
  );
};

export default AddManually;
