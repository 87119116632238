import React from 'react';

import styled from 'styled-components';

// Do not remove, we will probably use it again
// import Icon from '@web/components/common/Icon';
// import { Button, message } from 'antd';
// const ShareButton = styled(Button)`
//   &&& {
//     padding: 0;
//     border: none;
//     background-color: transparent;
//   }
// `;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 34px 16px;
  border-bottom: 2px solid black;
`;

const StyledHeader = styled.a`
  margin: 0;
  padding: 0;

  font-size: 18px;
  font-weight: 700;
  line-height: 100%;

  color: black;
  :hover {
    color: black;
    text-decoration: none;
  }
`;

const Header = () => {
  // Do not remove, we will probably use it again
  // const onShare = useCallback(() => {
  //   const url = window.location.href;

  //   if (window.navigator.share) {
  //     navigator
  //       .share({
  //         url,
  //       })
  //       .catch(() => {});
  //   } else {
  //     navigator?.clipboard
  //       .writeText(url)
  //       .then(() => message.success('Link copied!'))
  //       .catch(() => message.error('Error copying link'));
  //   }
  // }, []);

  return (
    <Flex>
      <StyledHeader href="/my_vote_score">Vote Score</StyledHeader>
      {/*
        Do not remove, we will probably use it again
        <ShareButton onClick={onShare}>
          <Icon name="Upload" />
        </ShareButton>
      */}
    </Flex>
  );
};

Header.propTypes = {};

export default Header;
