import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from '@web/components/Headcount/components/Text';

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const StyledVoterBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 12px;
  display: flex;
  margin: 2rem 0 ${({ theme }) => theme.spacing.wide};
  padding: ${({ theme }) => theme.spacing.normal};

  ${Column}:last-of-type {
    align-items: flex-end;
  }
`;

const Label = styled.span`
  background-color: ${({ $bgColor, theme }) => theme.colors[$bgColor]};
  border-radius: 3px;
  padding: 0.25rem;
`;

const Name = styled(Text)`
  line-height: 1.5;
  margin-bottom: ${({ theme }) => theme.spacing.tight};
`;

const AddressLine = styled(Text).attrs(() => ({ variant: 'subtitle' }))`
  &&& {
    font-family: ${({ theme }) => theme.fonts.bold};
    text-transform: uppercase;
  }
`;

function VoterBox({ address, label, voterName }) {
  return (
    <StyledVoterBox>
      <Column>
        <Name>{voterName}</Name>
        <AddressLine>{address.line1}</AddressLine>
        {address.line2 && <AddressLine>{address.line2}</AddressLine>}
        <AddressLine>{address.line3}</AddressLine>
      </Column>
      <Column>
        <Label $bgColor={label?.bgColor || 'bgGray2'}>
          <Text as="span" variant="f3">
            {label.text}
          </Text>
        </Label>
      </Column>
    </StyledVoterBox>
  );
}

VoterBox.propTypes = {
  address: PropTypes.shape({
    line1: PropTypes.string.isRequired,
    line2: PropTypes.string,
    line3: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.shape({
    // I tried to add a default in defaultProps by it wasn't picked up
    bgColor: PropTypes.string,
    text: PropTypes.string.isRequired,
  }).isRequired,
  voterName: PropTypes.string.isRequired,
};

VoterBox.defaultProps = {};

export default VoterBox;
