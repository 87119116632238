import PropTypes from 'prop-types';
import styled from 'styled-components';

const colors = {
  blue: 'rgba(108, 203, 218, 0.1)',
  green: 'rgba(82, 196, 26, 0.1)',
  red: 'rgba(245, 34, 45, 0.06)',
};

const MessageBox = styled.div`
  background-color: ${({ $bgColor }) => colors[$bgColor]};
  border-radius: 0.5rem;
  padding: 1rem;
`;

MessageBox.propTypes = {
  $bgColor: PropTypes.oneOf(['blue', 'red', 'green']),
};

MessageBox.defaultProps = {
  $bgColor: 'blue',
};

export default MessageBox;
