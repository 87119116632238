import { Modal, Switch } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Loading from 'web/components/common/Loading';
import { users } from 'web/services/api';
import theme from 'web/styles/theme';
import ThreeStateSwitch from './ThreeStateSwitch';

const ModalHeader = styled.div`
  font-size: 22px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  margin-bottom: 10px;
`;

const SubHeading = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  text-transform: capitalize;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  margin-top: 2rem;
`;

const StepTitle = styled.div`
  font-size: 23px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  margin: 0.5rem 0;
`;

const StepName = styled.div`
  font-size: 16px;
  margin-top: 0.5rem;
`;

const StepScript = styled.div`
  font-size: 14px;
  margin-top: 0.5rem;
  padding: 20px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.messageBackground};
  cursor: pointer;
`;

const List = styled.div``;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  padding: 0.5rem 0;
`;

const ListItemLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.semibold};
  text-transform: capitalize;
  padding-right: 1rem;
`;

const RegistrationStatusTypes = {
  UNREGISTERED: 'Unregistered',
  WRONG_ADDRESS: 'Wrong Address',
};

const RegStateTypes = {
  NEVER_RECEIVED_FORM: 'never_received_form',
  NOT_SENT: 'not_sent',
  RECEIVED_FORM: 'received_form',
  SENT_FORM: 'sent_form',
  UNABLE_REGISTER_ONLINE: 'unable_to_register_online',
};

const VbmStateTypes = {
  NEVER_RECEIVED_FORM: 'never_received_form',
  NOT_SENT: 'not_sent',
  RECEIVED_FORM: 'received_form',
  SENT_FORM: 'sent_form',
  UNABLE_REQUEST_ONLINE: 'unable_to_request_online',
};

const CheckRegistrationModal = ({
  campaignId,
  contactId,
  onSelectTemplate,
  onUpdateContact,
  onClose,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [step, setStep] = React.useState(null);
  const contact = step && step.contact;

  React.useEffect(() => {
    setLoading(true);
    users.getRegistrationStep(campaignId, contactId).then(response => {
      setStep(response.data);
      setLoading(false);
    });
  }, [campaignId, contactId]);

  const handleClickScript = React.useCallback(() => {
    onSelectTemplate(step.script, step);
  }, [step, onSelectTemplate]);

  const handleClickFollowUp = React.useCallback(() => {
    onSelectTemplate(step.follow_up, step);
  }, [step, onSelectTemplate]);

  return (
    <Modal
      bodyStyle={{ background: theme.colors.background }}
      visible
      maskClosable
      onCancel={onClose}
      footer={null}
    >
      <ModalHeader>{t('inbox.check_registration.header')}</ModalHeader>
      {loading && <Loading centered />}
      {!loading && (
        <>
          <SubHeading>{t('inbox.check_registration.current_status')}:</SubHeading>
          <StepTitle>{step.title}</StepTitle>
          <div>{step.instructions}</div>
          <SubHeading>{t('inbox.check_registration.next_script')}:</SubHeading>
          <StepName>{step.name}</StepName>
          <StepScript onClick={handleClickScript}>{step.script}</StepScript>
          {step.follow_up && (
            <>
              <SubHeading>{t('inbox.check_registration.follow_up_script')}:</SubHeading>
              <StepScript onClick={handleClickFollowUp}>{step.follow_up}</StepScript>
            </>
          )}
          <SubHeading>{t('inbox.check_registration.voting_information')}:</SubHeading>
          <List>
            {contact.reg_confirmed && contact.vbm_confirmed && (
              <>
                <ListItem>
                  <ListItemLabel>{t('inbox.check_registration.name')}</ListItemLabel>
                  <span>
                    {contact.voter_first_name
                      ? `${contact.voter_first_name} ${contact.voter_last_name}`
                      : t('inbox.check_registration.unknown')}
                  </span>
                </ListItem>
                <ListItem>
                  <ListItemLabel>{t('inbox.check_registration.location')}</ListItemLabel>
                  <span>
                    {contact.mail_city
                      ? `${contact.mail_city}, ${contact.mail_state_abbrev}`
                      : t('inbox.check_registration.unknown')}
                  </span>
                </ListItem>
                <ListItem>
                  <ListItemLabel>{t('inbox.check_registration.can_register_online')}</ListItemLabel>
                  <Switch checked={contact.can_register_online} />
                </ListItem>
                <ListItem>
                  <ListItemLabel>{t('inbox.check_registration.can_vote_by_mail')}</ListItemLabel>
                  <Switch checked={contact.can_vbm} />
                </ListItem>
              </>
            )}
            <ListItem>
              <ListItemLabel>{t('inbox.check_registration.registration_confirmed')}</ListItemLabel>
              <Switch checked={contact.reg_confirmed} />
            </ListItem>
            <ListItem>
              <ListItemLabel>{t('inbox.check_registration.address_confirmed')}</ListItemLabel>
              <Switch checked={contact.vbm_confirmed} />
            </ListItem>
            <ListItem>
              <ListItemLabel>{t('inbox.check_registration.correct_address')}</ListItemLabel>
              <Switch
                checked={
                  contact.vbm_confirmed &&
                  contact.registration_status !== RegistrationStatusTypes.WRONG_ADDRESS
                }
              />
            </ListItem>
          </List>
          <SubHeading>{t('inbox.check_registration.voting_questions')}:</SubHeading>
          <List>
            {(contact.registration_status === RegistrationStatusTypes.UNREGISTERED ||
              contact.registration_status === RegistrationStatusTypes.WRONG_ADDRESS) &&
              contact.reg_confirmed &&
              contact.can_register_online &&
              (contact.reg_state === RegStateTypes.NOT_SENT ||
                contact.reg_state === RegStateTypes.UNABLE_REGISTER_ONLINE) && (
                <ListItem>
                  <ListItemLabel>
                    {t('inbox.check_registration.were_able_to_register_online')}
                  </ListItemLabel>
                  <ThreeStateSwitch
                    value={
                      contact.reported_registered
                        ? ThreeStateSwitch.valueTypes.YES
                        : !contact.reported_registered &&
                          contact.reg_state === RegStateTypes.UNABLE_REGISTER_ONLINE
                        ? ThreeStateSwitch.valueTypes.NO
                        : ThreeStateSwitch.valueTypes.UNKNOWN
                    }
                    onChange={value => {
                      switch (value) {
                        case ThreeStateSwitch.valueTypes.YES:
                          return onUpdateContact({
                            reg_state: RegStateTypes.NOT_SENT,
                            reported_registered: true,
                          });
                        case ThreeStateSwitch.valueTypes.NO:
                          return onUpdateContact({
                            reg_state: RegStateTypes.UNABLE_REGISTER_ONLINE,
                            reported_registered: false,
                          });
                        default:
                          return onUpdateContact({
                            reg_state: RegStateTypes.NOT_SENT,
                            reported_registered: false,
                          });
                      }
                    }}
                  />
                </ListItem>
              )}
            {(contact.registration_status === RegistrationStatusTypes.UNREGISTERED ||
              contact.registration_status === RegistrationStatusTypes.WRONG_ADDRESS) &&
              contact.reg_confirmed &&
              (!contact.can_register_online ||
                contact.reg_state === RegStateTypes.UNABLE_REGISTER_ONLINE) &&
              step.is_sending_registration_forms && (
                <ListItem>
                  <ListItemLabel>
                    {t('inbox.check_registration.should_mail_registration_form')}
                  </ListItemLabel>
                  <ThreeStateSwitch
                    value={contact.requested_registration_form}
                    onChange={value => onUpdateContact({ requested_registration_form: value })}
                  />
                </ListItem>
              )}
            {(contact.reg_state === RegStateTypes.SENT_FORM ||
              contact.reg_state === RegStateTypes.RECEIVED_FORM ||
              contact.reg_state === RegStateTypes.NEVER_RECEIVED_FORM) && (
              <ListItem>
                <ListItemLabel>
                  {t('inbox.check_registration.registration_form_received')}
                </ListItemLabel>
                <ThreeStateSwitch
                  value={
                    contact.reg_state === RegStateTypes.SENT_FORM
                      ? ThreeStateSwitch.valueTypes.UNKNOWN
                      : contact.reg_state === RegStateTypes.RECEIVED_FORM
                      ? ThreeStateSwitch.valueTypes.YES
                      : ThreeStateSwitch.valueTypes.NO
                  }
                  onChange={value => {
                    return onUpdateContact({
                      reg_state:
                        value === ThreeStateSwitch.valueTypes.YES
                          ? RegStateTypes.RECEIVED_FORM
                          : value === ThreeStateSwitch.valueTypes.NO
                          ? RegStateTypes.NEVER_RECEIVED_FORM
                          : RegStateTypes.SENT_FORM,
                    });
                  }}
                />
              </ListItem>
            )}
            {(contact.registration_status === RegistrationStatusTypes.UNREGISTERED ||
              contact.registration_status === RegistrationStatusTypes.WRONG_ADDRESS) &&
              contact.reg_confirmed &&
              ((!contact.can_register_online && !step.is_sending_registration_forms) ||
                contact.reg_state === RegStateTypes.UNABLE_REGISTER_ONLINE ||
                contact.requested_registration_form === 'no') &&
              (contact.reg_state === RegStateTypes.NOT_SENT ||
                contact.reg_state === RegStateTypes.UNABLE_REGISTER_ONLINE) && (
                <ListItem>
                  <ListItemLabel>
                    {t('inbox.check_registration.were_able_to_register_on_their_own')}
                  </ListItemLabel>
                  <ThreeStateSwitch
                    value={
                      contact.reported_registered
                        ? ThreeStateSwitch.valueTypes.YES
                        : !contact.reported_registered &&
                          contact.reg_state === RegStateTypes.UNABLE_REGISTER_ONLINE
                        ? ThreeStateSwitch.valueTypes.NO
                        : ThreeStateSwitch.valueTypes.UNKNOWN
                    }
                    onChange={value => {
                      switch (value) {
                        case ThreeStateSwitch.valueTypes.YES:
                          return onUpdateContact({
                            reg_state: RegStateTypes.NOT_SENT,
                            reported_registered: true,
                          });
                        case ThreeStateSwitch.valueTypes.NO:
                          return onUpdateContact({
                            reg_state: RegStateTypes.UNABLE_REGISTER_ONLINE,
                            reported_registered: false,
                          });
                        default:
                          return onUpdateContact({
                            reg_state: RegStateTypes.NOT_SENT,
                            reported_registered: false,
                          });
                      }
                    }}
                  />
                </ListItem>
              )}
            {contact.can_vbm &&
              contact.vbm_confirmed &&
              contact.can_vbm_online &&
              (contact.vbm_state === VbmStateTypes.NOT_SENT ||
                contact.vbm_state === VbmStateTypes.UNABLE_REQUEST_ONLINE) && (
                <ListItem>
                  <ListItemLabel>
                    {t('inbox.check_registration.were_able_to_request_absentee_ballot_online')}
                  </ListItemLabel>
                  <ThreeStateSwitch
                    value={
                      contact.reported_requested_vbm
                        ? ThreeStateSwitch.valueTypes.YES
                        : !contact.reported_requested_vbm &&
                          contact.vbm_state === VbmStateTypes.UNABLE_REQUEST_ONLINE
                        ? ThreeStateSwitch.valueTypes.NO
                        : ThreeStateSwitch.valueTypes.UNKNOWN
                    }
                    onChange={value => {
                      switch (value) {
                        case ThreeStateSwitch.valueTypes.YES:
                          return onUpdateContact({
                            reported_requested_vbm: true,
                            vbm_state: VbmStateTypes.NOT_SENT,
                          });
                        case ThreeStateSwitch.valueTypes.NO:
                          return onUpdateContact({
                            reported_requested_vbm: false,
                            vbm_state: VbmStateTypes.UNABLE_REQUEST_ONLINE,
                          });
                        default:
                          return onUpdateContact({
                            reported_requested_vbm: false,
                            vbm_state: VbmStateTypes.NOT_SENT,
                          });
                      }
                    }}
                  />
                </ListItem>
              )}
            {contact.vbm_confirmed &&
              contact.can_vbm &&
              (!contact.can_vbm_online ||
                contact.vbm_state === VbmStateTypes.UNABLE_REQUEST_ONLINE) &&
              step.is_sending_vbm_forms && (
                <ListItem>
                  <ListItemLabel>
                    {t('inbox.check_registration.should_mail_absentee_ballot_request_form')}
                  </ListItemLabel>
                  <ThreeStateSwitch
                    value={contact.requested_absentee_form}
                    onChange={value => onUpdateContact({ requested_absentee_form: value })}
                  />
                </ListItem>
              )}
            {(contact.vbm_state === VbmStateTypes.SENT_FORM ||
              contact.vbm_state === VbmStateTypes.RECEIVED_FORM ||
              contact.vbm_state === VbmStateTypes.NEVER_RECEIVED_FORM ||
              contact.automatic_vbm) && (
              <ListItem>
                <ListItemLabel>
                  {t('inbox.check_registration.absentee_ballot_request_form_received')}
                </ListItemLabel>
                <ThreeStateSwitch
                  value={
                    contact.vbm_state === VbmStateTypes.SENT_FORM
                      ? ThreeStateSwitch.valueTypes.UNKNOWN
                      : contact.vbm_state === VbmStateTypes.RECEIVED_FORM
                      ? ThreeStateSwitch.valueTypes.YES
                      : ThreeStateSwitch.valueTypes.NO
                  }
                  onChange={value => {
                    return onUpdateContact({
                      vbm_state:
                        value === ThreeStateSwitch.valueTypes.YES
                          ? VbmStateTypes.RECEIVED_FORM
                          : value === ThreeStateSwitch.valueTypes.NO
                          ? VbmStateTypes.NEVER_RECEIVED_FORM
                          : VbmStateTypes.SENT_FORM,
                    });
                  }}
                />
              </ListItem>
            )}
            {contact.can_vbm &&
              contact.vbm_confirmed &&
              ((!contact.can_vbm_online && !step.is_sending_vbm_forms) ||
                contact.vbm_state === VbmStateTypes.UNABLE_REQUEST_ONLINE ||
                contact.requested_absentee_form === 'no') &&
              (contact.vbm_state === VbmStateTypes.NOT_SENT ||
                contact.vbm_state === VbmStateTypes.UNABLE_REQUEST_ONLINE) && (
                <ListItem>
                  <ListItemLabel>
                    {t(
                      'inbox.check_registration.were_able_to_request_absentee_ballot_on_their_own',
                    )}
                  </ListItemLabel>
                  <ThreeStateSwitch
                    value={
                      contact.reported_requested_vbm
                        ? ThreeStateSwitch.valueTypes.YES
                        : !contact.reported_requested_vbm &&
                          contact.vbm_state === VbmStateTypes.UNABLE_REQUEST_ONLINE
                        ? ThreeStateSwitch.valueTypes.NO
                        : ThreeStateSwitch.valueTypes.UNKNOWN
                    }
                    onChange={value => {
                      switch (value) {
                        case ThreeStateSwitch.valueTypes.YES:
                          return onUpdateContact({
                            reported_requested_vbm: true,
                            vbm_state: VbmStateTypes.NOT_SENT,
                          });
                        case ThreeStateSwitch.valueTypes.NO:
                          return onUpdateContact({
                            reported_requested_vbm: false,
                            vbm_state: VbmStateTypes.UNABLE_REQUEST_ONLINE,
                          });
                        default:
                          return onUpdateContact({
                            reported_requested_vbm: false,
                            vbm_state: VbmStateTypes.NOT_SENT,
                          });
                      }
                    }}
                  />
                </ListItem>
              )}
            {contact.reported_registered && contact.reported_requested_vbm && (
              <ListItem>
                <ListItemLabel>{t('inbox.check_registration.should_send_reminder')}</ListItemLabel>
                <ThreeStateSwitch
                  value={contact.requested_reminders}
                  onChange={value => onUpdateContact({ requested_reminders: value })}
                />
              </ListItem>
            )}
          </List>
        </>
      )}
    </Modal>
  );
};

CheckRegistrationModal.propTypes = {
  campaignId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectTemplate: PropTypes.func.isRequired,
  onUpdateContact: PropTypes.func.isRequired,
};

export default CheckRegistrationModal;
