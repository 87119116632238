import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { media } from '@web/styles/theme';

import JoinButton from '@web/components/common/JoinButton';
import clearLogo from '@web/images/app-logo-secondary.png';
import { replaceDefaultCampaignImage } from '@web/utils/string';

import CampaignImage from './components/CampaignImage';

const StyledCampaignHero = styled.div`
  color: ${({ theme }) => theme.colors.black};
  padding: 40px 30px 24px;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 850px;

  ${media.lg} {
    padding: 40px 0px 49px;
    min-height: 1024px;
    height: 90%;

    :before {
      background-position: center;
    }
  }

  :before {
    content: '';
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%),
      url(${props => props.backgroundImage});
    background-position: left 43% top;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`;

const CampaignContainer = styled.div`
  width: 100%;

  ${media.lg} {
    width: 80%;
    padding: 0 10%;
  }
`;

const LogoContainer = styled.div`
  width: 190px;
  height: 42px;
  border: 4px solid ${({ theme }) => theme.colors.white};
  padding: 4px 10px 6px 16px;
  margin-bottom: 30vh;

  img {
    width: 150px;
    height: 20px;
  }

  ${media.lg} {
    margin-bottom: 80px;
  }

  a {
    width: 100%;
    height: 100%;
  }
`;

const CampaignTitle = styled.h1`
  max-width: 600px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 3rem;

  color: ${({ theme }) => theme.colors.white};

  ${media.lg} {
    line-height: 90px;
    font-size: 5rem;
    margin-bottom: 30px;
  }
`;

const CampaignSubtitle = styled.h2`
  margin-bottom: 40px;
  max-width: 600px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;

  ${media.lg} {
    font-size: 3rem;
    margin-bottom: 40px;
  }
`;

const CampaignHero = ({ campaign, joined, handleGetStarted }) => {
  const { t } = useTranslation();

  return (
    <StyledCampaignHero backgroundImage={campaign.url_hero_img}>
      <CampaignContainer>
        <LogoContainer>
          <Link to="/frontline">
            <img src={clearLogo} alt="site-logo" />
          </Link>
        </LogoContainer>

        <CampaignImage
          alt={campaign.name}
          src={replaceDefaultCampaignImage(campaign.url_profile_img)}
        />

        <CampaignTitle>{campaign.name}</CampaignTitle>
        <CampaignSubtitle>{campaign.slogan}</CampaignSubtitle>

        <JoinButton joined={joined} onClick={handleGetStarted}>
          {joined ? t('campaign.joined') : t('campaign.get_started')}
        </JoinButton>
      </CampaignContainer>
    </StyledCampaignHero>
  );
};

CampaignHero.propTypes = {
  campaign: PropTypes.object.isRequired,
  handleGetStarted: PropTypes.func.isRequired,
  joined: PropTypes.bool.isRequired,
};

export default CampaignHero;
