import styled from 'styled-components';

export const Body = styled.p`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 16px;
  text-align: center;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 24px;
  line-height: 34px;
  text-align: center;
`;
