import * as React from 'react';
// import PropTypes from 'prop-types';

import { Body, Title } from '@web/components/DialerActivity/components/TextStyles';

function PhoneBankNotStarted() {
  return (
    <>
      <Title>This phone bank has not yet started.</Title>
      <Body style={{ marginTop: 16 }}>
        Once the phone bank begins you will be able to make a call.
      </Body>
    </>
  );
}

PhoneBankNotStarted.propTypes = {};

PhoneBankNotStarted.defaultProps = {};

export default PhoneBankNotStarted;
