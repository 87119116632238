import { Radio } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';

const Wrapper = styled.div`
  transition: all 300ms ease-in-out;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const StyledRadioGroup = styled(Radio.Group)`
  &&& {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 8px;

    @media screen and (max-width: 1300px) {
      flex-direction: column;
    }
  }
`;

const StyledRadio = styled(Radio)`
  &&& {
    padding: 16px;
    flex: 1;
    flex-direction: row;
    white-space: normal;
    &:hover {
      cursor: unset;
    }

    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fonts.regular};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;

    ${smallBreakpoint} {
      font-size: 0.875rem;
      line-height: 1.25rem; /* 142.857% */
    }

    .ant-radio-inner {
      border-color: ${({ theme }) => theme.colors.blackL42};
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: ${({ theme }) => theme.colors.black} !important;
    }

    .ant-radio-inner:after {
      background-color: ${({ theme }) => theme.colors.black};
      width: 12px;
      height: 12px;
      margin-top: -2px;
      margin-left: -2px;
      border-radius: 12px;
    }
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  border-radius: 8px;
  border: 1px solid
    ${({ $picked, theme }) => ($picked ? theme.colors.newBlue : 'rgba(35, 41, 62, 0.16)')};
  background: ${({ $picked }) =>
    $picked ? 'rgba(144, 185, 246, 0.20)' : 'rgba(255, 255, 255, 0.05)'};
  align-self: stretch;
  transition: all 300ms ease-in-out;

  &:hover {
    cursor: pointer;
  }
`;

const FirstSentence = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1rem;
  line-height: 1.5rem;

  ${smallBreakpoint} {
    font-size: 0.875rem;
    line-height: 1.25rem; /* 142.857% */
  }
`;

const RadioGroupFormPartial = ({ handleChange, name, options, answeredValue }) => {
  const answers = useMemo(
    () =>
      Object.entries(options)
        // add a random sort value to the answers
        .map(([key, value]) => ({ ...value, key, sortValue: Math.random() }))
        // randomly sort the answers
        .sort((a, b) => a.sortValue - b.sortValue)
        // remove sortValue
        .map(({ sortValue, ...rest }) => rest),
    [options],
  );

  return (
    <Wrapper>
      <StyledRadioGroup name={name} onChange={handleChange} value={answeredValue}>
        {answers.map(({ key, value }) => {
          const [firstSentence, ...restOfSentences] = value.split('.');
          const rest = restOfSentences.join('. ');

          return (
            <InnerContainer key={key} $picked={key === answeredValue}>
              <StyledRadio value={key}>
                <FirstSentence>{firstSentence}.</FirstSentence>
                <br />
                {rest}
              </StyledRadio>
            </InnerContainer>
          );
        })}
      </StyledRadioGroup>
    </Wrapper>
  );
};

RadioGroupFormPartial.propTypes = {
  answeredValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.object,
  values: PropTypes.object.isRequired,
};

export default RadioGroupFormPartial;
