import * as React from 'react';
import PropTypes from 'prop-types';

import ContactForm from '@web/components/Headcount/components/FormComponents/ContactForm';
import HeaderAndProgress from '@web/components/Headcount/components/HeaderAndProgress';
import RegisterToVoteResult from './pages/RegisterToVoteResult';
import StateIdQuestion from './pages/StateIdQuestion';

import usePageNavigation from '../../hooks/usePageNavigation';
import useContactForm from '@web/components/Headcount/hooks/useContactForm';
import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';

import { useTranslation } from 'react-i18next';

function RegisterToVote({ activity, backToStart, setRootFlow, team }) {
  const { t } = useTranslation();
  const { schemas } = useContactForm();
  const pages = [ContactForm, StateIdQuestion, RegisterToVoteResult];
  const headcountMtvEnabled = React.useMemo(
    () => activity?.campaign?.headcount_mtv_enabled,
    [activity?.campaign?.headcount_mtv_enabled],
  );
  const pageNavigation = usePageNavigation(pages.length, headcountMtvEnabled);
  const { currentPage, progress, numberOfPages } = pageNavigation;
  const isPartner = useIsPartner(team);
  const cannabisRegistrationEnabled = React.useMemo(
    () => activity?.campaign?.cannabis_registration_enabled,
    [activity?.campaign?.cannabis_registration_enabled],
  );

  function contactFormTitle() {
    if (headcountMtvEnabled) {
      return t('check_registration.headcount.mtvSubtitle.registerToVote');
    }
    if (cannabisRegistrationEnabled) {
      return t('check_registration.headcount.cannabisSubtitle.registerToVote');
    }
    if (isPartner) {
      return t('check_registration.headcount.partnerSubtitle.registerToVote');
    }

    return t('check_registration.headcount.subtitle.getReadyToVote');
  }

  const pageProps = {
    activity,
    backToStart,
    contactFormSchema: schemas.contactFormHeadcount,
    contactFormTitle: contactFormTitle(),
    setRootFlow,
    team,
    ...pageNavigation,
  };

  const CurrentPage = pages[currentPage];
  return (
    <>
      <HeaderAndProgress
        activity={activity}
        progress={progress}
        numberOfPages={numberOfPages}
        team={team}
      />
      <CurrentPage {...pageProps} />
    </>
  );
}

RegisterToVote.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      cannabis_registration_enabled: PropTypes.string.isRequired,
      headcount_mtv_enabled: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
  }).isRequired,
  backToStart: PropTypes.func.isRequired,
  setRootFlow: PropTypes.func.isRequired,
  team: PropTypes.object,
};

RegisterToVote.defaultProps = {
  team: null,
};

export default RegisterToVote;
