import * as React from 'react';
import PropTypes from 'prop-types';
import { track } from '@web/services/analytics';
import styled from 'styled-components';

import Button from '@web/components/Headcount/components/Button';
import Text from '@web/components/Headcount/components/Text';
import AddressInput from '@web/components/Headcount/components/Input/AddressInput';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import Input from '@web/components/Headcount/components/Input';

import { setStateIdWithAddressForm } from '@web/reducers/voterRegistration';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useContactForm from '@web/components/Headcount/hooks/useContactForm';
import useVoterRegistrationApi from '@web/components/Headcount/hooks/useVoterRegistrationApi';

const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.wide};
  line-height: 1.5;
`;

const AddressWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.wide};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  button:first-of-type {
    margin-bottom: ${({ theme }) => theme.spacing.normal};
  }
`;

const ErrorWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.wide};
`;

function StateIdQuestionWithAddress({ activity, nextPage, team }) {
  const dispatch = useDispatch();
  const { hasStateId } = useSelector(state => state.voterRegistration);
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const { t } = useTranslation();
  const { initialValues, schemas } = useContactForm();
  const { handleError, submitContactUpdate } = useVoterRegistrationApi(activity);
  const [apiError, setApiError] = React.useState(null);

  track('HEADCOUNT_VIEW_STATE_ID_QUESTION_WITH_ADDRESS', {
    activity_id: activity?.id,
    activity_title: activity?.title,
    activity_type: activity?.type,
    campaign_id: activity?.campaign?.id,
    campaign_name: activity?.campaign?.name,
    team_id: team?.id,
    team_name: team?.name,
  });

  function sendRequest(values, setSubmitting) {
    setApiError(null);
    setSubmitting(true);

    track('HEADCOUNT_ANSWERED_STATE_ID_QUESTION_WITH_ADDRESS', {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      team_id: team?.id,
      team_name: team?.name,
      value: values.stateId,
    });

    submitContactUpdate(contactFormResult, values)
      .then(({ data }) => {
        dispatch(
          setStateIdWithAddressForm({
            contact: data,
            hasStateId: data.has_state_identification,
            voter: {
              addressLine1: data.address,
              addressLine2: `${data.city}, ${data.state_abbrev}`,
              ageRange: data.voterfile['vb.voterbase_age'],
              name: `${data.first_name} ${data.last_name}`.toLowerCase(),
              voterbaseId: data.voterfile.voterbase_id,
              zip: data.zip_code,
            },
          }),
        );

        nextPage();
      })
      .catch(err => {
        const errorMessage = handleError(err);
        setApiError(errorMessage);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function handleButtonClick(values, setSubmitting, validateForm, setTouched) {
    validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        sendRequest(values, setSubmitting);
      } else {
        setTouched(errors);
      }
    });
  }

  function getButtonVariant(buttonType) {
    // We do three checks here: null, true, and false
    // hasStateId will only not be null if someone navigates
    // back to this component.
    if (hasStateId === null) {
      return 'secondary';
    }
    if (buttonType === 'yes' && hasStateId) {
      return 'primary';
    }
    // MTS - We must use false here or else null will give us a false positive
    if (buttonType === 'no' && hasStateId === false) {
      return 'primary';
    }
    return 'secondary';
  }

  function handleAddressChange(address, setFieldValue) {
    setFieldValue('address', address.address);
    setFieldValue('city', address.city);
    setFieldValue('zipCode', address.zip_code);
    setFieldValue('countyName', address.county_name);
    setFieldValue('stateAbbrev', address.state_abbrev);
  }

  return (
    <Formik
      initialValues={initialValues.updateAddressForm}
      validationSchema={schemas.updateAddressForm}
      render={({
        isSubmitting,
        setFieldValue,
        setSubmitting,
        setTouched,
        validateForm,
        values,
      }) => (
        <Form>
          <AddressWrapper>
            <StyledText variant="f2">
              {t('check_registration.headcount.enterVoteFromAddress')}
            </StyledText>
            <AddressInput
              label={t('idvoters.labels.address')}
              name="searchAddress"
              onChange={address => handleAddressChange(address, setFieldValue)}
              type="search"
            />
            <Input label={t('idvoters.labels.unitNumber')} name="unitNumber" />
          </AddressWrapper>

          <StyledText variant="f2">{t('check_registration.headcount.idQuestion')}</StyledText>
          <StyledText>{t('check_registration.headcount.idDesc')}</StyledText>
          <ButtonWrapper>
            <Button
              loading={isSubmitting}
              onClick={() => {
                handleButtonClick(
                  { ...values, stateId: true },
                  setSubmitting,
                  validateForm,
                  setTouched,
                );
              }}
              type="button"
              variant={getButtonVariant('yes')}
            >
              {t('common.yes')}
            </Button>
            <Button
              loading={isSubmitting}
              onClick={() =>
                handleButtonClick(
                  { ...values, stateId: false },
                  setSubmitting,
                  validateForm,
                  setTouched,
                )
              }
              type="button"
              variant={getButtonVariant('no')}
            >
              {t('common.no')}
            </Button>

            {apiError && (
              <ErrorWrapper>
                <Text color="red" variant="f3" style={{ marginBottom: '0.5rem' }}>
                  {apiError}
                </Text>
              </ErrorWrapper>
            )}
          </ButtonWrapper>
        </Form>
      )}
    />
  );
}

StateIdQuestionWithAddress.propTypes = {
  activity: PropTypes.object.isRequired,
  nextPage: PropTypes.func.isRequired,
  team: PropTypes.object,
};

StateIdQuestionWithAddress.defaultProps = {};

export default StateIdQuestionWithAddress;
