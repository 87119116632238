import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Field } from 'formik';
import { inputStyle } from '../sharedStyles';
import InputMask from 'react-input-mask';

export const StyledInput = styled(Field)`
  ${inputStyle}

  &&& {
    ${({ $withoutIcon }) => $withoutIcon && `padding-left: 16px`}
  }
`;

const MaskedInput = ({ $withoutIcon, ...props }) => (
  <InputMask {...props}>
    {inputProps => <StyledInput $withoutIcon={$withoutIcon} {...inputProps} />}
  </InputMask>
);

MaskedInput.propTypes = {
  $withoutIcon: PropTypes.bool,
};

export default MaskedInput;
