import { string } from 'prop-types';
import React from 'react';

import theme from '@web/styles/theme';

const Copy = props => {
  const { fill } = props;

  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <path
          fill={fill}
          d="M2.667 2h10.666a.667.667 0 0 1 .667.667V6h3.333a.667.667 0 0 1 .667.667v10.666a.666.666 0 0 1-.667.667H6.667A.666.666 0 0 1 6 17.333V14H2.667A.666.666 0 0 1 2 13.333V2.667A.667.667 0 0 1 2.667 2Zm4.666 14.667h9.334V7.333H7.333v9.334Zm-4-4H6v-6A.667.667 0 0 1 6.667 6h6V3.333H3.333v9.334Z"
        />
      </svg>
    </div>
  );
};

Copy.propTypes = {
  fill: string,
};

Copy.defaultProps = {
  fill: theme.colors.trueBlack,
};

export default Copy;
