import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IncentiveItem from './IncentiveItem';

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0;
  padding: 0;
  margin-left: 0.5rem;
`;

function Incentives({ fontColor, iconColor, pageColor, incentives }) {
  if (!incentives) {
    return null;
  }

  return (
    <Ul>
      {incentives.map(el => (
        <IncentiveItem
          iconColor={iconColor}
          pageColor={pageColor}
          key={el.id}
          style={{ order: el.position }}
        >
          <span style={{ color: fontColor }}>{el.name}</span>
        </IncentiveItem>
      ))}
    </Ul>
  );
}

Incentives.propTypes = {
  fontColor: PropTypes.string,
  iconColor: PropTypes.string,
  incentives: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image_url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
    }),
  ),
  pageColor: PropTypes.string,
};

Incentives.defaultProps = {};

export default Incentives;
