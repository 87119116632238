import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { fonts } from '@web/styles/theme';
import { FilterTypes } from '../consts';

const { Option } = Select;

const StyledSelect = styled(Select)`
  &&& {
    > div {
      color: ${({ theme, checked }) => (checked ? theme.colors.darkBlack : theme.colors.dimGray)};
      font-family: ${fonts.regular};
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      &:hover {
        border-color: unset;
      }

      min-width: 133px;
      display: flex;
      flex-direction: row-reverse;
      border: none;
      border-bottom: ${({ theme, checked }) =>
        `solid ${checked ? theme.colors.darkBlack : 'transparent'}`};
      > span {
        margin-top: 5px;
        display: flex;
        align-items: center;
        margin-left: 6px;
      }
    }
  }
`;

const FiltersSelect = ({ onFilterChange, filter }) => {
  const { t } = useTranslation();
  const dropdownValue = () => {
    if (
      filter === FilterTypes.TEAM ||
      filter === FilterTypes.CANVASSED ||
      filter === FilterTypes.FRIENDS
    ) {
      return filter;
    }

    return t('inbox.more_filters');
  };
  return (
    <StyledSelect
      onChange={val => {
        onFilterChange(val);
      }}
      data-testid="select"
      value={dropdownValue()}
      checked={dropdownValue() !== 'MORE FILTERS'}
    >
      <Option value={FilterTypes.TEAM} data-testid="team-filter">
        {t('inbox.team')}
      </Option>
      <Option value={FilterTypes.CANVASSED}>{t('inbox.canvassed')}</Option>
      <Option value={FilterTypes.FRIENDS}>{t('inbox.friends')}</Option>
    </StyledSelect>
  );
};

FiltersSelect.propTypes = {
  filter: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
};

export default FiltersSelect;
