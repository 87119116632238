import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SweepstakesEntries from '@web/components/FanOut/components/SweepstakesEntries';

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1;
`;

const HeroImage = styled.img`
  object-fit: scale-down;
  max-width: 740px;
  max-height: 740px;
  width: 100%;

  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.colors.blue};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 4px 4px 0px 0px ${({ theme }) => theme.colors.blue};
  overflow: hidden;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem; /* 125% */
`;

const Layout = ({
  activity,
  modalVoterOpen,
  modalReferralOpen,
  openVoterModal,
  openReferralModal,
  myStats,
  statsLoading,
}) => {
  return (
    <Wrapper>
      {activity.mobile_hero_img_url ? (
        <HeroImage alt={activity.title} src={activity.mobile_hero_img_url} />
      ) : (
        <Title>{activity.title}</Title>
      )}
      <SweepstakesEntries
        activity={activity}
        modalVoterOpen={modalVoterOpen}
        modalReferralOpen={modalReferralOpen}
        openVoterModal={openVoterModal}
        openReferralModal={openReferralModal}
        myStats={myStats}
        statsLoading={statsLoading}
      />
    </Wrapper>
  );
};

Layout.propTypes = {
  activity: PropTypes.object.isRequired,
  modalReferralOpen: PropTypes.bool.isRequired,
  modalVoterOpen: PropTypes.bool.isRequired,
  myStats: PropTypes.shape({
    entries: PropTypes.number,
    recruits: PropTypes.number,
  }).isRequired,
  openReferralModal: PropTypes.func.isRequired,
  openVoterModal: PropTypes.func.isRequired,
  statsLoading: PropTypes.bool.isRequired,
  team: PropTypes.object,
};

Layout.defaultProps = {};

export default Layout;
