import theme from '@web/styles/theme';
import React, { useContext, useMemo, useState } from 'react';

import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { campaigns } from '@admin/api/campaigns';
import { message } from 'antd';

import { AuthContext } from '@web/utils/context';
import SidebarLayout from '../Components/SidebarLayout';
import PlanSelectStep from '../Components/Steps/PlanSelectStep';
import TellUsMoreStep from '../Components/Steps/TellUsMoreStep';
import WelcomeStep from '../Components/Steps/WelcomeStep';
import Loading from '../../../common/Loading';

const StyledForm = styled.form`
  width: 100%;
  height: 100%;

  color: ${theme.colors.black};
`;

const URL_REGEX =
  /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))$/i;

const validationSchema = yup.object().shape({
  first_name: yup.string().nullable().required('First Name is required!'),
  last_name: yup.string().nullable().required('Last Name is required!'),
  org_name: yup.string().nullable().required('Organization Name Required'),
  org_type: yup.string().nullable(),
  phone: yup
    .string()
    .nullable()
    .matches(/^$|^(?:\+1)?\d{1,10}$/, 'Please enter a valid phone number.')
    .required('Phone number is required!'),
  website: yup
    .string()
    .nullable()
    .matches(URL_REGEX, 'Website must be a valid url!')
    .required('Website is required!'),
});

const AdminPlanSelect = () => {
  const { user } = useContext(AuthContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [campaign, setCampaign] = useState();

  const onNext = () => setCurrentStep(currentStep + 1);
  const onBack = () => setCurrentStep(currentStep - 1);

  const initialValues = useMemo(
    () => ({
      first_name: user?.first_name,
      last_name: user?.last_name,
      org_name: '',
      org_type: null,
      phone: user?.phone,
      website: '',
    }),
    [user],
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    campaigns
      .create_new_signup(values)
      .then(({ data: { data } }) => {
        setCampaign(data);
        onNext();
      })
      .catch(err => {
        message.error(err.response.data?.message || 'Error creating campaign');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  React.useEffect(() => {
    document.title = 'Impactive | Campaign Setup';
  }, []);

  if (!user) return null;

  return (
    <SidebarLayout activeIndex={currentStep} showDemoButton>
      {currentStep < 2 && (
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={initialValues}
        >
          {formikProps =>
            formikProps.isSubmitting ? (
              <Loading centered />
            ) : (
              <StyledForm>
                {currentStep === 0 && <WelcomeStep onNext={onNext} {...formikProps} />}
                {currentStep === 1 && (
                  <TellUsMoreStep
                    onNext={formikProps.submitForm}
                    onBack={onBack}
                    {...formikProps}
                  />
                )}
              </StyledForm>
            )
          }
        </Formik>
      )}
      {currentStep === 2 && <PlanSelectStep campaign={campaign} />}
    </SidebarLayout>
  );
};

export default AdminPlanSelect;
