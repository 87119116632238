import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { track } from '@web/services/analytics';
import { user } from '@web/services/api';
import { getButtonText, mobileButtonTextToWeb } from '@web/utils/other';
import { isRegistrationActivity } from '@admin/components/ActivitiesPage/utils';

import CampaignActionCTA from './CampaignActionCTA';

const CampaignActionLink = ({ activity, campaign, completed, joinCampaign, userActivityId }) => {
  const { t } = useTranslation();
  const closed = activity.aasm_state === 'closed';
  const { id: activityId, type: activityType, title: activityTitle } = activity;
  const { id: campaignId, name: campaignName } = campaign;

  function getStartLink() {
    if (isRegistrationActivity(activityType)) {
      return `/campaigns/${campaign.slug}/activities/${activityId}`;
    }

    if (!userActivityId) return;

    const routes = {
      // ShareActivity and WebActivity have their own handlers. Keeping the generic link for other activity types
      // Not true anymore since we're just showing previews of everything
      default: `/activities/${userActivityId}`,
      // IdVotersScreen -> takes user to the search screen, pick the person, fill out outreach report for the voter in VoterInfoScreen
      IdVotersActivity: {
        pathname: '/voters',
        state: { activityId, campaignId, userActivityId },
      },
      // Takes user to testimonial page where they can upload photo/video and add a note.
      TestimonialActivity: `/activities/${userActivityId}/testimonial`,
      // Opens up contacts page with select boxes, pick a template, edit the template, open 1 by 1 or bulk using mailto, manually confirm alert)
      // NOTE: on webapp we are emailing, not texting, so endpoint below will differ
      TextAFriendActivity: `/activities/${userActivityId}/email_friends`,
      // ListMessage - p2p texting action, you get the list of people, you hit send manually until all messages are sent.
      TextAListActivity: `/activities/${userActivityId}/p2p`,
    };

    return routes[activityType] || routes.default;
  }

  const trackClick = async () => {
    track('START_ACTIVITY', {
      activity_id: activityId,
      activity_title: activityTitle,
      activity_type: activityType,
      campaign_id: campaignId,
      campaign_name: campaignName,
    });

    // Only track user activity when one exists
    if (userActivityId) {
      user.userActivityTrackClick(userActivityId);
    }
  };

  let buttonCopy = t('button.start');

  if (isRegistrationActivity(activityType)) {
    buttonCopy = completed ? getButtonText(activityType, completed) : t('button.start');
  } else {
    buttonCopy = completed
      ? getButtonText(activityType, completed)
      : mobileButtonTextToWeb(activity.button_text);
    buttonCopy = closed ? t('button.closed') : buttonCopy;
    buttonCopy = !buttonCopy ? t('button.start') : buttonCopy;
  }

  if (!userActivityId && !isRegistrationActivity(activityType)) {
    return (
      <CampaignActionCTA activity={activity} onClickHandler={joinCampaign}>
        {t('campaign.activity.join_to_take_action')}
      </CampaignActionCTA>
    );
  }

  return (
    <Link
      data-testid="activity-start-button"
      disabled={closed}
      onClick={trackClick}
      to={getStartLink()}
    >
      <CampaignActionCTA activity={activity} completed={completed}>
        {buttonCopy}
      </CampaignActionCTA>
    </Link>
  );
};

CampaignActionLink.propTypes = {
  activity: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  completed: PropTypes.bool.isRequired,
  joinCampaign: PropTypes.func.isRequired,
  userActivityId: PropTypes.number.isRequired,
};

export default CampaignActionLink;
