import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import { Wrapper, Content } from '../styles';
import TitleResults from './TitleResults';
import HowIPlanToVote from './HowIPlanToVote';
import WhoRepresents from './WhoRepresents';
import { capitalize } from '@web/utils/string';
import { fanOut } from '@web/services/api';
import { handleErrorMessage } from '@web/components/FanOutVotingPlan/utils';
import { small800Breakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';

const StyledContent = styled(Content)`
  background: ${({ theme }) => theme.colors.transparent};

  ${small800Breakpoint} {
    padding: 0;
  }
`;

const Results = ({ referrer, VPAnswers, activity, contactId, referralHash, formVPAnswers }) => {
  const [willEarnPoints, setWillEarnPoints] = useState(null);
  const { t } = useTranslation();

  const fanOutSubmission = useCallback(async () => {
    fanOut
      .incrementPoints({
        activity_id: activity.id,
        campaign_id: activity.campaign.id,
        contact_id: contactId,
        email: formVPAnswers.email,
        metric: 'VotingPlan',
        phone: `+${formVPAnswers.phone}`,
        referral_hash: referralHash,
      })
      .then(({ data }) => {
        const referrerName = capitalize(referrer?.fullname || '');
        const text = data.is_points_earned
          ? t('fan_out.voting_plan.results.earned_entries', { referrer: referrerName })
          : t('fan_out.voting_plan.results.not_earned_entries', { referrer: referrerName });
        const notification = data.is_points_earned ? message.success : message.error;

        notification(text);
        setWillEarnPoints(!!data.is_points_earned);
      })
      .catch(err => message.error(handleErrorMessage(err)));
  }, [activity, contactId, formVPAnswers, referralHash, referrer, t]);

  useEffect(() => {
    fanOutSubmission();
  }, [fanOutSubmission]);

  return (
    <Wrapper $maxWidth={'1000px'}>
      <StyledContent>
        <TitleResults referrer={referrer} willEarnPoints={willEarnPoints} />
        <HowIPlanToVote VPAnswers={VPAnswers} activity={activity} />
        <WhoRepresents VPAnswers={VPAnswers} />
      </StyledContent>
    </Wrapper>
  );
};

Results.propTypes = {
  activity: PropTypes.object.isRequired,
  contactId: PropTypes.number.isRequired,
  currentPage: PropTypes.shape({
    nextButtonText: PropTypes.string,
    nextIcon: PropTypes.node,
    prevButtonText: PropTypes.string,
    prevIcon: PropTypes.node,
  }).isRequired,
  first3Questions: PropTypes.array.isRequired,
  formAttributes: PropTypes.object.isRequired,
  formVPAnswers: PropTypes.object.isRequired,
  onNextPage: PropTypes.func.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  partyQuestions: PropTypes.array.isRequired,
  progress: PropTypes.number,
  referralHash: PropTypes.string.isRequired,
  referrer: PropTypes.shape({
    fullname: PropTypes.string,
    id: PropTypes.number,
  }),
  setVPAnswers: PropTypes.func.isRequired,
  sortedActivityQuestions: PropTypes.array.isRequired,
  VPAnswers: PropTypes.array.isRequired,
};

Results.defaultProps = {
  progress: 0,
};

export default Results;
