import * as React from 'react';
import PropTypes from 'prop-types';
import theme from '@web/components/Headcount/theme';

function CheckboxIcon({ checked, hasError, ...rest }) {
  const checkboxColor = hasError ? theme.colors.red : theme.colors.textGray;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x="0.5" y="0.5" width="15" height="15" rx="2.5" fill="white" />
      {checked && (
        <path
          d="M12.2656 4.98438C12.5703 5.26562 12.5703 5.75781 12.2656 6.03906L6.26562 12.0391C5.98438 12.3438 5.49219 12.3438 5.21094 12.0391L2.21094 9.03906C1.90625 8.75781 1.90625 8.26562 2.21094 7.98438C2.49219 7.67969 2.98438 7.67969 3.26562 7.98438L5.72656 10.4453L11.2109 4.98438C11.4922 4.67969 11.9844 4.67969 12.2656 4.98438Z"
          fill="black"
        />
      )}
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="2.5"
        stroke={checkboxColor}
        strokeWidth={hasError ? 2 : 1}
      />
    </svg>
  );
}

CheckboxIcon.propTypes = {
  checked: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
};

CheckboxIcon.defaultProps = {};

export default CheckboxIcon;
