import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Body, Title } from '@web/components/DialerActivity/components/TextStyles';
import HangUpButton from '../HangUpButton';
import StatusBar from '../StatusBar';
import TextWithIcon from '../TextWithIcon';

import { useSelector } from 'react-redux';

const Teal = styled.span`
  &&& {
    color: ${({ theme }) => theme.colors.teal};
    font-family: ${({ theme }) => theme.fonts.bold};
  }
`;

const Content = styled.div`
  padding: 37px 34px;
`;

function ConnectingVoter() {
  const waitTime = useSelector(state => state.dialer.waitTime);

  function getWait() {
    if (Number.isInteger(waitTime) && waitTime > 0) {
      let mins = 0;
      const secs = waitTime % 60;
      if (waitTime > 59) {
        mins = Math.floor(waitTime / 60);
      }
      return `${mins}:${`0${secs}`.slice(-2)}`;
    }
    return false;
  }

  const formattedWaitTime = getWait();

  return (
    <>
      <StatusBar bgColor="yellow">
        <TextWithIcon>Connecting</TextWithIcon>
        <HangUpButton />
      </StatusBar>
      <Content>
        <Title>We are connecting you with the next contact.</Title>
        {formattedWaitTime && (
          <Body>
            Average Wait Time: <Teal>{formattedWaitTime}</Teal>
          </Body>
        )}
      </Content>
    </>
  );
}

ConnectingVoter.propTypes = {};

ConnectingVoter.defaultProps = {};

export default ConnectingVoter;
