import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

// Reducers
import dialer from './dialerReducer';
import oneCampaign from './oneCampaign';
import voterRegistration from './voterRegistration';

const appReducer = combineReducers({
  dialer,
  oneCampaign,
  voterRegistration,
});

const rootReducer = (state, action) => {
  // MTS - This can be used if an auth reducer is added in the future
  // // Reset state when logout is dipatched
  // if (action.type === 'auth/logout') {
  //   const initialState = appReducer(undefined, action);
  //   return initialState;
  // }

  return appReducer(state, action);
};

const store = configureStore({
  devTools: {
    name: `Impactive Web`,
  },
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  ],
  reducer: rootReducer,
});

export default store;
