import { LoadingOutlined } from '@ant-design/icons';
import { replaceDefaultCampaignImage } from '@web/utils/string';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import defaultCampaignProfile from '../../../../assets/images/web/default-campaign-profile.png';
import { track, trackPageViewWithDynamicIDs } from '../../services/analytics';
import { campaigns, teams, users } from '../../services/api';
import MarketingSiteFooter from './MarketingSiteFooter';
import {
  AvatarAndName,
  AvatarDescription,
  AvatarNameAndDescription,
  Banner,
  BannerAndContent,
  BannerLeftSide,
  BannerRightSide,
  BannerTitle,
  Container,
  ElectionDate,
  GetStarted,
  MainContent,
  RedSquareRoundButton,
  StyledAvatar,
  StyledName,
  Title,
  TwoRacesLeft,
} from './styles';

const ScreenTypes = {
  GET_STARTED: 'get_started',
};

const GeorgiaSpecialLanding = props => {
  const { campaignId } = props.match.params; // can be either campaign `id` or `slug`
  const { referral_hash } = queryString.parse(props.location.search);
  const { t } = useTranslation();

  const [campaign, setCampaign] = useState({});
  // keeping this in case we want to enable the team thing later on
  // eslint-disable-next-line no-unused-vars
  const [team, setTeam] = useState();
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [screenType, setScreenType] = useState(ScreenTypes.GET_STARTED);

  useEffect(() => {
    if (screenType) {
      track(`VIEW_GA_RUNOFF_${screenType.toUpperCase()}`);
    }
  }, [screenType]);

  useEffect(() => {
    setLoading(true);
    campaigns
      .getPublicCampaign({ id: campaignId })
      .then(({ data: { data: campaign } }) => {
        setCampaign(campaign);

        trackPageViewWithDynamicIDs({
          fbPixelId: campaign.fb_pixel_id,
          gaTrackingId: campaign.ga_tracking_id,
          snapchatPixelId: campaign.snapchat_pixel_id,
        });

        if (referral_hash) {
          return users
            .getUserByReferralHash(referral_hash)
            .then(({ data: { data: user } }) => {
              return teams.getTeam({ filters: { campaign_id: campaign.id }, id: user.id });
            })
            .then(({ data: { data: team } }) => setTeam(team))
            .catch(error => {
              // Catch error and make this promise always resolved,
              // so we can render this view at least
              console.error('get team data >', error);
            });
        }
      })
      .then(() => setLoading(false));
  }, [campaignId, referral_hash]);

  if (loading) {
    return (
      <div className="flex-row justify-content-center mt-4">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  const shouldRedirect = campaign && !campaign.ga_runoff_enabled;
  if (shouldRedirect) {
    return <Redirect to={`/campaigns/${campaignId}`} />;
  }

  return (
    <Container>
      <BannerAndContent>
        <Banner>
          <BannerLeftSide>
            <BannerTitle>{t('ga_runoff.gotv_ga')}</BannerTitle>

            <AvatarNameAndDescription>
              <AvatarDescription>{t('ga_runoff.organized_by')}</AvatarDescription>

              <AvatarAndName>
                <StyledAvatar
                  src={
                    replaceDefaultCampaignImage(campaign.url_profile_img) || defaultCampaignProfile
                  }
                  alt={campaign.name}
                />
                <StyledName>{campaign.name}</StyledName>
              </AvatarAndName>
            </AvatarNameAndDescription>
          </BannerLeftSide>
          {campaign.url_hero_img && (
            <BannerRightSide src={campaign.url_hero_img} alt={t('ga_runoff.lets_win')} />
          )}
        </Banner>

        {/* {team && (
        <ReferredBy>
          <AvatarNameAndDescription>
            <AvatarDescription>{'referred by'}</AvatarDescription>
            <AvatarAndName>
              <StyledName>{team.name}</StyledName>
              <StyledAvatar src={team.profile_url} alt={team.name} />
            </AvatarAndName>
          </AvatarNameAndDescription>
        </ReferredBy>
      )} */}
        <MainContent>
          <GetStarted>
            <Title>{t('ga_runoff.georgia_runoff_election')}</Title>
            <ElectionDate>{t('ga_runoff.election_date')}</ElectionDate>
            <TwoRacesLeft>{t('ga_runoff.two_races_left')}</TwoRacesLeft>
            <TwoRacesLeft>{t('ga_runoff.how_to_participate')}</TwoRacesLeft>
            <Link to={`/campaigns/${campaignId}/georgia_runoff/options`}>
              <RedSquareRoundButton type="primary" children={'get started'} />
            </Link>
          </GetStarted>
        </MainContent>
      </BannerAndContent>

      <MarketingSiteFooter />
    </Container>
  );
};

GeorgiaSpecialLanding.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default GeorgiaSpecialLanding;
