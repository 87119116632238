import { Avatar } from 'antd';
import { Input } from 'formik-antd';

import styled from 'styled-components';
import { media } from '../../styles/theme';
import ImpactiveButton from '../ImpactiveButton';

export const MiddleContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;

  ${media.ns} {
    flex-direction: row;
  }
`;

export const BannerAndContent = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1 0 auto;
`;

export const OutvoteLogo = styled.div`
  padding-left: 20px;
  padding-bottom: 20px;
  padding-top: 30px;
`;

export const AvatarNameAndDescription = styled.div`
  padding-top: 20px;
  padding-left: 20px;

  ${media.ns} {
    padding-left: 150px;
  }
`;

export const AvatarDescription = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.trueWhite};
  font-family: ${({ theme }) => theme.fonts.semibold};
  opacity: 90%;
  font-size: 12px;
  padding-bottom: 10px;
  text-align: center;
  ${media.ns} {
    text-align: left;
  }
`;

export const AvatarAndName = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  justify-content: center;

  ${media.ns} {
    justify-content: left;
  }
`;

export const StyledName = styled.div`
  color: ${({ theme }) => theme.colors.trueWhite};
  font-family: ${({ theme }) => theme.fonts.bold};
  padding-left: 15px;
  ${media.ns} {
    font-size: 20px;
  }
`;

export const StyledAvatar = styled(Avatar).attrs({ size: 60 })``;

export const BannerLeftSide = styled.div`
  background-color: ${({ theme }) => theme.colors.deepBlue};
  flex: 1;
  ${media.ns} {
    padding-right: 30px;
  }
`;

export const BannerRightSide = styled.img`
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  ${media.ns} {
    max-width: 600px;
  }
`;

export const BannerTitle = styled.div`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.trueWhite};

  font-size: 30px;
  padding-vertical: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;

  ${media.ns} {
    font-size: 36px;
    padding-vertical: 20px;
    padding-left: 150px;
    padding-right: 20px;
    text-align: left;
  }
`;

export const ReferredBy = styled.div``;

export const Title = styled.div`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.black};
  padding-bottom: 15px;
  font-size: 28px;
  text-align: center;

  ${media.ns} {
    font-size: 32px;
  }
`;

export const ElectionDate = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.gray};
  letter-spacing: 1.1px;
  font-size: 22px;
  padding-bottom: 15px;
`;

export const TwoRacesLeft = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
  padding-bottom: 15px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;

  // ${media.lg} {
  @media screen and (min-width: 1025px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const OptionColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  padding-left: 40px;
  padding-right: 40px;
`;

export const OptionDescription = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  padding-bottom: 20px;
`;

export const SquareRoundButton = styled(ImpactiveButton)`
  font-size: 24px;
  line-height: 1.2;
  border-radius: 15px;
  min-height: 50px;
  margin-bottom: 22px;
  width: 100%;

  ${media.ns} {
    width: 300px;
  }
`;

export const RedSquareRoundButton = styled(SquareRoundButton)`
  background: ${({ theme }) => theme.colors.red};
`;

export const GetStarted = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  width: 100%;

  padding: 20px 25px;

  ${media.ns} {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

export const ShowOptions = styled.div``;

export const SelectAnOption = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 20px;
  padding-bottom: 30px;
  max-width: 500px;
  text-align: center;

  padding-left: 20px;
  padding-right: 20px;

  ${media.ns} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const SubHeader = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 24px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  padding-bottom: 15px;
  text-align: center;
`;

export const CollectPhoneDescription = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 20px;
  max-width: 500px;
  text-align: center;
  padding-bottom: 15px;
`;

export const ThankYouSection = styled.div``;

export const ThreeXImpact = styled(SubHeader)`
  padding-top: 20px;
  padding-bottom: 5px;
`;

export const ThankYouDescription = styled(CollectPhoneDescription)`
  padding-bottom: 10px;
`;

export const MainContent = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  background: white;
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonsRow = styled.div`
  margin-top: 0.5rem;
  margin-bottom: -10px;

  > * {
    margin-bottom: 10px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  ${({ fullWithOnMobile }) =>
    fullWithOnMobile &&
    `
    @media screen and (max-width: 767px) {
      > *, button {
        display: block;
        width: 100%;
      }
    }
  `}
`;

export const ShareSection = styled.div``;

export const ShareButtonInner = styled(ImpactiveButton).attrs({ forwardedAs: 'span' })`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.label`
  && {
    font-family: ${({ theme }) => theme.fonts.semibold};
    color: ${({ theme }) => theme.colors.black};
    letter-spacing: 1.1px;
    font-size: 24px;
  }
`;

export const StyledInput = styled(Input)`
  && {
    font-size: 24px;
  }
`;

export const TermsAndConditions = styled.div`
  margin-top: 40px;
  font-size: 15px;
  text-align: center;
`;
