import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// NOTE: We need history prop until we switch to react router v5
const BackButton = ({ history, children, className }) => {
  const { t } = useTranslation();

  // Use transparent button wrapper if children were passed
  if (children) {
    return (
      <Button type="link" className={className} onClick={history.goBack}>
        {children}
      </Button>
    );
  }

  // Otherwise render default button
  return (
    <Button type="default" className={className} onClick={history.goBack}>
      <LeftOutlined />
      {t('common.back')}
    </Button>
  );
};

BackButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

// TODO: remove withRouter HOC in favour of hook for router v5.x
export default withRouter(BackButton);
