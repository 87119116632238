import { string } from 'prop-types';
import React from 'react';

const Drawer = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        stroke="#23293E"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M4.125 7.125h15.75M4.125 12h15.75m-15.75 4.875h15.75"
      />
    </svg>
  );
};

Drawer.propTypes = {
  fill: string,
};

Drawer.defaultProps = {};

export default Drawer;
