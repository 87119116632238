import { LoadingOutlined, NumberOutlined } from '@ant-design/icons';
import { Alert, Spin, Row, Col } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Checkbox } from 'formik-antd';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import i18n from 'web/components/i18n';
import * as yup from 'yup';
import { ContactRegistrationStatusTypes as RegistrationStatusTypes } from '../../constants';
import { track, trackPageViewWithDynamicIDs } from '../../services/analytics';
import { campaigns, teams, users } from '../../services/api';
import ImpactiveButton from '../ImpactiveButton';
import VoterRegistrationTemplate from '../templates/VoterRegistrationTemplate';
import AddressInput from './AddressInput';
import { FormContainer, Heading, CheckboxWrapper, SubmitWrapper } from './styles';

const AddressFormSchema = yup.object().shape({
  address: yup.string().required(i18n.t('confirm_address.validations.address')).nullable(),
  city: yup.string().nullable(),
  county_name: yup.string().nullable(),
  mail_address: yup.string().when('same_address', {
    is: true,
    otherwise: yup.string().required(i18n.t('confirm_address.validations.address')).nullable(),
    then: yup.string().nullable(),
  }),
  mail_city: yup.string().nullable(),
  mail_county_name: yup.string().nullable(),
  mail_state_abbrev: yup.string().nullable(),
  mail_unit_number: yup.string().nullable(),
  mail_zip_code: yup.string().nullable(),
  same_address: yup.bool(),
  state_abbrev: yup.string().nullable(),
  unit_number: yup.string().nullable(),
  zip_code: yup.string().nullable(),
});

const ConfirmAddress = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { campaignId, contactId } = props.match.params; // can be either campaign `id` or `slug`
  const { h, referral_hash } = queryString.parse(props.location.search);

  const [contact, setContact] = useState({});
  const [campaign, setCampaign] = useState({});
  const [team, setTeam] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    track('VIEW_CONFIRM_ADDRESS');
    setLoading(true);
    Promise.all([
      campaigns.getPublicCampaign({ id: campaignId }),
      campaigns.getMyRecord({ h, id: contactId }),
    ])
      .then(([campaignResponse, contactResponse]) => {
        const campaign = campaignResponse.data.data;
        setCampaign(campaign);
        setContact(contactResponse.data);

        trackPageViewWithDynamicIDs({
          fbPixelId: campaign.fb_pixel_id,
          gaTrackingId: campaign.ga_tracking_id,
          snapchatPixelId: campaign.snapchat_pixel_id,
        });

        if (referral_hash) {
          return users
            .getUserByReferralHash(referral_hash)
            .then(({ data: { data: user } }) => {
              return teams.getTeam({ filters: { campaign_id: campaign.id }, id: user.id });
            })
            .then(({ data: { data: team } }) => setTeam(team))
            .catch(error => {
              // Catch error and make this promise always resolved,
              // so we can render this view at least
              console.error('get team data >', error);
            });
        }

        return;
      })
      .then(() => setLoading(false));
  }, [campaignId, contactId, h, referral_hash]);

  const submitAddress = useCallback(
    ({ same_address, ...rest }) => {
      track('SUBMIT_ADDRESS');
      const address_info = { ...rest };
      if (same_address) {
        address_info.mail_address = address_info.address;
        address_info.mail_unit_number = address_info.unit_number;
        address_info.mail_county_name = address_info.county_name;
        address_info.mail_state_abbrev = address_info.state_abbrev;
        address_info.mail_city = address_info.city;
        address_info.mail_zip_code = address_info.zip_code;
      }
      campaigns
        .confirmMyAddress({ address_info, campaign_id: campaignId, h, id: contactId })
        .then(() => {
          const query = queryString.stringify({ h, referral_hash });
          const url = `/campaigns/${campaignId}/contacts/${contactId}/voting_information?${query}`;
          history.push(encodeURI(url));
        });
    },
    [campaignId, contactId, h, referral_hash, history],
  );

  if (loading) {
    return (
      <div className="flex-row justify-content-center mt-4">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  let address,
    unit_number,
    city,
    state_abbrev,
    county_name,
    zip_code,
    mail_address,
    mail_unit_number,
    mail_city,
    mail_state_abbrev,
    mail_county_name,
    mail_zip_code;

  const title =
    contact.registration_status === RegistrationStatusTypes.UNREGISTERED
      ? t('confirm_address.unregistered.title')
      : t('confirm_address.title');

  return (
    <VoterRegistrationTemplate
      campaign={campaign}
      team={team}
      stepCount={3}
      currentStep={2}
      title={title}
      instruction={t('confirm_address.description')}
      data-testid="confirm-address"
    >
      <FormContainer>
        <Formik
          initialValues={{
            address,
            city,
            county_name,
            mail_address,
            mail_city,
            mail_county_name,
            mail_state_abbrev,
            mail_unit_number,
            mail_zip_code,
            same_address: true,
            state_abbrev,
            unit_number,
            zip_code,
          }}
          validationSchema={AddressFormSchema}
          onSubmit={submitAddress}
          render={({ values, setFieldValue, isSubmitting, isValid, dirty, submitCount }) => (
            <Form>
              <Heading>{t('confirm_address.registration_address')}</Heading>
              <Row gutter={30}>
                <Col span={24} md={12}>
                  <FormItem name="address">
                    <label htmlFor="address">{t('confirm_address.labels.address')}</label>
                    <AddressInput
                      defaultValue={address}
                      data-testid={'test-autocomplete'}
                      onChange={address => {
                        setFieldValue('address', address.address);
                        setFieldValue('city', address.city);
                        setFieldValue('zip_code', address.zip_code);
                        setFieldValue('county_name', address.county_name);
                        setFieldValue('state_abbrev', address.state_abbrev);
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={24} md={12}>
                  <FormItem name="unit_number">
                    <label htmlFor="unit_number">{t('confirm_address.labels.unit_number')}</label>
                    <Input
                      name="unit_number"
                      id="unit_number"
                      placeholder="#"
                      addonBefore={<NumberOutlined />}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Heading>{t('confirm_address.mailing_address')}</Heading>
              <CheckboxWrapper>
                <Checkbox name="same_address">{t('confirm_address.labels.same_address')}</Checkbox>
              </CheckboxWrapper>
              {!values.same_address && (
                <Row gutter={30}>
                  <Col span={24} md={12}>
                    <FormItem name="mail_address">
                      <label htmlFor="mail_address">{t('confirm_address.labels.address')}</label>
                      <AddressInput
                        defaultValue={mail_address}
                        onChange={address => {
                          setFieldValue('mail_address', address.address);
                          setFieldValue('mail_city', address.city);
                          setFieldValue('mail_zip_code', address.zip_code);
                          setFieldValue('mail_county_name', address.county_name);
                          setFieldValue('mail_state_abbrev', address.state_abbrev);
                        }}
                      />
                    </FormItem>
                  </Col>
                  <Col span={24} md={12}>
                    <FormItem name="mail_unit_number">
                      <label htmlFor="mail_unit_number">
                        {t('confirm_address.labels.unit_number')}
                      </label>
                      <Input
                        name="mail_unit_number"
                        id="mail_unit_number"
                        placeholder="#"
                        addonBefore={<NumberOutlined />}
                      />
                    </FormItem>
                  </Col>
                </Row>
              )}
              <SubmitWrapper>
                <ImpactiveButton
                  data-testid="address-submit"
                  type="submit"
                  disabled={isSubmitting || (!isValid && (dirty || submitCount > 0))}
                >
                  {t('confirm_address.update_address')}
                </ImpactiveButton>
              </SubmitWrapper>
              <Alert
                style={{ marginTop: 30 }}
                message="Submitting this form will not make any changes to your voter registration status, it will only be used by Impactive to help get you the right information you need to vote."
                type="info"
                showIcon
              />
            </Form>
          )}
        />
      </FormContainer>
    </VoterRegistrationTemplate>
  );
};

ConfirmAddress.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string.isRequired,
      contactId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ConfirmAddress;
