import * as React from 'react';
import styled from 'styled-components';

import Text from '@web/components/Headcount/components/Text';

const ErrorTextWrapper = styled.div`
  line-height: 1; // antd override
  margin-top: 0.25rem;
`;

function ErrorText(props) {
  return (
    <ErrorTextWrapper>
      <Text as="span" color="red" variant="small" {...props} />
    </ErrorTextWrapper>
  );
}

ErrorText.propTypes = {};

ErrorText.defaultProps = {};

export default ErrorText;
