import democratIconImage from '@assets/images/democrat-icon-active.png';
import frequentVoterEmoji from '@assets/images/frequent-voter-emoji.png';
import inDistrictIconImage from '@assets/images/in-district-icon-active.png';
import infrequentVoterEmoji from '@assets/images/infrequent-voter-emoji.png';
import republicanIconImage from '@assets/images/republican-icon-active.png';
import superVoterEmoji from '@assets/images/super-voter-emoji.png';
import inSwingIconImage from '@assets/images/swing-district-icon-active.png';
import unknownVoterIcon from '@assets/images/unknown-voter-icon.png';
import { Button, message, List } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { users } from 'web/services/api';
import IdVotersModal from '../IdVotersActivity/IdVotersModal';
import VoterMatchModal from '../IdVotersActivity/VoterMatchModal';

const { Item } = List;

const ContactName = styled.div`
  padding-left: 1em;
`;

const Flags = styled.small`
  padding-left: 2em;
`;

const Flag = styled.span`
  padding-left: 1em;
`;

const Checkbox = styled.input`
  margin: 1em;
`;

const ItemContent = styled.div`
  padding-left: 10px;
  display: flex;
`;

const StyledButton = styled(Button)`
  &&& {
    font-family: ${({ theme }) => theme.fonts.semibolditalic};
  }
`;

const resolveFrequencyIcon = freqency => {
  const sourceByFrequency = {
    'Frequent Voter': frequentVoterEmoji,
    'Infrequent Voter': infrequentVoterEmoji,
    'Never Voted': infrequentVoterEmoji,
    'Super Voter': superVoterEmoji,
    Unregistered: infrequentVoterEmoji,
  };

  return sourceByFrequency[freqency] || unknownVoterIcon;
};

const resolveAffilitationIcon = party => {
  const sourceByAffilitation = {
    Democrat: democratIconImage,
    Republican: republicanIconImage,
  };

  return sourceByAffilitation[party] || null;
};

const ContactListItem = ({
  contact: {
    id,
    first_name: firstName,
    last_name: lastName,
    full_name: fullName,
    email,
    party_affiliation: party,
    voter_frequency: frequency,
    in_district: inDistrict,
    in_swing_district: inSwingDistrict,
    selected_voterbase_id: selectedVoterbaseId,
    invited: _invited,
  },
  hideCheckbox,
  onClickHandler,
  selected = false,
  withSelection = false,
  noLabels = false,
}) => {
  const { t } = useTranslation();
  const [invited, setInvited] = useState(_invited);
  const [showIdVotersModal, setShowIdVotersModal] = useState(false);

  const [showVoterMatchModal, setShowVoterMatchModal] = useState(false);
  const [voter, setVoter] = useState(null);

  const handleRecruitClick = async () => {
    if (!invited) {
      await users
        .sendInvite(id)
        .then(res => {
          setInvited(true);
        })
        .catch(() => {
          message.error(t('contacts.failed_to_invite'));
        });
    }
  };

  // When a contact is clicked that does not have a match we go to the IdVoter screen
  // to search for them with prefilled name parameters
  const SearchButton = (
    <StyledButton
      type="link"
      onClick={() => {
        setShowIdVotersModal(true);
      }}
    >
      {t('contacts.search')}
    </StyledButton>
  );

  // When a contact is clicked that has a match to the voter file, we should go to the voter info page.
  const VoterViewButton = (
    <StyledButton
      type="link"
      onClick={() => {
        setShowVoterMatchModal(true);
      }}
    >
      {t('contacts.see_file')}
    </StyledButton>
  );

  const searchOrSeeFileLink = selectedVoterbaseId ? VoterViewButton : SearchButton;
  const partyAffiliationIcon = resolveAffilitationIcon(party);
  const nameToShow =
    firstName || lastName
      ? `${firstName ? firstName.trim() : ''} ${lastName ? lastName.trim() : ''}`
      : email;

  if (!nameToShow) return <></>;

  return (
    <>
      <Item
        // TODO: Contact should link to FriendVoterReport if identified (when is this?)
        onClick={onClickHandler}
        actions={
          withSelection
            ? [] // no exterenal actions in selection mode
            : [
                searchOrSeeFileLink,
                // MTS - Temporarily removing
                <Button
                  type="link"
                  disabled={invited}
                  onClick={handleRecruitClick}
                  style={{ display: 'none' }}
                >
                  {invited ? t('contacts.invited') : t('contacts.invite')}
                </Button>,
              ]
        }
      >
        <ItemContent>
          {!hideCheckbox && <Checkbox type="checkbox" readOnly checked={selected} />}
          <img height={22} src={resolveFrequencyIcon(frequency)} alt="frequency" />
          <ContactName>{nameToShow}</ContactName>
          {withSelection && (
            <Flags>
              {!noLabels && partyAffiliationIcon && (
                <Flag>
                  <img
                    src={partyAffiliationIcon}
                    height={16}
                    width={16}
                    alt="party-affiliation-flag"
                  />
                </Flag>
              )}
              {inDistrict && (
                <Flag>
                  <img src={inDistrictIconImage} height={16} width={16} alt="in-district-flag" />
                </Flag>
              )}
              {inSwingDistrict && (
                <Flag>
                  <img src={inSwingIconImage} height={16} width={16} alt="in-swing-district-flag" />
                </Flag>
              )}
            </Flags>
          )}
          <div style={{ flex: 1 }}></div>
        </ItemContent>
      </Item>
      {showIdVotersModal && (
        <IdVotersModal
          setVisibleFn={setShowIdVotersModal}
          setShowVoterMatchModal={setShowVoterMatchModal}
          setVoter={setVoter}
          isFriend
          contact_id={id}
          firstName={firstName}
          lastName={lastName}
        />
      )}

      {showVoterMatchModal && (
        <VoterMatchModal
          setVisibleFn={setShowVoterMatchModal}
          setShowIdVotersModal={setShowIdVotersModal}
          isFriend
          contactId={id}
          voter={voter}
          voterbaseId={selectedVoterbaseId}
        />
      )}
    </>
  );
};

ContactListItem.propTypes = {
  contact: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    full_name: PropTypes.string,
    id: PropTypes.number,
    in_district: PropTypes.bool,
    in_swing_district: PropTypes.bool,
    invited: PropTypes.bool,
    last_name: PropTypes.string,
    party_affiliation: PropTypes.string,
    selected_voterbase_id: PropTypes.string,
    voter_frequency: PropTypes.string,
  }).isRequired,
  hideCheckbox: PropTypes.bool,
  noLabels: PropTypes.bool,
  onClickHandler: PropTypes.func,
  selected: PropTypes.bool,
  withSelection: PropTypes.bool,
};

ContactListItem.defaultProps = {
  hideCheckbox: false,
  noLabels: false,
  onClickHandler: undefined,
  selected: false,
  withSelection: false,
};

export default ContactListItem;
