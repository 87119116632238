const icons = {
  ballotBox: {
    d: 'M0.875 16.5625C0.875 17.5117 1.61328 18.25 2.5625 18.25H19.4375C20.3516 18.25 21.125 17.5117 21.125 16.5625V12.625H0.875V16.5625ZM16.625 1.09375C16.625 0.636719 16.2383 0.25 15.7812 0.25H6.21875C5.72656 0.25 5.375 0.636719 5.375 1.09375V8.125H16.625V1.09375ZM20 5.875H17.75V8.125C17.75 8.75781 17.2227 9.25 16.625 9.25H5.375C4.74219 9.25 4.25 8.75781 4.25 8.125V5.875H2C1.36719 5.875 0.875 6.40234 0.875 7V11.5H21.0898H21.125V7C21.125 6.40234 20.5977 5.875 20 5.875Z',
    viewBox: '0 0 22 19',
  },
  house: {
    d: 'M21.0898 9.25C21.0898 9.88281 20.5625 10.375 19.9648 10.375H18.8398L18.875 16C18.875 16.1055 18.875 16.2109 18.875 16.2812V16.8438C18.875 17.6523 18.2422 18.25 17.4688 18.25H16.9062C16.8359 18.25 16.8008 18.25 16.7656 18.25C16.7305 18.25 16.6602 18.25 16.625 18.25H14.6562C13.8477 18.25 13.25 17.6523 13.25 16.8438V13.75C13.25 13.1523 12.7227 12.625 12.125 12.625H9.875C9.24219 12.625 8.75 13.1523 8.75 13.75V16.8438C8.75 17.6523 8.11719 18.25 7.34375 18.25H5.375C5.30469 18.25 5.26953 18.25 5.19922 18.25C5.16406 18.25 5.12891 18.25 5.09375 18.25H4.53125C3.72266 18.25 3.125 17.6523 3.125 16.8438V12.9062C3.125 12.9062 3.125 12.8711 3.125 12.8359V10.375H2C1.36719 10.375 0.875 9.88281 0.875 9.25C0.875 8.93359 0.980469 8.65234 1.22656 8.40625L10.2266 0.566406C10.4727 0.320312 10.7539 0.25 11 0.25C11.2461 0.25 11.5273 0.355469 11.7383 0.53125L20.7031 8.40625C20.9844 8.65234 21.125 8.93359 21.0898 9.25Z',
    viewBox: '0 0 22 19',
  },
  person: {
    d: 'M8 9.25C10.4609 9.25 12.5 7.24609 12.5 4.75C12.5 2.28906 10.4609 0.25 8 0.25C5.50391 0.25 3.5 2.28906 3.5 4.75C3.5 7.24609 5.50391 9.25 8 9.25ZM9.75781 10.9375H6.20703C2.83203 10.9375 0.125 13.6797 0.125 17.0547C0.125 17.7227 0.652344 18.25 1.32031 18.25H14.6445C15.3125 18.25 15.875 17.7227 15.875 17.0547C15.875 13.6797 13.1328 10.9375 9.75781 10.9375Z',
    viewBox: '0 0 16 19',
  },
  stopwatch: {
    d: 'M9.6875 0.25C10.2852 0.25 10.8125 0.777344 10.8125 1.375C10.8125 2.00781 10.2852 2.5 9.6875 2.5H9.125V3.73047C10.4258 3.94141 11.6211 4.46875 12.6055 5.27734L13.3789 4.53906C13.8008 4.08203 14.5391 4.08203 14.9609 4.53906C15.418 4.96094 15.418 5.69922 14.9609 6.12109L14.1172 6.96484C14.8555 8.125 15.3125 9.49609 15.3125 10.9375C15.3125 14.9805 12.0078 18.25 8 18.25C3.95703 18.25 0.6875 14.9805 0.6875 10.9375C0.6875 7.28125 3.35938 4.25781 6.875 3.73047V2.5H6.3125C5.67969 2.5 5.1875 2.00781 5.1875 1.375C5.1875 0.777344 5.67969 0.25 6.3125 0.25H9.6875ZM8.84375 7C8.84375 6.54297 8.45703 6.15625 8 6.15625C7.50781 6.15625 7.15625 6.54297 7.15625 7V11.5C7.15625 11.9922 7.50781 12.3438 8 12.3438C8.45703 12.3438 8.84375 11.9922 8.84375 11.5V7Z',
    viewBox: '0 0 16 19',
  },
};

export default icons;
