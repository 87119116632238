import i18n from '@web/components/i18n';

export const addReportBreadcrumb = page => {
  const breadcrumb = [
    {
      name: i18n.t('add_report.find_voter.lookup_contact'),
      url: `/add-report`,
    },
    page === 'viewVoter' && {
      name: i18n.t('add_report.fill_report.header'),
      url: '/add-report/view_voter',
    },
    page === 'addManually' && {
      name: i18n.t('add_report.buttons.add_manually'),
      url: '/add-report/new-voter-report',
    },
  ];

  return breadcrumb.filter(Boolean);
};
