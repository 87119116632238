import React from 'react';

const UserFilled = ({ ...props }) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 8C9.13047 8 10.8571 6.23735 10.8571 4.0625C10.8571 1.88765 9.13047 0.125 7 0.125C4.86953 0.125 3.14286 1.88765 3.14286 4.0625C3.14286 6.23735 4.86953 8 7 8ZM9.7 8.98438H9.19676C8.52779 9.29814 7.78348 9.47656 7 9.47656C6.21652 9.47656 5.47522 9.29814 4.80324 8.98438H4.3C2.06406 8.98438 0.25 10.8362 0.25 13.1187V14.3984C0.25 15.2136 0.897879 15.875 1.69643 15.875H12.3036C13.1021 15.875 13.75 15.2136 13.75 14.3984V13.1187C13.75 10.8362 11.9359 8.98438 9.7 8.98438Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UserFilled;
