import styled from 'styled-components';

const SuggestionMessage = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.light};
  color: black;
  margin-top: 2rem;
  padding: 1rem;
  border-top: 2px solid #a9a9a9;
  border-bottom: 2px solid #a9a9a9;
`;

export default SuggestionMessage;
