import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

const Dot = styled.div`
  background-color: ${({ $color, theme }) => theme.colors[$color]};
  border-radius: ${({ $size }) => $size / 2}px;
  height: ${({ $size }) => $size}px;
  margin: 0 ${({ $size }) => $size / 4}px;
  width: ${({ $size }) => $size}px;
`;

const StyledDotLoader = styled.div`
  display: inline-flex;
  div {
    animation-duration: 1.2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: ${blink};
  }
  div:nth-child(2) {
    animation-delay: 0.2s;
  }
  div:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

function DotLoader({ centered, centeredStyle, color, size, ...rest }) {
  const dots = ['', '', ''];

  const dotLoader = (
    <StyledDotLoader {...rest}>
      {dots.map((el, i) => (
        <Dot $color={color} key={i} $size={size} />
      ))}
    </StyledDotLoader>
  );

  if (centered) {
    return <Centered centeredStyle={centeredStyle}>{dotLoader}</Centered>;
  }

  return dotLoader;
}

DotLoader.propTypes = {
  centered: PropTypes.bool,
  centeredStyle: PropTypes.object,
  color: PropTypes.string,
  size: PropTypes.number,
};

DotLoader.defaultProps = {
  centered: false,
  centeredStyle: {},
  color: 'white',
  size: 8,
};

export default DotLoader;
