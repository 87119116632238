import styled from 'styled-components';
import { media } from '../../styles/theme';

const MaxWidthContainer = styled.div`
  max-width: 1012px;
  padding: 16px 16px;
  width: 100%;
  ${media.ns} {
    margin: 0 auto;
    padding: 16px 32px;
  }
`;

export default MaxWidthContainer;
