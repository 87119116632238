import { LeftOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useModal } from '../../../../hooks';
import ImpactiveButton from '../../../ImpactiveButton';
import ActivityCompletedModal from '../../ActivityCompletedModal';
import BulkSendingScreen from './BulkSendingScreen';
import GmailApproval from './GmailApproval';
import IndividualSendingScreen from './IndividualSendingScreen';
import { BackLink } from './styles';

const SendingScreen = ({ userActivity, isBulk, onGoBack, ...rest }) => {
  const { t } = useTranslation();
  const [googleUser, setGoogleUser] = React.useState(null);
  const [skippedInAppEmail, setSkippedInAppEmail] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const [openActivityCompletedModal, closeActivityCompletedModal] =
    useModal(ActivityCompletedModal);

  const handleSkip = React.useCallback(() => {
    setSkippedInAppEmail(true);
  }, []);

  const handleCompleted = React.useCallback(() => {
    setCompleted(true);
  }, []);

  const handleShowActivityCompletedModal = React.useCallback(() => {
    openActivityCompletedModal({
      activityId: userActivity.activity.id,
      activityTitle: userActivity.activity.title,
      campaignId: userActivity.activity.campaign.id,
      campaignName: userActivity.activity.campaign.name,
      onModalClosedCallback: closeActivityCompletedModal,
      userActivityId: userActivity.id,
    });
  }, [userActivity, openActivityCompletedModal, closeActivityCompletedModal]);

  // Allow a user to choose to authorize Impactive to send emails on behalf of
  if (!googleUser && !skippedInAppEmail) {
    return (
      <GmailApproval googleUser={googleUser} onSkip={handleSkip} setGoogleUser={setGoogleUser} />
    );
  }

  if (completed) {
    return (
      <div className="flex-col align-items-center">
        <h3 className="text-center my-4">{t('activity.f2f.emailing.way_to_go_text')}</h3>
        <Link
          to={{
            pathname: '/messages',
          }}
        >
          <ImpactiveButton>{t('activity.f2f.emailing.fill_reports')}</ImpactiveButton>
        </Link>
      </div>
    );
  }

  const Component = isBulk ? BulkSendingScreen : IndividualSendingScreen;
  return (
    <>
      <BackLink onClick={onGoBack}>
        <LeftOutlined /> {t('activity.f2f.emailing.back')}
      </BackLink>
      <Component
        {...rest}
        userActivity={userActivity}
        googleUser={googleUser}
        skippedInAppEmail={skippedInAppEmail}
        onCompleted={handleCompleted}
        onShowActivityCompletedModal={handleShowActivityCompletedModal}
      />
    </>
  );
};

SendingScreen.propTypes = {
  isBulk: PropTypes.bool,
  onGoBack: PropTypes.func.isRequired,
  userActivity: PropTypes.shape({
    activity: PropTypes.shape({
      campaign: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
    completed: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default SendingScreen;
