import * as React from 'react';
import PropTypes from 'prop-types';

function Lock(props) {
  return (
    <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11 6.25H9.9375V2.75C9.9375 1.64531 9.04219 0.75 7.9375 0.75H4.0625C2.95781 0.75 2.0625 1.64531 2.0625 2.75V6.25H1C0.723437 6.25 0.5 6.47344 0.5 6.75V12.75C0.5 13.0266 0.723437 13.25 1 13.25H11C11.2766 13.25 11.5 13.0266 11.5 12.75V6.75C11.5 6.47344 11.2766 6.25 11 6.25ZM6.4375 9.95312V10.7812C6.4375 10.85 6.38125 10.9062 6.3125 10.9062H5.6875C5.61875 10.9062 5.5625 10.85 5.5625 10.7812V9.95312C5.43352 9.86052 5.33725 9.72938 5.28755 9.57859C5.23784 9.42779 5.23727 9.26511 5.28591 9.11396C5.33455 8.96281 5.42989 8.831 5.55821 8.73749C5.68654 8.64398 5.84122 8.5936 6 8.5936C6.15878 8.5936 6.31346 8.64398 6.44179 8.73749C6.57011 8.831 6.66545 8.96281 6.71409 9.11396C6.76273 9.26511 6.76216 9.42779 6.71245 9.57859C6.66275 9.72938 6.56648 9.86052 6.4375 9.95312ZM8.8125 6.25H3.1875V2.75C3.1875 2.26719 3.57969 1.875 4.0625 1.875H7.9375C8.42031 1.875 8.8125 2.26719 8.8125 2.75V6.25Z"
        fill="#B7B7B7"
      />
    </svg>
  );
}

Lock.propTypes = {
  height: PropTypes.number,
};

Lock.defaultProps = {
  height: 14,
};

export default Lock;
