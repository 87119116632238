import { Button, message } from 'antd';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { user as userAPI, campaigns as campaignsAPI } from 'web/services/api';
import { AuthContext } from 'web/utils/context';
import Text from '../common/Text';

const Unlock = () => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(AuthContext);
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    if (user && user.locked_campaign_id) {
      campaignsAPI.getCampaign({ id: user.locked_campaign_id }).then(resp => {
        setCampaign(_.get(resp, 'data.data'));
      });
    }
  }, [user]);

  const name = _.get(campaign, 'name', 'a campaign');

  return (
    <>
      <h3>{t('settings.unlock.header')}</h3>

      <Text>{t('settings.unlock.description', { name })}</Text>

      <Button
        type="primary"
        onClick={e => {
          e.preventDefault();
          userAPI
            .unlockAccount()
            .then(() => {
              message.success(t('settings.unlock.success_message'));
              return userAPI.getProfile();
            })
            .then(data => {
              const user = _.get(data, 'data');
              if (user) {
                setUser(user);
              }
            })
            .catch(() => {
              message.error(t('settings.unlock.error_message'));
            });
        }}
      >
        {t('settings.unlock.unlock_account')}
      </Button>
    </>
  );
};

export default Unlock;
