import * as React from 'react';
// import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import sendError from '@web/utils/sendError';
import styled from 'styled-components';

import InfoBox from '../InfoBox';

import { useSelector } from 'react-redux';

const Bold = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

function SessionEnded() {
  const { sessionStartTime, totalCalls } = useSelector(state => state?.dialer);
  const plural = totalCalls !== 1;

  function hourStr(h) {
    if (h === 0) {
      return '';
    }
    if (h === 1) {
      return `1 hour and `;
    }
    return `${h} hours and `;
  }

  function hms(secs) {
    const hours = Math.floor(secs / (60 * 60));

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);
    const obj = {
      h: hours,
      m: `0${minutes.toString()}`.slice(-2),
      s: `0${seconds.toString()}`.slice(-2),
    };
    return obj;
  }

  function getTime() {
    try {
      const diffMs = dayjs().diff(sessionStartTime);
      const hmsObj = hms(diffMs / 1000);
      const hours = parseInt(hmsObj.h, 10);
      const mins = parseInt(hmsObj.m, 10);
      return `${hourStr(hours)}${mins} minutes`;
    } catch (err) {
      sendError('Dialer Web - Error retrieving time in SessionEnded', err);
    }
  }

  const text = (
    <>
      You were connected to{' '}
      <Bold>
        {totalCalls} {plural ? 'people' : 'person'}
      </Bold>{' '}
      for a total time of <Bold>{getTime()}</Bold>.
    </>
  );

  return (
    <InfoBox
      buttonProps={{ children: 'Back to Home', to: '/frontline' }}
      text={text}
      title="Your session has ended"
    />
  );
}

SessionEnded.propTypes = {};

SessionEnded.defaultProps = {};

export default SessionEnded;
