import React, { useEffect, useState } from 'react';
import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import Loading from '../common/Loading';
import MaxWidthContainer from '../common/MaxWidthContainer';
import Metrics from './Metrics';
import NextRank from './NextRank';
import Profile from './Profile';
import Section from './Section';
// import UserTeamsSection from './UserTeamsSection';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { track } from '../../services/analytics';
import { user } from '../../services/api';
import { media } from '../../styles/theme';
import SyncContacts from './SyncContact';
import EditUserModal from '../Settings/EditUserModal';

const Title = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 3rem;
`;

const ProfileAndMetrics = styled(Section)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: ${({ theme }) => theme.colors.blue16} 1px dashed;
  margin-bottom: 0;
  ${media.ns} {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
  }
`;

const UserInfo = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
`;

const Hq = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('titles.hq'));

  const [profileLoading, setProfileLoading] = useState(true);
  const [userProfile, setUserProfile] = useState({});

  // const [teamsLoading, setTeamsLoading] = useState(true);
  // const [teams, setTeams] = useState();

  const [showEditModal, setShowEditModal] = useState(false);

  // Load user profile fn
  const loadUserProfile = async () => {
    setProfileLoading(true);
    await user.getProfile().then(({ data: { data } }) => {
      setUserProfile(data);
      setProfileLoading(false);
    });
  };

  // Load user's teams fn
  // Not ready yet
  // const loadUserTeams = async () => {
  //   setTeamsLoading(true);
  //   user.getTeams().then(({ data: { data } }) => {
  //     setTeams(data);
  //     setTeamsLoading(false);
  //   });
  // };

  // On page load we need to fetch the users profile, allies, and contacts and store it in state
  useEffect(() => {
    track('VIEW_HQ_SCREEN');
    loadUserProfile();
    // loadUserTeams();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  // loadUserTeams();
  // }, [campaign]);

  const {
    first_name: firstName = '',
    last_name: lastName = '',
    profile_url: profileUrl,
    locale,
    // TODO: this should be activities_completed_count (assuming the app
    // is correct)
    activities_performed_count: numActivities,
    activities_completed_count: activitiesCompleted,
    recruited_count: numRecruits,
    scoring: {
      rank_progress_from: rankProgressFrom,
      rank_progress_to: rankProgressTo,
      next_rank: nextRank,
      score: numPoints,
      rank,
    } = {},
    created_at: createdAt,
    supplied_zip_code: suppliedZipCode,
    phone,
  } = userProfile;

  const closeEditModal = () => {
    loadUserProfile();
    setShowEditModal(false);
  };

  if (profileLoading) {
    return (
      <>
        <AppHeader title={t('titles.hq')} />
        <AppContainer>
          <div className="flex-row justify-content-center mt-4">
            <Loading />
          </div>
        </AppContainer>
      </>
    );
  }

  return (
    <div>
      <AppHeader title={t('titles.hq')} />
      <AppContainer grayBackground>
        <MaxWidthContainer>
          <Title>{t('hq.title')}</Title>
          <UserInfo>
            <ProfileAndMetrics>
              <Profile
                firstName={firstName}
                lastName={lastName}
                profileUrl={profileUrl}
                createdAt={createdAt}
                suppliedZipCode={suppliedZipCode}
                phone={phone}
                locale={locale}
                onEditClick={() => setShowEditModal(true)}
              />
              <Metrics
                numPoints={numPoints}
                numActivities={numActivities}
                numRecruits={numRecruits}
              />
            </ProfileAndMetrics>
            <NextRank
              current={activitiesCompleted}
              fromComplete={rankProgressFrom}
              toComplete={rankProgressTo}
              nextRank={nextRank}
              rank={rank}
            />
          </UserInfo>
          {/* Not ready on the backend side
              {!teamsLoading && <UserTeamsSection user={userProfile} teams={teams} />} */}
          <SyncContacts />
        </MaxWidthContainer>
      </AppContainer>
      <EditUserModal user={userProfile} visible={showEditModal} onCancel={closeEditModal} />
    </div>
  );
};

Hq.propTypes = {};

export default Hq;
