import React, { useEffect, useRef } from 'react';

import { Form, Formik } from 'formik';
import FormField from '@web/components/common/shared/FormField';
import Loading from '@web/components/common/Loading';
import ContentWrapper from '../../../components/ContentWrapper';
import ViewTags from '../../../components/ReportFormHelpers/ViewTags';
import ViewCustomFields from '../../../components/ReportFormHelpers/ViewCustomFields';
import { FieldsWrapper } from '../../../components/shared';
import FormFooter from '../../../components/ReportFormHelpers/FormFooter';

import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const AddManuallyForm = ({
  campaignId,
  tags,
  customFields,
  onSubmit,
  redirectToFindVotersPage,
}) => {
  const { t } = useTranslation();
  const ref = useRef();

  const NewReportFormSchema = yup.object().shape({
    email: yup.string().email(t('idvoters.validation.email')),
    firstName: yup
      .string()
      .test('firstName', t('idvoters.validation.first_or_last'), function (value) {
        const { last_name } = this.parent;
        if (!last_name) return value != null;
        return true;
      }),
    lastName: yup
      .string()
      .test('lastName', t('idvoters.validation.first_or_last'), function (value) {
        const { first_name } = this.parent;
        if (!first_name) return value != null;
        return true;
      }),
    phone: yup.string().matches(phoneRegExp, t('idvoters.validation.phone')),
  });

  useEffect(() => {
    ref.current?.resetForm();
  }, [campaignId]);

  return (
    <ContentWrapper title={t('add_report.adding_manually.fill_and_send')}>
      {!tags || !customFields ? (
        <Loading />
      ) : (
        <Formik
          initialValues={{
            address: '',
            customFields,
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            tags,
          }}
          ref={ref}
          validationSchema={NewReportFormSchema}
          onSubmit={onSubmit}
          render={({ values, setFieldValue, errors, dirty, isSubmitting, submitCount }) => (
            <Form>
              <FieldsWrapper $twoColumns>
                <FormField
                  label={t('add_report.form.first_name')}
                  name="firstName"
                  placeholder={t('add_report.form.first_name')}
                />
                <FormField
                  label={t('add_report.form.last_name')}
                  name="lastName"
                  placeholder={t('add_report.form.last_name')}
                />
                <FormField
                  label={t('add_report.form.phone')}
                  name="phone"
                  placeholder={t('add_report.form.phone')}
                />
                <FormField
                  label={t('add_report.form.email')}
                  name="email"
                  placeholder={t('add_report.form.email')}
                />
                <FormField
                  label={t('add_report.form.address')}
                  name="address"
                  placeholder={t('add_report.form.address')}
                />
              </FieldsWrapper>
              <ViewTags setFieldValue={setFieldValue} tags={values.tags} />
              <ViewCustomFields customFields={values.customFields} setFieldValue={setFieldValue} />

              <FormFooter
                disabled={!!errors.firstName || !!errors.lastName || !dirty || isSubmitting}
                addAnotherReport={redirectToFindVotersPage}
                submitCount={submitCount}
              />
            </Form>
          )}
        />
      )}
    </ContentWrapper>
  );
};

AddManuallyForm.propTypes = {
  campaignId: PropTypes.number,
  customFields: PropTypes.arrayOf(PropTypes.shape()),
  onSubmit: PropTypes.func.isRequired,
  redirectToFindVotersPage: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape()),
};

export default AddManuallyForm;
