import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Autocomplete from 'react-google-autocomplete';
import { ErrorText, InputWrapper, Label, inputStyle } from './sharedStyles';
import { IconWrapper, Relative } from '.';
import Location from '../icons/Location';
import { Red } from '.';

import { connect, getIn } from 'formik';
import parseAddress from '@web/utils/parseAddress';

const StyledAutocomplete = styled(Autocomplete)`
  ${inputStyle}
`;

const AddressInput = ({
  formik,
  label,
  name,
  onChange,
  placeholder,
  required,
  inputRef,
  ...rest
}) => {
  const error = getIn(formik.errors, 'address');
  const touched = getIn(formik.touched, 'address');
  const hasError = touched && error;

  const selectPlace = place => {
    const { street_name, street_number, ...parsedAddress } = parseAddress(place);
    onChange(parsedAddress);
  };

  const handleValueChange = event => {
    if (event.target.value === '') {
      onChange({
        address: '',
        city: '',
        county_name: '',
        state_abbrev: '',
        zip_code: '',
      });
    }
  };

  const options = {
    componentRestrictions: { country: 'us' },
    types: ['address'],
  };

  return (
    <InputWrapper>
      <Label $labelFontColor={rest.labelFontColor} htmlFor={name}>
        {label} {required && <Red>*</Red>}
      </Label>
      <Relative $iconColor={rest.outlineColor}>
        <StyledAutocomplete
          autoComplete={'off'}
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
          defaultValue={''}
          $hasError={hasError}
          $fontColor={rest.fontColor}
          $bgColor={rest.backgroundColor}
          $outlineColor={rest.outlineColor}
          id={name}
          name={name}
          onBlur={formik.handleBlur('address')}
          onChange={handleValueChange}
          onPlaceSelected={selectPlace}
          options={options}
          placeholder={placeholder ? placeholder : label}
          type="search"
          ref={inputRef}
          {...rest}
        />
        <IconWrapper>
          <Location />
        </IconWrapper>
      </Relative>
      {hasError && <ErrorText>{error}</ErrorText>}
    </InputWrapper>
  );
};

AddressInput.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.any.isRequired,
    handleBlur: PropTypes.func.isRequired,
    touched: PropTypes.any.isRequired,
  }).isRequired,
  inputRef: PropTypes.any,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

AddressInput.defaultProps = {
  placeholder: null,
  required: false,
};

export default connect(AddressInput);
