import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'web/utils/context';
import { getLocalizedOnboardingData } from 'web/utils/other';

import { campaigns } from '../../services/api';
import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import Loading from '../common/Loading';
import CampaignDetails from './components/CampaignDetails';
import PublicCampaignDetails from './PublicCampaignDetails';

const CampaignDetail = () => {
  const { t } = useTranslation();
  const { campaignId } = useParams(); // can be either campaign `id` or `slug`
  const { token } = useContext(AuthContext);

  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);

  // populate campaign details from the campaign object here
  const loadCampaign = React.useCallback(() => {
    // if user is not logged in we use public campaign endpoint
    const getCampaign = token ? campaigns.getCampaign : campaigns.getPublicCampaign;

    getCampaign({ id: campaignId }).then(({ data: { data } }) => {
      const localizedData = getLocalizedOnboardingData(data);

      setCampaign(localizedData);
      setLoading(false);
    });
  }, [token, campaignId, setCampaign]);

  useEffect(() => {
    // initially load Campaign info based on provided id or slug
    loadCampaign();
  }, [loadCampaign, token]);

  if (loading) {
    return (
      <>
        <AppHeader title={t('campaign.title')} extra={campaign && campaign.name} />
        <AppContainer>
          <div className="flex-row justify-content-center mt-4">
            <Loading />
          </div>
        </AppContainer>
      </>
    );
  }

  // If we are logged out show new campaign details public page
  if (!token) {
    return (
      <PublicCampaignDetails
        campaign={campaign}
        loadCampaign={loadCampaign}
        setCampaign={setCampaign}
      />
    );
  }

  // When logged in use regular campaign page
  return (
    <CampaignDetails campaign={campaign} loadCampaign={loadCampaign} setCampaign={setCampaign} />
  );
};

CampaignDetail.propTypes = {};

export default CampaignDetail;
