import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import Sidebar from './Sidebar';

const StyledLayout = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const Content = styled.div`
  overflow-y: auto;
  width: 100%;
`;

const SidebarLayout = ({ activeIndex, showDemoButton, children }) => {
  return (
    <StyledLayout>
      <Sidebar activeIndex={activeIndex} showDemoButton={showDemoButton} />
      <Content>{children}</Content>
    </StyledLayout>
  );
};

SidebarLayout.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  showDemoButton: PropTypes.bool,
};

SidebarLayout.defaultProps = {
  showDemoButton: false,
};

export default SidebarLayout;
