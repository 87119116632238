import styled from 'styled-components';

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 28px;
  color: ${({ theme }) => theme.colors.black};

  small {
    color: ${({ theme }) => theme.colors.mediumGray};
    margin-left: 12px;
    font-size: 16px;
  }
`;

export default Title;
