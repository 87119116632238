import * as React from 'react';
import PropTypes from 'prop-types';

import Header from '@web/components/DialerActivity/components/Header';
import Modal from './components/Modal';
import PageLayout from '@web/components/DialerActivity/components/PageLayout';
import Progress from './components/Progress';

// pages
import Connect from './pages/Connect';
import Dialing from './pages/Dialing';
import EnterPhoneNumber from './pages/EnterPhoneNumber';
import Headphones from './pages/Headphones';
import Success from './pages/Success';

function ReceiveCall({ activity, history }) {
  const [pageIndex, setPageIndex] = React.useState(0);

  function onBack() {
    if (pageIndex === 0) {
      history.goBack();
    }
    setPageIndex(pageIndex - 1);
  }

  function onNext() {
    if (pageIndex === pages.length - 1) {
      // Just return early
      return;
    }
    setPageIndex(pageIndex + 1);
  }
  const pageProps = { activity, onBack, onNext };
  const pages = [
    <Headphones {...pageProps} />,
    <Connect {...pageProps} />,
    <EnterPhoneNumber {...pageProps} />,
    <Dialing {...pageProps} />,
    <Success {...pageProps} />,
  ];

  return (
    <PageLayout>
      <Header activity={activity} backLink={`preview-script`} />
      <Modal
        modalOpen
        onClose={() => null}
        progress={<Progress progress={(pageIndex + 1) / pages.length} />}
        style={{ maxWidth: 517 }}
      >
        {pages[pageIndex]}
      </Modal>
    </PageLayout>
  );
}

ReceiveCall.propTypes = {
  activity: PropTypes.any.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }),
};

ReceiveCall.defaultProps = {};

export default ReceiveCall;
