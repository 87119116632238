import { Button, Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import ImpactiveButton from '@web/components/ImpactiveButton';
import theme from '../../styles/theme';
import { AuthContext } from '../../utils/context';

const ModalTitle = styled.div`
  font-size: 22px;
  font-family: ${({ theme }) => theme.fonts.semibold};
`;

const ZipInput = styled(Input)`
  display: inline-block;
  width: 200px !important;
  height: 35px;
`;

const Description = styled.div`
  padding-bottom: 18px;
`;

const FormAndButton = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.span`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.gray};
`;

const SubTitle = styled.div`
  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.regular};
      color: ${theme.colors.blue};
    `}
  font-size: 1rem;
  margin-left: 10px;
`;

const SetZipButton = styled(Button)`
  background: none;
  border: none;
  padding: 0;
`;

const ButtonText = styled.div`
  color: ${({ theme }) => theme.fonts.blue};
  font-family: ${({ theme }) => theme.fonts.blackItalic};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ZipProvider = ({ zip, setZip }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const [showZipEntryModal, setShowZipEntryModal] = useState(false);
  const [modalZipInput, setModalZipInput] = useState('');
  const userSuppliedLocationAvailable = user && user.supplied_zip_code;

  const isValidZip = code => /^\d{5}$/.test(code);

  const onZipInputChanged = code => {
    if (/^\d{0,5}$/.test(code)) setModalZipInput(code);
  };

  const setZipCodeAndDismissModal = code => {
    setZip(code);
    setShowZipEntryModal(false);
  };

  const renderButton = () => {
    return userSuppliedLocationAvailable ? (
      <ImpactiveButton onClick={() => setZip(user.supplied_zip_code)}>
        {t('explore.near_me')}
      </ImpactiveButton>
    ) : (
      <ImpactiveButton onClick={() => setShowZipEntryModal(true)}>
        {t('explore.filter_by_location')}
      </ImpactiveButton>
    );
  };

  return (
    <Wrapper>
      {zip ? (
        <ButtonsWrapper>
          <ImpactiveButton secondary onClick={() => setShowZipEntryModal(true)}>
            {t('explore.change')}
          </ImpactiveButton>
          <SubTitle>
            {t('explore.searching_in_zip')} {zip}
          </SubTitle>
        </ButtonsWrapper>
      ) : (
        renderButton()
      )}

      <Modal
        visible={showZipEntryModal}
        maskClosable
        onCancel={() => setShowZipEntryModal(false)}
        bodyStyle={{ backgroundColor: theme.colors.background }}
        footer={null}
      >
        <ModalTitle>{t('explore.zip_modal.title')}</ModalTitle>
        <Form
          id="modalForm"
          onFinish={() => {
            // Submit zip code only if it's valid
            if (isValidZip(modalZipInput)) {
              setZipCodeAndDismissModal(modalZipInput);
            }
          }}
        >
          <Description>{t('explore.zip_modal.description')}</Description>
          <FormAndButton>
            <ZipInput
              autoFocus
              size="large"
              value={modalZipInput}
              onChange={e => onZipInputChanged(e.target.value)}
            />

            <div className="mx-3">
              <Button
                disabled={!isValidZip(modalZipInput)}
                form="modalForm"
                htmlType="submit"
                key="submit"
                type="primary"
              >
                {t('explore.zip_modal.submit')}
              </Button>
            </div>

            <Button key="clear" onClick={() => setZipCodeAndDismissModal(null)}>
              {t('explore.zip_modal.clear')}
            </Button>
          </FormAndButton>
        </Form>

        <SetZipButton
          type="link"
          disabled={!userSuppliedLocationAvailable}
          onClick={() => setZipCodeAndDismissModal(user.supplied_zip_code)}
          style={{ padding: '0px' }}
        >
          <ButtonText>{t('explore.zip_modal.use_current_location')}</ButtonText>
        </SetZipButton>
      </Modal>
    </Wrapper>
  );
};

ZipProvider.propTypes = {
  setZip: PropTypes.func.isRequired,
  zip: PropTypes.string,
};

export default ZipProvider;
