import { Input, Collapse } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { users } from '../../../../services/api';
import { interpolateMessage } from '../../../../utils/other';
import ImpactiveButton from '../../../ImpactiveButton';

const Wrapper = styled.div`
  display: flex;
`;

const Left = styled.div`
  flex: 1;
`;

const LeftHeading = styled.h3`
  font-size: 23px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  letter-spacing: 0.015em;
  margin-bottom: 16px;
`;

const MessageInput = styled(Input.TextArea)`
  && {
    height: 360px;
    font-size: 16px;
    padding: 10px 16px;
  }
`;

const Right = styled.div`
  width: 40%;
  flex: none;
  margin-left: 40px;
`;

const RightHeading = styled.h3`
  font-size: 17px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 6px 0 18px;
`;

const ScriptsBox = styled(Collapse).attrs({ accordion: true })`
  && {
    background: none;
    border: 1px dashed #b1b1b1;
    height: 360px;
    padding: 0 22px;
    overflow-y: auto;

    & > .ant-collapse-item {
      &:last-child {
        border-bottom: none;
      }

      & > .ant-collapse-header {
        font-family: ${({ theme }) => theme.fonts.semibold};
        padding: 19px 16px 19px 0;

        & > .ant-collapse-arrow {
          left: auto;
          right: 0;
          font-size: 14px;
        }
      }
    }

    .ant-collapse-content {
      background: none;
      border-top: none;
    }

    .ant-collapse-content-box {
      padding: 0 0 18px 0;
    }
  }
`;

const ScriptBody = styled.div`
  margin-bottom: 15px;
`;

const UseScriptButton = styled(ImpactiveButton)`
  font-size: 16px;
`;

// Input message handling component
const ScriptMessageInput = ({
  scripts,
  message,
  setMessage,
  setScriptId,
  contact,
  userActivity,
  disabled,
}) => {
  const { t } = useTranslation();
  const [me, setMe] = useState();
  const [selectedScript, setSelectedScript] = useState(null);
  const [showBulkInterpolationWarning, setShowBulkInterpolationWarning] = useState(false);

  // Pluck out campaign from userActivity for message interpolation
  const {
    activity: { campaign },
  } = userActivity;

  // Fetch data about the sender (logged in user)
  // TODO: check if we need to do this because scripts seem to be interpolated on backed first
  useEffect(() => {
    users.get().then(({ data: { data } }) => setMe(data));
  }, []);

  // Run interpolations whenever new script is selected or different user is at turn
  useEffect(() => {
    if (selectedScript) {
      const { body: scriptBody } = selectedScript;

      const interpolatedMessage = interpolateMessage(scriptBody, contact, me, campaign);
      setMessage(interpolatedMessage);
      setScriptId(selectedScript.id);

      // Notify user when batch sending and some of the scripts include interpolations.
      const scriptInterpolatable = scriptBody.match(/\{\{[\w.]*\}\}/);
      setShowBulkInterpolationWarning(!contact && scriptInterpolatable);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScript, contact]);

  return (
    <Wrapper>
      <Left>
        <LeftHeading>{t('activity.f2f.emailing.write_your_message')}:</LeftHeading>
        <MessageInput
          disabled={disabled}
          value={message}
          placeholder={t('activity.f2f.emailing.message_placeholder')}
          onChange={e => setMessage(e.target.value)}
        />
        {!disabled && !message && (
          <p className="text-error">{t('activity.f2f.emailing.body_required')}</p>
        )}
        {!disabled && showBulkInterpolationWarning && (
          <p className="text-error">{t('activity.f2f.emailing.bulk_interpolations_caveat')}</p>
        )}
      </Left>
      {scripts.length > 0 && (
        <Right>
          <RightHeading>{t('activity.f2f.emailing.use_a_script')}:</RightHeading>
          <ScriptsBox defaultActiveKey={scripts[0].id}>
            {scripts.map(({ name, body, id }) => (
              <Collapse.Panel header={name} key={id}>
                <ScriptBody>{body}</ScriptBody>
                <UseScriptButton onClick={() => setSelectedScript({ body, id, name })}>
                  {t('activity.f2f.emailing.use_this_script')}
                </UseScriptButton>
              </Collapse.Panel>
            ))}
          </ScriptsBox>
        </Right>
      )}
    </Wrapper>
  );
};

ScriptMessageInput.propTypes = {
  contact: PropTypes.shape({}),
  disabled: PropTypes.bool,
  message: PropTypes.string.isRequired,
  scripts: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })).isRequired,
  setMessage: PropTypes.func.isRequired,
  setScriptId: PropTypes.func.isRequired,
  userActivity: PropTypes.shape({
    activity: PropTypes.shape({
      campaign: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default ScriptMessageInput;
