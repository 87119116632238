import * as React from 'react';

import AddressConfirmation from '../flows/CheckRegistration/AddressConfirmation';
import ChooseFlow from '../newComponents/ChooseFlow';
import FindRegistration from '../flows/CheckRegistration/FindRegistration';
import Info from '../flows/HelpOthers/Info';
import NotRegistered from '../flows/CheckRegistration/NotRegistered';
// MTS - 8/2/22 We're removing the ReviewInformation screen until we're able
// to prefill a pdf with the voter's info.
// import ReviewInformation from '../newComponents/ReviewInformation';
import SelectVoter from '../flows/CheckRegistration/SelectVoter';
import Share from '../flows/HelpOthers/Share';
import TellUs from '../flows/RegisterToVote/TellUs';
import VotingInformation from '../newComponents/VotingInformation';
import FriendLookUp from '@web/components/FanOut/components/FriendLookUp';
import FriendLookUpResult from '@web/components/FanOut/components/FriendLookUpResult';
import VotingDetails from '@web/components/FanOut/components/VotingDetails';

const Placeholder = () => <div />;

// flows
export const defaultFlow = [ChooseFlow, Placeholder, Placeholder, Placeholder, Placeholder];

// Check Registration
export const checkRegistration = [
  ChooseFlow,
  FindRegistration,
  SelectVoter,
  AddressConfirmation,
  VotingInformation,
];

export const checkRegistrationReviewInfo = [
  ChooseFlow,
  FindRegistration,
  SelectVoter,
  AddressConfirmation,
  // ReviewInformation,
  VotingInformation,
];

export const checkRegistrationNotRegistered = [
  ChooseFlow,
  FindRegistration,
  NotRegistered,
  // ReviewInformation,
  VotingInformation,
];

export const registerToVote = [ChooseFlow, TellUs, VotingInformation];

export const fanOutRegistration = [
  FindRegistration,
  SelectVoter,
  AddressConfirmation,
  VotingInformation,
];

export const fanOutRegisterToVote = [TellUs, VotingInformation];

export const fanOutNotRegistered = [FindRegistration, NotRegistered, VotingInformation];

export const helpOthers = [ChooseFlow, Info, Share];

export const friendLookUp = [FriendLookUp, FriendLookUpResult];

export const voterDetails = [VotingDetails];

function useFlows() {
  const [selectedFlow, setSelectedFlow] = React.useState(defaultFlow);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [isReferralFlow, setIsReferralFlow] = React.useState(false);

  // Scroll to the top of the page each time currentPage changes.
  React.useEffect(() => {
    const modal = document.querySelector('.vr-modal');
    window?.scrollTo?.(0, 0);
    modal?.scrollTo?.(0, 0);
    // eslint-disable-next-line
  }, [currentPage, selectedFlow]);

  function backToStart() {
    setSelectedFlow(defaultFlow);
    setCurrentPage(0);
  }

  function nextPage() {
    const next = currentPage + 1;
    if (next > selectedFlow.length - 1) {
      return;
    }
    setCurrentPage(next);
  }

  function previousPage() {
    const next = currentPage - 1;
    if (next < 0) {
      return;
    }
    setCurrentPage(next);
  }

  return {
    backToStart,
    currentPage,
    isReferralFlow,
    nextPage,
    previousPage,
    selectedFlow,
    setCurrentPage,
    setIsReferralFlow,
    setSelectedFlow,
  };
}

export default useFlows;
