import React, { useCallback, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Drawer } from 'antd';

import Button from '@web/components/VoterRegistration/newComponents/Button';
import DrawerIcon from '@web/components/FanOut/components/icons/Drawer';
import FanOutLogoIcon from '@web/components/FanOut/components/icons/FanOutLogo';
import CloseDrawerIcon from '@web/components/FanOut/components/icons/CloseDrawer';
import DonateButton from '@web/components/FanOut/components/DonateButton';

const DrawerAntdStyles = createGlobalStyle`
  &&& {
    .ant-drawer-body {
      padding: 0px !important;
      width: 100vw;
      margin: 0;
      width: 100%;
      height: 100%;
      top: 0;
      overflow: hidden;
      display: flex;
      flex: 1;
    }
  }
`;

const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 56px 48px 73px 48px;
  background: ${({ theme }) => theme.colors.white};
`;

const FanOutLogo = styled(FanOutLogoIcon)`
  width: 176px;
  height: 33px;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CloseIconWrapper = styled.a`
  :hover,
  :focus,
  .active {
    opacity: 0.5;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    padding: 0px;
    height: 40px;
    width: 40px;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid #d0d5dd;
  }
`;

const LinksContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 10%;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 5px 0px;
  transition: all 0.3s ease-in-out;

  outline: 0;
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1.5rem;
  line-height: 2rem; /* 133.333% */
  font-weight: ${({ $isActive }) => ($isActive ? 600 : 400)};
  border-bottom: ${({ $isActive }) => $isActive && '2.748px solid #90b9f6'};

  cursor: pointer;

  :hover,
  .active {
    color: ${({ theme }) => theme.colors.newBlue};
    text-decoration: none;
    text-decoration-color: unset;
  }
`;

const DrawerComponent = ({ fanOutCookies, activity, team }) => {
  const { search, state } = useLocation();
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);

  const onOpen = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  const onClose = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  return (
    <>
      <StyledButton onClick={onOpen} variant="outline">
        <DrawerIcon />
      </StyledButton>
      <DrawerAntdStyles />
      <Drawer
        visible={openDrawer}
        width={'100%'}
        placement={'right'}
        onClose={onClose}
        closable={false}
      >
        <>
          <DrawerContent>
            <TopSection>
              <StyledLink onClick={onClose} to={{ search, state: '' }}>
                <FanOutLogo />
              </StyledLink>
              <CloseIconWrapper>
                <CloseDrawerIcon onClick={onClose} />
              </CloseIconWrapper>
            </TopSection>
            <LinksContainer>
              {fanOutCookies && (
                <StyledLink
                  onClick={onClose}
                  $isActive={state === 'activity'}
                  to={{ search, state: 'activity' }}
                >
                  {t('fan_out.header.my_activity')}
                </StyledLink>
              )}
              <StyledLink
                onClick={onClose}
                $isActive={state === 'sweepstakes'}
                to={{ search, state: 'sweepstakes' }}
              >
                {t('fan_out.header.all_sweepstakes')}
              </StyledLink>
              <StyledLink
                onClick={onClose}
                $isActive={state === 'terms'}
                to={{ search, state: 'terms' }}
              >
                {t('fan_out.header.terms')}
              </StyledLink>
            </LinksContainer>
            <DonateButton $isDrawer team={team} activity={activity} />
          </DrawerContent>
        </>
      </Drawer>
    </>
  );
};

DrawerComponent.propTypes = {
  activity: PropTypes.object,
  fanOutCookies: PropTypes.bool,
  team: PropTypes.object,
};

DrawerComponent.defaultProps = {};

export default DrawerComponent;
