import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { media, fonts } from '../../styles/theme';
import { truncate } from '../../utils/string';

const ProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  ${media.ns} {
    align-items: center;
    flex-direction: row;
  }
`;

const ProfileInfo = styled.div`
  display: inline-block;
  // margin-left: 1em;
  vertical-align: middle;
  margin-top: 1em;
  ${media.ns} {
    margin-top: 0em;
    margin-left: 2em;
  }
`;

const Name = styled.div`
  font-family: ${fonts.semibold};
  font-size: 1.5rem;
  letter-spacing: 1.45px;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 4px;
`;

const Info = styled.div`
  font-size: 12px;
`;

const EditButton = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.coral};
  font-family: ${({ theme }) => theme.fonts.semibolditalic};
  cursor: pointer;
  font-size: ${'1rem'};
  margin-top: 10px;
  max-width: 60px;
  > span {
    margin-right: 10px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

function Profile({ firstName, lastName, profileUrl, suppliedZipCode, phone, locale, onEditClick }) {
  const { t } = useTranslation();

  const hasName = !!firstName && !!lastName;

  const fullname = hasName
    ? `${firstName.toString()} ${lastName.toString()}`
    : t('hq.fullname_placeholder');

  const initials = hasName
    ? `${(firstName && firstName[0]) || ''} ${(lastName && lastName[0]) || ''}`
    : 'F L';

  const getLanguage = () => {
    switch (locale) {
      case 'en':
        return 'English';
      case 'es':
        return 'Español';
      case 'ko':
        return '한국어';
      default:
        return 'English';
    }
  };

  const zipCode = suppliedZipCode ? suppliedZipCode : t('hq.not_set');

  return (
    <ProfileHeader>
      <Avatar src={profileUrl} size={120}>
        {initials.toUpperCase()}
      </Avatar>

      <ProfileInfo>
        <Name>{truncate(fullname, 20)}</Name>
        <Info>
          {phone} {zipCode && `| ${zipCode}`}
        </Info>
        <Info>
          {t('profile.language')} {getLanguage()}
        </Info>
        <EditButton onClick={onEditClick}>
          <EditOutlined /> {t('button.edit')}
        </EditButton>
      </ProfileInfo>
    </ProfileHeader>
  );
}

Profile.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  locale: PropTypes.string,
  onEditClick: PropTypes.func.isRequired,
  phone: PropTypes.string,
  profileUrl: PropTypes.string,
  suppliedZipCode: PropTypes.string,
};

Profile.defaultProps = {
  createdAt: null,
  profileUrl: null,
  suppliedZipCode: null,
};

export default Profile;
