import { EventEmitter } from 'events';
import uniqueId from 'lodash/uniqueId';

const CHANGE = 'change';

class ModalManager extends EventEmitter {
  constructor() {
    super();
    this.modals = {};
  }

  openModal(key, component, props = {}) {
    this.modals = {
      ...this.modals,
      [key]: {
        component,
        props,
      },
    };
    this.emitChange();
  }

  closeModal(key) {
    if (!this.modals[key]) {
      return;
    }

    this.modals = { ...this.modals };
    delete this.modals[key];
    this.emitChange();
  }

  useModal(modalComponent) {
    const key = uniqueId('modal_');
    const open = props => this.openModal(key, modalComponent, props);
    const close = () => this.closeModal(key);
    return [open, close];
  }

  emitChange() {
    this.emit(CHANGE, this.modals);
  }

  addChangeListener(callback) {
    this.addListener(CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }
}

export default new ModalManager();
