import * as React from 'react';
import PropTypes from 'prop-types';

function Twitter(props) {
  return (
    <svg viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.0859 2.09472C15.5061 2.35166 14.8832 2.52527 14.2283 2.60375C14.904 2.19943 15.4095 1.5631 15.6505 0.813468C15.0157 1.19054 14.3209 1.45596 13.5963 1.59819C13.1091 1.07796 12.4637 0.733139 11.7605 0.617269C11.0572 0.501399 10.3353 0.620962 9.70693 0.957393C9.07855 1.29382 8.57883 1.8283 8.28534 2.47785C7.99185 3.12739 7.92102 3.85566 8.08384 4.54958C6.79752 4.48499 5.53914 4.15065 4.39039 3.56826C3.24164 2.98587 2.22818 2.16844 1.41579 1.16902C1.13801 1.64819 0.978288 2.20375 0.978288 2.79541C0.977978 3.32805 1.10914 3.85252 1.36015 4.32231C1.61115 4.79209 1.97423 5.19266 2.41718 5.48847C1.90348 5.47212 1.40112 5.33332 0.951899 5.08361V5.12527C0.951847 5.87232 1.21026 6.59637 1.68328 7.17458C2.1563 7.75278 2.8148 8.14953 3.54704 8.2975C3.0705 8.42647 2.57088 8.44546 2.08593 8.35305C2.29252 8.99584 2.69495 9.55793 3.23688 9.96064C3.7788 10.3633 4.43309 10.5865 5.10815 10.5989C3.9622 11.4985 2.54696 11.9864 1.09009 11.9843C0.832025 11.9844 0.574174 11.9693 0.317871 11.9392C1.79667 12.89 3.51811 13.3946 5.27621 13.3926C11.2276 13.3926 14.4811 8.46347 14.4811 4.18847C14.4811 4.04958 14.4776 3.9093 14.4713 3.77041C15.1042 3.31276 15.6504 2.74603 16.0845 2.0968L16.0859 2.09472Z"
        fill={props.color ?? '#6CCBDA'}
      />
    </svg>
  );
}

Twitter.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
};

Twitter.defaultProps = {
  height: 14,
};

export default Twitter;
