import React from 'react';
import PropTypes from 'prop-types';

import theme from '@web/styles/theme';

const FanOutLogo = props => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={175}
      height={34}
      fill="none"
      viewBox="0 0 175 34"
      {...props}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M96.177 31.026a15.98 15.98 0 0 0 5.427 1.521c4.057.39 7.937-.52 11.114-2.616 5.288-3.489 7.853-10.04 6.501-16.608-.667-3.23-2.426-6.291-4.816-8.379-1.945-1.698-4.677-3.043-7.205-3.553C98.622-.344 90.666 3.979 88.212 11.69c-.602 1.892-.704 2.598-.713 5.047 0 1.976.028 2.348.213 3.285 1.019 5.01 3.955 8.833 8.465 11.004Zm6.955-6.412c-1.482 1.986-1.546 2.06-1.991 2.292-.435.223-1.38.232-1.815.019-.352-.177-6.15-6.848-6.474-7.46-.26-.473-.287-1.336-.065-1.782.528-1.048 1.852-1.475 2.797-.9.158.093 1.223 1.272 2.38 2.617 1.158 1.345 2.13 2.44 2.177 2.44.037 0 .518-.584 1.065-1.299 3.473-4.518 9.067-11.05 9.9-11.551.787-.483 1.945-.223 2.593.584.232.288.287.455.315.937.056.9-.12 1.197-1.898 3.183-3.205 3.572-6.465 7.534-8.984 10.92Z"
        clipRule="evenodd"
      />
      <path
        fill={fill}
        d="M129.092 31.852c.981.39 2.473.76 3.51.872 1.797.204 3.797.093 5.473-.306 4.233-1.002 7.576-4.018 8.863-7.98l.091-.272c.07-.21.13-.389.181-.57.289-1.03.292-2.139.315-9.54l.043-11.84h-8.715v9.697c0 8.322 0 9.328-.36 10.181-.049.118-.106.232-.17.362l-.025.053c-.686 1.4-2.029 2.161-3.798 2.161-2.232 0-3.63-1.085-4.176-3.229-.167-.668-.176-1.104-.176-9.964v-9.26h-8.706v10.241c0 8.559 0 9.912.345 11.17.064.236.141.469.232.745l.034.103c1.13 3.423 3.603 6.012 7.039 7.376ZM2.063 2.217h20.005v7.422h-11.3v4.918h8.892v7.33h-8.891V32.37H2.063V2.217Z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M24.605 17.247c-3.121 8.24-5.686 15.013-5.686 15.059 0 .037 1.963.056 4.37.046l4.372-.027.287-.79.158-.433V31.1c.215-.59.555-1.524.852-2.367l.731-2.023h9.484l1.019 2.812 1.01 2.802 4.37.027c2.408.01 4.372-.009 4.372-.046 0-.046-2.556-6.82-5.687-15.059L38.572 2.263h-8.27l-5.696 14.984Zm9.826-5.103c.047 0 2.455 7.32 2.455 7.45 0 .038-1.103.066-2.455.066s-2.454-.028-2.454-.056c0-.12 2.417-7.46 2.454-7.46Z"
        clipRule="evenodd"
      />
      <path
        fill={fill}
        d="M51.148 2.217h7.094l5.548 7.608c3.047 4.185 5.584 7.608 5.621 7.608.037 0 .074-3.423.074-7.608V2.217H78.1V32.37h-7.086l-5.547-7.608c-3.047-4.184-5.575-7.636-5.622-7.664-.046-.028-.083 3.248-.083 7.608v7.664h-8.613V2.217ZM157.561 9.918V32.37h8.705V9.918h7.687V2.217h-24.172v7.7h7.78Z"
      />
    </svg>
  );
};

FanOutLogo.propTypes = {
  fill: PropTypes.string,
};

FanOutLogo.defaultProps = {
  fill: theme.colors.black,
};

export default FanOutLogo;
