import React from 'react';

import TextArea from 'antd/lib/input/TextArea';
import { SectionTitle } from '../shared';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import pxToRem from '@web/utils/pxToRem';

const FieldWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > span {
    margin-left: 20px;
  }
`;

const CustomFieldWrapper = styled.div`
  margin-bottom: 20px;
`;

const CustomFieldOption = styled.div`
  background: ${({ theme, $selected }) => ($selected ? theme.colors.teal : theme.colors.white)};
  color: ${({ theme, $selected }) => (!$selected ? theme.colors.blue60 : theme.colors.white)};
  border: 1px solid ${({ theme }) => theme.colors.teal};
  padding: 2px 1rem;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-size: ${pxToRem(14)};
`;

const ViewCustomFields = ({ customFields, setFieldValue }) => {
  return customFields.map(
    ({ name, value: fieldValue, slug, prompt, type, available_values }, index) => (
      <CustomFieldWrapper key={slug}>
        <SectionTitle> {prompt || name}</SectionTitle>

        {type === 'TextField' && (
          <TextArea
            name={slug}
            value={fieldValue}
            autoSize={{ minRows: 4 }}
            onChange={e => setFieldValue(`customFields[${index}]value`, e.target.value)}
          />
        )}

        {type === 'SelectionField' && (
          <FieldWrapper>
            {available_values.map(value => {
              return (
                <CustomFieldOption
                  key={value}
                  $selected={fieldValue === value}
                  onClick={() => setFieldValue(`customFields[${index}]value`, value)}
                >
                  {value}
                </CustomFieldOption>
              );
            })}
          </FieldWrapper>
        )}
      </CustomFieldWrapper>
    ),
  );
};

ViewCustomFields.propTypes = {
  customFields: PropTypes.arrayOf(PropTypes.shape()),
  setFieldValue: PropTypes.func.isRequired,
};

export default ViewCustomFields;
