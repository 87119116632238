import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import PageTitle from '@web/components/FanOutVotingPlan/components/PageTitle';
import theme from '@admin/styles/theme';
import { small800Breakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';

const Wrapper = styled.div`
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
  padding: 40px;
  gap: 24px;
  border: 2px solid ${theme.colors.darkBlue};
  box-shadow: 4px 4px 0px 0px ${theme.colors.darkBlue};

  ${small800Breakpoint} {
    padding: 24px 20px 32px;
    border: unset;
    box-shadow: unset;
  }
`;

const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  ${small800Breakpoint} {
    flex-direction: column;
    gap: 10px;
  }
`;

const AnswerCard = styled.div`
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  flex: 1;
  align-self: stretch;
  border-radius: 4px;
  background: #e9f1fd;

  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.regular};
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;

const LookUpText = styled.a`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  text-align: center;
  font-size: 1rem;
  line-height: 1.25rem; /* 125% */
  text-decoration-line: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.coral};
  }
`;

const HowIPlanToVote = ({ VPAnswers, activity }) => {
  const { t } = useTranslation();

  const first3Answers = useMemo(() => VPAnswers.slice(0, 3), [VPAnswers]);

  const lookUpClick = useCallback(
    () => amplitudeTrack({ activity, name: 'FAN_OUT_VOTING_PLAN_LOOK_UP_POLLING_LOCATION' }),
    [activity],
  );

  return (
    <Wrapper>
      <PageTitle icon={'✍️'} title={t('fan_out.voting_plan.results.plan_to_vote')} />
      <AnswersWrapper>
        {first3Answers.map(({ value }) => (
          <AnswerCard key={value}>{value}</AnswerCard>
        ))}
      </AnswersWrapper>
      <LookUpText
        target="_blank"
        href="https://www.vote.org/polling-place-locator/"
        onClick={lookUpClick}
      >
        {t('fan_out.voting_plan.results.look_up')}
      </LookUpText>
    </Wrapper>
  );
};

HowIPlanToVote.propTypes = {
  activity: PropTypes.object.isRequired,
  VPAnswers: PropTypes.array.isRequired,
};

HowIPlanToVote.defaultProps = {};

export default HowIPlanToVote;
