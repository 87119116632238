import React from 'react';

import Title from '@web/components/common/Title';
import ImpactiveLink from '@web/components/common/Link';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { media } from '@web/styles/theme';

const Wrapper = styled.div`
  background: white;
  padding: 2.5rem 3.125rem 3.125rem;
  box-shadow: 4px 4px 20px rgba(0, 16, 72, 0.06);
  border-radius: 16px;
  margin-bottom: 2rem;
  ${media.nxl} {
    padding: 1.5rem 2.125rem 2.125rem;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ allowMargin }) => (allowMargin ? '3rem' : '0')};
`;

const TitleAndSubtitle = styled.div``;

const Subtitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.black};
`;

const Buttons = styled.div`
  display: flex;
`;

const ContentWrapper = ({
  children,
  title,
  showAddManuallyButton,
  showSearchAgainButton,
  subtitle,
}) => {
  const { t } = useTranslation();

  const addManuallyButton = () => (
    <ImpactiveLink to={'/add-report/new-voter-report'}>
      {t('add_report.buttons.add_manually')}
    </ImpactiveLink>
  );

  const searchAgainButton = () => (
    <ImpactiveLink to={'/add-report'}>{t('add_report.buttons.search_again')}</ImpactiveLink>
  );

  return (
    <Wrapper>
      {title && (
        <TitleWrapper allowMargin={children}>
          <TitleAndSubtitle>
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </TitleAndSubtitle>
          <Buttons>
            {showAddManuallyButton && addManuallyButton()}
            {showSearchAgainButton && searchAgainButton()}
          </Buttons>
        </TitleWrapper>
      )}
      {children}
    </Wrapper>
  );
};

ContentWrapper.propTypes = {
  buttons: PropTypes.node,
  children: PropTypes.node,
  showAddManuallyButton: PropTypes.bool,
  showSearchAgainButton: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

ContentWrapper.defaultProps = {
  buttons: null,
  children: null,
  showAddManuallyButton: false,
  showSearchAgainButton: false,
  subtitle: null,
  title: '',
};

export default ContentWrapper;
