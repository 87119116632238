import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const Term = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const Description = styled.span`
  ${({ vertical }) =>
    vertical
      ? `
          display: block;
          margin-top: 6px;
        `
      : `
        padding-left: 5px;

        @media screen and (max-width: 767px) {
          padding-left: 0;
          display: block;
          margin-top: 6px;
        }
      `}
`;

const Help = styled.div`
  font-size: 0.75em;
  line-height: 1.4;
  margin-top: 0.5em;
`;

const Definition = ({ term, description, help, vertical, ...rest }) => (
  <Wrapper {...rest}>
    <Term>{term}</Term>
    {description && <Description vertical={vertical}>{description}</Description>}
    {help && <Help>{help}</Help>}
  </Wrapper>
);

Definition.propTypes = {
  description: PropTypes.node,
  help: PropTypes.string,
  term: PropTypes.string.isRequired,
  vertical: PropTypes.bool,
};

export default Definition;
