import { message, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Formik } from 'formik';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { track } from '../../services/analytics';
import { user, users, userReportApi } from '../../services/api';
import { sortByDisplayOrderFn } from '../../utils/other';
import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import BackButton from '../common/BackButton';
import './new-voter-report.scss';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const NewVoterReport = () => {
  // Fetch new_report from campaign endpoint to get the latest tags and custom fields that give us the report for users to fill out
  // Fetch voter using voterbase ID. If none exists, then we are adding someone new to the database and must require a phone or email and first/last.
  const { t } = useTranslation();
  const { state } = useLocation();
  const { campaignId, activityId, userActivityId } = state || {};

  const [redirectToVoterForm, setRedirectToVoterForm] = useState(null);
  const [userReport, setUserReport] = useState({});
  const [userActivity, setUserActivity] = useState({});
  const { tags = [], custom_fields = [] } = userReport;

  const NewReportFormSchema = yup.object().shape({
    email: yup.string().email(t('idvoters.validation.email')),
    first_name: yup
      .string()
      .test('first_name', t('idvoters.validation.first_or_last'), function (value) {
        const { last_name } = this.parent;
        if (!last_name) return value != null;
        return true;
      }),
    last_name: yup
      .string()
      .test('last_name', t('idvoters.validation.first_or_last'), function (value) {
        const { first_name } = this.parent;
        if (!first_name) return value != null;
        return true;
      }),
    phone: yup.string().matches(phoneRegExp, t('idvoters.validation.phone')),
  });

  useEffect(() => {
    // Load latest report when conditions are met.
    if (!campaignId) {
      return;
    }

    user
      .getUserActivity(userActivityId)
      .then(({ data: { data: userActivity } }) => {
        setUserActivity(userActivity);
      })
      .catch(x => {}); // Noop

    userReportApi
      .getBlankReport({ activity_id: activityId, campaign_id: campaignId })
      .then(({ data }) => {
        const { campaign_id, taggings, customizations } = data;

        const strippedTags = taggings
          .map(tagging => {
            const {
              tag: { id, slug, name, display_order, prompt },
              value,
            } = tagging;
            return { campaign_id, display_order, id, name, prompt, slug, value };
          })
          .sort(sortByDisplayOrderFn);

        const strippedFields = customizations
          .filter(customization => customization.custom_field.is_user_reportable !== false)
          .map(customization => {
            const {
              custom_field: { id, name, type, slug, available_values, display_order, prompt },
              value,
            } = customization;
            return {
              available_values,
              campaign_id,
              display_order,
              id,
              name,
              prompt,
              slug,
              type,
              value,
            };
          })
          .sort(sortByDisplayOrderFn);

        setUserReport({
          custom_fields: strippedFields,
          tags: strippedTags,
        });
      })
      .catch(err => {
        message.error(t('errors.fetch_outreach_report'));
      });
    // Load response scripts if latest message is associated with an activity
    // eslint-disable-next-line
  }, [campaignId]);

  const onSwitchToggle = (selectedSlug, selectionChange) => {
    const updatedTags = tags.map(tag => {
      if (tag.slug !== selectedSlug) {
        return tag;
      }
      return {
        ...tag,
        value: selectionChange,
      };
    });

    setUserReport({
      custom_fields,
      tags: updatedTags,
    });
  };

  const onCustomFieldTextUpdate = (selectedSlug, e) => {
    const updatedFields = custom_fields.map(field => {
      if (field.slug !== selectedSlug) {
        return field;
      }
      return {
        ...field,
        value: e.target.value,
      };
    });

    setUserReport({
      custom_fields: updatedFields,
      tags,
    });
  };

  const onCustomFieldSelectUpdate = (selectedSlug, val) => {
    const updatedFields = custom_fields.map(field => {
      if (field.slug !== selectedSlug) {
        return field;
      }
      return {
        ...field,
        value: val,
      };
    });

    setUserReport({
      custom_fields: updatedFields,
      tags,
    });
  };

  const onSaveError = () => {
    message.error(t('errors.submit_outreach_report'));
  };

  const submitReport = async formData => {
    if (!formData) {
      return;
    }

    const newUserReportBody = {
      activity_id: activityId,
      address: formData.address,
      email: formData.email,
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone: formData.phone,
    };

    const userReportRes = await userReportApi
      .findOrCreateReport({ activity_id: activityId, campaign_id: campaignId }, newUserReportBody)
      .catch(err => {
        onSaveError();
        return;
      });

    if (!userReportRes || userReportRes.status !== 200) {
      onSaveError();
      return;
    }

    const {
      data: { id: newReportId, taggings, customizations },
    } = userReportRes;

    let updatedReport = {
      ...newUserReportBody,
      customizations_attributes: customizations.map(cust => {
        const existingCf = custom_fields.find(x => x.id === cust.custom_field_id);
        cust.value = existingCf && existingCf.value;
        const { id, custom_field_id, value } = cust;
        return { custom_field_id, id, value };
      }),
      taggings_attributes: taggings.map(tagging => {
        const existingTag = tags.find(x => x.id === tagging.tag_id);
        tagging.value = existingTag && existingTag.value;
        const { id, tag_id, value } = tagging;
        return { id, tag_id, value };
      }),
    };

    await userReportApi
      .update({ campaign_id: campaignId, report_id: newReportId }, updatedReport)
      .catch(err => {
        onSaveError();
      });

    const activity = userActivity && userActivity.activity;
    const campaign = activity && activity.campaign;
    track('COMPLETED_ACTIVITY', {
      activity_id: activityId,
      activity_title: activity && activity.title,
      campaign_id: campaign && campaign.id,
      campaign_name: campaign && campaign.name,
    });

    await users.sendCanvasserMessagePublic(
      {
        activity_id: activityId,
        campaign_id: campaignId,
        contact_id: null,
        report_id: newReportId,
        user_activity_id: userActivityId,
      },
      updatedReport,
    );

    await user.updateUserActivity(userActivityId, { completed: true });
    await user.userActivityAddPerform(userActivityId);

    message.success(t('idvoters.voter.outreach_report_sent'));
    setRedirectToVoterForm(true);
  };

  if (redirectToVoterForm) {
    return (
      <Redirect
        push
        to={{
          pathname: '/voters',
          state: {
            activityId,
            campaignId,
            userActivityId,
          },
        }}
      />
    );
  }

  return (
    <div>
      <AppHeader title={t('idvoters.voter.new.header')} />
      <AppContainer>
        <div className="flexbox justify-content-center">
          <div className="new-voter-report-section p-2">
            <div className="p-3">
              <BackButton />
            </div>
            <Formik
              initialValues={{
                address: '',
                email: '',
                first_name: '',
                last_name: '',
                phone: '',
              }}
              validationSchema={NewReportFormSchema}
              onSubmit={submitReport}
            >
              <Form>
                <div className="flex-row flex-wrap">
                  <FormItem name="first_name" className="flex1 form-input px-3">
                    <label htmlFor="first_name">{t('idvoters.labels.firstname')}</label>
                    <Input name="first_name" placeholder={t('idvoters.labels.firstname')} />
                  </FormItem>
                  <FormItem name="last_name" className="flex1 form-input px-3">
                    <label htmlFor="last_name">{t('idvoters.labels.lastname')}</label>
                    <Input name="last_name" placeholder={t('idvoters.labels.lastname')} />
                  </FormItem>
                </div>

                <FormItem name="phone" className="form-input px-3">
                  <label htmlFor="phone">{t('idvoters.labels.phone')}</label>
                  <Input name="phone" placeholder={t('idvoters.labels.phone')} />
                </FormItem>

                <FormItem name="email" className="form-input px-3">
                  <label htmlFor="email">{t('idvoters.labels.email')}</label>
                  <Input name="email" placeholder={t('idvoters.labels.email')} />
                </FormItem>

                <FormItem name="address" className="form-input px-3">
                  <label htmlFor="address">{t('idvoters.labels.address')}</label>
                  <Input name="address" placeholder={t('idvoters.placeholders.address')} />
                </FormItem>

                <div className="p-3">
                  {tags &&
                    tags.map(({ name, value, slug, prompt }) => (
                      <div className="py-3" key={slug}>
                        <span className="pr-3">{prompt || name}</span>
                        <Switch
                          slug={slug}
                          checked={value}
                          onChange={(to, e) => onSwitchToggle(slug, to, e)}
                        />
                      </div>
                    ))}
                </div>

                <div className="p-3">
                  {custom_fields &&
                    custom_fields.map((field, i) => (
                      <div className="py-3" key={field.slug}>
                        <div className="pb-2">{field.prompt || field.name}</div>

                        {field.type === 'TextField' && (
                          <TextArea
                            name={field.slug}
                            value={field.value}
                            autoSize={{ minRows: 4 }}
                            onChange={e => onCustomFieldTextUpdate(field.slug, e)}
                          />
                        )}

                        {field.type === 'SelectionField' && (
                          <div className="flex-row flex-wrap">
                            {field.available_values.map(val => (
                              <div key={val} className="m-2">
                                <div
                                  className={`selection-box ${
                                    field.value === val ? 'selected' : ''
                                  }`}
                                  onClick={e => onCustomFieldSelectUpdate(field.slug, val)}
                                >
                                  {val}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                </div>

                <div className="flex-row justify-content-end m-3">
                  <SubmitButton className="w-100">{t('common.send_to_campaign')}</SubmitButton>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </AppContainer>
    </div>
  );
};

export default NewVoterReport;
