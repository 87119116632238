import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, message, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import GoogleLogin from 'react-google-login';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { authErrorMessage } from '../../../services/google/auth';

import Text from '../../common/Text';
import SuggestionMessage from './SuggestionMessage';

const GoogleLoginWrapper = styled.div`
  div {
    margin-right: 0 !important;
  }
  span {
    font-size: 16px;
    padding: 0 22px 0 10px !important;
  }

  svg {
    vertical-align: middle;
  }
`;

// Google API keys
const GOOGLE_API_KEY = 'AIzaSyDrsis93x3jXGXr7ZtX60BhvNpABBEBrm0';
const GOOGLE_CLIENT_ID = '703515819524-3dl462627e8kr6gjrr5026dolkmdq3uq.apps.googleusercontent.com';
const GOOGLE_DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/people/v1/rest',
  'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest',
];

function GoogleAuthButton({ googleUser, hideText, incrementStep, scopes, setGoogleUser }) {
  const { t } = useTranslation();
  const [gapiLoaded, setGapiLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [needsReauthentication, setNeedsReauthentication] = React.useState(false);

  // MTS - This is kind of hacky but it was the only way I could verify that we wouldn't go to the
  // next step before the APIs are loaded. This fixes a bug in the previous implementation where
  // a user was allowed to click the continue button before authorized.
  React.useEffect(() => {
    const interval = setInterval(() => {
      const { gapi } = window;
      if (
        gapi &&
        gapi.client &&
        gapi.client.people &&
        gapi.client.people.people &&
        gapi.client.people.people.connections &&
        gapi.client.gmail
      ) {
        clearInterval(interval);
        setLoading(false);
        setGapiLoaded(true);
        // eslint-disable-next-line
      }
    }, 500);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const initClient = () => {
    setLoading(true);
    window.gapi.client.init({
      apiKey: GOOGLE_API_KEY,
      clientId: GOOGLE_CLIENT_ID,
      discoveryDocs: GOOGLE_DISCOVERY_DOCS,
      scope: scopes,
    });
  };

  const handleClientLoad = () => {
    window.gapi.load('client:auth2', initClient);
  };

  const handleGoogleSuccess = response => {
    const { scope } = response.tokenObj;
    const authorizedScopes = scope.split(' ');
    // MTS - Not sure how accurate of a way this is to check the scopes that
    // are authorized. It seems to work locally but there might be a better way.

    const scopesNeeded = scopes.split(' ');
    const allScopesApproved = scopesNeeded.reduce((acc, thisScope) => {
      if (!authorizedScopes.find(el => el === thisScope)) {
        return false;
      }
      return acc;
    }, true);
    if (allScopesApproved) {
      handleClientLoad();
      setNeedsReauthentication(false);
      setGoogleUser(response.profileObj);
    } else {
      setNeedsReauthentication(true);
    }
  };

  const handleGoogleFailure = response => {
    const { error } = response;
    message.error(authErrorMessage(error));
    setLoading(false);
  };

  const googleButtonText = googleUser
    ? t('contact.sync.with_user', { googleUser })
    : t('contact.sync.choose_account');
  const reauthText = t('contact.sync.choose_account');

  return (
    <>
      <Row style={{ margin: '1em 0' }}>
        {!hideText && <Text>{t('contact.sync.google_auth_instructions')}</Text>}
      </Row>
      <Row style={{ margin: '1em 0' }}>
        <Col style={{ textAlign: 'center' }}>
          {needsReauthentication && (
            <SuggestionMessage style={{ margin: '0 0 1rem' }}>
              {t('contact.sync.additional_permissions')}
            </SuggestionMessage>
          )}
          <GoogleLoginWrapper>
            <GoogleLogin
              buttonText={needsReauthentication ? reauthText : googleButtonText}
              clientId={GOOGLE_CLIENT_ID}
              disabled={loading}
              scope={scopes}
              onSuccess={handleGoogleSuccess}
              onFailure={handleGoogleFailure}
              isSignedIn
              cookiePolicy="single_host_origin"
            />
          </GoogleLoginWrapper>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'center' }}>
          {googleUser && gapiLoaded && (
            <Button autoFocus type="primary" size="large" onClick={incrementStep}>
              {t('common.continue')}
              <ArrowRightOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

GoogleAuthButton.propTypes = {
  googleUser: PropTypes.any,
  hideText: PropTypes.bool,
  incrementStep: PropTypes.func.isRequired,
  scopes: PropTypes.string,
  setGoogleUser: PropTypes.func.isRequired,
};

GoogleAuthButton.defaultProps = {
  hideText: false,
  scopes:
    'https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/gmail.send',
};

export default GoogleAuthButton;
