import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DateItem from '@web/components/Headcount/components/VotingDates/DateItem';
import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';

const DateList = styled.ul`
  margin: ${({ theme }) => theme.spacing.wide} 0 0;
  padding: 0;
`;

const StyledVotingDates = styled.div`
  margin: 1em 0;
`;

function VotingDatesGA({ activity, team }) {
  const isPartner = useIsPartner(team);
  const isCannabis = activity?.campaign?.cannabis_registration_enabled;

  function getColor() {
    if (isCannabis) {
      return 'green';
    }
    if (isPartner) {
      return 'yellow';
    }
    return 'teal';
  }

  const color = getColor();

  return (
    <StyledVotingDates>
      <DateList>
        <DateItem color={color} date={'11-07-22'} type="lastRegDay" />
        <DateItem color={color} date={'11-27-22'} type="lastDayAbsenteeBallot" />
        <DateItem color={color} date={'11-28-22'} type="earlyVotingStart" />
        <DateItem color={color} date={'12-06-22'} type="electionDay" />
      </DateList>
    </StyledVotingDates>
  );
}

VotingDatesGA.propTypes = {
  activity: PropTypes.object,
  stateAbbreviation: PropTypes.string.isRequired,
  team: PropTypes.object,
  voteByMail: PropTypes.bool,
  votingInfo: PropTypes.shape({
    early_vote_start: PropTypes.string,
    general_election_date: PropTypes.string.isRequired,
    general_vbm_request_deadline: PropTypes.string,
    online_primary_reg_deadline: PropTypes.string,
    online_reg_deadline: PropTypes.string,
    polling_place_locator: PropTypes.string,
    primary_early_vote_start: PropTypes.string,
    primary_election_date: PropTypes.string,
    primary_vbm_request_deadline: PropTypes.string,
  }).isRequired,
};

VotingDatesGA.defaultProps = {};

export default VotingDatesGA;
