import dayjs from 'dayjs';

function parseVoterObject(voter) {
  const firstName = voter?.['vb.tsmart_first_name'];
  const lastName = voter?.['vb.tsmart_last_name'];
  const city = voter?.['vb.tsmart_city'];
  const state = voter?.['vb.tsmart_state'];
  const ageRange = voter?.['vb.voterbase_age'];
  const dob = voter?.['vb.voterbase_dob'];
  const addressLine1 = voter?.['vb.tsmart_full_address'];
  const voterbaseId = voter?.['voterbase_id'];
  const zip = voter?.['vb.tsmart_zip'];

  return {
    addressLine1,
    addressLine2: `${city}, ${state}`,
    age: Math.abs(dayjs(dob, 'YYYYMMDD').diff(dayjs(), 'years')) || ageRange,
    city: city,
    // MTS - .toLowerCase() required for css text-transform to work
    name: `${firstName} ${lastName}`.toLowerCase(),
    state: state,
    voterbaseId,
    zip,
    ...voter,
  };
}

export default parseVoterObject;
