import * as yup from 'yup';
import i18n from '@web/components/i18n';

const EditUserScheme = yup.object().shape({
  firstName: yup.string().required(i18n.t('idvoters.validation.name')),
  lastName: yup
    .string()
    .required(i18n.t('errors.field_required', { field: i18n.t('placeholders.last_name') })),
  locale: yup.string().oneOf(['en', 'es', 'ko']).required('Language is required!'),
  phone: yup.string().required(i18n.t('idvoters.validation.phoneRequired')),
  zipCode: yup.string().nullable(),
});

export default EditUserScheme;
