import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import defaultCampaignProfile from '../../../../../assets/images/web/default-campaign-profile.png';
import { colors, fonts, media } from '../../../styles/theme';
import { kFormatter, punyEncodedURL, replaceDefaultCampaignImage } from '../../../utils/string';

const TopicsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 13px;
  margin-bottom: 2px;
`;

const TopicButton = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
  justify-content: center;
  border-radius: 6px;
  color: ${colors.darkBlue};
  border: 1px solid ${colors.teal};
  font-family: ${fonts.bolditalic};
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 6px 8px;
  user-select: none;
`;

const MetricsContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
`;

const Metric = styled.div`
  margin-right: 18px;
`;

const MetricValue = styled.div`
  font-family: ${fonts.light};
  font-size: 28px;
  color: ${colors.black};
  text-align: left;
  line-height: 32px;
  text-align: center;
`;

const CampaignLink = styled.a`
  font-family: ${fonts.semibolditalic};
  font-size: 12px;
  color: ${colors.coral};
  line-height: 16px;
  margin-top: 4px;
`;

const MetricLabel = styled.div`
  font-family: ${fonts.semibold};
  font-size: 12px;
  color: ${colors.black};
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
`;

const CampaignName = styled(Link)`
  font-family: ${fonts.semibold};
  font-size: 28px;
  color: ${colors.black};
  line-height: 1;
`;

const CampaignInfo = styled.div`
  display: flex;
  flex-direction: row;
`;

const CampaignMetrics = styled.div``;

const Wrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.borderGray};
  display: flex;
  justify-content: space-between;
  padding: 1em 0;

  ${media.nlg} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledAvatar = styled(Avatar)`
  margin: 0 1em;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 1em;
`;

const Title = styled.div`
  display: flex;
`;

const JoinButton = styled(Button)`
  margin: 0 1em;
  cursor: ${props => (props.disabled ? 'default !important' : 'pointer')};

  &&& {
    color: white !important;
    background-color: gray !important;
    border-color: gray !important;
    ${media.nlg} {
      display: none;
    }
  }
`;

function CampaignHeader({ campaign, joined, onJoinClick }) {
  const { t } = useTranslation();

  if (!campaign) {
    return null;
  }

  const {
    name,
    slug,
    website_url: website,
    total_allies: allies,
    total_actions_performed: actions,
    url_profile_img: profileUrl,
  } = campaign;

  return (
    <Wrapper>
      <CampaignInfo>
        <StyledAvatar
          src={replaceDefaultCampaignImage(profileUrl) || defaultCampaignProfile}
          size={65}
        >
          {name}
        </StyledAvatar>
        <TitleWrapper>
          <Title>
            <CampaignName as={Link} to={`/campaigns/${slug}`}>
              {name}
            </CampaignName>
            <JoinButton
              data-testid="join-button"
              ghost={joined}
              icon={joined ? <CheckOutlined /> : <PlusOutlined />}
              disabled={!onJoinClick}
              onClick={onJoinClick}
              type="primary"
            >
              {joined ? t('campaign.joined') : t('campaign.join')}
            </JoinButton>
          </Title>
          <CampaignLink href={website}>{punyEncodedURL(website)}</CampaignLink>
        </TitleWrapper>
      </CampaignInfo>
      <CampaignMetrics>
        {allies >= 100 && actions >= 100 ? (
          <MetricsContainer>
            <Metric>
              <MetricValue>{kFormatter(allies)}</MetricValue>
              <MetricLabel>{t('campaign.allies')}</MetricLabel>
            </Metric>
            <Metric>
              <MetricValue>{kFormatter(actions)}</MetricValue>
              <MetricLabel>{t('campaign.actions')}</MetricLabel>
            </Metric>
          </MetricsContainer>
        ) : (
          <TopicsContainer>
            <TopicButton>{t('campaign.new')}</TopicButton>
          </TopicsContainer>
        )}
      </CampaignMetrics>
    </Wrapper>
  );
}

CampaignHeader.propTypes = {
  campaign: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    total_actions_completed: PropTypes.number,
    total_actions_performed: PropTypes.number,
    total_allies: PropTypes.number,
    url_profile_img: PropTypes.string,
    website_url: PropTypes.string,
  }).isRequired,
  joined: PropTypes.bool.isRequired,
  onJoinClick: PropTypes.func,
};

CampaignHeader.defaultProps = {
  onJoinClick: null,
};

export default CampaignHeader;
