import { LoadingOutlined } from '@ant-design/icons';
import { Alert, message, Spin } from 'antd';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { track, trackPageViewWithDynamicIDs } from '../../services/analytics';
import { users, campaigns, teams } from '../../services/api';
import CollectPhone from './CollectPhone';
import ThankYou from './ThankYou';
import {
  MiddleContainer,
  Title,
  GetStarted,
  SelectAnOption,
  Options,
  OptionColumn,
  OptionDescription,
  SquareRoundButton,
  MainContent,
} from './styles';

const ScreenTypes = {
  COLLECT_PHONE: 'collect_phone',
  SHOW_OPTIONS: 'show_options',
  THANK_YOU: 'thank_you',
};

const ContactPaths = {
  GA_NONRESIDENT: 'ga_nonresident',
  GA_REGISTERED: 'ga_registered',
  GA_UNREGISTERED: 'ga_unregistered',
};

const GaOptionsPage = props => {
  const { campaignId } = props.match.params; // can be either campaign `id` or `slug`
  const { referral_hash } = queryString.parse(props.location.search);
  const { t } = useTranslation();

  const history = useHistory();

  const [campaign, setCampaign] = useState({});
  // keeping this in case we want to enable the team thing later on
  // eslint-disable-next-line no-unused-vars
  const [, setTeam] = useState();
  const [loading, setLoading] = useState(true);

  let { contactPath: contactPathParam = null } = useParams();

  let initialScreenType = ScreenTypes.SHOW_OPTIONS;
  let initialContactPath = '';
  let initialCollectPhoneTitle = t('ga_runoff.want_to_help');

  if (contactPathParam) {
    switch (contactPathParam) {
      case ContactPaths.GA_NONRESIDENT:
        initialScreenType = ScreenTypes.COLLECT_PHONE;
        initialContactPath = ContactPaths.GA_NONRESIDENT;
        initialCollectPhoneTitle = t('ga_runoff.want_to_help');
        break;

      case ContactPaths.GA_UNREGISTERED:
        initialScreenType = ScreenTypes.COLLECT_PHONE;
        initialContactPath = ContactPaths.GA_UNREGISTERED;
        initialCollectPhoneTitle = t('ga_runoff.not_sure');
        break;

      case ContactPaths.GA_REGISTERED:
        initialScreenType = ScreenTypes.COLLECT_PHONE;
        initialContactPath = ContactPaths.GA_REGISTERED;
        initialCollectPhoneTitle = t('ga_runoff.already_registered');
        break;

      default:
        break;
    }
  }

  const [screenType, setScreenType] = useState(initialScreenType);
  const [collectPhoneTitle, setCollectPhoneTitle] = useState(initialCollectPhoneTitle);
  const [contactPath, setContactPath] = useState(initialContactPath);

  const hostname = `${window.location.protocol}//${window.location.host}`;
  const georgiaLandingLink = `${hostname}/campaigns/${campaignId}/georgia_runoff`;

  // needed to make back button work - if we push to a route where the initialScreenType changes
  useEffect(() => {
    setScreenType(initialScreenType);

    if (contactPathParam) {
      switch (contactPathParam) {
        case ContactPaths.GA_NONRESIDENT:
          setScreenType(ScreenTypes.COLLECT_PHONE);
          setContactPath(ContactPaths.GA_NONRESIDENT);
          setCollectPhoneTitle(t('ga_runoff.want_to_help'));
          break;

        case ContactPaths.GA_UNREGISTERED:
          setScreenType(ScreenTypes.COLLECT_PHONE);
          setContactPath(ContactPaths.GA_UNREGISTERED);
          setCollectPhoneTitle(t('ga_runoff.not_sure'));
          break;

        case ContactPaths.GA_REGISTERED:
          setScreenType(ScreenTypes.COLLECT_PHONE);
          setContactPath(ContactPaths.GA_REGISTERED);
          setCollectPhoneTitle(t('ga_runoff.already_registered'));
          break;

        default:
          setContactPath('');
          setCollectPhoneTitle(t('ga_runoff.want_to_help'));
          break;
      }
    }
  }, [initialScreenType, contactPathParam, t]);

  useEffect(() => {
    if (screenType) {
      track(`VIEW_GA_RUNOFF_${screenType.toUpperCase()}`);
    }
  }, [screenType]);

  useEffect(() => {
    setLoading(true);
    campaigns
      .getPublicCampaign({ id: campaignId })
      .then(({ data: { data: campaign } }) => {
        setCampaign(campaign);

        trackPageViewWithDynamicIDs({
          fbPixelId: campaign.fb_pixel_id,
          gaTrackingId: campaign.ga_tracking_id,
          snapchatPixelId: campaign.snapchat_pixel_id,
        });

        if (referral_hash) {
          return users
            .getUserByReferralHash(referral_hash)
            .then(({ data: { data: user } }) => {
              return teams.getTeam({ filters: { campaign_id: campaign.id }, id: user.id });
            })
            .then(({ data: { data: team } }) => setTeam(team))
            .catch(error => {
              // Catch error and make this promise always resolved,
              // so we can render this view at least
              console.error('get team data >', error);
            });
        }
      })
      .then(() => setLoading(false));
  }, [campaignId, referral_hash]);

  const handleSubmitPhone = useCallback(
    (formData, { setSubmitting }) => {
      const { phone } = formData;
      if (!phone) {
        message.error(t('ga_runoff.phone_required'));
        setSubmitting(false);
        return;
      }
      campaigns
        .gaRegister({ campaign_slug: campaignId, contact_path: contactPath, phone: formData.phone })
        .then(() => {
          setScreenType(ScreenTypes.THANK_YOU);
        })
        .catch(error => {
          console.error('problem with stuff >', error);
          setSubmitting(false);
          message.error(t('ga_runoff.problem_occurred'));
        });
    },
    [campaignId, contactPath, t],
  );

  const handleNativeShare = useCallback(() => {
    if (navigator.share) {
      navigator.share({ url: georgiaLandingLink }).catch(console.error);
    }
  }, [georgiaLandingLink]);

  if (loading) {
    return (
      <div className="flex-row justify-content-center mt-4">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  const shouldRedirect = campaign && !campaign.ga_runoff_enabled;
  if (shouldRedirect) {
    return <Redirect to={`/campaigns/${campaignId}`} />;
  }

  return (
    <MiddleContainer>
      <MainContent>
        {screenType === ScreenTypes.SHOW_OPTIONS && (
          <GetStarted>
            <Title>{t('ga_runoff.georgia_runoff_election')}</Title>
            <SelectAnOption>{t('ga_runoff.select_option')}</SelectAnOption>
            <Options>
              <OptionColumn>
                <OptionDescription>{t('ga_runoff.want_to_help')}</OptionDescription>
                <SquareRoundButton
                  type="primary"
                  children={'find voters'}
                  onClick={() => {
                    setScreenType(ScreenTypes.COLLECT_PHONE);
                    setCollectPhoneTitle(t('ga_runoff.want_to_help'));
                    setContactPath(ContactPaths.GA_NONRESIDENT);
                    history.push(
                      `/campaigns/${campaignId}/georgia_runoff/options/${ContactPaths.GA_NONRESIDENT}`,
                    );
                  }}
                />
              </OptionColumn>

              <OptionColumn>
                <OptionDescription>{t('ga_runoff.already_registered')}</OptionDescription>
                <SquareRoundButton
                  type="primary"
                  children={'vote by mail'}
                  onClick={() => {
                    setScreenType(ScreenTypes.COLLECT_PHONE);
                    setCollectPhoneTitle(t('ga_runoff.already_registered'));
                    setContactPath(ContactPaths.GA_REGISTERED);
                    history.push(
                      `/campaigns/${campaignId}/georgia_runoff/options/${ContactPaths.GA_REGISTERED}`,
                    );
                  }}
                />
              </OptionColumn>

              {/* <OptionColumn>
                <OptionDescription>{t('ga_runoff.not_sure')}</OptionDescription>

                <SquareRoundButton
                  type="primary"
                  children={'register to vote'}
                  onClick={() => {
                    setScreenType(ScreenTypes.COLLECT_PHONE);
                    setCollectPhoneTitle(t('ga_runoff.not_sure'));
                    setContactPath(ContactPaths.GA_UNREGISTERED);
                    history.push(
                      `/campaigns/${campaignId}/georgia_runoff/options/${ContactPaths.GA_UNREGISTERED}`,
                    );
                  }}
                />
              </OptionColumn> */}
            </Options>

            <Alert
              style={{
                backgroundColor: '#e6f7ff',
                fontSize: 13,
                marginTop: 50,
                maxWidth: 600,
              }}
              message={
                <span>
                  <b>Important:</b> The deadline to register to vote in Georgia has passed.
                </span>
              }
              type="info"
              showIcon
            />
          </GetStarted>
        )}
        {screenType === ScreenTypes.COLLECT_PHONE && (
          <CollectPhone
            title={collectPhoneTitle}
            description={t('ga_runoff.enter_your_number')}
            handleSubmitPhone={handleSubmitPhone}
            terms={campaign.terms}
            termsLink={campaign.terms_link}
          />
        )}
        {screenType === ScreenTypes.THANK_YOU && (
          <ThankYou handleNativeShare={handleNativeShare} shareLink={georgiaLandingLink} />
        )}
      </MainContent>
    </MiddleContainer>
  );
};

GaOptionsPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default GaOptionsPage;
