import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import SurveyActivity from '@web/components/SurveyActivity';
import { user, teams } from '@web/services/api';
import TeamActivityVoterRegistrationPage from './TeamActivityVoterRegistrationPage';

const TeamActivityPage = () => {
  const { activitySlug, campaignSlug, teamSlug } = useParams();
  const [activity, setActivity] = useState(null);
  const [team, setTeam] = useState(null);

  React.useEffect(() => {
    user.getPublicActivity(campaignSlug, activitySlug).then(({ data: activity }) => {
      setActivity(activity);
    });
  }, [activitySlug, campaignSlug]);

  React.useEffect(() => {
    teams.getTeamBySlug({ slug: teamSlug }).then(({ data }) => {
      setTeam(data.data);
    });
  }, [teamSlug]);

  if (!activity || !team) {
    return (
      <div className="flex-row justify-content-center mt-4">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  // Team Survey activity
  if (activity.type === 'SurveyActivity') {
    return <SurveyActivity activity={activity} team={team} />;
  }

  // All VR activities are handled within this component
  return <TeamActivityVoterRegistrationPage activity={activity} team={team} />;
};

TeamActivityPage.propTypes = {};

export default TeamActivityPage;
