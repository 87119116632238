import { message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import { userReportApi } from '../../../services/api';
import FillReportContent from './FillReportContent';
import {
  formatCustomizationResponse,
  formatTaggingsResponse,
  formatUserReportBody,
} from '../utils';

import { AuthContext } from '../../../utils/context';
import useLocalStorage from '@admin/hooks/useLocalStorage';

const FillReport = ({
  campaignId,
  contactId,
  activityId,
  archiveThread,
  archived,
  userActivityId,
  reportActivityId,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const [reportId, setReportId] = useState({});
  const [userReport, setUserReport] = useState({});
  const [initialUserReport, setInitialUserReport] = useState({});
  const [loading, setLoading] = useState(false);

  // Get whether to archive messages after submitting a report
  // We need to use a unique key to avoid when a user uses 2 or more accounts on the same device.
  const [automaticallyArchiveThread, setAutomaticallyArchiveThread] = useLocalStorage(
    `${user.id}-archive-thread`,
    false,
  );

  const { taggings = [], customizations = [], activityName = '' } = userReport;

  const setReportData = React.useCallback(data => {
    const {
      data: { id: reportId, taggings, customizations, activity_name },
    } = data;
    setReportId(reportId);

    const strippedTaggings = formatTaggingsResponse(taggings);

    const strippedCustomizations = formatCustomizationResponse(customizations);

    setUserReport({
      activityName: activity_name,
      customizations: strippedCustomizations,
      taggings: strippedTaggings,
    });
    setInitialUserReport({
      activityName: activity_name,
      customizations: strippedCustomizations,
      taggings: strippedTaggings,
    });
  }, []);

  // Load latest outreach report when conditions are met.
  useEffect(() => {
    if (!campaignId || !contactId || !activityId) return;

    setLoading(true);

    userReportApi
      .findOrCreateReport({
        activity_id: activityId,
        campaign_id: campaignId,
        contact_id: contactId,
      })
      .then(userReportDataResponse => {
        if (!userReportDataResponse || userReportDataResponse.status !== 200) {
          message.error(t('errors.fetch_outreach_report'));
          return;
        }

        setReportData(userReportDataResponse);
        setLoading(false);
      })
      .catch(err => {
        message.error(t('errors.fetch_report'));
        setLoading(false);
        return;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, contactId, activityId]);

  const submitReport = async () => {
    setLoading(true);

    const userReportBody = formatUserReportBody(reportId, customizations, taggings);

    await userReportApi
      .update({ campaign_id: campaignId, report_id: reportId }, userReportBody)
      .then(data => {
        message.success(t('common.submitted_successfully'));

        if (automaticallyArchiveThread && !archived) {
          archiveThread();
        }

        setReportData(data);
        setLoading(false);
      })
      .catch(err => {
        message.error(t('errors.submit_outreach_report'));
        setLoading(false);
      });
  };

  if (taggings.length === 0 && customizations.length === 0) {
    return null;
  }

  return (
    <FillReportContent
      userActivityId={userActivityId}
      archived={archived}
      customizations={customizations}
      taggings={taggings}
      disableSubmitButton={isEqual(initialUserReport, userReport)}
      loading={loading}
      setUserReport={setUserReport}
      submitReport={submitReport}
      initialUserReport={initialUserReport}
      setAutomaticallyArchiveThread={setAutomaticallyArchiveThread}
      automaticallyArchiveThread={automaticallyArchiveThread}
      reportActivityId={reportActivityId}
      isNewReportActivity={activityId === reportActivityId}
      reportActivityName={activityName}
    />
  );
};

FillReport.propTypes = {
  activityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  archived: PropTypes.bool,
  archiveThread: PropTypes.func,
  campaignId: PropTypes.number,
  contactId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  reportActivityId: PropTypes.number,
  userActivityId: PropTypes.number,
};

FillReport.defaultProps = {
  campaignId: null,
  firstName: null,
  lastName: null,
};

export default FillReport;
