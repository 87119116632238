import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import VotingPlanUnderline from '@web/components/FanOut/components/icons/VotingPlan';
import { capitalize } from '@web/utils/string';
import {
  smallBreakpoint,
  small800Breakpoint,
} from '@web/components/VoterRegistration/newComponents/breakpoints';
import { useIs800 } from '@web/components/VoterRegistration/hooks/useIsMobile';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 69px;

  ${smallBreakpoint} {
    margin-top: 30px;
    margin-bottom: 46px;
  }

  ${small800Breakpoint} {
    margin-top: 50px;
    margin-bottom: 26px;
  }
`;

const TitleText = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 3rem;
  line-height: 4rem;
  position: relative;
  text-align: center;

  ${small800Breakpoint} {
    font-size: 1.75rem;
    line-height: 2.25rem; /* 128.571% */
  }
`;

const StyledVotingPlanUnderline = styled(VotingPlanUnderline)`
  position: absolute;
  bottom: -12px;
  left: 3px;
  overflow: hidden;
`;

const ReferrerText = styled.div`
  margin-top: 32px;
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.75rem;
  line-height: 2rem; /* 114.286% */
  text-align: center;

  ${small800Breakpoint} {
    font-size: 1.25rem;
    line-height: 1.75rem; /* 140% */
  }

  ${smallBreakpoint} {
    margin-top: 24px;
  }
`;

const TitleResults = ({ referrer, willEarnPoints }) => {
  const { t } = useTranslation();
  const isMobile = useIs800();

  const referrerName = useMemo(() => capitalize(referrer?.fullname || ''), [referrer]);

  const referrerText = useMemo(
    () =>
      willEarnPoints
        ? t('fan_out.voting_plan.results.will_earn_entries', {
            referrer: referrerName,
          })
        : t('fan_out.voting_plan.results.will_not_earn_entries', {
            referrer: referrerName,
          }),
    [referrerName, willEarnPoints, t],
  );

  return (
    <TextWrapper>
      <TitleText>{t('fan_out.voting_plan.results.thanks')}</TitleText>
      <TitleText>
        {t('fan_out.voting_plan.results.voting_plan')}
        <StyledVotingPlanUnderline type={'secondary'} isMobile={isMobile} />
      </TitleText>
      {willEarnPoints !== null && <ReferrerText>{referrerText}</ReferrerText>}
    </TextWrapper>
  );
};

TitleResults.propTypes = {
  referrer: PropTypes.shape({
    fullname: PropTypes.string,
    id: PropTypes.number,
  }),
  willEarnPoints: PropTypes.bool,
};

TitleResults.defaultProps = {};

export default TitleResults;
