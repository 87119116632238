import React from 'react';
// import PropTypes from 'prop-types';

function Google(props) {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99999 3.48C10.69 3.48 11.83 4.21 12.48 4.82L15.02 2.34C13.46 0.89 11.43 0 8.99999 0C5.47999 0 2.43999 2.02 0.959991 4.96L3.86999 7.22C4.59999 5.05 6.61999 3.48 8.99999 3.48V3.48Z"
        fill="#EA4335"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.64 9.19999C17.64 8.45999 17.58 7.91999 17.45 7.35999H9V10.7H13.96C13.86 11.53 13.32 12.78 12.12 13.62L14.96 15.82C16.66 14.25 17.64 11.94 17.64 9.19999V9.19999Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.88 10.78C3.69 10.22 3.58 9.62002 3.58 9.00002C3.58 8.38002 3.69 7.78002 3.87 7.22002L0.96 4.96002C0.35 6.18002 0 7.55002 0 9.00002C0 10.45 0.35 11.82 0.96 13.04L3.88 10.78V10.78Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18C11.43 18 13.47 17.2 14.96 15.82L12.12 13.62C11.36 14.15 10.34 14.52 9 14.52C6.62 14.52 4.6 12.95 3.88 10.78L0.970001 13.04C2.45 15.98 5.48 18 9 18V18Z"
        fill="#34A853"
      />
    </svg>
  );
}

Google.propTypes = {};

Google.defaultProps = {};

export default Google;
