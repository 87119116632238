import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import Logo from '@web/components/Logo';

const Box = styled(Link)`
  background-color: rgba(255, 255, 255, 0.8);
  display: inline-flex;
  padding: 14px;
`;

// MTS - Needs to be wrapped in a div or else it expands full height
const BoxWrapper = styled.div``;

function BoxLogo() {
  return (
    <BoxWrapper>
      <Box to="/frontline">
        <Logo height="22" />
      </Box>
    </BoxWrapper>
  );
}

BoxLogo.propTypes = {};

BoxLogo.defaultProps = {};

export default BoxLogo;
