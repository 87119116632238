import React from 'react';
import PropTypes from 'prop-types';

import { ErrorIcon, SentIcon } from './Icons';
import { StarFilled } from '@ant-design/icons';
import styled from 'styled-components';
import theme from '../../styles/theme';

import { useTranslation } from 'react-i18next';
import { convertLinks, localizedDateTime } from '../../utils/string';
import { Tooltip } from 'antd';

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
`;

const Spacer = styled.div`
  flex: 1;
`;

const MessageContainer = styled.div`
  flex: 3;
  order: ${props => (props.from_me ? 1 : 0)};
  padding-bottom: 1rem;
`;

const Body = styled.div`
  background-color: ${props =>
    props.from_me
      ? props.$isError
        ? 'rgba(245, 34, 45, 0.32)'
        : theme.colors.lightCyan
      : theme.colors.whiteSmokeLighter};

  color: ${theme.colors.darksLateGray};
  border-radius: 1rem;
  padding: 1rem;
  font-family: ${theme.fonts.regular};
  white-space: pre-line;
`;

const Timestamp = styled.div`
  color: ${theme.colors.dimGray2};
  font-size: 0.8rem;
  font-family: ${theme.fonts.regular};
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: ${props => (props.from_me ? 'flex-end' : 'flex-start')};
  margin-top: 8px;
`;

const DeliveryStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #f5222d;
  gap: 4px;
  margin-right: 8px;
`;

const TeamLeader = styled.div`
  color: ${theme.colors.coral};
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  > span {
    margin-left: 5px;
  }
`;

const SenderData = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.black};
  font-weight: 700;
  margin-bottom: 1rem;
`;

const Message = React.forwardRef(({ from_me, body, created_at, sender, type, aasm_state }, ref) => {
  const { t } = useTranslation();
  const dateText = created_at === 'Sending...' ? t('inbox.sending') : localizedDateTime(created_at);

  const isError = aasm_state === 'error';
  const showErrorData = isError && from_me;

  return (
    <Wrapper ref={ref}>
      <MessageContainer from_me={from_me} tabIndex={0}>
        {!from_me && type === 'TeamMessage' && (
          <SenderData>
            <span>{sender.fullname}</span>
            {sender.is_team_admin && (
              <TeamLeader>
                Team Leader <StarFilled />
              </TeamLeader>
            )}
          </SenderData>
        )}
        <Tooltip title={showErrorData && t('inbox.delivery_error_message')}>
          <Body
            $isError={showErrorData}
            from_me={from_me}
            dangerouslySetInnerHTML={{
              __html: convertLinks(body),
            }}
          />
          <FlexWrapper from_me={from_me}>
            {from_me && (
              <DeliveryStatus>
                {showErrorData ? (
                  <>
                    <ErrorIcon /> {t('inbox.not_delivered')}
                  </>
                ) : (
                  <SentIcon />
                )}
              </DeliveryStatus>
            )}

            <Timestamp>{dateText}</Timestamp>
          </FlexWrapper>
        </Tooltip>
      </MessageContainer>
      <Spacer />
    </Wrapper>
  );
});

Message.propTypes = {
  aasm_state: PropTypes.string,
  body: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  from_me: PropTypes.bool.isRequired,
  sender: PropTypes.shape({
    fullname: PropTypes.string,
    is_team_admin: PropTypes.bool,
  }),
  type: PropTypes.string,
};

export default Message;
