import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from '@web/components/Headcount/components/Text';

const StyledListItem = styled.li`
  margin-top: ${({ theme }) => theme.spacing.normal};
`;

function ListItem({ children, shouldDangerouslySet }) {
  return (
    <StyledListItem>
      {shouldDangerouslySet ? (
        <Text as="span" color="black" variant="f3" dangerouslySetInnerHTML={{ __html: children }} />
      ) : (
        <Text as="span" color="black" variant="f3">
          {children}
        </Text>
      )}
    </StyledListItem>
  );
}

ListItem.propTypes = {
  children: PropTypes.any.isRequired,
  shouldDangerouslySet: PropTypes.bool,
};

ListItem.defaultProps = { shouldDangerouslySet: false };

export default ListItem;
