// MTS - The purpose of this hook is keep track of a "count". The "count" needs
// to update each time incrementBatch is called. After 3 seconds we call the
// onBatchComplete callback with the current number "count" that was incremented
// in that 3 second window. Each 3 second window is a "batch".
import * as React from 'react';

const DELAY = 3000;

function useBatch(onBatchComplete) {
  const [loading, setLoading] = React.useState(false);
  const [batching, setBatching] = React.useState(false);
  const countRef = React.useRef(0);

  React.useEffect(() => {
    let timeoutId;
    if (batching) {
      timeoutId = setTimeout(() => {
        onBatchComplete(countRef.current);
        countRef.current = 0;
        setBatching(() => false);
      }, DELAY);
    }
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, [batching]);

  function incrementBatch() {
    setLoading(true);
    countRef.current += 100;
    setBatching(true);
  }

  return { incrementBatch, loading };
}

export default useBatch;
