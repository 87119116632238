import { Alert, Col } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem } from 'formik-antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'web/components/i18n';
import * as yup from 'yup';
import {
  CollectPhoneDescription,
  GetStarted,
  SubmitButtonContainer,
  StyledLabel,
  StyledInput,
  SquareRoundButton,
  SubHeader,
  TermsAndConditions,
} from './styles';

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const ContactFormSchema = yup.object().shape({
  phone: yup.string().matches(phoneRegExp, i18n.t('check_registration.invalid_phone')),
});

const formInitialValues = {
  phone: '',
};

const CollectPhone = ({ title, description, handleSubmitPhone, terms, termsLink }) => {
  const { t } = useTranslation();

  const termsAndConditions = terms ? (
    <TermsAndConditions>
      <p>{terms}</p>
      {termsLink && (
        <a href={termsLink} target="_blank" rel="noopener noreferrer">
          {t('check_registration.read_more')}
        </a>
      )}
    </TermsAndConditions>
  ) : (
    <TermsAndConditions>
      <div>
        {t('ga_runoff.by_clicking_submit')}
        <a href="/outvote/terms_of_service" target="_blank" rel="noopener noreferrer">
          {t('ga_runoff.tos')}
        </a>
        {'.'}
      </div>
    </TermsAndConditions>
  );

  return (
    <GetStarted>
      <SubHeader>{title}</SubHeader>
      <CollectPhoneDescription>{description}</CollectPhoneDescription>
      <Formik
        initialValues={formInitialValues}
        validationSchema={ContactFormSchema}
        onSubmit={handleSubmitPhone}
        render={({ isSubmitting, isValid, submitCount }) => (
          <Form>
            <Col>
              <FormItem name="phone">
                <StyledLabel htmlFor="phone">{t('ga_runoff.phone_number')}</StyledLabel>
                <StyledInput name="phone" placeholder={'(111) 222 - 3333'} id="phone" />
              </FormItem>
            </Col>
            <SubmitButtonContainer>
              <SquareRoundButton
                type="submit"
                disabled={isSubmitting || (!isValid && submitCount > 0)}
              >
                {isSubmitting ? t('ga_runoff.submitting') : t('ga_runoff.submit')}
              </SquareRoundButton>
            </SubmitButtonContainer>
          </Form>
        )}
      />
      {title === t('ga_runoff.already_registered') && (
        <Alert
          style={{ marginTop: 20 }}
          message={t('voting_information.voter_reg_required')}
          type="info"
          showIcon
          closable
        />
      )}
      {termsAndConditions}
    </GetStarted>
  );
};

CollectPhone.propTypes = {
  description: PropTypes.string.isRequired,
  handleSubmitPhone: PropTypes.func.isRequired,
  terms: PropTypes.string,
  termsLink: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default CollectPhone;
