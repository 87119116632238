import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import ImpactiveCarousel from '../common/shared/Carousel';
import useElementsPerSlide from '../Inbox/hooks/useElementsPerSlide';

import ActivityPreviewModal from './ActivityPreviewModal';
import ActivityPreview from './ActivityPreview';
import NavArrows from './NavArrows';

const Wrapper = styled.div``;

const Slide = styled.div`
  display: grid !important;
  grid-template-columns: ${({ elementPerSlide }) => (elementPerSlide < 4 ? '1fr' : '1fr 1fr')};
  height: 100%;
  padding-top: 30px;
  gap: 10px;
  padding: 11px;
`;

const TransparentButton = styled.div`
  border: none;
  background: none;
  text-align: unset;
`;

const PinnedActivityCarousel = ({ activities }) => {
  const carouselRef = useRef(null);
  const wrapperRef = useRef(null);
  const slideData = useElementsPerSlide(activities, wrapperRef, 470);

  const [selectedAction, setSelectedAction] = useState();

  const createActivityPreview = userActivity => {
    const { activity, completed } = userActivity;

    return (
      <TransparentButton key={activity.id} onClick={() => setSelectedAction(userActivity)}>
        <ActivityPreview activity={activity} completed={completed} userActivity={userActivity} />
      </TransparentButton>
    );
  };

  if (activities.length === 1) {
    return createActivityPreview(activities[0]);
  }

  if (!slideData) return <></>;

  const showNavArrows = slideData.scripts?.length > 1;

  return (
    <>
      <Wrapper ref={wrapperRef}>
        <ImpactiveCarousel autoplaySpeed={10000} arrows ref={carouselRef}>
          {slideData.scripts.map((i, index) => (
            <Slide key={index} elementPerSlide={slideData.elementsPerSlide}>
              {i.map(createActivityPreview)}
            </Slide>
          ))}
        </ImpactiveCarousel>
        {showNavArrows && (
          <NavArrows
            next={() => carouselRef.current && carouselRef.current.next()}
            prev={() => carouselRef.current && carouselRef.current.prev()}
          />
        )}
      </Wrapper>
      <ActivityPreviewModal
        visible={!!selectedAction}
        onCancel={() => setSelectedAction(null)}
        activity={selectedAction?.activity}
        completed={selectedAction?.completed}
        userActivity={selectedAction}
      />
    </>
  );
};

PinnedActivityCarousel.propTypes = { activities: PropTypes.array.isRequired };

PinnedActivityCarousel.defaultProps = {};

export default PinnedActivityCarousel;
