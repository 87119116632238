import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 34px 16px;
  border-bottom: 2px solid black;
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 700;
`;

const Subtitle = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: black;
`;

const Header = () => {
  return (
    <Wrapper>
      <Title>Frequently Asked Questions</Title>
      <Subtitle>
        This website was created to increase public awareness of the importance of voting and
        encourage everyone to vote. When we all vote, we all win.
      </Subtitle>
    </Wrapper>
  );
};

export default Header;
