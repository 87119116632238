import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import theme from '@web/styles/theme';

const RefreshFormIcon = styled.button`
  position: absolute;
  right: ${({ $position }) => ($position ? $position.right : '16px')};
  top: ${({ $position }) => ($position ? $position.top : '16px')};
  border: none;
  padding: 0;
  background: transparent;
  color: ${({ $color }) => $color ?? theme.colors.black};
  stroke-width: 15px;
  stroke: ${({ $color }) => $color ?? theme.colors.black};
  background: #ffffff80;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const RecheckRegistrationIcon = ({ onClick, position, color }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('fan_out.my_activity.recheck')} placement="left">
      <RefreshFormIcon
        $color={color}
        type="button"
        $position={position}
        onClick={onClick}
        as="button"
      >
        <RedoOutlined />
      </RefreshFormIcon>
    </Tooltip>
  );
};

RecheckRegistrationIcon.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  position: PropTypes.shape({
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

export default memo(RecheckRegistrationIcon);
