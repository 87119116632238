import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/style.css';

import FormInput from '@web/components/common/shared/FormInput';
import RefreshFormButton from '@web/components/common/RefreshFormIcon';
import FormDatePicker from '@web/components/common/shared/FormDatePicker';
import PhoneNumberInput from '@web/components/common/shared/PhoneNumberInput';
import AddressFormField from '@web/components/common/shared/AddressFormField';

import { availableQuestions, requiredFieldsProps } from './utils';

import styled from 'styled-components';

const AddressesWrapper = styled.div`
  display: flex;
  gap: 16px;
  > div {
    width: 100%;
  }
`;

const VolunteerInfoFields = ({ activity, initialValues, values, handleChange, setFieldValue }) => {
  const addressRef = React.useRef(null);
  const fields = useMemo(() => {
    const questionKeys = Object.keys(initialValues);
    return availableQuestions.filter(question => questionKeys.includes(question));
  }, [initialValues]);

  // We have only one "address" field, but when we set it from autocomplete, we also set "city" and "state_abbrev", so we have to cleanup these fields as well
  const fieldsToClear = useMemo(() => [...fields, 'city', 'state_abbrev', 'unit_number'], [fields]);

  const clearVolunteerInfo = useCallback(() => {
    fieldsToClear.forEach(field => setFieldValue(field, ''));
    if (addressRef.current) {
      addressRef.current.value = '';
    }
  }, [fieldsToClear, setFieldValue]);

  const setAddress = useCallback(
    address => {
      setFieldValue('address', address.address);
      setFieldValue('zip_code', address.zip_code);
      setFieldValue('city', address.city);
      setFieldValue('state_abbrev', address.state_abbrev);
    },
    [setFieldValue],
  );

  const {
    form_field_background_color,
    form_field_font_color,
    form_field_outline_color,
    main_font_color,
  } = activity.settings ?? {};

  return (
    <>
      <RefreshFormButton color={main_font_color} clearData={clearVolunteerInfo} />
      {fields.map((field, index) => {
        if (field === 'date_of_birth') {
          return (
            <FormDatePicker
              key={field}
              prefix={requiredFieldsProps[field]?.prefix}
              label={requiredFieldsProps[field]?.label}
              placeholder={requiredFieldsProps[field]?.placeholder}
              name={field}
              value={values[field]}
              onChange={e => setFieldValue(field, e.target.value)}
              fontColor={form_field_font_color}
              backgroundColor={form_field_background_color}
              outlineColor={form_field_outline_color}
              labelFontColor={main_font_color}
            />
          );
        }

        if (field === 'address') {
          const address2Field = 'unit_number';
          return (
            <>
              {/* We need this to change grid to keep 2 address fields in one row */}
              {index % 2 !== 0 && <div></div>}
              <AddressesWrapper>
                <AddressFormField
                  placeholder={requiredFieldsProps[field]?.placeholder}
                  label={requiredFieldsProps[field]?.label.toLocaleUpperCase()}
                  name={field}
                  inputRef={addressRef}
                  key={field}
                  onChange={setAddress}
                  fontColor={form_field_font_color}
                  backgroundColor={form_field_background_color}
                  outlineColor={form_field_outline_color}
                  labelFontColor={main_font_color}
                />
                <FormInput
                  key={address2Field}
                  prefix={requiredFieldsProps[address2Field]?.prefix}
                  label={requiredFieldsProps[address2Field]?.label}
                  placeholder={requiredFieldsProps[address2Field]?.placeholder}
                  name={address2Field}
                  value={values[address2Field]}
                  onChange={handleChange}
                  fontColor={form_field_font_color}
                  backgroundColor={form_field_background_color}
                  outlineColor={form_field_outline_color}
                  labelFontColor={main_font_color}
                />
              </AddressesWrapper>
            </>
          );
        }

        if (field === 'phone') {
          return (
            <PhoneNumberInput
              key={field}
              name={field}
              label={requiredFieldsProps[field]?.label}
              placeholder={requiredFieldsProps[field]?.placeholder}
              onChange={e => setFieldValue(field, e)}
              value={values[field]}
              isSurvey
              prefixIcon={requiredFieldsProps[field]?.prefix}
              fontColor={form_field_font_color}
              backgroundColor={form_field_background_color}
              outlineColor={form_field_outline_color}
              labelFontColor={main_font_color}
            />
          );
        }

        return (
          <FormInput
            key={field}
            prefix={requiredFieldsProps[field]?.prefix}
            label={requiredFieldsProps[field]?.label}
            placeholder={requiredFieldsProps[field]?.placeholder}
            name={field}
            value={values[field]}
            onChange={handleChange}
            fontColor={form_field_font_color}
            backgroundColor={form_field_background_color}
            outlineColor={form_field_outline_color}
            labelFontColor={main_font_color}
          />
        );
      })}
    </>
  );
};

VolunteerInfoFields.propTypes = {
  activity: PropTypes.shape({
    settings: PropTypes.shape({
      form_field_background_color: PropTypes.string,
      form_field_font_color: PropTypes.string,
      form_field_outline_color: PropTypes.string,
      main_font_color: PropTypes.string,
      secondary_color: PropTypes.string,
    }),
  }),
  handleChange: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default VolunteerInfoFields;
