import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { media } from '@web/styles/theme';

import JoinButton from '@web/components/common/JoinButton';

import backgroundImage from './assets/bluesquare.png';

const StyledCampaignTakeAction = styled.div`
  padding: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightBlue};
`;

const Container = styled.div`
  padding: 80px 25px;

  ${media.xxxl} {
    padding: 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 3fr;
  }
`;

const SectionTitle = styled.h3`
  font-size: 2em;
  color: ${({ theme }) => theme.colors.blackL25};
  font-weight: 600;
  margin-bottom: 24px;

  ${media.xxxl} {
    font-size: 4em;
  }
`;

const AboutUsMedia = styled.div`
  display: none;

  ${media.xxxl} {
    display: block;
    position: relative;

    img {
      display: block;
      position: absolute;
      right: -30%;
      top: -30%;
      width: 920px;
      height: 920px;
    }
  }
`;

const AboutUsDetails = styled.div`
  ${media.lg} {
    text-align: center;
  }

  ${media.xxxl} {
    padding: 180px 0;
  }
`;

const SectionDescription = styled.p`
  font-size: 1.4em;
  margin-bottom: 60px;
  color: rgba(89, 99, 134, 0.6);
`;

const CenteredButton = styled.div`
  text-align: center;
`;

const CampaignTakeAction = ({ joined, handleGetStarted }) => {
  const { t } = useTranslation();

  return (
    <StyledCampaignTakeAction>
      <Container>
        <AboutUsMedia>
          <img src={backgroundImage} alt="blue-square" />
        </AboutUsMedia>
        <AboutUsDetails>
          <SectionTitle>{t('campaign.ready_to_take_action')}</SectionTitle>
          <SectionDescription>{t('campaign.discover_new_actions')}</SectionDescription>
          <CenteredButton>
            <JoinButton joined={joined} onClick={handleGetStarted}>
              {joined ? t('campaign.joined') : t('campaign.join_our_campaign')}
            </JoinButton>
          </CenteredButton>
        </AboutUsDetails>
      </Container>
    </StyledCampaignTakeAction>
  );
};

CampaignTakeAction.propTypes = {
  handleGetStarted: PropTypes.func.isRequired,
  joined: PropTypes.bool.isRequired,
};

export default CampaignTakeAction;
