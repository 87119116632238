import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ImpactiveButton from '../../ImpactiveButton';

const Button = styled(ImpactiveButton)`
  background: ${({ allSelected, theme }) => (allSelected ? theme.colors.gray : theme.colors.blue)};
`;

const StyledSelectAll = styled.div`
  display: flex;
  justify-content: flex-start;
  outline: 0;
`;

function SelectAll({ allSelected, onClick }) {
  const { t } = useTranslation();
  return (
    <StyledSelectAll>
      <Button onClick={onClick}>
        {allSelected ? t('contact.sync.deselect_all') : t('contact.sync.select_all')}
      </Button>
    </StyledSelectAll>
  );
}

SelectAll.propTypes = {
  allSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

SelectAll.defaultProps = {};

export default SelectAll;
