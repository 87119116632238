import styled from 'styled-components';
import ImpactiveButton from 'web/components/ImpactiveButton';

export const OptInForm = styled.div`
  align-items: center;
  background: #f4f5f6;
  color: #000;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 1.5rem;
  height: 100%;
  justify-content: center;
  padding: 3rem;
  width: 100%;
`;

export const InitialContainer = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const OptInImg = styled.img`
  display: block;
  max-width: 512px;
  max-height: 512px;
`;

export const Title = styled.h1`
  max-width: 512px;
  padding-top: 2rem;
  text-transform: uppercase;
  text-align: center;
`;

export const Subtitle = styled.div`
  max-width: 512px;
  padding-bottom: 2rem;
  text-align: center;
`;

export const OptInButton = styled(ImpactiveButton)`
  font-size: 1.8rem;
  text-transform: uppercase;
  max-width: 512px;
`;

export const Disclaimer = styled.div`
  color: $gray-800;
  font-size: 1.2rem;
  max-width: 512px;
  padding-top: 3.125rem;
  text-align: center;
`;

export const DisclaimerLink = styled.a`
  color: $gray-800;
  font-size: 1.2rem;
  max-width: 512px;
  margin-top: 1rem;
  text-align: center;
  display: block;
`;

export const ThankYouContainer = styled.div`
  color: white;
`;
