import { useMedia } from '@web/hooks';

function useIsMobile() {
  return useMedia('(max-width: 957px)');
}

export const useIs800 = () => {
  return useMedia('(max-width: 800px)');
};

export default useIsMobile;
