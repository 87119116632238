import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../Button';
import Header from '../Header';
import HeroImage from '../HeroImage';
import Link from '@web/components/Headcount/components/Link';
import LanguageToggle from './LanguageToggle';

import useIsDesktop from '../../hooks/useIsDesktop';
import useIsPartner from '../../hooks/useIsPartner';
import useRedirectRules from '../../hooks/useRedirectRules';
import { useTranslation } from 'react-i18next';

import { replaceDefaultCampaignImage } from '@web/utils/string';
import { trackPageViewWithDynamicIDs } from '@web/services/analytics';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const StyledLink = styled.div`
  margin-top: ${({ theme }) => theme.spacing.tight};
  font-size: 0.75rem; // 12px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.spacing.wide};
  font-size: 1rem;
`;

const Notice = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.textDarkGray};
  text-align: center;
`;

function ChooseFlow({ activity, flows, setSelectedFlow, team }) {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const isPartner = useIsPartner(team);
  const cannabisRegistrationEnabled = activity?.campaign?.cannabis_registration_enabled;
  const redirectUrl = useRedirectRules({ activity, setSelectedFlow });
  const history = useHistory();

  function getTeamImage() {
    if (!isPartner) {
      return null;
    }
    if (isDesktop) {
      return team?.hero_img_url;
    }
    return team?.mobile_hero_img_url;
  }
  const teamImageSrc = getTeamImage();
  const logoUrl = isPartner && team.profile_img_url ? team.profile_img_url : activity.media_url;

  function scrollToCTAButtons() {
    if (!isDesktop) {
      document.getElementById('cta-buttons').scrollIntoView({ behavior: 'smooth' });
    }
  }

  function checkForRedirect(activity, setSelectedFlow) {
    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      setSelectedFlow(flows.CHECK_REGISTRATION);
    }
  }

  React.useEffect(() => {
    trackPageViewWithDynamicIDs({
      fbPixelId: activity?.settings?.pixel_fb_id,
      gaTrackingId: activity?.settings?.pixel_ga_id,
      gtmPixelId: activity?.settings?.pixel_gtm_id,
      snapchatPixelId: activity?.settings?.pixel_sc_id,
    });
  }, [
    activity?.settings?.pixel_fb_id,
    activity?.settings?.pixel_ga_id,
    activity?.settings?.pixel_gtm_id,
    activity?.settings?.pixel_sc_id,
  ]);

  // Admin can add multiple incentives to the activity, but as a short-term solution we use first incentive until find better solution
  const activityIncentive = React.useMemo(
    () =>
      activity?.activity_incentives_attributes?.length > 0
        ? activity?.activity_incentives_attributes[0]
        : null,
    [activity],
  );

  const headcountMtvEnabled = React.useMemo(
    () => activity?.campaign?.headcount_mtv_enabled,
    [activity?.campaign?.headcount_mtv_enabled],
  );

  const headcountRtcEnabled = React.useMemo(
    () => activity?.campaign?.headcount_rct_enabled,
    [activity?.campaign?.headcount_rct_enabled],
  );

  const noticeText = React.useMemo(() => {
    if (headcountMtvEnabled) {
      return t('check_registration.headcount.mtv.notice');
    }

    if (headcountRtcEnabled) {
      return t('check_registration.headcount.rct.notice');
    }
    return t('check_registration.headcount.notice');
  }, [headcountMtvEnabled, headcountRtcEnabled, t]);

  const noticeRedText = React.useMemo(() => {
    if (headcountMtvEnabled) {
      return t('check_registration.headcount.mtv.notice_red');
    }

    if (headcountRtcEnabled) {
      return t('check_registration.headcount.rct.notice_red');
    }
    return t('check_registration.headcount.notice_red');
  }, [headcountMtvEnabled, headcountRtcEnabled, t]);

  return (
    <>
      <LanguageToggle />
      <Header
        onClick={scrollToCTAButtons}
        alt={activity.title}
        src={logoUrl}
        url={activity?.campaign?.website_url}
      />
      {!isDesktop && !isPartner && (
        <HeroImage
          onClick={scrollToCTAButtons}
          alt={activity.title}
          src={activity?.mobile_hero_img_url}
        />
      )}
      {!isDesktop && teamImageSrc && (
        <HeroImage onClick={scrollToCTAButtons} alt={team?.name} src={teamImageSrc} />
      )}
      {!isDesktop && isPartner && (
        <HeroImage onClick={scrollToCTAButtons} alt={team?.name} src={team?.incentives_img_url} />
      )}
      {!isDesktop && !isPartner && (
        <HeroImage
          onClick={scrollToCTAButtons}
          alt={activityIncentive?.name}
          src={replaceDefaultCampaignImage(activityIncentive?.image_url)}
        />
      )}
      <ButtonWrapper id="cta-buttons">
        {headcountRtcEnabled ? (
          <StyledButton onClick={() => checkForRedirect(activity, setSelectedFlow)} size="large">
            {t('check_registration.headcount.rct.ctaName')}
          </StyledButton>
        ) : (
          <>
            <StyledButton
              bgColor={cannabisRegistrationEnabled ? 'black' : isPartner ? '' : 'green'}
              onClick={() => setSelectedFlow(flows.CHECK_REGISTRATION)}
              size="large"
              variant={cannabisRegistrationEnabled ? 'solid' : isPartner ? 'primary' : 'colored'}
            >
              {t('check_registration.headcount.flowCheckRegistration')}
            </StyledButton>
            <StyledButton
              bgColor={cannabisRegistrationEnabled ? 'white' : isPartner ? '' : 'teal'}
              onClick={() => setSelectedFlow(flows.REGISTER_TO_VOTE)}
              size="large"
              variant={cannabisRegistrationEnabled ? 'outlined' : isPartner ? 'primary' : 'colored'}
            >
              {t('check_registration.headcount.flowRegisterToVote')}
            </StyledButton>
            <StyledButton
              bgColor={cannabisRegistrationEnabled ? 'white' : isPartner ? '' : 'purple'}
              onClick={() => setSelectedFlow(flows.WANT_TO_HELP)}
              size="large"
              variant={
                cannabisRegistrationEnabled ? 'outlined' : isPartner ? 'secondary' : 'colored'
              }
            >
              {t('check_registration.headcount.flowWantToHelp')}
            </StyledButton>
          </>
        )}
      </ButtonWrapper>
      {headcountRtcEnabled && <Notice style={{ fontSize: 12 }}>{noticeText}</Notice>}

      {!cannabisRegistrationEnabled && isPartner && (
        <>
          {!headcountRtcEnabled && (
            <>
              <Notice style={{ fontSize: 12 }}>{noticeText}</Notice>
              <Notice style={{ color: 'red', fontSize: 12 }}>{noticeRedText}</Notice>
            </>
          )}
          <StyledLink>
            <Link
              href={team?.terms_link || activity?.terms_link || activity?.campaign?.terms_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('check_registration.headcount.termsAndConditions')}
            </Link>
          </StyledLink>
          <StyledLink>
            <Link href={activity?.campaign?.privacy_link} target="_blank" rel="noopener noreferrer">
              {t('check_registration.headcount.privacyPolicy')}
            </Link>
          </StyledLink>
        </>
      )}
      {cannabisRegistrationEnabled && (
        <>
          <Notice>{t('check_registration.headcount.cannabis_notice')}</Notice>
          <Notice style={{ color: 'red', fontSize: 12 }}>
            {t('check_registration.headcount.notice_red')}
          </Notice>
          <StyledLink>
            <Link href={activity?.campaign?.privacy_link} target="_blank" rel="noopener noreferrer">
              {t('check_registration.headcount.privacyPolicy')}
            </Link>
          </StyledLink>
        </>
      )}
    </>
  );
}

ChooseFlow.propTypes = {
  activity: PropTypes.shape({
    activity_incentives_attributes: PropTypes.arrayOf(
      PropTypes.shape({ image_url: PropTypes.string, name: PropTypes.string }),
    ),
    campaign: PropTypes.object.isRequired,
    hero_img_url: PropTypes.string.isRequired,
    media_url: PropTypes.string,
    mobile_hero_img_url: PropTypes.string.isRequired,
    settings: PropTypes.shape({
      pixel_fb_id: PropTypes.string,
      pixel_ga_id: PropTypes.string,
      pixel_gtm_id: PropTypes.string,
      pixel_sc_id: PropTypes.string,
    }),
    terms_link: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  flows: PropTypes.shape({
    CHECK_REGISTRATION: PropTypes.string.isRequired,
    REGISTER_TO_VOTE: PropTypes.string.isRequired,
    WANT_TO_HELP: PropTypes.string.isRequired,
  }).isRequired,
  setSelectedFlow: PropTypes.func.isRequired,
  team: PropTypes.shape({
    hero_img_url: PropTypes.string,
    incentives_img_url: PropTypes.string,
    mobile_hero_img_url: PropTypes.string,
    name: PropTypes.string,
    profile_img_url: PropTypes.string,
    terms_link: PropTypes.string,
  }),
};

ChooseFlow.defaultProps = {
  team: null,
};

export default ChooseFlow;
