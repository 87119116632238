import * as React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types'
import styled from 'styled-components';

const StyledMessage = styled.div`
  background-color: #d5ecef;
  border-radius: 7px;
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 22px;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  text-align: center;
`;

const HelpMessage = () => {
  const { t } = useTranslation();
  return (
    <StyledMessage>
      <Text>{t('activity.phone_bank.script_help')}</Text>
    </StyledMessage>
  );
};

HelpMessage.propTypes = {};

HelpMessage.defaultProps = {};

export default HelpMessage;
