import { Button, message, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { track } from '../../services/analytics';
import { user } from '../../services/api';

/**
 * Modal window for activities that require users to confirm their action.
 * e.g. Web activity, Share activity
 */
const ActivityCompletedModal = ({
  activityId,
  activityTitle,
  campaignId,
  campaignName,
  onModalClosedCallback,
  userActivityId,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const submitReport = async () => {
    setLoading(true);

    try {
      track('COMPLETED_ACTIVITY', {
        activity_id: activityId,
        activity_title: activityTitle,
        campaign_id: campaignId,
        campaign_name: campaignName,
      });
      await user.updateUserActivity(userActivityId, { completed: true });
      await user.userActivityAddPerform(userActivityId);

      message.success(t('common.submitted_successfully'));
      onModalClosedCallback({ success: true });
    } catch (err) {
      message.error(t('errors.action_failed'));
    } finally {
      setLoading(false);
    }
  };

  // Show confirmation modal dialog
  return (
    <Modal
      visible
      title={t('activity.confirm_action_completed')}
      maskClosable
      onCancel={onModalClosedCallback}
      footer={[
        <Button key="close" onClick={onModalClosedCallback}>
          {t('common.no')}
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={submitReport}>
          {t('common.yes')}
        </Button>,
      ]}
    >
      <div>{t('activity.did_you_complete')}</div>
    </Modal>
  );
};

ActivityCompletedModal.propTypes = {
  activityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  activityTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  campaignActivityName: PropTypes.string.isRequired,
  campaignId: PropTypes.number.isRequired,
  campaignName: PropTypes.string.isRequired,
  onModalClosedCallback: PropTypes.func.isRequired,
  userActivityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // should actually be casted to a number at some point
};

export default ActivityCompletedModal;
