import styled, { css } from 'styled-components';

export const DynamicGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ columnCount }) =>
    columnCount && css`1fr repeat(${columnCount}, 1fr)`};

  /* overflow-x: auto; */

  & > div {
    min-width: 175px;
  }
`;
