import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ImpactiveButton from '../../ImpactiveButton';

const StyledStepButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

function StepButtons({ disabled, goBack, goForward, hideForward, hideBackward, buttonType }) {
  const { t } = useTranslation();
  return (
    <StyledStepButtons>
      {!hideBackward && (
        <Button disabled={disabled} type={buttonType} onClick={goBack}>
          {t('contact.sync.go_back')}
        </Button>
      )}
      {!hideForward && (
        <ImpactiveButton disabled={disabled} onClick={goForward}>
          {t('common.next')}
        </ImpactiveButton>
      )}
    </StyledStepButtons>
  );
}

StepButtons.propTypes = {
  buttonType: PropTypes.string,
  disabled: PropTypes.bool,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  hideBackward: PropTypes.bool,
  hideForward: PropTypes.bool,
};

StepButtons.defaultProps = {
  buttonType: 'primary',
  disabled: false,
  goBack: () => null,
  goForward: () => null,
  hideBackward: false,
  hideForward: false,
};

export default StepButtons;
