// MTS - The purpose of this file is to make sure we keep the dialerMachine the
// same in both the mobile app and the web app. The debugging code does not work
// with React native so this allows us to write our own debugging code while
// blindly copy-pasting the dialerMachine.js changes between mobile and web.
import { inspect } from '@xstate/inspect';
import { interpret } from 'xstate';
import sendError, { addBreadcrumb } from '@web/utils/sendError';

const env = process && process.env && process.env.NODE_ENV && process.env.NODE_ENV.toLowerCase();

const DEBUG_XSTATE = false;

let service = null;

function machineDebugger(dialerMachine) {
  if (env === 'development' && DEBUG_XSTATE) {
    inspect({
      iframe: false,
      url: 'https://statecharts.io/inspect',
    });
    // Setup the debugger via https://statecharts.io/inspect
    service = interpret(dialerMachine, { devTools: true }).onTransition(state => {
      // eslint-disable-next-line
      console.log('dialerMachine transitioned to: ', state.value);
    });

    service.start();
  }

  return { addBreadcrumb, sendError, service };
}

export default machineDebugger;
