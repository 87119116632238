import { message } from 'antd';
// import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'web/components/common/Text';
import { users } from '../../services/api';
import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import ContactApproval from './components/ContactApproval';
import GoogleAuthButton from './components/GoogleAuthButton';
import GoogleContacts from './components/GoogleContacts';
import ImportSuccess from './components/ImportSuccess';
import Loading from './components/Loading';
import SyncWrapper from './components/SyncWrapper';

import useUserLocked from './components/useUserLocked';

const ContactSync = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [selectedGoogleContacts, setSelectedGoogleContacts] = React.useState([]);

  const { lockedToCampaign } = useUserLocked();
  const [googleUser, setGoogleUser] = useState(null);

  // We only include contacts.readyonly here. We ask for gmail.send permission
  // in the TextAFriend activity component.
  const scopes = 'https://www.googleapis.com/auth/contacts.readonly';

  function incrementStep(steps) {
    const count = Number(steps) || 1;
    return setCurrentStep(currentStep + count);
  }

  function decrementStep(steps) {
    const count = Number(steps) || 1;
    setCurrentStep(currentStep - count);
  }

  React.useEffect(() => {
    // This step is specifically for loading and is more of a state than a step.
    if (currentStep === 2) {
      syncContacts();
    }
    // eslint-disable-next-line
  }, [currentStep]);

  const syncContacts = () => {
    // MTS - In the future you can add gmail contacts here with .concat
    let allContacts = selectedGoogleContacts;
    // backend has a weird requirement for email structure
    allContacts = allContacts.map(contact => ({ ...contact, emails: [{ email: contact.email }] }));
    users
      .uploadEmailContacts(allContacts)
      .then(res => {
        incrementStep();
      })
      .catch(() => {
        message.error(t('errors.unexpected'));
      });
  };

  return (
    <>
      <AppHeader title={t('titles.settings')} />
      <AppContainer>
        <SyncWrapper>
          <h3>{t('contact.sync.header')}</h3>

          {/* Step 0  */}
          {currentStep === 0 && (
            <>
              <Text>{t('contact.sync.what')}</Text>
              <Text>{t('contact.sync.privacy')}</Text>
              <GoogleAuthButton
                appApproval
                googleUser={googleUser}
                incrementStep={incrementStep}
                scopes={scopes}
                setGoogleUser={user => setGoogleUser(user)}
              />
            </>
          )}

          {/* Step 1 - show/select google contacts*/}
          {currentStep === 1 && (
            <GoogleContacts
              decrementStep={decrementStep}
              incrementStep={incrementStep}
              selectedGoogleContacts={selectedGoogleContacts}
              setSelectedGoogleContacts={setSelectedGoogleContacts}
            />
          )}

          {/* Step 2 - send all of the contacts to the backend */}
          {currentStep === 2 && <Loading />}

          {/* Step 3 - send all of the contacts to the backend */}
          {currentStep === 3 && (
            <ContactApproval
              appApproval
              contactsToApprove={selectedGoogleContacts}
              decrementStep={decrementStep}
              lockedToCampaign={lockedToCampaign}
              onSuccess={incrementStep}
            />
          )}

          {/* Step 4 - send all of the contacts to the backend */}
          {currentStep === 4 && <ImportSuccess count={selectedGoogleContacts.length} />}
        </SyncWrapper>
      </AppContainer>
    </>
  );
};

ContactSync.propTypes = {};

export default ContactSync;
