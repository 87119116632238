import {
  LoadingOutlined,
  TwitterOutlined,
  FacebookOutlined,
  MailOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Tag, Alert, Button, Spin, Row, Col, Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState, useCallback } from 'react';
import Clipboard from 'react-clipboard.js';
import { useTranslation } from 'react-i18next';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { ContactRegistrationStatusTypes as RegistrationStatusTypes } from '../../constants';
import { track, trackPageViewWithDynamicIDs } from '../../services/analytics';
import { campaigns, users, teams } from '../../services/api';
import ImpactiveButton from '../ImpactiveButton';
import VoterRegistrationTemplate from '../templates/VoterRegistrationTemplate';
import Definition from './Definition';
import SuccessText from './SuccessText';
import {
  Section,
  Heading,
  Description,
  DefinitionList,
  Link,
  BoldText,
  TeamSection,
  TeamButtonsContainer,
  TeamInviteLink,
  ButtonsRow,
  ShareButtonInner,
} from './styles';

const VotingInformation = props => {
  const { campaignId, contactId } = props.match.params; // can be either campaign `id` or `slug`
  const { h, referral_hash } = queryString.parse(props.location.search);
  const { t } = useTranslation();
  const locale = window.localStorage.getItem('locale');

  const formatDate = str => {
    if (str) {
      return dayjs(str, 'MM-DD-YY').locale(locale).format('MMMM D');
    }
  };

  const [campaign, setCampaign] = useState({});
  const [contact, setContact] = useState({});
  const [team, setTeam] = useState();
  const [loading, setLoading] = useState(true);

  const hostname = `${window.location.protocol}//${window.location.host}`;
  const query = queryString.stringify({ contact_referral_id: contactId, referral_hash });
  const checkRegistrationLink = `${hostname}/campaigns/${campaignId}/check_registration?${query}`;

  useEffect(() => {
    track('VIEW_VOTING_INFORMATION');
    setLoading(true);
    Promise.all([
      campaigns.getPublicCampaign({ id: campaignId }),
      campaigns.getMyRecord({ h, id: contactId }),
    ])
      .then(([campaignResponse, contactResponse]) => {
        const campaign = campaignResponse.data.data;
        setCampaign(campaign);
        setContact(contactResponse.data);

        trackPageViewWithDynamicIDs({
          fbPixelId: campaign.fb_pixel_id,
          gaTrackingId: campaign.ga_tracking_id,
          snapchatPixelId: campaign.snapchat_pixel_id,
        });

        if (referral_hash) {
          return users
            .getUserByReferralHash(referral_hash)
            .then(({ data: { data: user } }) => {
              return teams.getTeam({ filters: { campaign_id: campaign.id }, id: user.id });
            })
            .then(({ data: { data: team } }) => setTeam(team))
            .catch(error => {
              // Catch error and make this promise always resolved,
              // so we can render this view at least
              console.error('get team data >', error);
            });
        }

        return;
      })
      .then(() => setLoading(false));
  }, [campaignId, contactId, h, referral_hash]);

  const updateContact = useCallback(
    body => {
      users
        .updateContact(contactId, body, h)
        .then(({ data }) => setContact(contact => ({ ...contact, ...data })));
    },
    [contactId, h],
  );

  const handleNativeShare = useCallback(() => {
    if (navigator.share) {
      navigator.share({ url: checkRegistrationLink }).catch(console.error);
    }
  }, [checkRegistrationLink]);

  if (loading) {
    return (
      <div className="flex-row justify-content-center mt-4">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  const shareNowVisible = !!navigator.share;
  const inviteSection = (
    <TeamSection id="team-section">
      <Heading>{t('voting_information.share.title')}</Heading>
      <Description>{campaign.rewards || t('voting_information.share.description')}</Description>
      <ButtonsRow fullWithOnMobile>
        {shareNowVisible && (
          <ImpactiveButton onClick={handleNativeShare}>
            <UploadOutlined /> {t('voting_information.share.share_now')}
          </ImpactiveButton>
        )}
        {!shareNowVisible && (
          <Clipboard
            component="span"
            data-clipboard-text={checkRegistrationLink}
            onSuccess={() => message.info(t('common.copied_to_clipboard'))}
          >
            <Tooltip placement="top" title={checkRegistrationLink}>
              <ImpactiveButton>{t('voting_information.share.copy_share_link')}</ImpactiveButton>
            </Tooltip>
          </Clipboard>
        )}
        <FacebookShareButton url={checkRegistrationLink}>
          <ShareButtonInner>
            <FacebookOutlined /> {t('voting_information.share.facebook')}
          </ShareButtonInner>
        </FacebookShareButton>
        <TwitterShareButton url={checkRegistrationLink}>
          <ShareButtonInner>
            <TwitterOutlined /> {t('voting_information.share.twitter')}
          </ShareButtonInner>
        </TwitterShareButton>
        <ImpactiveButton
          onClick={() =>
            window.open(`mailto:?body=${encodeURIComponent(checkRegistrationLink)}`, '_blank')
          }
        >
          <MailOutlined /> {t('voting_information.share.email')}
        </ImpactiveButton>
      </ButtonsRow>
    </TeamSection>
  );

  if (contact.registration_status === RegistrationStatusTypes.UNABLE_TO_VOTE) {
    return (
      <VoterRegistrationTemplate campaign={campaign} team={team} stepCount={2} currentStep={2}>
        {inviteSection}
      </VoterRegistrationTemplate>
    );
  }

  // TODO: Wire up with team API data
  // const joinedTeam = false;
  const state = contact.state_abbrev ? t(`states.${contact.state_abbrev}`) : undefined;
  const vbm_request_deadline = contact.voting_info.general_vbm_request_deadline
    ? formatDate(contact.voting_info.general_vbm_request_deadline)
    : '';

  return (
    <VoterRegistrationTemplate
      campaign={campaign}
      team={team}
      stepCount={3}
      currentStep={3}
      title={t('voting_information.title_with_state', { state })}
      instruction={t('voting_information.disclaimer')}
      data-testid="voting-information"
    >
      <Alert
        style={{ marginTop: 20 }}
        message="Thanks for checking your registration! You should receive an email with a link to this page which  you can use to check your current status at any time."
        type="success"
        showIcon
        closable
      />
      <Section>
        <Heading>{t('voting_information.voter_registration')}</Heading>
        <Row gutter={60}>
          <Col span={24} md={24}>
            {contact.registration_status === RegistrationStatusTypes.REGISTERED ? (
              <DefinitionList>
                <Definition
                  term={t('voting_information.your_registration_status')}
                  description={<SuccessText>{t('voting_information.confirmed')}</SuccessText>}
                />
              </DefinitionList>
            ) : (
              <DefinitionList>
                <Definition
                  term={t('voting_information.your_registration_status')}
                  description={
                    <BoldText danger={!contact.reported_registered}>
                      {contact.registration_status}
                    </BoldText>
                  }
                  help={
                    contact.registration_status === RegistrationStatusTypes.DIFFERENT_ADDRESS
                      ? `Your addresses don't match. You provided an address beginning with: ${contact.address}, but the address on the voter file begins with: ${contact.voterfile.address}`
                      : contact.registration_status === RegistrationStatusTypes.UNCONFIRMED
                      ? t('voting_information.your_registration_status_help')
                      : ''
                  }
                />
              </DefinitionList>
            )}
          </Col>
          <Col span={24} md={24}>
            <DefinitionList>
              <Definition
                term={t('voting_information.deadline_to_register')}
                description={formatDate(
                  contact.voting_info.can_register_online
                    ? contact.voting_info.online_reg_deadline
                    : contact.voting_info.mail_reg_deadline_postmarked,
                )}
              />
              {contact.registration_status !== RegistrationStatusTypes.REGISTERED &&
                (!contact.reported_registered ||
                  contact.registration_status === RegistrationStatusTypes.DIFFERENT_ADDRESS) && (
                  <>
                    <Tag
                      style={{ marginTop: 20 }}
                      icon={<ExclamationCircleOutlined />}
                      color="error"
                    >
                      Next Steps
                    </Tag>
                    {contact.voting_info.can_register_online ? (
                      <Link
                        style={{ display: 'block', marginTop: 10 }}
                        href={contact.voting_info.online_reg_link}
                        target="_blank"
                      >
                        {t('voting_information.online_registration_link')}
                      </Link>
                    ) : (
                      <Definition
                        term={t('voting_information.can_register_online')}
                        description={t('common.no')}
                      />
                    )}
                    <Link
                      style={{ display: 'block' }}
                      target="_blank"
                      href={contact.voting_info.paper_reg_link}
                    >
                      {t('voting_information.how_to_register_by_mail')}
                    </Link>
                    {!campaign.is_sending_registration_forms &&
                      contact.voting_info.accepts_national_reg_form && (
                        <Link
                          style={{ display: 'block' }}
                          target="_blank"
                          href="https://www.eac.gov/sites/default/files/eac_assets/1/6/Federal_Voter_Registration_ENG.pdf"
                        >
                          {t('voting_information.national_voter_registration_form')}
                        </Link>
                      )}
                  </>
                )}
              <div>
                {((contact.registration_status !== RegistrationStatusTypes.REGISTERED &&
                  contact.registration_status !== RegistrationStatusTypes.UNCONFIRMED) ||
                  !campaign.is_sending_registration_forms ||
                  contact.requested_registration_form === 'no') && (
                  <div style={{ marginTop: 15 }}>
                    <Definition
                      term={
                        contact.registration_status === RegistrationStatusTypes.DIFFERENT_ADDRESS
                          ? t('Were you able to update your registration?')
                          : t('Were you able to register to vote?')
                      }
                    />
                    <ButtonsRow>
                      <Button
                        type={
                          contact.reported_registered &&
                          contact.registration_status !== RegistrationStatusTypes.DIFFERENT_ADDRESS
                            ? 'primary'
                            : 'default'
                        }
                        onClick={() => {
                          updateContact({
                            reg_state: 'not_sent',
                            registration_status: RegistrationStatusTypes.UNCONFIRMED,
                            reported_registered: true,
                          });
                          message.success(
                            'Amazing! The next step is to get your absentee ballot.',
                            3,
                          );
                        }}
                      >
                        {t('Yes')}
                      </Button>
                      <Button
                        type={!contact.reported_registered ? 'primary' : 'default'}
                        onClick={() => {
                          updateContact({
                            reg_state: 'unable_to_register_online',
                            registration_status: RegistrationStatusTypes.UNREGISTERED,
                            reported_registered: false,
                          });
                        }}
                      >
                        {t('No')}
                      </Button>
                      {contact.registration_status ===
                        RegistrationStatusTypes.DIFFERENT_ADDRESS && (
                        <Button
                          onClick={() => {
                            updateContact({
                              registration_status: RegistrationStatusTypes.REGISTERED,
                            });
                          }}
                        >
                          {t('Addresses are fine.')}
                        </Button>
                      )}
                    </ButtonsRow>
                  </div>
                )}
                {campaign.is_sending_registration_forms &&
                  !contact.reported_registered &&
                  contact.registration_status !== RegistrationStatusTypes.DIFFERENT_ADDRESS && (
                    <div style={{ marginTop: 15 }}>
                      <Definition term={t('Should we mail you a registration form?')} />
                      <ButtonsRow>
                        <Button
                          type={
                            contact.requested_registration_form === 'yes' ? 'primary' : 'default'
                          }
                          onClick={() => {
                            updateContact({
                              reported_registered: false,
                              requested_registration_form: 'yes',
                            });
                            message.success(
                              'Success! You will receive a voter registration form in the mail in the next 5-7 days.',
                              3,
                            );
                            document
                              .getElementById('team-section')
                              .scrollIntoView({ behavior: 'smooth', block: 'center' });
                          }}
                        >
                          {t('Yes')}
                        </Button>
                        <Button
                          type={
                            contact.requested_registration_form === 'no' ? 'primary' : 'default'
                          }
                          onClick={() => {
                            updateContact({
                              requested_registration_form: 'no',
                            });
                          }}
                        >
                          {t('No')}
                        </Button>
                      </ButtonsRow>
                    </div>
                  )}
              </div>
            </DefinitionList>
          </Col>
        </Row>
      </Section>
      {(contact.registration_status === RegistrationStatusTypes.REGISTERED ||
        contact.reported_registered) && (
        <Section id="vbm-section">
          <Row gutter={60}>
            <Col span={24} md={12} style={{ marginBottom: 40 }}>
              <Heading>{t('voting_information.vbm')}</Heading>
              <DefinitionList>
                {contact.vbm_state === 'returned_ballot' && (
                  <Definition
                    term={'Your voting status:'}
                    description={
                      <SuccessText>
                        {t('Congratulations! You have successfully voted.')}
                      </SuccessText>
                    }
                  />
                )}
                {contact.vbm_state !== 'returned_ballot' && (
                  <Definition
                    term={'Your vote by mail status:'}
                    description={
                      <BoldText danger={!contact.reported_requested_vbm}>
                        {contact.vbm_state === 'returned_ballot'
                          ? 'Ballot Returned'
                          : contact.vbm_state === 'received_ballot'
                          ? 'Ballot Received'
                          : contact.automatic_vbm
                          ? 'Ballot Sent Automatically'
                          : contact.reported_requested_vbm
                          ? 'Ballot Requested'
                          : 'Ballot Not Requested'}
                      </BoldText>
                    }
                  />
                )}
                {contact.can_vbm && contact.automatic_vbm && (
                  <Definition
                    term={t('Automatic Vote By Mail')}
                    description={<p>{t('voting_information.automatic_vbm_instruction')}</p>}
                  />
                )}
                {contact.can_vbm && !contact.automatic_vbm && (
                  <>
                    <Definition
                      term={t('voting_information.ballot_request_deadline')}
                      description={vbm_request_deadline}
                    />
                    <Alert
                      style={{ marginTop: 20 }}
                      message={t('voting_information.voter_reg_required')}
                      type="info"
                      showIcon
                      closable
                    />
                    {!contact.reported_requested_vbm && (
                      <Tag
                        style={{ marginTop: 20 }}
                        icon={<ExclamationCircleOutlined />}
                        color="error"
                      >
                        Next Steps
                      </Tag>
                    )}
                    <Definition
                      style={{ marginTop: 10 }}
                      term={t('voting_information.request_absentee_ballot')}
                    />
                    <div className="mt-2">
                      <Link
                        disabled={!contact.can_vbm_online}
                        target="_blank"
                        href={contact.voting_info.online_vbm_request_link}
                      >
                        {t('Request your absentee ballot online')}
                      </Link>
                    </div>
                    <div className="mt-2">
                      <Link target="_blank" href={contact.voting_info.paper_vbm_request_link}>
                        {t('voting_information.print_your_application')}
                      </Link>
                    </div>
                    {campaign.is_sending_vbm_forms &&
                      contact.state_abbrev &&
                      !contact.state_abbrev.match(/KY|SC|MS/) &&
                      !contact.automatic_vbm && (
                        <>
                          <Definition
                            term={t('Should we mail you a form to request an absentee ballot?')}
                          />
                          <ButtonsRow>
                            <Button
                              type={
                                contact.requested_absentee_form === 'yes' ? 'primary' : 'default'
                              }
                              onClick={() => {
                                updateContact({
                                  reported_requested_vbm: false,
                                  requested_absentee_form: 'yes',
                                });
                                message.success(
                                  'Success! You will receive an absentee ballot request form in the mail in the next 5-7 days.',
                                  3,
                                );
                                document
                                  .getElementById('team-section')
                                  .scrollIntoView({ behavior: 'smooth', block: 'center' });
                              }}
                            >
                              {t('Yes')}
                            </Button>
                            <Button
                              type={
                                contact.requested_absentee_form === 'no' ? 'primary' : 'default'
                              }
                              onClick={() => {
                                updateContact({
                                  requested_absentee_form: 'no',
                                });
                              }}
                            >
                              {t('No')}
                            </Button>
                          </ButtonsRow>
                        </>
                      )}
                    {(!campaign.is_sending_vbm_forms || contact.requested_absentee_form === 'no') &&
                      !contact.automatic_vbm && (
                        <>
                          <Definition term={t('Were you able to request an absentee ballot?')} />
                          <ButtonsRow>
                            <Button
                              type={contact.reported_requested_vbm ? 'primary' : 'default'}
                              onClick={() => {
                                updateContact({
                                  reported_requested_vbm: true,
                                  vbm_state: 'not_sent',
                                });
                                message.success(
                                  'Congratulations! Now just wait to receive your ballot and return it right away when it arrives!',
                                  3,
                                );
                              }}
                            >
                              {t('Yes')}
                            </Button>
                            <Button
                              type={!contact.reported_requested_vbm ? 'primary' : 'default'}
                              onClick={() => {
                                updateContact({
                                  reported_requested_vbm: false,
                                  vbm_state: 'unable_to_request_online',
                                });
                              }}
                            >
                              {t('No')}
                            </Button>
                          </ButtonsRow>
                        </>
                      )}
                    {contact.requested_absentee_form === 'yes' &&
                      contact.vbm_state !== 'not_sent' && (
                        <>
                          <Definition term={t('voting_information.received_ballot_app.question')} />
                          <ButtonsRow>
                            <Button
                              type={contact.vbm_state === 'received_form' ? 'primary' : 'default'}
                              onClick={() => {
                                updateContact({
                                  vbm_state: 'received_form',
                                });
                              }}
                            >
                              {t('Yes')}
                            </Button>
                            <Button
                              type={
                                contact.vbm_state === 'never_received_form' ? 'primary' : 'default'
                              }
                              onClick={() => {
                                updateContact({
                                  vbm_state: 'never_received_form',
                                });
                              }}
                            >
                              {t('No')}
                            </Button>
                          </ButtonsRow>
                        </>
                      )}
                    {contact.requested_absentee_form === 'yes' &&
                      contact.vbm_state === 'received_form' && (
                        <>
                          <Definition
                            term={t('voting_information.write_excuse')}
                            description={
                              contact.voting_info.vbm_excuse_info ||
                              t('voting_information.no_excuse_with_state', { state })
                            }
                            vertical
                          />
                          <Definition
                            term={t(
                              'You have received your application! Have you been able to return it yet?',
                            )}
                          />
                          <ButtonsRow>
                            <Button
                              type={contact.reported_requested_vbm ? 'primary' : 'default'}
                              onClick={() => {
                                updateContact({
                                  reported_requested_vbm: true,
                                });
                              }}
                            >
                              {t('Yes')}
                            </Button>
                            <Button
                              type={!contact.reported_requested_vbm ? 'primary' : 'default'}
                              onClick={() => {
                                updateContact({
                                  reported_requested_vbm: false,
                                });
                              }}
                            >
                              {t('No')}
                            </Button>
                          </ButtonsRow>
                        </>
                      )}
                    {contact.reported_requested_vbm &&
                      (contact.requested_absentee_form !== 'yes' ||
                        (contact.requested_absentee_form === 'yes' &&
                          [
                            'received_form',
                            'received_ballot',
                            'never_received_ballot',
                            'returned_ballot',
                          ].includes(contact.vbm_state) && (
                            <>
                              <Definition
                                term={t(
                                  'You should be receiving your ballot in the mail. Have you received it yet?',
                                )}
                              />
                              <ButtonsRow>
                                <Button
                                  type={
                                    contact.vbm_state === 'received_ballot' ? 'primary' : 'default'
                                  }
                                  onClick={() => {
                                    updateContact({
                                      vbm_state: 'received_ballot',
                                    });
                                    message.success(
                                      'Amazing! Make sure to return it right away!.',
                                      3,
                                    );
                                  }}
                                >
                                  {t('Yes')}
                                </Button>
                                <Button
                                  type={
                                    contact.vbm_state === 'never_received_ballot'
                                      ? 'primary'
                                      : 'default'
                                  }
                                  onClick={() => {
                                    updateContact({
                                      vbm_state: 'never_received_ballot',
                                    });
                                  }}
                                >
                                  {t('No')}
                                </Button>
                                <Button
                                  type={
                                    contact.vbm_state === 'returned_ballot' ? 'primary' : 'default'
                                  }
                                  onClick={() => {
                                    updateContact({
                                      vbm_state: 'returned_ballot',
                                    });
                                    message.success(
                                      'Congratulations, you voted! Now make sure your friends have voted too!',
                                      3,
                                    );
                                    document
                                      .getElementById('team-section')
                                      .scrollIntoView({ behavior: 'smooth', block: 'center' });
                                  }}
                                >
                                  {t('I already returned it!')}
                                </Button>
                              </ButtonsRow>
                            </>
                          )))}
                  </>
                )}
                {!contact.voting_info.can_vbm && (
                  <Definition>
                    <div>
                      <BoldText>
                        {t('voting_information.cannot_vbm_with_state', { state })}
                      </BoldText>
                    </div>
                  </Definition>
                )}
              </DefinitionList>
            </Col>
            <Col span={24} md={12}>
              <Heading>{t('voting_information.early_voting')}</Heading>
              {contact.voting_info.in_person_early_voting && (
                <DefinitionList>
                  <Definition
                    term={t('voting_information.early_voting_starts')}
                    description={formatDate(contact.voting_info.early_vote_start)}
                  />
                  <Definition
                    term={t('voting_information.early_voting_ends')}
                    description={formatDate(contact.voting_info.early_vote_end)}
                  />
                </DefinitionList>
              )}
              {!contact.voting_info.in_person_early_voting && (
                <DefinitionList>
                  <div>
                    <BoldText>
                      {t('voting_information.cannot_early_voting_with_state', { state })}
                    </BoldText>
                  </div>
                </DefinitionList>
              )}
            </Col>
          </Row>
        </Section>
      )}
      {inviteSection}
      {false && (
        <TeamSection>
          <Heading>
            {t('voting_information.invite_friends_to_team_with_name', { name: 'Ashton Kutcher' })}
          </Heading>
          <TeamInviteLink>https://campaigns.impactive.io/akutcher9038479</TeamInviteLink>
          <ImpactiveButton>{t('voting_information.copy_invite_link')}</ImpactiveButton>
        </TeamSection>
      )}
      {false && (
        <TeamSection>
          <Heading>
            {t('voting_information.finish_joining_team_with_name', { name: 'Ashton Kutcher' })}
          </Heading>
          <Description
            dangerouslySetInnerHTML={{ __html: t('voting_information.signup_download_cta') }}
          />
          <Link href="#">{t('voting_information.send_app_download_link')}</Link>
          <TeamButtonsContainer>
            <ImpactiveButton>{t('voting_information.signup_online')}</ImpactiveButton>&nbsp;&nbsp;
            <ImpactiveButton>{t('voting_information.signin')}</ImpactiveButton>
          </TeamButtonsContainer>
        </TeamSection>
      )}
      {contact.registration_status !== RegistrationStatusTypes.UNABLE_TO_VOTE && (
        <Alert
          message="Information was collected from secretaries of state offices, monitoring official websites, and local election officials. If you notice any updated voting rules not published on this website please email support@impactive.io."
          type="info"
          showIcon
        />
      )}
    </VoterRegistrationTemplate>
  );
};

VotingInformation.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string.isRequired,
      contactId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default VotingInformation;
