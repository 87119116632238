import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from '@web/components/Headcount/components/Text';

const ContentWrapper = styled.div`
  margin-top: 2rem;
`;

const StyledButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

function ButtonRow({ children, error }) {
  return (
    <ContentWrapper>
      {error && (
        <Text color="red" variant="f3" style={{ marginBottom: '0.5rem' }}>
          {error}
        </Text>
      )}
      <StyledButtonRow>{children}</StyledButtonRow>
    </ContentWrapper>
  );
}

ButtonRow.propTypes = {
  children: PropTypes.any.isRequired,
  error: PropTypes.string,
};

ButtonRow.defaultProps = {
  error: null,
};

export default ButtonRow;
