import axios from 'axios';
import queryString from 'query-string';
import { message } from 'antd';
import sendError from '@web/utils/sendError';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function useVoterRegistrationApi(activity, team) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { referral_hash } = queryString.parse(search);

  function buildData(values) {
    return {
      activity_id: values.recheck ? undefined : activity.id,
      address: values.address,
      auto_open_link: values.auto_open_link,
      campaign_id: activity.campaign.id,
      city: values.city,
      county_name: values.countyName,
      date_of_birth: values.date_of_birth,
      email: values.email,
      // MTS - We only have one checkbox and it encompasses email and sms
      email_opt_in: values.smsOptIn,
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phone,
      recheck: values.recheck,
      referral_hash,
      registration_status: values.registrationStatus,
      show_on_leaderboard: values.showOnLeaderboard,
      sms_opt_in: values.smsOptIn,
      state_abbrev: values.stateAbbrev,
      team_id: team?.id,
      unit_number: values.unitNumber,
      utm_campaign: values.utm_campaign,
      utm_medium: values.utm_medium,
      utm_source: values.utm_source,
      zip_code: values.zipCode,
    };
  }

  function submitContactForm(values) {
    return axios.post(`/api/v2/client/voter_registrations`, {
      ...buildData(values),
    });
  }

  function submitContactUpdate(contact, values) {
    // MTS - We can't reuse buildData because the required values are different
    return axios.patch(`/api/v2/client/voter_registrations/${contact.id}`, {
      activity_id: values.recheck ? undefined : activity.id,
      address: values.address,
      city: values.city,
      county_name: values.countyName,
      h: contact.encode_id,
      recheck: values.recheck,
      state_abbrev: values.stateAbbrev,
      unit_number: values.unitNumber,
      zip_code: values.zipCode,
    });
  }

  function handleError(error) {
    let errorMessage = t('errors.default');
    if (error.response) {
      const data = error.response.data;

      if (data.message) {
        if (typeof data.message === 'object') {
          errorMessage = `${errorMessage} ${Object.values(data.message).join(', ')}`;
        } else {
          errorMessage = `${errorMessage} ${data.message}`;
        }
      } else {
        // Log the error to Sentry only if the error is not a validation error
        sendError(`Voter Registration: error ${errorMessage}`, error);
      }
    }
    message.error(errorMessage);
    return errorMessage;
  }

  return {
    handleError,
    submitContactForm,
    submitContactUpdate,
  };
}

export default useVoterRegistrationApi;
