import * as React from 'react';

function ThirdPlaceIcon() {
  return (
    <svg
      width="122"
      height="116"
      viewBox="0 0 122 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="10.8587"
        y="6.39073"
        width="103.576"
        height="104.641"
        rx="51.7882"
        fill="url(#paint0_radial_428_2040)"
        stroke="white"
        stroke-width="5.28"
      />
      <path
        d="M62.2714 43.2184C54.8266 43.2184 49.9162 47.1784 49.9162 52.7224C49.9162 54.2536 50.233 55.4152 50.233 55.5208L57.4666 54.3592C57.361 54.0952 57.3082 53.6728 57.3082 53.1976C57.3082 51.244 59.0506 49.7128 62.0602 49.7128C65.1226 49.7128 67.1818 51.6136 67.1818 54.3592C67.1818 56.8936 64.8586 58.3192 61.057 58.4776L58.5754 58.5304V63.916L62.2186 63.9688C66.4954 64.1272 68.3962 65.7112 68.3434 68.14C68.3434 70.7272 66.0202 72.364 62.1658 72.364C56.9386 72.364 54.193 68.14 54.193 68.14L48.649 71.7832C48.649 71.7832 51.553 78.8056 62.4298 78.8056C70.9306 78.8056 75.9466 74.74 75.9466 69.0904C75.9466 64.1272 71.6698 61.012 67.8154 61.012V60.9064C71.617 60.9064 74.7322 57.844 74.7322 53.5672C74.7322 47.4424 69.9274 43.2184 62.2714 43.2184Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_428_2040"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.5368 12.2781) rotate(62.3075) scale(178.546 177.568)"
        >
          <stop stop-color="#D4AB42" />
          <stop offset="1" stop-color="#F8E8BF" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default ThirdPlaceIcon;
