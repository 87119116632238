import { MenuOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMedia } from '../../hooks';
import { media } from '../../styles/theme';

import Sidebar from '../AppContainer/Sidebar';

const StyledSidebar = styled(Sidebar)`
  height: 100% !important;
  margin-right: 0 !important;
  max-width: initial !important;
  min-width: initial !important;
  width: 100% !important;
`;

const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  outline: 0;
  // We add padding so we meet a11y standards for tappable areas
  padding: 12px 16px;
  position: absolute;
  right: 0px;
  top: 7px;
`;

const MenuWrapper = styled.nav`
  ${media.ns} {
    display: none;
  }
  background-color: #fff;
  position: fixed;
  top: 61px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transform: translateX(${({ menuOpen }) => (menuOpen ? 0 : '-100vw')});
  transition: transform 250ms ease-out;
`;

const StyledHamburgerMenu = styled.div`
  ${media.ns} {
    display: none;
  }
`;

function HamburgerMenu() {
  const notSmall = useMedia('(max-width: 600px)');
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (notSmall) {
      document.body.style.overflow = 'initial';
    } else {
      document.body.style.overflow = menuOpen ? 'hidden' : 'initial';
    }
  }, [notSmall, menuOpen]);

  if (!notSmall) return null;

  return (
    <StyledHamburgerMenu>
      <MenuButton
        aria-label={`${menuOpen ? 'close' : 'open'} menu`}
        onClick={() => setMenuOpen(!menuOpen)}
        type="button"
      >
        <MenuOutlined />
      </MenuButton>
      <MenuWrapper menuOpen={menuOpen}>
        <StyledSidebar />
      </MenuWrapper>
    </StyledHamburgerMenu>
  );
}

HamburgerMenu.propTypes = {};

HamburgerMenu.defaultProps = {};

export default HamburgerMenu;
