import React from 'react';
import PropTypes from 'prop-types';

const Entries = ({ fullColor, fill, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <g opacity={fullColor ? 1 : 0.2} clipPath="url(#clip0_513_1826)">
        <g filter="url(#filter0_d_513_1826)">
          <path
            d="M20.7733 22.4007L16 19.334L11.2266 22.4007L12.6666 16.9073L8.27996 13.334L13.9466 12.9873L16 7.73398L18.0533 12.9873L23.72 13.334L19.3333 16.9073M26.6666 16.0007C26.6666 15.2934 26.9476 14.6151 27.4477 14.115C27.9478 13.6149 28.6261 13.334 29.3333 13.334V8.00065C29.3333 7.29341 29.0523 6.61513 28.5522 6.11503C28.0521 5.61494 27.3739 5.33398 26.6666 5.33398H5.33329C4.62605 5.33398 3.94777 5.61494 3.44767 6.11503C2.94758 6.61513 2.66663 7.29341 2.66663 8.00065V13.334C3.37387 13.334 4.05215 13.6149 4.55224 14.115C5.05234 14.6151 5.33329 15.2934 5.33329 16.0007C5.33329 16.7079 5.05234 17.3862 4.55224 17.8863C4.05215 18.3864 3.37387 18.6673 2.66663 18.6673V24.0007C2.66663 24.7079 2.94758 25.3862 3.44767 25.8863C3.94777 26.3864 4.62605 26.6673 5.33329 26.6673H26.6666C27.3739 26.6673 28.0521 26.3864 28.5522 25.8863C29.0523 25.3862 29.3333 24.7079 29.3333 24.0007V18.6673C28.6261 18.6673 27.9478 18.3864 27.4477 17.8863C26.9476 17.3862 26.6666 16.7079 26.6666 16.0007Z"
            fill={fill || '#393E5B'}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_513_1826"
          x="-8.33337"
          y="-3.66602"
          width="50.6666"
          height="45.334"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_513_1826" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_513_1826"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_513_1826">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Entries.propTypes = {
  fill: PropTypes.string,
  fullColor: PropTypes.bool,
};

Entries.defaultProps = {};

export default Entries;
