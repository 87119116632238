import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from '@web/components/DialerActivity/components/Button';

const StyledNextButton = styled(Button)`
  width: 227px;
`;

function NextButton({ children, ...rest }) {
  const { t } = useTranslation();

  return (
    <StyledNextButton data-testid="slideshow-next-button" {...rest}>
      {children ?? t('common.next')}
    </StyledNextButton>
  );
}

NextButton.propTypes = {
  children: PropTypes.any,
};

NextButton.defaultProps = {
  children: null,
};

export default NextButton;
