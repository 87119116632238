import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BUTTON_SIZE = 24;

// MTS - For some reason all of the styles break if this imported from any other file.
const largeBreakpoint = '@media screen and (min-width: 958px)';

// MTS - For some reason on Safari mobile (not desktop) the svg is not rendered if this
// is a button 🤷‍♂️
const StyledCloseButton = styled.div`
  align-items: center;
  background-color: ${({ $secondary, $isFanOut, theme }) =>
    $secondary ? ($isFanOut ? 'rgba(45, 41, 42, 0.60)' : 'transparent') : '#fff'};
  border-radius: ${BUTTON_SIZE / 2}px;
  border: none;
  display: flex;
  height: ${BUTTON_SIZE}px;
  justify-content: center;

  width: ${BUTTON_SIZE}px;

  position: fixed;
  top: 19px;
  right: 15px;
  z-index: 2;
  ${largeBreakpoint} {
    position: absolute;
    top: 0;
    right: ${-8 - BUTTON_SIZE}px;
  }
`;

function CloseButton({ secondary, isFanOut, ...rest }) {
  const pathProps = {
    stroke: secondary ? '#fff' : '#000',
    strokeOpacity: secondary ? 1 : 0.2,
  };
  return (
    <StyledCloseButton
      $secondary={secondary}
      $isFanOut={isFanOut}
      {...rest}
      aria-label="close"
      role="button"
    >
      <svg height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5 5.00091L9.24264 0.758266M5 5.00091L0.757359 9.24355M5 5.00091L9.24264 9.24355M5 5.00091L0.757359 0.758266"
          strokeWidth="2"
          {...pathProps}
        />
      </svg>
    </StyledCloseButton>
  );
}

CloseButton.propTypes = {
  isFanOut: PropTypes.bool,
  secondary: PropTypes.bool,
};

CloseButton.defaultProps = {
  isFanOut: false,
  secondary: false,
};

export default CloseButton;
