import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined, ReloadOutlined } from '@ant-design/icons';

import TellUs from '@web/components/FanOutVotingPlan/components/TellUs';
import Oops from '@web/components/FanOutVotingPlan/components/Oops';
import HowWillYouVote from '@web/components/FanOutVotingPlan/components/HowWillYouVote';
import WhichCandidatesRepresent from '@web/components/FanOutVotingPlan/components/WhichCandidatesRepresent';
import Results from '@web/components/FanOutVotingPlan/components/Results';
import i18n from '@web/components/i18n';

const { t } = i18n;

export const VOTING_PAGES = [
  {
    Component: Oops,
    index: 0,
    nextButtonText: t('fan_out.voting_plan.oops.try_again'),
    nextIcon: <ReloadOutlined />,
    pageName: 'OOPS',
  },
  {
    Component: TellUs,
    index: 1,
    nextButtonText: t('fan_out.voting_plan.tell_us.next'),
    nextIcon: <ArrowRightOutlined />,
    pageName: 'TELL_US',
  },
  {
    Component: HowWillYouVote,
    index: 2,
    nextButtonText: t('fan_out.voting_plan.how_will_you_vote.next'),
    nextIcon: <ArrowRightOutlined />,
    pageName: 'HOW_WILL_YOU_VOTE',
    prevButtonText: t('fan_out.voting_plan.how_will_you_vote.back'),
    prevIcon: <ArrowLeftOutlined />,
  },
  {
    Component: WhichCandidatesRepresent,
    index: 3,
    nextButtonText: t('fan_out.voting_plan.which_candidates_represent.submit'),
    pageName: 'WHICH_CANDIDATES_REPRESENT',
    prevButtonText: t('fan_out.voting_plan.which_candidates_represent.back'),
    prevIcon: <ArrowLeftOutlined />,
  },
  {
    Component: Results,
    index: 4,
    pageName: 'RESULTS',
  },
];

export const useSurveyActivity = ({ activity }) => {
  // Form data
  const [sortedActivityQuestions, setSortedActivityQuestions] = useState([]);
  const [first3Questions, setFirst3Questions] = useState([]);
  const [partyQuestions, setPartyQuestions] = useState([]);
  const [formAttributes, setFormAttributes] = useState({});

  const getActivityQuestions = useCallback(() => {
    // sort by position
    const sortedQuestions = activity.activity_questions.sort((a, b) => a.position - b.position);
    setSortedActivityQuestions(sortedQuestions);
    setFormAttributes(activity.survey_activity_form);
    setFirst3Questions(sortedQuestions.slice(0, 3));
    setPartyQuestions(sortedQuestions.slice(3));
  }, [activity]);

  useEffect(() => {
    getActivityQuestions();
  }, [getActivityQuestions]);

  return {
    first3Questions,
    formAttributes,
    partyQuestions,
    sortedActivityQuestions,
  };
};

export const useVotingPlan = () => {
  // Voting plan answers
  const [formVPAnswers, setFormVPAnswers] = useState({});
  const [VPAnswers, setVPAnswers] = useState([]);
  const [oopsType, setOopsType] = useState({ errorType: null, referrer: null });

  // positions of disabled answers in each question (3 questions HowWillYouVote screen)
  const [disabledAnswers, setDisabledAnswers] = useState({ 0: [], 1: [], 2: [] });

  // contact id for the voting plan after form submission
  const [contactId, setContactId] = useState(null);

  const [currentPage, setCurrentPage] = useState(VOTING_PAGES[1]);

  const onNextPage = useCallback(() => {
    setCurrentPage(page => VOTING_PAGES.find(({ index }) => index === page.index + 1));
    setOopsType({ errorType: null, referrer: null });
    window.scrollTo(0, 0);
  }, []);

  const onPreviousPage = useCallback((errorType, referrer) => {
    setCurrentPage(page => VOTING_PAGES.find(({ index }) => index === page.index - 1));
    setOopsType({ errorType, referrer });
    window.scrollTo(0, 0);
  }, []);

  const onBackToStart = useCallback(() => {
    setCurrentPage(VOTING_PAGES[1]);
    setOopsType({ errorType: null, referrer: null });
    window.scrollTo(0, 0);
  }, []);

  const progress = useMemo(() => currentPage.index / (VOTING_PAGES.length - 2), [currentPage]);

  return {
    contactId,
    currentPage,
    disabledAnswers,
    formVPAnswers,
    onBackToStart,
    onNextPage,
    onPreviousPage,
    oopsType,
    progress,
    setContactId,
    setCurrentPage,
    setDisabledAnswers,
    setFormVPAnswers,
    setVPAnswers,
    VPAnswers,
  };
};
