import * as React from 'react';
import PropTypes from 'prop-types';

import clipboardTealImage from './clipboard-teal.png';
import clipboardYellowImage from './clipboard-yellow.png';
import clipboardGreenImage from './clipboard-green.png';

function getColor(color) {
  switch (color) {
    case 'teal':
      return clipboardTealImage;
    case 'green':
      return clipboardGreenImage;
    default:
      return clipboardYellowImage;
  }
}

// MTS - These exist like this for easy conversion into an svg icon which are currently
// being requested.
function ClipboardIcon(props) {
  return <img alt="Clipboard Icon" src={getColor(props.color)} {...props} />;
}

ClipboardIcon.propTypes = {
  color: PropTypes.oneOf(['teal', 'yellow', 'green']),
};

ClipboardIcon.defaultProps = {
  color: 'teal',
};

export default ClipboardIcon;
