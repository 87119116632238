import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { users } from '../../services/api';
import theme from '../../styles/theme';
import IdVotersForm from './IdVotersForm';
import IdVotersList from './IdVotersList';

const StyledModal = styled(Modal)``;

const HeaderAndDescription = styled.div`
  padding: 0px 16px;
`;

const ModalContent = styled.div`
  color: ${({ theme }) => theme.colors.black};
`;

const Header = styled.div`
  font-size: 22px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-size: 12px;
  margin-bottom: 20px;
`;

// Search the national voter file with the following parameters to find possible matches for either a contact or someone you've just met at an event
const IdVotersModal = ({
  contact_id,
  firstName,
  lastName,
  activityId,
  userActivityId,
  campaignId,
  setVisibleFn,
  setShowVoterMatchModal,
  setVoter,
}) => {
  const { t } = useTranslation();

  // POST form to get a list of all possible voters, then navigate to search results page to select one. Could also pass the parameters to the search page and POST there since you may want to filter/sort results on the search page.
  // All fields are required
  // Keep in mind: this may be to match a user's contact or it may be to find someone who was just met or it may be used to add someone new who was just met
  const [voters, setVoters] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const [searched, setSearched] = useState(false);

  const handleFormSubmit = async (formData, { setSubmitting }) => {
    setLoadingItems(true);
    setSearched(true);
    await users.searchVoters(formData).then(({ data }) => {
      setVoters(data);
      setSubmitting(false);
      setLoadingItems(false);
    });
  };

  const closeModal = () => {
    setVisibleFn(false);
  };
  return (
    <>
      <StyledModal
        bodyStyle={{ background: theme.colors.background }}
        visible
        maskClosable
        onCancel={closeModal}
        footer={null}
        width={700}
      >
        <ModalContent>
          <HeaderAndDescription>
            <Header>{t('idvoters.header')}</Header>
            <Description>{t('inbox.report.description')}</Description>
          </HeaderAndDescription>
          <IdVotersForm
            isFriend={!!contact_id}
            campaignId={campaignId}
            activityId={activityId}
            userActivityId={userActivityId}
            firstName={firstName}
            lastName={lastName}
            onSubmit={handleFormSubmit}
          />

          <IdVotersList
            isFriend={!!contact_id}
            campaignId={campaignId}
            activityId={activityId}
            userActivityId={userActivityId}
            loading={loadingItems}
            show={searched}
            voters={voters}
            contactId={contact_id}
            onListItemClick={voter => {
              setShowVoterMatchModal(true);
              setVisibleFn(false);
              setVoter(voter);
            }}
          />
        </ModalContent>
      </StyledModal>
    </>
  );
};

IdVotersModal.propTypes = {
  activityId: PropTypes.number,
  campaignId: PropTypes.number,
  contact_id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  setShowVoterMatchModal: PropTypes.func.isRequired,
  setVisibleFn: PropTypes.func.isRequired,
  setVoter: PropTypes.func.isRequired,
  userActivityId: PropTypes.number,
};

IdVotersModal.defaultProps = {
  activityId: null,
  campaignId: null,
  contact_id: null,
  firstName: null,
  lastName: null,
  userActivityId: null,
};

export default IdVotersModal;
