import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Metric from '../CampaignLeaderboard/Metric';

export const MetricsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 2.875rem;
  background: ${({ theme }) => theme.colors.whiteSmoke};
  border-radius: 10px;
`;

function Metrics({ numActivities, numRecruits, numPoints }) {
  const { t } = useTranslation();
  return (
    <MetricsContainer>
      <Metric large label={t('hq.metrics.points')} value={numPoints}></Metric>
      <Metric large label={t('hq.metrics.actions')} value={numActivities}></Metric>
      <Metric large label={t('hq.metrics.recruits')} value={numRecruits}></Metric>
    </MetricsContainer>
  );
}

Metrics.propTypes = {
  numActivities: PropTypes.string,
  numPoints: PropTypes.string,
  numRecruits: PropTypes.string,
};

Metrics.defaultProps = {};

export default Metrics;
