import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Location from '@web/components/FanOut/components/icons/Location';
import Calendar from '@web/components/FanOut/components/icons/Calendar';
import Ticket from '@web/components/FanOut/components/icons/Ticket';
import { getFrequency } from '@web/utils/getVoterInfo';
import {
  smallBreakpoint,
  largeBreakpoint,
} from '@web/components/VoterRegistration/newComponents/breakpoints';
import { isBattleGroundState } from '@web/components/FanOut/helpers';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 8px;
  flex-direction: column;
  padding: 24px 32px;
  border: 2px solid ${({ theme }) => theme.colors.blue};
  background: radial-gradient(
      64.5% 64.52% at 97.64% 1.3%,
      rgba(197, 55, 94, 0.2) 0%,
      rgba(197, 55, 94, 0) 100%
    ),
    radial-gradient(
      66.22% 90.3% at 98.11% 98.85%,
      rgba(140, 205, 217, 0.2) 0%,
      rgba(140, 205, 217, 0) 100%
    ),
    radial-gradient(
      64.49% 126.95% at 0% 100%,
      rgba(184, 59, 201, 0.2) 0%,
      rgba(184, 59, 201, 0) 100%
    ),
    radial-gradient(
      86.75% 134.97% at 0% 0%,
      rgba(152, 204, 201, 0.2) 0%,
      rgba(152, 204, 201, 0) 100%
    ),
    radial-gradient(
      53.76% 53.76% at 50% 46.24%,
      rgba(225, 159, 83, 0.2) 0%,
      rgba(225, 159, 83, 0) 100%
    ),
    #fff;
  box-shadow: 4px 4px 0px 0px ${({ theme }) => theme.colors.blue};

  ${smallBreakpoint} {
    padding: 20px;
  }
`;

const TopBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RecruitDescriptionBox = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const NameText = styled.div`
  color: ${({ theme }) => theme.colors.trueBlack};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-transform: capitalize;
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const DescriptionText = styled.div`
  color: ${({ theme }) => theme.colors.black};
  margin-right: 2rem;
`;

const BattleGroundText = styled(DescriptionText)`
  color: #c00;
  font-weight: 600;
`;

const BottomBox = styled.div`
  display: flex;
  width: 100%;
  gap: 9px;
  flex-direction: row;
  border-radius: 8px;

  ${largeBreakpoint} {
    margin-top: 8px;
  }

  ${smallBreakpoint} {
    flex-direction: column;
  }
`;

const Box = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
`;

const Icon = styled.div`
  font-size: 2rem;
  font-weight: 500;
  line-height: 2rem;
`;

const VoterStatusText = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */

  span {
    color: #ffd54f;
    font-weight: 700;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
`;

const VBFriendInfo = ({ voter }) => {
  const { t } = useTranslation();

  const fullName = useMemo(
    () =>
      [voter?.['vb.tsmart_first_name'], voter?.['vb.tsmart_last_name']]
        .filter(item => !!item)
        .join(' ')
        .toLowerCase(),
    [voter],
  );
  const location = useMemo(
    () => [voter?.['vb.tsmart_city'], voter?.['vb.tsmart_state']].filter(item => !!item).join(', '),
    [voter],
  );
  const age = useMemo(() => voter?.['vb.voterbase_age'] || '', [voter]);
  const isRegistered = useMemo(
    () => voter?.['vb.voterbase_registration_status'].toLowerCase() === 'registered',
    [voter],
  );
  const isBattlegroundState = useMemo(
    () => isBattleGroundState(voter?.['vb.tsmart_state']),
    [voter],
  );
  const { frequencyPercentage } = useMemo(() => getFrequency(voter), [voter]);
  const isHightFrequency = useMemo(() => frequencyPercentage > 0.49, [frequencyPercentage]);
  const entry = useMemo(() => {
    // 12 - low-frequency/unregistered in battleground state
    // 10 - low-frequency/unregistered in non-battleground state
    // 3 - high-frequency in battleground state
    // 1 - high-frequency in non-battleground state
    const base = !isRegistered || !isHightFrequency ? 10 : 1;
    return base + (isBattlegroundState ? 2 : 0);
  }, [isRegistered, isHightFrequency, isBattlegroundState]);

  return (
    <Wrapper>
      <TopBox>
        {fullName && <NameText>{fullName}</NameText>}
        <RecruitDescriptionBox>
          {location && (
            <IconBox>
              <Location />
              <DescriptionText>{location}</DescriptionText>
            </IconBox>
          )}
          {age && (
            <IconBox>
              <Calendar />
              <DescriptionText>{age}</DescriptionText>
            </IconBox>
          )}
          {isBattleGroundState(voter?.['vb.tsmart_state']) && (
            <IconBox>
              <BattleGroundText>{t('fan_out.my_activity.battleground_state')}</BattleGroundText>
            </IconBox>
          )}
        </RecruitDescriptionBox>
      </TopBox>
      <BottomBox>
        <Box style={{ flex: 1 }}>
          <IconWrapper>
            <Icon>{isRegistered ? '✅' : '❌'}</Icon>
          </IconWrapper>
          <VoterStatusText>
            {isRegistered
              ? t('voting_information.registered')
              : t('voting_information.unregistered')}
          </VoterStatusText>
        </Box>
        <Box style={{ flex: 1 }}>
          <IconWrapper>
            <Icon>{isHightFrequency ? '🌟' : '😴'}</Icon>
          </IconWrapper>
          <VoterStatusText>
            {isHightFrequency
              ? t('fan_out.friend_look_up.high_frequency')
              : t('fan_out.friend_look_up.low_frequency')}
          </VoterStatusText>
        </Box>
        <Box style={{ flex: 2 }}>
          <IconWrapper>
            <Ticket isFriendLookUp />
          </IconWrapper>
          <VoterStatusText
            dangerouslySetInnerHTML={{
              __html: t('fan_out.friend_look_up.recruit_description', {
                count: entry,
              }),
            }}
          />
        </Box>
      </BottomBox>
    </Wrapper>
  );
};

VBFriendInfo.propTypes = {
  voter: PropTypes.object.isRequired,
};

VBFriendInfo.defaultProps = {};

export default memo(VBFriendInfo);
