import styled from 'styled-components';

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 15px;
  line-height: 23px;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-size: 23px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  line-height: 28px;
  margin-bottom: 8px;
  margin-top: -3px;
`;
