import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useModal, useOneCampaign } from '@web/hooks';
import styled, { css } from 'styled-components';
import CampaignAndCharityModal from '@web/components/common/CampaignAndCharityModal';
import OneCampaignLoading from '@web/components/common/OneCampaignLoading';
import { replaceDefaultCampaignImage } from '@web/utils/string';

import { DownOutlined } from '@ant-design/icons';

import SvgLogo from '@web/components/Logo';

const Wrapper = styled.div`
  width: 100%;
  padding: 1rem;
`;

const Content = styled.div`
  box-shadow: 4px 4px 20px rgba(0, 16, 72, 0.06);
  border-radius: 16px;
  padding: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChangeCampaignButton = styled.button`
  display: flex;
  border: none;
  align-items: center;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.bold};
  background: ${({ theme }) => theme.colors.teal10};
  font-size: 1rem;
  border-radius: 7px;
  width: 100%;
  padding: 11px;
  cursor: pointer;
`;

const Logo = styled.img`
  border-radius: 50%;
  border: 2px solid #222222;
  margin-bottom: 11px;
  object-fit: cover;
  ${({ $mini }) => css`
    height: ${$mini ? '52px' : '100px'};
    width: ${$mini ? '52px' : '100px'};
  `}
`;

const StyledSvgLogo = styled(SvgLogo)`
  border-radius: 50%;
  border: 2px solid #222222;
  margin-bottom: 11px;
  object-fit: cover;

  ${({ $mini }) => css`
    height: ${$mini ? '52px' : '100px'};
    width: ${$mini ? '52px' : '100px'};
  `}
`;

const CampaignSelect = ({ mini }) => {
  const { selectedCampaign: campaign, joinedCampaigns: campaigns } = useSelector(
    state => state.oneCampaign,
  );
  const { t } = useTranslation();

  useOneCampaign();

  const [openCampaignAndCharityModal] = useModal(CampaignAndCharityModal);
  const pageTitle = `${t('common.your')} ${t('titles.campaigns')}`;

  const handleSeeAll = useCallback(() => {
    openCampaignAndCharityModal({
      list: campaigns,
      title: pageTitle,
    });
  }, [pageTitle, openCampaignAndCharityModal, campaigns]);

  const buildLogo = () => {
    return campaign ? (
      <Logo $mini={mini} src={replaceDefaultCampaignImage(campaign?.url_profile_img)} />
    ) : (
      <StyledSvgLogo $mini={mini} variant="IconDark" height={25} />
    );
  };

  if (mini) {
    return (
      <OneCampaignLoading centered={false} ignoreNullCampaign>
        {buildLogo()}
      </OneCampaignLoading>
    );
  }

  return (
    <Wrapper>
      <Content>
        <OneCampaignLoading centered={false} ignoreNullCampaign>
          {buildLogo()}
          <ChangeCampaignButton onClick={handleSeeAll}>
            {campaign ? campaign?.name : t('placeholders.select_campaign')} <DownOutlined />
          </ChangeCampaignButton>
        </OneCampaignLoading>
      </Content>
    </Wrapper>
  );
};

CampaignSelect.propTypes = {
  mini: PropTypes.bool,
};

CampaignSelect.defaultProps = {
  mini: false,
};

export default CampaignSelect;
