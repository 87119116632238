import React from 'react';
import { message } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import throttle from 'lodash/throttle';

import theme from '@web/styles/theme';
import coverImage from '@assets/images/AdminAuth/laptop.png';

import LayoutWithCover from '../Components/LayoutWithCover';
import ImpactiveLogo from '../Components/ImpactiveLogo';
import { BoldLabel, GrayText, Header, PrimaryText } from '../Components/TextComponents';
import GeneralCardWrapper from '@web/components/common/Cards/GeneralCardWrapper';
import { ImpactiveTextButton } from '@web/components/ImpactiveButton';
import { auth } from '@web/services/api';

const FlexWithGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const AdminLinkSent = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { email } = history.location.state;

  const requestMagicAuthEmail = React.useCallback(() => {
    auth.sendMagicLinkEmail(email, 'campaign-signup').catch(err => {
      message.error('Failed to send the activation link');
    });
  }, [email]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const requestMagicAuthEmailThrottled = React.useCallback(
    throttle(requestMagicAuthEmail, 5000),
    [],
  );

  // We're redirected to this with an email address to try passwordless signup with
  React.useEffect(() => {
    if (!history.location.state?.email) return history.push('/auth/campaign-signup');

    requestMagicAuthEmail();
  }, [email, history, requestMagicAuthEmail]);

  return (
    <LayoutWithCover coverUrl={coverImage}>
      <ImpactiveLogo />
      <div>
        <Header>{t('auth.check-your-inbox')} 📩</Header>
        <PrimaryText>
          We've sent a message to <strong>{email}</strong> with a link to activate your account.
        </PrimaryText>
      </div>
      <GeneralCardWrapper color={theme.colors.whiteSmoke}>
        <FlexWithGap>
          <BoldLabel>Didn't get an email?</BoldLabel>
          <GrayText>
            If you don't see an email from us within a few minutes, a few things might've happened:
          </GrayText>
          <GrayText>
            <ul>
              <li>The email is in your spam folder.</li>
              <li>The email address you entered had a mistake or typo.</li>
              <li>
                You accidentally gave us another email address, like a separate work or personal
                account.
              </li>
              <li>We can't deliver to this email address due to firewalls or filtering.</li>
            </ul>
          </GrayText>
        </FlexWithGap>
      </GeneralCardWrapper>
      <Flex>
        <ImpactiveTextButton onClick={history.goBack} htmlType="button">
          Re-enter email and try again
        </ImpactiveTextButton>
        <ImpactiveTextButton onClick={requestMagicAuthEmailThrottled} htmlType="button">
          Resend
        </ImpactiveTextButton>
      </Flex>
    </LayoutWithCover>
  );
};

export default AdminLinkSent;
