import axios from 'axios';
import { track } from '../analytics';
import routes from './routes';

const usersApi = {
  // ARCHIVE THREAD
  archiveThread: (threadId, archived) => {
    return axios.post(`/api/v2/client/message_threads/${threadId}/archive`, { archived });
  },

  bulkUpdateMessageSent: async (contact_ids, { message }) => {
    return axios.post(`/api/v2/client/contacts/messages`, {
      contact_ids,
      message,
    });
  },

  // Uses the normal 'Authorization' : 'Bearer TOKEN' to reset password using token returned from the sendConfirmationCode request
  changePassword: (password, token) => {
    return axios.put(
      `/api/v2/client/user`,
      {
        user: {
          password,
          password_confirmation: password,
        },
      },
      {
        headers: {
          Authorization: token,
          'content-type': 'application/json',
        },
      },
    );
  },

  createNewContact: async (campaign_id, report, outreachReportId = null) => {
    return axios.post(`/api/v2/client/campaigns/${campaign_id}/new_contact`, {
      id: outreachReportId,
      outreach_report: report,
    });
  },

  followCampaign: async ({ campaign_id, campaign_name, campaign_slug }) => {
    track('FOLLOW_CAMPAIGN', {
      campaign_id: campaign_id,
      campaign_name: campaign_name,
    });
    return axios.post('/api/v2/client/user/campaigns/join', { campaign_slug });
  },

  followCampaignOptions: ({ campaign_id, auth_token, campaign_slug }) => {
    return {
      data: {
        campaign_slug,
      },
      headers: { Authorization: auth_token },
      method: 'post',
      url: '/api/v2/client/user/campaigns/join',
    };
  },

  // getMe
  get: async () => {
    return axios.get(`${routes.user}`);
  },

  getAllies: async ({ per_page = 30, page = 1, ...rest } = {}, axiosHeaders) => {
    return axios.get('/api/v2/client/users/allies', {
      params: {
        page,
        per_page,
        ...rest,
      },
      ...axiosHeaders,
    });
  },

  getCampaignOutreachReport: async ({
    campaign_id,
    selected_voterbase_id,
    contactable_type,
    contactable_id,
  }) => {
    return axios.get(`/api/v2/client/campaigns/${campaign_id}/new_report`, {
      contactable_id: contactable_id,
      contactable_type: contactable_type,
      selected_voterbase_id: selected_voterbase_id,
    });
  },

  getContacts: async ({ search = '', per_page = 30, page = 1, ...rest } = {}, axiosHeaders) => {
    return axios.get('/api/v2/client/users/contacts', {
      params: {
        contact_types: ['email'],
        page,
        per_page,
        search,
        ...rest,
      },
      ...axiosHeaders,
    });
  },

  getCurrentUnreadMessageThreadsCount: async campaign_id => {
    return axios.get('/api/v2/client/message_threads/current_unread_message_threads_count', {
      params: { campaign_id },
    });
  },

  getNeedsAttentionMessagesCount: async campaign_id => {
    return axios.get('/api/v2/client/message_threads/needs_attention_message_threads_count', {
      params: { campaign_id },
    });
  },

  getOutreachReport: async (campaign_id, contact_id) => {
    return axios.get(
      `/api/v2/client/campaigns/${campaign_id}/contacts/${contact_id}/outreach_report`,
    );
  },

  getRegistrationStep: (campaign_id, contact_id) => {
    return axios.get(`/api/v2/client/contacts/${contact_id}/registration_step`, {
      params: { campaign_id },
    });
  },

  getUserByReferralHash: hash => {
    return axios.get(`${routes.auth}/referrer/${hash}`);
  },

  getUserCampaigns: async (page, per_page) => {
    return axios.get(`${routes.user}/campaigns`, { params: { page, per_page } });
  },

  getUserContactMessages: async (
    { id: contact_id, per_page = 30, page = 1, ...rest } = {},
    axiosHeaders,
  ) => {
    return axios.get(`/api/v2/client/contacts/${contact_id}/messages`, {
      params: { page, per_page, ...rest },
      ...axiosHeaders,
    });
  },

  getUserMessages: async ({ per_page = 30, page = 1, ...rest } = {}, axiosHeaders) => {
    return axios.get('/api/v2/client/messages', {
      params: { page, per_page, ...rest },
      ...axiosHeaders,
    });
  },

  getUserMessageThread: async (
    { id: message_thread_id, per_page = 30, page = 1, ...rest } = {},
    axiosHeaders,
  ) => {
    return axios.get(`/api/v2/client/message_threads/${message_thread_id}`, {
      params: { page, per_page, ...rest },
      ...axiosHeaders,
    });
  },

  getUserMessageThreads: async ({ per_page = 30, page = 1, ...rest } = {}, axiosHeaders) => {
    return axios.get('/api/v2/client/message_threads', {
      params: { page, per_page, ...rest },
      ...axiosHeaders,
    });
  },

  getUserUserMessages: async (
    { id: user_id, per_page = 30, page = 1, ...rest } = {},
    axiosHeaders,
  ) => {
    return axios.get(`/api/v2/client/users/${user_id}/messages`, {
      params: { page, per_page, ...rest },
      ...axiosHeaders,
    });
  },

  getVoter: async (contact_id, voter_id) => {
    return axios.post(`/api/v2/client/contacts/${contact_id}/get_voter`, {
      selected_voterbase_id: voter_id,
    });
  },
  reassignAllToAdmin: async ({ userActivityId }) =>
    axios.post(`api/v2/client/user/activities/${userActivityId}/reassign_to_admin`),

  reassignToAdmin: async ({ messageThreadId }) =>
    axios.post(`api/v2/client/message_threads/${messageThreadId}/reassign_to_admin`),

  // RESET PASSWORD
  requestEmailCode: email => {
    return axios.get(`/api/v2/users/request_email_code`, { params: { email } });
  },

  searchVoters: async ({
    firstName,
    lastName,
    stateAbbv,
    ageRange,
    city,
    zipCode,
    email,
    phone,
  }) => {
    return axios.post(`/api/v2/client/contacts/possible_voters`, {
      age_range: ageRange,
      city: city || null, // optional
      email: undefined,
      first_name: firstName,
      last_name: lastName,
      phone: undefined,
      state: stateAbbv,
      zip: zipCode || null, // optional
    });
  },

  sendCanvasserMessagePublic: async (
    { campaign_id, report_id, activity_id, contact_id, user_activity_id },
    user_report,
  ) => {
    return axios.post(
      `/api/v2/client/campaigns/${campaign_id}/user_reports/${report_id}/send_canvasser_message_public`,
      { activity_id, contact_id, user_activity_id, user_report },
    );
  },

  sendConfirmationCode: (code, email) => {
    return axios.post(`/api/v2/client/confirmations/confirm_email`, {
      confirmation_code: code,
      email,
    });
  },

  sendInvite: async contact_id => {
    return axios.post(`/api/v2/client/contacts/${contact_id}/send_invite`);
  },

  setOnboardingViewed: async () => {
    return axios.put('/api/v2/client/user', { user: { onboarding_viewed: true } });
  },

  setVoter: async (contact_id, voter_id) => {
    return axios.post(`/api/v2/client/contacts/${contact_id}/set_voter`, {
      selected_voterbase_id: voter_id,
    });
  },

  twilioBatchSend: async (user_activity_id, batchSendInfo) => {
    return axios.post(`/api/v2/client/contacts/batch/messages/twilio`, {
      batch_info: batchSendInfo,
      user_activity_id: user_activity_id,
    });
  },

  unfollowCampaign: async ({ campaign_id, campaign_name, campaign_slug }) => {
    track('UNFOLLOW_CAMPAIGN', {
      campaign_id: campaign_id,
      campaign_name: campaign_name,
    });
    return axios.post('/api/v2/client/user/campaigns/leave', { campaign_slug });
  },

  updateCompletion: async user_activity_id => {
    return axios.put(`/api/v2/client/user/activities/${user_activity_id}`, {
      user_activity: { completed: true },
    });
  },

  updateContact: async (contact_id, contact, h) => {
    return axios.put(`/api/v2/client/contacts/${contact_id}`, { contact, h });
  },

  // MTS - In the app there are deselect ids and select all properties. I can't seem
  // to figure out how to use them or why they are needed.
  updateContactApprovals: async (selected_contact_ids, deselected_contact_ids, select_all) => {
    return axios.post('/api/v2/client/contacts/update_approvals', {
      contact_ids:
        selected_contact_ids && selected_contact_ids.length > 0 ? selected_contact_ids : undefined,
      deselected_contact_ids: deselected_contact_ids || undefined,
      select_all: select_all || undefined,
      type: 'email',
    });
  },

  updateListMessageSent: async (
    contact_id,
    message_type,
    user_activity_id,
    message,
    activity_script_id,
  ) => {
    return axios.post(`/api/v2/client/contacts/${contact_id}/messages/${message_type}`, {
      activity_script_id: activity_script_id,
      message: message,
      user_activity_id: user_activity_id,
    });
  },
  updateMe: async body => {
    return axios.put('/api/v2/client/user', { user: body });
  },

  updateMessageSent: async (contact_id, body) => {
    return axios.post(`/api/v2/client/contacts/${contact_id}/messages`, body);
  },

  updateOutreachReport: async ({ campaign_id, contact_id, tags, custom_fields }) => {
    return axios.post(
      `/api/v2/client/campaigns/${campaign_id}/contacts/${contact_id}/outreach_report`,
      {
        campaign_id: campaign_id,
        contact_id: contact_id,
        outreach_report: { selections: { custom_fields, tags } },
      },
    );
  },

  updateUserMessageSent: async (user_id, body) => {
    return axios.post(`/api/v2/client/users/${user_id}/messages/`, body);
  },

  uploadEmailContacts: async contacts => {
    return axios.post('/api/v2/client/contacts/import_email_no_enrich', {
      contact_type: 'email',
      contacts,
    });
  },

  uploadPhoneContacts: async contacts => {
    return axios.post('/api/v2/client/contacts/import_phone_no_enrich', {
      contact_type: 'phone',
      contacts,
    });
  },
};

export default usersApi;
