import styled from 'styled-components';
import { Card } from '@web/components/common/shared/Card';
import theme from '@web/styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 740px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const HeaderImage = styled.img`
  object-fit: contain;
  height: 240px;
  border-radius: 5px;
  margin: 16px 0;

  @media screen and (max-width: 768px) {
    margin: 16px;
    height: 120px;
  }
`;

export const Header = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0px;

  color: ${({ $color }) => $color ?? theme.formDefaultColors.fontColor};

  margin: 0;
  padding: 0;
`;

export const SubText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  white-space: pre-line;

  color: ${({ $color }) => $color ?? theme.formDefaultColors.fontColor};
  margin: 18px 0 0;

  padding: 0;
`;

export const StyledCard = styled(Card)`
  margin: 16px 0;

  background: ${({ $background }) => $background ?? theme.formDefaultColors.sectionBackgroundColor};

  @media screen and (max-width: 768px) {
    margin: 16px;
    width: auto;
  }
`;

export const PaddedFlexCard = styled(StyledCard)`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;

  > div {
    flex: 1 1 calc(50% - 16px);
    min-width: 210px;
  }
`;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  margin-top: auto;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  height: 80px;
  @media screen and (max-width: 768px) {
    padding: 16px;
  }

  background: ${({ $bgColor }) => $bgColor ?? theme.formDefaultColors.pageBackgroundColor};
  z-index: 2;
`;
