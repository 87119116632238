import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import QuestionCard from './QuestionCard';
import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';

const Wrapper = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;

  ${smallBreakpoint} {
    margin-top: 0;
  }
`;

const QuestionFields = ({ questions, handleChange, values }) => {
  return (
    <Wrapper>
      {questions.map((question, i) => (
        <QuestionCard
          key={question.id}
          index={i}
          question={{
            ...question,
            answered_value: values.question_answers[i].value,
          }}
          handleChange={handleChange}
          values={values}
        />
      ))}
    </Wrapper>
  );
};

QuestionFields.propTypes = {
  handleChange: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      answer_mappings: PropTypes.object,
      id: PropTypes.number,
      position: PropTypes.number,
      question: PropTypes.string,
      question_type: PropTypes.string,
      required: PropTypes.bool,
    }),
  ).isRequired,

  values: PropTypes.shape({
    question_answers: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
      }),
    ),
  }),
};

export default QuestionFields;
