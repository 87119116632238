import styled from 'styled-components';

const ContactList = styled.ul`
  list-style: none;
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 5px;
  max-height: ${props => (props.maxHeight ? props.maxHeight : '')};
  padding: 0px;
  overflow-y: auto;
`;

export default ContactList;
