import * as React from 'react';
import PropTypes from 'prop-types';

function Phone({ size, ...rest }) {
  return (
    <svg height={size} viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="39" cy="39" r="39" fill="#D5ECEF" />
      <path
        d="M58.6652 28.6991H19.3347V28.5015C19.3347 24.9908 22.1815 22.144 25.6922 22.144H52.3077C55.8184 22.144 58.6652 24.9908 58.6652 28.5015V28.6991Z"
        fill="#8CC4CA"
      />
      <path d="M58.6653 28.5267H44.6187V32.2725H58.6653V28.5267Z" fill="#8CC4CA" />
      <path d="M33.3813 28.5267H19.3347V32.2725H33.3813V28.5267Z" fill="#8CC4CA" />
      <path
        d="M44.6187 26.8262V28.6991H58.6653V28.5015C58.6653 27.9209 58.581 27.3609 58.4349 26.8262H44.6187Z"
        fill="#4D9DA6"
      />
      <path
        d="M19.5651 26.8262C19.419 27.3609 19.3347 27.9209 19.3347 28.5015V28.6991H33.3813V26.8262H19.5651Z"
        fill="#4D9DA6"
      />
      <path d="M30.572 37.1271H24.9534V40.8728H30.572V37.1271Z" fill="#8CC4CA" />
      <path d="M41.8093 37.1271H36.1907V40.8728H41.8093V37.1271Z" fill="#8CC4CA" />
      <path d="M53.0466 37.1271H47.428V40.8728H53.0466V37.1271Z" fill="#8CC4CA" />
      <path d="M30.572 44.6187H24.9534V48.3644H30.572V44.6187Z" fill="#8CC4CA" />
      <path d="M41.8093 44.6187H36.1907V48.3644H41.8093V44.6187Z" fill="#8CC4CA" />
      <path d="M53.0466 44.6187H47.428V48.3644H53.0466V44.6187Z" fill="#8CC4CA" />
      <path d="M30.572 52.1101H24.9534V55.8559H30.572V52.1101Z" fill="#8CC4CA" />
      <path d="M41.8093 52.1101H36.1907V55.8559H41.8093V52.1101Z" fill="#8CC4CA" />
      <path d="M53.0466 52.1101H47.428V55.8559H53.0466V52.1101Z" fill="#8CC4CA" />
    </svg>
  );
}

Phone.propTypes = {
  size: PropTypes.number,
};

Phone.defaultProps = {
  size: 78,
};

export default Phone;
