import { message } from 'antd';
import axios from 'axios';
import { oneOfType, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { activities } from '../../../services/api';
import i18n from '../../i18n';

const Content = styled.div`
  display: flex;
  margin: 1rem 0;
  max-height: 400px;
`;

/**
 *  Preview component for uploaded media.
 *  @param file - is either uploaded file, or a url to previously updated file.
 *  @param contentType - in case file is a url conentType needs to be provided too.
 */

export const TestimonialMediaPreview = ({ file, contentType }) => {
  const [media, setMedia] = useState();
  const [mediaType, setMediaType] = useState(file ? file.type : contentType);
  const isImage = mediaType && mediaType.match('image');
  const isVideo = mediaType && mediaType.match('video');

  // Create preview when uploaded file changes
  useEffect(() => {
    if (!file) {
      return;
    }

    // Direct media URL provided
    if (typeof file === 'string') {
      setMedia(file);
      setMediaType(contentType);
      return;
    }

    // Otherwise we are dealing with file upload, and we read it directly.
    const reader = new FileReader();
    const callbackReaderFn = () => {
      setMedia(reader.result);
      setMediaType(file.type);
    };
    reader.addEventListener('load', callbackReaderFn);
    reader.readAsDataURL(file);

    // eslint-disable-next-line consistent-return
    return function cleanup() {
      reader.removeEventListener('load', callbackReaderFn);
    };
    // eslint-disable-next-line
  }, [file]);

  // don't render anything if there is no media or if it's an usupported format
  if (!media || (!isImage && !isVideo)) return null;

  if (isImage) {
    return (
      <Content>
        <img src={media} alt="media" style={{ height: '400px' }} />
      </Content>
    );
  }

  // Otherwise it's a video
  return (
    <Content>
      <video controls src={media} alt="media" style={{ height: '400px' }}>
        <track kind="captions" />
      </video>
    </Content>
  );
};

TestimonialMediaPreview.propTypes = {
  contentType: string,
  file: oneOfType([shape(File), string]),
};

TestimonialMediaPreview.defaultProps = {
  contentType: null,
  file: null,
};

/**
 *  Other helper functions that deal with file upload
 */

// Separate axios instance because we don't want to use common headers for accessing S3
const s3 = axios.create();
s3.defaults.headers.common = {
  'x-amz-acl': 'public-read',
};

// Uploader/preview helper function
const generateSignedUrl = async ({ type: fileType, name: fileName }) => {
  const {
    data: { signedUrl },
  } = await activities.getUploadFileUrl(fileType, fileName);

  return signedUrl;
};

export const uploadMedia = async ({ onProgress, onSuccess, onError, file }) => {
  const signedUrl = await generateSignedUrl(file);

  s3.put(signedUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: ({ total, loaded }) => {
      onProgress({ percent: Math.round((loaded / total) * 100) }, file);
    },
  })
    .then(({ data: response }) => {
      onSuccess(
        {
          name: file.name,
          type: file.type,
          url: signedUrl.split('?')[0],
          ...response,
        },
        file,
      );
    })
    .catch(onError);
};

export const validateFile = ({ type: fileType, size }) => {
  const isImage = fileType.match('image');
  const isVideo = fileType.match('video');
  const fileSizeMB = size / 1024 / 1024;
  const mediaLimitMB = 200;

  const isValidFormat = isImage || isVideo;
  const isValidSize = fileSizeMB <= mediaLimitMB;

  if (!isValidFormat) {
    message.error(i18n.t('activity.testimonial.file_format_error'));
  }

  if (!isValidSize) {
    message.error(`${i18n.t('activity.testimonial.file_size_error')} ${mediaLimitMB} MB!`);
  }

  return isValidFormat && isValidSize;
};
