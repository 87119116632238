import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledErrorMessage as ErrorMessage } from '@web/components/common/shared/FormInput';
import RadioGroupFormPartial from './RadioGroupFormPartial';
import theme from '@web/styles/theme';
import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';

const StyledCard = styled.div`
  display: flex;
  width: 100%;
  background-color: ${theme.colors.white};
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 11px;

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    margin-top: 21px;
  }
`;

const Title = styled.div`
  display: flex;
  flex: 0.25;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin-bottom: 8px;

  ${smallBreakpoint} {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  &&& {
    display: flex;
    margin-left: calc(20%);
    margin-top: 0px;

    @media screen and (max-width: 1300px) {
      margin-left: 0;
    }
  }
`;

const QuestionCard = ({
  handleChange,
  index,
  question: {
    answer_mappings: options,
    question: title,
    question_type: type,
    answered_value: answeredValue,
  },
  values,
}) => {
  if (type !== 'multiple_choice' && type !== 'closed') {
    return null;
  }

  return (
    <>
      <StyledCard>
        <Title>{title}</Title>
        <RadioGroupFormPartial
          handleChange={handleChange}
          name={`question_answers[${index}].value`}
          index={index}
          options={options}
          answeredValue={answeredValue}
          values={values}
        />
      </StyledCard>
      <StyledErrorMessage name={`question_answers[${index}].value`} />
    </>
  );
};

QuestionCard.propTypes = {
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number,
  question: PropTypes.shape({
    answer_mappings: PropTypes.object,
    answered_value: PropTypes.string,
    id: PropTypes.number,
    question: PropTypes.string,
    question_type: PropTypes.string,
    required: PropTypes.bool,
  }).isRequired,
  values: PropTypes.object.isRequired,
};

export default QuestionCard;
