import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Contact from './Contact';
import ContactList from './ContactList';
import ListTitle from './ListTitle';
import Loading from './Loading';
import SelectAll from './SelectAll';
import StepButtons from './StepButtons';

import useGoogleContacts from './useGoogleContacts';
import useUserLocked from './useUserLocked';

const SelectAndStep = styled.div`
  display: flex;
  justify-content: space-between;
`;

function GoogleContacts({
  step,
  maxHeight,
  type,
  decrementStep,
  incrementStep,
  selectedContacts,
  setSelectedContacts,
}) {
  const { t } = useTranslation();
  const [allSelected, setAllSelected] = React.useState(true);
  const { contacts, loading } = useGoogleContacts(type);
  const { lockedToCampaign } = useUserLocked();
  const checkboxesAvailable = lockedToCampaign || type === 'other';

  React.useEffect(() => {
    if (!loading && contacts) {
      setSelectedContacts(formatContacts(contacts));
    }
    // eslint-disable-next-line
  }, [contacts, loading]);

  function formatContacts(contacts) {
    return contacts.map(({ firstName, lastName, emails }) => ({
      email: emails[0].email,
      firstName,
      lastName,
    }));
  }

  function handleToggleContact(contact, selected) {
    if (selected) {
      return setSelectedContacts(selectedContacts.filter(el => el.email !== contact.email));
    }
    return setSelectedContacts(selectedContacts.concat([contact]));
  }

  function handleSelectAll() {
    const newAllSelected = !allSelected;
    setSelectedContacts(newAllSelected ? formatContacts(contacts) : []);
    setAllSelected(newAllSelected);
  }

  const noContacts = !loading && contacts.length === 0;

  if (loading) {
    return <Loading />;
  }

  const contactsType = 'Google';
  return (
    <div>
      <ListTitle
        contactsType={contactsType}
        selectedCount={selectedContacts.length}
        step={step}
        stepDescription={noContacts && t('contact.sync.load_contact_error', { contactsType })}
      />
      <ContactList maxHeight={maxHeight}>
        {contacts.map(({ id, firstName, lastName, emails }) => (
          <Contact
            key={id}
            email={emails[0].email}
            firstName={firstName}
            lastName={lastName}
            hasCheckbox={checkboxesAvailable}
            disabledCheckbox={type === 'my'}
            selected={!!selectedContacts.find(el => el.email === emails[0].email)}
            toggleContact={checkboxesAvailable ? handleToggleContact : () => null}
          />
        ))}
      </ContactList>
      <SelectAndStep>
        {!noContacts && checkboxesAvailable && (
          <SelectAll allSelected={allSelected} onClick={handleSelectAll} />
        )}
        <StepButtons hideBackward goBack={decrementStep} goForward={incrementStep} />
      </SelectAndStep>
    </div>
  );
}

GoogleContacts.propTypes = {
  decrementStep: PropTypes.func.isRequired,
  incrementStep: PropTypes.func.isRequired,
  maxHeight: PropTypes.string,
  selectedContacts: PropTypes.array.isRequired,
  setSelectedContacts: PropTypes.func.isRequired,
  step: PropTypes.number,
  type: PropTypes.string,
};

GoogleContacts.defaultProps = {
  maxHeight: '',
  step: 1,
  type: 'my',
};

export default GoogleContacts;
