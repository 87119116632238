import searchAdvocacyOrganizations from '@assets/images/web/searchAdvocacyOrganizations.png';
import searchCampaignNearMe from '@assets/images/web/searchCampaignNearMe.png';
import searchCharities from '@assets/images/web/searchCharities.png';
import searchCongessionalCandidates from '@assets/images/web/searchCongessionalCandidates.png';
import searchPresidentialCandidates from '@assets/images/web/searchPresidentialCandidates.png';

import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { campaigns as campaignsAPI } from 'web/services/api';
import { AuthContext } from 'web/utils/context';
import { media } from '../../styles/theme';
import Container from '../Container';
import Loading from '../common/Loading';
import CategoryList from './CategoryList';

const StyledCategoryView = styled.div``;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0px;
  margin-right: -15px;
`;

const CategoryCardWrapper = styled.div`
  width: 50%;
  padding-right: 15px;
  margin-bottom: 15px;
  ${media.ns} {
    width: 20%;
    min-width: 190px;
  }
`;

const CategoryCard = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.activityBackground};
  &:hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const CardImage = styled.img`
  width: 100%;
`;

const TitleAndDescription = styled.div`
  padding: 20px;
`;

const CardTitle = styled.div`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  padding-bottom: 4px;
`;

const CardDescription = styled.div`
  font-size: 12px;
`;

const SellAllButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.blackItalic};
`;

const CategoryView = ({ setZip }) => {
  const { t } = useTranslation();

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentCategory, setCurrentCategory] = useState(null);
  const { user } = useContext(AuthContext);

  // NOTE - Prod mappings to images are for backwards compat with existing
  // categories; once we run seed_causes_job.rb and verify search works
  // with reindexed elasticsearch that won't be needed.
  const logo = {
    Advocacy: searchAdvocacyOrganizations,
    // Prod
    'Advocacy Organizations': searchAdvocacyOrganizations,
    Charities: searchCharities,
    //
    Charity: searchCharities,
    Electoral: searchCongessionalCandidates,
    'Electoral Campaigns': searchCongessionalCandidates,
    Presidential: searchPresidentialCandidates,
    // Prod
    'Presidential Election': searchPresidentialCandidates,
  };

  const renderCategoryCard = (src, title, description, onClick) => {
    return (
      <CategoryCardWrapper key={title}>
        <CategoryCard onClick={onClick}>
          <CardImage src={src || logo[title]} alt={title} />
          <TitleAndDescription>
            <CardTitle>{title}</CardTitle>
            <CardDescription>{description}</CardDescription>
          </TitleAndDescription>
        </CategoryCard>
      </CategoryCardWrapper>
    );
  };

  // Load categories
  useEffect(() => {
    campaignsAPI.getCategories().then(({ data: { data } }) => {
      setCategories(data);
      setLoading(false);
    });
  }, []);

  return (
    <StyledCategoryView>
      {!currentCategory && (
        <CardContainer>
          {categories.map(category => {
            return renderCategoryCard(category.logo_url, category.name, category.description, () =>
              setCurrentCategory(category),
            );
          })}
          {renderCategoryCard(
            searchCampaignNearMe,
            t('search.find_near_me'),
            t('search.discover_causes'),
            () => {
              user && user.supplied_zip_code && setZip(user.supplied_zip_code);
            },
          )}
        </CardContainer>
      )}

      <Container>
        {currentCategory && <CategoryList category={currentCategory} />}
        {loading && <Loading centered />}
        {currentCategory && (
          <SellAllButton type="link" onClick={() => setCurrentCategory(null)}>
            {t('search.see_all_categories')}
          </SellAllButton>
        )}
      </Container>
    </StyledCategoryView>
  );
};

CategoryView.propTypes = {
  setZip: PropTypes.func.isRequired,
};

export default CategoryView;
