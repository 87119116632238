import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@web/components/DialerActivity/components/Button';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StyledBackButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.bold};
  margin-top: 6px;
  width: 200px;
`;

function BackButton({ children, ...rest }) {
  let history = useHistory();
  const { t } = useTranslation();

  return (
    <StyledBackButton onClick={history.pop} variant="gray" {...rest}>
      {children || t('common.back')}
    </StyledBackButton>
  );
}

BackButton.propTypes = {
  children: PropTypes.any,
};

BackButton.defaultProps = {
  children: null,
};

export default BackButton;
