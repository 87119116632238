import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@web/styles/theme';
import { isDark } from '@web/utils/other';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 130px auto;
  grid-template-rows: auto;
  grid-template-areas:
    'image titles'
    'buttons buttons';
  gap: 30px;
`;

const ProfileImage = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 100%;
  grid-area: image;
  border: solid ${theme.colors.trueBlack} 2px;
  object-fit: cover;
`;

const Buttons = styled.div`
  grid-area: buttons;
  margin-bottom: 16px;
`;

const Titles = styled.div`
  grid-area: titles;
  font-size: 36px;
  color: ${theme.colors.trueBlack};
  font-weight: 700;
  align-self: center;
`;

const StyledButton = styled.button`
  border: solid 2px ${theme.colors.black};
  color: ${({ $color, $active }) => ($active && isDark($color ?? '#23293E') ? '#fff' : '#000')};
  background: ${({ $active, $color }) => ($active ? $color ?? '#23293E' : 'white')};
  box-shadow: none;
  border-radius: 6px;
  height: 45px;
  width: 120px;
  font-weight: ${({ $active }) => ($active ? '700' : '4000')};

  &&:first-of-type {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &&:last-of-type {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const TopSection = ({ controls }) => {
  return (
    <Wrapper>
      <ProfileImage src={controls.campaign?.url_profile_img} alt="Campaign logo" />
      <Titles>
        <div>Leaderboard:</div>
        <div>{controls.activity?.title}</div>
      </Titles>
      <Buttons>
        <StyledButton
          $color={
            controls.activity?.settings?.button_color ??
            controls.activity?.settings?.secondary_color
          }
          $active={controls.type === 'individual'}
          onClick={() => controls.setType('individual')}
        >
          Individuals
        </StyledButton>
        <StyledButton
          $color={
            controls.activity?.settings?.button_color ??
            controls.activity?.settings?.secondary_color
          }
          $active={controls.type === 'team'}
          onClick={() => controls.setType('team')}
        >
          Teams
        </StyledButton>
      </Buttons>
    </Wrapper>
  );
};

TopSection.propTypes = {
  controls: PropTypes.shape({
    activity: PropTypes.shape({
      settings: PropTypes.shape({
        button_color: PropTypes.string,
        secondary_color: PropTypes.string,
      }),
      title: PropTypes.string,
    }),
    campaign: PropTypes.shape({
      url_profile_img: PropTypes.string,
    }),
    setType: PropTypes.func,
    type: PropTypes.string,
  }),
};

export default TopSection;
