import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'react-phone-input-2/lib/style.css';

import FormInput from '@web/components/common/shared/FormInput';
import FormDatePicker from '@web/components/common/shared/FormDatePicker';
import PhoneNumberInput from '@web/components/common/shared/PhoneNumberInput';
import { availableQuestions, requiredFieldsProps } from '@web/components/FanOutVotingPlan/utils';
import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import theme from '@web/styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 36px 0 24px 0;
  flex-wrap: wrap;
  gap: 24px;

  > div {
    flex: 1 1 calc(50% - 24px);
    min-width: 250px;
  }

  ${smallBreakpoint} {
    margin: 24px 0 18px 0;
    gap: 18px;
  }
`;

const InfoFields = ({ initialValues, values, handleChange, setFieldValue }) => {
  const fields = useMemo(() => {
    const questionKeys = Object.keys(initialValues);
    return availableQuestions.filter(question => questionKeys.includes(question));
  }, [initialValues]);

  return (
    <Wrapper>
      {fields.map(field => {
        if (field === 'date_of_birth') {
          return (
            <FormDatePicker
              key={field}
              prefix={requiredFieldsProps[field]?.prefix}
              label={requiredFieldsProps[field]?.label}
              placeholder={requiredFieldsProps[field]?.placeholder}
              backgroundColor={theme.colors.background}
              textTransform="capitalize"
              name={field}
              value={values[field]}
              onChange={e => setFieldValue(field, e.target.value)}
            />
          );
        }

        if (field === 'phone') {
          return (
            <PhoneNumberInput
              key={field}
              name={field}
              label={requiredFieldsProps[field]?.label}
              placeholder={requiredFieldsProps[field]?.placeholder}
              backgroundColor={theme.colors.background}
              textTransform="capitalize"
              onChange={e => setFieldValue(field, e)}
              value={values[field]}
              prefixIcon={requiredFieldsProps[field]?.prefix}
            />
          );
        }

        return (
          <FormInput
            key={field}
            prefix={requiredFieldsProps[field]?.prefix}
            label={requiredFieldsProps[field]?.label}
            placeholder={requiredFieldsProps[field]?.placeholder}
            backgroundColor={theme.colors.background}
            textTransform="capitalize"
            name={field}
            type={field === 'email' && 'email'}
            value={values[field]}
            onChange={handleChange}
          />
        );
      })}
    </Wrapper>
  );
};

InfoFields.propTypes = {
  handleChange: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default InfoFields;
