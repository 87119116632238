import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import StatusBar from '../StatusBar';

const BannerText = styled.span`
  color: white;
  font-family: ${({ theme }) => theme.fonts.black};
  font-size: 14px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

function SessionEnded() {
  return (
    <StatusBar bgColor="red">
      <BannerText>Session Ended</BannerText>
    </StatusBar>
  );
}

SessionEnded.propTypes = {};

SessionEnded.defaultProps = {};

export default SessionEnded;
