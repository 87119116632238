import * as React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import sendError from '@web/utils/sendError';
import styled from 'styled-components';

import { Body, Title } from '@web/components/DialerActivity/components/TextStyles';
import InteractiveScript from '../InteractiveScript';
import Question from './Question';
import MessageBox from './MessageBox';

import { machineTransition, setCurrentAnswers } from '@web/reducers/dialerReducer';
import { RESPONSES_SUBMITTED } from '@web/reducers/dialerReducer/dialerMachine';
import { useDispatch, useSelector } from 'react-redux';

const Answer = styled.p`
  color: ${({ theme }) => theme.colors.darkBlue};
`;

const Item = styled.div`
  margin-bottom: 20px;
`;

const StyledReviewResponses = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const Scroll = styled.div`
  flex: 1;
  overflow-y: auto;
`;

function ViewResponses({ interactiveScriptProps }) {
  const dispatch = useDispatch();
  const { connectedContact, voter_call_id } = useSelector(state => state.dialer);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const { answers, questions } = interactiveScriptProps;
  const loading = interactiveScriptProps.loading || submitLoading;

  function handleEditPress() {
    dispatch(setCurrentAnswers([]));
    setIsEditing(true);
  }

  function submitResponses() {
    if (loading) {
      return;
    }

    // Don't attempt the API request if the user did not select
    // any respones.
    if (answers.length === 0) {
      return dispatch(machineTransition(RESPONSES_SUBMITTED));
    }

    const url = `/api/v2/client/activity_script_responses`;
    setSubmitLoading(true);

    // build answers into the format the back end requires
    const responses = answers.reduce(
      (acc, answer) => ({
        ...acc,
        [answer.questionReference]: answer.answerReference,
      }),
      {},
    );

    const payload = {
      activity_script_id: interactiveScriptProps.scriptId,
      responses,
      voter_voice_call_id: voter_call_id,
    };

    axios
      .patch(url, payload)
      .then(res => {
        // We don't need to do anything here
      })
      .catch(err => {
        sendError('Dialer - Error submitting survey', err);
      })
      .finally(() => {
        dispatch(machineTransition(RESPONSES_SUBMITTED));
      });
  }

  const questionsAndAnswers = answers.map(answer => {
    const question = questions.find(q => answer.questionReference === q.reference);
    const questionObj = { reference: question.reference, stem: question.stem };
    return {
      answer: question.options.find(option => answer.answerReference === option.reference),
      question: questionObj,
    };
  });

  if (isEditing) {
    return (
      <>
        <Title>Editing Responses</Title>
        <InteractiveScript
          connectedContact={connectedContact}
          handleDoneClick={() => setIsEditing(false)}
          isEdit
          {...interactiveScriptProps}
        />
      </>
    );
  }

  const noResponses = answers.length === 0;
  const noResponsesMsg = 'You have not selected any responses for this call.';

  return (
    <StyledReviewResponses>
      <Scroll>
        <Title style={{ marginBottom: 16 }}>Review Responses</Title>
        {noResponses && <Body>{noResponsesMsg}</Body>}
        {questionsAndAnswers.map(el => (
          <Item key={el.question.reference}>
            <Question text={el.question.stem} />
            <Answer>{el.answer.label}</Answer>
          </Item>
        ))}
      </Scroll>
      <MessageBox
        handleEditPress={handleEditPress}
        loading={loading}
        submitResponses={submitResponses}
      />
    </StyledReviewResponses>
  );
}

ViewResponses.propTypes = {
  interactiveScriptProps: PropTypes.shape({
    answers: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    questions: PropTypes.array.isRequired,
    scriptId: PropTypes.number.isRequired,
    setAnswers: PropTypes.func.isRequired,
  }),
};

ViewResponses.defaultProps = {};

export default ViewResponses;
