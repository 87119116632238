import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { message } from 'antd';
import Clipboard from 'react-clipboard.js';
import ShareButton from './Box/ShareButton';
import Twitter from '@web/components/VoterRegistration/newComponents/icons/Twitter';
import LinkedIn from '@web/components/VoterRegistration/newComponents/icons/LinkedIn';
import Facebook from '@web/components/VoterRegistration/newComponents/icons/Facebook';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import Incentives from '@web/components/VoterRegistration/newComponents/ChooseFlow/Incentives';

import {
  largeBreakpoint,
  smallBreakpoint,
} from '@web/components/VoterRegistration/newComponents/breakpoints';
import { track } from '@web/services/analytics';
import { vrDefaultColors } from '@web/styles/theme';

const StyleShareButton = styled(ShareButton)`
  background: ${({ $bgColor }) => $bgColor ?? 'transparent'};
  border: 1px solid ${({ $outlineColor }) => $outlineColor};
  color: ${({ $fontColor }) => $fontColor};

  svg path {
    fill: ${({ $bgColor, $outlineColor }) => (!$bgColor ? $outlineColor : 'unset')};
  }
`;

const Right = styled.div`
  border-radius: 24px;
  grid-area: right;
  overflow: hidden;

  ${largeBreakpoint} {
    padding: 1rem 1rem 1.5rem;
    box-shadow: 4px 4px 64px 0px #0000000d;
  }
`;

const Image = styled.img`
  border-radius: 0.5rem;
  max-height: ${({ $isIncentives }) => ($isIncentives ? '174px' : '100%')};
  object-fit: ${({ $isFanOutReferrer }) => ($isFanOutReferrer ? 'scale-down' : 'cover')};
  width: 100%;

  ${largeBreakpoint} {
    max-height: ${({ $isIncentives }) => ($isIncentives ? '224px' : '100%')};
    margin-bottom: ${({ $isIncentives }) => (!$isIncentives ? 0 : '2rem')};
  }
`;

const IncentivesContainer = styled.div`
  margin-top: 1rem;

  ${smallBreakpoint} {
    padding: 0 1rem 1.5rem;
  }

  ${largeBreakpoint} {
    margin-top: 2rem;
  }
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ $isFanOutReferrer }) => ($isFanOutReferrer ? '0' : '1rem')};
  grid-area: buttons;
  margin-top: 2rem;
  ${largeBreakpoint} {
    flex: 1;
    justify-content: flex-end;
    margin-top: 0;
  }
`;

const StyledChooseFlow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'title'
    'right'
    'buttons';

  ${largeBreakpoint} {
    align-items: space-between;
    grid-gap: 0 3rem;
    grid-template-columns: 1fr 448px;
    grid-template-areas:
      'title right'
      'buttons right';
  }
`;

const VoterRegistrationShare = props => {
  const { t } = useTranslation();
  const { activity, referralLink, text, team } = props;
  const fontColor = React.useMemo(
    () => activity?.settings?.font_color ?? vrDefaultColors.fontColor,
    [activity?.settings?.font_color],
  );

  const buttonColor = React.useMemo(
    () => activity?.settings?.button_color ?? vrDefaultColors.buttonColor,
    [activity?.settings?.button_color],
  );

  const bgColor = React.useMemo(
    () => activity?.settings?.background_color ?? vrDefaultColors.backgroundColor,
    [activity?.settings?.background_color],
  );

  const isIncentives = React.useMemo(
    () => activity?.activity_incentives_attributes?.length > 0,
    [activity],
  );

  const image = React.useMemo(() => team?.profile_img_url || activity.media_url, [activity, team]);

  return (
    <StyledChooseFlow>
      <ButtonColumn>
        {text}
        <Clipboard
          style={{ marginTop: 30 }}
          component="div"
          data-clipboard-text={referralLink}
          onSuccess={() => {
            message.info(t('common.copied_to_clipboard'));
            track('VR_COPY_SHARE_LINK', {
              activity_id: activity?.id,
              activity_title: activity?.title,
              activity_type: activity?.type,
              campaign_id: activity?.campaign?.id,
              campaign_name: activity?.campaign?.name,
              team_id: team?.id,
              team_name: team?.name,
            });
          }}
        >
          <StyleShareButton $fontColor={bgColor} $outlineColor={buttonColor} $bgColor={buttonColor}>
            {t('check_registration.referral.copyButton')}
          </StyleShareButton>
        </Clipboard>
        <FacebookShareButton url={referralLink}>
          <StyleShareButton $fontColor={buttonColor} $outlineColor={buttonColor}>
            <Facebook />
            Share on Facebook
          </StyleShareButton>
        </FacebookShareButton>
        <TwitterShareButton url={referralLink}>
          <StyleShareButton $fontColor={buttonColor} $outlineColor={buttonColor}>
            <Twitter />
            Share on Twitter
          </StyleShareButton>
        </TwitterShareButton>
        <LinkedinShareButton url={referralLink}>
          <StyleShareButton $fontColor={buttonColor} $outlineColor={buttonColor}>
            <LinkedIn />
            Share on LinkedIn
          </StyleShareButton>
        </LinkedinShareButton>
      </ButtonColumn>
      <Right>
        <Image $isIncentives={isIncentives} src={image} />
        {isIncentives && (
          <IncentivesContainer>
            <Incentives
              fontColor={fontColor}
              iconColor={fontColor}
              pageColor={bgColor}
              incentives={activity.activity_incentives_attributes}
            />
          </IncentivesContainer>
        )}
      </Right>
    </StyledChooseFlow>
  );
};

VoterRegistrationShare.propTypes = {
  activity: PropTypes.object,
  referralLink: PropTypes.string,
  team: PropTypes.object,
  text: PropTypes.node,
};

export default VoterRegistrationShare;
