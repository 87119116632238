import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import SurveyActivityForm from './components/SurveyActivityForm';
import SurveyIntroPage from './components/SurveyIntroPage';

const ActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  background-color: ${({ $bgColor }) => $bgColor || '#fff'};
`;

const SurveyActivity = ({ activity, team }) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const activeFormSection = query.get('start') || '0';
  const [showIntroPage, setShowIntroPage] = useState(activeFormSection === '0');

  if (showIntroPage) {
    return (
      <SurveyIntroPage activity={activity} team={team} showForm={() => setShowIntroPage(false)} />
    );
  }

  return (
    <ActivityContainer $bgColor={activity.settings?.background_color}>
      <SurveyActivityForm activity={activity} team={team} />
    </ActivityContainer>
  );
};

SurveyActivity.propTypes = {
  activity: PropTypes.object,
  team: PropTypes.object,
};

export default SurveyActivity;
