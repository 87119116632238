import React from 'react';
import { users } from '../../services/api';

function useHasContacts() {
  const [hasContacts, setHasContacts] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    users
      .getContacts()
      .then(({ data }) => {
        if (data.data.length > 0) {
          setHasContacts(true);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return { hasContacts, loading };
}

export default useHasContacts;
