import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ImpactiveLink from '../Link';
import styled from 'styled-components';
import { media } from '@web/styles/theme';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import VolunteerLeaderboardCard from './components/VolunteerLeaderboardCard';

import activityRoutes from '@web/services/api/activities';

const StyledVolunteersLeaderboard = styled.div`
  padding: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Container = styled.div`
  padding: 50px 25px 140px;
  margin: 0 auto;

  ${media.lg} {
    padding: 180px 25px;
  }

  ${media.xl} {
    padding: 180px 0 210px;
    width: 80%;
  }

  ${media.xxxl} {
    width: 60%;
  }
`;

const LoadingSpin = styled.div`
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;

const SectionTitle = styled.h3`
  font-size: 3em;
  color: ${({ theme }) => theme.colors.blackL25};
  font-weight: 600;
  margin-bottom: 60px;
`;

const VolunteersList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  row-gap: 20px;

  ${media.lg} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${media.xxl} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

const EmptyMessage = styled.div`
  text-align: center;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.black};
  margin: 120px 0 80px;
`;

const VolunteersLeaderboard = ({ campaign, activity, team }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [contactMetrics, setContactMetrics] = useState(null);

  const campaignSlug = useMemo(
    () => campaign?.slug ?? activity?.campaign?.slug,
    [activity?.campaign?.slug, campaign?.slug],
  );

  useEffect(() => {
    setLoading(true);

    activityRoutes
      .getVoterRegistrationContactLeaderboard({
        activitySlug: activity.slug,
        campaignSlug: campaignSlug,
        page: 1,
        perPage: 5,
      })
      .then(({ data }) => {
        setContactMetrics(data.data.filter(item => item.referrals_count !== 0));
      })
      .catch(error => {
        message.error(t('errors.default'));
        setContactMetrics([]);
      })
      .then(() => {
        setLoading(false);
      });
  }, [activity, campaign, campaignSlug, t, team]);

  const leaderboardLink = useMemo(
    () => `${window.location.origin}/${campaignSlug}/${activity.slug}/leaderboard?type=individual`,
    [activity.slug, campaignSlug],
  );

  const isEmpty = useMemo(
    () => !loading && contactMetrics?.length === 0,
    [contactMetrics, loading],
  );

  if (isEmpty) {
    return null;
  }

  return (
    <StyledVolunteersLeaderboard>
      <Container>
        <SectionTitle>{t('campaign.leaderboard.top_volunteers')}</SectionTitle>
        {loading && (
          <LoadingSpin>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </LoadingSpin>
        )}

        {isEmpty && <EmptyMessage>{t('campaign.leaderboard.empty_volunteers')}</EmptyMessage>}

        <VolunteersList>
          {!loading &&
            contactMetrics &&
            contactMetrics.map((contactMetric, index) => (
              <VolunteerLeaderboardCard contactMetric={contactMetric} key={index} />
            ))}
        </VolunteersList>
        {!isEmpty && (
          <ImpactiveLink
            style={{ marginTop: 20 }}
            href={leaderboardLink}
            as="a"
            target="_blank"
            rel="noreferrer"
          >
            {t('campaign.leaderboard.view_full_leaderboard')}
          </ImpactiveLink>
        )}
      </Container>
    </StyledVolunteersLeaderboard>
  );
};

VolunteersLeaderboard.propTypes = {
  activity: PropTypes.object,
  campaign: PropTypes.object,
  team: PropTypes.object,
};

export default VolunteersLeaderboard;
