import { string } from 'prop-types';
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthContext } from '../../utils/context';
import OnboardingModal from '../common/OnboardingModal';

const AuthenticatedRoute = props => {
  const { token } = useContext(AuthContext);

  const { path } = props;

  const isUnauthenticatedRoute = path.includes('auth');
  // don't show onboarding modal for admin campaign signup
  const isAdminCampaignSignup = path.includes('/new-campaign');

  if (!token && !isUnauthenticatedRoute) {
    return <Redirect to="/auth" />;
  }

  return (
    <>
      <Route {...props} />
      {!isAdminCampaignSignup && <OnboardingModal />}
    </>
  );
};

AuthenticatedRoute.propTypes = {
  path: string.isRequired,
};

export default AuthenticatedRoute;
