import * as React from 'react';

function usePageNavigation(numberOfPages, isHeadcountMtv) {
  const [currentPage, setCurrentPage] = React.useState(0);

  // Scroll to the top of the page each time currentPage changes.
  React.useEffect(() => {
    window?.scrollTo?.(0, 0);
    // eslint-disable-next-line
  }, [currentPage]);

  function nextPage() {
    const next = currentPage + 1;
    if (next > numberOfPages - 1) {
      return;
    }
    setCurrentPage(next);
  }

  function previousPage() {
    const next = currentPage - 1;
    if (next < 0) {
      return;
    }
    setCurrentPage(next);
  }

  // For Headcount MTV we have to add 1 more step to show that users have to share link to finish it completely
  const progress = React.useMemo(
    () =>
      isHeadcountMtv ? (currentPage + 1) / (numberOfPages + 1) : (currentPage + 1) / numberOfPages,
    [currentPage, isHeadcountMtv, numberOfPages],
  );

  return {
    currentPage,
    nextPage,
    numberOfPages,
    previousPage,
    progress,
    setCurrentPage,
  };
}

export default usePageNavigation;
