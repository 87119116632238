import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import ProgressBar from '@web/components/FanOutVotingPlan/components/ProgressBar';
import PageTitle from '@web/components/FanOutVotingPlan/components/PageTitle';
import {
  getInitialQuestionValues,
  getValidationQuestionsSchema,
} from '@web/components/FanOutVotingPlan/utils';
import QuestionFields from './QuestionFields';
import ButtonsSection from '@web/components/FanOutVotingPlan/components/ButtonsSection';
import { Wrapper, Content } from '../styles';

const HowWillYouVote = ({
  progress,
  first3Questions,
  currentPage: { nextButtonText, nextIcon, prevButtonText, prevIcon },
  onNextPage,
  onPreviousPage,
  setVPAnswers,
  setDisabledAnswers,
  disabledAnswers,
  VPAnswers,
}) => {
  const { t } = useTranslation();
  const initialValues = getInitialQuestionValues(first3Questions, VPAnswers);
  const validationSchema = getValidationQuestionsSchema(first3Questions);

  const handlePreviousPage = useCallback(() => {
    setDisabledAnswers({ 0: [], 1: [], 2: [] });
    setVPAnswers([]);
    onPreviousPage();
  }, [setDisabledAnswers, setVPAnswers, onPreviousPage]);

  const onSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      const partyAnswers = values.question_answers.map(({ activity_question_id, value }) => ({
        activity_question_id,
        party: null,
        value,
      }));
      setVPAnswers(partyAnswers);
      onNextPage();
      setSubmitting(false);
    },
    [onNextPage, setVPAnswers],
  );

  return (
    <Wrapper>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Wrapper>
              <Content>
                <ProgressBar progress={progress} />
                <PageTitle icon={'✍️'} title={t('fan_out.voting_plan.how_will_you_vote.how')} />
                <QuestionFields
                  questions={first3Questions}
                  handleChange={handleChange}
                  values={values}
                  setFieldValue={setFieldValue}
                  disabledAnswers={disabledAnswers}
                  setDisabledAnswers={setDisabledAnswers}
                />
              </Content>
            </Wrapper>
            <ButtonsSection
              showPreviousButton={false}
              nextButtonProps={{
                disabled: isSubmitting,
                htmlType: 'submit',
                icon: nextIcon,
                text: nextButtonText,
              }}
              prevButtonProps={{
                disabled: isSubmitting,
                icon: prevIcon,
                onClick: handlePreviousPage,
                text: prevButtonText,
              }}
            />
          </Form>
        )}
      />
    </Wrapper>
  );
};

HowWillYouVote.propTypes = {
  currentPage: PropTypes.shape({
    nextButtonText: PropTypes.string,
    nextIcon: PropTypes.node,
    prevButtonText: PropTypes.string,
    prevIcon: PropTypes.node,
  }).isRequired,
  disabledAnswers: PropTypes.object.isRequired,
  first3Questions: PropTypes.array.isRequired,
  formAttributes: PropTypes.object.isRequired,
  onNextPage: PropTypes.func.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  partyQuestions: PropTypes.array.isRequired,
  progress: PropTypes.number,
  referrer: PropTypes.shape({
    fullname: PropTypes.string,
    id: PropTypes.number,
  }),
  setDisabledAnswers: PropTypes.func.isRequired,
  setVPAnswers: PropTypes.func.isRequired,
  sortedActivityQuestions: PropTypes.array.isRequired,
  VPAnswers: PropTypes.array.isRequired,
};

HowWillYouVote.defaultProps = {
  progress: 0,
};

export default HowWillYouVote;
