import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ImpactiveButton from '@web/components/ImpactiveButton';
import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import useIsMobile from '@web/components/VoterRegistration/hooks/useIsMobile';
import FanOutLogoIcon from '@web/components/FanOut/components/icons/FanOutLogo';
import DonateButton from '@web/components/FanOut/components/DonateButton';
import Facebook from '@web/components/FanOut/components/icons/Facebook';
import Instagram from '@web/components/FanOut/components/icons/Instagram';
import Threads from '@web/components/FanOut/components/icons/Threads';
import theme from '@web/styles/theme';

const StyledFooter = styled.footer`
  bottom: 0;
  z-index: 99;
  /* position: sticky; */
  width: 100%;
  display: flex;
  background: ${({ theme }) => theme.colors.blue};
  padding: 24px 40px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: wrap;
  gap: 20px;

  ${smallBreakpoint} {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 20px;
  }
`;

const LogoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  min-width: 300px;
`;

const FanOutLogo = styled(FanOutLogoIcon)`
  width: 161px;
  height: 31px;
`;

const RightsText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.regular};
  text-align: justify;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;

const ContactContent = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 80px;
  flex-wrap: wrap;

  ${smallBreakpoint} {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const MediaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const MediaTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem; /* 133.333% */
`;

const StyledHref = styled.a`
  :link {
    text-decoration: none;
  }
`;

const StyledImpactiveButton = styled(ImpactiveButton)`
  border-radius: 4px;
  background: #303648;
  padding: 6px 13px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  text-transform: lowercase;

  ${smallBreakpoint} {
    width: calc(100vw - 40px);
  }
`;

const SocialContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const IconLink = styled.a`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray};

  ${smallBreakpoint} {
    justify-content: flex-start;
    align-items: flex-start;
    border: 0px;
  }

  :link {
    text-decoration: none;
  }

  :hover,
  :focus,
  .active {
    opacity: 0.5;
  }
`;

const Footer = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const currentYear = new Date().getFullYear();

  const SOCIALS = useMemo(
    () => [
      { icon: <Facebook isMobile={isMobile} />, link: 'https://www.facebook.com/fanout.vote' },
      { icon: <Instagram isMobile={isMobile} />, link: 'https://www.instagram.com/fanout.vote/' },
      { icon: <Threads isMobile={isMobile} />, link: 'https://www.threads.net/@fanout.vote' },
    ],
    [isMobile],
  );

  return (
    <StyledFooter>
      <LogoContent>
        <FanOutLogo fill={theme.colors.white} />
        <RightsText>{t('fan_out.footer.rights', { year: currentYear })}</RightsText>
      </LogoContent>
      <ContactContent $isMobile={isMobile}>
        <MediaContent $isButtonCover>
          <MediaTitle>{t('fan_out.footer.contact_us')}</MediaTitle>
          <StyledHref target="_blank" href="mailto:team@fanout.vote" type="primary">
            <StyledImpactiveButton>team@fanout.vote</StyledImpactiveButton>
          </StyledHref>
        </MediaContent>
        <MediaContent $isButtonCover>
          <MediaTitle>{t('fan_out.footer.support_us')}</MediaTitle>
          <DonateButton $isFooter />
        </MediaContent>
        <MediaContent>
          <MediaTitle>{t('fan_out.footer.social')}</MediaTitle>
          <SocialContent>
            {SOCIALS.map(({ icon, link }, index) => (
              <IconLink
                $isMobile={isMobile}
                key={index}
                target="_blank"
                href={link}
                rel="noopener noreferrer"
              >
                {icon}
              </IconLink>
            ))}
          </SocialContent>
        </MediaContent>
      </ContactContent>
    </StyledFooter>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
