import React from 'react';

import { Switch } from 'antd';

import styled from 'styled-components';
import PropTypes from 'prop-types';

const FieldWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > span {
    margin-left: 20px;
  }
`;

const ViewTags = ({ tags, setFieldValue }) => {
  return tags.map(({ name, value, slug, prompt }, index) => {
    return (
      <FieldWrapper key={slug}>
        <Switch
          slug={slug}
          checked={value}
          onChange={v => setFieldValue(`tags[${index}]value`, v)}
        />
        <span>{prompt || name}</span>
      </FieldWrapper>
    );
  });
};

ViewTags.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape()),
};

export default ViewTags;
