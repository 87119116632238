import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ImpactiveButton from '../ImpactiveButton';
import FormActivity from '../ActivityDetail/FormActivity';
import { Link, useHistory } from 'react-router-dom';
import ShareActivity from '../ActivityDetail/ShareActivity';
import WebActivity from '../ActivityDetail/WebActivity';

import { getButtonCopy, getStartActivityLink } from './utils';
import { getButtonText } from '../../utils/other';
import { track } from '../../services/analytics';
import { user } from '../../services/api';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(ImpactiveButton)`
  &&& {
    ${({ theme, isModal }) => css`
      font-family: ${theme.fonts.blackItalic};
      width: ${isModal ? '100%' : 'unset'};
    `}

    ${({ $completed, disabled }) =>
      $completed &&
      !disabled &&
      `
      background-color: #949494;
      border-color: #949494;
      &:hover {
        background-color: #a9a9a9;
        border-color: #a9a9a9;
      }
      &:active {
        background-color: #808080;
        border-color: #808080;
      }
    `}
  }
`;

const StyledLink = styled(Link)`
  &&& {
    text-decoration: none;
  }
`;

const ActivityButton = ({
  userActivity,
  activity: {
    id: activityId,
    type: activityType,
    aasm_state,
    button_text,
    title: activityTitle,
    share_text,
    share_link,
    go_to_link,
    campaign: { id: campaignId, name: campaignName = '', slug: campaignSlug } = {},
  } = {},
  setActivityCompletedModalVisible,
  completed,
  isModal,
  onActivityActionButtonPress,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const closed = aasm_state === 'closed';

  const { id: userActivityId } = userActivity || {};

  const startLink = getStartActivityLink({
    activityId,
    activityType,
    campaignId,
    campaignSlug,
    userActivityId,
  });
  const buttonCopy = getButtonCopy({ activityType, button_text, closed, completed, t });

  /**
   * Call to action buttons are rendered conditionally based on activityType
   *  - ShareActivity and WebActivity are custom components and are handled directly
   *  - Other userActivities are simple links to corresponding handler page
   *  - Campaign activities (no userActivityId) will act as 'join campaign' button
   **/
  function renderCTA() {
    // Campaign join button for campaign activity
    if (!userActivityId) {
      return (
        <StyledButton
          type="primary"
          disabled={closed}
          isModal
          onClick={onActivityActionButtonPress}
        >
          {t('campaign.activity.join_to_take_action')}
        </StyledButton>
      );
    }

    if (activityType === 'ShareActivity') {
      return (
        <div onClick={trackClick}>
          <ShareActivity
            campaignName={campaignName}
            url={share_link}
            message={share_text}
            onShareWindowClose={() => {
              // Prompts user to confirm completed action
              setActivityCompletedModalVisible(true);
            }}
          />
        </div>
      );
    }

    if (activityType === 'FormActivity') {
      return <FormActivity campaignName={campaignName} userActivity={userActivity} />;
    }

    if (activityType === 'SurveyActivity') {
      history.push(`/campaigns/${campaignSlug}/activities/${activityId}`);
    }

    if (activityType === 'WebActivity') {
      return (
        <WebActivity
          goToUrl={go_to_link}
          closed={closed}
          onWebActivityCompleted={() => {
            // Prompts user to confirm completed action
            if (!completed) setActivityCompletedModalVisible(true);
          }}
        >
          <StyledButton
            data-testid="web-activity-start"
            onClick={trackClick}
            type="primary"
            block
            disabled={closed}
            $completed={completed}
            isModal
          >
            {getButtonText(activityType, completed)}
          </StyledButton>
        </WebActivity>
      );
    }

    return (
      <Link
        data-testid="activity-start-button"
        disabled={closed}
        onClick={trackClick}
        style={{ color: 'white' }}
        to={startLink}
      >
        <StyledButton type="primary" isModal disabled={closed} $completed={completed}>
          {buttonCopy}
        </StyledButton>
      </Link>
    );
  }

  const trackClick = async () => {
    track('START_ACTIVITY', {
      activity_id: activityId,
      activity_title: activityTitle,
      activity_type: activityType,
      campaign_id: campaignId,
      campaign_name: campaignName,
    });

    // Only track user activity when one exists
    if (userActivityId) {
      user.userActivityTrackClick(userActivityId);
    }
  };

  /**
   * Call to action buttons are rendered conditionally based on activityType
   *  - ShareActivity and WebActivity are custom components and are handled directly
   *  - Other userActivities are simple links to corresponding handler page
   *  - Campaign activities (no userActivityId) will act as 'join campaign' button
   **/
  function renderPreviewCTA() {
    // Campaign join button for campaign activity
    if (!userActivityId) {
      return (
        <StyledButton
          isModal={isModal}
          type="primary"
          disabled={closed}
          onClick={e => {
            e.stopPropagation();
            onActivityActionButtonPress();
          }}
        >
          {t('campaign.activity.join_to_take_action')}
        </StyledButton>
      );
    }

    // For Web Share and Social Share activities, we should open modal instead of redirecting to the activity page
    // There is no onClick function, because when the user click on the activity, the modal automatically appears
    if (!startLink) {
      return (
        <StyledButton type="primary" disabled={closed}>
          {buttonCopy}
        </StyledButton>
      );
    }

    return (
      <StyledLink
        data-testid="activity-start-button"
        onClick={trackClick}
        to={startLink}
        disabled={closed}
      >
        <StyledButton type="primary" disabled={closed} $completed={completed}>
          {buttonCopy}
        </StyledButton>
      </StyledLink>
    );
  }

  return <>{isModal ? renderCTA() : renderPreviewCTA()}</>;
};

ActivityButton.propTypes = {
  activity: PropTypes.shape({}),
  completed: PropTypes.bool.isRequired,
  isModal: PropTypes.bool,
  onActivityActionButtonPress: PropTypes.func,
  setActivityCompletedModalVisible: PropTypes.func,
  userActivity: PropTypes.shape({ id: PropTypes.number }),
};

export default ActivityButton;
