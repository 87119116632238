import { Button, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'web/utils/context';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { track } from '../../services/analytics';
import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import SyncContactsModal from '../ContactSync/components/SyncContactsModal';
import MaxWidthContainer from '../common/MaxWidthContainer';
import Text from '../common/Text';
import Unlock from './Unlock';
import UserPreferences from './UserPreferences';
import useHasContacts from './useHasContacts';

const Settings = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('titles.settings'));
  const { hasContacts, loading } = useHasContacts();
  const [syncContactsModalVisible, setSyncContactsModalVisible] = useState(false);
  const { isLockedToCampaign } = useContext(AuthContext);

  // Track the page view
  useEffect(() => {
    track('VIEW_SETTINGS_SCREEN');
  }, []);

  return (
    <>
      <AppHeader title={t('titles.settings')} />
      <AppContainer>
        <MaxWidthContainer>
          <UserPreferences />

          <hr style={{ borderColor: '#b9b9b9' }} />

          {isLockedToCampaign && (
            <>
              <Unlock />

              <hr style={{ borderColor: '#b9b9b9' }} />
            </>
          )}

          <h3>{t('contact.sync.header')}</h3>
          <Text>{t('contact.sync.what')}</Text>
          <Text>{t('contact.sync.privacy')}</Text>
          {loading && <Spin />}
          {!loading && (
            <Button type="primary" onClick={() => setSyncContactsModalVisible(true)}>
              {hasContacts ? t('contact.sync.resync_contacts') : t('contact.sync.sync_contacts')}
            </Button>
          )}
        </MaxWidthContainer>

        <SyncContactsModal
          syncContactsModalVisible={syncContactsModalVisible}
          setSyncContactsModalVisible={setSyncContactsModalVisible}
        />
      </AppContainer>
    </>
  );
};

Settings.propTypes = {};

export default Settings;
