import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { actionTypes } from '../../data/utils/types';
import { media } from '../../styles/theme';
import { StateContext } from '../../utils/context';
import ZipProvider from './ZipProvider';
import SelectComponent from '@web/components/common/shared/Select';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0;
  ${media.nlg} {
    flex-direction: column;
  }
`;

const StyledZipProvider = styled(ZipProvider)`
  padding-left: 0;
`;

const FiltersContainer = styled.div`
  display: flex;
  > div {
    &:last-of-type {
      margin-left: 13px;
    }
  }
`;

const ActivityFilters = ({ onChange }) => {
  const { t } = useTranslation();
  const { causes } = useContext(StateContext);
  const [searchWithinZip, setSearchWithinZip] = useState();
  const [issuesFilter, setIssuesFilter] = useState();
  const [actionsFilter, setActionsFilter] = useState();

  useEffect(() => {
    // NOTE: Terminology changed with the new design, filters are mapped to their backend model names here
    onChange({
      activity_type: actionsFilter,
      cause: issuesFilter,
      zip: searchWithinZip,
    });
  }, [onChange, searchWithinZip, issuesFilter, actionsFilter]);

  return (
    <Wrapper>
      <StyledZipProvider zip={searchWithinZip} setZip={setSearchWithinZip} />
      <FiltersContainer>
        <SelectComponent
          placeholder={t('frontline.filters.filter_by_issue')}
          value={issuesFilter}
          onChange={setIssuesFilter}
          options={(() => causes.map(({ id, name }) => ({ label: name, value: id })))()}
        />
        <SelectComponent
          data-testid="action_type_dropdown"
          placeholder={t('frontline.filters.filter_by_action_type')}
          value={actionsFilter}
          onChange={setActionsFilter}
          options={(() => actionTypes.map(({ value, label }) => ({ label, value })))()}
        />
      </FiltersContainer>
    </Wrapper>
  );
};

ActivityFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ActivityFilters;
