import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border-left: 3px solid rgba(246, 186, 31, 0.3);
  background: rgba(246, 186, 31, 0.1);
`;

const Icon = styled.div`
  font-size: 0.9rem;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BaseText = styled.div`
  color: #67686e;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: normal;
`;

const Title = styled(BaseText)`
  color: #a17911;
  font-weight: 600;
`;

const Description = styled(BaseText)`
  span {
    font-weight: 600;
  }
`;

const getWarningMessage = (questionPosition, answerPosition, values, t) => {
  if (questionPosition === 0 && answerPosition === 0) {
    const proposedAnswer = values.question_answers[0].possible_answers[1].value;

    return {
      description: t('fan_out.voting_plan.how_will_you_vote.warnings.0.description', {
        proposedAnswer,
      }),
      states: t('fan_out.voting_plan.how_will_you_vote.warnings.0.states'),
      title: t('fan_out.voting_plan.how_will_you_vote.warnings.0.title'),
    };
  }
  if (questionPosition === 0 && answerPosition === 1) {
    const proposedAnswer = values.question_answers[0].possible_answers[0].value;

    return {
      description: t('fan_out.voting_plan.how_will_you_vote.warnings.1.description', {
        proposedAnswer,
      }),
      states: t('fan_out.voting_plan.how_will_you_vote.warnings.1.states'),
      title: t('fan_out.voting_plan.how_will_you_vote.warnings.1.title'),
    };
  }
  if (questionPosition === 1 && answerPosition === 0) {
    const proposedAnswer = values.question_answers[1].possible_answers[1].value;

    return {
      description: t('fan_out.voting_plan.how_will_you_vote.warnings.2.description', {
        proposedAnswer,
      }),
      states: t('fan_out.voting_plan.how_will_you_vote.warnings.2.states'),
      title: t('fan_out.voting_plan.how_will_you_vote.warnings.2.title'),
    };
  }

  return null;
};

const WarningMessage = ({ questionPosition, answerPosition, picked, values }) => {
  const { t } = useTranslation();

  const warningMessage = useMemo(
    () => getWarningMessage(questionPosition, answerPosition, values, t),
    [questionPosition, answerPosition, values, t],
  );

  if (!picked || !warningMessage) return null;

  return (
    <Wrapper>
      <Icon>{'⚠️'}</Icon>
      <TextWrapper>
        <Title>{warningMessage.title}</Title>
        <Description>
          {warningMessage.description}
          <span>{warningMessage.states}</span>
        </Description>
      </TextWrapper>
    </Wrapper>
  );
};

WarningMessage.propTypes = {
  answerPosition: PropTypes.number.isRequired,
  picked: PropTypes.bool.isRequired,
  questionPosition: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
};

export default WarningMessage;
