import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@web/components/DialerActivity/components/Button';

const StyledButton = styled(Button)`
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
`;

const StyledInfoBox = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.whiteSmokeLighter};
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  max-width: 612px;
  padding: 56px 30px;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-top: 2px;
  margin-bottom: 40px;
  text-align: center;
`;

const Title = styled.h2`
  &&& {
    color: ${({ theme }) => theme.colors.darkBlue};
    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: 24px;
    line-height: 34px;
    text-align: center;
  }
`;

function InfoBox({ buttonProps, icon, text, title }) {
  return (
    <StyledInfoBox>
      {icon}
      <Title>{title}</Title>
      <Text>{text}</Text>
      <StyledButton {...buttonProps} />
    </StyledInfoBox>
  );
}

InfoBox.propTypes = {
  buttonProps: PropTypes.object,
  icon: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.any,
};

InfoBox.defaultProps = {
  buttonProps: {},
  icon: null,
  text: '',
  title: '',
};

export default InfoBox;
