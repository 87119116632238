import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { useSelector } from 'react-redux';

const StyledElapsed = styled.span`
  color: #929cbb;
`;

function Elapsed() {
  const { sessionStartTime } = useSelector(state => state.dialer);
  const [elapsed, setElapsed] = React.useState(null);
  const startTime = sessionStartTime || dayjs();

  React.useEffect(() => {
    const interval = setInterval(() => {
      const diffMs = dayjs().diff(startTime);
      setElapsed(hms(diffMs / 1000));
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [sessionStartTime]);

  function hms(secs) {
    var hours = Math.floor(secs / (60 * 60));

    var divisorForMinutes = secs % (60 * 60);
    var minutes = Math.floor(divisorForMinutes / 60);

    var divisorForSeconds = divisorForMinutes % 60;
    var seconds = Math.ceil(divisorForSeconds);

    var obj = {
      h: hours,
      m: `0${minutes.toString()}`.slice(-2),
      s: `0${seconds.toString()}`.slice(-2),
    };
    return obj;
  }

  return <StyledElapsed>{elapsed && ` ${elapsed?.h}:${elapsed?.m}:${elapsed?.s}`}</StyledElapsed>;
}

Elapsed.propTypes = {};

Elapsed.defaultProps = {};

export default Elapsed;
