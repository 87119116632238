import { message, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Formik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { UserReportSchema } from '../../../admin/data/schema/shared';
import { track } from '../../services/analytics';
import { user, userReportApi } from '../../services/api';

const FormActivityContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

const FormActivity = ({ campaignName, userActivity }) => {
  const { t } = useTranslation();

  const [redirectHome, setRedirectHome] = useState(null);
  const [userReport, setUserReport] = useState({});

  const { activity } = userActivity;
  const {
    starts_at,
    ends_at,
    address,
    state_abbrev,
    city,
    additional_details,
    activity_tags,
    activity_custom_fields,
  } = activity;

  const taggings = activity_tags.map(activity_tag => {
    return {
      tag: activity_tag.tag,
      value: false,
    };
  });

  const customizations = activity_custom_fields
    .filter(
      activity_custom_field => activity_custom_field.custom_field.is_user_reportable !== false,
    )
    .map(activity_custom_field => {
      return {
        custom_field: activity_custom_field.custom_field,
        value: '',
      };
    });

  const combinedSort = (taggings_attributes = [], customizations_attributes = []) => {
    let combinedArray = taggings_attributes.concat(customizations_attributes);
    combinedArray = _.sortBy(combinedArray, obj => {
      if (obj.tag) {
        return obj.tag.display_order ? obj.tag.display_order : 9999;
      }
      if (obj.custom_field) {
        return obj.custom_field.display_order ? obj.custom_field.display_order : 9999;
      }
      return 9999;
    });
    return combinedArray;
  };

  useEffect(() => {
    setUserReport({
      activity_id: userActivity.activity.id,
      campaign_id: userActivity.activity.campaign.id,
      customizations_attributes: customizations,
      reportable_id: userActivity.user.id,
      reportable_type: 'User',
      taggings_attributes: taggings,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitReport = async formData => {
    if (!formData) {
      return;
    }

    let userReportBody = {
      activity_id: userActivity.activity.id,
      campaign_id: userActivity.activity.campaign.id,
      customizations_attributes: userReport.customizations_attributes.map(c => {
        return {
          custom_field_id: c.custom_field.id,
          value: c.value,
        };
      }),
      reportable_id: userActivity.user.id,
      reportable_type: 'User',
      taggings_attributes: userReport.taggings_attributes.map(ta => {
        return {
          tag_id: ta.tag.id,
          value: ta.value,
        };
      }),
    };

    const userReportRes = await userReportApi
      .updateOrCreateReport(
        {
          activity_id: userActivity.activity.id,
          campaign_id: userActivity.activity.campaign.id,
        },
        userReportBody,
      )
      .catch(err => {
        onSaveError();
        return;
      });

    if (!userReportRes || userReportRes.status !== 200) {
      onSaveError();
      return;
    }

    track('COMPLETED_ACTIVITY', {
      activity_id: userActivity.activity.id,
      activity_title: userActivity.activity.title,
      campaign_id: userActivity.activity.campaign.id,
      campaign_name: userActivity.activity.campaign.name,
    });
    await user.updateUserActivity(userActivity.id, { completed: true });
    await user.userActivityAddPerform(userActivity.id);
    message.success(t('idvoters.voter.outreach_report_sent'));
    setRedirectHome(true);
  };

  const onSwitchToggle = (selectedSlug, selectionChange) => {
    const updatedTaggings = userReport.taggings_attributes.map(tagging => {
      if (tagging.tag.slug !== selectedSlug) {
        return tagging;
      }
      return {
        ...tagging,
        value: selectionChange,
      };
    });

    setUserReport({
      ...userReport,
      taggings_attributes: updatedTaggings,
    });
  };

  const onCustomFieldUpdate = (selectedSlug, val) => {
    const updatedFields = userReport.customizations_attributes.map(customization => {
      if (customization.custom_field.slug !== selectedSlug) {
        return customization;
      }
      return {
        ...customization,
        value: val,
      };
    });

    setUserReport({
      ...userReport,
      customizations_attributes: updatedFields,
    });
  };

  const onSaveError = () => {
    message.error(t('errors.submit_outreach_report'));
  };

  if (redirectHome) {
    return <Redirect push to={{ pathname: '/frontline' }} />;
  }

  return (
    <FormActivityContainer>
      <Fragment>
        {!!starts_at && (
          <div style={{ marginTop: 10 }}>
            <div style={{ alignItems: 'center', flexDirection: 'row' }}>
              <p>
                {t('activity.startAt')}: {moment(starts_at).format('MMMM Do, YYYY [at] HH:mm A')}
              </p>
            </div>
          </div>
        )}
        {!!ends_at && (
          <div style={{ marginTop: 10 }}>
            <div style={{ alignItems: 'center', flexDirection: 'row' }}>
              <p>
                {t('activity.endAt')}: {moment(ends_at).format('MMMM Do, YYYY [at] HH:mm A')}
              </p>
            </div>
          </div>
        )}
        {!!address && (
          <div style={{ marginBottom: 10, marginTop: 20 }}>
            <div style={{ alignItems: 'center', flexDirection: 'row' }}>
              <p>{t('activity.location')}</p>
              {!!address && <p>{address}</p>}
              {!!city && <p>{`${city}, ${state_abbrev}`}</p>}
            </div>
          </div>
        )}

        {!!additional_details && (
          <div style={{ marginBottom: 10, marginTop: 20 }}>
            <p>{t('activity.additional_information')}</p>
            <p style={{ marginTop: 20 }}>{additional_details}</p>
          </div>
        )}

        <Formik initialValues={{}} validationSchema={UserReportSchema} onSubmit={submitReport}>
          <Form>
            <div>
              {(userReport.taggings_attributes || userReport.customizations_attributes) &&
                combinedSort(
                  userReport.taggings_attributes,
                  userReport.customizations_attributes,
                ).map(obj => {
                  if (obj.tag) {
                    let tagging = obj;
                    return (
                      <div className="py-3" key={tagging.tag.slug}>
                        <span className="pr-3">{tagging.tag.prompt || tagging.tag.name}</span>
                        <Switch
                          slug={tagging.tag.slug}
                          checked={tagging.value}
                          onChange={(to, e) => onSwitchToggle(tagging.tag.slug, to, e)}
                        />
                      </div>
                    );
                  } else if (obj.custom_field) {
                    let customization = obj;
                    return (
                      <div className="py-2" key={customization.custom_field.slug}>
                        <div className="pb-2">
                          {customization.custom_field.prompt || customization.custom_field.name}
                        </div>

                        {customization.custom_field.type === 'TextField' && (
                          <TextArea
                            name={customization.custom_field.slug}
                            value={customization.value}
                            autoSize={{ minRows: 4 }}
                            onChange={e => {
                              onCustomFieldUpdate(customization.custom_field.slug, e.target.value);
                            }}
                          />
                        )}

                        {customization.custom_field.type === 'SelectionField' && (
                          <div className="flex-row flex-wrap">
                            {customization.custom_field.available_values.map(val => (
                              <div key={val} className="m-2">
                                <div
                                  className={`selection-box ${
                                    customization.value === val ? 'selected' : ''
                                  }`}
                                  onClick={e => {
                                    onCustomFieldUpdate(customization.custom_field.slug, val);
                                  }}
                                >
                                  {val}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>

            <SubmitButton className="w-100">{t('common.send_to_campaign')}</SubmitButton>
          </Form>
        </Formik>
      </Fragment>
    </FormActivityContainer>
  );
};

FormActivity.propTypes = {
  campaignName: PropTypes.string.isRequired,
  userActivity: PropTypes.object.isRequired,
};

export default FormActivity;
