import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Arrow from '../icons/Arrow';

const DURATION = '150ms';

const StyledHoverCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  box-shadow: 0px 4px 20px 0px #00000012;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 124px;
  padding: 20px;
  position: relative;
  transition: border ${DURATION} ease-out;
  :hover {
    border: 1px solid ${({ theme }) => theme.colors.blue};
  }
`;

const ArrowArea = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  top: 20px;
  right: 22px;
`;

function HoverCard({ arrowChildren, isFanOut, children, ...rest }) {
  return (
    <StyledHoverCard role="button" {...rest}>
      <ArrowArea>
        {arrowChildren}
        {!isFanOut && <Arrow />}
      </ArrowArea>
      {children}
    </StyledHoverCard>
  );
}

HoverCard.propTypes = {
  arrowChildren: PropTypes.any,
  children: PropTypes.any,
  isFanOut: PropTypes.bool,
};

HoverCard.defaultProps = {
  arrowChildren: null,
  children: null,
};

export default HoverCard;
