import { bool } from 'prop-types';
import React from 'react';

const Facebook = ({ isMobile, ...props }) => {
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path fill="#3D5A98" d="M0 0h40v40H0z" />
        <path
          fill="#fff"
          d="M27.025 39V24.17h4.806l.72-5.581h-5.526v-3.563c0-1.616.45-2.719 2.766-2.719h2.956v-5c-1.431-.149-2.87-.22-4.31-.212-4.256 0-7.187 2.594-7.187 7.378v4.116h-4.806v5.58h4.806v14.833h5.775Z"
        />
      </g>
      <rect width={39} height={39} x={0.5} y={0.5} stroke="#fff" strokeOpacity={0.2} rx={5.5} />
      <defs>
        <clipPath id="a">
          <rect width={40} height={40} fill="#fff" rx={6} />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#fff"
        d="M20.9 2H3.1A1.1 1.1 0 0 0 2 3.1v17.8A1.1 1.1 0 0 0 3.1 22h9.58v-7.75h-2.6v-3h2.6V9a3.64 3.64 0 0 1 3.88-4 20.26 20.26 0 0 1 2.33.12v2.7H17.3c-1.26 0-1.5.6-1.5 1.47v1.93h3l-.39 3H15.8V22h5.1a1.102 1.102 0 0 0 1.1-1.1V3.1A1.101 1.101 0 0 0 20.9 2Z"
      />
    </svg>
  );
};

Facebook.propTypes = {
  isMobile: bool,
};

Facebook.defaultProps = {};

export default Facebook;
