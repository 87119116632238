import * as React from 'react';
import PropTypes from 'prop-types';

import Wordmark from './Wordmark';
import IconDark from './IconDark';
// MTS - Most instances of this component will require a "height" prop
// Add additional logos here as they are used in the app. You can find them
// in this Slack post:
//https://impactiveinc.slack.com/archives/G014GQF3P1B/p1646238867660549?thread_ts=1646168308.890539&cid=G014GQF3P1B

// If you do add a logo, please optimize it first using this tool:
// https://jakearchibald.github.io/svgomg/

function Logo({ variant, ...rest }) {
  switch (variant) {
    case 'wordmark':
      return <Wordmark {...rest} />;
    case 'IconDark':
      return <IconDark {...rest} />;
    default:
      return <Wordmark {...rest} />;
  }
}

Logo.propTypes = {
  variant: PropTypes.oneOf(['wordmark']),
};

Logo.defaultProps = {
  variant: 'wordmark',
};

export default Logo;
