import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const QuestionTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
`;

const Question = ({ question }) => {
  return (
    <>
      <QuestionTitle>{question.title}</QuestionTitle>
      <ul>
        {question.answers.map(answer => (
          <li style={{ margin: '7px 0' }} dangerouslySetInnerHTML={{ __html: answer }} />
        ))}
      </ul>
    </>
  );
};

Question.propTypes = {
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
  }),
};

export default Question;
