import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Clipboard from 'react-clipboard.js';
import IconButton from './IconButton';
import { message } from 'antd';
import SocialShare from './SocialShare';

import { track } from '@web/services/analytics';
import { useTranslation } from 'react-i18next';
import { vrDefaultColors } from '@web/styles/theme';

const LinkInput = styled.input`
  align-items: center;
  background-color: ${({ theme, $bgColor }) => $bgColor ?? theme.colors.white};
  border: 1px solid ${({ $outlineColor }) => $outlineColor};
  border-radius: 3px;
  color: ${({ theme, $fontColor }) => $fontColor ?? theme.colors.black};
  display: flex;
  flex: 1;
  height: 40px;
  outline: 0;
  padding: 0 6px;
`;

const StyledShareLink = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 12px;
`;

function ShareLink({ activity, referralLink, team }) {
  const outlineColor = React.useMemo(
    () => activity?.settings?.field_outline_color ?? vrDefaultColors.fieldOutlineColor,
    [activity?.settings?.field_outline_color],
  );
  const bgColor = React.useMemo(
    () => activity?.settings?.background_color ?? vrDefaultColors.backgroundColor,
    [activity?.settings?.background_color],
  );
  const buttonColor = React.useMemo(
    () => activity?.settings?.button_color ?? vrDefaultColors.buttonColor,
    [activity?.settings?.button_color],
  );
  const fontColor = React.useMemo(
    () => activity?.settings?.font_color ?? vrDefaultColors.fontColor,
    [activity?.settings?.font_color],
  );
  const { t } = useTranslation();
  return (
    <StyledShareLink>
      <LinkInput
        $fontColor={fontColor}
        $bgColor={bgColor}
        $outlineColor={outlineColor}
        readOnly
        value={referralLink}
      />
      <SocialShare
        bgColor={bgColor}
        buttonColor={buttonColor}
        fontColor={fontColor}
        referralLink={referralLink}
      />
      <Clipboard
        component="span"
        data-clipboard-text={referralLink}
        onSuccess={() => {
          message.info(t('common.copied_to_clipboard'));
          track('VR_COPY_SHARE_LINK', {
            activity_id: activity?.id,
            activity_title: activity?.title,
            activity_type: activity?.type,
            campaign_id: activity?.campaign?.id,
            campaign_name: activity?.campaign?.name,
            team_id: team?.id,
            team_name: team?.name,
          });
        }}
      >
        <IconButton additionalStyles={{ backgroundColor: buttonColor, borderColor: buttonColor }}>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 3.625V0.25H9.5625C8.61328 0.25 7.875 1.02344 7.875 1.9375V12.0625C7.875 13.0117 8.61328 13.75 9.5625 13.75H16.3125C17.2266 13.75 18 13.0117 18 12.0625V4.75H14.625C13.9922 4.75 13.5 4.25781 13.5 3.625ZM14.625 0.25V3.625H18L14.625 0.25ZM6.75 12.625V4.75H1.6875C0.738281 4.75 0 5.52344 0 6.4375V16.5625C0 17.5117 0.738281 18.25 1.6875 18.25H8.4375C9.35156 18.25 10.125 17.5117 10.125 16.5625V14.875H9C7.73438 14.875 6.75 13.8906 6.75 12.625Z"
              fill={bgColor}
            />
          </svg>
        </IconButton>
      </Clipboard>
    </StyledShareLink>
  );
}

ShareLink.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    settings: PropTypes.shape({
      background_color: PropTypes.string,
      button_color: PropTypes.string,
      field_outline_color: PropTypes.string,
      font_color: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  referralLink: PropTypes.string.isRequired,
  team: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

ShareLink.defaultProps = {};

export default ShareLink;
