import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { media } from '@web/styles/theme';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

// This is a pretty gross fix for a super weird issue I wasn't
// able to figure out. This should be
// import 'swiper/css/pagination';
// but for some reason, importing any CSS from the swiper package
// other than "navigation" results in the following error:
// TypeError: Cannot read properties of undefined (reading 'call')
// It seems to be related to Webpack but I really don't know what's
// happening since all exports in the Swiper package look fine.
//
// For now, I've imported the CSS for the pagination in the file below.
import './assets/pagination';

import IncentiveCard from './components/IncentiveCard';
import { useTranslation } from 'react-i18next';

const StyledIncentivesSection = styled.div`
  padding: 50px 0 90px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  .swiper-slide {
    transition: all 0.3s ease;
    align-self: center;
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    opacity: 0.3;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;

    ${media.lg} {
      display: flex;
    }
  }

  ${media.xl} {
    padding: ${({ pageType }) => (pageType === 'team' ? 0 : '180px 0')};
  }
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;

  ${media.lg} {
  }

  ${media.xl} {
    width: 80%;
  }

  ${media.xxxl} {
    width: 60%;
  }
`;

const SectionTitle = styled.h3`
  font-size: 3em;
  color: ${({ theme }) => theme.colors.blackL25};
  font-weight: 600;
  margin-bottom: 60px;
  padding: 0 25px;

  ${media.xl} {
    margin-bottom: 80px;
  }
`;

const IncentivesSection = ({ incentives, pageType, campaign }) => {
  const { t } = useTranslation();

  if (!incentives || (incentives && incentives.length < 1)) {
    return null;
  }

  return (
    <StyledIncentivesSection pageType={pageType}>
      <Container>
        <SectionTitle>
          {[784, 3876].includes(campaign?.id)
            ? t('campaign.activity.prizes')
            : t('campaign.activity.incentives')}
        </SectionTitle>
        <Swiper
          slidesPerView={1}
          centeredSlides
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination]}
          navigation
          breakpoints={{
            600: {
              slidesPerView: 1.4,
              spaceBetween: 20,
            },
          }}
        >
          {incentives.map(incentive => (
            <SwiperSlide key={incentive.id}>
              <IncentiveCard incentive={incentive} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </StyledIncentivesSection>
  );
};

IncentivesSection.propTypes = {
  campaign: PropTypes.object,
  incentives: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageType: PropTypes.string,
};

export default IncentivesSection;
