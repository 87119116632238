import { bool, func, node, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useWindowVisibility from '../../hooks/useWindowVisibility';
import { colors } from '../../styles/theme';

const OutvoteExternalLink = styled.a`
  background: ${props =>
    props.closed ? 'linear-gradient(#ddd, #ccc)' : 'linear-gradient(#5292F2, #4187F0)'};
  border: none;
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px ${colors.boxShadow};
  cursor: pointer;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  &:hover,
  &:active {
    opacity: 0.5;
  }
`;

const WebActivity = ({ closed, children, goToUrl, onWebActivityCompleted }) => {
  const windowVisible = useWindowVisibility();
  const [navigatedAway, setNavigatedAway] = useState(false);

  // if user navigated away && returned back to the page -> trigger onWebActivityCompleted callback
  useEffect(() => {
    if (navigatedAway && windowVisible) {
      onWebActivityCompleted();
      setNavigatedAway(false);
    }
  }, [navigatedAway, windowVisible, onWebActivityCompleted]);

  return (
    <OutvoteExternalLink
      closed={closed}
      href={goToUrl}
      data-testid="web-activity-link"
      onClick={() => setNavigatedAway(true)}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </OutvoteExternalLink>
  );
};

WebActivity.propTypes = {
  children: node,
  closed: bool.isRequired,
  goToUrl: string.isRequired,
  onWebActivityCompleted: func.isRequired,
};

WebActivity.defaultProps = {
  children: null,
};

export default WebActivity;
