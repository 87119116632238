import { Table } from 'antd';
import { Radio } from 'formik-antd';
import styled from 'styled-components';
import ImpactiveButton from '../ImpactiveButton';

export const SplashWrapper = styled.div`
  max-width: 400px;
  margin: 40px auto;
  font-size: 16px;
  text-align: center;
`;

export const SplashTitle = styled.h3`
  color: #000;
  font-size: 2em;
  font-family: ${({ theme }) => theme.fonts.semibold};
  margin: 0 0 12px;
`;

export const SplashDescription = styled.div`
  margin-bottom: 2em;
  line-height: 1.3;
  opacity: 0.85;
`;

export const BigRoundButton = styled(ImpactiveButton)`
  font-size: 19px;
  border-radius: 10px;
  width: 100%;
  margin: 10px 0;
`;

export const CannotVoteLink = styled.a`
  margin-top: 14px;
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.boldItalic};
`;

export const FormContainer = styled.div`
  margin-top: 2rem;

  @media screen and (min-width: 768px) {
    max-width: 590px;
  }
`;

export const StyledRadio = styled(Radio.Button)`
  margin-right: 5px !important;
  margin-top: 10px !important;
  border-radius: 0 !important;
`;

export const SearchResults = styled.div`
  margin-top: 32px;
`;

export const SearchResultsTitle = styled.h3`
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  line-height: 26px;
  margin: 0;
`;

export const SearchResultsDescription = styled.div`
  margin-top: 6px;
`;

export const VoterTable = styled(Table)`
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 3px;
  margin: 20px 0;
  max-width: 620px;

  .ant-table {
    color: #000;
  }

  .ant-table-content {
    overflow-x: auto !important;
  }

  .ant-table tbody > tr {
    cursor: pointer;

    > td {
      height: 47px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

export const VoterName = styled.div`
  color: #454545;
  font-family: ${({ theme }) => theme.fonts.bold};
  text-transform: capitalize;
`;

export const VoterAddress = styled.div`
  text-transform: capitalize;
`;

export const VoterAge = styled.div``;

export const VoterNameCell = styled.div`
  ${VoterAddress}, ${VoterAge} {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
`;

export const ButtonsRow = styled.div`
  margin-bottom: -10px;

  > * {
    margin-bottom: 20px;

    &:not(:last-child) {
      margin-right: 20px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
`;

export const TermsAndConditions = styled.div`
  margin-top: 40px;
  font-size: 13px;
`;
