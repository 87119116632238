import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Team from 'web/components/CampaignLeaderboard/Team';
import Title from 'web/components/common/Title';
import { media } from 'web/styles/theme';

const Wrapper = styled.div`
  ${media.nxl} {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderGray}`};
  }
`;

const StyledTitle = styled(Title)`
  margin-top: 24px;
`;

const Teams = styled.div`
  margin-top: 12px;
`;

const StyledTeam = styled(Team)`
  margin-top: 24px;
  max-width: 350px;
`;

const StyledLink = styled(Link)`
  display: block;
  font-family: ${({ theme }) => theme.fonts.boldItalic};
  color: ${({ theme }) => theme.colors.link};
  margin: 16px 0;
`;

const TopTeams = ({ campaign, teams }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StyledTitle>
        {t('teams.top_teams')} <small>{t('teams.last_30_days')}</small>
      </StyledTitle>
      <Teams>
        {teams.map((team, index) => (
          <StyledTeam
            place={index + 1}
            key={team.id}
            campaignId={campaign.id}
            team={team}
            vertical
          />
        ))}
      </Teams>
      <StyledLink to={`/leaderboard?campaignId=${campaign.id}`}>
        {t('teams.see_leaderboard')}
      </StyledLink>
    </Wrapper>
  );
};

TopTeams.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
  }).isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TopTeams;
