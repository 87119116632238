import * as React from 'react';
import PropTypes from 'prop-types';

function Clock(props) {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 0.3125C4.64844 0.3125 0.3125 4.64844 0.3125 10C0.3125 15.3516 4.64844 19.6875 10 19.6875C15.3516 19.6875 19.6875 15.3516 19.6875 10C19.6875 4.64844 15.3516 0.3125 10 0.3125ZM12.2305 13.9883L8.78516 11.4844C8.66406 11.3945 8.59375 11.2539 8.59375 11.1055V4.53125C8.59375 4.27344 8.80469 4.0625 9.0625 4.0625H10.9375C11.1953 4.0625 11.4062 4.27344 11.4062 4.53125V9.91016L13.8867 11.7148C14.0977 11.8672 14.1406 12.1602 13.9883 12.3711L12.8867 13.8867C12.7344 14.0938 12.4414 14.1406 12.2305 13.9883Z"
        fill="black"
      />
    </svg>
  );
}

Clock.propTypes = {
  height: PropTypes.number,
};

Clock.defaultProps = {
  height: 20,
};

export default Clock;
