import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Person from '@web/components/VoterRegistration/newComponents/icons/Person';

import { useTranslation } from 'react-i18next';
import theme from '@web/styles/theme';

const StyledContact = styled.div``;

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  // MTS - We need to manually set the width here to accurately align the age row.
  min-width: 25px;
  width: 25px;
  path {
    fill: ${({ $color }) => $color};
  }
`;

const Row = styled.div`
  display: flex;
`;

const Age = styled.span`
  color: ${({ theme, $color }) => ($color ? `${$color}BF` : theme.colors.blackL32)};
  font-size: 16px;
  margin-left: 25px;
`;

const Name = styled.span`
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  color: ${({ $color }) => $color ?? theme.colors.black};
`;

function Contact({ age, name, fontColor }) {
  const { t } = useTranslation();
  return (
    <StyledContact>
      <Row>
        <IconWrapper $color={fontColor}>
          <Person />
        </IconWrapper>
        <Name $color={fontColor}>{name}</Name>
      </Row>
      <Row>
        <Age $color={fontColor}>
          {age} {t('check_registration.search_results.years_old')}
        </Age>
      </Row>
    </StyledContact>
  );
}

Contact.propTypes = {
  age: PropTypes.any.isRequired,
  fontColor: PropTypes.string,
  name: PropTypes.any.isRequired,
};

Contact.defaultProps = {};

export default Contact;
