import { Radio } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const RadioGroup = styled(Radio.Group)`
  white-space: nowrap;
`;

const values = {
  NO: 'no',
  UNKNOWN: 'unknown',
  YES: 'yes',
};

const ThreeStateSwitch = ({ value, onChange, ...rest }) => {
  const { t } = useTranslation();
  const computedValue = value !== values.NO && value !== values.YES ? values.UNKNOWN : value;
  const handleChange = React.useCallback(e => onChange(e.target.value), [onChange]);
  return (
    <RadioGroup {...rest} value={computedValue} onChange={handleChange}>
      <Radio.Button value={values.NO}>{t('common.no')}</Radio.Button>
      <Radio.Button value={values.YES}>{t('common.yes')}</Radio.Button>
      <Radio.Button value={values.UNKNOWN}>{t('common.n_a')}</Radio.Button>
    </RadioGroup>
  );
};

ThreeStateSwitch.propTypes = Radio.Group.propTypes;
ThreeStateSwitch.valueTypes = values;

export default ThreeStateSwitch;
