import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@web/styles/theme';

import logo from '@assets/images/logo/Impactive_Secondary.png';
import GeneralCardWrapper from '@web/components/common/Cards/GeneralCardWrapper';
import VerticalProgressPoints from '@web/components/common/Progress/VerticalProgressPoints';

const SignUpText = styled.div`
  color: ${theme.colors.white};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 17px;
`;

//not using spring here, because sidebar is not closable
const StyledSidebar = styled.div`
  background: #21283e;
  box-shadow: rgb(0 0 0 / 15%) 2px 2px 4px;
  max-height: 100vh;
  height: 100vh;
  width: 100%;
  max-width: 240px;

  display: flex;
  gap: 55px;
  flex-direction: column;
  align-items: center;

  padding: 40px 20px;

  // hide on mobile
  @media (max-width: 768px) {
    display: none;
  }
`;

const LeftAligned = styled.div`
  align-self: flex-start;
`;

const Sidebar = ({ activeIndex, showDemoButton }) => {
  const items = [
    { icon: '🏢', text: 'Organization Info' },
    { icon: '👤', text: 'Administrator Info' },
    { icon: '💳', text: 'Choose Your Plan' },
  ];

  return (
    <StyledSidebar>
      <LeftAligned>
        <SignUpText>Sign Up For</SignUpText>
        <img src={logo} width={123} height={17} alt="Impactive brand logo" />
      </LeftAligned>
      <GeneralCardWrapper color="#343c4e">
        <VerticalProgressPoints activeIndex={activeIndex} items={items} />
      </GeneralCardWrapper>
    </StyledSidebar>
  );
};

Sidebar.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  showDemoButton: PropTypes.bool,
};

Sidebar.defaultProps = {
  showDemoButton: false,
};

export default Sidebar;
