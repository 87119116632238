import styled from 'styled-components';

export const BackLink = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  cursor: pointer;
  margin-bottom: 20px;
`;

export const Top = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const TopLeft = styled.div`
  flex: 1;
`;

export const Title = styled.h2`
  font-size: 30px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  letter-spacing: 0.015em;
  margin: 0 0 12px;
`;

export const EmailInfo = styled.div`
  margin-bottom: 3px;
`;

export const EmailInfoLabel = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
  margin-right: 4px;
`;

export const TopRight = styled.div`
  flex: none;
  margin-left: 20px;
  text-align: right;
`;

export const Step = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  text-transform: uppercase;
  letter-spacing: 0.075em;
  margin-bottom: 4px;
`;

export const SkipToNextLink = styled.a`
  color: #4680d9;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.semibolditalic};
`;

export const Section = styled.div`
  margin-top: 40px;
`;

export const SectionTitle = styled.h3`
  font-size: 23px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  letter-spacing: 0.015em;
  margin-bottom: 8px;
`;

export const ActionButtons = styled.div`
  margin: 12px -7px 0;

  > * {
    display: inline-block;
    margin: 4px 7px;
  }
`;
