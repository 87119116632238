import * as React from 'react';
import PropTypes from 'prop-types';

function Plus(props) {
  return (
    <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447716 4 1L4 4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6H4L4 9C4 9.55229 4.44771 10 5 10C5.55228 10 6 9.55228 6 9V6H9C9.55229 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4H6V1Z"
        fill="#23293E"
        fillRule="evenodd"
      />
    </svg>
  );
}

Plus.propTypes = {
  height: PropTypes.number,
};

Plus.defaultProps = {
  height: 10,
};

export default Plus;
