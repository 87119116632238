// https://www.figma.com/file/NFYhKfKPHOeisKckQ686Vb/Headcount?node-id=1215%3A2268
import * as React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

export const variants = {
  default: css`
    font-size: 1rem;
  `,
  defaultImportant: css`
    font-size: 1rem !important;
  `,
  f1: css`
    font-family: ${({ theme }) => theme.fonts.semibold};
    font-size: 1.375rem; // 22px;
  `,
  f2: css`
    font-family: ${({ theme }) => theme.fonts.semibold};
    font-size: 1.125rem; // 18px;
  `,
  f3: css`
    font-family: ${({ theme }) => theme.fonts.semibold};
    font-size: 1rem;
  `,
  small: css`
    font-size: 0.75rem; // 12px;
  `,
  subtitle: css`
    font-size: 0.75rem; // 12px;
    text-transform: uppercase;
  `,
  title: css`
    font-family: ${({ theme }) => theme.fonts.semibold};
    font-size: 1.75rem; // 28px;
  `,
};

const StyledText = styled.p`
  color: ${({ $color, theme }) => theme.colors[$color]};
  font-family: ${({ theme }) => theme.fonts.regular};
  margin: 0; // this prevents antd margin from applying, override in your implementation
  ${({ $variant }) => $variant}
`;

function Text({ color, variant, ...rest }) {
  function getVariant() {
    let thisVariant;
    try {
      thisVariant = variants[variant];
    } catch (err) {
      console.error(`<Text /> error: variant ${variant} is not supported.`);
      return null;
    }
    return thisVariant;
  }

  const $variant = getVariant();

  if (!$variant) {
    return null;
  }
  return <StyledText $color={color} $variant={$variant} {...rest} />;
}

Text.propTypes = {
  color: PropTypes.string, // must be one of the colors from the theme
  variant: PropTypes.oneOf(['default', 'f1', 'f2', 'f3', 'small', 'subtitle', 'title']),
};

Text.defaultProps = {
  color: 'black',
  variant: 'default',
};

export default Text;
