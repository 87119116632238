import React from 'react';

const Open = props => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 10C11.9375 10 11.5 10.4688 11.5 11V14H2.5V5H5.5C6.03125 5 6.5 4.5625 6.5 4C6.5 3.46875 6.03125 3 5.5 3H2.5C1.375 3 0.5 3.90625 0.5 5V14C0.5 15.125 1.375 16 2.5 16H11.5C12.5938 16 13.5 15.125 13.5 14V11C13.5 10.4688 13.0312 10 12.5 10ZM16.1875 0.3125C16 0.125 15.75 0 15.5 0H10.5C9.9375 0 9.5 0.46875 9.5 1C9.5 1.53125 9.96875 1.96875 10.5 1.96875H13.0938L6.0625 9.09375C5.6875 9.5 5.6875 10.125 6.0625 10.5C6.46875 10.9062 7.09375 10.9062 7.5 10.5L14.5 3.4375V6C14.5 6.5625 14.9375 7 15.4688 7C15.9688 7 16.5 6.5625 16.5 6V1C16.5 0.75 16.375 0.5 16.1875 0.3125Z"
      fill="black"
    />
  </svg>
);

export default Open;
