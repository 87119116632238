import { RightOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { truncate } from '../../utils/string';
import { ArrowWrapper, Causes, Cause, Row, StyledListItem, StyledMeta } from './common';

const Campaign = ({ name, slogan, slug, logo, causes, isIndented }) => (
  <>
    <StyledListItem isIndented={isIndented}>
      <Row as={Link} to={`/campaigns/${slug}`}>
        <div className="flex-row align-items-center">
          <StyledMeta
            avatar={
              <Avatar size="large" src={logo}>
                {name}
              </Avatar>
            }
            title={<span>{name}</span>}
            description={truncate(slogan, 75)}
          />
        </div>
        <ArrowWrapper>
          <RightOutlined />
        </ArrowWrapper>
      </Row>
      <Causes as={Row}>
        {causes.map(cause => (
          <Cause key={`cc-${cause.id}`}>{cause.name}</Cause>
        ))}
      </Causes>
    </StyledListItem>
  </>
);

Campaign.propTypes = {
  causes: PropTypes.array,
  isIndented: PropTypes.bool,
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  slogan: PropTypes.string,
  slug: PropTypes.string.isRequired,
};

Campaign.defaultProps = {
  causes: [],
  isIndented: false,
  logo: null,
  slogan: null,
};

export default Campaign;
