import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import theme, { media } from '@web/styles/theme';

import AccelerateChangeHeader from '../AccelerateChangeHeader';

// Do not remove, we will probably use it again
// import { message } from 'antd';
// import AccelerateChangeFooter from '../AccelerateChangeFooter';
// import { useHistory } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import ACRoutes from '@web/services/api/accelerateChange';
// import Icon from '@web/components/common/Icon';
// import AccelerateChangeButton from '../AccelerateChangeButton';
// import ChooseVoterMenu from '../ChooseVoterMenu';
// import FriendForm from '../FriendForm';
// import useUtmParams from '../useUtmParams';

const PageWrapper = styled.div`
  color: black;

  width: 100%;
  min-height: 100vh;
  background-color: #fff2e1;

  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  width: 868px;
  margin: 24px auto 0;
  padding-bottom: 64px;

  ${({ $isLanding }) =>
    $isLanding &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      flex: 1;
      gap: 10px;

      > h2 {
        font-size: 36px;
      }

      > div {
        font-size: 18px;
      }
    `}

  ${media.nlg} {
    padding: 0 16px;
    width: 100%;
  }
`;

const Header = styled.h2`
  margin: 0;
  padding: 0;

  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
`;

const Text = styled.div`
  font-family: ${theme.fonts.AccelerateChange};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;

// Do not remove, we will probably use it again
// const StyledUl = styled.ul`
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   margin-top: 10px;
// `;

const ChooseVoter = ({ didTheyVoteFlow }) => {
  // Do not remove, we will probably use it again
  // const { t } = useTranslation();
  // const history = useHistory();
  // const params = useUtmParams();
  // const [voterData, setVoterData] = useState(null);
  // const [isError, setIsError] = useState(false);
  // const [voterSearchInfo, setVoterSearchInfo] = useState(null);

  // const onVoterDataSubmit = async (data, { setSubmitting }) => {
  //   const { address, first_name, last_name, age_range } = data;
  //   const { city, state } = address;
  //   ACRoutes.searchVoters(first_name, last_name, age_range, city, state)
  //     .then(data => {
  //       if (data?.data?.length > 1) {
  //         setVoterData(data.data);
  //         setVoterSearchInfo({ city, name: `${first_name} ${last_name}` });
  //       } else if (data?.data?.length === 1) {
  //         const path = didTheyVoteFlow ? 'did_they_vote' : 'did_my_friend_vote';
  //         history.push(`/${path}/${data.data[0].voterbase_id}${params.formattedParams}`);
  //       } else {
  //         setIsError(true);
  //       }
  //     })
  //     .catch(error => message.error(error?.response?.data?.message || error?.message))
  //     .finally(() => setSubmitting(false));
  // };

  const thanksTitle = useMemo(
    () =>
      didTheyVoteFlow
        ? 'Thank you for using Did They Vote this year.'
        : 'Thank you for using Did My Friends Vote this year.',
    [didTheyVoteFlow],
  );

  return (
    <PageWrapper>
      <AccelerateChangeHeader didTheyVoteFlow={didTheyVoteFlow} isLanding />
      <InnerContainer $isLanding>
        <Header>{thanksTitle}</Header>
        <Text>
          It will take several months for state departments of elections to update the vote history
          with Election Day 2024 voting records, so we're going to pause access to the tool for the
          time being.
        </Text>

        {/*
          Do not remove, we will probably use it again
          {!voterData && !isError && (
            <FriendForm didTheyVoteFlow={didTheyVoteFlow} onSubmit={onVoterDataSubmit} />
          )}
          {voterData && !isError && (
            <ChooseVoterMenu
              didTheyVoteFlow={didTheyVoteFlow}
              voters={voterData}
              city={voterSearchInfo?.city}
              name={voterSearchInfo?.name}
              checkAgain={() => setVoterData(null)}
            />
          )}
          {isError && (
            <>
              <Header>{t('accelerate_change.voter-not-found')}</Header>
              <Text>
                {t(
                  `accelerate_change.${
                    didTheyVoteFlow ? 'we-couldnt-find-this-person' : 'we-couldnt-find-your-friend'
                  }`,
                )}
              </Text>
              <Text>
                <StyledUl>
                  <li>{t('accelerate_change.they-are-not-registered-to-vote')}</li>
                  <li>{t('accelerate_change.their-name-is-spelled')}</li>
                  <li>{t('accelerate_change.they-live-in-a-different-place')}</li>
                  <li>{t('accelerate_change.records-are-not-yet-updated')}</li>
                </StyledUl>
                <AccelerateChangeButton onClick={() => setIsError(false)}>
                  {t('common.try_again')} <Icon name="Refresh" />
                </AccelerateChangeButton>
              </Text>
            </>
          )}
         */}
      </InnerContainer>
      {/*
        Do not remove, we will probably use it again
        <AccelerateChangeFooter didTheyVoteFlow={didTheyVoteFlow} />
      */}
    </PageWrapper>
  );
};

ChooseVoter.propTypes = {
  didTheyVoteFlow: PropTypes.bool,
};

ChooseVoter.defaultProps = {
  didTheyVoteFlow: false,
};

export default ChooseVoter;
