import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { truncate } from '../../utils/string';
import { Row, StyledListItem, StyledMeta } from './common';

const User = ({ user, index = null }) => {
  const {
    first_name,
    campaign_role,
    last_name,
    profile_url,
    scoring: { rank: scoringRank = '' } = {},
    user: { rank: userRank = '' } = {},
    leaderboard_score,
  } = user;
  const { t } = useTranslation();
  const campaignRole =
    campaign_role && campaign_role !== '' ? campaign_role : t('search.option.user');

  const userFirstName = (first_name && first_name[0]) || '';
  const userLastName = (last_name && last_name[0]) || '';
  const userDisplayName = userFirstName || userLastName ? `${userFirstName} ${userLastName}` : '?';

  // Displays different data for user and non-user types
  let avatarContent = userDisplayName;

  // Main item text
  const fullName = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : '';
  const userNameTitle = (fullName && fullName.trim()) || t('search.option.unknown');
  const title = `${index}. ${userNameTitle}`;

  // Subtitle
  const rank = userRank || scoringRank || null;
  const rankText = rank ? `${t('search.option.rank')} ${rank} | ` : ``;
  const ranking = `${rankText}${t('search.option.score')} ${leaderboard_score}`;
  const description = `${campaignRole} | ${ranking}`;

  return (
    <StyledListItem style={{ cursor: 'default' }}>
      <Row>
        <div className="flex-row align-items-center">
          <StyledMeta
            avatar={
              <Avatar size="large" src={profile_url}>
                {avatarContent}
              </Avatar>
            }
            title={<span>{title}</span>}
            description={truncate(description, 75)}
          />
        </div>
      </Row>
    </StyledListItem>
  );
};

User.propTypes = {
  index: PropTypes.number,
  user: PropTypes.shape({
    campaign_role: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    leaderboard_score: PropTypes.number,
    profile_url: PropTypes.string,
    scoring: PropTypes.shape({
      rank: PropTypes.string,
    }),
    user: PropTypes.shape({
      rank: PropTypes.string,
    }),
  }).isRequired,
};

User.defaultProps = {
  index: null,
};

export default User;
