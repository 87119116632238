import { shape } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { user as userAPI } from 'web/services/api';
import { maybeGetToken, removeToken, storeToken } from '../services/session';
import { AuthContext } from '../utils/context';

const AuthProvider = ({ children }) => {
  // Logged in user info
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Initial state is fethed from localStorage to achieve cross-session data persistance.
  const [token, setToken] = useState(maybeGetToken());

  useEffect(() => {
    // Fetch user info when logged in and onboarding finished (onboarding updates user profile)
    setLoading(true);
    if (token) {
      userAPI
        .getProfile()
        .then(({ data: { data } }) => setUser(data))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [token]);

  const setAuth = useCallback(newToken => {
    if (newToken) {
      storeToken(newToken);
      setToken(newToken);
    } else {
      removeToken();
      setToken(null);
    }
  }, []);

  // There is a case when token gets invalidated by axios interceptor handler, we need to update it in that case
  if (token !== maybeGetToken()) {
    setAuth(maybeGetToken());
  }

  // NOTE: if this provider gets any bigger it would be good to convert this to store w/ simple reducer (not Redux!)
  return (
    <AuthContext.Provider
      value={{
        isLockedToCampaign: !!(user && user.locked_campaign_id),
        loading,
        setAuth,
        setUser,
        token,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: shape({}).isRequired,
};

export default AuthProvider;
