import React from 'react';

const Refresh = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.0312 1.25L12.5312 2.78125C11.3125 1.6875 9.71875 1 8 1C5.03125 1 2.4375 2.90625 1.53125 5.71875C1.375 6.25 1.65625 6.8125 2.1875 6.96875C2.71875 7.125 3.28125 6.84375 3.4375 6.3125C4.0625 4.34375 5.90625 3 8 3C9.15625 3 10.2812 3.46875 11.125 4.1875L9.71875 5.5625C9.21875 6.09375 9.5625 7 10.3125 7H14.9062C15.2188 7 15.5 6.75 15.5 6.40625V1.875C15.5 1.09375 14.5625 0.71875 14.0312 1.25ZM13.7812 9.0625C13.25 8.90625 12.6875 9.1875 12.5312 9.71875C11.9062 11.6875 10.0625 13 8 13C6.8125 13 5.71875 12.5625 4.84375 11.8438L6.25 10.4375C6.75 9.9375 6.40625 9.03125 5.65625 9H1.0625C0.75 9.03125 0.5 9.28125 0.5 9.625V14.1562C0.5 14.9375 1.40625 15.3125 1.9375 14.7812L3.4375 13.25C4.65625 14.3438 6.25 15 7.96875 15C10.9062 15 13.5 13.125 14.4062 10.3125C14.5938 9.78125 14.3125 9.21875 13.7812 9.0625Z"
      fill="black"
    />
  </svg>
);

export default Refresh;
