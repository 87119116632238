import * as React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  cursor: pointer;
  margin-top: 60px;
  padding: 14px;
`;

const Text = styled.span`
  color: ${({ theme }) => theme.colors.darkTeal};
  font-family: ${({ theme }) => theme.fonts.black};
  font-size: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

function BackButton(props) {
  const { t } = useTranslation();

  return (
    <StyledButton {...props}>
      <Text>← {t('common.previous_question')}</Text>
    </StyledButton>
  );
}

BackButton.propTypes = {};

BackButton.defaultProps = {};

export default BackButton;
