import React from 'react';
import { user } from '../../../services/api';

function useUserLocked() {
  const [lockedToCampaign, setLockedToCampaign] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    user.getProfile().then(({ data }) => {
      const { locked_campaign_contact_selection, locked_campaign_delete_unapproved } = data.data;
      const isLocked = !!(locked_campaign_contact_selection || locked_campaign_delete_unapproved);
      setLockedToCampaign(isLocked);
      setLoading(false);
    });
  }, []);
  return { loading, lockedToCampaign };
}

export default useUserLocked;
