import { createContext } from 'react';

/**
 * AuthContext allows access to token and auth function which deals with
 * updating the token, or its removal (which results in logging user out)
 */
const AuthContext = createContext(null);

/**
 * StateContext stores data that's common across app (causes, etc.)
 * It's separated from AuthContext to avoid unnecessary app root rendering whenever
 * any of state wide data is loaded.
 */
const StateContext = createContext(null);

// eslint-disable-next-line import/prefer-default-export
export { AuthContext, StateContext };
