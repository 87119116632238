import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Button from '@web/components/DialerActivity/components/Button';
import CustomFieldList from './components/CustomFieldList';
import DotLoader from '@web/components/DotLoader';
import TagsList from './components/TagsList';

import useReportApi from './useReportApi';

const ButtonArea = styled.div`
  display: flex;
  margin-top: 2rem;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.5rem;
  font-weight: 600;
`;

const Wrapper = styled.div`
  ${({ $reportDuringCall }) =>
    $reportDuringCall &&
    css`
      width: 100%;
      padding: 7px 40px 20px;

      label {
        > span:nth-child(2) {
          white-space: pre-wrap;
        }
      }
    `}
`;

function TagsAndCustomFields({ activity, reportDuringCall }) {
  const { customFieldAnswers, handleAnswerChange, handleSubmit, loading, report, tagAnswers } =
    useReportApi(activity, reportDuringCall);

  if (loading) {
    return <DotLoader centered color="darkBlue" />;
  }

  if (!report?.customizations?.length && !report?.taggings?.length) {
    return null;
  }

  return (
    <Wrapper $reportDuringCall={reportDuringCall}>
      <Title>Fill Report</Title>
      <TagsList
        answers={tagAnswers}
        handleAnswerChange={handleAnswerChange}
        tags={report?.taggings}
      />
      <CustomFieldList
        answers={customFieldAnswers}
        customFields={report?.customizations}
        handleAnswerChange={handleAnswerChange}
      />
      {!reportDuringCall && (
        <ButtonArea>
          <Button loading={loading} onClick={handleSubmit} style={{ width: '100%' }}>
            Submit Report
          </Button>
        </ButtonArea>
      )}
    </Wrapper>
  );
}

TagsAndCustomFields.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    id: PropTypes.number.isRequired,
  }),
  reportDuringCall: PropTypes.bool,
};

TagsAndCustomFields.defaultProps = {
  reportDuringCall: false,
};

export default TagsAndCustomFields;
