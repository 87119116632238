import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { EnterOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';
import { truncate } from '../../../utils/string';
import theme from '@web/styles/theme';

const Slide = styled.div`
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
  padding-top: 30px;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Content = styled.div`
  position: relative;
  background: ${theme.colors.white};
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.06);
  margin: 8px;
  flex: 1;
  padding: 20px 20px 20px 30px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 2px 2px 20px rgb(0 16 72 / 8%);
    transform: translate3d(-6px, -5px, 0px);
  }
`;

const Title = styled.div`
  font-family: ${theme.fonts.semibold};
  color: ${theme.colors.black};
  font-size: 1.125rem;
`;

const Description = styled.div`
  font-family: ${theme.fonts.regular};
  overflow-wrap: anywhere;
  color: ${theme.fonts.dimGray2};
  font-size: 1rem;
  margin-top: 5px;
`;

const CarouselWrapper = styled.div`
  width: 92%;
  overflow: ${({ visible }) => (visible ? 'unset' : 'hidden')};
`;

const StyledCarousel = styled(Carousel)`
  .slick-dots-bottom {
    bottom: -20px;
    > li {
      width: 5px;
      button {
        background: #c4c4c4;
        opacity: 0.4;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
    .slick-active {
      width: 5px;
      button {
        background: #c4c4c4;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
  }
`;

const ArrowWrapper = styled.div`
  &&& {
    height: 100%;
    z-index: 99;
    width: 5%;
    ${({ rightArrow }) => (!rightArrow ? `left: -4.5%` : `right: -4.5%`)};
    top: 10px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    &:hover {
      background: rgba(64, 169, 255, 0.1);
    }
    > span {
      svg {
        width: 16px;
        height: 20px;
        fill: ${theme.colors.cyan};
      }
    }
  }
`;

const StyledEnterOutlined = styled(EnterOutlined)`
  position: absolute;
  left: 10px;
  top: 25px;
  transform: scaleX(-1);
  svg {
    fill: ${theme.colors.cyan};
    stroke: ${theme.colors.cyan};
    stroke-width: 30;
  }
`;
const ScriptsCarousel = ({ visible, slideData, setScriptMessage }) => {
  const carouselRef = useRef(null);
  const [dragging, setDragging] = useState(false);

  const renderArrow = isRight => {
    return (
      <ArrowWrapper
        rightArrow={isRight}
        onClick={() => {
          if (carouselRef.current) {
            if (isRight) {
              carouselRef.current.next();
            } else {
              carouselRef.current.prev();
            }
          }
        }}
      >
        {isRight ? <RightOutlined /> : <LeftOutlined />}
      </ArrowWrapper>
    );
  };

  const beforeChange = () => {
    setDragging(true);
    // We need this because the afterChange function is sometimes not called.
    setTimeout(() => {
      setDragging(false);
    }, 800);
  };

  return (
    <CarouselWrapper visible={visible}>
      <StyledCarousel
        dots
        beforeChange={beforeChange}
        nextArrow={renderArrow(true)}
        prevArrow={renderArrow(false)}
        infinite
        draggable
        arrows
        swipeToSlide
        slidesToScroll={1}
        ref={carouselRef}
      >
        {slideData.scripts.map((i, index) => (
          <Slide key={index}>
            {i.map((script, scriptIndex) => (
              <Content
                key={scriptIndex}
                onClick={() => (!dragging ? setScriptMessage(script) : null)}
              >
                <StyledEnterOutlined />
                <Title>{script.name}</Title>
                <Description>{truncate(script.message, 82)}</Description>
              </Content>
            ))}
          </Slide>
        ))}
      </StyledCarousel>
    </CarouselWrapper>
  );
};

ScriptsCarousel.propTypes = {
  setScriptMessage: PropTypes.func.isRequired,
  slideData: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default ScriptsCarousel;
