import * as React from 'react';

function FirstPlaceIcon() {
  return (
    <svg
      width="122"
      height="116"
      viewBox="0 0 122 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.761 6.53498L83.4807 28.9886C83.9602 29.4843 84.5197 29.896 85.1356 30.2065L112.976 44.2393C113.413 44.4594 113.586 44.9932 113.363 45.428L98.9565 73.4069C98.6454 74.011 98.4365 74.6625 98.3381 75.3348L93.7758 106.511C93.7043 107 93.2442 107.334 92.757 107.251L62.1654 102.027C61.479 101.91 60.7778 101.91 60.0915 102.027L29.4999 107.251C29.0127 107.334 28.5526 107 28.481 106.511L23.9188 75.3348C23.8204 74.6625 23.6115 74.011 23.3004 73.4069L8.89427 45.428C8.67043 44.9933 8.84392 44.4594 9.28056 44.2393L37.1213 30.2065C37.7372 29.896 38.2967 29.4843 38.7762 28.9886L60.4959 6.53498C60.8418 6.17742 61.4151 6.17742 61.761 6.53498Z"
        fill="url(#paint0_radial_428_1995)"
        stroke="white"
        stroke-width="5.28"
      />
      <path
        d="M58.7613 76.0015H66.0477V41.1535H64.8861L49.8909 44.3215V51.0799L58.7613 48.7567V76.0015Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_428_1995"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.9663 39.5269) rotate(52.1317) scale(195.151 194.515)"
        >
          <stop stop-color="#FDB90A" />
          <stop offset="1" stop-color="white" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default FirstPlaceIcon;
