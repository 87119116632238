import { ArrowUpOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { media } from '../../styles/theme';
import ImpactiveButton from '../ImpactiveButton';

const { TextArea } = Input;

const Inputs = styled.div`
  box-sizing: border-box;
  padding: 15px 15px 15px;
  ${media.ns} {
    padding-bottom: 1em;
  }
`;

const TextAndSendButton = styled.div`
  display: flex;
  justify-content: space-between;
  martin: 15px;
`;

const TextAreaWrapper = styled.div`
  margin-right: 10px;
  flex: 1;
`;

const ButtonWrapper = styled.div`
  min-width: 135px;
  display: flex;
  flex-direction: column-reverse;
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    min-height: 42px !important;
    font-size: 1rem;
    border-radius: 4px;
  }
`;

const MessageInput = React.forwardRef(
  (
    {
      setMessageContent,
      pressSend,
      sendDisabled,
      constructMailToLink,
      textDisabled,
      messageContent,
      isEmailResponseType,
      profaneWordDetected,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    return (
      <Inputs ref={ref}>
        <TextAndSendButton>
          <TextAreaWrapper>
            <StyledTextArea
              autoSize={{ maxRows: 6 }}
              onChange={e => setMessageContent(e.target.value)}
              disabled={textDisabled}
              value={messageContent}
              aria-label="Enter message"
            />
          </TextAreaWrapper>
          <Tooltip title={profaneWordDetected && t('inbox.thread.profane_words')}>
            <ButtonWrapper>
              {isEmailResponseType ? (
                // eslint-disable-next-line
                <a
                  role="button"
                  target="emailTab"
                  href={sendDisabled ? null : constructMailToLink()}
                  onClick={sendDisabled ? undefined : pressSend}
                  tabIndex={0}
                  aria-label="Send"
                  aria-invalid={sendDisabled}
                >
                  <ImpactiveButton
                    icon={<ArrowUpOutlined />}
                    type="primary"
                    size="medium"
                    block
                    disabled={sendDisabled}
                    children={t('common.send')}
                  />
                </a>
              ) : (
                <ImpactiveButton
                  icon={<ArrowUpOutlined />}
                  type="primary"
                  size="medium"
                  block
                  disabled={sendDisabled}
                  onClick={pressSend}
                  children={t('common.send')}
                  aria-label="Send"
                />
              )}
            </ButtonWrapper>
          </Tooltip>
        </TextAndSendButton>
      </Inputs>
    );
  },
);

MessageInput.propTypes = {
  constructMailToLink: PropTypes.func,
  isEmailResponseType: PropTypes.bool,
  messageContent: PropTypes.string,
  pressSend: PropTypes.func,
  profaneWordDetected: PropTypes.bool,
  sendDisabled: PropTypes.bool,
  setMessageContent: PropTypes.func,
  textDisabled: PropTypes.bool,
};

export default MessageInput;
