import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Button as AntdButton } from 'antd';
import DotLoader from '@web/components/DotLoader';
import { vrDefaultColors } from '@web/styles/theme';

const variants = {
  blue: css`
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.blue};
  `,
  disabled: css`
    background-color: ${({ theme }) => theme.colors.whiteSmoke};
    border-color: ${({ theme }) => theme.colors.blackL72};
    color: ${({ theme }) => theme.colors.blackL72};
  `,
  fan_out: css`
    background-color: ${({ theme }) => theme.colors.newBlue};
    color: ${({ theme }) => theme.colors.blue};
    border: 1px solid ${({ theme }) => theme.colors.blue};
    border-radius: 8px;
    text-transform: capitalize;
  `,
  link: css`
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.blue};
    :hover {
      color: ${({ theme }) => theme.colors.black};
    }
  `,
  outline: css`
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blue};
    border: 1px solid ${({ theme }) => theme.colors.blue};
  `,
};

const StyledButton = styled(AntdButton)`
  &&& {
    border-radius: ${({ $isFanOut }) => ($isFanOut ? '8px' : '6px')};
    box-shadow: none;
    height: ${({ $isFanOut }) => ($isFanOut ? '64px' : '48px')};
    font-weight: 700;
    font-size: 1rem;
    padding-bottom: 4px;
    padding-top: 4px;
    text-align: center;
    transition: color 150ms ease-out, opacity 150ms ease-out;
    text-transform: ${({ $isFanOut }) => $isFanOut && 'capitalize'};
    width: 100%;
    :hover {
      opacity: 0.5;
    }
    &&& {
      ${({ $variant, $originalVariant, $customSettings }) =>
        $customSettings
          ? css`
              background-color: ${$originalVariant === 'link'
                ? 'unset'
                : $customSettings.button_color ?? vrDefaultColors.buttonColor};

              color: ${$originalVariant === 'link'
                ? `${$customSettings.font_color ?? vrDefaultColors.fontColor}BF`
                : $customSettings.background_color ?? vrDefaultColors.backgroundColor};
              border-color: ${$originalVariant === 'link'
                ? 'unset'
                : $customSettings.background_color ?? vrDefaultColors.backgroundColor};
              border-width: ${$originalVariant === 'link' ? 0 : 1};
              svg path {
                stroke: ${$originalVariant === 'link'
                  ? `${$customSettings.font_color ?? vrDefaultColors.fontColor}BF`
                  : $customSettings.background_color ?? vrDefaultColors.backgroundColor};
              }
            `
          : $variant}
    }
    :disabled {
      ${variants.disabled}
    }
  }
`;

function Button({ children, loading, variant, isFanOut, customVariant, ...rest }) {
  const thisVariant = variants[variant] || 'blue';

  return (
    <StyledButton
      $customSettings={customVariant}
      $variant={thisVariant}
      $originalVariant={variant}
      $isFanOut={isFanOut}
      {...rest}
    >
      {loading ? <DotLoader color={isFanOut && 'blue'} /> : children}
    </StyledButton>
  );
}

Button.propTypes = {
  children: PropTypes.any,
  customVariant: PropTypes.shape({
    button_color: PropTypes.string,
    field_outline_color: PropTypes.string,
    font_color: PropTypes.string,
  }),
  isFanOut: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['blue', 'disabled', 'link', 'outline', 'fan_out']),
};

Button.defaultProps = {
  children: null,
  loading: false,
  variant: 'blue',
};

export default Button;
