import i18n from 'web/components/i18n';

export const actionTypes = [
  {
    label: i18n.t('activity.types.all'),
    value: null,
  },
  {
    label: i18n.t('activity.types.text_a_list_activity'),
    value: 'TextAListActivity',
  },
  {
    label: i18n.t('activity.types.DialerActivity'),
    value: 'DialerActivity',
  },
  {
    label: i18n.t('activity.types.text_a_friend_activity'),
    value: 'TextAFriendActivity',
  },
  {
    label: i18n.t('activity.types.share_activity'),
    value: 'ShareActivity',
  },
  {
    label: i18n.t('activity.types.web_activity'),
    value: 'WebActivity',
  },
];
