import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';

import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  background-color: #b4b4b4;
  border-radius: ${({ $height, $isMtv }) => ($isMtv ? 4 : $height / 2)}px;
  border: ${({ $isMtv }) => ($isMtv ? 'solid 1px black' : 'unset')};
  display: flex;
  height: ${({ $height }) => $height}px;
  overflow: hidden;
`;

const StyledProgressBar = styled(animated.div)`
  background-color: ${({ $isCannabis, $isPartner, theme }) =>
    $isCannabis ? theme.colors.yellow : $isPartner ? theme.colors.yellow : theme.colors.teal};
  border-radius: ${({ $height, $isMtv }) => ($isMtv ? 4 : $height / 2)}px;
  height: ${({ $height }) => $height}px;
`;

const HowToFinishWrapper = styled.div`
  border: 2px solid black;
  border-radius: 4px;
  width: 43%;
  text-align: center;
  margin-left: ${({ $position }) => `calc(${$position}% - 21.5%)`};
  margin-top: 16px;
  position: relative;
  font-size: 12px;
  padding: 6px 10px;
  color: black;
  line-height: 12px;

  &:before {
    content: '';
    height: ${({ $height }) => `calc(${$height / 2}px + 16px)`};
    width: 2px;
    position: absolute;
    transform: translate(0, 0);
    left: 50%;
    bottom: 100%;
    background: black;
  }
`;

function ProgressBar({ height, progress, team, activity, numberOfPages }) {
  const { t } = useTranslation();

  const width = Math.ceil(progress * 100);
  const style = useSpring({
    width: `${width || 0}%`,
  });
  const isPartner = useIsPartner(team);
  const isCannabis = React.useMemo(
    () => activity?.campaign?.cannabis_registration_enabled,
    [activity?.campaign?.cannabis_registration_enabled],
  );
  const headcountMtvEnabled = React.useMemo(
    () => activity.campaign.headcount_mtv_enabled,
    [activity.campaign.headcount_mtv_enabled],
  );

  const progressBarHeight = React.useMemo(
    () => (headcountMtvEnabled ? 24 : height),
    [headcountMtvEnabled, height],
  );

  const lastStepProgress = React.useMemo(
    () => (100 / (numberOfPages + 1)) * numberOfPages,
    [numberOfPages],
  );

  const showHowToFinishSection = React.useMemo(
    () => headcountMtvEnabled && ~~(progress * 100) === ~~lastStepProgress,
    [lastStepProgress, headcountMtvEnabled, progress],
  );

  const errorMsg = `<ProgressBar /> progress prop requires a range between 0 and 1, received: ${progress}`;
  if (progress < 0 || progress > 1) {
    console.error(errorMsg);
    return null;
  }

  return (
    <>
      <Wrapper $isMtv={headcountMtvEnabled} $height={progressBarHeight}>
        <StyledProgressBar
          $height={progressBarHeight}
          style={style}
          $isCannabis={isCannabis}
          $isPartner={isPartner}
          $isMtv={headcountMtvEnabled}
        />
      </Wrapper>
      {showHowToFinishSection && (
        <HowToFinishWrapper $height={progressBarHeight} $position={lastStepProgress}>
          {t('check_registration.headcount.mtv.almostThere')}
        </HowToFinishWrapper>
      )}
    </>
  );
}

ProgressBar.propTypes = {
  activity: PropTypes.object,
  height: PropTypes.number,
  numberOfPages: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  team: PropTypes.object,
};

ProgressBar.defaultProps = {
  height: 6,
};

export default ProgressBar;
