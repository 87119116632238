import React from 'react';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import pxToRem from '@web/utils/pxToRem';
import { CheckOutlined } from '@ant-design/icons';

const containerStyle = css`
  color: #23293e;
  min-height: ${pxToRem(50)};
  background-color: ${({ isGray }) => (isGray ? '#f5f6f7' : '#fafafa')};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  ${containerStyle};
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${containerStyle};
`;

const Wrapper = styled.div`
  position: relative;
  ${containerStyle};
`;

const TextElement = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: ${pxToRem(100)};
  background: #fafafa;
  font-size: ${pxToRem(18)};
  text-align: center;

  > span {
    font-size: ${pxToRem(16)};
  }
`;

const PlanGridRow = ({ name, options, isGray }) => {
  return (
    <>
      <Container isGray={isGray}>{name}</Container>
      {options.map((opt, index) => {
        const isStringValue = typeof opt === 'string';

        return !isStringValue ? (
          <Icon isGray={isGray} key={index}>
            {opt && <CheckOutlined style={{ color: '#23293e' }} />}
          </Icon>
        ) : (
          <Wrapper>
            <TextElement dangerouslySetInnerHTML={{ __html: opt }} />
          </Wrapper>
        );
      })}
    </>
  );
};

PlanGridRow.propTypes = {
  isGray: PropTypes.bool,
  name: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(PropTypes.bool).isRequired,
};

PlanGridRow.defaultProps = {
  isGray: null,
};

export default PlanGridRow;
