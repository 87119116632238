import {
  ContactsOutlined,
  FileTextOutlined,
  TrophyOutlined,
  HomeOutlined,
  InboxOutlined,
  LoginOutlined,
  LogoutOutlined,
  SearchOutlined,
  UserOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Redirect, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { auth } from '../../../services/api';
import theme, { colors } from '../../../styles/theme';
import { AuthContext, StateContext } from '@web/utils/context';

import poweredByImpactive from '@assets/images/logo/Impactive_Primary.png';
import poweredByImpactiveIcon from '@assets/images/logo/Impactive_Icon_Primary.png';
import CampaignSelect from './CampaignSelect';

const { Sider } = Layout;
const { Item } = Menu;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ToggleIconWrapper = styled.div`
  z-index: 3;
  cursor: pointer;

  > span {
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
    color: ${theme.colors.black};
  }

  ${({ $open }) =>
    !$open &&
    css`
      position: absolute;
      left: 230px;
      top: 16px;
      width: 32px;
      height: 32px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      box-shadow: 4px 4px 20px rgba(0, 16, 72, 0.06);
      > span {
        margin-bottom: 0px;
      }
    `}
`;

const TransparentBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: 0.3;
  z-index: 2;
`;

// Changed Menu from adnd to div because antd Menu should have Menu.Item children (there were a lot of console errors)
// but we are using links so not to rewrite styles I prefer to change it to div
const CustomMenu = styled.div`
  background-color: ${colors.background};
  align-items: center;
`;

const MenuItem = styled(Item)`
  &&& {
    background: ${({ theme }) => theme.colors.background};
    font-family: ${({ theme }) => theme.fonts.black};
    color: ${({ theme, $goToAdmin }) => ($goToAdmin ? theme.colors.coral : 'unset')};
    font-size: 13px;
    letter-spacing: 1.15px;
    margin: 0 !important;
    text-transform: uppercase;
    text-decoration: none;
    width: 100%;
    padding: ${({ $logo }) => ($logo ? 'unset' : '0 16px 0 24px')};
    margin: 0 -16px 0 -24px;
    height: unset;
    display: flex;
    align-items: center;
    &:hover {
      background: ${({ theme, $logo }) => ($logo ? 'unset' : theme.colors.teal20)};
      cursor: ${({ $logo }) => ($logo ? 'unset' : 'pointer')};
    }
    :after {
      border-right: none !important;
    }
    .anticon {
      margin-right: 10px !important;
    }

    svg {
      width: 18px;
      height: 18px;
    }

    ${({ $open }) =>
      $open &&
      css`
        width: 48px;
        height: 48px;
        padding: 15px;
        justify-content: center;
        border-radius: 4px;
        .anticon {
          margin-right: 0 !important;
          svg {
            width: 18px;
            height: 18px;
          }
        }
      `}
  }
`;

const MenuLink = styled(NavLink)`
  &&& {
    font-family: ${({ theme }) => theme.fonts.black};
    color: ${({ theme }) => theme.colors.blue};
    font-size: 13px;
    letter-spacing: 1.15px;
    margin: 0 !important;
    text-transform: uppercase;
    width: 100%;
    padding: 16px 16px 16px 24px;
    margin: 0 -16px 0 -24px;
    display: flex;
    align-items: center;
    .anticon {
      margin-right: 10px !important;
    }
    &.active {
      color: ${({ theme }) => theme.colors.coral};
      background: ${({ theme }) => theme.colors.teal20};
    }
    &:hover {
      background: ${({ theme }) => theme.colors.teal20};
      color: ${({ theme }) => theme.colors.coral};
      text-decoration: none;
    }

    svg {
      width: 18px;
      height: 18px;
    }

    ${({ $open }) =>
      $open &&
      css`
        width: 48px;
        height: 48px;
        padding: 15px;
        justify-content: center;
        border-radius: 4px;
        .anticon {
          margin-right: 0 !important;
          svg {
            width: 18px;
            height: 18px;
          }
        }
      `}
  }
`;

const PoweredByImpactive = styled.img`
  ${({ $open }) =>
    $open
      ? css`
          height: 48px;
          width: 48px;
          margin: 24px;
        `
      : css`
          width: 70%;
          margin: 0 16px 24px 24px;
        `}
`;

const PoweredByText = styled.div`
  margin-left: 24px;
`;

const StyledSider = styled(Sider)`
  &&& {
    border-right: ${({ theme }) => `3px solid ${theme.colors.borderGray}`};
    background-color: ${colors.background};
    height: 100vh;
    left: 0;
    overflow: auto;
    padding: ${({ $open }) => ($open ? '7px 0' : 'unset')};
    position: fixed;
    z-index: 2;
  }
`;

const StyledMenu = styled(Menu)`
  &&& {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const Sidebar = props => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { token, setAuth, user, setUser } = useContext(AuthContext);
  const { unreadMessagesCount } = useContext(StateContext);
  const [loggedOut, setLoggedOut] = useState(false);
  const [redirectURL, setRedirectURL] = useState(null);
  const [open, setOpen] = useState(props.mini);

  const doLogout = () => {
    auth.signOut().then(() => {
      // set logout flag so we can cleanup in useEffects
      setLoggedOut(true);
      setUser(null);
      setRedirectURL('/auth');
    });
  };

  const goToAdmin = () => {
    window.open('/admin', '_self');
  };

  useEffect(() => {
    // Unset Authentication on component cleanup, otherwise there are memory leaks.
    return () => {
      if (loggedOut) setAuth(null);
    };
  });

  if (redirectURL) return <Redirect to={redirectURL} />;

  const canAccessAdmin = user && (user.is_campaign_admin || user.can_admin_accounts);

  return (
    <>
      {props.mini && !open && (
        <>
          <ToggleIconWrapper onClick={() => setOpen(!open)}>
            {open ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          </ToggleIconWrapper>
          <TransparentBackground />
        </>
      )}
      <StyledSider width={open ? '80' : '240'} $open={open} {...props}>
        {token ? (
          // Logged in
          <>
            <MenuWrapper>
              <CustomMenu theme="light" mode="inline" className="d-flex flex-column">
                {props.mini && open && (
                  <ToggleIconWrapper onClick={() => setOpen(!open)} $open>
                    {open ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                  </ToggleIconWrapper>
                )}

                <CampaignSelect mini={open} />

                <MenuLink
                  $open={open}
                  to="/frontline"
                  isActive={(match, location) => match && !location.search.match(/charities/g)}
                >
                  <HomeOutlined /> {!open && t('menu.actions')}
                </MenuLink>

                <MenuLink $open={open} to={'/leaderboard'}>
                  <TrophyOutlined /> {!open && t('menu.leaderboard')}
                </MenuLink>

                <MenuLink $open={open} to="/messages">
                  <InboxOutlined /> {!open && t('menu.inbox')}
                  {!open && unreadMessagesCount ? ` (${unreadMessagesCount})` : null}
                </MenuLink>

                <MenuLink $open={open} to="/contacts">
                  <ContactsOutlined /> {!open && t('menu.contacts')}
                </MenuLink>

                <MenuLink $open={open} to="/add-report">
                  <FileTextOutlined /> {!open && t('menu.add_report')}
                </MenuLink>

                <MenuLink $open={open} to="/headquarters">
                  <UserOutlined /> {!open && t('menu.my_profile')}
                </MenuLink>
              </CustomMenu>

              <StyledMenu>
                {canAccessAdmin && (
                  <MenuItem $open={open} $goToAdmin onClick={goToAdmin}>
                    <LogoutOutlined
                      style={{
                        color: '#f3766b',
                        transform: 'rotate(180deg)',
                      }}
                    />
                    {!open && t('menu.admin')}
                  </MenuItem>
                )}
                <MenuItem $open={open} data-testid="logout-button" onClick={doLogout}>
                  <LogoutOutlined /> {!open && t('menu.logout')}
                </MenuItem>
                <MenuItem $open={open} style={open && { height: 'unstet' }} $logo>
                  {open ? (
                    <PoweredByImpactive
                      $open
                      src={poweredByImpactiveIcon}
                      alt="Powered by Impactive"
                    />
                  ) : (
                    <Flex>
                      <PoweredByText>{t('menu.powered_by')}</PoweredByText>
                      <PoweredByImpactive src={poweredByImpactive} alt="Powered by Impactive" />
                    </Flex>
                  )}
                </MenuItem>
              </StyledMenu>
            </MenuWrapper>
          </>
        ) : (
          <Menu theme="light" mode="inline" style={{ width: '100%' }} selectedKeys={[pathname]}>
            <MenuLink to="/explore">
              <SearchOutlined /> {t('menu.explore')}
            </MenuLink>

            <MenuLink to="/auth">
              <LoginOutlined /> {t('menu.sign_in')}
            </MenuLink>
          </Menu>
        )}
      </StyledSider>
    </>
  );
};

Sidebar.propTypes = {
  mini: PropTypes.bool,
};

Sidebar.defaultProps = {
  mini: false,
};

export default Sidebar;
