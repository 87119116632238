import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PageHeader from '../../components/Header';
import { Content } from '../../components/shared';
import FindVotersForm from './components/FindVotersForm';
import VotersList from './components/VotersList';

import { users } from '@web/services/api';
import VotersHistoryModal from '../../components/VotersHistoryModal';
import { Redirect } from 'react-router-dom';

const FindVoters = () => {
  const { t } = useTranslation();
  const campaign = useSelector(state => state.oneCampaign.selectedCampaign);
  const listSection = useRef(null);

  const [redirectData, setRedirectData] = useState(null);
  const [voters, setVoters] = useState(null);
  const [selectedVoter, setSelectedVoter] = useState(null);
  const [showVoterModal, setShowVoterModal] = useState(null);

  const submitForm = async (formData, { setSubmitting }) => {
    users.searchVoters(formData).then(({ data }) => {
      setVoters(data);
      setSubmitting(false);
      listSection.current.scrollIntoView({ behavior: 'smooth' });
    });
  };

  const redirectToVoterView = voter => {
    setSelectedVoter(voter);
    setRedirectData(true);
  };

  if (redirectData) {
    return (
      <Redirect
        to={{
          pathname: '/add-report/view-voter',
          state: { voter: selectedVoter },
        }}
      />
    );
  }

  return (
    <Content>
      <PageHeader
        title={`${t('add_report.find_voter.header')} ${campaign?.name}`}
        description={t('add_report.find_voter.description')}
      />

      <FindVotersForm campaignId={campaign?.id} onSubmit={submitForm} />

      <VotersList
        ref={listSection}
        voters={voters}
        selectVoter={setSelectedVoter}
        showVoterModal={setShowVoterModal}
        selectedVoterId={selectedVoter?.voterbase_id}
        onSelectClick={() => redirectToVoterView(selectedVoter)}
      />

      <VotersHistoryModal
        closeModal={() => setShowVoterModal(null)}
        voter={showVoterModal}
        visible={!!showVoterModal}
        onListItemClick={redirectToVoterView}
      />
    </Content>
  );
};

export default FindVoters;
