import React from 'react';

import { Progress } from 'antd';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors, fonts } from '../../styles/theme';

import badge1 from '@web/../../assets/images/web/badge1.png';
import badge2 from '@web/../../assets/images/web/badge2.png';
import badge3 from '@web/../../assets/images/web/badge3.png';
import badge4 from '@web/../../assets/images/web/badge4.png';
import badge5 from '@web/../../assets/images/web/badge5.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
`;
const NextRankText = styled.div`
  width: 100%;
  color: ${colors.black};
  font-family: ${fonts.semibold};
  font-size: 16px;
`;

const NextRankUntilText = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  font-family: ${fonts.regular};
  color: ${colors.blue};
  margin-bottom: 20px;
`;

const RanksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RankAndBadgeText = styled.div`
  align-items: center;
  display: flex;
  text-transform: uppercase;
  margin-bottom: 10px;
  opacity: ${({ nextRank }) => (nextRank ? 0.4 : 1)};
`;

const RankBadge = styled.img`
  width: 28px;
  height: 26px;
  padding: 5px 0;
  padding-right: 7px;
`;

const Rank = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.25rem;
  font-family: ${fonts.regular};
`;

function NextRank({ current, nextRank, fromComplete, toComplete, rank }) {
  const { t } = useTranslation();
  const toNextRank = toComplete - current;
  const progress =
    toComplete === fromComplete
      ? 100
      : ((current - fromComplete) / (toComplete - fromComplete)) * 100;

  // turn rank from "New Volunteer" to "new_volunteer" and then get that key from i18n locale
  const translatedRank = t(`profile.badges.${rank?.toLowerCase()?.replace(/\s/g, '_')}`, rank);
  const translatedNextRank = nextRank
    ? t(`profile.badges.${nextRank?.toLowerCase()?.replace(/\s/g, '_')}`, nextRank)
    : '';

  const GetRankBadge = ({ rank }) => {
    switch (rank) {
      case 'New Recruit':
        return badge1;
      case 'Dedicated Volunteer':
        return badge2;
      case 'Activist':
        return badge3;
      case 'Community Leader':
        return badge4;
      case 'Veteran Organizer':
        return badge5;
      default:
        return badge1;
    }
  };

  return (
    <Wrapper>
      <NextRankText>
        <span></span>
        <RanksWrapper>
          <RankAndBadgeText>
            <RankBadge src={GetRankBadge({ rank })} alt="Badge" />
            <Rank>{translatedRank}</Rank>
          </RankAndBadgeText>
          <RankAndBadgeText nextRank={!!nextRank}>
            {nextRank && <RankBadge src={GetRankBadge({ nextRank })} alt="Badge" />}
            <Rank>{translatedNextRank || t('hq.max_rank_achieved')}</Rank>
          </RankAndBadgeText>
        </RanksWrapper>

        <Progress
          percent={progress}
          showInfo={false}
          strokeWidth={10}
          strokeColor={colors.coral}
          trailColor={colors.blue06}
          style={{ margin: '7px 0 15px' }}
        />
        <NextRankUntilText>
          {nextRank && (
            <>
              <div>
                {t('hq.complete_actions_to_receive', {
                  actionOrActions: toNextRank === 1 ? t('hq.action') : t('hq.action_plural'),
                  toNextRank: toNextRank,
                })}
              </div>
              <div>
                {t('hq.actions_completed', {
                  completed: toComplete - toNextRank,
                  toComplete: toComplete,
                })}
              </div>
            </>
          )}
        </NextRankUntilText>
      </NextRankText>
    </Wrapper>
  );
}

NextRank.propTypes = {
  current: PropTypes.number.isRequired,
  fromComplete: PropTypes.number.isRequired,
  nextRank: PropTypes.string,
  rank: PropTypes.string.isRequired,
  toComplete: PropTypes.number.isRequired,
};

NextRank.defaultProps = {};

export default NextRank;
