import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { colors, media, vrDefaultColors } from '@web/styles/theme';

import {
  ProgressContainer,
  ProgressPlaceholder,
  Progress,
} from '@web/components/RegistrationActivity/ActivityHero/components/ActivityGoals/components/Progress';

const GoalsPlaceholder = styled.div`
  height: 80px;
`;

const StyledActivityGoals = styled.div`
  margin: 0 auto;
  width: 100%;

  ${media.ns} {
    max-width: 70vw;
  }
  ${media.xxl} {
    max-width: 1065px;
  }
`;

const GoalLabels = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const GoalsLeft = styled.div`
  width: 50%;
`;

const GoalsRight = styled.div`
  width: 50%;
  text-align: right;
`;

const GoalLabel = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.1rem;
  line-height: 1.1rem;
  text-transform: uppercase;

  ${media.ns} {
    line-height: 1.5rem;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const GoalAmount = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 1.2rem;

  ${media.ns} {
    color: ${({ theme }) => theme.colors.blue};
    font-size: 1.5rem;
  }
`;

const ActivityGoals = ({ activity, goals, isMobile, ...rest }) => {
  const progressColor = useMemo(
    () => activity?.settings?.progress_bar_color ?? vrDefaultColors.progressBarColor,
    [activity?.settings?.progress_bar_color],
  );

  if (!activity.registrations_goal_amount) {
    return <GoalsPlaceholder />;
  }

  const { registrations_goal_amount: goalAmount, registrations_goal_label: label } = activity;
  const { registrations_count: currentTotalAmount, referrals_count: currentReferralsAmount } =
    goals;

  const currentPercent = Math.ceil((currentTotalAmount * 100) / goalAmount);
  // `currentTotalAmount` as a divisor for referralsPercent would give us referals as a % of total (in a [0, 100%] range)
  // however that does not work with the way this data is presented in a chart so reverting back to % of the goal instead.
  const referralsPercent = Math.ceil((currentReferralsAmount * 100) / goalAmount);

  return (
    <StyledActivityGoals {...rest}>
      <GoalLabels>
        <GoalsLeft>
          <GoalLabel>{label}</GoalLabel>
          <GoalAmount>{currentTotalAmount?.toLocaleString()}</GoalAmount>
        </GoalsLeft>

        <GoalsRight>
          <GoalLabel>GOAL</GoalLabel>
          <GoalAmount>{goalAmount?.toLocaleString()}</GoalAmount>
        </GoalsRight>
      </GoalLabels>

      <ProgressContainer>
        {/* Placeholder background progress bar simulating not reached yet portion of the goal */}
        <ProgressPlaceholder backgroundColor={progressColor} />
        {/* Total registrations */}
        <Progress backgroundColor={progressColor} value={currentPercent} indicatorPriority />
        {/* Referral registration */}
        <Progress backgroundColor={colors.blue} label="referrals" value={referralsPercent} />
      </ProgressContainer>
    </StyledActivityGoals>
  );
};

ActivityGoals.propTypes = {
  activity: PropTypes.object.isRequired,
  goals: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

ActivityGoals.defaultProps = {
  isMobile: false,
};

export default ActivityGoals;
