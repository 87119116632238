import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IconWrapper } from '@web/components/VoterRegistration/newComponents/Contact';
import HoverCard from '@web/components/VoterRegistration/newComponents/HoverCard';

import Location from '@web/components/VoterRegistration/newComponents/icons/Location';
import Plus from '@web/components/VoterRegistration/newComponents/icons/Plus';

// MTS - We need to define these in order for the :hover selector to work.
const StyledLocation = styled(Location)``;
const StyledPlus = styled(Plus)``;

const StyledIconWrapper = styled(IconWrapper)`
  align-self: flex-start;
  height: 25px;
`;

const Address = styled.p`
  color: #707070;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  transition: color 150ms ease-out;
  color: ${({ $color }) => `${$color}BF`};
`;

const StyledAddressCard = styled(HoverCard)`
  min-height: 115px;
  justify-content: space-between;
  ${StyledLocation} path, ${StyledPlus} path {
    fill: ${({ $color }) => $color ?? '#23293e'};
    fill-opacity: 0.3;
    transition: fill 150ms ease-out, fill-opacity 150ms ease-out;
  }
  ${({ isFanOut }) => {
    if (!isFanOut) {
      return css`
        border: 1px solid ${({ theme, $bgColor }) => $bgColor ?? theme.colors.white};
        svg path {
          fill: ${({ theme, $color }) => $color ?? theme.colors.blue} !important;
        }
        :hover {
          border: 1px solid ${({ theme, $color }) => $color ?? theme.colors.blue};
          ${Address} {
            color: ${({ $color }) => $color};
          }
          ${StyledLocation} path {
            fill: ${({ $color }) => $color};
            fill-opacity: 1;
          }
          ${StyledPlus} path {
            fill-opacity: 1;
          }
        }
      `;
    } else {
      return css`
        ${Address} {
          color: ${({ theme }) => theme.colors.black};
        }
        ${({ picked }) =>
          picked &&
          css`
            border: 1px solid ${({ theme }) => theme.colors.blue};
          `}
      `;
    }
  }}
`;

const Row = styled.div`
  align-items: center;
  display: flex;
`;

const Text = styled.p`
  color: ${({ theme, $color }) => `${$color ?? theme.colors.blackL32}BF`};
  font-size: 1rem;
  margin: 0;
`;

const PickerIndicator = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${({ theme, $picked }) => ($picked ? theme.colors.trueBlack : theme.colors.white)};
  border: 1px solid ${({ theme }) => theme.colors.trueBlack};
  opacity: ${({ $picked }) => ($picked ? 1 : 0.4)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Indicator = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.white};
`;

const AgainText = styled.div`
  color: rgba(35, 41, 62, 0.3);
  font-size: 1rem;
  font-weight: 400;
  line-height: 100%; /* 1rem */
`;

const AddressCard = ({ address, icon, text, isFanOut, picked, fontColor, bgColor, ...rest }) => {
  const { t } = useTranslation();

  const iconMap = {
    location: <StyledLocation />,
    plus: <StyledPlus />,
  };

  return (
    <StyledAddressCard
      $bgColor={bgColor}
      $color={fontColor}
      isFanOut={isFanOut}
      picked={picked}
      {...rest}
    >
      <Row>
        <StyledIconWrapper $color={fontColor}>
          {isFanOut ? (
            <PickerIndicator $picked={picked}>
              <Indicator />
            </PickerIndicator>
          ) : (
            iconMap[icon]
          )}
        </StyledIconWrapper>
        <Text $color={fontColor}>{text}</Text>
      </Row>
      <Row>
        <IconWrapper $color={fontColor} />
        <Address $color={fontColor}>{address}</Address>
      </Row>
      {isFanOut && icon === 'plus' && (
        <Row style={{ marginTop: '10px' }}>
          <IconWrapper />
          <AgainText>{t('check_registration.addressConfirmation.registerAgain')}</AgainText>
        </Row>
      )}
    </StyledAddressCard>
  );
};

AddressCard.propTypes = {
  address: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  fontColor: PropTypes.string,
  icon: PropTypes.oneOf(['location', 'plus']),
  isFanOut: PropTypes.bool,
  picked: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

AddressCard.defaultProps = {
  icon: 'plus',
};

export default AddressCard;
