import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { DownOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';

import { Tooltip } from 'antd';

import theme from '@web/styles/theme';
import { useMedia } from '../../../hooks';

const ScriptsSectionHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px 0;
  width: 100%;
`;

const HideButton = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ visible }) => (visible ? `${theme.fonts.regular}` : `${theme.fonts.semibold}`)};
  color: ${({ visible }) => (visible ? `${theme.colors.black}` : `${theme.colors.coral}`)};
  font-size: 1rem;
  cursor: pointer;
  > span {
    margin-left: 8px;
    > svg {
      width: 13px;
    }
  }

  &:hover {
    color: ${theme.colors.dodgerBlue};
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.regular};
  color: ${theme.colors.darkGray};
  font-size: 1rem;
  > span {
    margin-right: 8px;
  }
`;

const ScriptsSectionHeader = ({ textDisabled, visible, setVisible }) => {
  const showInfoTooltip = useMedia('(max-width: 1000px)');
  const infoText = textDisabled
    ? 'Note you cannot edit this response'
    : "Note you'll be able to edit before sending";

  return (
    <ScriptsSectionHeaderWrapper>
      <HideButton
        visible={visible}
        onClick={() => {
          setVisible(false);
        }}
      >
        {visible ? (
          <>
            Hide follow-up scripts <DownOutlined />
          </>
        ) : (
          <>
            Show follow-up scripts <UpOutlined />
          </>
        )}
      </HideButton>
      <Info>
        {showInfoTooltip ? (
          <Tooltip placement="right" trigger="hover" title={infoText}>
            <InfoCircleOutlined />
          </Tooltip>
        ) : (
          <>
            <InfoCircleOutlined /> {infoText}
          </>
        )}
      </Info>
    </ScriptsSectionHeaderWrapper>
  );
};

ScriptsSectionHeader.propTypes = {
  setVisible: PropTypes.func.isRequired,
  textDisabled: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default ScriptsSectionHeader;
