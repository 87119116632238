import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from 'antd';
import { StyledErrorMessage } from '@web/components/common/shared/FormInput';
import theme from '@web/styles/theme';

const UnderlinedInput = styled(Input)`
  &&& {
    border: 1px solid
      ${({ $outlineColor }) => $outlineColor ?? theme.formDefaultColors.fieldOutlineColor};
    border-radius: 6px;
    color: ${({ $color }) => $color ?? theme.formDefaultColors.fieldFontColor};
    background: ${({ $backgroundColor }) =>
      $backgroundColor ?? theme.formDefaultColors.fieldBackgroundColor};
    height: 40px;

    box-shadow: 0 0 0 30px
      ${({ $backgroundColor }) => $backgroundColor ?? theme.formDefaultColors.fieldBackgroundColor}
      inset !important;
    -webkit-box-shadow: 0 0 0 30px
      ${({ $backgroundColor }) => $backgroundColor ?? theme.formDefaultColors.fieldBackgroundColor}
      inset !important;

    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-autofill:first-line {
      color: ${({ $color }) => $color ?? theme.formDefaultColors.fieldFontColor};
    }

    ::placeholder {
      color: ${({ $outlineColor }) => $outlineColor ?? theme.colors.placeHolderColor};
    }
  }
`;

export function InputFieldFormPartial({ activity, handleChange, name }) {
  const { form_field_outline_color, form_field_background_color, form_field_font_color } =
    activity.settings ?? {};

  return (
    <>
      <UnderlinedInput
        $outlineColor={form_field_outline_color}
        $color={form_field_font_color}
        $backgroundColor={form_field_background_color}
        bordered={false}
        onChange={handleChange}
        name={name}
      />
      <StyledErrorMessage name={name} />
    </>
  );
}

InputFieldFormPartial.propTypes = {
  activity: PropTypes.shape({
    settings: {
      form_field_background_color: PropTypes.string,
      form_field_font_color: PropTypes.string,
      form_field_outline_color: PropTypes.string,
    },
  }),
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
