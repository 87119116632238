import * as React from 'react';
import PropTypes from 'prop-types';

function Link(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0443 8.45645C11.2252 7.63768 10.1144 7.17773 8.9563 7.17773C7.79815 7.17773 6.68741 7.63768 5.8683 8.45645L2.7793 11.5444C1.96018 12.3636 1.5 13.4745 1.5 14.6329C1.5 15.7914 1.96018 16.9023 2.7793 17.7215C3.59842 18.5406 4.70939 19.0007 5.8678 19.0007C7.02621 19.0007 8.13718 18.5406 8.9563 17.7215L10.5003 16.1775"
        stroke="#6CCBDA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.95605 11.5443C9.77516 12.3631 10.8859 12.823 12.0441 12.823C13.2022 12.823 14.3129 12.3631 15.1321 11.5443L18.2211 8.4563C19.0402 7.63718 19.5004 6.52621 19.5004 5.3678C19.5004 4.20939 19.0402 3.09842 18.2211 2.2793C17.4019 1.46018 16.291 1 15.1326 1C13.9741 1 12.8632 1.46018 12.0441 2.2793L10.5001 3.8233"
        stroke="#6CCBDA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Link.propTypes = {
  height: PropTypes.number,
};

Link.defaultProps = {
  height: 20,
};

export default Link;
