import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from '@web/components/common/Icon';
import theme from '@web/styles/theme';
import { Button, message } from 'antd';
import { getShareData } from './utils';

const ClearButton = styled(Button)`
  &&& {
    padding: 0;
    border: none;
    background-color: transparent;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 34px 16px;
  border-bottom: 2px solid black;
`;

const StyledHeader = styled.a`
  margin: 0;
  padding: 0;

  font-family: ${theme.fonts.AccelerateChange};
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;

  color: black;
  :hover {
    color: black;
    text-decoration: none;
  }
`;

const AccelerateChangeHeader = ({ useCurrentUrl, didTheyVoteFlow, isLanding }) => {
  const onShare = () => {
    const shareData = getShareData(useCurrentUrl, didTheyVoteFlow);

    if (window.navigator.share) {
      navigator
        .share({
          text: shareData.messageToShare,
          url: shareData.url,
        })
        .catch(() => {});
    } else {
      navigator?.clipboard
        .writeText(shareData.url)
        .then(() => message.success('Link copied!'))
        .catch(() => message.error('Error copying link'));
    }
  };

  return (
    <Flex>
      <StyledHeader href={didTheyVoteFlow ? '/did_they_vote' : '/did_my_friend_vote'}>
        {didTheyVoteFlow ? 'Did They Vote?' : 'DIDMYFRIENDSVOTE.ORG'}
      </StyledHeader>
      {!isLanding && (
        <ClearButton onClick={onShare}>
          <Icon name="Upload" />
        </ClearButton>
      )}
    </Flex>
  );
};

AccelerateChangeHeader.propTypes = {
  didTheyVoteFlow: PropTypes.bool,
  isLanding: PropTypes.bool,
  useCurrentUrl: PropTypes.bool,
};

export default AccelerateChangeHeader;
