import * as React from 'react';
import PropTypes from 'prop-types';
import { track } from '@web/services/analytics';
import styled from 'styled-components';

import { media } from '@web/styles/theme';
import Banner from '@web/components/Headcount/components/Banner';
import Button from '@web/components/Headcount/components/Button';
import Text from '@web/components/Headcount/components/Text';

import { setHasStateId } from '@web/reducers/voterRegistration';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';
import useVoterRegistrationApi from '@web/components/Headcount/hooks/useVoterRegistrationApi';

const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.wide};
  line-height: 1.5;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.normal};

  button:first-of-type {
    margin-bottom: ${({ theme }) => theme.spacing.normal};
  }
`;

const BackButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;

  ${media.ns} {
    display: block;
    margin-top: ${({ theme }) => theme.spacing.wide};
  }
`;

const ErrorWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.wide};
`;

function StateIdQuestion({ activity, nextPage, previousPage, team, showBanner }) {
  const dispatch = useDispatch();
  const { hasStateId } = useSelector(state => state.voterRegistration);
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const { t } = useTranslation();
  const { handleError, submitContactStateIdUpdate } = useVoterRegistrationApi(activity);
  const isPartner = useIsPartner(team);

  const [apiError, setApiError] = React.useState(null);

  track('HEADCOUNT_VIEW_STATE_ID_QUESTION', {
    activity_id: activity?.id,
    activity_title: activity?.title,
    activity_type: activity?.type,
    campaign_id: activity?.campaign?.id,
    campaign_name: activity?.campaign?.name,
    team_id: team?.id,
    team_name: team?.name,
  });

  function handleButtonClick(value) {
    track('HEADCOUNT_ANSWERED_STATE_ID_QUESTION', {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      team_id: team?.id,
      team_name: team?.name,
      value: value,
    });

    setApiError(null);

    submitContactStateIdUpdate(contactFormResult, value)
      .then(({ data }) => {
        dispatch(setHasStateId(value));
        nextPage();
      })
      .catch(err => {
        const errorMessage = handleError(err);
        setApiError(errorMessage);
      });
  }

  function getButtonVariant(buttonType) {
    // We do three checks here: null, true, and false
    // hasStateId will only not be null if someone navigates
    // back to this component.
    if (hasStateId === null) {
      return 'secondary';
    }
    if (buttonType === 'yes' && hasStateId) {
      return 'primary';
    }
    // MTS - We must use false here or else null will give us a false positive
    if (buttonType === 'no' && hasStateId === false) {
      return 'primary';
    }
    return 'secondary';
  }

  return (
    <>
      {isPartner && showBanner && <Banner activity={activity} />}
      <StyledText variant="f2">{t('check_registration.headcount.idQuestion')}</StyledText>
      <StyledText>{t('check_registration.headcount.idDesc')}</StyledText>
      <ButtonWrapper>
        <Button onClick={() => handleButtonClick(true)} variant={getButtonVariant('yes')}>
          {t('common.yes')}
        </Button>
        <Button onClick={() => handleButtonClick(false)} variant={getButtonVariant('no')}>
          {t('common.no')}
        </Button>

        {apiError && (
          <ErrorWrapper>
            <Text color="red" variant="f3" style={{ marginBottom: '0.5rem' }}>
              {apiError}
            </Text>
          </ErrorWrapper>
        )}
      </ButtonWrapper>
      <BackButtonWrapper>
        <Button onClick={previousPage} style={{ paddingLeft: 0 }} type="button" variant="tertiary">
          {t('common.back')}
        </Button>
      </BackButtonWrapper>
    </>
  );
}

StateIdQuestion.propTypes = {
  activity: PropTypes.object.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  showBanner: PropTypes.bool,
  team: PropTypes.object,
};

StateIdQuestion.defaultProps = {};

export default StateIdQuestion;
