import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import { removeToken } from '../web/services/session';

import App from '../web/components/App';

import '../web/styles/web.scss';

import * as Sentry from '@sentry/browser';

const env = process.env.NODE_ENV?.toLowerCase();
const HEROKU_SLUG_COMMIT = process.env.HEROKU_SLUG_COMMIT;
const SENTRY_ENV = process.env.SENTRY_ENV || 'localhost';
const SOURCE_VERSION = process.env.SOURCE_VERSION;
const SHA = SOURCE_VERSION || HEROKU_SLUG_COMMIT || 'unknown';

// Sentry build release needs to match the one in Sentry.init options
const SENTRY_UNIQUE_RELEASE_NAME = `${SENTRY_ENV}-${SHA}`;

if (env === 'production') {
  Sentry.init({
    dsn: 'https://d15784584c314b30b7798117c9f0c255@o171260.ingest.sentry.io/6584212',
    environment: SENTRY_ENV,
    release: SENTRY_UNIQUE_RELEASE_NAME,
  });
} else {
  // eslint-disable-next-line no-console
  console.log('Sentry turned off - running in ' + env);
}

axios.defaults.headers.common['Content-Type'] = 'applications/json';
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post.accept = 'application/json';

// Set axios interceptors for detecting invalidated/expired token and logging user out
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Unauthorized status code -> log user out
    if (error?.response?.status === 401) {
      message.error(
        <>
          Session expired. Please <a href="/auth">log in</a> again.
        </>,
        15, // message timeout
      );
      removeToken();
    }
    return Promise.reject(error);
  },
);

const node = document.querySelector('#root');

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    node,
  );
});
