import { Button, Col, message, Progress, Row, Skeleton } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { media } from 'web/styles/theme';
import quoteIcon from '../../../../assets/images/web/quote_icon.png';
import { track } from '../../services/analytics';
import { campaigns, user, users } from '../../services/api';
import theme from '../../styles/theme';
import { interpolateMessage } from '../../utils/other';
import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import Container from '../Container';
import BackButton from '../common/BackButton';

const StyledButton = styled(Button)`
  color: ${theme.colors.white};
  font-family: ${theme.fonts.blackItalic};
  font-size: 16px;
  letter-spacing: 1.15px;
  text-align: center;
  text-transform: uppercase;

  ${({ $confirmContactState }) => {
    return (
      $confirmContactState &&
      `
      background: ${theme.colors.green} !important;
      border-color: ${theme.colors.green} !important;
      color: ${theme.colors.white} !important;
    `
    );
  }}

  &&& {
    height: 60px;
    line-height: 56px;
  }
`;

const ProgressBar = styled(Progress)`
  .ant-progress-inner {
    background-color: #ddd;
  }

  &&& {
    .ant-progress-bg,
    .ant-progress-success-bg {
      background-color: ${theme.colors.red};
    }
  }
`;

const ProgressCount = styled.p`
  color: ${theme.colors.lightBlack};
  font-family: ${theme.fonts.bold};
  letter-spacing: 1px;
  margin-bottom: 0;
  opacity: 0.6;
  padding: 0.5em 0;
`;

const Title = styled.h3`
  font-family: ${theme.fonts.semibold};
  font-size: 1.75em;
  margin-bottom: 1em;
`;

const Content = styled.div`
  margin: 2em 0;
`;

const ScriptIcon = styled.img`
  position: absolute;
`;

const ScriptText = styled.p`
  opacity: 0.6;
  padding: 2.5rem 1rem;
  white-space: pre-line;

  ${media.ns} {
    font-size: 1.25rem;
  }
`;

const SuccessMessage = styled.p`
  font-family: ${theme.fonts.black};
  font-size: 1.5em;
  letter-spacing: 1px;
  margin: 1em 0;
  text-align: center;
  text-transform: uppercase;
`;

const ButtonSection = styled.div`
  margin-top: 1rem;
`;

const ScriptContainer = styled.div`
  min-height: 20rem;
`;

const ScriptSkeleton = styled(Skeleton)`
  padding: 3rem 1rem;
`;

const TextAListActivity = () => {
  const { t } = useTranslation();
  const { userActivityId } = useParams();
  const [userActivity, setUserActivity] = useState();
  const { activity: { _type: activityType } = {} } = userActivity || {}; // extract activity type

  const { lastCampaignId, selectedCampaign } = useSelector(state => state.oneCampaign);

  const [loading, setLoading] = useState(true); // when loading initial data
  const [loadingScripts, setLoadingScripts] = useState(true); // when loading scripts for next contact
  const [sending, setSending] = useState(false); // when sending a message

  const [initialScript, setInitialScript] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [contactIndex, setContactIndex] = useState(0);

  const [confirmContactMode, setConfirmContactMode] = useState(false);
  const [isConfirmContactState, setIsConfirmContactState] = useState(false);

  const [activityCompleted, setActivityCompleted] = useState(false);
  const [batchSendInfo, setBatchSendInfo] = useState([]);
  const [batchSendTimeout, setBatchSendTimeout] = useState(null);

  const contact = contactList[contactIndex] || null; // shorthand for accessing current contact
  const contactListExhausted = contactIndex >= contactList.length;

  const textingAllowed = userActivity?.activity?.campaign?.is_allowing_texting;
  const isSendButtonDisabled = !textingAllowed || !contact || loadingScripts || sending;

  const loadData = async () => {
    try {
      // First fetch userActivity data
      const {
        data: { data: activity },
      } = await user.getUserActivity(userActivityId);

      const {
        activity: {
          id: activityId,
          campaign: { p2p_confirm_mode },
          completed,
        },
      } = activity;

      if (completed) {
        setActivityCompleted(true);
      }

      // Next load contacts
      const {
        data: { data: contacts },
      } = await campaigns.getUserContactListContacts({ id: activityId, interpolate: true });

      // Set data
      if (p2p_confirm_mode) {
        setConfirmContactMode(true);
        setIsConfirmContactState(true);
      }

      setUserActivity(activity);
      setContactList(contacts);
      setLoading(false);
    } catch (err) {
      message.error(t('errors.activity_loading_failed'));
    }
  };

  // Load initial data required: userActivity + contactList
  useEffect(() => {
    loadData(userActivityId);
    // eslint-disable-next-line
  }, [userActivityId]);

  // Load scripts for each contacts because interpolation happens on the backend:
  useEffect(() => {
    if (!userActivity || !contact) return;

    const {
      activity: {
        id: activityId,
        campaign: { id: campaignId },
      },
    } = userActivity;

    const fetchScripts = async () => {
      if (contact.hasOwnProperty('activity_script')) {
        setInitialScript(contact.activity_script);
        return setLoadingScripts(false);
      }

      // Get scripts based on campaign, activity and currently selected contact
      setLoadingScripts(true);
      const {
        data: { data: activityScripts },
      } = await campaigns.getActivityScripts(campaignId, activityId, {
        contact_id: contact?.contact_id,
        preview_links: true,
        script_type: 'initial',
      });

      // Extract initial script(s) to be used for messaging and randomize if multiple
      const scripts = Object.values(activityScripts).filter(el => el.script_type === 'initial');
      const script = scripts[Math.floor(Math.random() * scripts.length)];

      setInitialScript(script);
      setLoadingScripts(false);
    };
    fetchScripts();
  }, [userActivity, contact]);

  useEffect(() => {
    const totalToSend = totalContactsToSend();
    if (!totalToSend) {
      return;
    }

    if (batchSendTimeout) {
      clearTimeout(batchSendTimeout);
      setBatchSendTimeout(null);
    }

    const reachedBatchThreshold = totalToSend >= 50;
    if (contactListExhausted || reachedBatchThreshold) {
      sendBatch();
      return;
    }

    const batchTimeout = setTimeout(() => {
      sendBatch();
    }, 2500);

    setBatchSendTimeout(batchTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchSendInfo, contactListExhausted]);

  const addContactForScript = useCallback(
    (scriptId, contact) => {
      if (!scriptId || !contact || !contact.id) {
        return;
      }

      const contactId = contact.id;
      const batchCopy = [...batchSendInfo];
      const existing = batchCopy.find(x => x.script_id === scriptId);
      if (!existing) {
        const newBatch = [
          ...batchCopy,
          {
            contact_ids: [contactId],
            script_id: scriptId,
          },
        ];
        setBatchSendInfo(newBatch);
        return;
      }

      if (!existing.contact_ids) {
        existing.contact_ids = [contactId];
      } else {
        existing.contact_ids.push(contactId);
      }

      setBatchSendInfo(batchCopy);
    },
    [batchSendInfo],
  );

  // Sends message to current contact (if any, and updates indexes for the next contact)
  const handleSendMessage = useCallback(() => {
    if (isSendButtonDisabled) {
      return;
    }

    if (isConfirmContactState) {
      setIsConfirmContactState(false);
      return;
    }

    addContactForScript(initialScript.id, contact);

    setContactIndex(contactIndex + 1);

    if (confirmContactMode) {
      setIsConfirmContactState(true);
    }
  }, [
    addContactForScript,
    confirmContactMode,
    contact,
    contactIndex,
    initialScript?.id,
    isConfirmContactState,
    isSendButtonDisabled,
  ]);

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === 'Enter' || e.key === ' ') {
        handleSendMessage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSendMessage]);

  const sendBatch = () => {
    setSending(true);

    if (!totalContactsToSend()) {
      setSending(false);
      return;
    }

    try {
      users.twilioBatchSend(userActivityId, batchSendInfo).then(() => {});
    } finally {
      setBatchSendInfo([]);
      setSending(false);
    }

    if (!activityCompleted) {
      track('COMPLETED_ACTIVITY', {
        activity_id: userActivity?.activity?.id,
        activity_title: userActivity?.activity?.title,
        campaign_id: userActivity?.activity?.campaign.id,
        campaign_name: userActivity?.activity?.campaign.name,
      });

      user.updateUserActivity(userActivityId, { completed: true });
      setActivityCompleted(true);
    }
  };

  const totalContactsToSend = () => {
    if (!batchSendInfo || !batchSendInfo.length) {
      return 0;
    }

    let count = 0;

    for (let i = 0; i < batchSendInfo.length; i++) {
      const info = batchSendInfo[i];
      count += (info.contact_ids && info.contact_ids.length) || 0;
    }

    return count;
  };

  const capitalizedContactName = contact => {
    const full_name = `${contact.first_name?.trim()} ${contact.last_name?.trim()}`;
    return full_name
      .split(' ')
      .map(el => (el === el.toUpperCase() ? _.capitalize(el) : el))
      .join(' ');
  };

  // Redirect if activity type doesn't match this action
  if (activityType && activityType !== 'text_a_list_activities') {
    message.error(t('errors.activity_type_mismatch'));
    return <Redirect to={`/activities/${userActivityId}`} />;
  }

  if (selectedCampaign?.id && lastCampaignId && selectedCampaign?.id !== lastCampaignId) {
    return <Redirect to={`/frontline`} />;
  }

  if (loading) {
    return (
      <>
        <AppHeader title={t('activity.p2p.header_title')} />
        <AppContainer>
          <Container>{t('common.loading')}</Container>
        </AppContainer>
      </>
    );
  }

  return (
    <>
      <AppHeader title={t('activity.p2p.header_title')} />
      <AppContainer>
        <div className="flex-row justify-content-center">
          <div style={{ maxWidth: '500px', width: '100%' }}>
            <Container>
              <>
                <Row style={{ marginBottom: '1rem' }}>
                  <BackButton />
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Row>
                      <ProgressBar
                        percent={(contactIndex / contactList.length) * 100}
                        showInfo={false}
                      />
                    </Row>
                    <Row>
                      <ProgressCount>{`${contactIndex} ${t('activity.p2p.of')} ${
                        contactList.length
                      } ${t('activity.p2p.messages')}`}</ProgressCount>
                    </Row>
                  </Col>
                </Row>
                {contact && (
                  <Row>
                    <Col span={24}>
                      <Content>
                        <Title>
                          {t('activity.p2p.send_to')} {capitalizedContactName(contact)}
                        </Title>
                        <ScriptIcon src={quoteIcon} width={40} height={40} alt="quote" />
                        <ScriptContainer>
                          {loadingScripts ? (
                            <ScriptSkeleton active title={false} paragraph={{ rows: 6 }} />
                          ) : (
                            <ScriptText>
                              {interpolateMessage(initialScript?.script, contact || 'complete')}
                            </ScriptText>
                          )}
                        </ScriptContainer>
                      </Content>
                      <ButtonSection>
                        <StyledButton
                          block
                          $confirmContactState={isConfirmContactState}
                          type="primary"
                          size="large"
                          loading={sending}
                          disabled={isSendButtonDisabled}
                          onClick={handleSendMessage}
                        >
                          {isConfirmContactState
                            ? `${t('activity.p2p.confirm_send')} ${contact.first_name}`
                            : t('common.send')}
                        </StyledButton>
                      </ButtonSection>
                      {!textingAllowed && (
                        <p>
                          <b>{t('activity.p2p.texting_not_allowed')}</b>{' '}
                          <i>{t('activity.p2p.try_later')}</i>
                        </p>
                      )}
                    </Col>
                  </Row>
                )}

                {!contact && contactList.length > 0 && contactListExhausted && (
                  <Row>
                    <Col span={24}>
                      <Content>
                        <SuccessMessage>{t('activity.p2p.nice_work')}</SuccessMessage>
                        <SuccessMessage>{t('activity.p2p.responses_inbox')}</SuccessMessage>
                      </Content>
                      <ButtonSection>
                        <StyledButton block type="primary" size="large" href="">
                          {t('activity.p2p.keep_texting')}
                        </StyledButton>
                      </ButtonSection>
                    </Col>
                  </Row>
                )}

                {!contact && contactList.length === 0 && (
                  <Row>
                    <Col span={24}>
                      <Content>
                        <SuccessMessage>{t('activity.p2p.no_contacts')} </SuccessMessage>
                        <SuccessMessage>{t('activity.p2p.check_inbox_responses')}</SuccessMessage>
                      </Content>
                      <ButtonSection>
                        <Link to="/messages">
                          <StyledButton block type="primary" size="large">
                            {t('activity.p2p.check_inbox')}
                          </StyledButton>
                        </Link>
                      </ButtonSection>
                    </Col>
                  </Row>
                )}
              </>
            </Container>
          </div>
        </div>
      </AppContainer>
    </>
  );
};

TextAListActivity.propTypes = {};

export default TextAListActivity;
