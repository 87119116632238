import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Checkbox from '@web/components/Headcount/components/Input/Checkbox';
import TermsAndConditions from '@web/components/Headcount/components/FormComponents/TermsAndConditions';

const StyledOptIns = styled.div`
  margin-top: ${({ theme }) => theme.spacing.wide};
`;

function OptIns({ activity, setFieldValue, team, values }) {
  const { t } = useTranslation();

  const headcountRtcEnabled = React.useMemo(
    () => activity?.campaign?.headcount_rct_enabled,
    [activity?.campaign?.headcount_rct_enabled],
  );

  return (
    <StyledOptIns>
      <Checkbox
        checked={values?.smsOptIn}
        name="smsOptIn"
        onClick={() => setFieldValue('smsOptIn', !values?.smsOptIn)}
      >
        {headcountRtcEnabled
          ? t('check_registration.headcount.rct.smsOptIn')
          : t('check_registration.headcount.smsOptIn')}
      </Checkbox>
      <Checkbox
        checked={values?.emailOptIn}
        name="emailOptIn"
        onClick={() => setFieldValue('emailOptIn', !values?.emailOptIn)}
      >
        {headcountRtcEnabled
          ? t('check_registration.headcount.rct.emailOptIn')
          : t('check_registration.headcount.emailOptIn')}
      </Checkbox>
      <TermsAndConditions activity={activity} campaign={activity.campaign} team={team} />
    </StyledOptIns>
  );
}

OptIns.propTypes = {
  activity: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  team: PropTypes.object,
  values: PropTypes.object.isRequired,
};

OptIns.defaultProps = {};

export default OptIns;
