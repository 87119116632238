import { Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fmtNumber } from '../../utils/string';
import ImpactiveLink from '@web/components/common/Link';

const StyledTable = styled(Table)`
  &&& {
    margin-top: 1rem;
    .ant-table {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.black};
      table {
        border-spacing: 0 12px;
      }
    }

    ${'' /* Colors and fonts */}
    tr th {
      background-color: ${({ theme }) => theme.fonts.white};
      font-family: ${({ theme }) => theme.fonts.bold};
      text-transform: uppercase;
    }
    tr {
      background-color: ${({ theme }) => theme.colors.white};
      box-shadow: 8px 8px 32px rgba(0, 41, 64, 0.06);
    }

    td {
      &:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }
      &:last-child {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    tr:hover {
      td {
        background-color: ${({ theme }) => theme.colors.white};
      }
    }

    td:nth-child(1),
    td:nth-child(2) {
      font-family: ${({ theme }) => theme.fonts.bold};
    }
    ${'' /* Remove borders */}
    tr td, tr th {
      border-bottom: none !important;
    }
    ${'' /* Reduce default padding */}
    .ant-table-cell {
      padding: 1.5rem;
    }
    .ant-table-column-sorters {
      padding: 8px;
    }
    .ant-table-column-sorter-full {
      margin-top: -0.5rem;
    }
    ${'' /* Remove sort overlay */}
    td.ant-table-column-sort {
      background: transparent;
    }
    .ant-table-thead {
      > tr {
        box-shadow: none;
        th {
          background: ${({ theme }) => theme.colors.snow};
          padding: 0 12px;
        }
      }
    }
  }
`;

const StyledLink = styled(ImpactiveLink)`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ $start }) => ($start ? 'flex-start' : 'center')};
  font-family: ${({ theme, $bold }) => ($bold ? theme.fonts.bold : theme.fonts.regular)};
  font-size: 1rem;
  color: ${({ theme, $bold }) => ($bold ? theme.colors.blackL72 : theme.colors.black)};
`;

const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ $start }) => ($start ? 'flex-start' : 'center')};
  font-size: 0.75rem;
`;

const TableSection = ({ teams, campaignId }) => {
  const { t } = useTranslation();

  const formattedTeams = teams.map(team => {
    const { points, actions, messages, members } = team;
    return {
      formattedActions: actions ? fmtNumber(actions) : 0,
      formattedMembers: members ? fmtNumber(members) : 0,
      formattedMessages: messages ? fmtNumber(messages) : 0,
      formattedPoints: points ? fmtNumber(points) : 0,
      ...team,
    };
  });

  const tableColumns = [
    {
      dataIndex: 'rank',
      key: 'rank',
      render: rank => <Cell $bold>{rank ? rank : '-'}</Cell>,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (i, j) => i.rank - j.rank,
      title: () => <HeaderCell>{t('campaign.leaderboard.cols.rank')}</HeaderCell>,
      width: 10,
    },
    {
      dataIndex: 'formattedPoints',
      key: 'formattedPoints',
      render: points => <Cell $bold>{points}</Cell>,
      title: () => <HeaderCell>{t('campaign.leaderboard.cols.points')}</HeaderCell>,
    },
    {
      dataIndex: 'name',
      key: 'name',
      render: (name, row) => {
        return (
          <Cell $start>
            <StyledLink to={`/teams/${row.id}?campaign_id=${campaignId}`}>{name}</StyledLink>
          </Cell>
        );
      },
      title: () => <HeaderCell $start>{t('campaign.leaderboard.cols.team_name')}</HeaderCell>,
    },

    {
      dataIndex: 'formattedActions',
      key: 'formattedActions',
      render: actions => <Cell>{actions}</Cell>,
      responsive: ['md'],
      title: () => <HeaderCell>{t('campaign.leaderboard.cols.actions')}</HeaderCell>,
    },
    {
      dataIndex: 'formattedMembers',
      key: 'formattedMembers',
      render: members => <Cell>{members}</Cell>,
      responsive: ['md'],
      title: () => <HeaderCell>{t('campaign.leaderboard.cols.members')}</HeaderCell>,
    },
    {
      dataIndex: 'formattedMessages',
      key: 'formattedMessages',
      render: messages => <Cell>{messages}</Cell>,
      responsive: ['md'],
      title: () => <HeaderCell>{t('campaign.leaderboard.cols.messages')}</HeaderCell>,
    },
  ];

  return (
    <StyledTable
      columns={tableColumns}
      dataSource={formattedTeams}
      rowKey="id"
      pagination={false}
    />
  );
};

TableSection.propTypes = {
  campaignId: PropTypes.number.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TableSection;
