import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Tooltip } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

import theme from '@web/styles/theme';
import { useTranslation } from 'react-i18next';

const RefreshFormIcon = styled.button`
  position: absolute;
  right: ${({ $position }) => ($position ? $position.right : '10px')};
  top: ${({ $position }) => ($position ? $position.top : '5px')};
  border: none;
  padding: 0;
  background: transparent;
  color: ${({ $color }) => $color ?? theme.colors.black};
  stroke-width: 15px;
  stroke: ${({ $color }) => $color ?? theme.colors.black};
`;

const RefreshFormButton = ({ clearData, position, color }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('common.clearTheForm')}>
      <RefreshFormIcon
        $color={color}
        type="button"
        $position={position}
        onClick={clearData}
        as="button"
      >
        <RedoOutlined />
      </RefreshFormIcon>
    </Tooltip>
  );
};

RefreshFormButton.propTypes = {
  clearData: PropTypes.func.isRequired,
  color: PropTypes.string,
  position: PropTypes.shape({
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

export default RefreshFormButton;
