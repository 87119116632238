import React from 'react';
import PropTypes from 'prop-types';

import { Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import styled from 'styled-components';

const SectionTitle = styled.div`
  font-family: ${({ theme, regular }) => (regular ? theme.fonts.regular : theme.fonts.semibold)};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
`;

const Customization = styled.div`
borderGray`;

const TextFieldContainer = styled.div``;

const SelectionFieldRadio = styled.div``;

const RadioStyled = styled(Radio)`
  &&& {
    margin: 10px 5px 10px 0;
    font-family: ${({ theme, regular }) => (regular ? theme.fonts.regular : theme.fonts.semibold)};
    color: ${({ theme }) => theme.colors.black};
    font-size: 1rem;
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  &&& {
    display: flex;
    flex-direction: column;
  }
`;

const CustomFieldsList = ({ customizations, setUserReportCustomizations }) => {
  const onCustomFieldTextUpdate = (selectedSlug, e) => {
    const updatedFields = customizations.map(field => {
      if (field.slug !== selectedSlug) {
        return field;
      }
      return {
        ...field,
        // When the report is empty, the field's default value is null,
        // so if the user has filled in something and then cleared the field,
        // we'll need the same value as the initial to disable the button
        value: e.target.value !== '' ? e.target.value : null,
      };
    });
    setUserReportCustomizations(updatedFields);
  };

  const onCustomFieldSelectUpdate = (selectedSlug, val) => {
    const updatedFields = customizations.map(field => {
      if (field.slug !== selectedSlug) {
        return field;
      }
      return {
        ...field,
        value: val,
      };
    });
    setUserReportCustomizations(updatedFields);
  };

  const handleRadioClick = (field, value) => {
    if (field.value === value) {
      onCustomFieldSelectUpdate(field.slug, null);
    }
  };
  return customizations.map((field, i) => {
    if (
      (field.type === 'SelectionField' && field.available_values.length > 0) ||
      field.type === 'TextField'
    ) {
      return (
        <Customization className="py-3" key={field.slug}>
          {field.type === 'TextField' && (
            <TextFieldContainer>
              <SectionTitle className="pb-2">{field.prompt || field.name}</SectionTitle>

              <TextArea
                name={field.slug}
                value={field.value}
                autoSize={{ minRows: 4 }}
                onChange={e => onCustomFieldTextUpdate(field.slug, e)}
              />
            </TextFieldContainer>
          )}

          {field.type === 'SelectionField' && (
            <SelectionFieldRadio>
              <SectionTitle className="pb-2">{field.prompt || field.name}</SectionTitle>
              <StyledRadioGroup
                onChange={e => onCustomFieldSelectUpdate(field.slug, e.target.value)}
                value={field.value}
              >
                {field.available_values.map((val, fieldIndex) => (
                  <RadioStyled
                    onClick={() => {
                      handleRadioClick(field, val);
                    }}
                    key={fieldIndex}
                    regular={field.value !== val}
                    value={val}
                  >
                    {val}
                  </RadioStyled>
                ))}
              </StyledRadioGroup>
            </SelectionFieldRadio>
          )}
        </Customization>
      );
    }
    return null;
  });
};

CustomFieldsList.propTypes = {
  customizations: PropTypes.array.isRequired,
  setUserReportCustomizations: PropTypes.func.isRequired,
};

export default CustomFieldsList;
