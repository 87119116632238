import { string, node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import theme, { media } from '@web/styles/theme';

const NoteOuter = styled.div`
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  margin: 1em 0;
`;

const NoteInner = styled.div`
  border: 2px dashed #dddddd;
  border-radius: 5px;
  display: flex;
  padding: 1em;
  align-items: center;

  ${media.nxl} {
    flex-wrap: wrap;
  }
`;

const NoteTitle = styled.div`
  color: ${theme.colors.black};
  font-family: ${theme.fonts.black};
  font-size: 1.25em;
  letter-spacing: 1.35px;
  margin-bottom: 0.5em;
`;

const NoteBody = styled.div`
  color: ${theme.colors.darkBlack};
  font-family: ${theme.fonts.light};
  font-size: 1em;
  opacity: 0.65;
`;

const Note = ({ title, children, actions }) => (
  <NoteOuter>
    <NoteInner>
      <div>
        <NoteTitle>{title}</NoteTitle>
        <NoteBody>{children}</NoteBody>
      </div>
      {actions}
    </NoteInner>
  </NoteOuter>
);

Note.propTypes = {
  actions: node,
  children: node,
  title: string,
};

Note.defaultProps = {
  actions: undefined,
  children: undefined,
  title: '',
};

export default Note;
