// For descriptions of metrics see: https://github.com/outvote/web-app/issues/5789
import * as React from 'react';
import axios from 'axios';
import Pusher from 'pusher-js';
import sendError from '@web/utils/sendError';
import useBatch from './useBatch';

const pusher = new Pusher(process.env.PUSHER_APP_KEY, {
  cluster: process.env.PUSHER_APP_CLUSTER,
});

const CALLS_KEY = 'valid_authorised_voice_calls_count';
const TOTAL_CALLS_KEY = 'voter_calls_attempted_count';

function useQueueCalls(activityId) {
  const [callsQueued, setCallsQueued] = React.useState(0);
  const [totalCalls, setTotalCalls] = React.useState(0);
  const channelName = `activity;${activityId};voice-call-pool`;
  const { incrementBatch } = useBatch(queueCall);

  React.useEffect(() => {
    const thisChannel = pusher.subscribe(channelName);
    subscriptions(thisChannel);
    getMetrics();
    return () => pusher.unsubscribe(channelName);
    // eslint-disable-next-line
  }, []);

  function subscriptions(channel) {
    channel.bind('metrics', handleMetrics);
  }

  function handleMetrics(data) {
    setTotalCalls(data[TOTAL_CALLS_KEY]);
    setCallsQueued(data[CALLS_KEY]);
  }

  function handleClick() {
    incrementBatch();
    setCallsQueued(callsQueued + 100);
  }

  function getMetrics() {
    const url = `/api/v2/activities/${activityId}/voice_call_pool/metrics`;
    axios(url)
      .then(({ data }) => {
        handleMetrics(data);
      })
      .catch(() => {
        // MTS - We don't need to do anything here because we are also getting
        // Pusher messages with this data.
      });
  }

  function queueCall(count) {
    const url = `/api/v2/activities/${activityId}/voice_call_pool/authorizations`;
    axios
      .post(url, { authorizations_count: count || 100 })
      .then(({ data }) => {
        setCallsQueued(data.authorised_voice_calls);
      })
      .catch(err => {
        sendError('Error queueing calls', err);
      });
  }

  return { callsQueued, handleClick, totalCalls };
}

export default useQueueCalls;
