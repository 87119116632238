import * as React from 'react';
import PropTypes from 'prop-types';

import HeaderAndProgress from '@web/components/Headcount/components/HeaderAndProgress';
import ContactForm from '@web/components/Headcount/components/FormComponents/ContactForm';
import ReferralShowLink from './pages/ReferralShowLink';

import usePageNavigation from '@web/components/Headcount/hooks/usePageNavigation';
import useContactForm from '@web/components/Headcount/hooks/useContactForm';
import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';

import { useTranslation } from 'react-i18next';

function WantToHelp({ activity, backToStart, setRootFlow, team }) {
  const { t } = useTranslation();
  const { schemas } = useContactForm();
  const pages = [ContactForm, ReferralShowLink];
  const headcountMtvEnabled = React.useMemo(
    () => activity?.campaign?.headcount_mtv_enabled,
    [activity?.campaign?.headcount_mtv_enabled],
  );
  const { currentPage, progress, setCurrentPage, numberOfPages } = usePageNavigation(
    pages.length,
    headcountMtvEnabled,
  );
  const isPartner = useIsPartner(team);
  const cannabisRegistrationEnabled = React.useMemo(
    () => activity?.campaign?.cannabis_registration_enabled,
    [activity?.campaign?.cannabis_registration_enabled],
  );

  function contactFormTitle() {
    if (headcountMtvEnabled) {
      return t('check_registration.headcount.mtvSubtitle.wantToHelp');
    }
    if (cannabisRegistrationEnabled) {
      return t('check_registration.headcount.cannabisSubtitle.wantToHelp');
    }
    if (isPartner) {
      return t('check_registration.headcount.partnerSubtitle.wantToHelp');
    }

    return t('check_registration.headcount.subtitle.getElectionDetails');
  }

  const pageProps = {
    activity,
    backToStart,
    contactFormSchema: schemas.referralFormHeadcount,
    contactFormTitle: contactFormTitle(),
    nextPage: () => setCurrentPage(1),
    registrationStatus: 'Unable to Vote',
    setRootFlow,
    team,
  };

  const CurrentPage = pages[currentPage];
  return (
    <>
      <HeaderAndProgress
        activity={activity}
        progress={progress}
        numberOfPages={numberOfPages}
        team={team}
      />
      <CurrentPage {...pageProps} />
    </>
  );
}

WantToHelp.propTypes = {
  activity: PropTypes.object.isRequired,
  backToStart: PropTypes.func.isRequired,
  setRootFlow: PropTypes.func.isRequired,
  team: PropTypes.object,
};

WantToHelp.defaultProps = {
  team: null,
};

export default WantToHelp;
