import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledAnswerBlock = styled.div`
  background-color: #eee;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  padding: 14px 18px 25px;
  width: 100%;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
`;

function AnswerBlock({ children }) {
  const { t } = useTranslation();

  return (
    <StyledAnswerBlock>
      <Text>{t('activity.phone_bank.recipient_response')}</Text>
      {children}
    </StyledAnswerBlock>
  );
}

AnswerBlock.propTypes = {
  children: PropTypes.any.isRequired,
};

AnswerBlock.defaultProps = {};

export default AnswerBlock;
