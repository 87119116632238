import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Input } from 'antd';
import RadioGroup from './RadioGroup';

const Label = styled.label`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 0.25rem;
`;

const TextField = styled.div`
  margin-bottom: 1rem;
`;

function CustomFieldList({ answers, customFields, handleAnswerChange }) {
  if (!customFields) {
    return null;
  }

  function changeHandler(el, value) {
    handleAnswerChange('customField', { ...el, value });
  }

  function renderComponent(el) {
    const value = answers.find(answer => el.id === answer.id)?.value || '';
    const { name, prompt, type } = el.custom_field;
    switch (type) {
      case 'SelectionField':
        return (
          <RadioGroup
            key={el.id}
            onChange={e => changeHandler(el, e.target.value)}
            options={el.custom_field.available_values}
            title={prompt || name}
            value={value}
          />
        );
      case 'TextField':
        return (
          <TextField key={el.id}>
            <Label htmlFor={el.id}>{prompt || name}</Label>
            <Input id={el.id} onChange={e => changeHandler(el, e.target.value)} value={value} />
          </TextField>
        );
      default:
        return null;
    }
  }
  return (
    <div>
      {customFields.map(el => (
        <div key={el.id}>{renderComponent(el)}</div>
      ))}
    </div>
  );
}

CustomFieldList.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    }),
  ),
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      available_values: PropTypes.array,
      id: PropTypes.number.isRequired,
      prompt: PropTypes.string.isRequired,
    }),
  ),
  handleAnswerChange: PropTypes.func.isRequired,
};

CustomFieldList.defaultProps = {};

export default CustomFieldList;
