import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '.';

import { largeBreakpoint } from '../breakpoints';

const StyledBackButton = styled(Button)`
  &&& {
    color: #707070;
    font-weight: 700;
    ${largeBreakpoint} {
      justify-content: flex-start;
    }
  }
`;

function BackButton({ children, showIcon, ...rest }) {
  return (
    <StyledBackButton {...rest} variant="link">
      {showIcon && (
        <svg
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginRight: 5, transform: 'translateY(1px)' }}
        >
          <path
            d="M7.5 2L3.5 6L7.5 10"
            stroke="#707070"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {children}
    </StyledBackButton>
  );
}

BackButton.propTypes = {
  children: PropTypes.any,
  showIcon: PropTypes.bool,
};

BackButton.defaultProps = {
  children: null,
  showIcon: true,
};

export default BackButton;
