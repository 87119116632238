import * as React from 'react';
import PropTypes from 'prop-types';

import ContactForm from '@web/components/Headcount/components/FormComponents/ContactForm';
import HeaderAndProgress from '@web/components/Headcount/components/HeaderAndProgress';
import RegisterToVoteResult from '@web/components/Headcount/flows/RegisterToVote/pages/RegisterToVoteResult';
import StateIdQuestion from '@web/components/Headcount/flows/RegisterToVote/pages/StateIdQuestion';
import StateIdQuestionWithAddress from '@web/components/Headcount/flows/RegisterToVote/pages/StateIdQuestionWithAddress';
import NotRegistered from '@web/components/Headcount/flows/CheckRegistration/pages/NotRegistered';
import RegisteredLocation from './pages/RegisteredLocation';
import SelectVoter from './pages/SelectVoter';
import UpdateAddressResult from './pages/UpdateAddressResult';
import VoteFromAddressResult from './pages/VoteFromAddressResult';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { init as voterRegistrationInit } from '@web/reducers/voterRegistration';
import useContactForm from '@web/components/Headcount/hooks/useContactForm';
import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';
import usePageNavigation from '@web/components/Headcount/hooks/usePageNavigation';
import VoteByMailResult from './pages/VoteByMailResult';

// This ensures the progress bar will perform correctly
const Placeholder = () => <div />;

const baseFlow = [ContactForm, SelectVoter, RegisteredLocation, Placeholder];
// We replace SelectVoter with NotRegistered if we do not receive any results from
// the possible_voters endpoint.
const notRegisteredFlow = [ContactForm, NotRegistered, StateIdQuestion, RegisterToVoteResult];
const updateAddressFlow = [
  ContactForm,
  SelectVoter,
  RegisteredLocation,
  StateIdQuestionWithAddress,
  UpdateAddressResult,
];
const voteByMailFlow = [ContactForm, SelectVoter, RegisteredLocation, VoteByMailResult];
const voteFromAddressFlow = [ContactForm, SelectVoter, RegisteredLocation, VoteFromAddressResult];

function CheckRegistration({ activity, backToStart, setRootFlow, team }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { schemas } = useContactForm();
  const [selectedFlow, setSelectedFlow] = React.useState(baseFlow);
  const [showBanner, setShowBanner] = React.useState(true);

  const headcountMtvEnabled = React.useMemo(
    () => activity?.campaign?.headcount_mtv_enabled,
    [activity?.campaign?.headcount_mtv_enabled],
  );

  const pageNavigation = usePageNavigation(selectedFlow.length, headcountMtvEnabled);
  const { currentPage } = pageNavigation;
  const isPartner = useIsPartner(team);
  const cannabisRegistrationEnabled = React.useMemo(
    () => activity?.campaign?.cannabis_registration_enabled,
    [activity?.campaign?.cannabis_registration_enabled],
  );
  const headcountRtcEnabled = React.useMemo(
    () => activity?.campaign?.headcount_rct_enabled,
    [activity?.campaign?.headcount_rct_enabled],
  );

  React.useEffect(() => {
    if (currentPage === 0) {
      setShowBanner(true);
      dispatch(voterRegistrationInit());
    }
  }, [currentPage, dispatch]);

  function notRegistered(showBanner) {
    setSelectedFlow(notRegisteredFlow);
    setShowBanner(showBanner);
  }

  function voteFromAddress() {
    setSelectedFlow(voteFromAddressFlow);
  }

  function updateAddress() {
    setSelectedFlow(updateAddressFlow);
  }

  function voteByMail() {
    setSelectedFlow(voteByMailFlow);
  }

  function contactFormTitle() {
    if (headcountRtcEnabled) {
      return t('check_registration.headcount.rct.formTitle');
    }

    if (headcountMtvEnabled) {
      return t('check_registration.headcount.mtvSubtitle.checkRegistration');
    }
    if (cannabisRegistrationEnabled) {
      return t('check_registration.headcount.cannabisSubtitle.checkRegistration');
    }
    if (isPartner) {
      return t('check_registration.headcount.partnerSubtitle.checkRegistration');
    }

    return t('check_registration.headcount.subtitle.getReadyToVote');
  }

  const pageProps = {
    activity,
    backToStart,
    contactFormSchema: schemas.contactFormHeadcount,
    contactFormTitle: contactFormTitle(),
    notRegistered,
    registrationStatus: 'Unregistered',
    setRootFlow,
    setShowBanner,
    showBanner,
    team,
    updateAddress,
    voteByMail,
    voteFromAddress,
    ...pageNavigation,
  };

  const CurrentPage = selectedFlow[currentPage];
  return (
    <>
      <HeaderAndProgress
        activity={activity}
        progress={pageNavigation.progress}
        numberOfPages={pageNavigation.numberOfPages}
        team={team}
      />
      <CurrentPage {...pageProps} />
    </>
  );
}

CheckRegistration.propTypes = {
  activity: PropTypes.object.isRequired,
  backToStart: PropTypes.func.isRequired,
  setRootFlow: PropTypes.func.isRequired,
  team: PropTypes.object,
};

CheckRegistration.defaultProps = {
  team: null,
};

export default CheckRegistration;
