import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import CampaignRightSidebar from 'web/components/common/CampaignRightSidebar';
import { media } from 'web/styles/theme';
import Activity from '../Activity';
import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import Loading from '../common/Loading';
import SwitchCampaignAlert from '../common/SwitchCampaignAlert';

const ActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  ${media.ns} {
    width: 700px;
    height: 100%;
  }
`;

const ActivityAndCampaign = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  ${media.ns} {
    align-items: normal;
    flex-direction: row;
  }
`;

const ActivityDetailCore = ({
  title,
  appContainerDataTestId,
  activity,
  completed,
  headerTitle,
  userActivity,
  campaign,
  setCampaign,
  onActivityActionButtonPress,
  loading,
}) => {
  return (
    <>
      <AppHeader title={title} />
      <AppContainer data-testid={appContainerDataTestId}>
        {loading && (
          <div className="flex-row justify-content-center mt-4">
            <Loading />
          </div>
        )}
        {!loading && (
          <>
            <ActivityAndCampaign>
              <ActivityContainer>
                <SwitchCampaignAlert campaign={campaign} onSwitchCampaign={setCampaign} />
                <Activity
                  activity={activity}
                  completed={completed}
                  detailedView
                  headerTitle={headerTitle}
                  userActivity={userActivity}
                  onActivityActionButtonPress={onActivityActionButtonPress}
                />
              </ActivityContainer>
              <CampaignRightSidebar campaignId={campaign.id} />
            </ActivityAndCampaign>
          </>
        )}
      </AppContainer>
    </>
  );
};

ActivityDetailCore.propTypes = {
  activity: PropTypes.object,
  appContainerDataTestId: PropTypes.string,
  campaign: PropTypes.object,
  completed: PropTypes.bool,
  headerTitle: PropTypes.string,
  loading: PropTypes.bool,
  onActivityActionButtonPress: PropTypes.func,
  setCampaign: PropTypes.func,
  title: PropTypes.string,
  userActivity: PropTypes.object,
};

export default ActivityDetailCore;
