import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@web/components/VoterRegistration/newComponents/Button';
import BackButton from '@web/components/VoterRegistration/newComponents/Button/BackButton';
import Reason from './Reason';
import { Title } from '@web/components/VoterRegistration/newComponents/TextStyles';

// Icons
import ABCheck from '@web/components/VoterRegistration/newComponents/icons/ABCheck';
import Clock from '@web/components/VoterRegistration/newComponents/icons/Clock';
import Contact from '@web/components/VoterRegistration/newComponents/icons/Contact';
import Location from '@web/components/VoterRegistration/newComponents/icons/Location';
import MessageBox from '@web/components/VoterRegistration/newComponents/MessageBox';

import {
  checkRegistration,
  fanOutRegistration,
} from '@web/components/VoterRegistration/hooks/useFlows';
import { init } from '@web/reducers/voterRegistration';
import { largeBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import { track } from '@web/services/analytics';
import { capitalize } from '@web/utils/string';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { vrDefaultColors } from '@web/styles/theme';

const StyledNotRegistered = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const Push = styled.div`
  margin-top: 80px;
  flex: 1;
`;

const StyledMessageBox = styled(MessageBox)`
  margin: 40px 0 20px;
  background: ${({ $background }) => ($background ? `${$background}40` : undefined)};
`;

const Row = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 1rem;

  ${largeBreakpoint} {
    flex-direction: row;
    justify-content: space-between;
    button {
      max-width: 250px;
    }
  }
`;

const Ul = styled.ul`
  margin: 0;
  margin-left: -0.5rem;
  padding: 0;
`;

const KeepGoingText = styled.div`
  color: ${({ theme }) => theme.colors.trueBlack};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */

  > span {
    text-decoration: underline;
    text-transform: capitalize;
  }
`;

const Text = styled.p`
  font-size: 1rem;
  color: ${({ $fontColor }) => $fontColor};
`;

const StyledBackButton = styled(BackButton)`
  text-transform: ${({ isFanOut }) => (isFanOut ? 'capitalize' : 'none')};
`;

function NotRegistered({ nextPage, previousPage, setSelectedFlow, isFanOut, referrer, ...rest }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { activity, team } = rest;

  React.useEffect(() => {
    if (!isFanOut) {
      track('VR_VIEW_CHECK_REGISTRATION_NOT_REGISTERED', {
        activity_id: activity?.id,
        activity_title: activity?.title,
        activity_type: activity?.type,
        campaign_id: activity?.campaign?.id,
        campaign_name: activity?.campaign?.name,
        team_id: team?.id,
        team_name: team?.name,
      });
    }
    // eslint-disable-next-line
  }, []);

  function handleBack() {
    if (isFanOut) {
      setSelectedFlow(fanOutRegistration);
    } else {
      setSelectedFlow(checkRegistration);
      // MTS - We were only initializing the form when a user closed the modal
      // on the last page but due to the input mask bug we'll need to run init
      // everytime.
      dispatch(init());
    }

    previousPage();
  }

  const title = React.useMemo(
    () =>
      isFanOut
        ? t('check_registration.notRegistered.descriptionFanOut')
        : t('check_registration.notRegistered.description'),
    [isFanOut, t],
  );
  const reason1 = React.useMemo(() => t('check_registration.notRegistered.reason1'), [t]);
  const reason2 = React.useMemo(
    () =>
      isFanOut
        ? t('check_registration.notRegistered.reason2FanOut')
        : t('check_registration.notRegistered.reason2'),
    [isFanOut, t],
  );
  const reason3 = React.useMemo(
    () =>
      isFanOut
        ? t('check_registration.notRegistered.reason3FanOut')
        : t('check_registration.notRegistered.reason3'),
    [isFanOut, t],
  );
  const reason4 = React.useMemo(
    () =>
      isFanOut
        ? t('check_registration.notRegistered.reason4FanOut')
        : t('check_registration.notRegistered.reason4'),
    [isFanOut, t],
  );
  const isFanOutReferrer = React.useMemo(() => referrer && isFanOut, [referrer, isFanOut]);

  const fontColor = React.useMemo(
    () => activity?.settings?.font_color ?? vrDefaultColors.fontColor,
    [activity?.settings?.font_color],
  );

  const sectionColor = React.useMemo(
    () =>
      isFanOut ? null : activity?.settings?.progress_bar_color ?? vrDefaultColors.progressBarColor,
    [activity?.settings?.progress_bar_color, isFanOut],
  );

  return (
    <StyledNotRegistered>
      <Title $fontColor={fontColor}>{t('check_registration.notRegistered.title')}</Title>
      <StyledMessageBox $background={sectionColor}>
        <Text $fontColor={fontColor}>{title}</Text>
        <Ul $fontColor={fontColor}>
          <Reason fontColor={fontColor} icon={<Contact />} text={reason1} />
          <Reason fontColor={fontColor} icon={<ABCheck />} text={reason2} />
          <Reason fontColor={fontColor} icon={<Clock />} text={reason3} />
          <Reason fontColor={fontColor} icon={<Location />} text={reason4} />
        </Ul>
      </StyledMessageBox>
      {isFanOutReferrer && (
        <KeepGoingText
          dangerouslySetInnerHTML={{
            __html: t('check_registration.notRegistered.keepGoing', {
              name: capitalize(referrer?.fullname || ''),
            }),
          }}
        />
      )}
      <Push />
      <Row>
        <StyledBackButton
          customVariant={isFanOut ? null : activity.settings}
          isFanOut={isFanOut}
          onClick={handleBack}
          variant="link"
        >
          {t('check_registration.search_results.search_again')}
        </StyledBackButton>
        <Button
          isFanOut={isFanOut}
          customVariant={isFanOut ? null : activity.settings}
          onClick={nextPage}
          variant={isFanOut ? 'fan_out' : 'blue'}
        >
          {t('check_registration.notRegistered.registerToVote')}
        </Button>
      </Row>
    </StyledNotRegistered>
  );
}

NotRegistered.propTypes = {
  activity: PropTypes.object.isRequired,
  isFanOut: PropTypes.bool,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  referrer: PropTypes.shape({
    fullname: PropTypes.string,
    id: PropTypes.number,
  }),
  setSelectedFlow: PropTypes.func.isRequired,
  team: PropTypes.object,
};

NotRegistered.defaultProps = {
  team: null,
};

export default NotRegistered;
