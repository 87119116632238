import React from 'react';

import { Form, Formik } from 'formik';
import FormField from '@web/components/common/shared/FormField';
import Loading from '@web/components/common/Loading';
import { FieldsWrapper } from '../../../components/shared';
import ContentWrapper from '../../../components/ContentWrapper';
import ViewTags from '../../../components/ReportFormHelpers/ViewTags';
import ViewCustomFields from '../../../components/ReportFormHelpers/ViewCustomFields';
import FormFooter from '../../../components/ReportFormHelpers/FormFooter';

import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const phoneRegExp =
  /^((\\ +[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ViewVoterForm = ({ onSubmit, tags, customFields, voter, redirectToFindVotersPage }) => {
  const { t } = useTranslation();

  const ReportFormSchema = yup.object().shape({
    email: yup.string().email(t('idvoters.validation.email')).notRequired(),
    phone: yup.string().matches(phoneRegExp, t('idvoters.validation.phone')).notRequired(),
  });

  return (
    <ContentWrapper title={t('add_report.fill_report.title')}>
      {!tags || !customFields ? (
        <Loading />
      ) : (
        <Formik
          initialValues={{
            customFields,
            email: '',
            firstName: voter['vb.tsmart_first_name'] || '',
            lastName: voter['vb.tsmart_last_name'] || '',
            phone: '',
            tags,
            voterId: voter.voterbase_id,
          }}
          onSubmit={onSubmit}
          validationSchema={ReportFormSchema}
          render={({ values, errors, submitCount, dirty, isSubmitting, setFieldValue }) => (
            <Form>
              <FieldsWrapper $twoColumns>
                <FormField
                  label={t('add_report.form.phone')}
                  name="phone"
                  placeholder={t('add_report.form.phone')}
                />
                <FormField
                  label={t('add_report.form.email')}
                  name="email"
                  placeholder={t('add_report.form.email')}
                />
              </FieldsWrapper>
              <ViewTags setFieldValue={setFieldValue} tags={values.tags} />
              <ViewCustomFields customFields={values.customFields} setFieldValue={setFieldValue} />
              <FormFooter
                disabled={!!errors.phone || !!errors.email || !dirty || isSubmitting}
                submitCount={submitCount}
                addAnotherReport={redirectToFindVotersPage}
              />
            </Form>
          )}
        />
      )}
    </ContentWrapper>
  );
};

ViewVoterForm.propTypes = {
  customFields: PropTypes.arrayOf(PropTypes.shape({})),
  onSubmit: PropTypes.func.isRequired,
  redirectToFindVotersPage: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  voter: PropTypes.shape({
    'vb.tsmart_first_name': PropTypes.string,
    'vb.tsmart_last_name': PropTypes.string,
    voterbase_id: PropTypes.string,
  }).isRequired,
};

export default ViewVoterForm;
