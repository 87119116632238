import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Error from '../icons/Error';
import theme from '@web/styles/theme';

const StyledErrorText = styled.p`
  align-items: center;
  color: ${theme.colors.red};
  display: flex;
  font-size: 14px;
  margin-bottom: 0;
`;

function ErrorText({ children }) {
  return (
    <StyledErrorText>
      <Error height={12} style={{ marginRight: 5 }} />
      <span>{children}</span>
    </StyledErrorText>
  );
}

ErrorText.propTypes = {
  children: PropTypes.any.isRequired,
};

ErrorText.defaultProps = {};

export default ErrorText;
