import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Header, StyledButton, StyledInput } from '../Shared';

const Confirmation = ({ onCodeSubmit }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');

  const onSubmitHandler = e => {
    if (e) e.preventDefault();

    onCodeSubmit(code);
  };

  return (
    <Wrapper>
      <Header>{t('auth.forgot.confirmation_header')}</Header>
      <form data-testid="form" onSubmit={onSubmitHandler}>
        <StyledInput
          autoFocus
          placeholder={t('auth.forgot.confirmation_code')}
          onChange={e => setCode(e.target.value)}
          value={code}
        />
        <StyledButton type="primary" htmlType="submit" disabled={!code}>
          {t('common.submit')}
        </StyledButton>
      </form>
    </Wrapper>
  );
};

Confirmation.propTypes = {
  onCodeSubmit: PropTypes.func.isRequired,
};

export default Confirmation;
