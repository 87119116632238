import { PhoneOutlined, MailOutlined, LoadingOutlined } from '@ant-design/icons';
import { Checkbox, message, Row, Col, Spin } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Radio, Select } from 'formik-antd';
import toLower from 'lodash/toLower';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import i18n from 'web/components/i18n';
import states from 'web/data/states';
import * as yup from 'yup';
import { track, trackPageViewWithDynamicIDs } from '../../services/analytics';
import { users, campaigns, teams } from '../../services/api';
import ImpactiveButton from '../ImpactiveButton';
import Note from '../Note';
import VoterRegistrationTemplate from '../templates/VoterRegistrationTemplate';
import {
  SplashWrapper,
  SplashTitle,
  SplashDescription,
  BigRoundButton,
  CannotVoteLink,
  FormContainer,
  StyledRadio,
  SearchResults,
  SearchResultsTitle,
  SearchResultsDescription,
  VoterTable,
  VoterNameCell,
  VoterName,
  VoterAddress,
  VoterAge,
  ButtonsRow,
  TermsAndConditions,
} from './styles';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SearchVotersFormSchema = yup.object().shape({
  ageRange: yup.string().required(i18n.t('idvoters.validation.age')),
  city: yup.string(),
  email: yup.string().email(i18n.t('check_registration.invalid_email')),
  firstName: yup.string().required(i18n.t('idvoters.validation.firstname')),
  lastName: yup.string().required(i18n.t('idvoters.validation.lastname')),
  phone: yup.string().matches(phoneRegExp, i18n.t('check_registration.invalid_phone')),
  stateAbbv: yup.string().required(i18n.t('idvoters.validation.state')),
  zipCode: yup.number(i18n.t('idvoters.validation.zip')),
});

const ContactFormSchema = yup.object().shape({
  email: yup.string().email(i18n.t('check_registration.invalid_email')),
  firstName: yup.string(),
  lastName: yup.string(),
  phone: yup.string().matches(phoneRegExp, i18n.t('check_registration.invalid_phone')),
});

const voterNameFormatter = (_, record) => (
  <VoterName>
    {toLower(record['vb.tsmart_first_name'])} {toLower(record['vb.tsmart_last_name'])}
  </VoterName>
);

const voterAddressFormatter = (_, record) => (
  <VoterAddress>
    {toLower(record['vb.tsmart_city'])}, {record['vb.tsmart_state']}
  </VoterAddress>
);

const voterAgeFormatter = (_, record) => {
  const { 'vb.voterbase_age': age } = record;
  return <VoterAge>{!!age && i18n.t('idvoters.results.years_old', { age })}</VoterAge>;
};

const voterTableColumns = [
  {
    key: 'name',
    render: (_, record) => (
      <VoterNameCell>
        {voterNameFormatter(_, record)}
        {voterAddressFormatter(_, record)}
        {voterAgeFormatter(_, record)}
      </VoterNameCell>
    ),
    title: 'Name',
  },
  {
    key: 'address',
    render: voterAddressFormatter,
    responsive: ['md'],
    title: 'Address',
  },
  {
    dataIndex: 'vb.voterbase_age',
    key: 'age',
    render: voterAgeFormatter,
    responsive: ['md'],
    title: 'Age',
  },
];

const formInitialValues = {
  ageRange: '18-255',
  city: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  stateAbbv: '',
  zipCode: '',
};

const ScreenTypes = {
  NEW_VOTER: 'new_voter',
  SEARCH_VOTER: 'search_voter',
  SIGNUP_TO_HELP: 'signup_to_help',
  SPLASH: 'splash',
};

const CheckRegistration = props => {
  const { campaignId } = props.match.params; // can be either campaign `id` or `slug`
  const { contact_id, contact_referral_id, h, referral_hash } = queryString.parse(
    props.location.search,
  );
  const { t } = useTranslation();
  const history = useHistory();

  const [campaign, setCampaign] = useState({});
  const [team, setTeam] = useState();
  const [loading, setLoading] = useState(true);
  const [voters, setVoters] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const [searched, setSearched] = useState(false);
  const [selectedVoterId, setSelectedVoterId] = useState();
  const [optIn, setOptIn] = useState(true);
  const searchRef = useRef(null);
  const formRef = useRef(null);
  const [screenType, setScreenType] = useState(ScreenTypes.SPLASH);
  const isSearchScreen = screenType === ScreenTypes.SEARCH_VOTER;
  const isSignupToHelpScreen = screenType === ScreenTypes.SIGNUP_TO_HELP;
  const user_referral_id = team ? team.id : undefined;

  useEffect(() => {
    track('VIEW_CHECK_REGISTRATION');
    setLoading(true);
    campaigns
      .getPublicCampaign({ id: campaignId })
      .then(({ data: { data: campaign } }) => {
        setCampaign(campaign);

        trackPageViewWithDynamicIDs({
          fbPixelId: campaign.fb_pixel_id,
          gaTrackingId: campaign.ga_tracking_id,
          snapchatPixelId: campaign.snapchat_pixel_id,
        });

        if (referral_hash) {
          return users
            .getUserByReferralHash(referral_hash)
            .then(({ data: { data: user } }) => {
              return teams.getTeam({ filters: { campaign_id: campaign.id }, id: user.id });
            })
            .then(({ data: { data: team } }) => setTeam(team))
            .catch(error => {
              // Catch error and make this promise always resolved,
              // so we can render this view at least
              console.error('get team data >', error);
            });
        }
      })
      .then(() => setLoading(false));
  }, [campaignId, referral_hash]);

  const handleClickAlreadyRegistered = useCallback(() => {
    setScreenType(ScreenTypes.SEARCH_VOTER);
  }, []);

  const handleClickNotRegistered = useCallback(() => {
    setScreenType(ScreenTypes.NEW_VOTER);
  }, []);

  const handleClickCannotVote = useCallback(() => {
    setScreenType(ScreenTypes.SIGNUP_TO_HELP);
  }, []);

  const handleSearchFormSubmit = useCallback((formData, { setSubmitting }) => {
    track('SUBMIT_CHECK_REGISTRATION');
    setLoadingItems(true);
    setSearched(true);
    setSelectedVoterId();
    users
      .searchVoters(formData)
      .then(({ data }) => {
        setVoters(data);
      })
      .catch(error => {
        // TODO: Improve error handling
        console.error(error);
        setVoters([]);
      })
      .then(() => {
        setSubmitting(false);
        setLoadingItems(false);
        window.scrollTo(0, searchRef.current.offsetTop);
      });
  }, []);

  const goToNextStep = useCallback(
    contact => {
      const query = queryString.stringify({ h: contact.h, referral_hash });
      const url = isSignupToHelpScreen
        ? `/campaigns/${campaign.slug}/contacts/${contact.id}/voting_information?${query}`
        : `/campaigns/${campaign.slug}/contacts/${contact.id}/confirm_address?${query}`;
      history.push(encodeURI(url));
    },
    [campaign, referral_hash, isSignupToHelpScreen, history],
  );

  const handleSelectVoter = useCallback(() => {
    const {
      values: { email, phone, firstName, lastName },
      errors: { email: emailError, phone: phoneError },
    } = formRef.current.state;
    if (!contact_id && !h && !email && !phone) {
      message.error(t('check_registration.email_phone_required'));
      return;
    }
    if (email && emailError) {
      message.error(emailError);
      return;
    }
    if (phone && phoneError) {
      message.error(phoneError);
      return;
    }
    campaigns
      .confirmMyRecord({
        campaign_id: campaign.id,
        contact_id,
        contact_referral_id,
        email,
        first_name: firstName,
        h,
        last_name: lastName,
        opt_in: optIn,
        phone,
        user_referral_id,
        voter: selectedVoterId,
      })
      .then(({ data }) => goToNextStep(data))
      .catch(error => {
        // TODO: Improve error handling
        console.error(error);
        message.error(t('errors.default'));
      });
  }, [
    campaign,
    user_referral_id,
    selectedVoterId,
    contact_id,
    contact_referral_id,
    h,
    optIn,
    goToNextStep,
    t,
  ]);

  const handleSelectNone = useCallback(() => {
    const {
      values: { email, phone, firstName, lastName },
      errors: { email: emailError, phone: phoneError },
    } = formRef.current.state;
    if (!contact_id && !h && !email && !phone) {
      message.error(t('check_registration.email_phone_required'));
      return;
    }
    if (email && emailError) {
      message.error(emailError);
      return;
    }
    if (phone && phoneError) {
      message.error(phoneError);
      return;
    }
    campaigns
      .confirmUnregistered({
        campaign_id: campaign.id,
        contact_id,
        contact_referral_id,
        email,
        first_name: firstName,
        h,
        last_name: lastName,
        opt_in: optIn,
        phone,
        user_referral_id,
      })
      .then(({ data }) => goToNextStep(data))
      .catch(error => {
        // TODO: Improve error handling
        console.error(error);
        message.error(t('errors.default'));
      });
  }, [campaign, user_referral_id, contact_id, contact_referral_id, h, optIn, goToNextStep, t]);

  const handleContactFormSubmit = useCallback(
    (formData, { setSubmitting }) => {
      const { email, phone, firstName, lastName } = formData;
      if (!contact_id && !h && !email && !phone) {
        message.error(t('check_registration.email_phone_required'));
        setSubmitting(false);
        return;
      }
      campaigns
        .confirmUnregistered({
          campaign_id: campaign.id,
          contact_id,
          contact_referral_id,
          email,
          first_name: firstName,
          h,
          last_name: lastName,
          opt_in: optIn,
          phone,
          registration_status: isSignupToHelpScreen ? 'Unable to Vote' : undefined,
          user_referral_id,
        })
        .then(({ data }) => goToNextStep(data))
        .catch(error => {
          // TODO: Improve error handling
          console.error(error);
          setSubmitting(false);
          message.error(t('errors.default'));
        });
    },
    [
      campaign,
      user_referral_id,
      contact_id,
      contact_referral_id,
      h,
      optIn,
      isSignupToHelpScreen,
      goToNextStep,
      t,
    ],
  );

  const voterTableProps = useMemo(() => {
    return {
      onRow: record => ({
        onClick: () => setSelectedVoterId(record.voterbase_id),
      }),
      pagination: false,
      rowKey: 'voterbase_id',
      rowSelection: {
        fixed: true,
        onChange: selectedKeys => setSelectedVoterId(selectedKeys[0]),
        selectedRowKeys: [selectedVoterId],
        type: 'radio',
      },
      scroll: {
        x: true,
      },
      showHeader: false,
    };
  }, [selectedVoterId]);

  if (loading) {
    return (
      <div className="flex-row justify-content-center mt-4">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  const termsAndConditions = campaign.terms ? (
    <TermsAndConditions>
      <p>{campaign.terms}</p>
      {campaign.terms_link && (
        <a href={campaign.terms_link} target="_blank" rel="noopener noreferrer">
          {t('check_registration.read_more')}
        </a>
      )}
    </TermsAndConditions>
  ) : (
    <TermsAndConditions>
      <div>
        {t('check_registration.by_proceeding')}
        <a href="/outvote/terms_of_service" target="_blank" rel="noopener noreferrer">
          {t('check_registration.tos')}
        </a>
        {'.'}
      </div>
    </TermsAndConditions>
  );

  if (screenType === ScreenTypes.SPLASH) {
    return (
      <VoterRegistrationTemplate campaign={campaign} team={team}>
        <SplashWrapper>
          <SplashTitle>{t('check_registration.splash.title')}</SplashTitle>
          <SplashDescription>{t('check_registration.description')}</SplashDescription>
          <BigRoundButton
            onClick={handleClickAlreadyRegistered}
            data-testid="already-registered-button"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t('check_registration.splash.already_registered'),
              }}
            />
          </BigRoundButton>
          <BigRoundButton onClick={handleClickNotRegistered} secondary>
            {t('check_registration.splash.not_registered')}
          </BigRoundButton>
          <CannotVoteLink onClick={handleClickCannotVote}>
            {t('check_registration.splash.cannot_vote')}
          </CannotVoteLink>
          {termsAndConditions}
        </SplashWrapper>
      </VoterRegistrationTemplate>
    );
  }

  const stepCount = isSignupToHelpScreen ? 2 : 3;
  const title = isSignupToHelpScreen
    ? t('check_registration.signup_to_help.title')
    : isSearchScreen
    ? t('check_registration.title')
    : t('check_registration.new_voter.title');
  const instruction = isSignupToHelpScreen
    ? t('check_registration.signup_to_help.description')
    : isSearchScreen
    ? t('check_registration.description')
    : t('check_registration.new_voter.description');

  return (
    <VoterRegistrationTemplate
      campaign={campaign}
      team={team}
      stepCount={stepCount}
      currentStep={1}
      title={title}
      instruction={instruction}
      data-testid="find-possibilities"
    >
      <FormContainer>
        <Formik
          ref={formRef}
          initialValues={formInitialValues}
          validationSchema={!isSearchScreen ? ContactFormSchema : SearchVotersFormSchema}
          onSubmit={!isSearchScreen ? handleContactFormSubmit : handleSearchFormSubmit}
          render={({ isSubmitting, isValid, submitCount }) => (
            <Form>
              <Row gutter={30}>
                {!contact_id && (
                  <>
                    <Col span={24} md={12}>
                      <FormItem name="email">
                        <label htmlFor="email">{t('idvoters.labels.email')}</label>
                        <Input
                          name="email"
                          placeholder={t('placeholders.default_email')}
                          id="email"
                          prefix={<MailOutlined />}
                        />
                      </FormItem>
                    </Col>
                    <Col span={24} md={12}>
                      <FormItem name="phone">
                        <label htmlFor="phone">{t('idvoters.labels.phone')}</label>
                        <Input
                          name="phone"
                          placeholder={'(111) 222 - 3333'}
                          id="phone"
                          prefix={<PhoneOutlined />}
                        />
                      </FormItem>
                    </Col>
                  </>
                )}
                <Col span={24} md={12}>
                  <FormItem name="firstName">
                    <label htmlFor="firstName">{t('idvoters.labels.firstname')}</label>
                    <Input
                      name="firstName"
                      placeholder={t('idvoters.labels.firstname')}
                      id="firstName"
                    />
                  </FormItem>
                </Col>
                <Col span={24} md={12}>
                  <FormItem name="lastName">
                    <label htmlFor="lastName">{t('idvoters.labels.lastname')}</label>
                    <Input
                      name="lastName"
                      placeholder={t('idvoters.labels.lastname')}
                      id="lastName"
                    />
                  </FormItem>
                </Col>
                {isSearchScreen && (
                  <>
                    <Col span={24}>
                      <FormItem name="ageRange">
                        <label htmlFor="ageRange">{t('idvoters.labels.age')}</label>
                        <Radio.Group
                          className="flex-row flex-wrap"
                          id="ageRange"
                          name="ageRange"
                          defaultValue="18-255"
                          buttonStyle="solid"
                        >
                          <StyledRadio className="radio-btn-outline" value="18-255">
                            {t('idvoters.labels.age_all')}
                          </StyledRadio>
                          <StyledRadio className="radio-btn-outline" value="18-28">
                            18-28
                          </StyledRadio>
                          <StyledRadio className="radio-btn-outline" value="25-35">
                            25-35
                          </StyledRadio>
                          <StyledRadio className="radio-btn-outline" value="30-45">
                            30-45
                          </StyledRadio>
                          <StyledRadio className="radio-btn-outline" value="40-60">
                            40-60
                          </StyledRadio>
                          <StyledRadio className="radio-btn-outline" value="55-150">
                            {t('idvoters.labels.age_55_up')}
                          </StyledRadio>
                        </Radio.Group>
                      </FormItem>
                    </Col>
                    <Col span={24} md={8}>
                      <FormItem name="stateAbbv">
                        <label htmlFor="stateAbbv">{t('idvoters.labels.state')}</label>
                        <Select
                          data-testid="state-abbv"
                          name="stateAbbv"
                          autoComplete={'off'}
                          id="stateAbbv"
                          placeholder={t('idvoters.placeholders.state')}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {states.map(({ value, label }) => (
                            <Select.Option key={value} value={value}>
                              {label}
                            </Select.Option>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span={24} md={8}>
                      <FormItem name="city">
                        <label htmlFor="city">{t('idvoters.labels.city')}</label>
                        <Input
                          name="city"
                          placeholder={t('idvoters.placeholders.city')}
                          id="city"
                        />
                      </FormItem>
                    </Col>
                    <Col span={24} md={8}>
                      <FormItem name="zipCode">
                        <label htmlFor="zipCode">{t('idvoters.labels.zip')}</label>
                        <Input name="zipCode" placeholder={t('idvoters.labels.zip')} id="zipCode" />
                      </FormItem>
                    </Col>
                  </>
                )}
              </Row>
              <ButtonsRow>
                {!isSearchScreen && (
                  <ImpactiveButton
                    type="submit"
                    disabled={isSubmitting || (!isValid && submitCount > 0)}
                  >
                    {t('check_registration.submit')}
                  </ImpactiveButton>
                )}
                {isSearchScreen && (
                  <>
                    <ImpactiveButton
                      type="submit"
                      data-testid="search-button"
                      disabled={isSubmitting || (!isValid && submitCount > 0)}
                    >
                      {t('check_registration.search_my_file')}
                    </ImpactiveButton>
                    <ImpactiveButton
                      type="button"
                      data-testid="not-registered-button"
                      onClick={handleSelectNone}
                      secondary
                    >
                      {t('check_registration.not_registered')}
                    </ImpactiveButton>
                  </>
                )}
              </ButtonsRow>
            </Form>
          )}
        />
        <Checkbox
          style={{ marginTop: 10 }}
          disabled
          name="optIn"
          id="optIn"
          checked={optIn}
          onChange={e => {
            setOptIn(e.target.checked);
          }}
        >
          <span
            style={{ color: '#232322' }}
            dangerouslySetInnerHTML={{
              __html: t('check_registration.agreement', {
                campaign: campaign.name,
                privacy_link: campaign.privacy_link,
                terms_link: campaign.terms_link,
              }),
            }}
          ></span>
        </Checkbox>
      </FormContainer>
      <SearchResults ref={searchRef}>
        {searched && (
          <>
            <SearchResultsTitle>{t('check_registration.search_results.title')}</SearchResultsTitle>

            {loadingItems && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '2em',
                }}
              >
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              </div>
            )}

            {!loadingItems && (
              <>
                <SearchResultsDescription>
                  {t('check_registration.search_results.description')}
                </SearchResultsDescription>
                {voters && voters.length > 0 && (
                  <VoterTable
                    {...voterTableProps}
                    columns={voterTableColumns}
                    dataSource={voters}
                  />
                )}
                {!(voters && voters.length > 0) && (
                  <Note title={t('idvoters.results.no_results_title')}>
                    {t('idvoters.results.no_results_message')}
                  </Note>
                )}
                <ButtonsRow>
                  {voters && voters.length > 0 && (
                    <ImpactiveButton onClick={handleSelectVoter} disabled={!selectedVoterId}>
                      {t('check_registration.search_results.this_is_me')}
                    </ImpactiveButton>
                  )}
                  <ImpactiveButton onClick={handleSelectNone} secondary>
                    {t('check_registration.search_results.cannot_find_myself')}
                  </ImpactiveButton>
                </ButtonsRow>
              </>
            )}
          </>
        )}
      </SearchResults>
      {termsAndConditions}
    </VoterRegistrationTemplate>
  );
};

CheckRegistration.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CheckRegistration;
