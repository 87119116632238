import styled from 'styled-components';

import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';

export const Wrapper = styled.div`
  min-width: 480px;
  max-width: ${({ $maxWidth }) => $maxWidth || '800px'};
  width: 100%;
  min-height: calc(100vh - 190px);

  ${smallBreakpoint} {
    min-width: unset;
    max-width: unset;
    min-height: unset;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.white};

  ${smallBreakpoint} {
    padding: 20px;
    width: calc(100vw - 40px);
  }
`;
