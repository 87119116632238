import * as yup from 'yup';
import i18n from 'web/components/i18n';
import dayjs from 'dayjs';

export const dateFormat = 'MM/DD/YYYY';

export const phoneRegExp =
  /^[+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s.]?)[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export const ReferralFormSchema = yup.object().shape({
  email: yup.string().email(i18n.t('check_registration.invalid_email')).required(),
  firstName: yup.string().required(i18n.t('idvoters.validation.firstname')),
  lastName: yup.string().required(i18n.t('idvoters.validation.lastname')),
  phone: yup.string().matches(phoneRegExp, i18n.t('check_registration.invalid_phone')).required(),
});

export const ContactFormSchema = yup.object().shape({
  address: yup.string().required(i18n.t('idvoters.validation.address')),
  dateOfBirth: yup
    .date()
    .typeError(i18n.t('idvoters.validation.valid_dob'))
    .required(i18n.t('idvoters.validation.dob'))
    .min(dayjs().subtract(120, 'year'), i18n.t('idvoters.validation.valid_dob'))
    .max(dayjs().subtract(17, 'year'), i18n.t('idvoters.validation.max_date', { age: 18 })),
  email: yup.string().email(i18n.t('check_registration.invalid_email')).required(),
  firstName: yup.string().required(i18n.t('idvoters.validation.firstname')),
  lastName: yup.string().required(i18n.t('idvoters.validation.lastname')),
  phone: yup.string().matches(phoneRegExp, i18n.t('check_registration.invalid_phone')).required(),
});
