import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const StyledButton = styled(Button)`
  // compensation for transition
  margin-left: 4px;
  ${({ $isMobile, $topCompensation }) =>
    ($isMobile || $topCompensation) &&
    css`
      margin-top: -4px;
    `}

  &&& {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    gap: 8px;
    border-radius: 8px;
    height: 64px;
    color: ${({ theme }) => theme.colors.darkBlue};
    font-family: ${({ theme }) => theme.fonts.regular};
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem; /* 125% */
    letter-spacing: 0.03125rem;
  }

  ${({ $type }) => {
    switch ($type) {
      case 'submit':
        return css`
          &&& {
            width: 244px;
            border: 1px solid ${({ theme }) => theme.colors.darkBlue};
            background: ${({ theme }) => theme.colors.newBlue};
          }
        `;
      case 'back':
        return css`
          &&& {
            width: 140px;
            border: 1px solid #d0d5dd;
            background: ${({ theme }) => theme.colors.white};
          }
        `;
      default:
        return css``;
    }
  }}

  &:active {
    ${({ disabled }) =>
      !disabled &&
      css`
        margin: -4px 4px 0px 0px;
        box-shadow: 4px 4px 0px 0px ${({ theme }) => theme.colors.darkBlue};
      `}
  }

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        margin: -4px 4px 0px 0px;
        box-shadow: 4px 4px 0px 0px ${({ theme }) => theme.colors.darkBlue};
      `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      &&& {
        width: 100%;
      }
    `}
`;

const VotingPlanButton = ({ onClick, text, icon, type, isMobile, topCompensation, ...rest }) => {
  const content = useMemo(
    () =>
      type === 'back' ? (
        <>
          {icon}
          {text}
        </>
      ) : (
        <>
          {text}
          {icon}
        </>
      ),
    [type, text, icon],
  );

  if (!text) return null;

  return (
    <StyledButton
      onClick={onClick}
      $type={type}
      $isMobile={isMobile}
      $topCompensation={topCompensation}
      {...rest}
    >
      {content}
    </StyledButton>
  );
};

VotingPlanButton.propTypes = {
  icon: PropTypes.node,
  isMobile: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  topCompensation: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'back']),
};

VotingPlanButton.defaultProps = {
  type: 'submit',
};

export default VotingPlanButton;
