import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AddressInput from '@web/components/Headcount/components/Input/AddressInput';
import Button from '@web/components/Headcount/components/Button';
import ButtonRow from '../ButtonRow';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import Input from '@web/components/Headcount/components/Input';
import MaskedInput from '@web/components/Headcount/components/Input/MaskedInput';
import OptIns from '@web/components/Headcount/components/OptIns';
import Text from '@web/components/Headcount/components/Text';

import { setContactFormResult } from '@web/reducers/voterRegistration';
import useContactForm from '@web/components/Headcount/hooks/useContactForm';
// import { flows } from '@web/components/Headcount';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useVoterRegistrationApi from '@web/components/Headcount/hooks/useVoterRegistrationApi';
import pxToRem from '@web/utils/pxToRem';
import { trackEventViewWithDynamicIDs } from '@web/services/analytics';

const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.wide};
  line-height: 1.5;
`;

const ThanksMessage = styled(Text)`
  font-size: ${pxToRem(32)};
  text-align: center;
`;

function ContactForm({
  activity,
  backToStart,
  contactFormTitle,
  contactFormSchema,
  nextPage,
  registrationStatus,
  setRootFlow,
  team,
}) {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const state = useSelector(state => state.voterRegistration);
  const { initialValues } = useContactForm(state.contactFormResult);
  const { handleError, submitContactForm } = useVoterRegistrationApi(activity, team);
  const [apiError, setApiError] = React.useState(null);
  const isHeadcountRctEnabled = React.useMemo(
    () => activity?.campaign?.headcount_rct_enabled,
    [activity?.campaign?.headcount_rct_enabled],
  );

  function handleSubmit(values, { setSubmitting }) {
    setApiError(null);
    setSubmitting(true);
    values.registrationStatus = registrationStatus;

    submitContactForm(values)
      .then(({ data }) => {
        setIsSubmitted(true);
        dispatch(setContactFormResult({ activity, contact: data }));
        trackEventViewWithDynamicIDs({
          actionName: 'headcount_form_filled',
          categoryName: 'Headcount Form Filled',
          fbPixelId: activity?.settings?.pixel_fb_id,
          gaTrackingId: activity?.settings?.pixel_ga_id,
          gtmPixelId: activity?.settings?.pixel_gtm_id,
          snapchatPixelId: activity?.settings?.pixel_sc_id,
        });
        // Special case to enable when GA has a runoff
        // Redirect to GA Runoff page directly for matching campaigns and if the submitted address is from GA
        // if (data.state_abbrev === 'GA' && shouldShowGARunoffPage(activity?.campaign)) {
        //   setRootFlow(flows.GA_RUNOFF_PAGE);
        // } else { nextPage(); }
        if (!isHeadcountRctEnabled) {
          nextPage();
        }
      })
      .catch(err => {
        const errorMessage = handleError(err);
        setApiError(errorMessage);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function handleAddressChange(address, setFieldValue) {
    setFieldValue('address', address.address);
    setFieldValue('city', address.city);
    setFieldValue('zipCode', address.zip_code);
    setFieldValue('countyName', address.county_name);
    setFieldValue('stateAbbrev', address.state_abbrev);
  }

  const handleDateChange = ({ target: { value } }, setFieldValue) => {
    setFieldValue('dateOfBirth', value);
  };

  function getDefaultAddress(values) {
    if (values?.address) {
      return `${values.address}, ${values.city}, ${values.stateAbbrev}, USA`;
    }
  }

  const cannabisRegistrationEnabled = React.useMemo(
    () => activity?.campaign?.cannabis_registration_enabled,
    [activity?.campaign?.cannabis_registration_enabled],
  );

  const isHeadcountMtvEnabled = React.useMemo(
    () => activity?.campaign?.headcount_mtv_enabled,
    [activity?.campaign?.headcount_mtv_enabled],
  );

  initialValues.contactFormHeadcount.smsOptIn =
    !!cannabisRegistrationEnabled || !!isHeadcountMtvEnabled;

  return (
    <Formik
      initialValues={initialValues.contactFormHeadcount}
      onSubmit={handleSubmit}
      validationSchema={contactFormSchema}
      render={({ errors, isSubmitting, isValid, setFieldValue, touched, values, submitCount }) => {
        const showCompleted = isSubmitted && isHeadcountRctEnabled;

        return (
          <>
            {showCompleted ? (
              <>
                <ThanksMessage>{t('check_registration.headcount.rct.thanksMessage')}</ThanksMessage>
              </>
            ) : (
              <Form>
                <StyledText dangerouslySetInnerHTML={{ __html: contactFormTitle }} variant="f2" />
                <Input
                  autoComplete="email"
                  label={t('idvoters.labels.email')}
                  name="email"
                  type="email"
                />
                <Input
                  autoComplete="tel"
                  label={t('idvoters.labels.phone')}
                  name="phone"
                  type="tel"
                />
                <Input
                  autoComplete="given-name"
                  label={t('idvoters.labels.firstname')}
                  name="firstName"
                />
                <Input
                  autoComplete="family-name"
                  label={t('idvoters.labels.lastname')}
                  name="lastName"
                />
                <MaskedInput
                  label={t('idvoters.labels.date_of_birth')}
                  name="dateOfBirth"
                  onChange={event => handleDateChange(event, setFieldValue)}
                  placeholder="MM/DD/YYYY"
                  autoComplete="bday"
                  value={values?.dateOfBirth}
                  type="tel"
                />
                <AddressInput
                  label={t('idvoters.labels.address')}
                  name="searchAddress"
                  onChange={address => handleAddressChange(address, setFieldValue)}
                  type="search"
                  defaultValue={getDefaultAddress(values)}
                />
                <Input label={t('idvoters.labels.unitNumber')} name="unitNumber" />
                <OptIns
                  activity={activity}
                  setFieldValue={setFieldValue}
                  team={team}
                  values={values}
                />
                <ButtonRow error={apiError}>
                  <Button
                    onClick={backToStart}
                    style={{ paddingLeft: 0 }}
                    type="button"
                    variant="tertiary"
                  >
                    {t('common.back')}
                  </Button>
                  <Button loading={isSubmitting} type="submit">
                    {t('common.submit')}
                  </Button>
                </ButtonRow>
              </Form>
            )}
          </>
        );
      }}
    />
  );
}

ContactForm.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.object.isRequired,
    settings: PropTypes.shape({
      pixel_fb_id: PropTypes.string,
      pixel_ga_id: PropTypes.string,
      pixel_gtm_id: PropTypes.string,
      pixel_sc_id: PropTypes.string,
    }),
  }).isRequired,
  backToStart: PropTypes.func.isRequired,
  contactFormSchema: PropTypes.object.isRequired,
  contactFormTitle: PropTypes.string.isRequired,
  flow: PropTypes.string,
  nextPage: PropTypes.func.isRequired,
  registrationStatus: PropTypes.string,
  setRootFlow: PropTypes.func.isRequired,
  team: PropTypes.object,
};

ContactForm.defaultProps = {
  team: null,
};

export default ContactForm;
