import { LockOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Input, message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { campaigns, users } from '../../services/api';
import theme from '../../styles/theme';

const StyledJoinCodeForm = styled.form`
  width: 225px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledInput = styled(Input)`
  // Remove number arrows
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

function JoinCode() {
  const { t } = useTranslation();
  let history = useHistory();
  const [isValid, setIsValid] = React.useState(false);
  const [code, setCode] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const ErrorMessage = <span dangerouslySetInnerHTML={{ __html: t('explore.join_code.error') }} />;

  React.useEffect(() => {
    setIsValid(code.toString().length === 6);
  }, [code]);

  function handleInput(e) {
    // Clean up the error message on type event
    if (e.target.value.length > 6) {
      return;
    }
    setCode(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();

    // Don't submit multiple times
    if (loading) return;

    // Don't submit if input isn't valid
    if (!isValid) {
      message.error(ErrorMessage, 2000);
      return;
    }

    setLoading(true);
    campaigns
      .findCampaign(code)
      .then(({ data }) => {
        if (!data) {
          setLoading(false);
          message.error(ErrorMessage, 2000);
        } else {
          // success
          const { id, slug, name } = data.data;
          joinCampaign(id, slug, name);
        }
      })
      .catch(err => {
        setLoading(false);
        message.error(ErrorMessage);
      });
  }

  // Joins Campaign and redirects to Campaign detail page
  function joinCampaign(id, slug, name) {
    users
      .followCampaign({ campaign_id: id, campaign_name: name, campaign_slug: slug })
      .then(({ data }) => {
        history.push(`/campaigns/${slug}/?join-success=true`);
      })
      .catch(err => {
        // We shouldn't display an error here. We can just redirect to
        // the campaign detail page
        history.push(`/campaigns/${slug}`);
      });
  }

  return (
    <StyledJoinCodeForm onSubmit={handleSubmit} data-testid="join-code-form">
      <StyledInput
        prefix={<LockOutlined />}
        suffix={
          code && (
            <RightCircleOutlined onClick={handleSubmit} style={{ color: theme.colors.blue }} />
          )
        }
        data-testid="join-code-input"
        onChange={handleInput}
        placeholder={t('explore.join_code.join_using_campaign_code')}
        required
        size="default"
        type="number"
        value={code}
      />
    </StyledJoinCodeForm>
  );
}

JoinCode.propTypes = {};

JoinCode.defaultProps = {};

export default JoinCode;
