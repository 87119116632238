import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Header from '../Header';
import ProgressBar from '../ProgressBar';

import useIsPartner from '../../hooks/useIsPartner';

const StyledHeaderAndProgress = styled.div`
  margin: ${({ theme }) => theme.spacing.wide} 0;
`;

const HeaderWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.normal};
`;

function HeaderAndProgress({ activity, progress, team, numberOfPages }) {
  const isPartner = useIsPartner(team);
  const imageSrc = isPartner && team.profile_img_url ? team.profile_img_url : activity.media_url;

  const isHeadcountRctEnabled = React.useMemo(
    () => activity?.campaign?.headcount_rct_enabled,
    [activity?.campaign?.headcount_rct_enabled],
  );

  return (
    <StyledHeaderAndProgress>
      <HeaderWrapper>
        <Header alt={activity.title} src={imageSrc} url={activity?.campaign?.website_url} />
      </HeaderWrapper>
      {!isHeadcountRctEnabled && (
        <ProgressBar
          progress={progress}
          team={team}
          numberOfPages={numberOfPages}
          activity={activity}
        />
      )}
    </StyledHeaderAndProgress>
  );
}

HeaderAndProgress.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.object.isRequired,
    hero_img_url: PropTypes.string,
    media_url: PropTypes.string,
    mobile_hero_img_url: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  numberOfPages: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  team: PropTypes.shape({
    profile_img_url: PropTypes.string,
  }),
};

HeaderAndProgress.defaultProps = {};

export default HeaderAndProgress;
