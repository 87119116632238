import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import ScrollToTop from '@web/components/common/ScrollToTop';

import { activities } from '../../services/api';

import AboutUsSection from '@web/components/common/AboutUsSection';
import HeadcountRegistrationActivity from '@web/components/Headcount';
import IncentivesSection from '@web/components/common/IncentivesSection';
import VolunteerLeaderboardSection from '@web/components/common/VolunteerLeaderboardSection';
import VoterRegistration from '@web/components/VoterRegistration';
import FanOutActivity from '@web/components/FanOut';

import ActivityHero from './ActivityHero';

const StyledTeamActivity = styled.div`
  height: 100%;
  width: 100%;
`;

const TeamActivityVoterRegistrationPage = ({ activity, team }) => {
  const { t } = useTranslation();
  const { activitySlug, campaignSlug } = useParams();
  const { search } = useLocation();
  const { referral_hash, start } = queryString.parse(search);
  const [goalsLoading, setGoalsLoading] = useState(false);
  const [referrerLoading, setReferrerLoading] = useState(false);
  const [goals, setGoals] = useState({});
  const [referrer, setReferrer] = useState(null);
  const [showVoterModal, setShowVoterModal] = useState(start === '1');
  const [showReferralModal, setShowReferralModal] = useState(false);

  useEffect(() => {
    setGoalsLoading(true);

    activities
      .getActivityGoals({ activitySlug, campaignSlug })
      .then(({ data }) => {
        setGoals(data);
      })
      .finally(() => setGoalsLoading(false));
  }, [activitySlug, campaignSlug]);

  useEffect(() => {
    if (referral_hash) {
      setReferrerLoading(true);

      axios
        .get(
          `/api/v2/client/voter_registrations/campaigns/${campaignSlug}/referrer/${referral_hash}`,
        )
        .then(({ data }) => {
          setReferrer(data);
        })
        .finally(() => setReferrerLoading(false));
    }
  }, [activitySlug, campaignSlug, referral_hash]);

  function openVoterModal() {
    setShowVoterModal(true);
  }

  function openReferralModal() {
    setShowReferralModal(true);
  }

  function closeModal() {
    setShowReferralModal(false);
    setShowVoterModal(false);
  }

  if (!activity || !team || goalsLoading || referrerLoading) {
    return (
      <div className="flex-row justify-content-center mt-4">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  if (activity.campaign?.fan_out_registration_enabled) {
    return <FanOutActivity activity={activity} team={team} />;
  }

  // Headcount registration activity
  if (
    activity?.campaign?.headcount_registration_enabled &&
    activity?.type === 'RegistrationActivity'
  ) {
    return <HeadcountRegistrationActivity activity={activity} team={team} />;
  }

  // Regular voter registration activity
  return (
    <StyledTeamActivity>
      <ScrollToTop />

      <VoterRegistration
        activity={activity}
        closeModal={closeModal}
        modalVoterOpen={showVoterModal}
        openVoterModal={openVoterModal}
        modalReferralOpen={showReferralModal}
        openReferralModal={openReferralModal}
        team={team}
      />

      <ActivityHero
        activity={activity}
        campaign={activity.campaign}
        goals={goals}
        handleGetStarted={openVoterModal}
        handleGetReferral={openReferralModal}
        team={team}
        referrer={referrer}
      />

      <AboutUsSection
        background={false}
        description={team.action_page_description}
        mediaContentType={team.about_media_type}
        mediaUrl={team.about_media_url}
        title={t('campaign.activity.take_action')}
      />

      <IncentivesSection
        incentives={activity.activity_incentives_attributes}
        pageType={'team'}
        campaign={activity?.campaign}
      />

      {![784, 3876].includes(activity?.campaign?.id) && (
        <VolunteerLeaderboardSection activity={activity} pageType={'team'} team={team} />
      )}
    </StyledTeamActivity>
  );
};

TeamActivityVoterRegistrationPage.propTypes = {
  activity: PropTypes.object,
  team: PropTypes.object,
};

export default TeamActivityVoterRegistrationPage;
