import { Button, message, Spin, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import imageElectionCheck from '../../../../assets/images/web/election-check.png';
import imageElectionX from '../../../../assets/images/web/election-x.png';
import { track } from '../../services/analytics';
import { user, userReportApi, users } from '../../services/api';
import { sortByDisplayOrderFn } from '../../utils/other';
import ImpactiveButton from '../ImpactiveButton';
import FilterButton from '../common/FilterButton';
import './voter-match.scss';
import {
  getVoterInfo,
  getParty,
  getVotingRecords,
  getFrequency,
  frequencyTagConverter,
} from '@web/utils/getVoterInfo';

const phoneRegExp =
  /^((\\ +[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const NameAndBadLink = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 16px;
`;

const StyledButton = styled(Button)`
  &&& {
    font-family: ${({ theme }) => theme.fonts.blackItalic};
    color: ${({ theme }) => theme.colors.blue};
    text-transform: uppercase;
  }
`;

const RecentElections = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.semibold};
`;

const RecentElectionSection = styled.div`
  margin-bottom: 30px;
`;

const ElectionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 60%;
  margin-bottom: 10px;
`;

const ElectionName = styled.div`
  font-size: 14px;
`;

const StyledElectionImage = styled.img`
  height: 16px;
  width: 16px;
`;

/**
 * This should show the contact's voter file match using the "selected_voterbase_id" column
 * If this is empty, the contact is not matched and there should be a button to find a match which takes the user to the ID Voter Screen
 * Otherwise it should show the labels and the voting history of the contact and some basic details such as name, state, city, age range, and so on
 * There should be a BAD MATCH button that also takes the user to the ID Voter screen
 * The ID voter result will be used to update the contact's selected_voterbase_id.
 *
 * NOTE: this component will show both contact's voter file, and will be used to select voter if contact is passed to it as described above
 */
const VoterMatchCore = ({
  contactId,
  voterParams,
  voterbaseId,
  isFriend,
  userActivityId,
  activityId,
  campaignId,
  onBadMatch,
  onSaveMatchFinally,
  redirectIfNoVoterInfo,
}) => {
  const { t } = useTranslation();

  // MTS - It looks like this setContact isn't being used
  // eslint-disable-next-line
  const [contact, setContact] = useState({});
  const [userActivity, setUserActivity] = useState({});
  const [voter, setVoter] = useState({});
  const [loading, setLoading] = useState(true);
  const [userReport, setUserReport] = useState({});
  const { tags = [], custom_fields = [] } = userReport;

  const [redirectURL, setRedirectURL] = useState(null);
  const [redirectToVoterForm, setRedirectToVoterForm] = useState(null);

  const ReportFormSchema = yup.object().shape({
    email: yup.string().email(t('idvoters.validation.email')).notRequired(),
    phone: yup.string().matches(phoneRegExp, t('idvoters.validation.phone')).notRequired(),
  });

  useEffect(() => {
    // if voter params are passed then use that, otherwise fetch voter from the db

    if (voterParams) {
      setVoter(voterParams);
      setLoading(false);
    } else {
      users
        .getVoter(contactId, voterbaseId)
        .then(({ data }) => {
          setVoter(data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    // TODO fetch contact if we need contact info

    if (isFriend || !campaignId || !activityId) {
      // don't need report if trying to match contact
      return;
    }

    user
      .getUserActivity(userActivityId)
      .then(({ data: { data: userActivity } }) => {
        setUserActivity(userActivity);
      })
      .catch(x => {}); // Noop

    userReportApi
      .getBlankReport({ activity_id: activityId, campaign_id: campaignId })
      .then(({ data }) => {
        // strip tags first so that they can be used for POST request updateOutreachReport
        const { campaign_id, taggings, customizations } = data;

        const strippedTags = taggings
          .map(tagging => {
            const {
              tag: { id, slug, name, display_order, prompt },
              value,
            } = tagging;
            return { campaign_id, display_order, id, name, prompt, slug, value };
          })
          .sort(sortByDisplayOrderFn);

        const strippedFields = customizations
          .filter(customization => customization.custom_field.is_user_reportable !== false)
          .map(customization => {
            const {
              custom_field: { id, name, type, slug, available_values, display_order, prompt },
              value,
            } = customization;
            return {
              available_values,
              campaign_id,
              display_order,
              id,
              name,
              prompt,
              slug,
              type,
              value,
            };
          })
          .sort(sortByDisplayOrderFn);

        setUserReport({
          custom_fields: strippedFields,
          tags: strippedTags,
        });
      })
      .catch(err => {
        message.error(t('errors.fetch_outreach_report'));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFriend, campaignId, activityId]);

  const onSwitchToggle = (selectedSlug, selectionChange) => {
    const updatedTags = tags.map(tag => {
      if (tag.slug !== selectedSlug) {
        return tag;
      }
      return {
        ...tag,
        value: selectionChange,
      };
    });

    setUserReport({
      custom_fields,
      tags: updatedTags,
    });
  };

  const onCustomFieldTextUpdate = (selectedSlug, e) => {
    const updatedFields = custom_fields.map(field => {
      if (field.slug !== selectedSlug) {
        return field;
      }
      return {
        ...field,
        value: e.target.value,
      };
    });

    setUserReport({
      custom_fields: updatedFields,
      tags,
    });
  };

  const onCustomFieldSelectUpdate = (selectedSlug, val) => {
    const updatedFields = custom_fields.map(field => {
      if (field.slug !== selectedSlug) {
        return field;
      }
      return {
        ...field,
        value: val,
      };
    });

    setUserReport({
      custom_fields: updatedFields,
      tags,
    });
  };

  // Post to set_voter to update selected_voterbase_id
  const saveMatch = () => {
    users
      .setVoter(contactId, voter.voterbase_id)
      .then(({ data }) => {
        message.success(`${t('idvoters.voter.match.saved_to_match_contact')} ${data.full_name}`);
        setRedirectURL('/headquarters');
      })
      .catch(() => message.error(t('idvoters.voter.match.could_not_save')))
      .finally(onSaveMatchFinally());
  };

  const { firstName, secondName, fullName, address } = getVoterInfo(voter);
  const party = getParty(voter);
  const votingRecords = getVotingRecords(voter);
  const { frequency } = getFrequency(voter);
  const inDistrict = !!contact.in_district;

  const onSaveError = () => {
    message.error(t('errors.submit_outreach_report'));
  };

  const submitReport = async formData => {
    if (!formData) {
      return;
    }

    const newUserReportBody = {
      activity_id: activityId,
      email: formData.email,
      first_name: firstName,
      last_name: secondName,
      phone: formData.phone,
      selected_voterbase_id: voterbaseId,
    };

    const userReportRes = await userReportApi
      .findOrCreateReport({ activity_id: activityId, campaign_id: campaignId }, newUserReportBody)
      .catch(err => {
        onSaveError();
        return;
      });

    if (!userReportRes || userReportRes.status !== 200) {
      onSaveError();
      return;
    }

    const {
      data: { id: newReportId, taggings, customizations },
    } = userReportRes;

    let updatedReport = {
      ...newUserReportBody,
      customizations_attributes: customizations.map(cust => {
        const existingCf = custom_fields.find(x => x.id === cust.custom_field_id);
        cust.value = existingCf && existingCf.value;
        const { id, custom_field_id, value } = cust;
        return { custom_field_id, id, value };
      }),
      taggings_attributes: taggings.map(tagging => {
        const existingTag = tags.find(x => x.id === tagging.tag_id);
        tagging.value = existingTag && existingTag.value;
        const { id, tag_id, value } = tagging;
        return { id, tag_id, value };
      }),
    };

    await userReportApi
      .update({ campaign_id: campaignId, report_id: newReportId }, updatedReport)
      .catch(err => {
        onSaveError();
      });

    const activity = userActivity && userActivity.activity;
    const campaign = activity && activity.campaign;
    track('COMPLETED_ACTIVITY', {
      activity_id: activityId,
      activity_title: activity && activity.title,
      campaign_id: campaign && campaign.id,
      campaign_name: campaign && campaign.name,
    });

    await users.sendCanvasserMessagePublic(
      {
        activity_id: activityId,
        campaign_id: campaignId,
        contact_id: null,
        report_id: newReportId,
        user_activity_id: userActivityId,
      },
      updatedReport,
    );

    await user.updateUserActivity(userActivityId, { completed: true });
    await user.userActivityAddPerform(userActivityId);

    message.success(t('idvoters.voter.outreach_report_sent'));
    setRedirectToVoterForm(true);
  };

  // Explicit redirect for when save action is performed
  if (redirectURL) {
    return <Redirect push to={redirectURL} />;
  }

  if (redirectToVoterForm) {
    return (
      <Redirect
        push
        to={{
          pathname: '/voters',
          state: {
            activityId,
            campaignId,
            userActivityId,
          },
        }}
      />
    );
  }

  if (loading) {
    return (
      <>
        <div>{t('common.loading')}</div>
        <Spin />
      </>
    );
  }

  // case when url was opened directly and there is no voter info in location state
  if (redirectIfNoVoterInfo) {
    if (!loading && isEmpty(voter) && isEmpty(contact)) {
      return <Redirect to="/frontline" />;
    }
  }

  return (
    <div>
      {/* ***************************** Voter Info **************************************** */}
      <div className="voter-match-container flexbox justify-content-center">
        <div className="voter-match-section p-3">
          <NameAndBadLink>
            <Name>{fullName}</Name>
            <StyledButton onClick={onBadMatch} type="link">
              {t('idvoters.voter.match.bad_match')}
            </StyledButton>
          </NameAndBadLink>
          <div>{address}</div>

          {/* ***************************** /Voter Info **************************************** */}

          <hr />

          {/* ***************************** Voter Tags **************************************** */}
          <div className="flex-row flex-wrap">
            <div className="m-2">
              <FilterButton icon="inDistrict" active={inDistrict} onClick={() => {}}>
                {t('idvoters.voter.tags.in_district')}
              </FilterButton>
            </div>

            {party.party && (
              <div className="m-2">
                <FilterButton icon={party.icon} active onClick={() => null}>
                  {party.party}
                </FilterButton>
              </div>
            )}

            <div className="m-2">
              <FilterButton icon={frequencyTagConverter(frequency)} active onPress={() => null}>
                {frequency}
              </FilterButton>
            </div>
          </div>

          {/* ***************************** /Voter Tags **************************************** */}

          <hr />

          {/* ***************************** Election Attendance ******************************** */}

          <RecentElections>{t('idvoters.voter.recent_elections')}</RecentElections>
          <RecentElectionSection>
            {votingRecords.map(
              (vote, index) =>
                // na === not old enough to vote
                vote.value !== 'na' && (
                  <ElectionRow key={`${vote}-${index}`}>
                    <ElectionName>{vote.title}</ElectionName>
                    <StyledElectionImage
                      alt="election"
                      src={vote.value === 'miss' ? imageElectionX : imageElectionCheck}
                    />
                  </ElectionRow>
                ),
            )}
          </RecentElectionSection>
          {/* ***************************** /Election Attendance ******************************** */}

          {/* ***************************** Outreach Report ******************************** */}

          {!isFriend && (
            <Formik
              initialValues={{
                email: '',
                phone: '',
              }}
              validationSchema={ReportFormSchema}
              onSubmit={submitReport}
            >
              {({ errors, touched }) => (
                <Form className="voter-search-form">
                  <FormItem name="phone" className="form-input px-3">
                    <label htmlFor="phone">{t('idvoters.labels.phone')}</label>
                    <Input name="phone" placeholder={t('idvoters.labels.phone')} />
                  </FormItem>

                  <FormItem name="email" className="form-input px-3">
                    <label htmlFor="email">{t('idvoters.labels.email')}</label>
                    <Input name="email" placeholder={t('idvoters.labels.email')} />
                  </FormItem>

                  <div className="p-3">
                    {tags &&
                      tags.map(({ name, value, slug, prompt }) => (
                        <div className="py-2" key={slug}>
                          <span className="pr-3">{prompt || name}</span>
                          <Switch
                            slug={slug}
                            checked={value}
                            onChange={(to, e) => onSwitchToggle(slug, to, e)}
                          />
                        </div>
                      ))}
                  </div>

                  <div className="px-3 py-2">
                    {custom_fields &&
                      custom_fields.map((field, i) => (
                        <div className="py-2" key={field.slug}>
                          <div className="pb-2">{field.prompt || field.name}</div>

                          {field.type === 'TextField' && (
                            <TextArea
                              name={field.slug}
                              value={field.value}
                              autoSize={{ minRows: 4 }}
                              onChange={e => onCustomFieldTextUpdate(field.slug, e)}
                            />
                          )}

                          {field.type === 'SelectionField' && (
                            <div className="flex-row flex-wrap">
                              {field.available_values.map(val => (
                                <div key={val} className="m-2">
                                  <div
                                    className={`selection-box ${
                                      field.value === val ? 'selected' : ''
                                    }`}
                                    onClick={e => onCustomFieldSelectUpdate(field.slug, val)}
                                  >
                                    {val}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>

                  <div className="flex-row justify-content-end m-3">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="w-100"
                      disabled={errors.phone || errors.email}
                    >
                      {t('common.send_to_campaign')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}

          {/* ***************************** /Outreach Report ******************************** */}

          {isFriend && (
            <ImpactiveButton className="w-100" type="primary" onClick={saveMatch}>
              {t('idvoters.voter.save_match')}
            </ImpactiveButton>
          )}
        </div>
      </div>
    </div>
  );
};

VoterMatchCore.propTypes = {
  activityId: PropTypes.number,
  campaignId: PropTypes.number,
  contactId: PropTypes.number,
  isFriend: PropTypes.bool,
  onBadMatch: PropTypes.func,
  onSaveMatchFinally: PropTypes.func,
  redirectIfNoVoterInfo: PropTypes.bool,
  userActivityId: PropTypes.number,
  voterbaseId: PropTypes.string,
  voterParams: PropTypes.object,
};

VoterMatchCore.defaultProps = {
  redirectIfNoVoterInfo: false,
};

export default VoterMatchCore;
