import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { handleDisabledAnswers } from '@web/components/FanOutVotingPlan/utils';
import QuestionCard from './QuestionCard';
import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';

const Wrapper = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 55px;
  flex-direction: column;

  ${smallBreakpoint} {
    gap: 28px;
  }
`;

const QuestionFields = ({
  questions,
  handleChange,
  values,
  disabledAnswers,
  setDisabledAnswers,
}) => {
  const changeHandler = useCallback(
    (e, target) => {
      handleChange(e);
      handleDisabledAnswers({ ...target, values }, setDisabledAnswers);
    },
    [handleChange, setDisabledAnswers, values],
  );

  return (
    <Wrapper>
      {questions.map((question, i) => (
        <QuestionCard
          key={question.id}
          index={i}
          question={{
            ...question,
            answered_value: values.question_answers[i].value,
          }}
          handleChange={changeHandler}
          disabledAnswers={disabledAnswers}
          values={values}
        />
      ))}
    </Wrapper>
  );
};

QuestionFields.propTypes = {
  disabledAnswers: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      answer_mappings: PropTypes.object,
      id: PropTypes.number,
      position: PropTypes.number,
      question: PropTypes.string,
      question_type: PropTypes.string,
      required: PropTypes.bool,
    }),
  ).isRequired,
  setDisabledAnswers: PropTypes.func.isRequired,
  values: PropTypes.shape({
    question_answers: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
      }),
    ),
  }),
};

export default QuestionFields;
