import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const DATE_FORMAT = 'MM-DD-YY';
export const DISPLAY_DATE_FORMAT = "MMM D, 'YY";

export const iconTypes = {
  dot: 'dot',
  star: 'star',
};

export function showTimeline(data) {
  const today = dayjs();

  return !!data && !today.isAfter(dayjs(data, DATE_FORMAT));
}

export function todayBeforeOrEqual(date) {
  if (!date) return false;

  const today = dayjs();
  return today.isBefore(dayjs(date, DATE_FORMAT)) || today.isSame(dayjs(date, DATE_FORMAT));
}

export function showPostmarkedReceivedInfo({
  postmarkedDate,
  receivedDate,
  postmarkedText,
  receivedText,
}) {
  if (showTimeline(postmarkedDate)) {
    return {
      date: postmarkedDate,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: postmarkedText,
    };
  }

  if (showTimeline(receivedDate)) {
    return {
      date: receivedDate,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: receivedText,
    };
  }

  return null;
}

export const sortTimelines = timelines => {
  // Filter out timelines with valid dates
  const timelinesWithDates = timelines.filter(timeline => timeline.date);

  // Create an array of objects for timelines without dates
  const timelinesWithoutDates = [];
  for (let i = 0; i < timelines.length; i++) {
    const timeline = timelines[i];
    if (!timeline.date) {
      timelinesWithoutDates.push({ index: i, timeline });
    }
  }

  // Sort timelines with dates based on date
  const sortedTimelines = timelinesWithDates.sort(
    (a, b) => dayjs(a.date, DATE_FORMAT).unix() - dayjs(b.date, DATE_FORMAT).unix(),
  );

  // Insert timelines without dates at their specified index
  timelinesWithoutDates.forEach(({ index, timeline }) => {
    sortedTimelines.splice(index, 0, timeline);
  });

  return sortedTimelines;
};

// Check if today is before or equal one of the provided dates
export const getLatestDate = dates => {
  // Make sure there is no empty dates & sort them
  const sorted = dates
    .filter(d => d)
    .sort((a, b) => dayjs(a, DATE_FORMAT).unix() - dayjs(b, DATE_FORMAT).unix());

  let latestDate = null;

  for (const date of sorted) {
    // If one of the dates meet the condition we can stop the loop
    if (todayBeforeOrEqual(date)) {
      latestDate = date;
      break;
    }
  }

  return latestDate;
};

export const getNoOnlineRegistrationAvailableDeadlineDate = votingInfo => {
  const date = getLatestDate([
    votingInfo.mail_reg_deadline_postmarked,
    votingInfo.mail_reg_deadline_received,
    votingInfo.mail_primary_reg_deadline_postmarked,
    votingInfo.mail_primary_reg_deadline_received,
  ]);

  if (date) {
    const displayFormat = 'MMM D';
    return dayjs(date, DATE_FORMAT).format(displayFormat);
  }
};
