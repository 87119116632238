import React from 'react';

const Upload = props => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5 12.625V14.875C13.5 15.5078 12.9727 16 12.375 16H3.375C2.74219 16 2.25 15.5078 2.25 14.875V12.625C2.25 12.0273 1.72266 11.5 1.125 11.5C0.492188 11.5 0 12.0273 0 12.625V14.875C0 16.7734 1.47656 18.25 3.375 18.25H12.375C14.2383 18.25 15.75 16.7734 15.75 14.875V12.625C15.75 12.0273 15.2227 11.5 14.625 11.5C13.9922 11.5 13.5 12.0273 13.5 12.625ZM7.06641 0.601562L2.56641 5.10156C2.10938 5.52344 2.10938 6.26172 2.56641 6.68359C2.98828 7.14062 3.72656 7.14062 4.14844 6.68359L6.75 4.11719V11.5C6.75 12.1328 7.24219 12.625 7.875 12.625C8.47266 12.625 9 12.1328 9 11.5V4.11719L11.5664 6.68359C11.9883 7.14062 12.7266 7.14062 13.1484 6.68359C13.6055 6.26172 13.6055 5.52344 13.1484 5.10156L8.64844 0.601562C8.22656 0.144531 7.48828 0.144531 7.06641 0.601562Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Upload;
