import React from 'react';
import Loading from '../common/Loading';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
`;

const MessageThreadLoading = () => (
  <LoadingWrapper>
    <Loading />
  </LoadingWrapper>
);

export default MessageThreadLoading;
