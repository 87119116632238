import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { media } from '@web/styles/theme';

import FirstPlaceIcon from './FIrstPlaceIcon';
import SecondPlaceIcon from './SecondPlaceIcon';
import ThirdPlaceIcon from './ThirdPlaceIcon';

const StyledLeaderboardCard = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  padding: 12px;
  box-shadow: 0px 14px 44px rgba(89, 99, 134, 0.11);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const TeamDetails = styled.div`
  padding-left: 15px;
  display: grid;
  grid-template-columns: 1rf;
  grid-template-rows: 0.5fr 0.5fr 1fr;

  ${media.lg} {
    padding: 0;
  }
`;

const TeamTitle = styled.p`
  font-weight: 600;
  font-size: 1.2em;
  color: ${({ theme }) => theme.colors.blackL25};
  margin: 6px 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;

  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;

  ${media.lg} {
    margin: 14px 0px 20px;
    text-align: center;
    padding: 0;
  }
`;

const TeamName = styled.p`
  font-weight: 600;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.blackL25};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;

  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;

  ${media.lg} {
    text-align: center;
    padding: 0;
  }
`;

const TeamStats = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
`;

const TeamStat = styled.div`
  background-color: rgba(150, 209, 220, 0.1);
  border-radius: 8px;
  padding: 10px 5px;
  width: 100%;
  max-height: 70px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-self: flex-end;

  ${media.lg} {
    padding: 18px 5px;
  }
`;

const TeamStatNumber = styled.div`
  font-size: 1rem;
  color: #23293e;
  font-weight: 600;

  ${media.lg} {
    font-size: 1.1rem;
  }
`;

const TeamStatDescription = styled.div`
  font-size: 0.8rem;
`;

const TopWrapper = styled.div`
  width: 120px;
  height: 120px;
  > svg {
    width: 100%;
    height: 100%;
  }

  ${media.lg} {
    width: 80px;
    height: 80px;
  }
`;

const icons = {
  1: <FirstPlaceIcon />,
  2: <SecondPlaceIcon />,
  3: <ThirdPlaceIcon />,
};

const LeaderboardCard = ({ contactMetric }) => {
  const { t } = useTranslation();
  const formatter = Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact' });

  const icon = useMemo(() => icons[contactMetric.place], [contactMetric]);

  return (
    <StyledLeaderboardCard>
      {icon && <TopWrapper>{icon}</TopWrapper>}
      <TeamDetails>
        <TeamTitle>
          {contactMetric.referrer_first_name} {contactMetric.referrer_last_name?.[0]}.
        </TeamTitle>
        <TeamName>{contactMetric.team_name ?? ''}</TeamName>
        <TeamStats>
          <TeamStat>
            <TeamStatNumber>{formatter.format(contactMetric.referrals_count)}</TeamStatNumber>
            <TeamStatDescription>{t('campaign.leaderboard.cols.referrals')}</TeamStatDescription>
          </TeamStat>
        </TeamStats>
      </TeamDetails>
    </StyledLeaderboardCard>
  );
};

LeaderboardCard.propTypes = {
  contactMetric: PropTypes.object,
};

export default LeaderboardCard;
