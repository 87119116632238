import axios from 'axios';
import routes from './routes';

const onSuccess = ({ data, headers }) => {
  const { authorization: token } = headers;
  const { data: user } = data;
  return {
    token,
    user,
  };
};

const sendMagicLinkEmail = (email, destination, campaign_slug = null) => {
  return axios.post(`/api/v2/passwordless_session`, {
    user: { campaign_slug, destination, email },
  });
};

const verifyMagicLink = token => {
  return axios.post(`/api/v2/passwordless_session/claim`, { token: token });
};

const signIn = async (email, password, provider) => {
  return axios
    .post(`${routes.auth}/sign_in`, { user: { email, password, provider } })
    .then(onSuccess);
};

const signInWithGoogle = async (email, accessToken, googleId) => {
  return axios
    .post(`${routes.auth}/sign_in`, {
      user: {
        email,
        google_access_token: accessToken,
        google_id: googleId,
        provider: 'google',
      },
    })
    .then(onSuccess);
};

const signUp = async (email, password, provider) => {
  return axios.post(`${routes.auth}`, { user: { email, password, provider } }).then(onSuccess);
};

const signOut = async () => {
  return axios.delete(`/api/v2/users/sign_out`);
};

const deleteAccount = async userId => {
  return axios.delete(`/api/v2/client/users/${userId}/confirm_account_deletion`);
};

const auth = {
  deleteAccount,
  sendMagicLinkEmail,
  signIn,
  signInWithGoogle,
  signOut,
  signUp,
  verifyMagicLink,
};

export default auth;
