import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Contact from '../Contact';
import HangUpButton from '../HangUpButton';
import StatusBar from '../StatusBar';
import TextWithIcon from '../TextWithIcon';
import TagsAndCustomFields from '../../../TagsAndCustomFields';

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  overflow: auto;
`;

function VoterConnected({ activity }) {
  return (
    <>
      <StatusBar bgColor="teal">
        <TextWithIcon>Active Call</TextWithIcon>
        <HangUpButton bgColor="teal" />
      </StatusBar>
      <Content>
        <Contact activity={activity} />
        <TagsAndCustomFields activity={activity} reportDuringCall />
      </Content>
    </>
  );
}

VoterConnected.propTypes = {
  activity: PropTypes.object.isRequired,
};

VoterConnected.defaultProps = {};

export default VoterConnected;
