import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/theme';
import Icon from '../common/Icon';

const PlayButton = styled.button`
  font-size: 50px;
  color: #ffffff;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1;
  background: none;
  outline: none;
  border: none;

  cursor: pointer;

  transition: opacity 150ms ease-out !important;
  :hover {
    opacity: 0.5 !important;
  }
`;

const MediaContainer = styled.video`
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  outline: 0;
`;

const Picture = styled.img`
  max-height: 375px;
  max-width: 100%;

  box-shadow: 0px 2px 2px rgba(128, 126, 121, 0.1);

  margin-top: 29px;
  margin-bottom: 25px;
  border-radius: 0.5rem;

  ${media.lg} {
    margin-right: 20px;
    margin-bottom: 74px;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const MediaPreview = ({ mediaUrl, mediaContentType }) => {
  const [showPlayButton, setPlayButton] = useState(true);
  const [showControls, setShowControls] = useState(false);
  const videoRef = useRef(null);

  const handlePlay = () => {
    videoRef.current.play();
    setPlayButton(false);
    setShowControls(true);
  };

  const mediaIsVideo = mediaUrl && mediaContentType && mediaContentType.match('video');
  const mediaIsImage = mediaUrl && mediaContentType && mediaContentType.match('image');

  if (mediaIsImage)
    return (
      <Wrapper>
        <Picture src={mediaUrl} alt="campaign-image-media" />
      </Wrapper>
    );

  if (mediaIsVideo)
    return (
      <Wrapper>
        {showPlayButton && (
          <PlayButton onClick={handlePlay} aria-label="Play video">
            <Icon name="Play" height="77" />
          </PlayButton>
        )}
        <MediaContainer ref={videoRef} controls={showControls} src={mediaUrl} type={'video/mp4'} />
      </Wrapper>
    );

  return null;
};

MediaPreview.propTypes = {
  mediaContentType: PropTypes.string.isRequired,
  mediaUrl: PropTypes.string.isRequired,
};

export default MediaPreview;
