import * as React from 'react';
import PropTypes from 'prop-types';
import { track } from '@web/services/analytics';
import styled from 'styled-components';

import Banner from '@web/components/Headcount/components/Banner';
import Button from '@web/components/Headcount/components/Button';
import HeroImage from '@web/components/Headcount/components/HeroImage';
import ShareLink from '@web/components/Headcount/components/ShareLink';
import VotingDates from '@web/components/Headcount/components/VotingDates';

import useIsDesktop from '@web/components/Headcount/hooks/useIsDesktop';
import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Center = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ $withMargin }) => ($withMargin ? '2rem' : '0')};
`;

function ReferralShowLink({ activity, backToStart, team }) {
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const isPartner = useIsPartner(team);

  track('HEADCOUNT_VIEW_WANT_TO_HELP', {
    activity_id: activity?.id,
    activity_title: activity?.title,
    activity_type: activity?.type,
    campaign_id: activity?.campaign?.id,
    campaign_name: activity?.campaign?.name,
    team_id: team?.id,
    team_name: team?.name,
  });

  const headcountMtvEnabled = React.useMemo(
    () => activity.campaign.headcount_mtv_enabled,
    [activity.campaign.headcount_mtv_enabled],
  );

  return (
    <>
      {headcountMtvEnabled && (
        <ShareLink
          activity={activity}
          referralLink={contactFormResult?.referral_link}
          team={team}
        />
      )}
      {isPartner && <Banner activity={activity} />}
      <VotingDates
        stateAbbreviation={contactFormResult?.state_abbrev}
        team={team}
        votingInfo={contactFormResult?.voting_info}
      />
      {!isDesktop && isPartner && !headcountMtvEnabled && (
        <HeroImage alt={team?.name} src={team?.incentives_img_url} />
      )}
      {!headcountMtvEnabled && (
        <ShareLink
          activity={activity}
          referralLink={contactFormResult?.referral_link}
          team={team}
        />
      )}
      <Center $withMargin={!headcountMtvEnabled}>
        <Button variant="secondary" onClick={backToStart}>
          {t('check_registration.headcount.startOver')}
        </Button>
      </Center>
    </>
  );
}

ReferralShowLink.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      cannabis_registration_enabled: PropTypes.bool,
      headcount_mtv_enabled: PropTypes.string,
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
    description: PropTypes.string.isRequired,
    id: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  backToStart: PropTypes.func.isRequired,
  team: PropTypes.object,
};

ReferralShowLink.defaultProps = {};

export default ReferralShowLink;
