import React from 'react';

const TwoRecruits = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={60}
      height={60}
      viewBox="0 0 60 60"
      fill="none"
      {...props}
    >
      <path
        d="M5.65891 48.8095C4.29156 48.8095 3.18311 47.701 3.18311 46.3337V42.1248C3.18311 40.772 3.53518 39.5281 4.23934 38.3933C4.9435 37.2585 5.87755 36.3935 7.0415 35.7983C9.53339 34.5648 12.0655 33.6393 14.6377 33.0217C17.21 32.4042 19.8225 32.0962 22.4751 32.0978C25.1278 32.0978 27.7402 32.4066 30.3125 33.0241C32.8847 33.6417 35.4168 34.5664 37.9087 35.7983C39.0743 36.3951 40.0091 37.2609 40.7133 38.3957C41.4174 39.5305 41.7687 40.7736 41.7671 42.1248V46.3337C41.7671 47.701 40.6586 48.8095 39.2913 48.8095H5.65891ZM49.0659 48.8095C47.6986 48.8095 46.5901 47.701 46.5901 46.3337V41.6473C46.5901 39.8966 46.0974 38.2151 45.1119 36.6028C44.1264 34.9905 42.7301 33.6082 40.9231 32.4559C42.9729 32.6947 44.9021 33.1029 46.7107 33.6807C48.5193 34.2584 50.2073 34.9643 51.7748 35.7983C53.2217 36.5941 54.327 37.479 55.0906 38.453C55.8543 39.4271 56.2361 40.4919 56.2361 41.6473V46.3337C56.2361 47.701 55.1276 48.8095 53.7603 48.8095H49.0659ZM22.4751 29.7104C19.8225 29.7104 17.5516 28.7754 15.6626 26.9053C13.7736 25.0352 12.8291 22.787 12.8291 20.1609C12.8291 17.5348 13.7736 15.2867 15.6626 13.4166C17.5516 11.5464 19.8225 10.6114 22.4751 10.6114C25.1278 10.6114 27.3986 11.5464 29.2876 13.4166C31.1766 15.2867 32.1211 17.5348 32.1211 20.1609C32.1211 22.787 31.1766 25.0352 29.2876 26.9053C27.3986 28.7754 25.1278 29.7104 22.4751 29.7104ZM46.5901 20.1609C46.5901 22.787 45.6456 25.0352 43.7566 26.9053C41.8676 28.7754 39.5968 29.7104 36.9441 29.7104C36.502 29.7104 35.9393 29.6603 35.2561 29.56C34.5728 29.4598 34.0101 29.3507 33.568 29.233C34.6532 27.9597 35.4876 26.5472 36.0711 24.9954C36.6547 23.4436 36.9457 21.8321 36.9441 20.1609C36.9441 18.4897 36.6531 16.8783 36.0711 15.3265C35.4892 13.7747 34.6548 12.3621 33.568 11.0889C34.1307 10.8899 34.6934 10.7602 35.2561 10.6997C35.8187 10.6392 36.3814 10.6098 36.9441 10.6114C39.5968 10.6114 41.8676 11.5464 43.7566 13.4166C45.6456 15.2867 46.5901 17.5348 46.5901 20.1609Z"
        fill="url(#paint0_linear_414_5643)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_414_5643"
          x1={23.3432}
          y1={5.83656}
          x2={23.3432}
          y2={50.9316}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopOpacity={0.54} />
        </linearGradient>
      </defs>
    </svg>
  );
};

TwoRecruits.propTypes = {};

TwoRecruits.defaultProps = {};

export default TwoRecruits;
