import { Radio, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import React, { Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledErrorMessage } from '@web/components/common/shared/FormInput';
import WarningMessage from './WarningMessage';
import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';

const Wrapper = styled.div`
  transition: all 300ms ease-in-out;
`;

const StyledRadioGroup = styled(Radio.Group)`
  &&& {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
`;

const StyledRadio = styled(Radio)`
  &&& {
    padding: 16px;
    width: 100%;
    white-space: normal;
    &:hover {
      cursor: unset;
    }

    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fonts.regular};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;

    ${smallBreakpoint} {
      font-size: 0.875rem;
      line-height: 1.25rem; /* 142.857% */
    }

    .ant-radio-inner {
      border-color: ${({ theme }) => theme.colors.blackL42};
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: ${({ theme }) => theme.colors.black} !important;
    }

    .ant-radio-inner:after {
      background-color: ${({ theme }) => theme.colors.black};
      width: 12px;
      height: 12px;
      margin-top: -2px;
      margin-left: -2px;
      border-radius: 12px;
    }
  }
`;

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 8px;
  border: 1px solid
    ${({ $picked, theme }) => ($picked ? theme.colors.newBlue : 'rgba(35, 41, 62, 0.16)')};
  background: ${({ $picked }) =>
    $picked ? 'rgba(144, 185, 246, 0.20)' : 'rgba(255, 255, 255, 0.05)'};
  align-self: stretch;
  transition: all 300ms ease-in-out;

  &:hover {
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  }
`;

const StyledCheckOutlined = styled(CheckOutlined)`
  &&& {
    position: absolute;
    right: 5px;
    top: calc(50% - 1.25rem / 2);
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.newBlue};

    svg {
      fill: ${({ theme }) => theme.colors.newBlue};
      stroke: ${({ theme }) => theme.colors.newBlue};
    }
  }
`;

const RadioGroupFormPartial = ({
  handleChange,
  name,
  options,
  answeredValue,
  disabledAnswers,
  index,
  values,
}) => {
  const answers = useMemo(
    () =>
      Object.entries(options)
        .map(([key, value]) => ({ ...value, key }))
        .sort((a, b) => a.position - b.position),
    [options],
  );

  const handleRadioChange = useCallback(
    e => {
      const target = {
        answerPosition: answers.findIndex(({ key }) => key === e.target.value),
        name: e.target.name,
        questionPosition: index,
        value: e.target.value,
      };
      handleChange(e, target);
    },
    [handleChange, index, answers],
  );

  return (
    <Wrapper>
      <StyledRadioGroup name={name} onChange={handleRadioChange} value={answeredValue}>
        <Space direction="vertical">
          {answers.map(({ key, value, position }) => (
            <Fragment key={key}>
              <InnerContainer
                $picked={key === answeredValue}
                $disabled={disabledAnswers[index].includes(position)}
              >
                <StyledRadio value={key} disabled={disabledAnswers[index].includes(position)}>
                  {value} {key === answeredValue && <StyledCheckOutlined />}
                </StyledRadio>
              </InnerContainer>
              <WarningMessage
                picked={key === answeredValue}
                questionPosition={index}
                answerPosition={position}
                values={values}
              />
            </Fragment>
          ))}
        </Space>
      </StyledRadioGroup>
      <StyledErrorMessage name={name} />
    </Wrapper>
  );
};

RadioGroupFormPartial.propTypes = {
  answeredValue: PropTypes.string,
  disabledAnswers: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.object,
  values: PropTypes.object.isRequired,
};

export default RadioGroupFormPartial;
