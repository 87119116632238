import amplitude from 'amplitude-js';
import ReactFBPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import ReactSnapchatPixel from 'react-snapchat-pixel';
import TagManager from '@sooro-io/react-gtm-module';
// TODO: we should have a shared fns directory for these:
import { dbg } from '../../admin/utils/dbg';

amplitude.init(process.env.AMPLITUDE_API_KEY);

export const identify = (id, options) => {
  dbg(['IDENTIFY', id, options]);
  if (id) {
    amplitude.setUserId(id);
    if (options) {
      amplitude.setUserProperties(options);
    }
  } else {
    amplitude.clearUserProperties();
  }
};

export const track = (event, options) => {
  dbg(['TRACK', event, options]);
  if (options) {
    amplitude.logEvent(event, options);
  } else {
    amplitude.logEvent(event);
  }
};

// To prevent installing a pixel multiple times, let's track installed pixel ids
const activePixelIds = {};

export const trackPageViewWithDynamicIDs = ({
  fbPixelId,
  gaTrackingId,
  snapchatPixelId,
  gtmPixelId,
}) => {
  if (fbPixelId) {
    if (!activePixelIds.fbPixelId) {
      activePixelIds.fbPixelId = fbPixelId;
      ReactFBPixel.init(fbPixelId);
    }
    if (activePixelIds.fbPixelId === fbPixelId) {
      ReactFBPixel.pageView();
    }
  }
  if (gaTrackingId) {
    if (!activePixelIds.gaTrackingId) {
      activePixelIds.gaTrackingId = gaTrackingId;
      ReactGA.initialize(gaTrackingId);
    }
    if (activePixelIds.gaTrackingId === gaTrackingId) {
      ReactGA.send({
        hitType: 'page_view',
        page: window.location.pathname + window.location.search,
      });
    }
  }
  if (snapchatPixelId) {
    if (!activePixelIds.snapchatPixelId) {
      activePixelIds.snapchatPixelId = snapchatPixelId;
      ReactSnapchatPixel.init(snapchatPixelId);
    }
    if (activePixelIds.snapchatPixelId === snapchatPixelId) {
      ReactSnapchatPixel.pageView();
    }
  }
  if (gtmPixelId) {
    if (!activePixelIds.gtmPixelId) {
      activePixelIds.gtmPixelId = gtmPixelId;
      TagManager.initialize({ gtmId: gtmPixelId });
    }

    if (activePixelIds.gtmPixelId === gtmPixelId) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'page_view',
          label: document.title,
          path: window.location.pathname + window.location.search,
        },
      });
    }
  }
};

export const trackEventViewWithDynamicIDs = ({
  fbPixelId,
  gaTrackingId,
  snapchatPixelId,
  actionName,
  categoryName,
  gtmPixelId,
}) => {
  if (fbPixelId) {
    if (!activePixelIds.fbPixelId) {
      activePixelIds.fbPixelId = fbPixelId;
      ReactFBPixel.init(fbPixelId);
    }
    if (activePixelIds.fbPixelId === fbPixelId) {
      ReactFBPixel.track(actionName, { category: categoryName });
    }
  }
  if (gaTrackingId) {
    if (!activePixelIds.gaTrackingId) {
      activePixelIds.gaTrackingId = gaTrackingId;
      ReactGA.initialize(gaTrackingId);
    }
    if (activePixelIds.gaTrackingId === gaTrackingId) {
      ReactGA.send({
        hitType: actionName,
        page: window.location.pathname + window.location.search,
        title: categoryName,
      });
    }
  }
  if (snapchatPixelId) {
    if (!activePixelIds.snapchatPixelId) {
      activePixelIds.snapchatPixelId = snapchatPixelId;
      ReactSnapchatPixel.init(snapchatPixelId);
    }
    if (activePixelIds.snapchatPixelId === snapchatPixelId) {
      ReactSnapchatPixel.track(actionName, { category: categoryName });
    }
  }

  if (gtmPixelId) {
    if (!activePixelIds.gtmPixelId) {
      activePixelIds.gtmPixelId = gtmPixelId;
      TagManager.initialize({ gtmId: gtmPixelId });
    }

    if (activePixelIds.gtmPixelId === gtmPixelId) {
      TagManager.dataLayer({
        dataLayer: {
          category: categoryName,
          event: actionName,
        },
      });
    }
  }
};
