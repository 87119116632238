import * as React from 'react';
import axios from 'axios';
import sendError from '@web/utils/sendError';

import { EXIT_ACTIVITY } from '@web/reducers/dialerReducer/dialerMachine';
import { init, machineTransition, setActivityId } from '@web/reducers/dialerReducer';
import { useDispatch, useSelector } from 'react-redux';

function useExitActivity(activityId) {
  const dispatch = useDispatch();
  const { currentState } = useSelector(state => state.dialer);

  React.useEffect(() => {
    // Set the activityId when this mounts
    dispatch(setActivityId(activityId));
    // On unmount
    return () => {
      endSession();
      dispatch(init());
      dispatch(machineTransition(EXIT_ACTIVITY));
    };
    // eslint-disable-next-line
  }, []);

  function endSession() {
    const dontSendRequest = ['dialingVolunteer'];
    if (dontSendRequest.includes(currentState)) {
      return;
    }
    const url = `/api/v2/activities/${activityId}/volunteer_voice_call`;
    axios
      .delete(url)
      .then(res => {
        // We don't do anything here
      })
      .catch(err => {
        // MTS - 2/17/22 We've decided not to send errors to Sentry if this fails.
        // The most likely scenario is that the user has already clicked the "End
        // Session" button which ended their session in a previous state. This is
        // a catch-all for when a user leaves the activity in any other way.

        // MTS - We'll still send an error if there isn't an activity id just in case.
        if (!activityId) {
          sendError('Attempted to exit activity without providing an activity id.', err);
        }
      });
  }
}

export default useExitActivity;
