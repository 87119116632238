import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import useIsMobile from '@web/components/VoterRegistration/hooks/useIsMobile';
import FanOutLogoIcon from '@web/components/FanOut/components/icons/FanOutLogo';
import DonateButton from '@web/components/FanOut/components/DonateButton';
import Drawer from '@web/components/FanOut/components/Drawer';

const StyledHeader = styled.header`
  top: 0;
  z-index: 99;
  position: sticky;
  width: 100%;
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 40px;
  box-shadow: -3px 4px 34px 0px rgba(0, 0, 0, 0.05);

  ${smallBreakpoint} {
    padding: 0 20px;
  }
`;

const FanOutLogo = styled(FanOutLogoIcon)`
  width: 107px;
  height: 20px;
`;

const LinksContainer = styled.div`
  display: flex;
  gap: 56px;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  outline: 0;
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1.125rem;
  font-weight: 400;
  border-bottom: ${({ $isActive }) => ($isActive ? '2px solid #90b9f6' : 'none')};
  cursor: pointer;

  :hover,
  .active {
    color: ${({ theme }) => theme.colors.newBlue};
    text-decoration: none;
    text-decoration-color: unset;
  }
`;

const Header = ({ activity, team }) => {
  const { search, state } = useLocation();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [cookies] = useCookies();

  const fanOutCookies = useMemo(() => !!cookies?.fan_out_auth, [cookies?.fan_out_auth]);

  return (
    <>
      <StyledHeader>
        <StyledLink to={{ search, state: '' }}>
          <FanOutLogo />
        </StyledLink>
        {isMobile ? (
          <Drawer fanOutCookies={fanOutCookies} activity={activity} team={team} />
        ) : (
          <>
            <LinksContainer>
              {fanOutCookies && (
                <StyledLink $isActive={state === 'activity'} to={{ search, state: 'activity' }}>
                  {t('fan_out.header.my_activity')}
                </StyledLink>
              )}
              <StyledLink $isActive={state === 'sweepstakes'} to={{ search, state: 'sweepstakes' }}>
                {t('fan_out.header.all_sweepstakes')}
              </StyledLink>
              <StyledLink $isActive={state === 'terms'} to={{ search, state: 'terms' }}>
                {t('fan_out.header.terms')}
              </StyledLink>
            </LinksContainer>
            <DonateButton activity={activity} team={team} />
          </>
        )}
      </StyledHeader>
    </>
  );
};

Header.propTypes = {
  activity: PropTypes.object,
  team: PropTypes.object,
};

Header.defaultProps = {};

export default Header;
