import { Input, Modal, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getLocalizedOnboardingData } from 'web/utils/other';
import logo from '../../images/app-logo-primary.png';
import { track } from '../../services/analytics';
import { campaigns, users as usersApi } from '../../services/api';
import { AuthContext } from '../../utils/context';
import ImpactiveButton from '../ImpactiveButton';
import { setAppLanguage } from '../i18n';
const USER_DETAIL_SCREEN = 'user_detail_screen';
const INSTRUCTIONS_SCREEN = 'instructions_screen';

const HeaderMedia = styled.div`
  display: flex;
  justify-content: center;
  margin: 1em 1em 2em 1em;
`;

/**
 * Modal dialog that shows welcome instructions when user intially logs in/signs up
 */
const OnboardingModal = ({ onCloseCallback }) => {
  const { t } = useTranslation();
  const { token, user, setUser, isLockedToCampaign } = useContext(AuthContext);
  const [displayModal, setDisplayModal] = useState(false);
  const [campaign, setCampaign] = useState(null);

  const [screen, setScreen] = useState(USER_DETAIL_SCREEN);
  const [error, setError] = useState(null);

  const [first, setFirst] = useState(null);
  const [last, setLast] = useState(null);
  const [phone, setPhone] = useState(null);
  const [zip, setZip] = useState(null);
  const [locale, setLocale] = useState('en');

  const onPhoneChangeHandler = number => {
    // Add `+` prefix if user starts entering digits
    if (/^\d{1}$/.test(number)) {
      setPhone(`+1${number}`);
      return;
    }

    // Only allow numbers, starting with + symbol
    if (/^$|^(?:\+1)\d*$/.test(number)) {
      setPhone(number);
      return;
    }
  };

  const onChangeZip = code => {
    // validate input, only allow numbers
    if (/^\d{0,5}$/.test(code)) setZip(code);
  };

  const onUserDetailSubmit = e => {
    e.preventDefault();
    if (!first || !last || !phone || !locale) {
      //
      setError('First, Last, Phone and Locale are required');
      return;
    }

    usersApi
      .updateMe({ first_name: first, last_name: last, locale, phone, supplied_zip_code: zip })
      .then(() => {
        setScreen(INSTRUCTIONS_SCREEN);
      });
  };

  const closeModal = () => {
    if (screen === USER_DETAIL_SCREEN) {
      //user info required
      return;
    }

    track('CLOSED_ONBOARDING_MODAL');
    usersApi.updateMe({ onboarding_viewed: true }).then(res => {
      const {
        data: { data: current_user },
      } = res;
      setUser(current_user);
      setDisplayModal(false);
      onCloseCallback();
    });
  };

  useEffect(() => {
    track('VIEW_INSTRUCTIONS_SCREEN');
  }, []);

  useEffect(() => {
    // We only care about onboarding instructions for logged in users
    if (!token || !user) return;

    const { onboarding_viewed, first_name, last_name, phone, supplied_zip_code } = user;

    // Only continue if user has not seen welcome instructions yet
    if (onboarding_viewed) return;

    setFirst(first_name);
    setLast(last_name);
    setPhone(phone);
    setZip(supplied_zip_code);

    const campaignId = user.locked_campaign_id || user.last_campaign_id;

    if (!campaignId) {
      // Fetch campaign if locked in or coming from campaign screen, otherwise just display default welcome screen
      setDisplayModal(true);
      return;
    }

    campaigns
      .getCampaign({ id: campaignId })
      .then(({ data: { data } }) => {
        const localizedData = getLocalizedOnboardingData(data);

        setCampaign(localizedData);
      })
      .then(() => {
        setDisplayModal(true);
      });

    // Retrigger effect whenever context flags are updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLockedToCampaign, user]);

  const userDetailContent = (
    <>
      <div className="h3 my-3 text-center">Welcome onboard!</div>

      <div className="text-center">We would love to get to know you better!</div>

      {!!error && <div className="text-error text-center mt-2">{error}</div>}

      <Input
        className="mt-4"
        autoFocus
        name="First name"
        onChange={e => setFirst(e.target.value)}
        placeholder={t('placeholders.first_name')}
        value={first}
      />

      <Input
        className="mt-3"
        name="Last name"
        onChange={e => setLast(e.target.value)}
        placeholder={t('placeholders.last_name')}
        value={last}
      />

      <Input
        className="mt-3"
        name="Phone"
        onChange={e => onPhoneChangeHandler(e.target.value)}
        placeholder={t('placeholders.phone_number')}
        value={phone}
      />

      <Input
        className="my-3"
        name="Zip code"
        placeholder={t('placeholders.zip')}
        value={zip}
        onChange={e => onChangeZip(e.target.value)}
      />

      <Select
        className="mb-3"
        style={{ width: '100%' }}
        onChange={val => {
          setAppLanguage(val);
          setLocale(val);
        }}
        value={locale}
      >
        <Select.Option value="en">English</Select.Option>
        <Select.Option value="es">Español</Select.Option>
        <Select.Option value="ko">한국어</Select.Option>
      </Select>

      <ImpactiveButton size="large" onClick={onUserDetailSubmit}>
        Continue
      </ImpactiveButton>
    </>
  );

  const instructionContent = (
    <>
      <HeaderMedia>
        {campaign && campaign.instructions_video_url && (
          <video controls width="100%" height="320">
            <source src={campaign.instructions_video_url} />
            <p>
              {t('campaign.onboarding.video_not_supported')}
              <a href={campaign.instructions_video_url}>{t('common.link')}</a>
            </p>
          </video>
        )}

        {campaign && !campaign.instructions_video_url && campaign.url_hero_img && (
          <img alt="hero" src={campaign.url_hero_img} style={{ width: 250 }} />
        )}

        {!campaign && <img alt="impactive" src={logo} style={{ width: 250 }} />}
      </HeaderMedia>

      <h1>{t('campaign.onboarding.how_to_help')}</h1>
      <p>
        {(campaign && campaign.instructions_text) || t('campaign.onboarding.fallback_instructions')}
      </p>
      <br />
      <h2>{t('campaign.onboarding.first_action')}</h2>
      <p style={{ marginTop: 10 }}>{t('campaign.onboarding.first_action_instructions')}</p>

      <ImpactiveButton size="large" onClick={closeModal}>
        {t('campaign.onboarding.get_started')}
      </ImpactiveButton>
    </>
  );

  const bodyContent = screen === USER_DETAIL_SCREEN ? userDetailContent : instructionContent;

  return (
    <Modal visible={displayModal} closable={false} footer={null}>
      <div className="d-flex flex-column align-items-center">{bodyContent}</div>
    </Modal>
  );
};

OnboardingModal.propTypes = {
  onCloseCallback: PropTypes.func,
};

OnboardingModal.defaultProps = {
  onCloseCallback: () => {},
};

export default OnboardingModal;
