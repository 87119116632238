import PropTypes from 'prop-types';
import React from 'react';

function Play({ fill = '#FFFFFF', ...rest }) {
  return (
    <svg viewBox="0 0 55 77" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6.16597C0 1.1914 5.70556 -1.62246 9.65231 1.40565L51.7956 33.7397C54.9258 36.1413 54.9258 40.8587 51.7955 43.2603L9.6523 75.5944C5.70555 78.6225 0 75.8086 0 70.834V6.16597Z"
        fill={fill}
      />
    </svg>
  );
}

Play.propTypes = {
  fill: PropTypes.string,
};

Play.defaultProps = {
  fill: '#FFFFFF',
};

export default Play;
