import * as React from 'react';
import PropTypes from 'prop-types';

function CircleCheck({ fill, ...rest }) {
  return (
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14 0C6.26875 0 0 6.26875 0 14C0 21.7313 6.26875 28 14 28C21.7313 28 28 21.7313 28 14C28 6.26875 21.7313 0 14 0ZM14 25.625C7.58125 25.625 2.375 20.4188 2.375 14C2.375 7.58125 7.58125 2.375 14 2.375C20.4188 2.375 25.625 7.58125 25.625 14C25.625 20.4188 20.4188 25.625 14 25.625Z"
        fill={fill}
      />
      <path
        d="M14 25.625C7.58125 25.625 2.375 20.4188 2.375 14C2.375 7.58125 7.58125 2.375 14 2.375C20.4188 2.375 25.625 7.58125 25.625 14C25.625 20.4188 20.4188 25.625 14 25.625Z"
        fill={fill}
      />
      <path
        d="M19.8427 9.03125H18.3771C18.0583 9.03125 17.7552 9.18437 17.5677 9.44687L12.6552 16.2594L10.4302 13.1719C10.2427 12.9125 9.94268 12.7563 9.62081 12.7563H8.15518C7.95206 12.7563 7.83331 12.9875 7.95206 13.1531L11.8458 18.5531C11.9378 18.6815 12.0591 18.7861 12.1995 18.8583C12.34 18.9305 12.4957 18.9681 12.6536 18.9681C12.8116 18.9681 12.9672 18.9305 13.1077 18.8583C13.2482 18.7861 13.3694 18.6815 13.4614 18.5531L20.0427 9.42813C20.1646 9.2625 20.0458 9.03125 19.8427 9.03125Z"
        fill="white"
      />
    </svg>
  );
}

CircleCheck.propTypes = {
  fill: PropTypes.any,
  height: PropTypes.number,
};

CircleCheck.defaultProps = {
  fill: '#52C41A',
  height: 28,
};

export default CircleCheck;
