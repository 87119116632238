import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Description, Title } from '@web/components/VoterRegistration/newComponents/TextStyles';
import Message from '@web/components/VoterRegistration/newComponents/VotingInformation/Message';
import Status from '@web/constants/contactRegistrationStatusTypes';
import { largeBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import BackButton from '@web/components/VoterRegistration/newComponents/Button/BackButton';
import { useTrackFanOut } from '@web/components/FanOut/hooks/useFanOutAmplitude';

const StyledVotingDetails = styled.div``;

const ButtonRow = styled.div`
  margin-top: 40px;
  ${largeBreakpoint} {
    display: none;
  }
`;

const StyledBackButton = styled(BackButton)`
  text-decoration: underline;
`;

const VotingDetails = ({ handleClose, contact, activity, team }) => {
  const { t } = useTranslation();
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  useTrackFanOut({ activity, name: 'FAN_OUT_VIEW_VOTING_DETAILS', team });

  const registered = useMemo(
    () =>
      contact?.voting_info
        ? contact.latest_registration_status === Status.REGISTERED
        : contactFormResult.registration_status === Status.REGISTERED,
    [contactFormResult, contact],
  );
  const descriptionText = useMemo(
    () =>
      registered
        ? t('voting_information.descriptionRegistered')
        : t('voting_information.descriptionNotRegisteredFanOut'),
    [registered, t],
  );

  return (
    <StyledVotingDetails>
      <Title>{t('voting_information.title')}</Title>
      <Description>{descriptionText}</Description>
      <Message
        registered={registered}
        disableCollapse
        contact={contact}
        activity={activity}
        team={team}
      />
      <ButtonRow>
        <StyledBackButton onClick={handleClose} showIcon={false}>
          {t('common.close')}
        </StyledBackButton>
      </ButtonRow>
    </StyledVotingDetails>
  );
};

VotingDetails.propTypes = {
  activity: PropTypes.object.isRequired,
  contact: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  team: PropTypes.object,
};

VotingDetails.defaultProps = {};

export default VotingDetails;
