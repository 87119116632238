import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@web/components/DialerActivity/components/Button';

const StyledSelectionItem = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 7px;
  margin-bottom: 16px;
  padding: 24px 34px 41px;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 23px;
`;

const Description = styled.p`
  font-size: 15px;
  margin-bottom: 26px;
  margin-top: 6px;
`;

function SelectionItem({ buttonText, description, title, to }) {
  return (
    <StyledSelectionItem>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Button style={{ maxWidth: 227, textTransform: 'uppercase' }} to={to}>
        {buttonText}
      </Button>
    </StyledSelectionItem>
  );
}

SelectionItem.propTypes = {
  buttonText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

SelectionItem.defaultProps = {};

export default SelectionItem;
