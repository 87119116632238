import * as React from 'react';
// import PropTypes from 'prop-types';

function Location(props) {
  return (
    <svg height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.04771 16.7735C1.45039 10.1088 0.597046 9.42479 0.597046 6.97539C0.597046 3.62025 3.31691 0.900391 6.67205 0.900391C10.0272 0.900391 12.747 3.62025 12.747 6.97539C12.747 9.42479 11.8937 10.1088 7.29638 16.7735C6.99469 17.2094 6.34938 17.2093 6.04771 16.7735ZM6.67205 9.50664C8.07002 9.50664 9.2033 8.37337 9.2033 6.97539C9.2033 5.57741 8.07002 4.44414 6.67205 4.44414C5.27407 4.44414 4.1408 5.57741 4.1408 6.97539C4.1408 8.37337 5.27407 9.50664 6.67205 9.50664Z"
        fill="#23293E"
      />
    </svg>
  );
}

Location.propTypes = {};

Location.defaultProps = {};

export default Location;
