import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Description, Title } from '@web/components/VoterRegistration/newComponents/TextStyles';
import DesktopLinks from './DesktopLinks';
import MobileShare from './MobileShare';
import ShareImage from './ShareImage';

import {
  largeBreakpoint,
  smallBreakpoint,
} from '@web/components/VoterRegistration/newComponents/breakpoints';
import useDescriptionPosition from './useDescriptionPosition';
import { useTranslation } from 'react-i18next';

const ButtonArea = styled.div`
  display: flex;
  margin-top: 29px;
  width: 100%;
`;

const StyledBox = styled.div`
  align-items: center;
  background: linear-gradient(
      110.61deg,
      rgba(108, 203, 218, 0.2) 6.98%,
      rgba(108, 203, 218, 0.006) 100.54%
    ),
    #ffffff;
  border: 1px solid #6ccbda;
  border-radius: 12px;
  box-shadow: 8px 8px 32px rgba(0, 41, 64, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 2rem;
  transition: opacity 250ms ease-out, transform 250ms ease-out;
  ${smallBreakpoint} {
    opacity: ${({ $ready }) => ($ready ? 1 : 0)};
    margin-top: 0;

    position: absolute;
    z-index: 2;
    left: 24px;
    right: 24px;
    top: 197px;
    transform: translateY(${({ $translateY }) => $translateY}px);
  }
  ${largeBreakpoint} {
    border-radius: 0.25rem;
  }
`;

const centerMobile = css`
  text-align: center;
  ${largeBreakpoint} {
    border-radius: 0.25rem;
  }
`;

const StyledTitle = styled(Title)`
  ${centerMobile}
  margin-top: 25px;
  font-size: 18px;
  ${largeBreakpoint} {
  }
`;
const StyledDescription = styled(Description)`
  ${centerMobile}
  max-width: 236px;
  margin-bottom: 11px;
  ${largeBreakpoint} {
    max-width: initial;
  }
`;

function Box(props) {
  const { t } = useTranslation();
  const showMobileShare = navigator?.share;

  // The next three lines are for mobile only
  const { bottom, ready } = useDescriptionPosition();
  const defaultPosition = 197;
  const translateY = ready ? bottom + 32 - defaultPosition : -10;

  return (
    <StyledBox $ready={ready} $translateY={translateY}>
      <ShareImage />
      <StyledTitle forwardedAs="h2">{t('check_registration.referral.ctaTitle')}</StyledTitle>
      <StyledDescription>{t('check_registration.referral.ctaDescription')}</StyledDescription>
      <ButtonArea>
        {showMobileShare ? <MobileShare {...props} /> : <DesktopLinks {...props} />}
      </ButtonArea>
    </StyledBox>
  );
}

Box.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  referralLink: PropTypes.string.isRequired,
  team: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

Box.defaultProps = {};

export default Box;
