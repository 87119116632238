import PropTypes from 'prop-types';
import styled from 'styled-components';

function getColor(color, theme) {
  switch (color) {
    case 'darkBlue':
      return theme.colors.darkBlue;
    case 'yellow':
      return '#E8BC72';
    case 'teal':
      return theme.colors.darkTeal;
    case 'red':
      return '#E37D70';
    default:
      return theme.colors.darkBlue;
  }
}

const StatusBar = styled.div`
  align-items: center;
  background-color: ${({ bgColor, theme }) => getColor(bgColor, theme)};
  color: white;
  display: flex;
  font-family: ${({ theme }) => theme.fonts.black};
  font-size: 14px;
  height: 70px;
  justify-content: space-between;
  letter-spacing: 1.2px;
  padding: 24px;
  text-transform: uppercase;
`;

StatusBar.propTypes = {
  bgColor: PropTypes.oneOf(['darkBlue', 'red', 'teal', 'yellow']),
};

StatusBar.defaultProps = {
  bgColor: 'darkBlue',
};

export default StatusBar;
