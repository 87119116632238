import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Select } from 'formik-antd';

import { selectorStyle } from '../sharedStyles';

const { Option } = Select;

export const StyledSelect = styled(Select)`
  ${selectorStyle}

  &&& {
    .ant-select-selector {
      ${({ $withoutIcon }) => $withoutIcon && `padding-left: 16px`}
    }
  }
`;

const OptionContent = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const SelectInput = ({ options, ...props }) => (
  <StyledSelect {...props}>
    {options.map((item, index) => (
      <Option key={index} value={item.value} label={item.label}>
        <OptionContent>
          {item.icon}
          {item.label}
        </OptionContent>
      </Option>
    ))}
  </StyledSelect>
);

SelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
};

export default SelectInput;
