import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { users } from '../../services/api';
import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import BackButton from '../common/BackButton';
import IdVotersForm from './IdVotersForm';
import IdVotersList from './IdVotersList';

const HeaderAndDescription = styled.div`
  padding: 0px 16px;
`;
const Header = styled.div`
  font-size: 22px;
  font-family: ${({ theme }) => theme.fonts.semibold};
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-size: 12px;
  margin-bottom: 20px;
`;

// Search the national voter file with the following parameters to find possible matches for either a contact or someone you've just met at an event
const IdVoters = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const history = useHistory();
  const { contact_id, firstName, lastName, activityId, userActivityId, campaignId } = state || {};
  useDocumentTitle(t('titles.idvoters'));

  // POST form to get a list of all possible voters, then navigate to search results page to select one. Could also pass the parameters to the search page and POST there since you may want to filter/sort results on the search page.
  // All fields are required
  // Keep in mind: this may be to match a user's contact or it may be to find someone who was just met or it may be used to add someone new who was just met
  const [voters, setVoters] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const [searched, setSearched] = useState(false);

  const handleFormSubmit = async (formData, { setSubmitting }) => {
    setLoadingItems(true);
    setSearched(true);
    await users.searchVoters(formData).then(({ data }) => {
      setVoters(data);
      setSubmitting(false);
      setLoadingItems(false);
    });
  };

  const isFriend = !!contact_id;
  const contactId = contact_id;
  const pushToVoterView = voter => {
    history.push({
      pathname: '/voter/view',
      state: {
        activityId,
        campaignId,
        contactId,
        isFriend,
        userActivityId,
        voter,
        voterbaseId: voter?.voterbase_id,
      },
    });
  };

  return (
    <>
      <AppHeader title={t('idvoters.header')} />
      <AppContainer>
        <div className="px-3">
          <div className="p-3">
            <BackButton />
          </div>
          <HeaderAndDescription>
            <Header>{t('idvoters.header')}</Header>
            <Description>{t('inbox.report.description')}</Description>
          </HeaderAndDescription>
          <IdVotersForm
            isFriend={!!contact_id}
            campaignId={campaignId}
            activityId={activityId}
            userActivityId={userActivityId}
            firstName={firstName}
            lastName={lastName}
            onSubmit={handleFormSubmit}
          />
          <div className="mt-4">
            <IdVotersList
              isFriend={!!contact_id}
              campaignId={campaignId}
              activityId={activityId}
              userActivityId={userActivityId}
              loading={loadingItems}
              show={searched}
              voters={voters}
              contactId={contact_id}
              onListItemClick={pushToVoterView}
            />
          </div>
        </div>
      </AppContainer>
    </>
  );
};

IdVoters.propTypes = {};

export default IdVoters;
