import { List } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { campaigns as campaignsAPI } from '../../services/api';
import { Campaign } from '../ListItems';
import Loading from '../common/Loading';
import { replaceDefaultCampaignImage } from '@web/utils/string';

const StyledList = styled(List)`
  width: 100%;
  .ant-list-item {
    border-bottom: none !important;
  }
`;

const CategoryList = ({ category }) => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState();

  const loadCategoryCampaigns = () => {
    // Only load data once
    if (!campaigns) {
      setLoading(true);
      campaignsAPI
        .getCategoryCampaigns(category.id)
        .then(({ data: { data } }) => {
          setCampaigns(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    loadCategoryCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCampaigns(null);
  }, [category]);

  if (loading) {
    return <Loading centered />;
  }

  return (
    <StyledList
      dataSource={campaigns}
      split={false}
      renderItem={(item, index) => {
        const { name, slogan, slug, url_profile_img, causes } = item;
        return (
          <Campaign
            name={name}
            slogan={slogan}
            slug={slug}
            logo={replaceDefaultCampaignImage(url_profile_img)}
            causes={causes}
            key={index}
          />
        );
      }}
    />
  );
};

CategoryList.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default CategoryList;
