import * as React from 'react';
import PropTypes from 'prop-types';
import { track } from '@web/services/analytics';
import axios from 'axios';
import styled from 'styled-components';

import Banner from '@web/components/Headcount/components/Banner';
import Button from '@web/components/Headcount/components/Button';
import DotLoader from '@web/components/DotLoader';
import Text from '@web/components/Headcount/components/Text';
import VoterItem from './VoterItem';

import { setSelectedVoter } from '@web/reducers/voterRegistration';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';
import useVoterRegistrationApi from '@web/components/Headcount/hooks/useVoterRegistrationApi';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing.wider};
`;

const NoneOfThese = styled.div`
  margin-left: auto;
`;

const StyledSelectVoter = styled.div``;

const VoterList = styled.ul`
  margin: 0;
  padding: 0;
`;

function SelectVoter({ activity, nextPage, notRegistered, previousPage, team }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [error, setError] = React.useState(null);
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const { handleError } = useVoterRegistrationApi(activity);
  const [loading, setLoading] = React.useState(true);
  const [voters, setVoters] = React.useState([]);
  const isPartner = useIsPartner(team);

  track('HEADCOUNT_VIEW_SELECT_VOTER', {
    activity_id: activity?.id,
    activity_title: activity?.title,
    activity_type: activity?.type,
    campaign_id: activity?.campaign?.id,
    campaign_name: activity?.campaign?.name,
    team_id: team?.id,
    team_name: team?.name,
  });

  const trackNotFound = async () => {
    track('HEADCOUNT_NOT_FOUND', {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      team_id: team?.id,
      team_name: team?.name,
    });
  };

  function handleNotRegistered(showBanner) {
    trackNotFound();
    notRegistered(showBanner);
  }

  React.useEffect(() => {
    fetchVoters();
    // eslint-disable-next-line
  }, []);

  function fetchVoters() {
    axios
      .post(`/api/v2/client/voter_registrations/${contactFormResult?.id}/possible_voters`, {
        activity_id: activity.id,
        campaign_id: activity.campaign.id,
        h: contactFormResult.encode_id,
      })
      .then(({ data }) => {
        setVoters(data);
        // MTS - If we don't get any results then we switch to the
        // "Not Registered" flow.
        if (data.length === 0) {
          handleNotRegistered(true);
        }
      })
      .catch(err => {
        const errorMessage = handleError(err);
        setError(errorMessage);
      })
      .then(() => {
        setLoading(false);
      });
  }

  function handleSelect(voter) {
    setLoading(true);
    axios
      .patch(`/api/v2/client/voter_registrations/${contactFormResult?.id}/set_voter`, {
        activity_id: activity.id,
        campaign_id: activity.campaign.id,
        h: contactFormResult?.encode_id,
        voter_id: voter.voterbaseId,
      })
      .then(({ data }) => {
        dispatch(setSelectedVoter(voter));
        nextPage();
      })
      .catch(err => {
        const errorMessage = handleError(err);
        setError(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getVoterProps(voter) {
    const firstName = voter?.['vb.tsmart_first_name'];
    const lastName = voter?.['vb.tsmart_last_name'];
    const city = voter?.['vb.tsmart_city'];
    const state = voter?.['vb.tsmart_state'];
    const ageRange = voter?.['vb.voterbase_age'];
    const addressLine1 = voter?.['vb.tsmart_full_address'];
    const voterbaseId = voter?.['voterbase_id'];
    const zip = voter?.['vb.tsmart_zip'];

    return {
      addressLine1,
      addressLine2: `${city}, ${state}`,
      ageRange,
      city: city,
      // MTS - .toLowerCase() required for css text-transform to work
      name: `${firstName} ${lastName}`.toLowerCase(),
      state: state,
      voterbaseId,
      zip,
    };
  }

  if (loading) {
    return <DotLoader centered color="black" />;
  }

  if (error) {
    return (
      <Text color="red" variant="f3">
        {error}
      </Text>
    );
  }

  const titleKey = voters.length === 1 ? 'titleForOne' : 'titleForMany';

  return (
    <StyledSelectVoter>
      {isPartner && <Banner activity={activity} />}
      <Text variant="f2">
        {t(`check_registration.headcount.selectVoter.${titleKey}`, { count: voters.length })}
      </Text>
      <VoterList>
        {voters.map((voter, i) => (
          <VoterItem key={i} onSelect={handleSelect} {...getVoterProps(voter)} />
        ))}
      </VoterList>
      <Row>
        <Button onClick={previousPage} style={{ paddingLeft: 0 }} type="button" variant="tertiary">
          {t('common.back')}
        </Button>
        <NoneOfThese>
          <Button onClick={() => handleNotRegistered(false)} variant="secondary">
            {t('check_registration.headcount.selectVoter.noneOfThese')}
          </Button>
        </NoneOfThese>
      </Row>
    </StyledSelectVoter>
  );
}

SelectVoter.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      cannabis_registration_enabled: PropTypes.bool,
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
  }),
  nextPage: PropTypes.func.isRequired,
  notRegistered: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  team: PropTypes.object,
};

SelectVoter.defaultProps = {};

export default SelectVoter;
