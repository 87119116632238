import React from 'react';
import i18n from '@web/components/i18n';

const { t } = i18n;

const getLastElectionYear = () => {
  const currentYear = new Date().getFullYear();
  return currentYear % 2 === 0 ? currentYear : currentYear - 1;
};

/**
 * Voterfile data comes in an Object, that has votes as "vb.vf_g2020" for general, and "vb.vf_p2020" for primary.
 * This function turns it into an array of objects, with a schema of:
 *  {
 *    year: 2020,
 *    type: "Primary",
 *    voted: true,
 *  }
 *  */
export const checkForElections = (voterFile, includePrimary = false) => {
  const lastElections = getLastElectionYear();

  const votedYears = Object.keys(voterFile).filter(
    key => key.startsWith('vb.vf_g2') || key.startsWith('vb.vf_p2'),
  );

  const elections = Array.from({ length: lastElections - 2008 + 2 })
    .map((v, index) => {
      const isGeneral = index % 2 === 0;
      const year = lastElections - (isGeneral ? index : index - 1);
      // specific case for 2024 election is described below
      if (year === 2024) return null;

      if (!isGeneral && !includePrimary) return null;

      return {
        type: isGeneral ? 'General' : 'Primary',
        voted: votedYears.includes(isGeneral ? `vb.vf_g${year}` : `vb.vf_p${year}`),
        year,
      };
    })
    .filter(Boolean)
    .sort((a, b) => {
      if (a.type > b.type) {
        return 1;
      }
      if (a.type < b.type) {
        return -1;
      }
      return 0;
    });

  elections.unshift(
    {
      ballot_status: getBallotStatus(voterFile),
      type: 'General',
      year: 2024,
    },
    includePrimary && {
      type: 'Primary',
      voted: votedYears.includes(`vb.vf_p2024`),
      year: 2024,
    },
  );

  return elections;
};

const getBallotStatus = voterFile => {
  // If there is any value - return Voted
  if (voterFile['g2024_vote_method']) return 'Voted';

  const status = voterFile['g2024_ballot_status'];
  // If status does not exist return 'Pending'
  if (!status) return 'Pending';

  // Show friendly names
  switch (status) {
    case 'Ballot Requested':
      return 'Ballot Requested by Voter';
    case 'Ballot Mailed':
      return 'Ballot Mailed to Voter';
    case 'Ballot Received':
      return 'Ballot Received by State';
    case 'Ballot Cancelled':
      return 'Ballot Request Cancelled';
    default:
      // Return actual status by default
      return status;
  }
};

export const getLastElections = (electionsHistory, number = 3) => {
  const lastElectionYear = getLastElectionYear();

  const electionYears = [
    lastElectionYear,
    ...Array.from(Array(number - 1)).map((v, index) => lastElectionYear - 2 * (index + 1)),
  ];

  return electionYears.map(year => ({
    voted:
      electionsHistory.filter(
        entry => entry.year === year && (entry.voted || entry.ballot_status === 'Voted'),
      ).length > 0,
    year,
  }));
};

export const getVoterIntroSentence = (elections, name, state, isRegistered) => {
  if (!isRegistered) {
    return (
      <>
        Public voting records indicate that <strong>{name}</strong> is not registered to vote in{' '}
        <strong>{state}</strong>.
      </>
    );
  }

  const filteredElections = elections.filter(election => election.year >= 2016);

  const votedYears = filteredElections
    .filter(el => el.voted || el.ballot_status === 'Voted')
    .map(y => y.year);
  const notVotedYears = filteredElections
    .filter(el => el.hasOwnProperty('voted') && !el.voted)
    .map(y => y.year);

  if (isRegistered && votedYears.length < 1) {
    return (
      <>
        Public voting records indicate that <strong>{name}</strong> is registered to vote in{' '}
        <strong>{state}</strong>, and has not voted in any elections yet.
      </>
    );
  }

  const votedPart =
    votedYears.length > 1
      ? `${votedYears.slice(0, -1).join(', ')} and ${votedYears.slice(-1)}`
      : votedYears.join();
  const notVotedPart =
    notVotedYears.length > 1
      ? `${notVotedYears.slice(0, -1).join(', ')} or ${notVotedYears.slice(-1)}`
      : notVotedYears.join();

  return (
    <>
      {t('accelerate_change.intro.records-indicate')} <strong>{name}</strong>{' '}
      {t('accelerate_change.intro.registered-in')} <strong>{state},</strong>{' '}
      {t('accelerate_change.intro.has-voted')} {votedPart}
      {notVotedYears.length > 0 && (
        <>
          {t('accelerate_change.intro.but-not')} {notVotedPart}
        </>
      )}{' '}
      {t('accelerate_change.intro.elections')}.
    </>
  );
};

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const getShareData = (useCurrentUrl, didTheyVoteFlow, prefixParagraph = true) => {
  const url =
    useCurrentUrl || didTheyVoteFlow ? window.location.href : 'https://didmyfriendvote.org';

  const messageToShare = useCurrentUrl
    ? didTheyVoteFlow
      ? `Hey - I looked up your voting history on Did They Vote. Let's hold each other accountable to vote in the November 5th election!`
      : `Hey - I looked up your voting history on didmyfriendsvote.org. Let's hold each other accountable to vote in the November 5th election!`
    : '';

  return {
    messageToShare: `${prefixParagraph ? '\n' : ''}${messageToShare}${
      !prefixParagraph ? '\n' : ''
    }`,
    url,
  };
};
