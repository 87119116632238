import * as React from 'react';
import PropTypes from 'prop-types';
import { track } from '@web/services/analytics';

import dayjs from 'dayjs';
import styled from 'styled-components';

import DateItem from './DateItem';
import Link from '@web/components/Headcount/components/Link';
import Text from '@web/components/Headcount/components/Text';

import useIsPartner from '../../hooks/useIsPartner';

import stateAbbrMap from './stateAbbrMap';
import { useTranslation } from 'react-i18next';

import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const DateList = styled.ul`
  margin: ${({ theme }) => theme.spacing.wide} 0 0;
  padding: 0;
`;

const StyledLink = styled(Link)`
  margin: 1rem 0;
`;

const StyledVotingDates = styled.div`
  margin: 2rem 0;
`;

function VotingDates({ stateAbbreviation, activity, team, voteByMail, votingInfo }) {
  const { t } = useTranslation();
  const isPartner = useIsPartner(team);
  const isCannabis = activity?.campaign?.cannabis_registration_enabled;

  let lastRegDayVBM;
  let lastRegDayOnline;
  let earlyVotingStart;
  let electionDayDate;
  let isPrimary;

  if (
    votingInfo?.primary_election_date &&
    dayjs().isAfter(dayjs(votingInfo?.primary_election_date, 'MM-DD-YY'))
  ) {
    isPrimary = false;
    lastRegDayVBM = votingInfo?.general_vbm_request_deadline;
    lastRegDayOnline = votingInfo?.online_reg_deadline;
    earlyVotingStart = votingInfo?.early_vote_start;
    electionDayDate = votingInfo?.general_election_date;
  } else {
    isPrimary = true;
    lastRegDayVBM = votingInfo?.primary_vbm_request_deadline;
    lastRegDayOnline = votingInfo?.online_primary_reg_deadline;
    earlyVotingStart = votingInfo?.primary_early_vote_start;
    electionDayDate = votingInfo?.primary_election_date;
  }

  const trackClick = async () => {
    track('HEADCOUNT_VOTING_DATES_SEE_MORE', {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      team_id: team?.id,
      team_name: team?.name,
    });
  };

  function getStateName() {
    let fullStateName;
    try {
      fullStateName = stateAbbrMap[stateAbbreviation?.toUpperCase()];
    } catch (err) {
      fullStateName = stateAbbreviation;
    }
    return fullStateName;
  }

  function getColor() {
    if (isCannabis) {
      return 'yellow';
    }
    if (isPartner) {
      return 'yellow';
    }
    return 'teal';
  }

  const stateName = getStateName();
  const stateWithHyphens = stateName?.toLowerCase()?.split(' ')?.join('-');
  const additionalInfoLink = `https://www.headcount.org/state/${stateWithHyphens}/?utm_source=impactive`;
  const color = getColor();

  return (
    <StyledVotingDates>
      <Text variant="f2">
        {t('check_registration.headcount.votingDatesTitle', {
          election: isPrimary ? 'Primary election' : 'General election',
          state: stateName,
        })}
      </Text>
      <DateList>
        {(voteByMail || !lastRegDayOnline) && (
          <DateItem color={color} date={lastRegDayVBM} type="lastDayVBM" />
        )}
        {!voteByMail && lastRegDayOnline && (
          <DateItem color={color} date={lastRegDayOnline} type="lastRegDay" />
        )}
        <DateItem color={color} date={earlyVotingStart} type="earlyVotingStart" />
        <DateItem color={color} date={electionDayDate} type="electionDay" />
      </DateList>

      {votingInfo?.polling_place_locator && (
        <div>
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            href={votingInfo?.polling_place_locator}
          >
            <Text as="span" color="blue" variant="f3">
              {t('check_registration.headcount.findYourPolling')}
            </Text>
          </StyledLink>
        </div>
      )}

      <div onClick={trackClick} style={{ marginTop: 20 }}>
        <StyledLink href={additionalInfoLink} target="_blank" rel="noopener noreferrer">
          <Text as="span" color="blue" variant="f3">
            {t('check_registration.headcount.votingDatesMoreInfo', {
              info: 'voting',
              state: stateName,
            })}
          </Text>
        </StyledLink>
      </div>

      {votingInfo?.county_election_info && (
        <div style={{ marginTop: 20 }}>
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            href={votingInfo?.county_election_info}
          >
            <Text as="span" color="blue" variant="f3">
              {t('check_registration.headcount.lookUpCountry')}
            </Text>
          </StyledLink>
        </div>
      )}
    </StyledVotingDates>
  );
}

VotingDates.propTypes = {
  activity: PropTypes.object,
  stateAbbreviation: PropTypes.string.isRequired,
  team: PropTypes.object,
  voteByMail: PropTypes.bool,
  votingInfo: PropTypes.shape({
    county_election_info: PropTypes.string,
    early_vote_start: PropTypes.string,
    general_election_date: PropTypes.string.isRequired,
    general_vbm_request_deadline: PropTypes.string,
    online_primary_reg_deadline: PropTypes.string,
    online_reg_deadline: PropTypes.string,
    polling_place_locator: PropTypes.string,
    primary_early_vote_start: PropTypes.string,
    primary_election_date: PropTypes.string,
    primary_vbm_request_deadline: PropTypes.string,
  }).isRequired,
};

VotingDates.defaultProps = {};

export default VotingDates;
