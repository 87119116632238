import React, { useCallback, useEffect, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroller';
import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import Loading from '../common/Loading';
import MaxWidthContainer from '../common/MaxWidthContainer';
import Title from '../common/Title';
import TeamActivity from './TeamActivity';
import TeamMember from './TeamMember';
import { Avatar } from 'antd';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { toObject, usePaginatedApi } from '../../hooks/usePaginatedApi';
import { teams } from '../../services/api';
import { colors, media } from '../../styles/theme';

const HeaderContainer = styled.div`
  width: 100%;
`;

const TeamHeader = styled.div`
  background-color: #061240;
  display: flex;
  justify-content: space-between;
`;

const StyledAvatar = styled(Avatar)`
  margin: 18px 18px 18px 24px !important;
  border: 3px solid white;
`;

const TeamWrapper = styled.div`
  display: flex;
  color: white;
`;

const TeamInfo = styled.div`
  margin: auto;
`;

const TeamLeader = styled.div`
  color: #dddddd;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const TeamName = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 28px;
  line-height: 1.2;
`;

const TeamContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.nlg} {
    flex-direction: column;
  }
`;

const TopMembersSection = styled.div`
  flex: 1;
`;

const MembersSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledTeamMember = styled(TeamMember)`
  flex: 0 50%;

  ${media.nlg} {
    flex: 0 100%;
  }
`;

export const StyledTitle = styled(Title)`
  margin: 24px 0 12px 0;
  font-size: 24px;

  small {
    color: ${({ theme }) => theme.colors.mediumGray};
    margin-left: 12px;
    font-size: 18px;
  }
`;

export const Divider = styled.hr`
  border-bottom: 2px solid ${colors.borderGray};
`;

const TeamDetail = () => {
  const { t } = useTranslation();
  const title = t('teams.leaderboard.title');
  useDocumentTitle(title);
  const { teamId } = useParams();
  const { search } = useLocation();
  const campaign = useSelector(state => state.oneCampaign.selectedCampaign);

  const queryParams = new URLSearchParams(search);
  const id = queryParams.get('campaign_id');
  const [filters] = useState({
    campaign_id: id ? parseInt(id, 10) : campaign?.id,
  });

  const [team, setTeam] = useState();
  const [loading, setLoading] = useState(true);

  // Pagination object for team members
  const membersPaginator = toObject(usePaginatedApi(teams.getTeamMembers, teamId, filters));
  const { items: members } = membersPaginator;

  const loadTeam = useCallback(() => {
    setLoading(true);
    teams
      .getTeam({ filters, id: teamId })
      .then(({ data: { data } }) => {
        setTeam(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [teamId, filters]);

  // Load team and fetch team members on page load & any filter changes
  useEffect(() => {
    loadTeam();
    membersPaginator.resetPagination({ refetch: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadTeam, teamId, filters]);

  // Infinite Scroller parent reference
  const [scrollRef, setScrollRef] = useState(null);

  // Destructure team info
  const {
    name: teamName,
    team_leader: { first_name: leaderFirstName, last_name: leaderLastName } = {},
    profile_url: teamProfileUrl,
  } = team || {};

  // We want to disable Join functionality for for personal team

  const teamCampaign = team?.campaigns.find(({ id }) => id === filters.campaign_id);

  const teamLeaderName =
    leaderFirstName || leaderLastName ? `${leaderFirstName} ${leaderLastName}` : `Anonymous's`;

  return (
    <>
      <AppHeader title={title} extra={teamName} />
      <AppContainer>
        <HeaderContainer>
          <TeamHeader>
            <TeamWrapper>
              <StyledAvatar src={teamProfileUrl} size={75}>
                {teamName}
              </StyledAvatar>
              <TeamInfo>
                <TeamLeader>{t('teams.team', { leader: teamLeaderName })}</TeamLeader>
                <TeamName>{teamName}</TeamName>
              </TeamInfo>
            </TeamWrapper>
          </TeamHeader>
        </HeaderContainer>
        <MaxWidthContainer>
          {team && (
            <TeamContainer>
              <TeamActivity campaign={teamCampaign} team={team} loading={loading} />
              <TopMembersSection>
                <StyledTitle>
                  {t('teams.top_members')} <small>{t('teams.last_30_days')}</small>
                </StyledTitle>
                {loading ? (
                  <Loading centered />
                ) : (
                  <div>
                    {team.top_members.map(user => (
                      <TeamMember key={user.id} user={user} detailedView />
                    ))}
                  </div>
                )}
              </TopMembersSection>
            </TeamContainer>
          )}
          <Divider />
          <StyledTitle>
            {t('teams.all_members')} ({membersPaginator.metadata.total_entries || 0})
          </StyledTitle>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            threshold={0}
            loadMore={membersPaginator.loadMore}
            hasMore={!membersPaginator.loading && membersPaginator.hasMore}
            useWindow
            getScrollParent={() => scrollRef}
          >
            <MembersSection ref={setScrollRef}>
              {members.map(user => (
                <StyledTeamMember key={user.id} user={user} />
              ))}
            </MembersSection>
            {membersPaginator.loading && <Loading centered />}
          </InfiniteScroll>
        </MaxWidthContainer>
      </AppContainer>
    </>
  );
};

TeamDetail.propTypes = {};

export default TeamDetail;
