import * as React from 'react';
import PropTypes from 'prop-types';

function Contact(props) {
  return (
    <svg viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.3333 0.222656H1.66667C0.746528 0.222656 0 0.969184 0 1.88932V14.1115C0 15.0317 0.746528 15.7782 1.66667 15.7782H18.3333C19.2535 15.7782 20 15.0317 20 14.1115V1.88932C20 0.969184 19.2535 0.222656 18.3333 0.222656ZM6.11111 3.55599C7.33681 3.55599 8.33333 4.55252 8.33333 5.77821C8.33333 7.00391 7.33681 8.00043 6.11111 8.00043C4.88542 8.00043 3.88889 7.00391 3.88889 5.77821C3.88889 4.55252 4.88542 3.55599 6.11111 3.55599ZM10 11.7782C10 12.1463 9.65278 12.4449 9.22222 12.4449H3C2.56944 12.4449 2.22222 12.1463 2.22222 11.7782V11.1115C2.22222 10.0074 3.26736 9.11154 4.55556 9.11154H4.72917C5.15625 9.28863 5.62153 9.38932 6.11111 9.38932C6.60069 9.38932 7.06944 9.28863 7.49306 9.11154H7.66667C8.95486 9.11154 10 10.0074 10 11.1115V11.7782ZM17.7778 9.94488C17.7778 10.0977 17.6528 10.2227 17.5 10.2227H12.5C12.3472 10.2227 12.2222 10.0977 12.2222 9.94488V9.38932C12.2222 9.23654 12.3472 9.11154 12.5 9.11154H17.5C17.6528 9.11154 17.7778 9.23654 17.7778 9.38932V9.94488ZM17.7778 7.72266C17.7778 7.87543 17.6528 8.00043 17.5 8.00043H12.5C12.3472 8.00043 12.2222 7.87543 12.2222 7.72266V7.1671C12.2222 7.01432 12.3472 6.88932 12.5 6.88932H17.5C17.6528 6.88932 17.7778 7.01432 17.7778 7.1671V7.72266ZM17.7778 5.50043C17.7778 5.65321 17.6528 5.77821 17.5 5.77821H12.5C12.3472 5.77821 12.2222 5.65321 12.2222 5.50043V4.94488C12.2222 4.7921 12.3472 4.6671 12.5 4.6671H17.5C17.6528 4.6671 17.7778 4.7921 17.7778 4.94488V5.50043Z"
        fill="black"
      />
    </svg>
  );
}

Contact.propTypes = {
  height: PropTypes.number,
};

Contact.defaultProps = {
  height: 16,
};

export default Contact;
