import React from 'react';

const Share = props => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.851"
      d="M10.2337 12.0555C9.76706 12.0555 9.45595 11.6666 9.45595 11.2777C9.45595 10.8888 9.84483 10.4999 10.2337 10.4999C10.6226 10.4999 11.0115 10.8888 11.0115 11.2777C11.0115 11.6666 10.7004 12.0555 10.2337 12.0555ZM3.38928 8.39991C2.6115 8.39991 1.98928 7.77769 1.98928 6.99991C1.98928 6.22214 2.6115 5.59991 3.38928 5.59991C4.16706 5.59991 4.78928 6.22214 4.78928 6.99991C4.78928 7.77769 4.16706 8.39991 3.38928 8.39991ZM10.2337 1.94436C10.7004 1.94436 11.0115 2.33325 11.0115 2.72214C11.0115 3.1888 10.6226 3.49991 10.2337 3.49991C9.84483 3.49991 9.45595 3.11102 9.45595 2.72214C9.45595 2.33325 9.76706 1.94436 10.2337 1.94436ZM10.2337 9.41102C9.76706 9.41102 9.37817 9.56658 9.06706 9.79991L5.80039 7.46658C5.87817 7.15547 5.87817 6.84436 5.80039 6.53325L9.06706 4.19991C9.37817 4.43325 9.76706 4.5888 10.2337 4.5888C11.2448 4.5888 12.1004 3.73325 12.1004 2.72214C12.1004 1.71102 11.2448 0.855469 10.2337 0.855469C9.22261 0.855469 8.36706 1.71102 8.36706 2.72214C8.36706 2.87769 8.36706 3.11102 8.44483 3.26658L5.33372 5.52214C4.94483 4.89991 4.16706 4.51102 3.38928 4.51102C1.98928 4.51102 0.900391 5.59991 0.900391 6.99991C0.900391 8.39991 1.98928 9.4888 3.38928 9.4888C4.24483 9.4888 4.94484 9.09991 5.4115 8.47769L8.52261 10.7332C8.44483 10.8888 8.44483 11.0444 8.44483 11.2777C8.44483 12.2888 9.30039 13.1444 10.3115 13.1444C11.3226 13.1444 12.1782 12.2888 12.1782 11.2777C12.1004 10.1888 11.3226 9.41102 10.2337 9.41102Z"
      fill="currentColor"
    />
  </svg>
);

export default Share;
