import styled from 'styled-components';

const ShareButton = styled.button`
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 8px 8px 32px rgba(0, 41, 64, 0.06);
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  svg {
    margin-right: 1rem;
  }
`;

export default ShareButton;
