import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { media } from '@web/styles/theme';

import { usePaginatedApi, toObject } from '@web/hooks/usePaginatedApi';
import { campaigns, user } from '@web/services/api';

import CampaignActionCard from '../CampaignActionCard';

const StyledCampaignActions = styled.div`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  padding: 0;
`;

const Container = styled.div`
  padding: 50px 25px 140px;
  margin: 0 auto;

  ${media.lg} {
    padding: 0 25px;
  }

  ${media.xl} {
    padding: 0 0 210px;
    width: 80%;
  }

  ${media.xxxl} {
    width: 60%;
  }
`;

const SectionTitle = styled.h3`
  font-size: 3em;
  color: ${({ theme }) => theme.colors.blackL25};
  font-weight: 600;
  margin-bottom: 30px;

  ${media.lg} {
    font-size: 4em;
    margin-bottom: 60px;
  }
`;

const CampaignActionsHeader = styled.div`
  display: block;

  ${media.lg} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const CampaignActionsList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 40px;
  margin-bottom: 40px;

  ${media.lg} {
    margin-bottom: 60px;
    grid-template-columns: 1fr 1fr;
  }

  ${media.xxl} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const SeeMoreLink = styled.a`
  font-weight: bold;
  font-size: 1.2em;
  color: ${({ theme }) => theme.colors.salmon};
  opacity: 0.85;
`;

const CampaignActions = ({ campaign, campaignJoined, joinCampaign }) => {
  const { t } = useTranslation();

  // Depending on whether or not campaign is joined we use different endpoint with different parameters
  const apiEndpoint = campaignJoined ? user.getUserActivities : campaigns.getCampaignActivities;
  const resourceId = campaignJoined ? null : campaign.id;
  const params = campaignJoined ? { campaign_id: campaign.id } : {};
  params.perPage = 3;

  const {
    hasMore,
    items,
    loadMore: loadMoreActivities,
    resetPagination,
  } = toObject(usePaginatedApi(apiEndpoint, resourceId, params));

  // MTS - This is temporary code to make sure we don't render campaign activities in "draft" state.
  // It is going to be fixed on the backend at some point.
  const filteredItems = items.filter(
    item => !(item?.activity?.aasm_state && item?.activity?.aasm_state === 'draft'),
  );

  const onlyActionItems = filteredItems.filter(
    item => item?.activity?.type !== 'UserBroadcastActivity',
  );

  useEffect(() => {
    resetPagination({ refetch: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignJoined]);

  return (
    <StyledCampaignActions>
      <Container>
        <CampaignActionsHeader>
          <SectionTitle>{t('campaign.actions')}</SectionTitle>
        </CampaignActionsHeader>
        <CampaignActionsList>
          {onlyActionItems.map(item => (
            <CampaignActionCard
              campaign={campaign}
              item={item}
              joinCampaign={joinCampaign}
              key={item.id}
            />
          ))}
        </CampaignActionsList>

        {hasMore && (
          <SeeMoreLink onClick={loadMoreActivities}>{t('campaign.see_more')}</SeeMoreLink>
        )}
      </Container>
    </StyledCampaignActions>
  );
};

CampaignActions.propTypes = {
  campaign: PropTypes.object.isRequired,
  campaignJoined: PropTypes.bool.isRequired,
  joinCampaign: PropTypes.func.isRequired,
};

export default CampaignActions;
