import * as React from 'react';
import PropTypes from 'prop-types';

function Connect({ size, ...rest }) {
  return (
    <svg height={size} viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="39" cy="39" r="39" fill="#D5ECEF" />
      <path
        d="M33.5464 14.8507L14.8503 33.5474L44.4535 63.1495L63.1495 44.4527L33.5464 14.8507Z"
        fill="#8CC4CA"
      />
      <path
        d="M31.2099 23.4199L23.4199 31.2099L18.7454 29.6521L29.6521 18.7454L31.2099 23.4199Z"
        fill="#4D9DA6"
      />
      <path
        d="M21.0265 44.5091L18.6895 46.8462L31.1539 59.3102L33.4909 56.9731L21.0265 44.5091Z"
        fill="#8CC4CA"
      />
      <path
        d="M18.6351 51.5745L16.2981 53.9116L24.0884 61.7016L26.4254 59.3646L18.6351 51.5745Z"
        fill="#8CC4CA"
      />
      <path
        d="M56.9733 33.4909L59.3103 31.1538L46.8458 18.6898L44.5088 21.0269L56.9733 33.4909Z"
        fill="#8CC4CA"
      />
      <path
        d="M59.3647 26.4255L61.7017 24.0884L53.9114 16.2984L51.5744 18.6354L59.3647 26.4255Z"
        fill="#8CC4CA"
      />
    </svg>
  );
}

Connect.propTypes = {
  size: PropTypes.number,
};

Connect.defaultProps = {
  size: 78,
};

export default Connect;
