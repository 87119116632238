import styled, { css } from 'styled-components';
import { variants as textVariants } from '../Text';

// For simpler imports
export { default as ErrorText } from './ErrorText';

export const Label = styled.label`
  margin-bottom: 0.4rem;
  ${textVariants.defaultImportant}
`;

export const InputWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.normal};
  flex-direction: column;
`;

export const inputStyle = css`
  // fix address autocomplete field background color for iPhones
  background: ${({ theme }) => theme.colors.white};

  &&& {
    align-items: center;
    border: 1px solid
      ${({ $hasError, theme }) => ($hasError ? theme.colors.red : theme.colors.borderGray)};
    border-radius: 8px;
    color: ${({ $hasError, theme }) => ($hasError ? theme.colors.red : theme.colors.black)};
    display: flex;
    height: 38px;
    outline: 0;
    padding: 0 ${({ theme }) => theme.spacing.normal};

    // font style
    ${textVariants.default}

    ::placeholder {
      color: ${({ theme }) => theme.colors.textGray};
    }

    :focus {
      border: 1px solid
        ${({ $hasError, theme }) => ($hasError ? theme.colors.red : theme.colors.black)};
      box-shadow: none; // required because antd applies a blue outline
      outline: 0;
    }
  }
`;
