import { message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { campaigns, user } from '../../../services/api';
import AppContainer from '../../AppContainer';
import AppHeader from '../../AppHeader';
import Container from '../../Container';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import ContactSelection from './ContactSelection';
import SendingScreen from './SendingScreen';
import { useSelector } from 'react-redux';

const Content = styled(MaxWidthContainer)`
  max-width: 806px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.black};

  && {
    padding-top: 35px;
  }
`;

const TextAFriendActivity = () => {
  const { t } = useTranslation();
  const { userActivityId } = useParams();
  const [userActivity, setUserActivity] = useState({});
  const { activity: { _type: activityType } = {} } = userActivity; // Extract activity type
  const [initialScripts, setInitialScripts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displaySendingScreen, setDisplaySendingScreen] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const oneCampaignData = useSelector(state => state.oneCampaign);

  // Fetches initial data
  const loadData = useCallback(
    async userActivityId => {
      try {
        // First fetch userActivity data
        const {
          data: { data: userActivity },
        } = await user.getUserActivity(userActivityId);

        const {
          activity: {
            id: activityId,
            campaign: { id: campaignId },
          },
        } = userActivity;

        // Next get the script based on campaign and activity ids
        const {
          data: { data: activityScripts },
        } = await campaigns.getActivityScripts(campaignId, activityId, {
          mode_priority: ['email', 'sms', 'phone', 'social'],
          script_type: 'initial',
        });

        const initialScripts = Object.values(activityScripts).map(({ id, name, script }) => ({
          body: script,
          id,
          name,
        }));

        // Set data
        setUserActivity(userActivity);
        setInitialScripts(initialScripts);
        setLoading(false);
      } catch (err) {
        message.error(t('errors.activity_loading_failed'));
      }
    },
    [t],
  );

  const handleGoBack = useCallback(() => {
    setRedirect(true);
  }, []);

  const handleSendIndividual = useCallback(() => {
    setDisplaySendingScreen('individual');
  }, []);

  const handleSendBulk = useCallback(() => {
    setDisplaySendingScreen('bulk');
  }, []);

  const handleGoBackToSelect = useCallback(() => {
    setDisplaySendingScreen(null);
  }, []);

  // Load initial data
  useEffect(() => {
    loadData(userActivityId);
  }, [loadData, userActivityId]);

  // Redirect to one campaign frontline page if campaign has been changed
  useEffect(() => {
    const id = oneCampaignData.selectedCampaign?.id;
    const lastCampaignId = oneCampaignData.lastCampaignId;
    if (!id || !lastCampaignId || id === lastCampaignId) return;
    setRedirect(true);
  }, [oneCampaignData]);

  // Redirect if activity type doesn't match this action
  if (activityType && activityType !== 'text_a_friend_activities') {
    message.error(t('errors.activity_type_mismatch'));
    return <Redirect to={`/frontline`} />;
  }

  if (loading) {
    return (
      <>
        <AppHeader title={t('activity.f2f.header_title')} />
        <AppContainer>
          <Container>{t('common.loading')}</Container>
        </AppContainer>
      </>
    );
  }

  if (redirect) {
    return <Redirect to={`/frontline`} />;
  }

  return (
    <>
      <AppHeader title={t('activity.f2f.header_title')} />
      <AppContainer>
        <Content>
          {displaySendingScreen ? (
            <SendingScreen
              isBulk={displaySendingScreen === 'bulk'}
              contactList={selectedContacts}
              scripts={initialScripts}
              userActivity={userActivity}
              onGoBack={handleGoBackToSelect}
            />
          ) : (
            <ContactSelection
              selectedContacts={selectedContacts}
              setSelectedContacts={setSelectedContacts}
              userActivity={userActivity}
              onGoBack={handleGoBack}
              onSendIndividual={handleSendIndividual}
              onSendBulk={handleSendBulk}
            />
          )}
        </Content>
      </AppContainer>
    </>
  );
};

TextAFriendActivity.propTypes = {};

export default TextAFriendActivity;
