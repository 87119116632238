import { Button, message, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import Clipboard from 'react-clipboard.js';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { media } from '../../styles/theme';
import Metric from '../CampaignLeaderboard/Metric';
import Loading from '../common/Loading';
import { Divider, StyledTitle } from './index';

const Wrapper = styled.div`
  flex: 1;

  ${media.lg} {
    padding-right: 32px;
    hr {
      display: none;
    }
  }
`;

const Metrics = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledMetric = styled(Metric)`
  flex: 0 33% !important;
`;

const InvitesContainer = styled.div``;

const StyledButton = styled(Button)`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.blackItalic};
  letter-spacing: 1px;
`;

const TeamJoinDescription = styled.div`
  margin: 18px 0;
`;

const TeamCode = styled.div`
  color: ${({ theme }) => theme.colors.link};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 28px;
  margin: 12px 0;
`;

const TeamActivity = ({ campaign, team, loading }) => {
  const { t } = useTranslation();

  if (!team) return null;

  if (loading) {
    return (
      <Wrapper>
        <StyledTitle>{t('teams.activity.title')}</StyledTitle>
        <Loading centered />
      </Wrapper>
    );
  }

  const { members, actions, rank, messages, reports, points, invite_link, team_leader, join_code } =
    team;
  const teamLeaderEmailHash = team_leader?.email_hash || '';
  const {
    name: campaignName,
    slug,
    registration_feature_enabled: showRegistrationLink,
  } = campaign || {};

  const hostname = `${window.location.protocol}//${window.location.host}`;
  const queryParams = teamLeaderEmailHash ? `?referral_hash=${teamLeaderEmailHash}` : '';
  const checkRegistrationLink = `${hostname}/campaigns/${slug}/check_registration${queryParams}`;
  const sampleInvitation = t('teams.invites.sample_invitation', {
    code: join_code,
    link: invite_link,
  });

  return (
    <Wrapper>
      <StyledTitle>
        {t('teams.activity.title')}
        {!campaign && <small>{t('teams.activity.summary')}</small>}
      </StyledTitle>

      <Metrics>
        <StyledMetric large label={t('teams.activity.labels.members')} value={members} />
        <StyledMetric large label={t('teams.activity.labels.actions')} value={actions} />
        {campaign ? (
          <StyledMetric
            large
            ordinal
            label={`${t('teams.activity.labels.place_for')} ${campaignName}`}
            value={rank}
          />
        ) : (
          <StyledMetric large label={t('teams.activity.labels.ranking')} value="-" />
        )}
        <StyledMetric large label={t('teams.activity.labels.messages')} value={messages} />
        <StyledMetric large label={t('teams.activity.labels.reports')} value={reports} />
        <StyledMetric large label={t('teams.activity.labels.points')} value={points} />
      </Metrics>
      <Divider />
      <InvitesContainer>
        <StyledTitle>{t('teams.invites.to_team_title')}</StyledTitle>
        <TeamJoinDescription>{t('teams.invites.join_disclaimer')}</TeamJoinDescription>
        <Clipboard
          component="span"
          className="anticon"
          data-clipboard-text={sampleInvitation}
          onSuccess={() => message.info(t('activity.share.copied_to_clipboard'))}
        >
          <Tooltip
            placement="top"
            title={campaign ? sampleInvitation : t('teams.invites.select_campaign_message')}
          >
            <StyledButton type="primary" disabled={!campaign}>
              {t('teams.invites.copy_sample_invitation')}
            </StyledButton>
          </Tooltip>
        </Clipboard>
        {join_code && (
          <>
            <TeamJoinDescription>
              {t('teams.invites.alternative_join_disclaimer')}
            </TeamJoinDescription>
            <TeamCode>{join_code}</TeamCode>
          </>
        )}
        {campaign && showRegistrationLink && (
          <>
            <StyledTitle>{t('teams.invites.to_check_registration_title')}:</StyledTitle>
            <Clipboard
              component="span"
              className="anticon"
              data-clipboard-text={checkRegistrationLink}
              onSuccess={() => message.info(t('activity.share.copied_to_clipboard'))}
            >
              <Tooltip placement="top" title={checkRegistrationLink}>
                <StyledButton type="primary" disabled={!campaign}>
                  {t('teams.invites.copy_registration')}
                </StyledButton>
              </Tooltip>
            </Clipboard>
          </>
        )}
      </InvitesContainer>
      <Divider />
    </Wrapper>
  );
};

TeamActivity.propTypes = {
  campaign: PropTypes.shape({
    campaign_name: PropTypes.string,
    slug: PropTypes.string,
  }),
  loading: PropTypes.bool,
  team: PropTypes.shape({
    actions: PropTypes.number,
    invite_link: PropTypes.string,
    join_code: PropTypes.string,
    members: PropTypes.number,
    messages: PropTypes.number,
    points: PropTypes.number,
    rank: PropTypes.number,
    reports: PropTypes.number,
    team_leader: PropTypes.shape({
      email_hash: PropTypes.string,
    }),
  }).isRequired,
};

TeamActivity.defaultProps = {
  loading: false,
};

export default TeamActivity;
