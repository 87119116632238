// MTS - The main reason for this hook is to make this logic easily sharable
// between web and mobile.
import * as React from 'react';
import axios from 'axios';

import { machineTransition, reportUpdated } from '@web/reducers/dialerReducer';
import sendError from '@web/utils/sendError';
import {
  NO_TAGS_OR_CUSTOM_FIELDS,
  TAGS_AND_CUSTOM_FIELDS_SUBMITTED,
} from '@web/reducers/dialerReducer/dialerMachine';
import { useDispatch, useSelector } from 'react-redux';

function useReportApi(activity, reportDuringCall = false) {
  const dispatch = useDispatch();
  const connectedContact = useSelector(state => state.dialer.connectedContact);
  const initialData = useSelector(state => state.dialer.reportData);
  const [customFieldAnswers, setCustomFieldAnswers] = React.useState([]);
  const [tagAnswers, setTagAnswers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [report, setReport] = React.useState(null);
  const campaignId = activity.campaign.id;

  // On mount, generate a report
  React.useEffect(() => {
    createReport();
    // eslint-disable-next-line
  }, []);

  function handleSubmit() {
    setLoading(true);
    const userReport = buildUserReport();
    axios
      .put(`/api/v2/client/campaigns/${campaignId}/user_reports/${report.id}`, {
        user_report: userReport,
      })
      .then(res => {
        // We don't need to do anything here because we navigate to the
        // session stats screen on success and failure.
      })
      .catch(err => {
        sendError('Dialer - Error submitting tags and custom fields', {
          request: err?.request,
          response: err?.response,
          userReport,
        });
      })
      .finally(() => {
        dispatch(machineTransition(TAGS_AND_CUSTOM_FIELDS_SUBMITTED));
      });
  }

  function createReport() {
    const tags = activity.activity_tags;
    const customFields = activity.activity_custom_fields;

    // We don't need to get the report unless custom fields or tags are set
    if ((tags?.length || 0) === 0 && (customFields?.length || 0) === 0) {
      if (!reportDuringCall) {
        dispatch(machineTransition(NO_TAGS_OR_CUSTOM_FIELDS));
      } else {
        setLoading(false);
      }
      return;
    }

    setLoading(true);
    axios
      .post(`/api/v2/client/campaigns/${campaignId}/user_reports/find_or_create`, {
        activity_id: activity?.id,
        contact_id: connectedContact.contact.id,
      })
      .then(({ data }) => {
        // We only display this UI if the activity has the reporting options
        // configured.
        if (data.customizations.length === 0 && data.taggings.length === 0) {
          if (!reportDuringCall) {
            dispatch(machineTransition(NO_TAGS_OR_CUSTOM_FIELDS));
          }
        } else {
          setReport(data);

          setCustomFieldAnswers(
            mergeBlankDataWithInitialData(
              data.customizations,
              initialData?.customFieldAnswers ?? [],
            ),
          );
          setTagAnswers(
            mergeBlankDataWithInitialData(data.taggings, initialData?.tagAnswers ?? []),
          );
        }
      })
      .catch(err => {
        sendError('Dialer - Error generating tagsAndCustomFields report', {
          request: err?.request,
          response: err?.response,
        });
      })
      .finally(() => setLoading(false));
  }

  function buildUserReport() {
    return {
      customizations_attributes: customFieldAnswers.map(el => {
        return { custom_field_id: el.custom_field_id, id: el.id, value: el.value };
      }),
      id: report.id,
      taggings_attributes: tagAnswers.map(el => {
        return { id: el.id, tag_id: el.tag_id, value: el.value };
      }),
    };
  }

  function handleAnswerChange(type, answer) {
    if (type === 'customField') {
      if (customFieldAnswers.length === 0) {
        const newAnswers = [answer];
        updateReportDataInRedux(newAnswers, tagAnswers);
        return setCustomFieldAnswers(newAnswers);
      }
      const index = customFieldAnswers.findIndex(el => el.id === answer.id);
      if (index >= 0) {
        let newAnswers = [...customFieldAnswers];
        newAnswers[index] = answer;
        updateReportDataInRedux(newAnswers, tagAnswers);
        return setCustomFieldAnswers(newAnswers);
      } else {
        const newAnswers = [answer];
        updateReportDataInRedux(newAnswers, tagAnswers);
        return setCustomFieldAnswers(customFieldAnswers.concat(newAnswers));
      }
    }
    if (type === 'tag') {
      if (tagAnswers.length === 0) {
        const newAnswers = [answer];
        updateReportDataInRedux(customFieldAnswers, newAnswers);
        return setTagAnswers(newAnswers);
      }
      const index = tagAnswers.findIndex(el => el.id === answer.id);
      if (index >= 0) {
        const newAnswers = [...tagAnswers];
        newAnswers[index] = answer;
        updateReportDataInRedux(customFieldAnswers, newAnswers);
        return setTagAnswers(newAnswers);
      } else {
        const newAnswers = tagAnswers.concat([answer]);
        updateReportDataInRedux(customFieldAnswers, newAnswers);
        return setTagAnswers(newAnswers);
      }
    }
  }

  const updateReportDataInRedux = React.useCallback((customFields, tags) => {
    if (!reportDuringCall) return;
    dispatch(
      reportUpdated({
        customFieldAnswers: customFields,
        tagAnswers: tags,
      }),
    );
    // Ignore to avoid this looping
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mergeBlankDataWithInitialData = React.useCallback((blank, initial) => {
    const ids = new Set(initial.map(d => d.id));
    return [...initial, ...blank.filter(d => !ids.has(d.id))].filter(
      data => data.value !== null && data.value !== undefined,
    );
  }, []);

  return {
    customFieldAnswers,
    handleAnswerChange,
    handleSubmit,
    loading,
    report,
    tagAnswers,
  };
}

export default useReportApi;
