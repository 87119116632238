import * as React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

function PhoneInput({ phoneNumber, setPhoneNumber, ...rest }) {
  const onPhoneChangeHandler = ({ target: { value: number } }) => {
    // Add `+` prefix if user starts entering digits
    if (/^\d{1}$/.test(number)) {
      setPhoneNumber(`+1${number}`);
      return;
    }

    // Only allow numbers, starting with + symbol
    if (/^$|^(?:\+1)\d*$/.test(number)) {
      setPhoneNumber(number);
      return;
    }
  };

  return (
    <Form.Item style={{ maxWidth: 387 }}>
      <Input
        addonBefore={<PhoneOutlined />}
        onChange={onPhoneChangeHandler}
        placeholder={'Phone Number'}
        value={phoneNumber}
        {...rest}
      />
    </Form.Item>
  );
}

PhoneInput.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
};

PhoneInput.defaultProps = {};

export default PhoneInput;
