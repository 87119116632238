import React from 'react';
import ModalManager from 'web/components/ModalManager';

export default function useModal(modalComponent) {
  const functions = React.useMemo(() => {
    // False positive, eslint thinks ModalManager.useModal is a hook, but it's not
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return ModalManager.useModal(modalComponent);
  }, [modalComponent]);

  // Make sure to close the modal when parent component is destroyed or "modalComponent" is changed
  React.useEffect(() => {
    return () => {
      functions[1]();
    };
  }, [functions]);

  return functions;
}
