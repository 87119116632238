import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { media } from '@web/styles/theme';
import { colors } from '@web/styles/theme';
import { Button } from 'antd';

const StyledCampaignActionCTA = styled(Button)`
  &&& {
    float: right;
    width: auto;
    min-width: 130px;
    height: 42px;
    background: #23293e;
    border-radius: 4px;
    font-size: 0.8rem;

    font-weight: 900;
    line-height: 150%;
    text-align: center;
    color: ${colors.white};

    ${({ $completed, disabled }) =>
      $completed &&
      !disabled &&
      `
      background-color: #949494;
      border-color: #949494;

      &:hover {
        background-color: #a9a9a9;
        border-color: #a9a9a9;
      }

      &:active {
        background-color: #808080;
        border-color: #808080;
      }
    `}

    ${media.ns} {
      font-size: 0.9rem;
    }
  }
`;

const CampaignActionCTA = ({ activity, children, completed, onClickHandler }) => {
  const closed = activity.aasm_state === 'closed';

  /**
   * Call to action buttons are rendered conditionally based on activityType
   *  - ShareActivity and WebActivity are custom components and are handled directly
   *  - Other userActivities are simple links to corresponding handler page
   *  - Campaign activities (no userActivityId) will act as 'join campaign' button
   **/
  return (
    <StyledCampaignActionCTA
      type="primary"
      disabled={closed}
      onClick={onClickHandler}
      $completed={completed}
    >
      {children}
    </StyledCampaignActionCTA>
  );
};

CampaignActionCTA.propTypes = {
  activity: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired,
  completed: PropTypes.bool,
  onClickHandler: PropTypes.func,
};

CampaignActionCTA.defaultProps = {
  completed: false,
};

export default CampaignActionCTA;
