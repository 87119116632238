import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Spinner } from '@web/components/common/Loading';
import PlanHeader from './PlanHeader';
import { DynamicGrid } from './DynamicGrid';
import PlanSectionHeader from './PlanSectionHeader';
import PlanGridRow from './PlanGridRow';
import PlanButtons from './PlanButtons';

const ShadowWrapper = styled(DynamicGrid)`
  position: relative; // to allow for shadow to be casted over other blocks

  box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.08);
`;

const ScrollingContainer = styled.div`
  overflow-x: auto;

  ${({ noScrollbar }) =>
    noScrollbar &&
    css`
      background-color: white;
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    `}
`;

const Sticky = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 3;
`;

const SubscriptionSelection = ({ plans, onPlanSelect }) => {
  const columnCount = plans?.length;

  const scrollRefTable = useRef(null);
  const scrollRefButtons = useRef(null);

  const onScrollTable = scroll => {
    scrollRefButtons.current.scrollLeft = scroll.target.scrollLeft;
  };
  const onScrollButtons = scroll => {
    scrollRefTable.current.scrollLeft = scroll.target.scrollLeft;
  };

  if (!plans) return <Spinner color="coral" />;

  const { options } = plans[0];

  // TODO: this custom table doesn't make sense, use regular antd table for displaying this data
  return (
    <>
      <ScrollingContainer noScrollbar ref={scrollRefTable} onScroll={onScrollTable}>
        <ShadowWrapper columnCount={columnCount}>
          <PlanHeader plans={plans} />
        </ShadowWrapper>
        <DynamicGrid columnCount={columnCount}>
          {Object.keys(options)?.map(key => (
            <>
              <PlanSectionHeader text={key} optionsCount={plans.length} key={key} />
              {Object.keys(options[key]).map((name, index) => (
                <PlanGridRow
                  name={name}
                  key={name}
                  isGray={index % 2 === 0}
                  options={plans.map(plan => plan.options[key][name])}
                />
              ))}
            </>
          ))}
        </DynamicGrid>
      </ScrollingContainer>
      <Sticky>
        <ScrollingContainer ref={scrollRefButtons} onScroll={onScrollButtons}>
          <PlanButtons plans={plans} onPlanSelect={onPlanSelect} />
        </ScrollingContainer>
      </Sticky>
    </>
  );
};

SubscriptionSelection.propTypes = {
  onPlanSelect: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SubscriptionSelection;
