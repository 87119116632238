import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Box from './Box';
import { Description, Title } from '@web/components/VoterRegistration/newComponents/TextStyles';
import FanOutShare from '@web/components/FanOut/components/Share';
import MobileHero from '../Info/MobileHero';

import { track } from '@web/services/analytics';
import useIsMobile from '@web/components/VoterRegistration/hooks/useIsMobile';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';
import { vrDefaultColors } from '@web/styles/theme';
import VoterRegistrationShare from './VoterRegistrationShare';

const StyledShare = styled.div``;

const StyledMobileHero = styled(MobileHero)`
  &&& {
    padding-bottom: ${({ $isFanOut }) => ($isFanOut ? '5px' : '90px')};
  }
`;

const FanOutShareWrapper = styled.div`
  margin-top: 16px;
`;

function Share(props) {
  const { t } = useTranslation();
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const isMobile = useIsMobile();
  const { activity, team, isFanOut } = props;

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const activeFormSection = queryParams.get('start');
  const activeLanguage = queryParams.get('language');

  let referralLink = contactFormResult?.referral_link || '';

  if (activeFormSection === '1') {
    referralLink += '?start=1';
  }

  if (activeLanguage) {
    const separator = referralLink.includes('?') ? '&' : '?';
    referralLink += `${separator}language=${activeLanguage}`;
  }

  React.useEffect(() => {
    if (isFanOut) {
      amplitudeTrack({ activity, name: 'FAN_OUT_VIEW_REFERRAL_RESULTS', team });
    } else {
      track('VR_VIEW_REFERRAL_RESULTS', {
        activity_id: activity?.id,
        activity_title: activity?.title,
        activity_type: activity?.type,
        campaign_id: activity?.campaign?.id,
        campaign_name: activity?.campaign?.name,
        team_id: team?.id,
        team_name: team?.name,
      });
    }
    // eslint-disable-next-line
  }, []);

  const fontColor = React.useMemo(
    () => (isFanOut ? undefined : activity?.settings?.font_color ?? vrDefaultColors.fontColor),
    [activity?.settings?.font_color, isFanOut],
  );

  const text = (
    <>
      <Title $fontColor={fontColor}>
        {isFanOut
          ? t('check_registration.referral.fanOutShareTitle')
          : t('check_registration.referral.title')}
      </Title>
      {!isFanOut && (
        <Description $fontColor={fontColor} className="description-text">
          {t('check_registration.referral.shareDescription')}
        </Description>
      )}
    </>
  );

  if (!isFanOut) {
    return (
      <VoterRegistrationShare
        text={text}
        team={team}
        activity={activity}
        referralLink={referralLink}
      />
    );
  }

  return (
    <StyledShare>
      {isMobile ? (
        <StyledMobileHero $isFanOut={isFanOut} isFanOut={isFanOut}>
          {text}
        </StyledMobileHero>
      ) : (
        text
      )}
      {isFanOut ? (
        <FanOutShareWrapper>
          <FanOutShare {...props} />
        </FanOutShareWrapper>
      ) : (
        <Box {...props} referralLink={referralLink} />
      )}
    </StyledShare>
  );
}

Share.propTypes = {
  activity: PropTypes.object.isRequired,
  isFanOut: PropTypes.bool,
  team: PropTypes.object,
};

Share.defaultProps = {
  team: null,
};

export default Share;
