import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SIZE = 16;

const Circle = styled.div`
  align-items: center;
  background-color: ${({ $bgColor }) => $bgColor};
  display: flex;
  border-radius: ${SIZE / 2}px;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.07);
  height: ${SIZE}px;
  justify-content: center;
  margin-right: 0.5rem;
  min-width: ${SIZE}px;
  width: ${SIZE}px;
`;

function CheckMark({ backgroundColor, pageColor }) {
  return (
    <Circle $bgColor={backgroundColor}>
      <svg height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 3.30755L3.79999 6L9 1" stroke={pageColor} strokeLinecap="round" />
      </svg>
    </Circle>
  );
}

CheckMark.propTypes = {
  backgroundColor: PropTypes.string,
  pageColor: PropTypes.string,
};

CheckMark.defaultProps = {
  backgroundColor: '#fff',
  pageColor: '#23293E',
};

export default CheckMark;
