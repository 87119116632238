import React from 'react';
import { bool, string } from 'prop-types';

import InputMask from 'react-input-mask';
import { ErrorMessage, Field } from 'formik';
import { CalendarFilled } from '@ant-design/icons';

import theme from '@web/styles/theme';
import styled, { css } from 'styled-components';

const Wrapper = styled.div``;

export const Label = styled.div`
  margin-bottom: 5px;
  text-transform: ${({ $textTransform }) => $textTransform ?? 'uppercase'};
  font-size: 0.875rem;
  ${({ $labelFontColor }) => css`
    color: ${$labelFontColor ?? theme.colors.black};
    font-family: ${theme.fonts.bold};
  `}
`;

export const StyledErrorMessage = styled(ErrorMessage).attrs({ component: 'div' })`
  color: #ea5156;
  font-size: 12px;
  margin-top: 4px;
`;

const StyledField = styled(Field)`
  &&& {
    border: 1px solid ${({ $outlineColor }) => $outlineColor ?? '#d9d9d9'};
    background-color: ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster};
    border-radius: 6px;
    padding: ${({ $hidePrefix }) => ($hidePrefix ? '10px 14px 12px' : '10px 14px 12px 42px')};
    color: ${({ $fontColor }) => $fontColor ?? theme.colors.black};
    width: 100%;
    height: 42px;
    font-size: 14px;

    ::placeholder {
      color: #707070;
      opacity: 1;
    }

    &:hover {
      border: 1px solid #40a9ff;
    }

    &:focus-visible {
      outline: 0;
      border-right-width: 1px !important;
      border: 1px solid #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    svg {
      color: ${({ $outlineColor }) => $outlineColor ?? theme.colors.gray};
    }

    input {
      background-color: ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster};
      color: ${({ $fontColor }) => $fontColor ?? theme.colors.black};

      ::placeholder {
        color: ${({ $outlineColor }) => $outlineColor ?? theme.colors.gray};
      }
    }

    .ant-input-prefix {
      margin-right: 12px;
    }
  }
`;

const StyledInputMast = styled(InputMask)`
  &&& {
    color: ${({ $fontColor }) => $fontColor ?? theme.colors.black};

    background: ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster};

    -webkit-background-clip: text !important;
    background-clip: text !important;
    box-shadow: 0 0 0 30px ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster}
      inset !important;
    -webkit-box-shadow: 0 0 0 30px
      ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster} inset !important;
    -webkit-autofill:first-line {
      color: ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster};
    }

    ::placeholder {
      color: ${({ $outlineColor }) => $outlineColor ?? theme.colors.blackL72} !important;
    }
  }
`;

export const Relative = styled.div`
  position: relative;

  svg path {
    fill: ${({ $iconColor }) => $iconColor ?? theme.colors.gray};
  }
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  left: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-55%);
`;

const FormDatePicker = ({
  name,
  label,
  backgroundColor,
  fontColor,
  labelFontColor,
  outlineColor,
  textTransform,
  mask,
  hidePrefix,
  ...rest
}) => {
  return (
    <Wrapper>
      <Label $labelFontColor={labelFontColor} $textTransform={textTransform}>
        {label}
      </Label>
      <Relative $iconColor={outlineColor}>
        <StyledInputMast
          mask={mask ?? '99/99/9999'}
          name={name}
          $backgroundColor={backgroundColor}
          $fontColor={fontColor}
          $outlineColor={outlineColor}
          {...rest}
        >
          {inputProps => (
            <StyledField
              $hidePrefix={hidePrefix}
              $outlineColor={outlineColor}
              $backgroundColor={backgroundColor}
              $fontColor={fontColor}
              {...inputProps}
              name={name}
            />
          )}
        </StyledInputMast>
        {!hidePrefix && (
          <IconWrapper>
            <CalendarFilled />
          </IconWrapper>
        )}
      </Relative>
      <StyledErrorMessage data-testid={`${name}-error`} name={name} />
    </Wrapper>
  );
};

FormDatePicker.propTypes = {
  backgroundColor: string,
  fontColor: string,
  hidePrefix: bool,
  label: string,
  labelFontColor: string,
  mask: string,
  name: string.isRequired,
  outlineColor: string,
  placeholder: string,
  textTransform: string,
  value: string,
};

export default FormDatePicker;
