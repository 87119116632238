const transformConnections = connections => {
  return connections
    .filter(x => x.emailAddresses && x.emailAddresses.length)
    .map(connection => {
      const { photos, names, resourceName, emailAddresses } = connection;

      const id = resourceName;
      const photoUrl = (photos && photos.length > 0 && photos[0].url) || null;
      const name = (names && names.length > 0 && names[0].displayName) || null;
      const firstName = (names && names.length > 0 && names[0].givenName) || null;
      const lastName = (names && names.length > 0 && names[0].familyName) || null;
      const email =
        (emailAddresses && emailAddresses.length > 0 && emailAddresses[0].value) || null;

      return { emails: [{ email }], firstName, id, lastName, name, photoUrl };
    });
};

export { transformConnections };
