import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import emailIconImage from '../../../../../assets/images/web/email-icon.png';
import { track } from '../../../services/analytics';
import { users } from '../../../services/api';
import { isValidEmail } from '../../../utils/string';
import { Wrapper, Header, StyledButton, StyledInput } from '../Shared';
import Confirmation from './Confirmation';
import PasswordEntry from './PasswordEntry';

const PasswordReset = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { email: _email } = state || {};

  const [email, setEmail] = useState(_email);
  const [passwordResetAuthToken, setPasswordResetAuthToken] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showPasswordEntry, setShowPasswordEntry] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  // Track the page view
  useEffect(() => {
    track('VIEW_RESET_PASSWORD_SCREEN');
  }, []);

  const onEmailSubmitHandler = e => {
    if (e) e.preventDefault(); // prevent html form from being submitted

    // validate input, only submit for valid email addresses
    if (!isValidEmail(email)) {
      message.error(t('auth.email.invalid'));
      return;
    }

    users
      .requestEmailCode(email)
      .then(() => setShowConfirmation(true))
      .catch(() => {
        // TODO: decide if user should be notified that the email doesn't exist?
      });
  };

  const onCodeSubmitHandler = code => {
    users
      .sendConfirmationCode(code, email)
      .then(({ headers: { authorization } }) => {
        setPasswordResetAuthToken(authorization);
        setShowConfirmation(false);
        setShowPasswordEntry(true);
      })
      .catch(
        ({
          response: {
            data: { error },
          },
        }) => {
          message.error(error);
        },
      );
  };

  const onPasswordSubmitHandler = password => {
    users
      .changePassword(password, passwordResetAuthToken)
      .then(() => {
        setShowPasswordEntry(false);
        setRedirectToLogin(true);
        message.success(t('auth.forgot.password_updated'));
      })
      .catch(
        ({
          response: {
            data: { error },
          },
        }) => {
          message.error(error);
        },
      );
  };

  if (showConfirmation) {
    return <Confirmation onCodeSubmit={onCodeSubmitHandler} />;
  }

  if (showPasswordEntry) {
    return <PasswordEntry onPasswordSubmit={onPasswordSubmitHandler} />;
  }

  if (redirectToLogin) {
    return <Redirect to="/auth/email" />;
  }

  // Email entry form
  return (
    <Wrapper>
      <img src={emailIconImage} width={84} height={56} alt="email-icon" />
      <Header>{t('auth.forgot.header')}</Header>
      <form data-testid="form" onSubmit={onEmailSubmitHandler}>
        <StyledInput
          autoFocus
          type="email"
          placeholder={t('placeholders.email')}
          onChange={e => setEmail(e.target.value)}
          value={email}
        />
        <StyledButton type="primary" htmlType="submit" disabled={!email}>
          {t('auth.forgot.reset')}
        </StyledButton>
      </form>
    </Wrapper>
  );
};

PasswordReset.propTypes = {};

export default PasswordReset;
