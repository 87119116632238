import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import theme from '@web/styles/theme';

import FilterButton from '@web/components/common/FilterButton';
import imageElectionCheck from '@web/../../assets/images/web/election-check.png';
import imageElectionX from '@web/../../assets/images/web/election-x.png';
import ImpactiveModal from '../../common/ImpactiveModal';
import ImpactiveButton from '../../ImpactiveButton';
import {
  getVoterInfo,
  getParty,
  getVotingRecords,
  getFrequency,
  frequencyTagConverter,
} from '@web/utils/getVoterInfo';

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  > button {
    width: 15%;
    min-width: 170px;
  }
`;

const ModalContent = styled.div`
  color: ${({ theme }) => theme.colors.black};
  padding-right: 20px;
  margin-right: -20px;
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 16px;
`;

const RecentElections = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.semibold};
`;

const RecentElectionSection = styled.div`
  margin-bottom: 30px;
`;

const ElectionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ElectionName = styled.div`
  font-size: 14px;
`;

const StyledElectionImage = styled.img`
  height: 16px;
  width: 16px;
`;

// Search the national voter file with the following parameters to find possible matches for either a contact or someone you've just met at an event
const VotersHistoryModal = ({ closeModal, voter, visible, onListItemClick }) => {
  const { t } = useTranslation();

  if (!visible) return null;

  const { fullName, address } = getVoterInfo(voter);
  const party = getParty(voter);
  const votingRecords = getVotingRecords(voter);
  const { frequency } = getFrequency(voter);
  const inDistrict = false;

  return (
    <>
      <ImpactiveModal
        bodyStyle={{ background: theme.colors.background }}
        visible={visible}
        maskClosable
        onCancel={closeModal}
        width={700}
        footer={
          <ButtonWrapper>
            <ImpactiveButton
              onClick={() => {
                onListItemClick ? onListItemClick(voter) : closeModal();
              }}
            >
              {t(onListItemClick ? 'add_report.buttons.select' : 'add_report.buttons.close')}
            </ImpactiveButton>
          </ButtonWrapper>
        }
      >
        <ModalContent>
          <Name>{fullName}</Name>

          <div>{address}</div>

          {/* ***************************** /Voter Info **************************************** */}

          <hr />

          {/* ***************************** Voter Tags **************************************** */}
          <div className="flex-row flex-wrap">
            <div className="m-2">
              <FilterButton icon="inDistrict" active={inDistrict} onClick={() => {}}>
                {t('idvoters.voter.tags.in_district')}
              </FilterButton>
            </div>

            {party.party && (
              <div className="m-2">
                <FilterButton icon={party.icon} active onClick={() => null}>
                  {party.party}
                </FilterButton>
              </div>
            )}

            <div className="m-2">
              <FilterButton icon={frequencyTagConverter(frequency)} active onPress={() => null}>
                {frequency}
              </FilterButton>
            </div>
          </div>

          {/* ***************************** /Voter Tags **************************************** */}

          <hr />

          {/* ***************************** Election Attendance ******************************** */}

          <RecentElections>{t('idvoters.voter.recent_elections')}</RecentElections>
          <RecentElectionSection>
            {votingRecords.map(
              (vote, index) =>
                // na === not old enough to vote
                vote.value !== 'na' && (
                  <ElectionRow key={`${vote}-${index}`}>
                    <ElectionName>{vote.title}</ElectionName>
                    <StyledElectionImage
                      alt="election"
                      src={vote.value === 'miss' ? imageElectionX : imageElectionCheck}
                    />
                  </ElectionRow>
                ),
            )}
          </RecentElectionSection>
        </ModalContent>
      </ImpactiveModal>
    </>
  );
};

VotersHistoryModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onListItemClick: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  voter: PropTypes.object,
};

VotersHistoryModal.defaultProps = {};

export default VotersHistoryModal;
