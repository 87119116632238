import React from 'react';

const Checked = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.11101 2.17971C5.26216 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05026 12.9497C2.07129 11.9708 1.4046 10.7235 1.13451 9.36563C0.86441 8.00776 1.00303 6.6003 1.53285 5.32122C2.06266 4.04213 2.95987 2.94888 4.11101 2.17971ZM8.49188 9.30346L7 10.7955L4.5 8.2955L4.50025 8.29525L4.5 8.295L5.295 7.5L5.29525 7.50025L5.2955 7.5L7 9.2045L10.705 5.5L11.5025 6.2925L11.503 6.293L8.49188 9.30346Z"
        fill="#2A2E47"
      />
    </svg>
  );
};

Checked.propTypes = {};

Checked.defaultProps = {};

export default Checked;
