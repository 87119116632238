import React from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import AuthMenu from '../AdminAuthMenu';
import LayoutWithCover from '../Components/LayoutWithCover';
import ImpactiveLogo from '../Components/ImpactiveLogo';

import coverImage from '@assets/images/AdminAuth/laptop.png';
import { useHistory } from 'react-router-dom';
import { GrayText, Header } from '../Components/TextComponents';

import { AuthContext } from '@web/utils/context';
import { auth } from '@web/services/api';

const AdminPasswordless = () => {
  const { t } = useTranslation();
  const { setAuth } = React.useContext(AuthContext);

  const history = useHistory();

  // Passwordless login happens on the "redirected" page so that we can handle retries
  const onPasswordlessSignup = async email => {
    history.push({ pathname: '/auth/campaign-signup/confirm', state: { email } });
  };

  // Google auth handler
  const onGoogleSignup = async (email, accessToken, googleId) => {
    return auth
      .signInWithGoogle(email, accessToken, googleId)
      .then(res => onGoogleAuthSuccess(res))
      .catch(unsetAuthHandler);
  };

  const onGoogleAuthSuccess = async data => {
    const { token } = data;
    setAuth(token);

    // We only handle campaign signups using this ui path
    history.push('/new-campaign');
  };

  const unsetAuthHandler = ({ response }) => {
    // unset auth token
    setAuth(null);

    // display error message
    const { data: { error, message: errorMessage } = {} } = response;
    message.error(errorMessage || error || t('errors.action_failed'));
  };

  return (
    <LayoutWithCover coverUrl={coverImage}>
      <ImpactiveLogo />
      <Header>Sign Up To Create a Campaign</Header>
      <AuthMenu
        onGoogleLogin={onGoogleSignup}
        onPasswordlessLogin={values => onPasswordlessSignup(values.email)}
      />
      <GrayText>
        <span>By signing up, you agree to Impactive's&nbsp;</span>
        <a target="_blank" rel="noopener noreferrer" href="https://www.impactive.io/privacy-policy">
          Privacy Policy
        </a>
        {' and '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.impactive.io/terms-conditions"
        >
          Terms & Conditions
        </a>
        .
      </GrayText>
    </LayoutWithCover>
  );
};

export default AdminPasswordless;
