import * as React from 'react';
import PropTypes from 'prop-types';

function CopyIcon(props) {
  return (
    <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.2031 3.625V0.25H10.2656C9.31641 0.25 8.57812 1.02344 8.57812 1.9375V12.0625C8.57812 13.0117 9.31641 13.75 10.2656 13.75H17.0156C17.9297 13.75 18.7031 13.0117 18.7031 12.0625V4.75H15.3281C14.6953 4.75 14.2031 4.25781 14.2031 3.625ZM15.3281 0.25V3.625H18.7031L15.3281 0.25ZM7.45312 12.625V4.75H2.39062C1.44141 4.75 0.703125 5.52344 0.703125 6.4375V16.5625C0.703125 17.5117 1.44141 18.25 2.39062 18.25H9.14062C10.0547 18.25 10.8281 17.5117 10.8281 16.5625V14.875H9.70312C8.4375 14.875 7.45312 13.8906 7.45312 12.625Z"
        fill="#E5E5E5"
      />
    </svg>
  );
}

CopyIcon.propTypes = {
  height: PropTypes.number,
};

CopyIcon.defaultProps = {
  height: 19,
};

export default CopyIcon;
