import React from 'react';

const ArrowBack = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M19 10.9997H7.83L12.71 6.11967C13.1 5.72967 13.1 5.08967 12.71 4.69967C12.6175 4.60697 12.5076 4.53342 12.3866 4.48324C12.2657 4.43306 12.136 4.40723 12.005 4.40723C11.874 4.40723 11.7443 4.43306 11.6234 4.48324C11.5024 4.53342 11.3925 4.60697 11.3 4.69967L4.71 11.2897C4.6173 11.3822 4.54375 11.4921 4.49357 11.613C4.44339 11.734 4.41756 11.8637 4.41756 11.9947C4.41756 12.1256 4.44339 12.2553 4.49357 12.3763C4.54375 12.4973 4.6173 12.6072 4.71 12.6997L11.3 19.2897C11.3926 19.3823 11.5025 19.4557 11.6235 19.5058C11.7444 19.5559 11.8741 19.5817 12.005 19.5817C12.1359 19.5817 12.2656 19.5559 12.3865 19.5058C12.5075 19.4557 12.6174 19.3823 12.71 19.2897C12.8026 19.1971 12.876 19.0872 12.9261 18.9662C12.9762 18.8452 13.002 18.7156 13.002 18.5847C13.002 18.4537 12.9762 18.3241 12.9261 18.2031C12.876 18.0822 12.8026 17.9723 12.71 17.8797L7.83 12.9997H19C19.55 12.9997 20 12.5497 20 11.9997C20 11.4497 19.55 10.9997 19 10.9997Z"
        fill="#23293E"
      />
    </svg>
  );
};

ArrowBack.propTypes = {};

ArrowBack.defaultProps = {};

export default ArrowBack;
