import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// icons
import Connect from '../../icons/Connect';
import Headphones from '../../icons/Headphones';
import Phone from '../../icons/Phone';
import PhoneNumber from '../../icons/PhoneNumber';
import Success from '../../icons/Success';

import InstructionItem from './InstructionItem';

const StyledInstructions = styled.div`
  padding-top: 4px;
`;

function Instructions() {
  const { t } = useTranslation();

  return (
    <StyledInstructions>
      <InstructionItem
        icon={<Headphones />}
        title={`1. ${t('activity.phone_bank.grab_headphones')}`}
      >
        {t('activity.phone_bank.grab_headphones_description')}
      </InstructionItem>
      <InstructionItem icon={<Connect />} title={`2. ${t('activity.phone_bank.connect_phone')}`}>
        {t('activity.phone_bank.connect_phone_description')}
      </InstructionItem>
      <InstructionItem icon={<PhoneNumber />} title={`3. ${t('activity.phone_bank.enter_phone')}`}>
        {t('activity.phone_bank.enter_phone_description')}
      </InstructionItem>
      <InstructionItem icon={<Phone />} title={`4. ${t('activity.phone_bank.we_will_call')}`}>
        {t('activity.phone_bank.we_will_call_description')}
      </InstructionItem>
      <InstructionItem icon={<Success />} title={`5. ${t('activity.phone_bank.you_are_in')}`}>
        {t('activity.phone_bank.you_are_in_description')}
      </InstructionItem>
    </StyledInstructions>
  );
}

Instructions.propTypes = {};

Instructions.defaultProps = {};

export default Instructions;
