import { PhoneOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { user as userAPI } from 'web/services/api';
import { AuthContext } from 'web/utils/context';
import { setAppLanguage } from '../i18n';

const UserPreferences = () => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(AuthContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [teamName, setTeamName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [locale, setLocale] = useState(null);
  const [profileLoaded, setProfileLoaded] = useState(false);

  useEffect(() => {
    if (user) {
      const {
        first_name,
        last_name,
        team_name,
        phone,
        supplied_zip_code,
        locale: initialLocale,
      } = user;
      setFirstName(first_name);
      setLastName(last_name);
      setTeamName(team_name);
      setPhone(phone);
      setZipCode(supplied_zip_code);
      setLocale(initialLocale);
      setProfileLoaded(true);
    }
  }, [user]);

  const submit = async () => {
    userAPI
      .updateProfile({ firstName, lastName, locale, phone, teamName, zipCode })
      .then(({ data: { data } }) => {
        message.success(t('settings.updated_message'));
        setAppLanguage(locale);
        setUser(data); // Update AuthContext user
      })
      .catch(() => {
        message.error(t('errors.action_failed'));
      });
  };

  const onPhoneChangeHandler = ({ target: { value: number } }) => {
    // Add `+` prefix if user starts entering digits
    if (/^\d{1}$/.test(number)) {
      setPhone(`+1${number}`);
      return;
    }

    // Only allow numbers, starting with + symbol
    if (/^$|^(?:\+1)\d*$/.test(number)) {
      setPhone(number);
      return;
    }
  };

  const onZipChangeHandler = ({ target: { value: zip } }) => {
    if (/^\d{0,5}$/.test(zip)) {
      setZipCode(zip);
      return;
    }
  };

  return (
    <>
      <h3>{t('settings.header')}</h3>
      <Form>
        <Form.Item>
          <Input
            placeholder={t('placeholders.first_name')}
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Input
            placeholder={t('placeholders.last_name')}
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Input
            placeholder={t('placeholders.team_name')}
            value={teamName}
            onChange={e => setTeamName(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Input
            placeholder={t('placeholders.phone_number')}
            addonBefore={<PhoneOutlined />}
            value={phone}
            onChange={onPhoneChangeHandler}
          />
        </Form.Item>
        <Form.Item>
          <Input
            placeholder={t('placeholders.zip')}
            addonBefore={<EnvironmentOutlined />}
            value={zipCode}
            onChange={onZipChangeHandler}
          />
        </Form.Item>
        <Form.Item>
          <Select
            defaultValue="en"
            style={{ minWidth: '150px', width: '50%' }}
            onChange={setLocale}
            value={locale}
          >
            <Select.Option value="en">English</Select.Option>
            <Select.Option value="es">Español</Select.Option>
            <Select.Option value="ko">한국어</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" block disabled={!profileLoaded} onClick={submit}>
            {t('common.save')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UserPreferences;
