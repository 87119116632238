import React from 'react';
import PropTypes from 'prop-types';

const Recruits = ({ fullColor, fill, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      {...props}
    >
      <g opacity={fullColor ? 1 : 0.2} clipPath="url(#clip0_513_1838)">
        <g filter="url(#filter0_d_513_1838)">
          <path
            d="M27.8753 21.7928C26.447 20.2453 24.7137 19.0102 22.7846 18.1655C20.8555 17.3208 18.7723 16.8848 16.6664 16.8848C14.5605 16.8848 12.4773 17.3208 10.5482 18.1655C8.61912 19.0102 6.88582 20.2453 5.45751 21.7928C5.23699 22.0367 5.11363 22.3529 5.11084 22.6817V28.015C5.11551 28.3656 5.25805 28.7002 5.50759 28.9464C5.75714 29.1926 6.09361 29.3306 6.44417 29.3306H26.8886C27.2422 29.3306 27.5814 29.1901 27.8314 28.9401C28.0815 28.69 28.222 28.3509 28.222 27.9973V22.6639C28.2149 22.3413 28.0918 22.0321 27.8753 21.7928Z"
            fill={fill || '#393E5B'}
          />
        </g>
        <g filter="url(#filter1_d_513_1838)">
          <path
            d="M16.6663 15.1105C20.1028 15.1105 22.8885 12.3247 22.8885 8.88824C22.8885 5.4518 20.1028 2.66602 16.6663 2.66602C13.2299 2.66602 10.4441 5.4518 10.4441 8.88824C10.4441 12.3247 13.2299 15.1105 16.6663 15.1105Z"
            fill={fill || '#393E5B'}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_513_1838"
          x="-5.88916"
          y="7.88477"
          width="47.1111"
          height="36.4453"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_513_1838" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_513_1838"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_513_1838"
          x="-0.555908"
          y="-6.33398"
          width="36.4443"
          height="36.4453"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_513_1838" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_513_1838"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_513_1838">
          <rect width="32" height="32" fill="white" transform="translate(0.666748)" />
        </clipPath>
      </defs>
    </svg>
  );
};

Recruits.propTypes = {
  fill: PropTypes.string,
  fullColor: PropTypes.bool,
};

Recruits.defaultProps = {};

export default Recruits;
