import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RadioGroupFormPartial from './RadioGroupFormPartial';
import theme from '@web/styles/theme';
import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';

const StyledCard = styled.div`
  display: flex;
  width: 100%;
  background-color: ${theme.colors.white};
  flex-direction: column;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin-bottom: 20px;

  ${smallBreakpoint} {
    margin-bottom: 10px;
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
`;

const QuestionCard = ({
  handleChange,
  index,
  question: {
    answer_mappings: options,
    question: title,
    question_type: type,
    answered_value: answeredValue,
  },
  disabledAnswers,
  values,
}) => {
  if (type !== 'multiple_choice' && type !== 'closed') {
    return null;
  }

  return (
    <StyledCard>
      <Title>{title}</Title>
      <RadioGroupFormPartial
        handleChange={handleChange}
        name={`question_answers[${index}].value`}
        index={index}
        options={options}
        answeredValue={answeredValue}
        disabledAnswers={disabledAnswers}
        values={values}
      />
    </StyledCard>
  );
};

QuestionCard.propTypes = {
  disabledAnswers: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number,
  question: PropTypes.shape({
    answer_mappings: PropTypes.object,
    answered_value: PropTypes.string,
    id: PropTypes.number,
    question: PropTypes.string,
    question_type: PropTypes.string,
    required: PropTypes.bool,
  }).isRequired,
  values: PropTypes.object.isRequired,
};

export default QuestionCard;
