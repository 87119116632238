import { List } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import MessageThreadLoading from './MessageThreadLoading';
import MessageThreadPreview from './MessageThreadPreview';

const InboxList = ({ messages, loading, setMessageThreadParams, messageThreadId }) => {
  return (
    <List
      itemLayout="vertical"
      dataSource={messages}
      renderItem={message => (
        <MessageThreadPreview
          messageThread={message}
          activeMessageThreadId={messageThreadId}
          setMessageThreadParams={setMessageThreadParams}
        />
      )}
    >
      {loading && <MessageThreadLoading />}
    </List>
  );
};

InboxList.propTypes = {
  loading: PropTypes.bool.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  messageThreadId: PropTypes.number,
  setMessageThreadParams: PropTypes.func.isRequired,
};

export default InboxList;
