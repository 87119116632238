import React from 'react';

import styled from 'styled-components';
import logo from '@assets/images/logo/Impactive_Primary.png';

const LogoImage = styled.img`
  padding: 11px;
  max-width: 200px;
`;

const ImpactiveLogo = () => <LogoImage src={logo} alt="Impactive logo" />;

export default ImpactiveLogo;
