import * as React from 'react';
// import PropTypes from 'prop-types';

function Search(props) {
  return (
    <svg height={14} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.851"
        d="M7.40001 7.9129C6.70001 8.6129 5.90001 8.9129 4.90001 8.9129C4.00001 8.9129 3.10001 8.5129 2.40001 7.9129C1.70001 7.2129 1.40001 6.4129 1.40001 5.4129C1.40001 4.5129 1.80001 3.6129 2.40001 2.9129C3.10001 2.2129 3.90001 1.9129 4.90001 1.9129C5.80001 1.9129 6.70001 2.3129 7.40001 2.9129C8.10001 3.6129 8.40001 4.4129 8.40001 5.4129C8.40001 6.4129 8.10001 7.3129 7.40001 7.9129ZM12.7 12.4129L8.60001 8.3129C9.20001 7.5129 9.60001 6.5129 9.60001 5.4129C9.60001 4.1129 9.10001 3.01289 8.20001 2.11289C7.30001 1.21289 6.10001 0.712891 4.90001 0.712891C3.70001 0.712891 2.50001 1.21289 1.60001 2.11289C0.700012 3.01289 0.200012 4.2129 0.200012 5.4129C0.200012 6.7129 0.700012 7.81289 1.60001 8.71289C2.50001 9.61289 3.70001 10.1129 4.90001 10.1129C5.90001 10.1129 6.90001 9.81289 7.80001 9.11289L11.9 13.2129C12 13.3129 12 13.3129 12.1 13.2129L12.8 12.5129C12.8 12.5129 12.8 12.4129 12.7 12.4129Z"
      />
    </svg>
  );
}

Search.propTypes = {};

Search.defaultProps = {};

export default Search;
