import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ImpactiveButton from '@web/components/ImpactiveButton';
import theme, { media } from '@web/styles/theme';
import { convertLinks } from '@web/utils/string';
import { getFont } from './utils';
import { trackPageViewWithDynamicIDs } from '@web/services/analytics';

const PageWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  min-height: 100vh;
  max-width: 100%;
  margin: 0 auto;
  background-color: ${({ $bgColor }) => $bgColor || theme.formDefaultColors.pageBackgroundColor};

  * {
    font-family: ${({ $font }) => getFont($font)};
  }

  ${media.nlg} {
    flex-direction: column;
    height: auto;
  }
`;

const Images = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto 0;
  padding: 12px;
  width: 624px;

  ${media.nxl} {
    width: 424px;
  }
  ${media.nlg} {
    width: 100%;
    margin: auto 0 0;
  }
`;

const FormIntroImage = styled.img`
  max-height: 600px;
  object-fit: contain;
  border-radius: 5px;
`;

const IncentiveImage = styled.img`
  height: 200px;
  object-fit: contain;
  border-radius: 5px;

  ${media.nlg} {
    height: unset;
  }
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto 0;
  align-items: center;
  padding: 12px;

  width: 500px;

  ${media.nlg} {
    width: 100%;
    margin: 0 0 auto;
  }
`;

const MainImage = styled.img`
  width: 350px;
  height: 350px;
  object-fit: contain;
  border-radius: 5px;
  margin: auto;

  ${media.nlg} {
    display: ${({ $mobileOnly }) => ($mobileOnly ? 'block' : 'none')};
    width: 150px;
    height: 150px;
  }

  ${media.lg} {
    display: ${({ $mobileOnly }) => ($mobileOnly ? 'none' : 'block')};
  }
`;

const DisclaimerText = styled.p`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: ${({ $color }) => $color ?? theme.formDefaultColors.fontColor};
`;

const SurveyIntroPage = ({ activity, team, showForm }) => {
  const history = useHistory();
  const { search } = useLocation();
  const formQueryParams = new URLSearchParams(search);

  const incentive = activity.activity_incentives_attributes?.[0];
  const imageSrc = team?.profile_img_url || activity.campaign.url_profile_img;

  const goToForm = () => {
    formQueryParams.delete('start');
    showForm();

    history.push({
      pathname: '',
      search: formQueryParams.toString(),
    });
  };

  const {
    background_color,
    secondary_color,
    button_font_color,
    main_font_color,
    font,
    pixel_fb_id,
    pixel_ga_id,
    pixel_sc_id,
    pixel_gtm_id,
  } = activity.settings ?? {};

  useEffect(() => {
    trackPageViewWithDynamicIDs({
      fbPixelId: pixel_fb_id,
      gaTrackingId: pixel_ga_id,
      gtmPixelId: pixel_gtm_id,
      snapchatPixelId: pixel_sc_id,
    });
  }, [pixel_fb_id, pixel_ga_id, pixel_sc_id, pixel_gtm_id]);

  return (
    <PageWrapper $font={font} $bgColor={background_color}>
      <Images>
        {/* Main image is css controlled, and will be shown here on top on small screens only */}
        <MainImage src={imageSrc} $mobileOnly />
        <FormIntroImage src={activity.media_url} />
        {incentive && <IncentiveImage src={incentive.name} />}
      </Images>

      <InfoSection>
        <MainImage src={imageSrc} />
        <ImpactiveButton
          $background={secondary_color ?? theme.formDefaultColors.buttonColor}
          size="full-width"
          onClick={goToForm}
          style={{ color: button_font_color ?? theme.formDefaultColors.buttonFontColor }}
        >
          {activity.button_text || 'Start'}
        </ImpactiveButton>
        <DisclaimerText
          $color={main_font_color}
          dangerouslySetInnerHTML={{
            __html: convertLinks(activity.paid_by),
          }}
        ></DisclaimerText>
      </InfoSection>
    </PageWrapper>
  );
};

SurveyIntroPage.propTypes = {
  activity: PropTypes.object,
  showForm: PropTypes.func.isRequired,
  team: PropTypes.object,
};

export default SurveyIntroPage;
