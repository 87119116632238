import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { media } from '@web/styles/theme';

import { truncate } from '@web/utils/string';

import TwitchEmbed from './components/TwitchEmbed';
import YoutubeEmbed from './components/YoutubeEmbed';
import MediaPreview from '../MediaPreview';

const StyledAboutUsSection = styled.div`
  padding: 0;
  width: 100%;
  background-color: ${({ background, theme }) => (background ? theme.colors.lightBlue : 'none')};
  transform: skewY(-1.3deg);
`;

const Container = styled.div`
  margin: 0 auto;
  transform: skewY(1.3deg);
  padding: 50px 25px;
  margin: 0 auto;

  ${media.ns} {
    padding: 120px 0;
    width: 70%;
  }

  ${media.lg} {
    padding: 180px 0;
    width: 80%;
    display: grid;
    grid-template-columns: ${({ type }) => (type !== 'twitch' ? '1fr 1fr' : '2fr 1fr')};
  }
`;

const MobileSectionTitle = styled.h3`
  font-size: 3em;
  color: ${({ theme }) => theme.colors.blackL25};
  font-weight: 600;

  ${media.lg} {
    display: none;
  }
`;

const SectionTitle = styled.h3`
  display: none;

  ${media.lg} {
    display: block;
    font-size: 3em;
    line-height: 76.8px;
    color: ${({ theme }) => theme.colors.blackL25};
    font-weight: 600;
    margin-bottom: 20px;
  }

  ${media.xl} {
    margin-bottom: 40px;
  }
`;

const AboutUsMedia = styled.div`
  width: 100%;
  border-radius: 20px;
  margin: 0 auto;
  max-width: ${({ type }) => (type !== 'twitch' ? '800px' : 'none')};
`;

const AboutUsDetails = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.lg} {
    padding-left: 40px;
  }
`;

const SectionDescription = styled.p`
  font-size: 1.2em;
  padding-top: 40px;
  max-width: 600px;

  ${media.lg} {
    padding-top: 0;
  }

  ${media.xl} {
    margin-bottom: 40px;
  }
`;

const SeeMoreLink = styled.a`
  font-weight: bold;
  font-size: 1.2em;
  color: ${({ theme }) => theme.colors.salmon};
  opacity: 0.85;
`;

const MAX_LENGTH = 500;

const AboutUsSection = ({ background, description, mediaContentType, mediaUrl, title }) => {
  const { t } = useTranslation();
  const [seeMore, setSeeMore] = React.useState(false);
  const [aboutDescription, setAboutDescription] = React.useState(truncate(description, MAX_LENGTH));
  const needSeeMoreButton = description?.length > MAX_LENGTH;

  const toggleSeeMore = () => {
    setAboutDescription(seeMore ? truncate(description, MAX_LENGTH) : description);
    setSeeMore(!seeMore);
  };

  return (
    <StyledAboutUsSection background={background}>
      <Container type={mediaContentType}>
        <MobileSectionTitle>{title}</MobileSectionTitle>
        <AboutUsMedia type={mediaContentType}>
          {!!mediaUrl && ['image', 'video'].includes(mediaContentType) && (
            <MediaPreview mediaUrl={mediaUrl} mediaContentType={mediaContentType} />
          )}
          {!!mediaUrl && mediaContentType === 'twitch' && <TwitchEmbed mediaUrl={mediaUrl} />}
          {!!mediaUrl && mediaContentType === 'youtube' && <YoutubeEmbed mediaUrl={mediaUrl} />}
        </AboutUsMedia>
        <AboutUsDetails>
          <SectionTitle>{title}</SectionTitle>
          <SectionDescription>{aboutDescription}</SectionDescription>

          {needSeeMoreButton && (
            <SeeMoreLink onClick={toggleSeeMore}>
              {seeMore ? t('campaign.see_less') : t('campaign.see_more')}
            </SeeMoreLink>
          )}
        </AboutUsDetails>
      </Container>
    </StyledAboutUsSection>
  );
};

AboutUsSection.propTypes = {
  background: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  mediaContentType: PropTypes.string.isRequired,
  mediaUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default AboutUsSection;
