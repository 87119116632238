import * as React from 'react';
import PropTypes from 'prop-types';
import { track } from '@web/services/analytics';

import DotLoader from '@web/components/DotLoader';
import BackToStart from '@web/components/Headcount/components/BackToStart';
import ExternalButtonLink from '@web/components/Headcount/components/ExternalButtonLink';
import ShareLink from '@web/components/Headcount/components/ShareLink';
import Text from '@web/components/Headcount/components/Text';
import VoterBox from '@web/components/Headcount/components/VoterBox';
import VotingDates from '@web/components/Headcount/components/VotingDates';
import HeroImage from '@web/components/Headcount/components/HeroImage';

import useFetchVoterRecord from '@web/components/Headcount/hooks/useFetchVoterRecord';
import useIsDesktop from '@web/components/Headcount/hooks/useIsDesktop';
import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { activities } from '@web/services/api';

function RegisterToVoteResult({ activity, backToStart, team }) {
  const { contactFormResult, hasStateId } = useSelector(state => state.voterRegistration);
  const { error, loading, voterRecord } = useFetchVoterRecord(activity);
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const isPartner = useIsPartner(team);
  const cannabisRegistrationEnabled = React.useMemo(
    () => activity?.campaign?.cannabis_registration_enabled,
    [activity?.campaign?.cannabis_registration_enabled],
  );

  const headcountMtvEnabled = React.useMemo(
    () => activity.campaign.headcount_mtv_enabled,
    [activity.campaign.headcount_mtv_enabled],
  );

  let title = t('check_registration.headcount.followLink');
  if (isPartner) {
    title = t('check_registration.headcount.partnerFollowLink');
  }
  if (cannabisRegistrationEnabled) {
    title = t('check_registration.headcount.cannabisFollowLink');
  }

  track('HEADCOUNT_VIEW_REGISTER_TO_VOTE', {
    activity_id: activity?.id,
    activity_title: activity?.title,
    activity_type: activity?.type,
    campaign_id: activity?.campaign?.id,
    campaign_name: activity?.campaign?.name,
    team_id: team?.id,
    team_name: team?.name,
  });

  const trackOfLinkClick = React.useCallback(
    url => {
      activities.trackExternalLinkClick({
        activityId: activity?.id,
        activitySlug: activity?.slug,
        campaignId: activity?.campaign?.id,
        contactId: contactFormResult?.id,
        url,
      });
    },
    [activity?.campaign?.id, activity?.id, activity?.slug, contactFormResult?.id],
  );

  if (loading) {
    return <DotLoader centered color="black" />;
  }

  if (error) {
    return (
      <Text color="red" variant="f3">
        {error}
      </Text>
    );
  }

  const stateAbbreviation = voterRecord?.state_abbrev;

  const regParams = new URLSearchParams({
    apartment: contactFormResult?.unit_number,
    campaign: 'impactive',
    city: contactFormResult?.city,
    date_of_birth_day: parseInt(contactFormResult?.date_of_birth.split('-')[2]),
    date_of_birth_month: parseInt(contactFormResult?.date_of_birth.split('-')[1]),
    date_of_birth_year: parseInt(contactFormResult?.date_of_birth.split('-')[0]),
    email: contactFormResult?.email,
    first_name: contactFormResult?.first_name,
    last_name: contactFormResult?.last_name,
    phone_number: contactFormResult?.phone.replace('+1', ''),
    state: contactFormResult?.state_abbrev,
    street_address: contactFormResult?.address,
    zip_5: contactFormResult?.zip_code,
  });

  const registrationLink =
    !hasStateId || !voterRecord?.voting_info?.online_reg_link
      ? `https://www.headcount.org/registertovote/?${regParams.toString()}`
      : voterRecord?.voting_info?.online_reg_link;

  const trackClick = async () => {
    trackOfLinkClick(registrationLink);
    track('HEADCOUNT_CLICK_REGISTRATION_LINK', {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      automatic_vbm: contactFormResult?.automatic_vbm,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      can_register_online: contactFormResult?.can_register_online,
      can_vbm: contactFormResult?.can_vbm,
      can_vbm_online: contactFormResult?.can_vbm_online,
      contact_id: contactFormResult?.id,
      has_state_identification: contactFormResult?.has_state_identification,
      registration_status: contactFormResult?.registration_status,
      state_abbrev: contactFormResult?.state_abbrev,
      team_id: team?.id,
      team_name: team?.name,
    });
  };

  const address = {
    line1: `${voterRecord?.address}`,
    line2: `${voterRecord.unit_number}`,
    line3: `${voterRecord?.city}, ${stateAbbreviation.toUpperCase()} ${voterRecord?.zip_code}`,
  };

  return (
    <>
      {headcountMtvEnabled && (
        <ShareLink
          activity={activity}
          referralLink={contactFormResult?.referral_link}
          team={team}
        />
      )}
      <Text variant="f2">{title}</Text>
      <VoterBox
        address={address}
        label={{ text: t('check_registration.headcount.statusNotRegistered') }}
        voterName={contactFormResult?.fullname}
      />
      {registrationLink && stateAbbreviation !== 'ND' && (
        <ExternalButtonLink href={registrationLink} onClick={trackClick}>
          {t('voting_information.register_to_vote')}
        </ExternalButtonLink>
      )}
      {stateAbbreviation === 'ND' && (
        <p>{'No voter registration required. Go to your polling site with a valid ID.'}</p>
      )}
      <VotingDates
        stateAbbreviation={stateAbbreviation}
        team={team}
        activity={activity}
        votingInfo={voterRecord?.voting_info}
      />
      {!isDesktop && isPartner && !headcountMtvEnabled && (
        <HeroImage alt={team?.name} src={team?.incentives_img_url} />
      )}
      {!headcountMtvEnabled && (
        <ShareLink
          activity={activity}
          referralLink={contactFormResult?.referral_link}
          team={team}
        />
      )}
      <BackToStart onClick={backToStart} />
    </>
  );
}

RegisterToVoteResult.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      cannabis_registration_enabled: PropTypes.bool,
      headcount_mtv_enabled: PropTypes.string,
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }).isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    slug: PropTypes.string,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
  backToStart: PropTypes.func.isRequired,
  team: PropTypes.object,
};

RegisterToVoteResult.defaultProps = {};

export default RegisterToVoteResult;
