import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import styled from 'styled-components';
import Clipboard from 'react-clipboard.js';
import { message as messageAlert } from 'antd';
import theme from '@web/styles/theme';

const LabelText = styled.div`
  align-self: center;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  padding-right: 2em;
`;

const LineBreak = styled.hr`
  margin: 1em 0;
`;

const ShareInstructions = styled.div`
  color: ${({ theme }) => theme.colors.lightBlack};
  font-size: 14px;
  line-height: 20px;
`;

const Quote = styled.div`
  padding-left: 1em;
  margin: 1em 0 1em 0;
  font-style: italic;
  font-size: 16px;
  line-height: 20px;
`;

const CopyButton = styled.div`
  background: ${theme.colors.darkBlack};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  padding: 6px 16px;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 8px;
  &:hover {
    opacity: 0.5;
  }
`;

const ShareActivity = ({ campaignName, url, message, onShareWindowClose }) => {
  const { t } = useTranslation();
  const title = campaignName;

  // Invoke onShareWindowClose with delay of 2 seconds for email action
  const delayedShareWindowClose = () => setTimeout(onShareWindowClose, 2000);

  return (
    <Fragment>
      {message && (
        <>
          <ShareInstructions>{t('activity.share.text')}</ShareInstructions>
          <LineBreak />
          <Quote className="justify-content-center">{message}</Quote>
        </>
      )}
      <LineBreak />
      <div className="flex-row justify-content-center">
        <Clipboard
          component={CopyButton}
          data-clipboard-text={message}
          onClick={e => e.stopPropagation()}
          onSuccess={() => messageAlert.info(t('activity.share.message_copied_to_clipboard'))}
        >
          {t('activity.share.copy')}
        </Clipboard>

        <LabelText>{t('activity.share.share')}</LabelText>
        <div className="pointer fb-share-btn">
          <FacebookShareButton onShareWindowClose={onShareWindowClose} url={url} quote={message}>
            <FacebookIcon round size={40} />
          </FacebookShareButton>
        </div>
        <div className="mx-2 pointer tw-share-btn">
          <TwitterShareButton onShareWindowClose={onShareWindowClose} url={url} title={message}>
            <TwitterIcon round size={40} />
          </TwitterShareButton>
        </div>
        <div className="pointer email-share-btn">
          <EmailShareButton
            beforeOnClick={delayedShareWindowClose}
            url={url}
            subject={title}
            body={message}
          >
            <EmailIcon round size={40} />
          </EmailShareButton>
        </div>
      </div>
    </Fragment>
  );
};

ShareActivity.propTypes = {
  campaignName: PropTypes.string.isRequired,
  message: PropTypes.string,
  onShareWindowClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

ShareActivity.defaultProps = {
  message: '',
};

export default ShareActivity;
