import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';

import VoterMatchCore from './VoterMatchCore';

const StyledModal = styled(Modal)`
  &&& {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const VoterMatchModal = ({
  contactId,
  voter: voterParams,
  voterbaseId,
  isFriend,
  userActivityId,
  activityId,
  campaignId,
  setVisibleFn,
  setShowIdVotersModal,
}) => {
  return (
    <div>
      <StyledModal
        bodyStyle={{ background: theme.colors.background }}
        visible
        maskClosable
        onCancel={() => setVisibleFn(false)}
        footer={null}
      >
        <VoterMatchCore
          contactId={contactId}
          voterParams={voterParams}
          voterbaseId={voterbaseId}
          isFriend={isFriend}
          userActivityId={userActivityId}
          activityId={activityId}
          campaignId={campaignId}
          onBadMatch={() => {
            setVisibleFn(false);
            setShowIdVotersModal(true);
          }}
          onSaveMatchFinally={() => setVisibleFn(false)}
        />
      </StyledModal>
    </div>
  );
};

VoterMatchModal.propTypes = {
  activityId: PropTypes.number,
  campaignId: PropTypes.number,
  contactId: PropTypes.number,
  isFriend: PropTypes.bool,
  setShowIdVotersModal: PropTypes.func,
  setVisibleFn: PropTypes.func,
  userActivityId: PropTypes.number,
  voter: PropTypes.object,
  voterbaseId: PropTypes.string,
};

export default VoterMatchModal;
