import * as React from 'react';
import PropTypes from 'prop-types';
import { track } from '@web/services/analytics';

import BackToStart from '@web/components/Headcount/components/BackToStart';
import DotLoader from '@web/components/DotLoader';
import ExternalButtonLink from '@web/components/Headcount/components/ExternalButtonLink';
import HeroImage from '@web/components/Headcount/components/HeroImage';
import ShareLink from '@web/components/Headcount/components/ShareLink';
import Text from '@web/components/Headcount/components/Text';
import VoterBox from '@web/components/Headcount/components/VoterBox';
import VotingDates from '@web/components/Headcount/components/VotingDates';

import useIsDesktop from '@web/components/Headcount/hooks/useIsDesktop';
import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';
import useFetchVoterRecord from '@web/components/Headcount/hooks/useFetchVoterRecord';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { automaticVBMStates, excuseVBMStates } from '../helpers';

function VoteByMailResult({ activity, backToStart, team }) {
  const { contactFormResult, selectedVoter } = useSelector(state => state.voterRegistration);
  const { error, loading, voterRecord } = useFetchVoterRecord(activity);
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const isPartner = useIsPartner(team);

  const headcountMtvEnabled = React.useMemo(
    () => activity.campaign.headcount_mtv_enabled,
    [activity.campaign.headcount_mtv_enabled],
  );

  const isExcuseVBMState = React.useMemo(
    () => excuseVBMStates.includes(contactFormResult?.state_abbrev),
    [contactFormResult?.state_abbrev],
  );

  const isAutomaticVBMState = React.useMemo(
    () => automaticVBMStates.includes(contactFormResult?.state_abbrev),
    [contactFormResult?.state_abbrev],
  );

  const title = React.useMemo(
    () =>
      t(
        `check_registration.headcount.voteByMail.${
          isExcuseVBMState ? 'excuseVBMTitle' : isAutomaticVBMState ? 'automaticVBMTitle' : 'title'
        }`,
        { state: t(`states.${contactFormResult?.state_abbrev}`) },
      ),
    [contactFormResult?.state_abbrev, isExcuseVBMState, isAutomaticVBMState, t],
  );

  track('HEADCOUNT_VIEW_VOTE_BY_MAIL_RESULT', {
    activity_id: activity?.id,
    activity_title: activity?.title,
    activity_type: activity?.type,
    campaign_id: activity?.campaign?.id,
    campaign_name: activity?.campaign?.name,
    team_id: team?.id,
    team_name: team?.name,
  });

  const trackClick = async () => {
    track('HEADCOUNT_CLICK_REQUEST_BALLOT', {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      automatic_vbm: contactFormResult?.automatic_vbm,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      can_register_online: contactFormResult?.can_register_online,
      can_vbm: contactFormResult?.can_vbm,
      can_vbm_online: contactFormResult?.can_vbm_online,
      contact_id: contactFormResult?.id,
      has_state_identification: contactFormResult?.has_state_identification,
      registration_status: contactFormResult?.registration_status,
      state_abbrev: contactFormResult?.state_abbrev,
      team_id: team?.id,
      team_name: team?.name,
    });
  };

  if (loading) {
    return <DotLoader centered color="black" />;
  }

  if (error) {
    return (
      <Text color="red" variant="f3">
        {error}
      </Text>
    );
  }

  const stateAbbreviation = selectedVoter?.addressLine2?.split(',')[1].trim();

  const address = {
    // MTS - The voter database concats the first 2 address lines into
    // addressLine1, therefore the "unit/apt" will always be part of line 1
    line1: selectedVoter?.addressLine1,
    line2: null,
    line3: `${selectedVoter?.addressLine2} ${selectedVoter?.zip}`,
  };

  const regParams = new URLSearchParams({
    apartment: contactFormResult?.unit_number,
    campaign: 'impactive',
    city: contactFormResult?.city,
    date_of_birth_day: parseInt(contactFormResult?.date_of_birth.split('-')[2]),
    date_of_birth_month: parseInt(contactFormResult?.date_of_birth.split('-')[1]),
    date_of_birth_year: parseInt(contactFormResult?.date_of_birth.split('-')[0]),
    email: contactFormResult?.email,
    first_name: contactFormResult?.first_name,
    last_name: contactFormResult?.last_name,
    phone_number: contactFormResult?.phone.replace('+1', ''),
    state: contactFormResult?.state_abbrev,
    street_address: contactFormResult?.address,
    zip_5: contactFormResult?.zip_code,
  });

  return (
    <>
      {headcountMtvEnabled && (
        <ShareLink
          activity={activity}
          referralLink={contactFormResult?.referral_link}
          team={team}
        />
      )}
      <Text
        variant="f2"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <VoterBox
        address={address}
        label={{ bgColor: 'green', text: t('check_registration.headcount.statusRegistered') }}
        voterName={contactFormResult?.fullname}
      />
      <ExternalButtonLink
        href={`https://www.headcount.org/request-your-ballot/?${regParams.toString()}`}
        onClick={trackClick}
      >
        {t('check_registration.headcount.voteByMail.buttonLinkText')}
      </ExternalButtonLink>
      <VotingDates
        stateAbbreviation={stateAbbreviation}
        team={team}
        activity={activity}
        voteByMail
        votingInfo={voterRecord?.voting_info}
      />
      {!isDesktop && isPartner && !headcountMtvEnabled && (
        <HeroImage alt={team?.name} src={team?.incentives_img_url} />
      )}
      {!headcountMtvEnabled && (
        <ShareLink
          activity={activity}
          referralLink={contactFormResult?.referral_link}
          team={team}
        />
      )}
      <BackToStart onClick={backToStart} />
    </>
  );
}

VoteByMailResult.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      headcount_mtv_enabled: PropTypes.bool,
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }).isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
  backToStart: PropTypes.func.isRequired,
  hasStateId: PropTypes.bool.isRequired,
  team: PropTypes.object,
};

VoteByMailResult.defaultProps = {};

export default VoteByMailResult;
