import React from 'react';

import BreadcrumbContainer from '@web/components/common/Breadcrumb';

import styled from 'styled-components';
import PropTypes from 'prop-types';

const HeaderAndDescription = styled.div``;
const Header = styled.div`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.semibold};
  color: ${({ theme }) => theme.colors.black};
`;

const Description = styled.div`
  font-size: 1rem;
  margin: 40px 0;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.black};
  opacity: 0.6;
`;

const PageHeader = ({ title, description, breadcrumbData }) => {
  return (
    <HeaderAndDescription>
      <BreadcrumbContainer urlStack={breadcrumbData} />
      <Header>{title}</Header>
      {description && <Description>{description}</Description>}
    </HeaderAndDescription>
  );
};

PageHeader.propTypes = {
  breadcrumbData: PropTypes.array,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

PageHeader.defaultProps = {
  breadcrumbData: [],
  description: null,
};

export default PageHeader;
