import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

function SEO({ description, image, title }) {
  const fallbackDescription = `Whether it's amplifying the national discussion on important issues, mobilizing for policy fights, or getting your favorite candidates elected, Impactive empowers progressives like you to create change both on and offline.`;
  const fallbackImage =
    'https://s3.amazonaws.com/outvote-static-assets/development/uploads/Ll/1585241956/outvote-default.jpg';
  const fallbackTitle = 'Impactive';

  const seoDescription = description || fallbackDescription;
  const seoImage = image || fallbackImage;
  const seoTitle = title ? `Impactive · ${title}` : fallbackTitle;

  return (
    <Helmet title={seoTitle || undefined}>
      <meta name="description" content={seoDescription} />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@joinimpactive" />
      <meta name="twitter:domain" content="impactive.io" />
      <meta name="twitter:site" content="@joinimpactive" />
      <meta name="twitter:description" content={seoDescription} />
      <meta name="twitter:image" content={seoImage} />
      <meta name="twitter:title" content={seoTitle} />
      {/* Schema.org  */}
      {/* Need to define which schemas to use here in the future  */}
      {/* Open Graph */}
      <meta property="og:description" content={seoDescription} />
      <meta property="og:image" content={seoImage} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:url" content={`https://impactive.io/${window.location.pathname}`} />
    </Helmet>
  );
}

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
};

SEO.defaultProps = {
  description: null,
  image: null,
  title: null,
};

export default SEO;
