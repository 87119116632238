import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledHeader = styled.img`
  margin-bottom: ${({ theme }) => theme.spacing.wide};
  width: 100%;
`;

function Header(props) {
  if (!props.src) {
    return null;
  }

  if (!props.url) {
    return <StyledHeader {...props} />;
  }

  return (
    <a href={props.url} target="_blank" rel="noreferrer">
      <StyledHeader {...props} />
    </a>
  );
}

Header.propTypes = {
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  src: PropTypes.string,
  url: PropTypes.string,
};

Header.defaultProps = {};

export default Header;
