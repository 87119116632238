import React from 'react';

const LinkedIn = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.8588 6.47352H9.9538V8.01518C10.3996 7.12852 11.543 6.33185 13.2605 6.33185C16.553 6.33185 17.3346 8.09685 17.3346 11.3352V17.3327H14.0013V12.0727C14.0013 10.2285 13.5555 9.18852 12.4205 9.18852C10.8463 9.18852 10.1921 10.3093 10.1921 12.0718V17.3327H6.8588V6.47352ZM1.14297 17.191H4.4763V6.33185H1.14297V17.191ZM4.9538 2.79102C4.95393 3.07041 4.89852 3.34704 4.79079 3.60484C4.68307 3.86263 4.52518 4.09644 4.3263 4.29268C3.9233 4.6932 3.37781 4.91739 2.80964 4.91602C2.24246 4.91563 1.69823 4.69201 1.29464 4.29352C1.09648 4.09661 0.939124 3.86254 0.831588 3.60471C0.724053 3.34688 0.668451 3.07037 0.667969 2.79102C0.667969 2.22685 0.892969 1.68685 1.29547 1.28852C1.69871 0.889481 2.24317 0.665766 2.81047 0.666016C3.3788 0.666016 3.9238 0.890182 4.3263 1.28852C4.72797 1.68685 4.9538 2.22685 4.9538 2.79102Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkedIn;
