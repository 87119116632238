import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from '@web/components/Headcount/components/Text';

const StyledListItem = styled.li`
  align-items: center;
  display: flex;
  margin-left: ${({ theme }) => theme.spacing.tight};
  margin-top: ${({ theme }) => theme.spacing.wide};
`;

const IconArea = styled.div`
  display: flex;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing.normal};
  width: 22px;
`;

function ListItem({ children, icon }) {
  return (
    <StyledListItem>
      <IconArea>
        <svg height="19" viewBox={icon.viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d={icon.d} fill="black" />
        </svg>
      </IconArea>
      <Text variant="f2">{children}</Text>
    </StyledListItem>
  );
}

ListItem.propTypes = {
  children: PropTypes.any.isRequired,
  icon: PropTypes.shape({
    d: PropTypes.string.isRequired,
    viewBox: PropTypes.string.isRequired,
  }).isRequired,
};

ListItem.defaultProps = {};

export default ListItem;
