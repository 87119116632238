import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import ImpactiveButton from '@web/components/ImpactiveButton';

const ButtonWithDropdown = ({
  buttonText,
  icon,
  onClick,
  menuItems,
  buttonType,
  placement,
  styles,
  trigger,
  disabled,
  ...rest
}) => {
  const menu = (
    <Menu>
      {menuItems.map((menuItem, index) => {
        return (
          <Menu.Item
            icon={menuItem.icon}
            disabled={menuItem.disabled}
            key={index}
            onClick={menuItem.onClick}
          >
            {menuItem.tooltipData ? (
              <Tooltip placement="top" trigger={'hover'} title={menuItem.tooltipData}>
                {menuItem.text}
              </Tooltip>
            ) : (
              menuItem.text
            )}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown disabled={disabled} overlay={menu} trigger={trigger || 'hover'} placement={placement}>
      <ImpactiveButton
        type={buttonType ?? 'primary'}
        style={styles}
        icon={icon || <DownOutlined />}
        noIconMargin={!buttonText}
        onClick={onClick}
        {...rest}
      >
        {buttonText}
      </ImpactiveButton>
    </Dropdown>
  );
};

ButtonWithDropdown.propTypes = {
  buttonText: PropTypes.string,
  buttonType: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({ icon: PropTypes.element, onClick: PropTypes.func, text: PropTypes.string }),
  ),
  onClick: PropTypes.func,
  placement: PropTypes.oneOf(['bottomLeft', 'bottom', 'bottomRight', 'topLeft', 'top', 'topRight']),
  styles: PropTypes.object,
  trigger: PropTypes.string,
};

export default ButtonWithDropdown;
