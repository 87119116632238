import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Avatar } from 'antd';
import BackLink from './BackLink';
import { Link, useParams } from 'react-router-dom';
import { replaceDefaultCampaignImage } from '@web/utils/string';

const StyledHeader = styled.div``;

const Content = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 35px;
  margin-top: 17px;
`;

const CampaignName = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 24px;
  margin-left: 21px;
  :hover {
    color: ${({ theme }) => theme.colors.black};
    text-decoration-color: ${({ theme }) => theme.colors.black};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ActivityTitle = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;
  margin-left: 21px;
`;

function Header({ activity, backLink, backText, backTo }) {
  let { userActivityId } = useParams();

  if (!activity) {
    return null;
  }

  const baseRoute = `/activities/${userActivityId}/phone-bank`;
  return (
    <StyledHeader>
      <BackLink backLink={backTo || `${baseRoute}/${backLink}`} backText={backText} />
      <Content>
        <Avatar size={54} src={replaceDefaultCampaignImage(activity?.campaign?.url_profile_img)} />
        <Column>
          <CampaignName to={`/campaigns/${activity?.campaign?.slug}`}>
            {activity?.campaign?.name}
          </CampaignName>
          {activity && <ActivityTitle>{activity?.title}</ActivityTitle>}
        </Column>
      </Content>
    </StyledHeader>
  );
}

Header.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      url_profile_img: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string,
  }).isRequired,
  backLink: PropTypes.string,
  backText: PropTypes.any,
  backTo: PropTypes.string,
};

Header.defaultProps = {
  backLink: '',
  backText: 'Back',
  backTo: null,
};

export default Header;
