import * as React from 'react';
import PropTypes from 'prop-types';

import { Body, Title } from '@web/components/DialerActivity/components/TextStyles';
import BackButton from '../../components/BackButton';
import ConnectIcon from '@web/components/DialerActivity/icons/Connect';
import NextButton from '../../components/NextButton';
import Page, { CopyWrapper } from '../../components/Page';
import { useTranslation } from 'react-i18next';

function Connect({ onBack, onNext }) {
  const { t } = useTranslation();

  return (
    <Page>
      <ConnectIcon size={118} />
      <CopyWrapper>
        <Title>{t('activity.phone_bank.connect_phone')}</Title>
        <Body>{t('activity.phone_bank.connect_phone_description')}</Body>
      </CopyWrapper>
      <NextButton onClick={onNext} />
      <BackButton onClick={onBack} />
    </Page>
  );
}

Connect.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

Connect.defaultProps = {};

export default Connect;
