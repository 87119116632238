import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { user, users, userReportApi } from '@web/services/api';
import { track } from '@web/services/analytics';
import sendError from '@web/utils/sendError';
import {
  formatCustomFields,
  formatTags,
  mergeBlankDataWithInitialData,
  updateReportData,
} from './utils';

/**
 * Hook to process add report flow
 * @param isAddManually
 * There are two flows of reporting
 *  1. Add a report for existing voters
 *  2. Add the report manually if no voter is found
 * @param voter
 * If a voter is found, we must pre-select tags and custom fields from the last report, so we need to know the voter information to get the latest report
 */
const useSendReport = (isAddManually, voter) => {
  const campaign = useSelector(state => state.oneCampaign.selectedCampaign);
  const { t } = useTranslation();
  const submitErrorMessage = t('errors.submit_outreach_report');

  const [tags, setTags] = useState(null);
  const [customFields, setCustomFields] = useState(null);

  // Get a blank report with all the tags and custom fields that are configured on the admin side
  const getBlankReport = useCallback(async () => {
    const response = await userReportApi.getBlankReport({
      activity_id: campaign.report_activity_id,
      campaign_id: campaign.id,
    });

    return response.data;
  }, [campaign]);

  // Find an existing report or create a new one if the report was not completed for this voter before
  const findOrCreateReport = useCallback(
    async body => {
      const response = await userReportApi.findOrCreateReport(
        {
          activity_id: campaign.report_activity_id,
          campaign_id: campaign.id,
        },
        body,
      );

      return response.data;
    },
    [campaign],
  );

  // We need to get a blank report and set tags and custom fields
  const getAddManuallyData = useCallback(async () => {
    try {
      if (!isAddManually) return;

      const blankReport = await getBlankReport();

      setReportFields(blankReport);
    } catch (e) {
      throw Error(e);
    }
  }, [getBlankReport, isAddManually]);

  // Here we need to get a blank report and the last completed report for the available voter and combine them
  // so as not to lose the tags and custom fields that can be added to the blank report on the admin side
  const prepareFieldsForAvailableVoters = useCallback(async () => {
    try {
      if (!campaign || !voter) return;

      const body = {
        activity_id: campaign.report_activity_id,
        first_name: voter['vb.tsmart_first_name'],
        last_name: voter['vb.tsmart_last_name'],
        selected_voterbase_id: voter['voterbase_id'],
      };

      const blankReport = await getBlankReport();

      const report = await findOrCreateReport(body);

      const mergedCustomization = formatCustomFields(
        mergeBlankDataWithInitialData(blankReport.customizations, report.customizations),
      );

      const mergedTaggigns = formatTags(
        mergeBlankDataWithInitialData(blankReport.taggings, report.taggings),
      );

      setTags(mergedTaggigns);
      setCustomFields(mergedCustomization);
    } catch (e) {
      message.error('There was a problem getting report data');
      setCustomFields([]);
      setTags([]);
    }
  }, [campaign, findOrCreateReport, getBlankReport, voter]);

  useEffect(() => {
    getAddManuallyData();
  }, [getAddManuallyData]);

  useEffect(() => {
    prepareFieldsForAvailableVoters();
  }, [prepareFieldsForAvailableVoters]);

  // Format and set tags and custom fields to make them easier to display
  const setReportFields = data => {
    const { taggings, customizations } = data;

    const formattedTags = formatTags(taggings);
    const formattedCustomFields = formatCustomFields(customizations);

    setTags(formattedTags);
    setCustomFields(formattedCustomFields);
  };

  const getUserActivityId = async () => {
    try {
      const response = await user.lookupUserActivity(campaign.slug, campaign.report_activity_id);

      return response.data.id;
    } catch (e) {
      throw Error(e);
    }
  };

  const getUserActivity = async userActivityId => {
    try {
      const response = await user.getUserActivity(userActivityId);

      return response.data.userActivity;
    } catch (e) {
      throw Error(e);
    }
  };

  const updateReport = async (reportId, updatedReport) => {
    try {
      await userReportApi.update({ campaign_id: campaign.id, report_id: reportId }, updatedReport);
    } catch (e) {
      throw Error(e);
    }
  };

  const trackEvent = async userActivityId => {
    try {
      const userActivity = await getUserActivity(userActivityId);

      const activity = userActivity?.activity;
      const activityCampaign = activity?.campaign;

      track('COMPLETED_ACTIVITY', {
        activity_id: campaign.report_activity_id,
        activity_title: activity?.title,
        campaign_id: activityCampaign?.id,
        campaign_name: activityCampaign?.name,
      });
    } catch (e) {
      sendError('Track Add Report error', e);
      // RK: Since it only tracks the event, I don't think we need to cause an error and cancel the report submissions
    }
  };

  const sendMessageAndUpdateActivity = async (reportId, userActivityId, updatedReport) => {
    try {
      await users.sendCanvasserMessagePublic(
        {
          activity_id: campaign.report_activity_id,
          campaign_id: campaign.id,
          contact_id: null,
          report_id: reportId,
          user_activity_id: userActivityId,
        },
        updatedReport,
      );

      await user.updateUserActivity(userActivityId, { completed: true });
      await user.userActivityAddPerform(userActivityId);
    } catch (e) {
      throw Error(e);
    }
  };

  const submitReport = async (formValues, { setSubmitting, resetForm, setFormikState }) => {
    try {
      const { tags: formTags, customFields: formCustomFields, ...voterData } = formValues;

      const body = {
        activity_id: campaign.report_activity_id,
        email: voterData.email,
        first_name: voterData.firstName,
        last_name: voterData.lastName,
        phone: voterData.phone,
      };

      // We do not have a voter ID if we add a report manually, but we may have an address
      if (isAddManually) {
        body.address = voterData.address;
      } else {
        body.selected_voterbase_id = voterData.voterId;
      }

      const report = await findOrCreateReport(body);

      const updatedReport = {
        ...body,
        ...updateReportData(report, formTags, formCustomFields),
      };

      await updateReport(report.id, updatedReport);

      const userActivityId = await getUserActivityId();

      await trackEvent(userActivityId);

      await sendMessageAndUpdateActivity(report.id, userActivityId, updatedReport);
      // We can update report right after submit it, so we have to reset form to update all properties to default
      // but we still need to know that we submitted it earlier, so I set submitCount to 1
      setSubmitting(false);
      resetForm(formValues);
      setFormikState({ submitCount: 1 });
      message.success(t('idvoters.voter.outreach_report_sent'));
    } catch (e) {
      sendError('Send a manually added report error', e);
      setSubmitting(false);
      setFormikState({ submitCount: 0 });
      message.error(submitErrorMessage);
    }
  };

  return { customFields, submitReport, tags };
};

export default useSendReport;
