import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { kFormatter } from '../../utils/string';

const Wrapper = styled.div`
  margin: 12px 0;
`;

const MetricValue = styled.div`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${({ large }) => (large ? '24px' : '18px')};
  color: ${({ theme }) => theme.colors.black};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  line-height: 18px;
`;

const MetricLabel = styled.div`
  font-family: ${({ theme, large }) => (large ? theme.fonts.semibold : theme.fonts.regular)};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black};
  line-height: ${({ large }) => (large ? '14px' : '12px')};
  text-transform: ${({ large }) => (large ? 'uppercase' : 'regular')};
  padding-top: ${({ large }) => (large ? '8px' : '4px')};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  width: ${({ large }) => (large ? '82px' : '40px')};

  ${({ center }) => center && 'margin: auto'};
`;

const formatValue = (value, ordinal) => {
  if (!parseInt(value, 10)) return '0';
  if (!ordinal) return kFormatter(value);
  return moment.localeData().ordinal(value);
};

const Metric = ({ label, value, large, center, ordinal, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <MetricValue center={center} large={large}>
        {formatValue(value, ordinal)}
      </MetricValue>
      <MetricLabel center={center} large={large}>
        {label}
      </MetricLabel>
    </Wrapper>
  );
};

Metric.propTypes = {
  center: PropTypes.bool,
  label: PropTypes.string.isRequired,
  large: PropTypes.bool,
  ordinal: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Metric.defaultProps = {
  center: false,
  large: false,
  ordinal: false,
};

export default React.memo(Metric);
