// Design: https://www.figma.com/file/NFYhKfKPHOeisKckQ686Vb/Headcount?node-id=1218%3A1667
import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import DotLoader from '@web/components/DotLoader';
import { variants as textVariants } from '../Text';

const variants = {
  colored: css`
    box-shadow: 0px 0px 24px 4px rgba(160, 160, 160, 0.42);
    color: ${({ theme }) => theme.colors.black};
    font-weight: 600;
    line-height: 150%;
    border: none;
    height: 56px;
  `,
  outlined: css`
    box-shadow: 0px 0px 24px 4px rgba(160, 160, 160, 0.42);
    color: ${({ theme }) => theme.colors.black};
    font-weight: 600;
    line-height: 150%;
    border: 1px solid black;
  `,
  primary: css`
    background-color: ${({ theme }) => theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.bgGray};
  `,
  secondary: css`
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
  `,
  solid: css`
    box-shadow: 0px 0px 24px 4px rgba(160, 160, 160, 0.42);
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    line-height: 150%;
    border: 1px solid black;
  `,
  tertiary: css`
    background-color: transparent;
    border: 1px solid transparent;
    color: ${({ theme }) => theme.colors.black};
  `,
};

const bgColors = {
  black: css`
    background: ${({ theme }) => theme.colors.black};
  `,
  green: css`
    background: ${({ theme }) => theme.colors.green};
  `,
  purple: css`
    background: ${({ theme }) => theme.colors.purple};
  `,
  teal: css`
    background: ${({ theme }) => theme.colors.teal};
  `,
};

// Sizes are -2px from the designs to account for border
const sizes = {
  default: css`
    height: 42px;
  `,
  large: css`
    height: 54px;
  `,
};

const StyledButton = styled.button`
  align-items: center;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  padding: 0 24px;
  transition: opacity 200ms ease-out;
  :disabled {
    opacity: 0.5;
  }
  :hover {
    opacity: 0.7;
  }
  ${textVariants.f2}
  ${({ $size }) => $size}
  ${({ $variant }) => $variant}
  ${({ $bgColor }) => $bgColor}
`;

function Button({ children, bgColor, disabled, loading, size, variant, ...rest }) {
  const $bgColor = bgColors[bgColor];
  const $variant = variants[variant];
  const $size = sizes[size];
  return (
    <StyledButton
      $bgColor={$bgColor}
      disabled={disabled || loading}
      $size={$size}
      $variant={$variant}
      {...rest}
    >
      {loading ? <DotLoader /> : children}
    </StyledButton>
  );
}

Button.propTypes = {
  bgColor: PropTypes.oneOf(['black', 'green', 'teal', 'purple', 'white']),
  children: PropTypes.any,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'large']),
  variant: PropTypes.oneOf(['colored', 'outlined', 'primary', 'secondary', 'solid', 'tertiary']),
};

Button.defaultProps = {
  bgColor: '',
  children: null,
  disabled: false,
  loading: false,
  size: 'default',
  variant: 'primary',
};

export default Button;
