import { CheckOutlined, FlagFilled, UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import ImpactiveTypes from '@web/../types';
import { localizedDateTime, truncate } from '@web/utils/string';
import { getThreadTypeName } from '../utils';
import pxToRem from '@web/utils/pxToRem';

const StyledMessageThreadItem = styled.li`
  ${({ active, theme, left, unread }) => css`
    border: 1px solid #eeeeee;
    cursor: pointer;
    padding: ${left ? `28px 40px 28px ${active ? '54px' : '60px'}` : '28px 40px 28px 42px'};
    border-left: ${active ? `solid 7px ${theme.colors.blue}` : 'unset'};
    background: ${unread ? theme.colors.white : theme.colors.activityBackground};
    z-index: 1;
    position: relative;
    :hover {
      box-shadow: ${!active
        ? '2px 6px 14px rgb(0 16 72 / 5%)'
        : 'inset 12px 4px 25px -2px rgb(0 0 0 / 6%)'};
      z-index: 2;
    }
    box-shadow: ${active ? 'inset 12px 4px 25px -2px rgb(0 0 0 / 6%);' : 'none'};
    @media (max-width: 499px) {
      padding: 28px 40px 28px 25px;
    }
  `}
`;

const MessageTopSection = styled.div`
  display: flex;
  align-items: start;
`;

const MessageTypeSection = styled.div`
  margin-right: 10px;
  svg {
    width: 10px;
    height: 10px;
    ${({ theme, unread }) => `
      color: ${unread ? theme.colors.trueBlack : theme.colors.blue};
      opacity: ${unread ? 1 : 0.8};
    `}
  }
`;

const MessageInfoWrapper = styled.div`
  width: 100%;
`;

const MessageBottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ThreadInfo = styled.div`
  font-family: ${({ theme }) => theme.fonts.semibold};
  color: ${({ theme }) => theme.colors.blue};
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  > span {
    margin-left: 6px;
  }
`;

const UnreadDot = styled.div`
  background: ${({ theme }) => theme.colors.red};
  border-radius: 50px;
  margin-top: 11px;
  width: 8px;
  height: 8px;
`;

const FullNameAndLabel = styled.span`
  ${({ theme, unread }) => css`
    font-family: ${unread ? theme.fonts.semibold : theme.fonts.regular};
    color: ${unread ? theme.colors.trueBlack : theme.colors.blue};
    opacity: ${unread ? 1 : 0.8};
    font-size: 1.125rem;
    display: flex;
    align-items: end;
  `}
`;

const Timestamp = styled.span`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.gray};
  font-size: 0.875rem;
`;

const MessageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MessageContent = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.darksLateGray};
  font-size: ${pxToRem(14)};
  line-height: 26px;
  margin: 10px 0 26px;
`;

const Tag = styled.div`
  background: rgba(123, 218, 230, 0.44);
  padding: 14px 10px;
  color: rgba(39, 39, 39, 0.5);
  border-radius: 30px;
  height: 24px;
  display: flex;
  font-size: 0.625rem;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 8px;
`;

const MessageThreadPreviewContent = props => {
  const {
    unread,
    constructThreadLink,
    name,
    relationship,
    countOfMessages,
    isActive,
    isReportFilled,
    messageThread: { latest_message_body, needs_attention, latest_message_at, kind, team },
  } = props;
  const { t } = useTranslation();
  const isTeamChat = kind === 'team';

  const renderChatName = () => {
    const chatName = isTeamChat ? team?.name : name;

    return truncate(chatName, 30);
  };

  return (
    <StyledMessageThreadItem
      active={isActive}
      left={!unread && !needs_attention}
      onClick={constructThreadLink}
      onKeyDown={e => e.key === 'Enter' && constructThreadLink()}
      unread={unread}
      tabIndex={0}
    >
      <MessageTopSection>
        {(unread || needs_attention) && (
          <MessageTypeSection unread={unread}>
            {unread ? <UnreadDot /> : needs_attention ? <FlagFilled size={8} /> : null}
          </MessageTypeSection>
        )}
        <MessageInfoWrapper>
          <MessageInfo>
            <FullNameAndLabel unread={unread}>
              {renderChatName()} {countOfMessages}
              {relationship && <Tag>{relationship}</Tag>}
            </FullNameAndLabel>
            <Timestamp>{latest_message_at && localizedDateTime(latest_message_at)}</Timestamp>
          </MessageInfo>
          <MessageContent>
            {latest_message_body && truncate(latest_message_body, 130)}
          </MessageContent>
        </MessageInfoWrapper>
      </MessageTopSection>
      <MessageBottomSection>
        <span>{getThreadTypeName(kind, t)}</span>
        {isReportFilled && (
          <ThreadInfo>
            {t('inbox.thread.report_filled')} <CheckOutlined />
          </ThreadInfo>
        )}
        {isTeamChat && (
          <ThreadInfo>
            {t('inbox.thread.team')} <UserOutlined />
          </ThreadInfo>
        )}
      </MessageBottomSection>
    </StyledMessageThreadItem>
  );
};

MessageThreadPreviewContent.propTypes = {
  constructThreadLink: PropTypes.func.isRequired,
  countOfMessages: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isReportFilled: PropTypes.bool.isRequired,
  messageThread: ImpactiveTypes.messageThread,
  name: PropTypes.string.isRequired,
  relationship: PropTypes.string,
  unread: PropTypes.bool.isRequired,
};

export default MessageThreadPreviewContent;
