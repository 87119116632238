import React from 'react';

const ArrowUp = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill="#000"
        d="M8.354 2.646a.5.5 0 0 0-.708 0L4.464 5.828a.5.5 0 1 0 .708.708L8 3.707l2.828 2.829a.5.5 0 1 0 .708-.708L8.354 2.646ZM8.5 12V3h-1v9h1Z"
      />
    </svg>
  );
};

ArrowUp.propTypes = {};

ArrowUp.defaultProps = {};

export default ArrowUp;
