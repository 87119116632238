import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from '@web/components/Headcount/components/Text';

import { useTranslation } from 'react-i18next';

const StyledVoterItem = styled.li`
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 0.75rem;
  display: flex;
  height: 6rem;
  margin-top: ${({ theme }) => theme.spacing.wide};
  padding: ${({ theme }) => theme.spacing.normal};
  cursor: pointer;
`;

const SelectButton = styled.div`
  align-self: center;
  color: ${({ theme }) => theme.colors.blue};
  padding-right: 0;
  font-weight: 900;
  cursor: pointer;
`;

const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const Name = styled(Text)`
  text-transform: capitalize;
`;

const Subtitle = styled(Text).attrs({ variant: 'subtitle' })`
  line-height: 1.2;
`;

const Bottom = styled.div``;

function VoterItem({ onSelect, ...voter }) {
  const { t } = useTranslation();
  const { addressLine2, ageRange, name } = voter;
  return (
    <StyledVoterItem onClick={() => onSelect(voter)}>
      <Left>
        <Name>{name}</Name>
        <Bottom>
          <Subtitle>{addressLine2}</Subtitle>
          {ageRange && (
            <Subtitle>
              {t('check_registration.headcount.ageRange')}
              {ageRange}
            </Subtitle>
          )}
        </Bottom>
      </Left>
      <SelectButton variant="tertiary">{t('add_report.buttons.select')}</SelectButton>
    </StyledVoterItem>
  );
}

VoterItem.propTypes = {
  addressLine2: PropTypes.string.isRequired,
  ageRange: PropTypes.string,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

VoterItem.defaultProps = {
  ageRange: null,
};

export default VoterItem;
