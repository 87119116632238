import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { track } from '@web/services/analytics';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import Link from '../Link';
import Clipboard from 'react-clipboard.js';
import IconWrapper from './IconWrapper';
import CopyIcon from './CopyIcon';
import { message } from 'antd';
import ShareIcon from './ShareIcon';
import Text, { variants as textVariants } from '../Text';

import sendError from '@web/utils/sendError';
import { useTranslation } from 'react-i18next';

import useIsPartner from '../../hooks/useIsPartner';

// This does not share the same input styles as Input
const LinkInput = styled.input`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex: 1;
  height: 40px;
  outline: 0;
  padding: ${({ theme }) => theme.spacing.normal};
  ${textVariants.normal}
`;

const ShareSection = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ $isHeadcount, theme }) => ($isHeadcount ? theme.spacing.wide : 0)};
  margin-top: ${({ $isHeadcount, theme }) => (!$isHeadcount ? theme.spacing.wide : 0)};
`;

const StyledShareLink = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled.div`
  margin-top: ${({ theme }) => theme.spacing.tight};
  font-size: 0.75rem; // 12px;
  text-align: center;
`;

function ShareLink({ activity, referralLink, team }) {
  const { t } = useTranslation();
  const isPartner = useIsPartner(team);
  const { search } = useLocation();
  // share button will only be present for mobile devices
  const showShareButton = navigator?.share;
  const queryParams = new URLSearchParams(search);
  const activeLanguage = queryParams.get('language');
  referralLink = referralLink + (activeLanguage ? `?language=${activeLanguage}` : '');

  const headcountMtvEnabled = React.useMemo(
    () => activity?.campaign?.headcount_mtv_enabled,
    [activity?.campaign?.headcount_mtv_enabled],
  );

  const handleShare = React.useCallback(() => {
    const shareData = {
      text: isPartner ? team?.action_page_description : activity.description,
      title: isPartner ? team?.action_page_title : activity.title,
      url: referralLink,
    };
    if (navigator?.canShare && navigator.canShare(shareData) && navigator.share) {
      navigator.share(shareData).catch(err => {
        if (err.toString().includes('AbortError')) {
          // MTS - The share api throws this error when a user decides not to share.
          // It isn't a real error.
          return null;
        }

        sendError('Voter Registration: User encountered error while sharing', { err });
      });
    }
  }, [
    activity.description,
    activity.title,
    isPartner,
    referralLink,
    team?.action_page_description,
    team?.action_page_title,
  ]);

  const renderShare = React.useMemo(() => {
    return (
      <ShareSection $isHeadcount={headcountMtvEnabled}>
        <LinkInput readOnly value={referralLink} />

        <Clipboard
          component="span"
          data-clipboard-text={referralLink}
          onSuccess={() => {
            message.info(t('common.copied_to_clipboard'));
            track('HEADCOUNT_COPY_SHARE_LINK', {
              activity_id: activity?.id,
              activity_title: activity?.title,
              activity_type: activity?.type,
              campaign_id: activity?.campaign?.id,
              campaign_name: activity?.campaign?.name,
              team_id: team?.id,
              team_name: team?.name,
            });
          }}
        >
          <IconWrapper aria-label="Copy link" role="button">
            <CopyIcon />
          </IconWrapper>
        </Clipboard>

        {showShareButton && (
          <>
            {/* This was a "button" but Safari did not render the svg correctly */}
            <IconWrapper aria-label="Share" onClick={handleShare} role="button">
              <ShareIcon />
            </IconWrapper>
          </>
        )}
      </ShareSection>
    );
  }, [
    activity?.campaign?.id,
    activity?.campaign?.name,
    activity?.id,
    activity?.title,
    activity?.type,
    handleShare,
    headcountMtvEnabled,
    referralLink,
    showShareButton,
    t,
    team?.id,
    team?.name,
  ]);

  if (!referralLink) {
    console.error('Error <ShareLink /> : must pass referralLink as a prop');
    return null;
  }

  const antdOverrides = {
    lineHeight: 1,
    margin: 0,
    textAlign: headcountMtvEnabled ? 'center' : 'unset',
    whiteSpace: headcountMtvEnabled ? 'break-spaces' : 'unset',
  };
  return (
    <StyledShareLink style={{ marginBottom: headcountMtvEnabled ? 16 : 0 }}>
      {headcountMtvEnabled && renderShare}
      {!headcountMtvEnabled ? (
        <Text color="black" style={antdOverrides} variant="f2">
          {t('check_registration.headcount.shareLink')}
        </Text>
      ) : (
        <>
          <Text
            color="black"
            dangerouslySetInnerHTML={{
              __html: t('check_registration.headcount.mtvShareLink.share'),
            }}
            style={{ ...antdOverrides, fontFamily: 'BasicSans-Regular' }}
            variant="f3"
          ></Text>
          <br />
          <Text color="red" style={antdOverrides} variant="f3">
            {t('check_registration.headcount.mtvShareLink.share_red')}
          </Text>
        </>
      )}
      {!headcountMtvEnabled && renderShare}
      {headcountMtvEnabled && (
        <>
          {!headcountMtvEnabled && (
            <Text variant="small" color="black" style={{ marginTop: 10, textAlign: 'center' }}>
              {t('check_registration.headcount.mtvShareLink.warning')}
            </Text>
          )}
          <StyledLink>
            <Link
              href={team?.terms_link || activity?.terms_link || activity?.campaign?.terms_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('check_registration.headcount.termsAndConditions')}
            </Link>
          </StyledLink>
        </>
      )}
    </StyledShareLink>
  );
}

ShareLink.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      headcount_mtv_enabled: PropTypes.bool,
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string.isRequired,
      terms_link: PropTypes.string,
    }).isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.number,
    slug: PropTypes.string.isRequired,
    terms_link: PropTypes.string,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
  referralLink: PropTypes.string.isRequired,
  team: PropTypes.object,
};

ShareLink.defaultProps = {};

export default ShareLink;
