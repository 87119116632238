import React, { Fragment } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { media } from '@web/styles/theme';
import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import { Title } from '@web/components/VoterRegistration/newComponents/TextStyles';
import { useTrackFanOut } from '@web/components/FanOut/hooks/useFanOutAmplitude';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.whiteSmoke};
  display: flex;
  flex: 1;
`;

const StyledTerms = styled.div`
  display: flex;
  padding: 40px 22% 80px 22%;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  ${media.nxl} {
    padding: 40px 11% 80px 11%;
  }
  ${media.nlg} {
    padding: 40px 40px 80px 40px;
  }
  ${smallBreakpoint} {
    padding: 20px 20px 40px 20px;
    gap: 20px;
  }
`;

const StyledTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.5rem;

  ${smallBreakpoint} {
    font-size: 2rem;
    line-height: 2.25rem; /* 112.5% */
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;

  ${smallBreakpoint} {
    gap: 20px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const TitleText = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.5rem; /* 175% */
`;

const DescriptionText = styled.div`
  color: ${({ theme }) => theme.colors.trueBlack};
  font-family: ${({ theme }) => theme.fonts.regular};
  text-align: justify;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  white-space: pre-line;

  margin-top: ${({ $collapsed }) => $collapsed && '8px'};

  middle {
    text-align: center;
  }
`;

const { Panel } = Collapse;

const CollapseAntdStyles = createGlobalStyle`
  &&& {
    .ant-collapse-content {
      background: ${({ theme }) => theme.colors.transparent}!important;
      border-top: 0px !important;
      padding: 0px !important;
    }
    .ant-collapse-header {
      padding: 0px !important;
    }
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  &&& {
    margin: 0;
    overflow: hidden;
    width: 100%;
  }
`;

const StyledPanel = styled(Panel)`
  padding: 0;
  border: none;
`;

const Separator = styled.div`
  margin-top: 32px;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blackL42};

  ${smallBreakpoint} {
    margin-top: 18px;
  }

  margin-top: ${({ $collapsed }) => $collapsed && '0px'};
`;

const Terms = ({ activity, team }) => {
  const { t } = useTranslation();
  useTrackFanOut({ activity, name: 'FAN_OUT_ABOUT_PAGE', team });

  return (
    <Wrapper>
      <StyledTerms>
        <StyledTitle>{t('fan_out.terms.title')}</StyledTitle>
        <Container>
          <CollapseAntdStyles />
          {[...new Array(5)].map((_, index) =>
            // add collapse for Contest Rules & Privacy Policy sections
            index === 3 || index === 4 ? (
              <Fragment key={index}>
                <StyledCollapse expandIconPosition={'right'} ghost>
                  <StyledPanel
                    header={<TitleText>{t(`fan_out.terms.${index + 1}.title`)}</TitleText>}
                    key={index}
                  >
                    <DescriptionText
                      $collapsed
                      dangerouslySetInnerHTML={{
                        __html: t(`fan_out.terms.${index + 1}.description`),
                      }}
                    />
                  </StyledPanel>
                </StyledCollapse>
                {/* hide separator for last section */}
                {index !== 4 && <Separator $collapsed />}
              </Fragment>
            ) : (
              <Section key={index}>
                <TitleText>{t(`fan_out.terms.${index + 1}.title`)}</TitleText>
                <DescriptionText
                  dangerouslySetInnerHTML={{
                    __html: t(`fan_out.terms.${index + 1}.description`),
                  }}
                />
                <Separator />
              </Section>
            ),
          )}
        </Container>
      </StyledTerms>
    </Wrapper>
  );
};

Terms.propTypes = {
  activity: PropTypes.object.isRequired,
  team: PropTypes.object,
};

Terms.defaultProps = {};

export default Terms;
