import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CallStatus from './components/CallStatus';
import Header from '@web/components/DialerActivity/components/Header';
import MainContent from './components/MainContent';
import PageLayout from '../../components/PageLayout';
import ReceiveCall from './components/ReceiveCall';
import VolunteerDisconnected from './components/VolunteerDisconnected';

import useExitActivity from './useExitActivity';
import usePusher from './usePusher';
import { useSelector } from 'react-redux';

const StyledInCall = styled.div`
  display: grid;
  grid-template-columns: 1fr 430px;
  height: 100vh;
  overflow: hidden;
`;

const StyledPageLayout = styled(PageLayout)`
  height: 100vh;
  overflow: auto;
`;

function InCall({ activity, history }) {
  const audioRef = React.useRef(null);
  const { currentState } = useSelector(state => state.dialer);
  usePusher(activity, audioRef);
  useExitActivity(activity.id);

  // The volunteer has not yet received the call from Impactive
  if (currentState === 'dialingVolunteer') {
    return <ReceiveCall activity={activity} history={history} />;
  }

  if (currentState === 'volunteerDisconnected') {
    return <VolunteerDisconnected activity={activity} />;
  }

  return (
    <StyledInCall>
      {/* left hand side */}
      <StyledPageLayout>
        <Header activity={activity} backText="Exit Phone Bank" backTo={''} />
        <MainContent activity={activity} />
        <audio ref={audioRef} src="/files/notification.mp3" />
      </StyledPageLayout>
      {/* right hand side */}
      <CallStatus activity={activity} />
    </StyledInCall>
  );
}

InCall.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  history: PropTypes.any.isRequired,
};

InCall.defaultProps = {};

export default InCall;
