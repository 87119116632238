import * as React from 'react';
import PropTypes from 'prop-types';

import Header from '@web/components/DialerActivity/components/Header';
import SelectionItem from './SelectionItem';

import { useParams } from 'react-router-dom';

function QueueCallsSelection({ activity }) {
  const campaignName = activity?.campaign?.name;
  let { userActivityId } = useParams();
  const baseRoute = `/activities/${userActivityId}/phone-bank`;
  return (
    <>
      <Header activity={activity} backTo={`/activities/${userActivityId}`} />
      <SelectionItem
        buttonText={'Queue Calls'}
        description={'Tap a button to queue calls for our phone banking efforts.'}
        title={'I want to queue calls'}
        to={`${baseRoute}/queue-calls`}
      />
      <SelectionItem
        buttonText={'Phone Banking'}
        description={`Participate in the ${campaignName} phone banking session.`}
        title={'I want to phone bank'}
        to={`${baseRoute}/preview-script`}
      />
    </>
  );
}

QueueCallsSelection.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }),
};

QueueCallsSelection.defaultProps = {};

export default QueueCallsSelection;
