import { bool } from 'prop-types';
import React from 'react';

const Threads = ({ isMobile, ...props }) => {
  return isMobile ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path fill="#000" d="M0 0h40v40H0z" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M20.54 11.96c-2.587 0-4.457.79-5.875 2.815 0 0 1.683 1.168 2.188 1.505l.06-.068c.724-.84 1.347-1.56 3.625-1.56 2.555 0 3.564 2.046 3.564 3.24-.692-.14-2.017-.215-3.117-.215-2.938 0-6.657 1.42-6.657 5.34 0 2.745 2.617 4.84 5.65 4.84 5.204 0 6.39-3.892 6.832-6.204.117 0 2.383 1.29 2.383 3.987 0 3.962-3.883 6.667-9.09 6.667-6.33 0-10.556-4.125-10.556-11.892 0-8.662 3.78-12.732 10.468-12.732 4.043 0 8.455 1.02 10.56 7.42l2.55-.66C30.405 5.455 23.168 5 20.082 5 13.402 5 6.875 8.815 6.875 19.99c0 11.235 6.74 15.01 13.227 15.01 7.555 0 11.73-4.422 11.73-9.515 0-2.395-1.145-4.485-3.224-5.883a9.85 9.85 0 0 0-1.695-.907c-.146-2.128-.778-3.793-1.886-4.957-1.11-1.163-2.662-1.778-4.487-1.778Zm.803 8.395c-3.826 0-4.318 1.63-4.318 2.655 0 1.648 1.953 2.193 2.998 2.193 1.91 0 3.875-.53 4.184-4.545-.97-.218-1.692-.303-2.864-.303Z"
          clipRule="evenodd"
        />
      </g>
      <rect width={39} height={39} x={0.5} y={0.5} stroke="#fff" strokeOpacity={0.2} rx={5.5} />
      <defs>
        <clipPath id="a">
          <rect width={40} height={40} fill="#fff" rx={6} />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#fff"
        d="M17.692 11.123a8.398 8.398 0 0 0-.315-.142c-.185-3.414-2.05-5.368-5.182-5.388h-.042c-1.873 0-3.431.8-4.39 2.255l1.722 1.181c.716-1.087 1.84-1.318 2.669-1.318h.028c1.031.006 1.81.306 2.313.89.367.426.612 1.015.733 1.757a13.176 13.176 0 0 0-2.96-.143c-2.977.172-4.892 1.909-4.763 4.322.065 1.223.675 2.277 1.717 2.964.88.582 2.015.866 3.194.802 1.558-.085 2.78-.68 3.632-1.766.647-.825 1.056-1.894 1.237-3.241.742.448 1.292 1.037 1.596 1.745.516 1.205.546 3.184-1.068 4.797-1.415 1.414-3.116 2.025-5.686 2.044-2.851-.02-5.008-.935-6.41-2.717-1.313-1.67-1.991-4.08-2.016-7.165.025-3.085.703-5.496 2.016-7.165 1.402-1.782 3.558-2.696 6.41-2.717 2.871.02 5.065.94 6.521 2.73.714.879 1.252 1.983 1.607 3.27l2.018-.538c-.43-1.585-1.107-2.95-2.027-4.083C18.38 1.2 15.65.024 12.134 0h-.014C8.61.024 5.912 1.205 4.099 3.51c-1.614 2.05-2.446 4.904-2.474 8.482v.016c.028 3.578.86 6.431 2.474 8.482 1.813 2.305 4.511 3.486 8.02 3.51h.015c3.12-.022 5.319-.838 7.13-2.649 2.371-2.368 2.3-5.336 1.518-7.158-.56-1.307-1.629-2.368-3.09-3.07Zm-5.387 5.065c-1.305.074-2.66-.512-2.728-1.766-.05-.93.662-1.969 2.808-2.092.246-.015.487-.021.724-.021.78 0 1.508.075 2.171.22-.247 3.088-1.697 3.59-2.975 3.66Z"
      />
    </svg>
  );
};

Threads.propTypes = {
  isMobile: bool,
};

Threads.defaultProps = {};

export default Threads;
