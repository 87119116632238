import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledYoutubeEmbed = styled.div`
  text-align: right;
`;

const YoutubeContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

function YoutubeEmbed({ mediaUrl }) {
  const [youtubeId, setYoutubeId] = useState(null);

  useEffect(() => {
    setYoutubeId(mediaUrl);
  }, [mediaUrl]);

  return (
    <StyledYoutubeEmbed>
      {youtubeId && (
        <YoutubeContainer>
          <iframe
            src={`https://www.youtube.com/embed/${youtubeId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </YoutubeContainer>
      )}
    </StyledYoutubeEmbed>
  );
}

YoutubeEmbed.propTypes = {
  mediaUrl: PropTypes.string.isRequired,
};

YoutubeEmbed.defaultProps = {};

export default YoutubeEmbed;
