import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Radio } from 'antd';

const StyledRadioGroup = styled(Radio.Group)`
  display: flex !important;
  flex-direction: column;
  margin-bottom: 1rem !important;
`;

const Label = styled.p`
  margin-bottom: 0;
`;

const StyledRadio = styled(Radio)`
  margin: 0.25rem 0;
`;

function RadioGroup({ options, title, ...rest }) {
  return (
    <>
      <Label>{title}</Label>
      <StyledRadioGroup {...rest}>
        {options.map((el, i) => (
          <StyledRadio key={i} value={el}>
            {el}
          </StyledRadio>
        ))}
      </StyledRadioGroup>
    </>
  );
}

RadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

RadioGroup.defaultProps = {};

export default RadioGroup;
