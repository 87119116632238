import * as React from 'react';
// import PropTypes from 'prop-types';

function PartyIcon(props) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118 118" {...props}>
      <circle cx="59" cy="59" r="59" fill="#D5ECEF" />
      <path
        d="M62.2025 52.7976C57.707 48.3021 52.811 45.5211 49.8125 45.5001C48.932 45.4941 48.215 45.7251 47.72 46.2201C45.5375 48.4026 48.482 54.8871 54.2975 60.7026C60.113 66.5181 66.596 69.4626 68.78 67.2801C70.9625 65.0976 68.018 58.6131 62.2025 52.7976Z"
        fill="#4D9DA6"
      />
      <path
        d="M76.25 65L74.75 66.5L74 67.25L74.96 68.21L76.25 69.5L77.75 68L78.5 67.25L77.375 66.125L76.25 65Z"
        fill="#8CC4CA"
      />
      <path
        d="M47.75 81.5L46.25 83L45.5 83.75L46.553 84.803L47.75 86L49.0625 84.6875L50 83.75L49.2515 83L47.75 81.5Z"
        fill="#4D9DA6"
      />
      <path
        d="M43.25 32L41.711 33.539L41 34.25L41.633 34.883L43.25 36.5L44.402 35.348L45.5 34.25L44.2565 33.0065L43.25 32Z"
        fill="#8CC4CA"
      />
      <path
        d="M59.75 32L57.9185 33.8315L57.5 34.25L58.079 34.829L59.75 36.5L62 34.25L59.75 32Z"
        fill="#4D9DA6"
      />
      <path
        d="M62.75 41L61.289 42.461L60.5 43.25L61.52 44.27L62.75 45.5L64.061 44.189L65 43.25L63.9665 42.2165L62.75 41Z"
        fill="#4D9DA6"
      />
      <path
        d="M83.75 48.5L82.742 49.508L81.5 50.75L82.3445 51.5945L83.75 53L85.133 51.617L86 50.75L85.13 49.88L83.75 48.5Z"
        fill="#8CC4CA"
      />
      <path
        d="M34.25 59L32.7035 60.5465L32 61.25L32.8445 62.0945L34.25 63.5L35.7035 62.0465L36.5 61.25L35.681 60.431L34.25 59Z"
        fill="#4D9DA6"
      />
      <path
        d="M83 69.5L81.0785 71.4215L80 72.5L81.008 73.508L83 75.5L85.109 73.391L86 72.5L85.274 71.774L83 69.5Z"
        fill="#4D9DA6"
      />
      <path
        d="M59 75.5L56.9375 77.5625L56 78.5L56.7965 79.2965L59 81.5L61.0625 79.4375L62 78.5L61.2965 77.7965L59 75.5ZM53 53C53.129 52.8845 54.5 48.5 54.5 44C54.5 42.0965 53.984 40.6235 52.9655 39.62C51.3035 37.9835 48.761 38.009 48.719 38H48.5V35H48.6875C48.8 35.003 52.451 34.9295 55.0415 37.4555C56.6735 39.0455 57.5 41.2475 57.5 44C57.5 50 53 53 53 53ZM86 86H83V85.8125C82.9295 83.6555 82.187 81.5 81.6875 81.5C80.594 81.5 79.5185 82.817 78.6545 83.876C77.7245 85.016 76.922 86 75.6875 86C73.6205 86 73.1615 83.546 72.7175 81.1745C72.6425 80.774 72.5465 80.2595 72.437 79.7705C72.3605 79.9265 72.284 80.078 72.215 80.2205C70.8125 83.063 69.362 86 67.0625 86C65.2895 86 63.5 84.608 63.5 81.5H66.5C66.5 82.3835 66.731 83 67.0625 83C67.637 82.718 68.96 80.0375 69.5255 78.893C70.6055 76.7045 71.1995 75.5 72.5 75.5C74.708 75.5 75.194 78.104 75.6665 80.6225C75.7595 81.116 75.8855 81.797 76.0235 82.355C76.13 82.2275 76.235 82.0985 76.331 81.98C77.5295 80.5115 79.1705 78.5 81.6875 78.5C85.5815 78.5 85.9625 84.554 86 85.7675V86Z"
        fill="#8CC4CA"
      />
      <path
        d="M54.2975 60.7025C49.0655 55.4705 46.1705 49.709 47.2295 47.0135L47.2175 47L32 83L68 67.7825L67.9895 67.769C65.2955 68.8295 59.531 65.936 54.2975 60.7025Z"
        fill="#8CC4CA"
      />
      <path
        d="M84.5 32.0015C81.4745 32.051 79.373 32.7035 78.1505 33.917C77.456 34.6055 77.063 35.4755 77.0075 36.443C77.006 36.461 77 36.56 77 36.59C77.027 38.462 77.945 40.0835 78.2675 40.625C78.3275 40.727 78.4325 40.934 78.455 41.009C77.6495 40.9715 76.1945 40.673 75.2825 40.478C72.599 39.9065 70.286 39.4115 68.903 40.5275C68.321 41 68 41.699 68 42.5C68 44.2295 69.119 45.7535 70.0175 46.979C70.3895 47.486 70.952 48.251 71 48.5C71 49.2785 70.4555 49.571 67.577 50.351C64.343 51.227 59 53 56 57.5C60.5 54.5 65.153 54.116 68.36 53.2475C71.1335 52.4975 74 51.7205 74 48.5C74 47.3345 73.241 46.2995 72.4355 45.203C71.891 44.4605 71.234 43.565 71.0495 42.8525C71.876 42.8285 73.574 43.181 74.6555 43.412C76.1255 43.7255 77.477 43.9925 78.56 43.997V44C79.844 44 80.7425 43.3385 81.1205 42.77L81.266 42.494C81.437 42.068 81.5 41.6495 81.5 41.2625C81.5 40.214 81.032 39.392 80.933 39.23C80.7185 38.9075 80.078 37.787 80.0165 36.683C80.015 36.659 80.015 36.5825 80.0165 36.569C80.0375 36.3635 80.1065 36.2 80.264 36.0455C80.5175 35.7935 81.611 34.979 85.25 35H86V32C86 32 84.7115 31.997 84.5 32.0015Z"
        fill="#8CC4CA"
      />
      <path
        d="M83 65H86C86 64.742 85.985 64.4915 85.958 64.25C85.3475 58.9445 78.263 57.5 74 57.5C65 57.5 60.5 62 60.5 62C60.5 62 65 60.5 74 60.5C74.09 60.5 83 60.623 83 65Z"
        fill="#8CC4CA"
      />
      <path
        d="M38.9375 45.5C35.4935 45.5 35.0375 44.969 35.0225 44.951C35.0225 44.951 35 44.8985 34.967 44.894C35.1425 44.603 36.161 44.1185 36.7085 43.859C37.8455 43.319 39.113 42.7145 39.4265 41.5625C39.4745 41.3885 39.5 41.201 39.5 41C39.5 40.8005 39.482 40.6145 39.449 40.4375C39.17 38.966 37.8095 38.255 36.7865 37.7195C35.4245 37.007 35 36.668 35 35.9375C35 35.0675 36.2585 35.003 36.512 35H36.5V32C34.691 32 32 33.0485 32 35.9375C32 38.6015 34.1255 39.713 35.396 40.3775C35.6285 40.5005 35.93 40.658 36.161 40.787C35.918 40.913 35.6405 41.045 35.4185 41.1515C33.977 41.837 32 42.7775 32 44.75C32 48.5 36.5135 48.5 38.9375 48.5C39.0395 48.5 39.131 48.5045 39.212 48.512C38.567 49.2095 36.836 50.4245 33.56 51.587L34.5635 54.4145C36.95 53.567 42.5 51.26 42.5 48.125C42.5 46.8605 41.3855 45.5 38.9375 45.5Z"
        fill="#8CC4CA"
      />
    </svg>
  );
}

PartyIcon.propTypes = {};

PartyIcon.defaultProps = {};

export default PartyIcon;
