import * as React from 'react';
import PropTypes from 'prop-types';

function CircleX(props) {
  return (
    <svg viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14 0.03125C6.26875 0.03125 0 6.3 0 14.0313C0 21.7625 6.26875 28.0313 14 28.0313C21.7313 28.0313 28 21.7625 28 14.0313C28 6.3 21.7313 0.03125 14 0.03125ZM14 25.6563C7.58125 25.6563 2.375 20.45 2.375 14.0313C2.375 7.6125 7.58125 2.40625 14 2.40625C20.4188 2.40625 25.625 7.6125 25.625 14.0313C25.625 20.45 20.4188 25.6563 14 25.6563Z"
        fill="#F5222D"
      />
      <path
        d="M14 25.6563C7.58125 25.6563 2.375 20.45 2.375 14.0313C2.375 7.6125 7.58125 2.40625 14 2.40625C20.4188 2.40625 25.625 7.6125 25.625 14.0313C25.625 20.45 20.4188 25.6563 14 25.6563Z"
        fill="#F5222D"
      />
      <path
        d="M19.4186 9.08789C19.4186 8.95039 19.3061 8.83789 19.1686 8.83789L17.1061 8.84727L13.9998 12.5504L10.8967 8.85039L8.83105 8.84102C8.69355 8.84102 8.58105 8.95039 8.58105 9.09102C8.58105 9.15039 8.60293 9.20664 8.64043 9.25352L12.7061 14.0973L8.64043 18.9379C8.60267 18.9837 8.58172 19.041 8.58105 19.1004C8.58105 19.2379 8.69355 19.3504 8.83105 19.3504L10.8967 19.341L13.9998 15.6379L17.1029 19.3379L19.1654 19.3473C19.3029 19.3473 19.4154 19.2379 19.4154 19.0973C19.4154 19.0379 19.3936 18.9816 19.3561 18.9348L15.2967 14.0941L19.3623 9.25039C19.3998 9.20664 19.4186 9.14727 19.4186 9.08789Z"
        fill="white"
      />
    </svg>
  );
}

CircleX.propTypes = {
  height: PropTypes.number,
};

CircleX.defaultProps = {
  height: 29,
};

export default CircleX;
