import { message } from 'antd';
import React from 'react';
import Clipboard from 'react-clipboard.js';
import { useTranslation } from 'react-i18next';
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from 'react-share';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ShareButtonInner = styled.div`
  align-items: center;
  border: 1px solid;
  border-color: white;
  border-radius: 4px;
  height: 52px;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
`;

const ShareButtonText = styled.div`
  padding-left: 1rem;
`;

const StyledFacebookShareButton = styled(FacebookShareButton)`
  margin-bottom: 1.5rem;
  width: 100%;
  cursor: pointer;
  display: flex;
`;

const StyledTwitterShareButton = styled(TwitterShareButton)`
  margin-bottom: 1.5rem;
  width: 100%;
  cursor: pointer;
`;

const StyledClipboard = styled(Clipboard)`
  margin-bottom: 1.5rem;
  width: 100%;
  cursor: pointer;
`;

export const FacebookShare = ({ url, children }) => (
  <StyledFacebookShareButton url={url}>
    <ShareButtonInner>
      <FacebookIcon round size="30" />
      <ShareButtonText>{children}</ShareButtonText>
    </ShareButtonInner>
  </StyledFacebookShareButton>
);

FacebookShare.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string.isRequired,
};

export const TwitterShare = ({ url, children }) => (
  <StyledTwitterShareButton url={url}>
    <ShareButtonInner>
      <TwitterIcon round size="30" />
      <ShareButtonText>{children}</ShareButtonText>
    </ShareButtonInner>
  </StyledTwitterShareButton>
);

TwitterShare.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string.isRequired,
};

export const ClipboardShare = ({ children, text }) => {
  const { t } = useTranslation();

  return (
    <StyledClipboard
      component="span"
      data-clipboard-text={text}
      onSuccess={() => message.info(t('common.copied_to_clipboard'))}
    >
      <ShareButtonInner>
        <i className="fal fa-external-link ml-1 text-dark" />
        <ShareButtonText>{children}</ShareButtonText>
      </ShareButtonInner>
    </StyledClipboard>
  );
};

ClipboardShare.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string.isRequired,
};
