import styled from 'styled-components';
import ImpactiveButton from '../ImpactiveButton';

export const Section = styled.div`
  font-size: 16px;
  line-height: 1.25;
  margin-top: 1.5em;
  padding-bottom: 2em;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
    margin-bottom: 2em;
  }
`;

export const Heading = styled.h4`
  color: #000;
  font-size: 1.25em;
  font-family: ${({ theme }) => theme.fonts.semibold};
  line-height: 1.3;
  text-transform: capitalize;
  margin: 0;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 22px;
  opacity: 0.85;
  margin: 8px 0 12px;
`;

export const DefinitionList = styled.div`
  > * {
    margin-top: 1em;
  }
`;

export const Link = styled.a`
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.boldItalic};
`;

export const BoldText = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
  ${({ danger, theme }) => danger && `color: ${theme.colors.red};`}
`;

export const TeamSection = styled(Section)`
  font-size: 18px;
`;

export const TeamButtonsContainer = styled.div`
  margin-top: 20px;
`;

export const TeamInviteLink = styled.div`
  font-size: 14px;
  opacity: 0.6;
  margin: 10px 0 16px;
`;

export const ButtonsRow = styled.div`
  margin-top: 0.5rem;
  margin-bottom: -10px;

  > * {
    margin-bottom: 10px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  ${({ fullWithOnMobile }) =>
    fullWithOnMobile &&
    `
    @media screen and (max-width: 767px) {
      > *, button {
        display: block;
        width: 100%;
      }
    }
  `}
`;

export const ShareButtonInner = styled(ImpactiveButton).attrs({ forwardedAs: 'span' })`
  display: flex;
  align-items: center;
`;
