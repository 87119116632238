import * as React from 'react';
import PropTypes from 'prop-types';
import { track } from '@web/services/analytics';
import styled from 'styled-components';

import Button from '@web/components/Headcount/components/Button';
import ListItem from './ListItem';
import Text from '@web/components/Headcount/components/Text';

import { useDispatch, useSelector } from 'react-redux';
import { setContactFormResult } from '@web/reducers/voterRegistration';
import useVoterRegistrationApi from '@web/components/Headcount/hooks/useVoterRegistrationApi';

import sendError from '@web/utils/sendError';
import { useTranslation } from 'react-i18next';
import { automaticVBMStates, excuseVBMStates } from '../helpers';

const Address = styled.div`
  margin: ${({ theme }) => theme.spacing.wide} 0;
  p {
    font-family: ${({ theme }) => theme.fonts.semibold};
    line-height: 1.4;
  }
`;

const List = styled.ul`
  &&& {
    padding-left: ${({ theme }) => theme.spacing.wide};
  }
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const ButtonArea = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing.normal} 0px;
  grid-template-rows: 1fr 1fr 1fr;
`;

function RegisteredLocation({
  activity,
  team,
  nextPage,
  updateAddress,
  voteByMail,
  voteFromAddress,
}) {
  const dispatch = useDispatch();
  const { contactFormResult, selectedVoter } = useSelector(state => state.voterRegistration);
  const { t } = useTranslation();
  const { submitContactUpdate } = useVoterRegistrationApi(activity);

  track('HEADCOUNT_VIEW_REGISTERED_LOCATION', {
    activity_id: activity?.id,
    activity_title: activity?.title,
    activity_type: activity?.type,
    campaign_id: activity?.campaign?.id,
    campaign_name: activity?.campaign?.name,
    team_id: team?.id,
    team_name: team?.name,
  });

  const trackClick = async event_name => {
    track(event_name, {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      automatic_vbm: contactFormResult?.automatic_vbm,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      can_register_online: contactFormResult?.can_register_online,
      can_vbm: contactFormResult?.can_vbm,
      can_vbm_online: contactFormResult?.can_vbm_online,
      contact_id: contactFormResult?.id,
      has_state_identification: contactFormResult?.has_state_identification,
      registration_status: contactFormResult?.registration_status,
      state_abbrev: contactFormResult?.state_abbrev,
      team_id: team?.id,
      team_name: team?.name,
    });
  };

  function handleVoteFromAddress() {
    trackClick('HEADCOUNT_VOTE_FROM_ADDRESS');
    // Update address in backend
    submitContactUpdate(contactFormResult, {
      address: selectedVoter?.addressLine1,
      city: selectedVoter?.city,
      stateAbbrev: selectedVoter?.state,
      zipCode: selectedVoter?.zip,
    })
      .then(({ data }) => {
        dispatch(setContactFormResult({ activity, contact: data }));
      })
      .catch(err => {
        sendError('HC Voter Registration: User encountered error while updating their address', {
          err,
        });
      })
      .finally(() => {
        voteFromAddress();
        nextPage();
      });
  }

  function handleUpdateAddress() {
    trackClick('HEADCOUNT_UPDATE_ADDRESS');
    updateAddress();
    nextPage();
  }

  function handleVoteByMail() {
    trackClick('HEADCOUNT_VOTE_BY_MAIL');
    voteByMail();
    nextPage();
  }

  const isExcuseVBMState = React.useMemo(
    () => excuseVBMStates.includes(contactFormResult?.state_abbrev),
    [contactFormResult?.state_abbrev],
  );

  const isAutomaticVBMState = React.useMemo(
    () => automaticVBMStates.includes(contactFormResult?.state_abbrev),
    [contactFormResult?.state_abbrev],
  );

  const vbmListItem = React.useCallback(
    specificString => (
      <ListItem>
        {t(`check_registration.headcount.registeredLocation.${specificString ?? 'listItem3'}`)}
      </ListItem>
    ),
    [t],
  );

  const showVBMAsMain = React.useMemo(
    () => contactFormResult?.can_vbm && !isExcuseVBMState && !isAutomaticVBMState,
    [contactFormResult?.can_vbm, isExcuseVBMState, isAutomaticVBMState],
  );

  return (
    <>
      <Text variant="f2">{t('check_registration.headcount.registeredLocation.title')}</Text>
      <Address>
        <Text>{selectedVoter?.addressLine1}</Text>
        <Text>
          {selectedVoter?.addressLine2} {selectedVoter?.zip}
        </Text>
      </Address>
      <Text
        dangerouslySetInnerHTML={{
          __html: t('check_registration.headcount.registeredLocation.listTitle', {
            state: t(`states.${contactFormResult?.state_abbrev}`),
          }),
        }}
      />

      <List>
        {showVBMAsMain && vbmListItem()}
        {isAutomaticVBMState && vbmListItem('automaticVbmItem')}
        <ListItem>{t('check_registration.headcount.registeredLocation.listItem1')}</ListItem>

        <ListItem shouldDangerouslySet={isAutomaticVBMState}>
          {t(
            `check_registration.headcount.registeredLocation.${
              isAutomaticVBMState ? 'automaticVbmRequestBallotItem' : 'listItem2'
            }`,
          )}
        </ListItem>

        {isExcuseVBMState && vbmListItem('excuseVbmItem')}
      </List>
      <ButtonArea>
        {showVBMAsMain && (
          <Button variant="primary" onClick={handleVoteByMail}>
            {t('check_registration.headcount.registeredLocation.button3')}
          </Button>
        )}
        <Button variant={showVBMAsMain ? 'secondary' : 'primary'} onClick={handleVoteFromAddress}>
          {t(
            `check_registration.headcount.registeredLocation.${
              isAutomaticVBMState ? 'automaticVbmButton' : 'button1'
            }`,
          )}
        </Button>
        <Button variant="secondary" onClick={handleUpdateAddress}>
          {t('check_registration.headcount.registeredLocation.button2')}
        </Button>
        {isExcuseVBMState && (
          <Button variant="secondary" onClick={handleVoteByMail}>
            {t('check_registration.headcount.registeredLocation.button3')}
          </Button>
        )}
      </ButtonArea>
    </>
  );
}

RegisteredLocation.propTypes = {
  activity: PropTypes.object.isRequired,
  nextPage: PropTypes.func.isRequired,
  team: PropTypes.object,
  updateAddress: PropTypes.func.isRequired,
  voteByMail: PropTypes.func.isRequired,
  voteFromAddress: PropTypes.func.isRequired,
};

RegisteredLocation.defaultProps = {};

export default RegisteredLocation;
