import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import StatusBox from '@web/components/FanOut/components/StatusBox';
import EntriesBoxes from '@web/components/FanOut/components/EntriesBoxes';
import Recruits from '@web/components/FanOut/components/Recruits';

import { useMyStats, useGetVotingPlanReferralLink } from '@web/components/FanOut/hooks/useFanOut';
import { useTrackFanOut } from '@web/components/FanOut/hooks/useFanOutAmplitude';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.whiteSmoke};
  width: 100%;
  display: flex;
  flex: 1;
  padding: 40px 48px;
  flex-direction: column;

  ${smallBreakpoint} {
    padding: 20px;
  }
`;

const MyActivity = ({ activity, openFriendSearchModal, team, openVoterModal }) => {
  const history = useHistory();
  const { search } = useLocation();
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const localContact = JSON.parse(localStorage.getItem('fan_out_contact'));
  const [cookies] = useCookies();

  useTrackFanOut({ activity, name: 'FAN_OUT_MY_ACTIVITY', team });

  const fanOutCookies = useMemo(() => cookies?.fan_out_auth, [cookies?.fan_out_auth]);

  const phone = useMemo(
    () => contactFormResult?.phone || localContact?.phone,
    [contactFormResult?.phone, localContact?.phone],
  );

  const { myStats, statsLoading } = useMyStats({
    campaign_id: activity.campaign.id,
    contactFormResult,
  });

  const { votingPlanReferralLink, votingPlanReferralLinkLoading } = useGetVotingPlanReferralLink({
    campaignId: activity.campaign.id,
    phone,
  });

  useEffect(() => {
    // return to main page if user tries to access my activity without phone & email verification
    if (!phone || !fanOutCookies) {
      history.replace({ search, state: '' });
    }
  }, [phone, search, history, fanOutCookies]);

  return (
    <Wrapper>
      <StatusBox
        myStats={myStats}
        myStatsIsLoading={statsLoading}
        activity={activity}
        team={team}
        openVoterModal={openVoterModal}
      />
      <EntriesBoxes
        openFriendSearchModal={openFriendSearchModal}
        myStats={myStats}
        myStatsIsLoading={statsLoading}
        votingPlanReferralLink={votingPlanReferralLink}
        votingPlanReferralLinkLoading={votingPlanReferralLinkLoading}
      />
      {!!phone && !!fanOutCookies && (
        <Recruits
          activity={activity}
          phone={phone}
          team={team}
          votingPlanReferralLink={votingPlanReferralLink}
          votingPlanReferralLinkLoading={votingPlanReferralLinkLoading}
        />
      )}
    </Wrapper>
  );
};

MyActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  openFriendSearchModal: PropTypes.func.isRequired,
  openVoterModal: PropTypes.func.isRequired,
  team: PropTypes.object,
};

MyActivity.defaultProps = {
  team: null,
};

export default MyActivity;
