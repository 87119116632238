import axios from 'axios';
import routes from './routes';

const { campaigns_and_topics: route } = routes;

const campaignsAndTopics = {
  getCampaignsTopics: async ({ page, query }, axiosHeaders) => {
    return axios.get(route, {
      params: { key: 'campaignsTopics', page, per_page: 20, query },
      ...axiosHeaders,
    });
  },
};

export default campaignsAndTopics;
