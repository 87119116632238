import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import styled from 'styled-components';

import CircleCheck from '@web/components/VoterRegistration/newComponents/icons/CircleCheck';
import Lock from '@web/components/VoterRegistration/newComponents/icons/Lock';
import Star from '@web/components/VoterRegistration/newComponents/icons/Star';
import { largeBreakpoint } from '../../../breakpoints';
import { DATE_FORMAT } from '../utils';

dayjs.extend(customParseFormat);

const colors = {
  black: '#222',
  gray: '#707070',
  green: '#52C41A',
  lighterGray: '#F5F6F7',
  lightGray: '#B7B7B7',
  red: '#F5222D',
};

const Link = styled.a`
  color: ${({ $color }) => $color ?? colors.gray};
  position: relative;
  ${largeBreakpoint} {
    margin-left: 8px;
  }
  :hover {
    text-decoration: none;
    &::before {
      color: ${({ $color }) => $color ?? colors.gray};
    }
  }

  &::before {
    content: '|';
    height: 8px;
    width: 1px;
    margin-right: 8px;
  }

  > span {
    text-decoration: underline;
    :hover {
      color: ${colors.gray};
    }
  }
`;

const Dot = styled.div`
  background-color: ${({ $color }) => $color};
  border-radius: 4px;
  height: 8px;
  width: 8px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 40px;
  position: relative;
  svg,
  ${Dot} {
    position: relative;
    z-index: 1;
  }
`;

const StyledTimelineItem = styled.li`
  display: grid;
  grid-gap: 0 11px;
  grid-template-columns: ${({ $twoColumns }) => ($twoColumns ? '20px 1fr' : '20px 80px 1fr')};
  min-height: 40px;
`;

const Line = styled.div`
  background-color: ${({ $color }) => `${$color}1A`};
  left: 50%;
  width: 2px;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  position: absolute;
  z-index: 0;
`;

const Text = styled.p`
  color: ${({ $color }) => $color};
  font-weight: ${({ $bold }) => ($bold ? 700 : 400)};
  margin-bottom: 0;
`;

const TextBlock = styled.div`
  align-items: flex-start;
  display: flex;
  padding-bottom: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${largeBreakpoint} {
    align-items: center;
    flex-direction: row;
  }
`;

const Date = styled.div`
  > p {
    white-space: nowrap;
  }
`;

function checkAfter(date) {
  return dayjs().isAfter(dayjs(date, DATE_FORMAT));
  // // MTS - Leaving this here for debugging purposes.
  // return dayjs('10-01-22', DATE_FORMAT).isAfter(dayjs(date, DATE_FORMAT));
}

const firstStyle = {
  top: 8,
};

const lastStyle = {
  bottom: '90%',
};

function TimelineItem({
  date,
  displayDate,
  first,
  icon,
  last,
  link,
  linkText,
  registered,
  text,
  fontColor,
  customIconColor,
}) {
  const todayIsAfter = checkAfter(date);
  const accentColor = registered ? colors.green : colors.red;
  const iconColor = customIconColor ?? (todayIsAfter ? colors.lightGray : accentColor);
  const textColor = fontColor ?? (todayIsAfter ? colors.gray : colors.black);
  const formattedDate = date ? dayjs(date, DATE_FORMAT).format("MMM D, 'YY") : null;

  function getIcon() {
    if (icon === 'star') {
      if (todayIsAfter) {
        return <CircleCheck fill={iconColor} height={13} style={{ marginTop: 5 }} />;
      }
      return <Star height={14} fill={iconColor} style={{ marginTop: 5 }} />;
    }
    // icon is a dot
    return todayIsAfter ? (
      <Lock height={14} style={{ marginTop: 5 }} />
    ) : (
      <Dot $color={iconColor} style={{ marginTop: 8 }} />
    );
  }

  function getLineStyle() {
    if (first) {
      return firstStyle;
    }
    if (last) {
      return lastStyle;
    }
    return {};
  }

  function getLink() {
    if (link && linkText && !todayIsAfter) {
      return (
        <>
          <Link $color={fontColor} target="_blank" href={link} rel="noopener noreferrer">
            <span>
              {linkText} {'>'}
            </span>
          </Link>
        </>
      );
    }
  }

  return (
    <StyledTimelineItem $twoColumns={!date}>
      <IconWrapper>
        {getIcon()}
        <Line $color={iconColor} style={getLineStyle()} />
      </IconWrapper>
      {date && (
        <Date>
          <Text $color={textColor} $bold>
            {displayDate || formattedDate}
          </Text>
        </Date>
      )}
      <TextBlock>
        <TextWrapper>
          <Text $bold={icon === 'star'} $color={textColor}>
            {text}
          </Text>
          {getLink()}
        </TextWrapper>
      </TextBlock>
    </StyledTimelineItem>
  );
}

TimelineItem.propTypes = {
  customIconColor: PropTypes.string,
  date: PropTypes.string,
  displayDate: PropTypes.string,
  first: PropTypes.bool.isRequired,
  fontColor: PropTypes.string,
  icon: PropTypes.string.isRequired,
  last: PropTypes.bool.isRequired,
  link: PropTypes.string,
  linkText: PropTypes.any,
  registered: PropTypes.bool.isRequired,
  text: PropTypes.any.isRequired,
};

TimelineItem.defaultProps = {
  displayDate: null,
  link: null,
  linkText: null,
};

export default TimelineItem;
