import axios from 'axios';
import routes from './routes';
const { teams: route } = routes;

const teams = {
  getTeam: async ({ id, filters }) => {
    return axios.get(`${route}/${id}`, {
      params: {
        ...filters,
      },
    });
  },

  getTeamBySlug: async ({ slug }) => {
    return axios.get(`${route}/${slug}`);
  },

  getTeamMembers: async ({ id, per_page = 30, page = 1, ...rest }, axiosHeaders) => {
    return axios.get(`${route}/${id}/members`, {
      params: {
        page,
        per_page,
        ...rest,
      },
      ...axiosHeaders,
    });
  },

  joinTeam: async ({ id }) => {
    return axios.post(`${route}/${id}/join`);
  },

  joinTeamByCode: async code => {
    return axios.post(`${route}/join_by_code`, {
      join_code: code,
    });
  },

  leaveTeam: async ({ id }) => {
    return axios.post(`${route}/${id}/leave`);
  },
};

export default teams;
