import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import logo from '../../images/app-logo-primary.png';
import { identify, track } from '../../services/analytics';
import { auth, campaigns, users } from '../../services/api';
import { AuthContext } from '../../utils/context';
import { getRedirectAfterLoginURL, getAndClearRedirectAfterLoginURL } from 'web/services/session';
import Loading from '../common/Loading';
import { setAppLanguage } from '../i18n';
import AuthMenu from './Components/AuthMenu';
import { replaceDefaultCampaignImage } from '@web/utils/string';

const AuthPage = ({ isSignup }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { campaign_slug } = useParams();

  const title = isSignup ? t('auth.sign_up') : t('auth.login');
  useDocumentTitle(title);

  const { setAuth } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    // Track the page view
    track('VIEW_AUTH_SCREEN');

    if (!isSignup) {
      // Only have campaign headers when signing up
      setLoading(false);
      return;
    }

    if (!campaign_slug) {
      setLoading(false);
      return;
    }

    campaigns
      .getPublicCampaign({ id: campaign_slug })
      .then(({ data: { data } }) => {
        setCampaign(data);
        setLoading(false);
      })
      .catch(e => {
        setCampaign(null);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogInWithGoogle = async (email, accessToken, googleId) => {
    return auth
      .signInWithGoogle(email, accessToken, googleId)
      .then(res => onGoogleAuthSuccess(res))
      .catch(unsetAuthHandler);
  };

  // In the case of google auth we need to perform additional actions
  // to join the campaign if user came from any of the campaign signup pages
  const onGoogleAuthSuccess = async data => {
    const { token, user } = data;
    setAuth(token);
    setAppLanguage(user.locale);
    identify(user.id);

    const canViewAdmin = user.is_campaign_admin || user.can_admin_accounts;
    // If the user can access admin and no redirectURL is set - go to admin
    const hasRedirectURL = !!getRedirectAfterLoginURL();
    if (canViewAdmin && !hasRedirectURL) {
      // hard reload (switch to) admin react app
      window.location.href = '/admin';
      return;
    }

    // Join the campaign if auth happened on campaign screen
    if (campaign_slug && campaign) {
      await users.followCampaign({
        campaign_id: campaign.id,
        campaign_name: campaign.name,
        campaign_slug: campaign.slug,
      });

      // redirect after login logic is temporarily handled here, but should be extracted out
      const redirectURL = getAndClearRedirectAfterLoginURL();
      if (redirectURL) history.push(redirectURL);
      return;
    }

    // Fallback throw the user to /frontline
    history.push('/frontline');
  };

  const unsetAuthHandler = ({ response }) => {
    // unset auth token
    setAuth(null);

    // display error message
    const { data: { error, message: errorMessage } = {} } = response;
    message.error(errorMessage || error || t('errors.action_failed'));
  };

  const onPasswordLoginClick = () => {
    if (!isSignup) {
      history.push('/auth/login/email');
      return;
    }

    if (campaign_slug) {
      history.push(`/auth/signup/${campaign_slug}/email`);
      return;
    }

    history.push(`/auth/signup/email`);
  };

  const onPasswordlessClick = () => {
    if (!isSignup) {
      history.push('/auth/login/passwordless');
      return;
    }

    if (campaign_slug) {
      history.push(`/auth/signup/${campaign_slug}/passwordless`);
      return;
    }

    history.push(`/auth/signup/passwordless`);
  };

  const onSignupOrLoginClick = () => {
    if (!isSignup) {
      // on login now, so nav to sign up
      history.push(`/auth/signup`);
      return;
    }

    history.push(`/auth/login`);
  };

  if (loading) {
    return (
      <div className="d-flex flex-row justify-content-center">
        <Loading />
      </div>
    );
  }

  const modalHeader = () => {
    if (campaign && campaign.name) {
      return 'Join the campaign';
    }

    if (isSignup) {
      return 'Sign up to join a campaign';
    }

    return title;
  };

  const isCampaignPage = campaign && campaign.name;
  const showCampaignImg = campaign && replaceDefaultCampaignImage(campaign.url_profile_img);

  return (
    <div
      className={`h-100 bg-auth-cyan d-flex flex-column align-items-center justify-content-center`}
    >
      <div className="d-flex flex-row justify-content-center my-4">
        <img src={logo} alt="site-logo" height="27" />
      </div>

      <div className="card fixed-auth">
        {!!showCampaignImg && (
          <div className="d-flex flex-row justify-content-center my-4">
            <img
              style={{ maxHeight: '80px' }}
              src={showCampaignImg}
              className="img-fluid"
              alt={campaign.name}
              height="80"
            />
          </div>
        )}

        <div className={`card-title bolder ${isCampaignPage ? '' : 'mb-4'}`}>{modalHeader()}</div>

        {!!isCampaignPage && <div className="text-muted text-center pb-4">{campaign.name}</div>}

        <AuthMenu
          onGoogleLogin={onLogInWithGoogle}
          onEmailLogIn={onPasswordLoginClick}
          onPasswordlessLogin={onPasswordlessClick}
          passwordlessButtonText={
            isSignup ? t('auth.sign_up_with_email') : t('auth.sign_in_with_email')
          }
          includePasswordOption={!isSignup}
        />

        <div className="d-flex flex-row align-items-center justify-content-center mt-3 text-center">
          <div className="pr-2">
            {isSignup ? t('auth.existing_account_question') : t('auth.no_account_question')}
          </div>
          <div className="text-underline pointer" onClick={onSignupOrLoginClick}>
            {isSignup ? t('auth.login') : t('auth.sign_up')}
          </div>
        </div>
      </div>
    </div>
  );
};

AuthPage.propTypes = {
  isSignup: PropTypes.bool,
};

export default AuthPage;
