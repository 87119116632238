// This is an adaptation of the modal in the admin app "ImportListModal"
import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import { Modal as AntModal } from 'antd';

const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0 60px;
  width: 100%;
`;

const ModalStyle = createGlobalStyle`
  &&& {
    .import-modal .ant-modal {
      max-width: 517px;
      width: 100%;
    }
    .import-modal  .ant-modal-body{
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0;
    }
    .import-modal  .ant-modal-content {
      border-radius: 6px;
      border-top-left-radius: 0px;
      display: flex;
      flex-direction: column;
      min-height: 558px;
      overflow: hidden;
      padding: 0px 0px 40px;
    }
  }
`;

function Modal({ children, modalOpen, progress, ...rest }) {
  return (
    <>
      <ModalStyle />
      <AntModal
        bodyStyle={{ padding: 0 }}
        closable={false}
        footer={null}
        onCancel={() => null}
        visible={modalOpen}
        wrapClassName="import-modal"
        {...rest}
      >
        {progress}
        <Content>{children}</Content>
      </AntModal>
    </>
  );
}

Modal.propTypes = {
  children: PropTypes.any,
  modalOpen: PropTypes.bool.isRequired,
  progress: PropTypes.any,
};

Modal.defaultProps = {};

export default React.memo(Modal);
