import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { truncate } from '@web/utils/string';

import styled from 'styled-components';
import { media } from '@web/styles/theme';

const StyledIncentiveCard = styled.div`
  width: 100%;
  max-width: 400px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px 50px;

  ${media.xl} {
    margin: 0;
    max-width: none;
  }

  ${media.xxl} {
    padding: 60px;
  }
`;

const IncentiveCardWrapper = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 14px 44px rgba(89, 99, 134, 0.11);
  border-radius: 16px;

  ${media.xl} {
    justify-content: center;
    flex-direction: row;
  }
`;

const IncentiveImage = styled.div`
  border-radius: 12px;
  overflow: hidden;
  align-self: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const IncentiveDetails = styled.div`
  padding: 15px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.xl} {
    padding: 0 50px;
  }
`;

const IncentiveDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const IncentiveTitle = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  color: ${({ theme }) => theme.colors.blackL25};
  margin-bottom: 10px;

  ${media.xl} {
    font-size: 1.5rem;
  }
`;

const IncentiveDescription = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.blackL25};
`;

const IncentiveReadMore = styled.a`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.mediumGray};

  &:hover {
    color: ${({ theme }) => theme.colors.mediumGray};
    text-decoration: none;
  }
`;

const MAX_LENGTH = 150;

const IncentiveCard = ({ incentive }) => {
  const { t } = useTranslation();
  const [seeMore, setSeeMore] = React.useState(false);
  const [description, setDescription] = React.useState(truncate(incentive.description, MAX_LENGTH));
  const needSeeMoreButton = description.length > MAX_LENGTH;

  const toggleSeeMore = () => {
    setDescription(seeMore ? truncate(incentive.description, MAX_LENGTH) : incentive.description);
    setSeeMore(!seeMore);
  };

  return (
    <StyledIncentiveCard>
      <IncentiveCardWrapper>
        <IncentiveImage>
          <img src={incentive.image_url} alt={incentive.name} />
        </IncentiveImage>
        <IncentiveDetails>
          <IncentiveDetailsWrapper>
            <IncentiveTitle>{incentive.name}</IncentiveTitle>
            <IncentiveDescription>
              {description}
              {needSeeMoreButton && (
                <IncentiveReadMore onClick={toggleSeeMore}>
                  &nbsp;{seeMore ? t('campaign.see_less') : t('button.read_more')}
                </IncentiveReadMore>
              )}
            </IncentiveDescription>
          </IncentiveDetailsWrapper>
        </IncentiveDetails>
      </IncentiveCardWrapper>
    </StyledIncentiveCard>
  );
};

IncentiveCard.propTypes = {
  incentive: PropTypes.object.isRequired,
};

export default IncentiveCard;
