import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'web/hooks/useDocumentTitle';
import { kFormatter, punyEncodedURL, replaceDefaultCampaignImage } from 'web/utils/string';
import defaultCampaignProfile from '../../../../../assets/images/web/default-campaign-profile.png';
import {
  Wrapper,
  Header,
  HeaderInner,
  TeamInfo,
  TeamAvatar,
  TeamNameWrapper,
  TeamNameTop,
  TeamName,
  TeamMetrics,
  TeamMetric,
  TeamMetricLabel,
  TeamMetricValue,
  Body,
  BodyInner,
  Sidebar,
  CampaignNameTop,
  CampaignAvatarAndName,
  CampaignName,
  CampaignWebsiteUrl,
  CampaignAvatar,
  CampaignDescription,
  Content,
  StepText,
  Title,
  Instruction,
} from './styles';

const formatCount = value => (parseInt(value, 10) ? kFormatter(value) : '-');
const formatRank = value => (parseInt(value, 10) ? moment.localeData().ordinal(value) : '-');

const VoterRegistrationTemplate = ({
  campaign,
  team,
  postmatesEnabled,
  stepCount,
  currentStep,
  title,
  instruction,
  children,
  ...rest
}) => {
  const { t } = useTranslation();
  useDocumentTitle(t('voter_registration.document_title'));
  const teamLeaderFirstName = get(team, 'team_leader.first_name', '');
  const teamLeaderLastName = get(team, 'team_leader.last_name', '');
  const teamLeaderName =
    teamLeaderFirstName || teamLeaderLastName
      ? `${teamLeaderFirstName} ${teamLeaderLastName}`
      : t('voter_registration.anonymous');
  return (
    <Wrapper {...rest}>
      {team && (
        <Header>
          <HeaderInner>
            <TeamInfo>
              <TeamAvatar src={team.profile_url}>{team.name}</TeamAvatar>
              <TeamNameWrapper>
                <TeamNameTop>
                  {t('voter_registration.join_team_with_name', { name: teamLeaderName })}
                </TeamNameTop>
                <TeamName>{team.name}</TeamName>
              </TeamNameWrapper>
            </TeamInfo>
            <TeamMetrics>
              {team.registrations > 100 && (
                <TeamMetric>
                  <TeamMetricValue>{formatCount(team.registrations)}</TeamMetricValue>
                  <TeamMetricLabel
                    dangerouslySetInnerHTML={{ __html: t('voter_registration.registrations') }}
                  />
                </TeamMetric>
              )}
              {team.pledges > 100 && (
                <TeamMetric>
                  <TeamMetricValue>{formatCount(team.pledges)}</TeamMetricValue>
                  <TeamMetricLabel
                    dangerouslySetInnerHTML={{ __html: t('voter_registration.pledges') }}
                  />
                </TeamMetric>
              )}
              {(team.registrations > 100 || team.pledges > 100) && (
                <TeamMetric>
                  <TeamMetricValue>{formatRank(team.rank)}</TeamMetricValue>
                  <TeamMetricLabel
                    dangerouslySetInnerHTML={{
                      __html: t('voter_registration.place_for_campaign', {
                        campaign: campaign.name,
                      }),
                    }}
                  />
                </TeamMetric>
              )}
            </TeamMetrics>
          </HeaderInner>
        </Header>
      )}
      <Body>
        <BodyInner>
          <Sidebar>
            <CampaignNameTop>{t('voter_registration.taking_action_for')}</CampaignNameTop>
            <CampaignAvatarAndName>
              <CampaignName>{campaign.name}</CampaignName>
              <CampaignWebsiteUrl target="_blank" href={campaign.website_url}>
                {punyEncodedURL(campaign.website_url)}
              </CampaignWebsiteUrl>
              <CampaignAvatar
                src={
                  replaceDefaultCampaignImage(campaign.url_profile_img) || defaultCampaignProfile
                }
                alt={campaign.name}
              />
            </CampaignAvatarAndName>
            {postmatesEnabled && (
              <>
                <CampaignNameTop style={{ marginTop: 20 }}>
                  {t('pledge_to_vote.partnership')}
                </CampaignNameTop>
                <CampaignAvatarAndName>
                  <CampaignAvatar
                    src={'https://outvote-static-assets.s3.amazonaws.com/postmates_logo_square.png'}
                    alt={t('Postmates')}
                  />
                </CampaignAvatarAndName>
              </>
            )}
            {!postmatesEnabled && <CampaignDescription>{campaign.description}</CampaignDescription>}
          </Sidebar>
          <Content>
            {currentStep > 0 && (
              <StepText>
                {t('voter_registration.step_of_with_count', { currentStep, stepCount })}
              </StepText>
            )}
            {title && <Title>{title}</Title>}
            {instruction && <Instruction>{instruction}</Instruction>}
            {children}
          </Content>
        </BodyInner>
      </Body>
    </Wrapper>
  );
};

VoterRegistrationTemplate.propTypes = {
  campaign: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  currentStep: PropTypes.number,
  instruction: PropTypes.string,
  postmatesEnabled: PropTypes.bool,
  stepCount: PropTypes.number,
  team: PropTypes.object,
  title: PropTypes.string,
};

export default VoterRegistrationTemplate;
