import * as React from 'react';
import PropTypes from 'prop-types';
import { track } from '@web/services/analytics';

import DotLoader from '@web/components/DotLoader';
import BackToStart from '@web/components/Headcount/components/BackToStart';
import ExternalButtonLink from '@web/components/Headcount/components/ExternalButtonLink';
import ShareLink from '@web/components/Headcount/components/ShareLink';
import Text from '@web/components/Headcount/components/Text';
import VoterBox from '@web/components/Headcount/components/VoterBox';
import VotingDates from '@web/components/Headcount/components/VotingDates';
import HeroImage from '@web/components/Headcount/components/HeroImage';

import useIsDesktop from '@web/components/Headcount/hooks/useIsDesktop';
import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';
import useFetchVoterRecord from '@web/components/Headcount/hooks/useFetchVoterRecord';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function UpdateAddressResult({ activity, backToStart, team }) {
  const { contactFormResult, hasStateId, selectedVoter } = useSelector(
    state => state.voterRegistration,
  );
  const { error, loading, voterRecord } = useFetchVoterRecord(activity);
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const isPartner = useIsPartner(team);
  const cannabisRegistrationEnabled = React.useMemo(
    () => activity?.campaign?.cannabis_registration_enabled,
    [activity?.campaign?.cannabis_registration_enabled],
  );

  const headcountMtvEnabled = React.useMemo(
    () => activity.campaign.headcount_mtv_enabled,
    [activity.campaign.headcount_mtv_enabled],
  );

  track('HEADCOUNT_VIEW_UPDATE_ADDRESS', {
    activity_id: activity?.id,
    activity_title: activity?.title,
    activity_type: activity?.type,
    campaign_id: activity?.campaign?.id,
    campaign_name: activity?.campaign?.name,
    team_id: team?.id,
    team_name: team?.name,
  });

  let title;
  title = hasStateId
    ? t('check_registration.headcount.updateAddress.title')
    : t('check_registration.headcount.useSSN');
  if (isPartner) {
    title = hasStateId
      ? t('check_registration.headcount.updateAddress.partnerTitle')
      : t('check_registration.headcount.partnerUseSSN');
  }
  if (cannabisRegistrationEnabled) {
    title = hasStateId
      ? t('check_registration.headcount.updateAddress.cannabisTitle')
      : t('check_registration.headcount.cannabisUseSSN');
  }

  if (loading) {
    return <DotLoader centered color="black" />;
  }

  if (error) {
    return (
      <Text color="red" variant="f3">
        {error}
      </Text>
    );
  }

  const stateAbbreviation = selectedVoter?.addressLine2?.split(',')[1].trim();

  const address = {
    // MTS - The voter database concats the first 2 address lines into
    // addressLine1, therefore the "unit/apt" will always be part of line 1
    line1: selectedVoter?.addressLine1,
    line2: null,
    line3: `${selectedVoter?.addressLine2} ${selectedVoter?.zip}`,
  };

  const regParams = new URLSearchParams({
    apartment: contactFormResult?.unit_number,
    campaign: 'impactive',
    city: contactFormResult?.city,
    date_of_birth_day: parseInt(contactFormResult?.date_of_birth.split('-')[2]),
    date_of_birth_month: parseInt(contactFormResult?.date_of_birth.split('-')[1]),
    date_of_birth_year: parseInt(contactFormResult?.date_of_birth.split('-')[0]),
    email: contactFormResult?.email,
    first_name: contactFormResult?.first_name,
    last_name: contactFormResult?.last_name,
    phone_number: contactFormResult?.phone.replace('+1', ''),
    state: contactFormResult?.state_abbrev,
    street_address: contactFormResult?.address,
    zip_5: contactFormResult?.zip_code,
  });

  const registrationLink =
    !hasStateId || !voterRecord?.voting_info?.online_reg_link
      ? `https://www.headcount.org/registertovote/?${regParams.toString()}`
      : voterRecord?.voting_info?.online_reg_link;

  const trackClick = async () => {
    track('HEADCOUNT_CLICK_REGISTRATION_LINK', {
      activity_id: activity?.id,
      activity_title: activity?.title,
      activity_type: activity?.type,
      automatic_vbm: contactFormResult?.automatic_vbm,
      campaign_id: activity?.campaign?.id,
      campaign_name: activity?.campaign?.name,
      can_register_online: contactFormResult?.can_register_online,
      can_vbm: contactFormResult?.can_vbm,
      can_vbm_online: contactFormResult?.can_vbm_online,
      contact_id: contactFormResult?.id,
      has_state_identification: contactFormResult?.has_state_identification,
      registration_status: contactFormResult?.registration_status,
      state_abbrev: contactFormResult?.state_abbrev,
      team_id: team?.id,
      team_name: team?.name,
    });
  };

  return (
    <>
      {headcountMtvEnabled && (
        <ShareLink
          activity={activity}
          referralLink={contactFormResult?.referral_link}
          team={team}
        />
      )}
      <Text variant="f2">{title}</Text>
      <VoterBox
        address={address}
        label={{ text: t('check_registration.headcount.updateAddress.labelText') }}
        voterName={contactFormResult?.fullname}
      />
      {registrationLink && (
        <ExternalButtonLink href={registrationLink} onClick={trackClick}>
          {t('check_registration.headcount.updateAddress.buttonLinkText')}
        </ExternalButtonLink>
      )}
      <VotingDates
        stateAbbreviation={stateAbbreviation}
        team={team}
        activity={activity}
        votingInfo={voterRecord?.voting_info}
      />
      {!isDesktop && isPartner && !headcountMtvEnabled && (
        <HeroImage alt={team?.name} src={team?.incentives_img_url} />
      )}
      {!headcountMtvEnabled && (
        <ShareLink
          activity={activity}
          referralLink={contactFormResult?.referral_link}
          team={team}
        />
      )}
      <BackToStart onClick={backToStart} />
    </>
  );
}

UpdateAddressResult.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      cannabis_registration_enabled: PropTypes.bool,
      headcount_mtv_enabled: PropTypes.bool,
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }).isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
  backToStart: PropTypes.func.isRequired,
  team: PropTypes.object,
};

UpdateAddressResult.defaultProps = {};

export default UpdateAddressResult;
