import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import headcountTheme from '@web/components/Headcount/theme';
import { media } from '@web/styles/theme';

const StyledLayout = styled.div`
  align-items: center;
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.bgGray};
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding: ${({ theme }) => `3rem ${theme.spacing.wide}  3rem`};
  ${media.ns} {
    padding: ${({ theme }) => `${theme.spacing.wider} ${theme.spacing.wide} 6rem`};
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  flex: 1;
  flex-direction: column;
  display: flex;
`;

function HeadcountLayoutMobile({ backgroundColor, children, ...rest }) {
  return (
    <ThemeProvider theme={headcountTheme}>
      <meta name="theme-color" content={backgroundColor || headcountTheme.colors.bgGray} />
      <StyledLayout backgroundColor={backgroundColor} {...rest}>
        <Container>{children}</Container>
      </StyledLayout>
    </ThemeProvider>
  );
}

HeadcountLayoutMobile.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.any.isRequired,
};

HeadcountLayoutMobile.defaultProps = {};

export default HeadcountLayoutMobile;
