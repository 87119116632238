import { vrDefaultColors } from '@web/styles/theme';
import styled, { css } from 'styled-components';

// For simpler imports
export { default as ErrorText } from './ErrorText';

export const Label = styled.label`
  font-weight: 600;
  margin-bottom: 0.4rem;
  color: ${({ $labelFontColor }) => $labelFontColor ?? vrDefaultColors.fontColor};
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const inputStyle = css`
  // fix address autocomplete field background color for iPhones
  background: ${({ $bgColor }) => $bgColor ?? vrDefaultColors.backgroundColor};

  &&& {
    align-items: center;
    border: 1px solid
      ${({ $hasError, theme, $outlineColor }) =>
        $hasError ? theme.colors.red : $outlineColor ?? vrDefaultColors.fieldOutlineColor};
    border-radius: 4px;
    box-shadow: none;
    color: ${({ $fontColor }) => $fontColor ?? vrDefaultColors.fontColor} !important;
    display: flex;
    height: 42px;
    outline: 0;
    padding: 0 16px 0 48px;
    transition: border 150ms ease-out, box-shadow 150ms ease-out;
    width: 100%;

    ::placeholder {
      color: ${({ $fontColor }) => `${$fontColor ?? vrDefaultColors.fontColor}80`} !important;
    }

    :hover {
      border: 1px solid ${({ theme, $outlineColor }) => $outlineColor ?? theme.colors.dodgerBlue};
    }

    :focus {
      border: 1px solid
        ${({ $hasError, theme, $outlineColor }) =>
          $hasError ? theme.colors.red : $outlineColor ?? theme.colors.dodgerBlue};
      box-shadow: ${({ $hasError, theme }) =>
        $hasError ? 'none' : '0px 0px 0px 2px rgba(24, 144, 255, 0.2)'};
      outline: 0;
    }

    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-autofill:first-line {
      color: ${({ $fontColor }) => $fontColor ?? vrDefaultColors.fontColor} !important;
    }
    &:-webkit-autofill {
      color: ${({ $fontColor }) => $fontColor ?? vrDefaultColors.fontColor} !important;
      -webkit-text-fill-color: ${({ $fontColor }) =>
        $fontColor ?? vrDefaultColors.fontColor} !important;
    }

    box-shadow: 0 0 0 30px ${({ $bgColor }) => $bgColor ?? vrDefaultColors.backgroundColor} inset !important;
    -webkit-box-shadow: 0 0 0 30px ${({ $bgColor }) => $bgColor ?? vrDefaultColors.backgroundColor}
      inset !important;
  }
`;

export const selectorStyle = css`
  &&& {
    color: ${({ theme }) => theme.colors.black};
    height: 42px;
    transition: border 150ms ease-out, box-shadow 150ms ease-out;
    width: 100%;

    svg {
      color: #707070;
    }

    .ant-select-selection-placeholder {
      color: #707070;
    }

    .ant-select-selector {
      transition: border 150ms ease-out, box-shadow 150ms ease-out;
      display: flex;
      height: 42px;
      border-radius: 4px;
      border: 1px solid
        ${({ $hasError, theme }) => ($hasError ? theme.colors.red : theme.colors.gray)};
      padding: 5px 16px 0 48px;

      :hover {
        border: 1px solid ${({ theme }) => theme.colors.dodgerBlue};
      }
    }

    :focus {
      border: 1px solid
        ${({ $hasError, theme }) => ($hasError ? theme.colors.red : theme.colors.dodgerBlue)};
      box-shadow: ${({ $hasError }) =>
        $hasError ? 'none' : '0px 0px 0px 2px rgba(24, 144, 255, 0.2)'};
      outline: 0;
    }
  }
`;
