import {
  LoadingOutlined,
  TwitterOutlined,
  FacebookOutlined,
  MailOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Alert, Spin, Row, Col, Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState, useCallback } from 'react';
import Clipboard from 'react-clipboard.js';
import { useTranslation } from 'react-i18next';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { track, trackPageViewWithDynamicIDs } from '../../services/analytics';
import { campaigns, users, teams } from '../../services/api';
import ImpactiveButton from '../ImpactiveButton';
import VoterRegistrationTemplate from '../templates/VoterRegistrationTemplate';
import Definition from './Definition';
import {
  Section,
  Heading,
  Description,
  DefinitionList,
  Link,
  BoldText,
  TeamSection,
  ButtonsRow,
  ShareButtonInner,
} from './styles';

const StateVotingInformation = props => {
  const { campaignId, contactId } = props.match.params; // can be either campaign `id` or `slug`
  const { h, referral_hash, state_abbrev, can_vote } = queryString.parse(props.location.search);
  const { t } = useTranslation();

  const locale = window.localStorage.getItem('locale');

  const [campaign, setCampaign] = useState({});
  const [state_info, setStateInfo] = useState({});
  const [team, setTeam] = useState();
  const [loading, setLoading] = useState(true);

  const hostname = `${window.location.protocol}//${window.location.host}`;
  const query = queryString.stringify({ contact_referral_id: contactId, referral_hash });
  const readyToVoteLink = `${hostname}/campaigns/${campaignId}/ready_to_vote?${query}`;

  const formatDate = str => {
    if (str) {
      return dayjs(str, 'MM-DD-YY').locale(locale).format('MMMM D');
    }
  };

  useEffect(() => {
    track('VIEW_STATE_VOTING_INFORMATION');
    setLoading(true);
    Promise.all([
      campaigns.getPublicCampaign({ id: campaignId }),
      campaigns.getStateInfo({ state_abbrev: state_abbrev }),
    ])
      .then(([campaignResponse, stateResponse]) => {
        const campaign = campaignResponse.data.data;
        setCampaign(campaign);
        setStateInfo(stateResponse.data);

        trackPageViewWithDynamicIDs({
          fbPixelId: campaign.fb_pixel_id,
          gaTrackingId: campaign.ga_tracking_id,
          snapchatPixelId: campaign.snapchat_pixel_id,
        });

        if (referral_hash) {
          return users
            .getUserByReferralHash(referral_hash)
            .then(({ data: { data: user } }) => {
              return teams.getTeam({ filters: { campaign_id: campaign.id }, id: user.id });
            })
            .then(({ data: { data: team } }) => setTeam(team))
            .catch(error => {
              // Catch error and make this promise always resolved,
              // so we can render this view at least
              console.error('get team data >', error);
            });
        }

        return;
      })
      .then(() => setLoading(false));
  }, [campaignId, contactId, h, referral_hash, state_abbrev]);

  const handleNativeShare = useCallback(() => {
    if (navigator.share) {
      navigator.share({ url: readyToVoteLink }).catch(console.error);
    }
  }, [readyToVoteLink]);

  if (loading) {
    return (
      <div className="flex-row justify-content-center mt-4">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  const shareNowVisible = !!navigator.share;
  const inviteSection = (
    <TeamSection id="team-section">
      <Heading>{t('Triple Your Vote')}</Heading>
      <Description style={{ marginBottom: 20 }}>
        {campaign.rewards || t('pledge_to_vote.invite_description')}
      </Description>
      <ButtonsRow fullWithOnMobile>
        {shareNowVisible && (
          <ImpactiveButton onClick={handleNativeShare}>
            <UploadOutlined /> {t('voting_information.share.share_now')}
          </ImpactiveButton>
        )}
        {!shareNowVisible && (
          <Clipboard
            component="span"
            data-clipboard-text={readyToVoteLink}
            onSuccess={() => message.info(t('common.copied_to_clipboard'))}
          >
            <Tooltip placement="top" title={readyToVoteLink}>
              <ImpactiveButton>{t('voting_information.share.copy_share_link')}</ImpactiveButton>
            </Tooltip>
          </Clipboard>
        )}
        <FacebookShareButton url={readyToVoteLink}>
          <ShareButtonInner>
            <FacebookOutlined /> {t('voting_information.share.facebook')}
          </ShareButtonInner>
        </FacebookShareButton>
        <TwitterShareButton url={readyToVoteLink}>
          <ShareButtonInner>
            <TwitterOutlined /> {t('voting_information.share.twitter')}
          </ShareButtonInner>
        </TwitterShareButton>
        <ImpactiveButton
          onClick={() =>
            window.open(`mailto:?body=${encodeURIComponent(readyToVoteLink)}`, '_blank')
          }
        >
          <MailOutlined /> {t('voting_information.share.email')}
        </ImpactiveButton>
      </ButtonsRow>
    </TeamSection>
  );

  return (
    <VoterRegistrationTemplate
      campaign={campaign}
      team={team}
      postmatesEnabled={campaign.postmates_integration_enabled}
      stepCount={2}
      currentStep={2}
      title={
        can_vote === 'true' ? t('voting_information.title_with_state', { state: state_abbrev }) : ''
      }
      instruction={can_vote === 'true' ? t('voting_information.disclaimer') : ''}
      data-testid="voting-information"
    >
      {can_vote === 'true' && (
        <Section>
          <Heading style={{ marginBottom: 10 }}>{t(' Vote by Mail or Absentee')}</Heading>
          <Row gutter={60}>
            <Col span={24} md={24}>
              {state_info.can_vbm && state_info.automatic_vbm && (
                <Definition
                  term={t('Automatic Vote By Mail')}
                  description={<p>{t('voting_information.automatic_vbm_instruction')}</p>}
                />
              )}
              {state_info.can_vbm && (
                <Definition
                  term={t('Return Deadline')}
                  description={<p>{state_info.vbm_return_deadline_description}</p>}
                />
              )}
              {state_info.can_vbm && (
                <Definition
                  term={t('Excuse Info')}
                  description={<p>{state_info.vbm_excuse_info}</p>}
                />
              )}
              <div className="mt-2">
                <Link
                  target="_blank"
                  disabled={!state_info.ballot_tracker}
                  href={state_info.ballot_tracker}
                >
                  {t('Absentee Ballot Tracker')}
                </Link>
              </div>
            </Col>
          </Row>

          <Heading style={{ marginTop: 30 }}>{t('Vote In Person')}</Heading>
          <Row gutter={60}>
            <Col span={24} md={24}>
              {state_info.early_vote_start && state_info.early_vote_end && (
                <DefinitionList>
                  <Definition
                    term={t('voting_information.early_voting_starts')}
                    description={formatDate(state_info.early_vote_start)}
                  />
                  <Definition
                    term={t('voting_information.early_voting_ends')}
                    description={formatDate(state_info.early_vote_end)}
                  />
                </DefinitionList>
              )}

              {!(state_info.early_vote_start && state_info.early_vote_end) && (
                <DefinitionList>
                  <div>
                    <BoldText>
                      {t('voting_information.cannot_early_voting_with_state', {
                        state: state_abbrev,
                      })}
                    </BoldText>
                  </div>
                </DefinitionList>
              )}
              <div style={{ marginTop: 20 }}>
                <Link
                  target="_blank"
                  disabled={!state_info.in_person_early_voting_link}
                  href={state_info.in_person_early_voting_link}
                >
                  {t('In Person Early Voting Rules')}
                </Link>
              </div>
              <div style={{ marginTop: 20 }}>
                <Link
                  target="_blank"
                  disabled={!state_info.polling_place_locator}
                  href={state_info.polling_place_locator}
                >
                  {t('Polling Place Locator')}
                </Link>
              </div>
            </Col>
          </Row>
        </Section>
      )}

      {inviteSection}
      {can_vote === 'true' && (
        <Alert
          message="Information was collected from secretaries of state offices, monitoring official websites, and local election officials. If you notice any updated voting rules not published on this website please email support@impactive.io."
          type="info"
          showIcon
        />
      )}
    </VoterRegistrationTemplate>
  );
};

StateVotingInformation.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string.isRequired,
      contactId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default StateVotingInformation;
