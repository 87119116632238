import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import dayjs from 'dayjs';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { message } from 'antd';

import theme from '@web/styles/theme';
import { activities } from '@web/services/api';
import { Form, Formik } from 'formik';
import { getFont, getInitialSurveyValues, getValidationSchema } from './utils';
import VolunteerInfoFields from './VolunteerInfoFields';
import ActivityQuestions from './ActivityQuestions';
import OptInCheckboxField from './OptInCheckboxField';
import ImpactiveButton from '@web/components/ImpactiveButton';
import ReferralLink from './ReferralLink';
import {
  Container,
  HeaderImage,
  Header,
  SubText,
  StyledCard,
  PaddedFlexCard,
  Footer,
} from './styles';
import { trackEventViewWithDynamicIDs } from '@web/services/analytics';

const StyledForm = styled(Form)`
  width: 100%;
  background-color: ${({ $bgColor }) => $bgColor || theme.formDefaultColors.pageBackgroundColor};
  * {
    font-family: ${({ $font }) => getFont($font)};
  }
`;

const TitleAndDescriptionWrapper = styled.div`
  text-align: center;
`;

const getDateValue = date => (date ? dayjs(date).format('MM/DD/YYYY') : '');

const StyledSubmitButton = styled(ImpactiveButton)`
  width: 728px;
  margin: 0 auto;
  font-weight: 600;
  border: none;

  color: ${({ $color }) => $color ?? theme.formDefaultColors.buttonFontColor};
  background: ${({ $bgColor }) => $bgColor || theme.formDefaultColors.buttonColor};

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const SurveyActivityForm = ({ activity, team }) => {
  const [referralLink, setReferralLink] = useState(null);
  const [contactId, setContactId] = useState(null);
  // Pluck referral_hash from the query params, used for form referral tracking
  const { search } = useLocation();
  const {
    referral_hash: referralHash,
    start: activeFormSection,
    utm_campaign,
    utm_source,
    utm_medium,
  } = queryString.parse(search);

  const formAttributes = activity.survey_activity_form;
  // We need to sort the questions by position before validation schema is built so that
  // the initial values and the validation schema match the order of the questions in the form
  const sortedActivityQuestions = activity.activity_questions.sort(
    (a, b) => a.position - b.position,
  );

  const incentive = activity.activity_incentives_attributes?.[0];

  // For the BVM campaign on prod, we need to prevent users from submitting the form until they check the OPT-IN agreement
  const isBVMCampaign = useMemo(() => {
    const applicationHost = process.env.APPLICATION_HOST;

    // To make it testable on staging we check the Test Campaign id
    // To test it locally just change 190 to your campaign ID
    if (applicationHost !== 'app.impactive.io') {
      // "Test Campaign" campaign id on Staging
      return activity.campaign.id === 190;
    }

    // BVM campaign id
    return activity.campaign.id === 4554;
  }, [activity.campaign.id]);

  const isOptInRequired = useMemo(
    () => activity.settings.opt_in_agreement_required,
    [activity.settings.opt_in_agreement_required],
  );

  const initialValues = getInitialSurveyValues(
    formAttributes,
    sortedActivityQuestions,
    isBVMCampaign || isOptInRequired,
    activeFormSection === '1',
  );
  const validationSchema = getValidationSchema(formAttributes, sortedActivityQuestions);

  const {
    background_color,
    section_background_color,
    main_font_color,
    secondary_color,
    button_font_color,
    font,
    pixel_fb_id,
    pixel_ga_id,
    pixel_sc_id,
  } = activity.settings ?? {};

  const onSubmit = async (values, formikBag) => {
    // prepare and save contact data to local storage
    const localContact = JSON.parse(localStorage.getItem('vr_and_form_contact')) || {};
    const contact = {
      // we also don't want to lose data from vr/headcount form
      ...localContact,
      date_of_birth: getDateValue(values?.date_of_birth || localContact?.date_of_birth) || null,
      email: values?.email || localContact?.email || '',
      first_name: values?.first_name || localContact?.first_name || '',
      last_name: values?.last_name || localContact?.last_name || '',
      phone: values?.phone || localContact?.phone || '',
      zip_code: values?.zip_code || localContact?.zip_code || '',
    };
    localStorage.setItem('vr_and_form_contact', JSON.stringify(contact));

    if (isOptInRequired && !values.opt_in) {
      message.error('Opt in is required to submit this form.');
      formikBag.setSubmitting(false);
      return;
    }

    const { question_answers, ...surveyFormData } = values;
    // Parse string to date
    surveyFormData.date_of_birth = dayjs(surveyFormData.date_of_birth, 'MM/DD/YYYY').format(
      'DD/MM/YYYY',
    );

    if (team) {
      surveyFormData.team_referral_id = team.id;
    }

    surveyFormData.utm_campaign = utm_campaign;
    surveyFormData.utm_source = utm_source;
    surveyFormData.utm_medium = utm_medium;

    formikBag.setSubmitting(true);
    activities
      .submitSurveyActivityForm({
        autoOpenLink: activeFormSection === '1',
        campaignSlug: activity.campaign.slug,
        formSlug: activity.survey_activity_form.slug,
        questionAnswers: question_answers,
        referralHash,
        surveyFormData,
      })
      .then(({ data }) => {
        const link = data.data.referral_link + (activeFormSection === '1' ? '?start=1' : '');
        const contact_id = data.data.contact_id;
        trackEventViewWithDynamicIDs({
          actionName: 'survey_form_filled',
          categoryName: 'Survey Activity',
          fbPixelId: pixel_fb_id,
          gaTrackingId: pixel_ga_id,
          gtmPixelId: activity?.settings?.pixel_gtm_id,
          snapchatPixelId: pixel_sc_id,
        });
        setReferralLink(link);
        setContactId(contact_id);
      })
      .catch(err =>
        message.error(
          err?.response?.data?.message || err?.message || 'Something went wrong, please try again',
        ),
      )
      .finally(() => formikBag.setSubmitting(false));
  };

  // Thank you message is shown after the form is submitted
  if (referralLink) {
    const speechifyAiData = (activity.speechifyai_activities ?? []).find(
      e => e.activity_id === activity.id,
    );
    return (
      <Container>
        <ReferralLink
          speechifyAiData={speechifyAiData}
          link={referralLink}
          settings={activity.settings}
          incentive={incentive}
          activity={activity}
          contactId={contactId}
        />
      </Container>
    );
  }
  const isClosed = activity.aasm_state === 'closed';

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      render={({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => {
        // check at least one value is not empty to enable submit button when initialized from local storage
        const isValuesExists = Object.entries(values).some(([key, value]) => {
          // exclude question_answers and opt_in from checking
          if (key === 'question_answers') return false;
          if (key === 'opt_in') return false;
          return !!value;
        });

        return (
          <StyledForm $font={font} onSubmit={handleSubmit} $bgColor={background_color}>
            <Container>
              {activity.hero_img_url && <HeaderImage src={activity.hero_img_url} />}
              <StyledCard $background={section_background_color}>
                <TitleAndDescriptionWrapper>
                  <Header $color={main_font_color}>{activity.title}</Header>
                  <SubText $color={main_font_color}>{activity.description}</SubText>
                </TitleAndDescriptionWrapper>
                {isClosed && <Header style={{ marginTop: 30 }}>Closed</Header>}
              </StyledCard>
              {!isClosed && (
                <>
                  <PaddedFlexCard $background={section_background_color}>
                    <VolunteerInfoFields
                      initialValues={initialValues}
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      activity={activity}
                    />
                  </PaddedFlexCard>
                  <ActivityQuestions
                    questions={sortedActivityQuestions}
                    handleChange={handleChange}
                    activity={activity}
                  />
                  <OptInCheckboxField
                    activity={activity}
                    values={values}
                    handleChange={handleChange}
                  />
                </>
              )}
            </Container>
            {!isClosed && (
              <Footer $bgColor={background_color}>
                <StyledSubmitButton
                  htmlType="submit"
                  type="submit"
                  block
                  $bgColor={secondary_color}
                  $color={button_font_color}
                  disabled={isSubmitting || !isValuesExists || (isBVMCampaign && !values.opt_in)}
                >
                  Submit
                </StyledSubmitButton>
              </Footer>
            )}
          </StyledForm>
        );
      }}
    />
  );
};

SurveyActivityForm.propTypes = {
  activity: PropTypes.object.isRequired,
  team: PropTypes.object,
};

export default SurveyActivityForm;
