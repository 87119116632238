import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 70px;
  margin: 0 auto;
  max-width: 700px;
  padding: 40px;

  width: 100%;
  height: 100%;
`;

const CoverImage = styled.img`
  max-width: 50%;
  max-height: 100vh;
  height: 100%;
  object-fit: cover;
  object-position: right;

  // hide on mobile
  @media (max-width: 768px) {
    display: none;
  }
`;

const LayoutWithCover = ({ coverUrl, children }) => {
  return (
    <Flex>
      <CoverImage src={coverUrl} alt="auth cover" />
      <VerticalFlex>{children}</VerticalFlex>
    </Flex>
  );
};

LayoutWithCover.propTypes = {
  children: PropTypes.node.isRequired,
  coverUrl: PropTypes.string.isRequired,
};

export default LayoutWithCover;
