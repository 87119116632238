import React, { useState } from 'react';

import MaxWidthContainer from '../common/MaxWidthContainer';
import ImpactiveLink from '@web/components/common/Link';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { truncate, punyEncodedURL, parseUrl } from 'web/utils/string';
import pxToRem from 'web/utils/pxToRem';

const Wrapper = styled.div`
  width: 100%;
  padding: 40px 40px 0;
  margin-bottom: 2rem;
`;

const Description = styled.div`
  font-size: ${pxToRem(16)};
  color: ${({ theme }) => theme.colors.blue};
  margin: 1rem 0;
  @media (max-width: 680px) {
    font-size: ${pxToRem(14)};
  }
`;

const General = styled.div`
  margin-right: 5rem;
  width: 100%;
  @media (max-width: 1170px) {
    margin-right: 0;
  }
`;

const Data = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1170px) {
    flex-wrap: wrap-reverse;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.teal40};
  border-radius: 20px;
  padding: 16px 0;
`;

const CampaignName = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 2.5rem;
  @media (max-width: 1170px) {
    margin-bottom: 1rem;
    width: 100%;
  }
`;

const CampaignImage = styled.img`
  align-self: center;
  border-radius: 12px;
  height: 226px;
  max-width: 400px;
  object-fit: contain;
  @media (max-width: 1170px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const Metrics = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const MetricContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 3rem;
`;

const MetricName = styled.div`
  font-size: 0.875rem;
  text-transform: uppercase;
`;

const Metric = styled.div`
  font-size: 1.5rem;
`;

const SeeMoreButton = styled.button`
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.coral};
  font-family: ${({ theme }) => theme.fonts.bold};
  &:hover {
    color: #0056b3;
  }
`;

const TRUNCATE_LENGTH = 250;

const CampaignData = () => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { t } = useTranslation();
  const {
    name,
    description,
    website_url,
    total_actions_completed,
    total_allies,
    total_actions_performed,
    url_hero_img,
  } = useSelector(state => state.oneCampaign.selectedCampaign);
  const isDescriptionLong = description?.length > TRUNCATE_LENGTH;
  const formattedDescription =
    isDescriptionLong && !showFullDescription
      ? truncate(description, TRUNCATE_LENGTH)
      : description;

  const renderMetric = (metric, metricName) => (
    <MetricContainer>
      <Metric>{metric}</Metric>
      <MetricName>{metricName}</MetricName>
    </MetricContainer>
  );

  const websiteUrl = parseUrl(website_url);

  return (
    <Wrapper>
      <Content>
        <MaxWidthContainer>
          <Data>
            <General>
              <CampaignName>{name}</CampaignName>
              <Description>
                {formattedDescription}
                {isDescriptionLong && (
                  <SeeMoreButton onClick={() => setShowFullDescription(!showFullDescription)}>
                    {showFullDescription ? t('campaign.see_less') : t('campaign.see_more')}
                  </SeeMoreButton>
                )}
              </Description>
              {!!websiteUrl && (
                <ImpactiveLink as="a" target="blank" href={websiteUrl}>
                  {punyEncodedURL(website_url)}
                </ImpactiveLink>
              )}
              <Metrics>
                {renderMetric(`${total_allies}`, t('campaign.allies'))}
                {renderMetric(`${total_actions_completed}`, t('campaign.actions'))}
                {renderMetric(`${total_actions_performed}`, t('campaign.network'))}
              </Metrics>
            </General>
            <CampaignImage src={url_hero_img} />
          </Data>
        </MaxWidthContainer>
      </Content>
    </Wrapper>
  );
};

export default CampaignData;
