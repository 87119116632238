import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import AddressCard from './AddressCard';
import Contact, { IconWrapper } from '@web/components/VoterRegistration/newComponents/Contact';
import DotLoader from '@web/components/DotLoader';
import MessageBox from '@web/components/VoterRegistration/newComponents/MessageBox';
import Question from '@web/components/VoterRegistration/newComponents/icons/Question';
import { largeBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';

const Row = styled.div`
  align-items: flex-start;
  display: flex;
`;

const StyledMessageBox = styled(MessageBox)`
  margin-bottom: 1rem;
  margin-top: 2rem;

  ${({ $isFanOut }) =>
    $isFanOut
      ? css`
          background: ${({ theme }) => theme.colors.white};
          border: 1px solid ${({ theme }) => theme.colors.white};
          border-radius: 0.5rem;
          box-shadow: 0px 4px 20px 0px #00000012;
        `
      : css`
          background: ${({ $bgColor }) => ($bgColor ? `${$bgColor}40` : 'unset')};
        `}
`;

const Cards = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 40px;
  ${largeBreakpoint} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 20px;
  margin-bottom: 0;
  color: ${({ $color }) => $color};
`;

function NoAddressMatch({
  parsedVoter,
  recordAddress,
  userAddress,
  handleRecordCorrect,
  handleUserCorrect,
  loading,
  isFanOut,
  pickedAddress,
  setPickedAddress,
  fontColor,
  bgColor,
  progressColor,
}) {
  const { t } = useTranslation();

  const infoText = React.useMemo(
    () =>
      isFanOut
        ? t('check_registration.addressConfirmation.infoLine1FanOut')
        : t('check_registration.addressConfirmation.infoLine1'),
    [t, isFanOut],
  );
  const recordCorrectText = React.useMemo(
    () =>
      isFanOut
        ? t('check_registration.addressConfirmation.recordCorrectFanOut')
        : t('check_registration.addressConfirmation.recordCorrect'),
    [t, isFanOut],
  );
  const userCorrectText = React.useMemo(
    () =>
      isFanOut
        ? t('check_registration.addressConfirmation.userCorrectFanOut')
        : t('check_registration.addressConfirmation.userCorrect'),
    [t, isFanOut],
  );

  const recordClicked = React.useCallback(() => setPickedAddress('record'), [setPickedAddress]);

  const userClicked = React.useCallback(() => setPickedAddress('user'), [setPickedAddress]);

  return (
    <>
      <StyledMessageBox $bgColor={progressColor} $isFanOut={isFanOut}>
        <Contact fontColor={fontColor} age={parsedVoter.age} name={parsedVoter.name} />
        <Row style={{ marginTop: 20 }}>
          <IconWrapper $color={fontColor} style={{ paddingTop: 3 }}>
            {isFanOut ? <CloseCircleOutlined style={{ color: '#F5222D' }} /> : <Question />}
          </IconWrapper>
          <Text $color={fontColor}>{infoText}</Text>
        </Row>
        {!isFanOut && (
          <Row>
            <IconWrapper />
            <Text $color={fontColor}>{t('check_registration.addressConfirmation.infoLine2')}</Text>
          </Row>
        )}
      </StyledMessageBox>
      {loading && <DotLoader centered color="black" />}
      {!loading && (
        <Cards>
          <AddressCard
            fontColor={fontColor}
            bgColor={bgColor}
            address={recordAddress}
            icon="location"
            isFanOut={isFanOut}
            picked={pickedAddress === 'record'}
            onClick={isFanOut ? recordClicked : handleRecordCorrect}
            text={recordCorrectText}
          />
          <AddressCard
            fontColor={fontColor}
            bgColor={bgColor}
            address={userAddress}
            icon="plus"
            isFanOut={isFanOut}
            picked={pickedAddress === 'user'}
            onClick={isFanOut ? userClicked : handleUserCorrect}
            text={userCorrectText}
          />
        </Cards>
      )}
    </>
  );
}

NoAddressMatch.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    id: PropTypes.number.isRequired,
  }),
  bgColor: PropTypes.string,
  contactFormResult: PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    encode_id: PropTypes.any.isRequired,
    id: PropTypes.number.isRequired,
    state_abbrev: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
  }),
  fontColor: PropTypes.string,
  handleRecordCorrect: PropTypes.func.isRequired,
  handleUserCorrect: PropTypes.func.isRequired,
  isFanOut: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
  parsedVoter: PropTypes.shape({
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string.isRequired,
    age: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    voterbaseId: PropTypes.any.isRequired,
    zip: PropTypes.string.isRequired,
  }),
  pickedAddress: PropTypes.string.isRequired,
  progressColor: PropTypes.string,
  recordAddress: PropTypes.string.isRequired,
  setPickedAddress: PropTypes.func.isRequired,
  setSelectedFlow: PropTypes.func.isRequired,
  team: PropTypes.shape({
    id: PropTypes.number,
  }),
  userAddress: PropTypes.string.isRequired,
};

NoAddressMatch.defaultProps = {
  team: null,
};

export default NoAddressMatch;
