import styled from 'styled-components';
import React, { useState, useRef } from 'react';

import { array, bool, func } from 'prop-types';

import useElementsPerSlide from '../hooks/useElementsPerSlide';
import ScriptsCarousel from './Carousel';
import ScriptsSectionHeader from './ScriptSectionHeader';

const ScriptsSectionWrapper = styled.div`
  padding-top: 14px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ visible }) => (visible ? 'unset' : '140px')};
  cursor: ${({ visible }) => (visible ? 'unset' : 'pointer')};
  position: relative;
  ${({ visible }) =>
    !visible &&
    `:after {
    background: linear-gradient(180deg, #ffffff 32.29%, rgba(255, 255, 255, 0) 100%);
    content: '';
    transform: matrix(1, 0, 0, -1, 0, 0);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 58px;
  }`}
`;

const ScriptsSection = ({ templates, onSelectTemplate, textDisabled, onOpenScripts }) => {
  const [visible, setVisible] = useState(true);

  const ref = useRef();

  // TODO: Slider is no longer being used - remove `useElementsPerSlide` and all the logic behind it
  const slideData = useElementsPerSlide(templates, ref);

  const setScriptMessage = script => {
    onSelectTemplate(script.id, script.message);
  };

  const openScripts = () => {
    if (visible) return;
    setVisible(true);
    // Needed to add setTimeout to allow time for the scripting section to be displayed
    setTimeout(() => {
      onOpenScripts();
    }, 100);
  };

  return (
    <ScriptsSectionWrapper ref={ref} visible={visible} onClick={openScripts}>
      <ScriptsSectionHeader visible={visible} setVisible={setVisible} textDisabled={textDisabled} />
      <ScriptsCarousel
        setScriptMessage={setScriptMessage}
        slideData={slideData}
        visible={visible}
      />
    </ScriptsSectionWrapper>
  );
};

ScriptsSection.propTypes = {
  onOpenScripts: func,
  onSelectTemplate: func,
  templates: array,
  textDisabled: bool,
};

export default ScriptsSection;
