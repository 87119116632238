import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from 'web/styles/theme';
import { campaigns } from '../../../services/api';
import CampaignAbout from './CampaignAbout';
import TopTeams from './TopTeams';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 24px;

  ${media.xl} {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderGray}`};
  }

  ${media.nxl} {
    flex-direction: column;
  }
`;

const VerticalDivider = styled.div`
  display: none;

  ${media.xl} {
    display: block;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.lightGray};
    margin: 64px 32px;
  }
`;

const CampaignInfo = ({ campaign }) => {
  const [topTeams, setTopTeams] = useState([]);

  useEffect(() => {
    campaigns
      .getCampaignLeaderboard({ id: campaign.slug, page: 1, per_page: 3 })
      .then(({ data: { data } }) => {
        setTopTeams(data);
      });
  }, [campaign.slug]);

  return (
    <Wrapper>
      <CampaignAbout campaign={campaign} />
      <VerticalDivider />
      <TopTeams campaign={campaign} teams={topTeams} />
    </Wrapper>
  );
};

CampaignInfo.propTypes = {
  campaign: PropTypes.shape({
    slug: PropTypes.string,
  }).isRequired,
  topTeams: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CampaignInfo;
