import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Switch } from 'antd';

const Label = styled.p`
  margin-bottom: 0.25rem;
`;

const StyledTag = styled.div`
  margin: 1rem 0;
`;

const StyledTagsList = styled.ul`
  margin: 0;
  padding: 0;
`;

function TagsList({ answers, handleAnswerChange, tags }) {
  function changeHandler(el, value) {
    handleAnswerChange('tag', { ...el, value });
  }

  function getValue(id) {
    return answers.find(el => el.id === id)?.value || false;
  }

  if (!tags?.length) {
    return null;
  }

  return (
    <StyledTagsList>
      {tags.map(el => (
        <StyledTag key={el.id}>
          <Label>{el.tag.name}</Label>
          <Switch
            checked={getValue(el.id)}
            checkedChildren="Yes"
            defaultChecked={false}
            onChange={checked => changeHandler(el, checked)}
            unCheckedChildren="No"
          />
        </StyledTag>
      ))}
    </StyledTagsList>
  );
}

TagsList.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    }),
  ),
  handleAnswerChange: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
};

TagsList.defaultProps = {};

export default TagsList;
