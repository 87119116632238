import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

const Box = styled.div`
  display: flex;
  width: 3.75rem;
  height: 3.75rem;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1.067px solid ${({ theme }) => theme.colors.darkBlue};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 4.267px 4.267px 0px 0px ${({ theme }) => theme.colors.darkBlue};
`;

const Icon = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem; /* 100% */
`;

const TextWrapper = styled.div`
  width: calc(100% - 3.75rem - 20px); // 3.75rem is the width of the Box component
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  min-height: 58px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.75rem;
  line-height: 2rem; /* 114.286% */

  ${smallBreakpoint} {
    font-size: 1.25rem;
    line-height: 1.5rem; /* 120% */
  }
`;

export const IconBox = ({ icon }) => {
  return (
    <Box>
      <Icon>{icon}</Icon>
    </Box>
  );
};

const PageTitle = ({ icon, title, descriptionComponent }) => {
  return (
    <Wrapper>
      <IconBox icon={icon} />
      <TextWrapper>
        <Title>{title}</Title>
        {descriptionComponent && descriptionComponent}
      </TextWrapper>
    </Wrapper>
  );
};

IconBox.propTypes = {
  icon: PropTypes.string.isRequired,
};

PageTitle.propTypes = {
  descriptionComponent: PropTypes.node,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

PageTitle.defaultProps = {};

export default PageTitle;
