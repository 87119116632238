import * as React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { track } from '@web/services/analytics';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import ChooseFlow from './components/ChooseFlow';
import HeadcountLayoutDesktop from './components/HeadcountLayoutDesktop';
import HeadcountLayoutMobile from './components/HeadcountLayoutMobile';
import { init, setContactFormResult } from '@web/reducers/voterRegistration';

// Flows
import CheckRegistration from './flows/CheckRegistration';
import RegisterToVote from './flows/RegisterToVote';
import WantToHelp from './flows/WantToHelp';
import GeorgiaRunoffPage from './flows/GeorgiaRunoffPage';

import useIsDesktop from './hooks/useIsDesktop';
import useIsPartner from './hooks/useIsPartner';

export const flows = {
  CHECK_REGISTRATION: 'CHECK_REGISTRATION',
  GA_RUNOFF_PAGE: 'GA_RUNOFF_PAGE',
  REGISTER_TO_VOTE: 'REGISTER_TO_VOTE',
  WANT_TO_HELP: 'WANT_TO_HELP',
};

function Headcount({ activity, team }) {
  const dispatch = useDispatch();
  const [selectedFlow, setSelectedFlow] = React.useState(null);
  const isDesktop = useIsDesktop();
  const isPartner = useIsPartner(team);
  const { search } = useLocation();
  const { start } = queryString.parse(search);

  const prefillContactForm = React.useCallback(() => {
    // get contact from local storage and prefill forms if there was no splash screen
    if (start === '1') {
      const contact = JSON.parse(localStorage.getItem('vr_and_form_contact'));
      dispatch(setContactFormResult({ activity, contact: contact || {} }));
    } else {
      dispatch(init());
    }
  }, [activity, dispatch, start]);

  React.useEffect(() => {
    prefillContactForm();
  }, [prefillContactForm]);

  const pageProps = {
    activity,
    backToStart: () => {
      setSelectedFlow(null);
      prefillContactForm();
    },
    setRootFlow: setSelectedFlow,
    team,
  };

  const { utm_campaign, utm_source, utm_term, utm_content, utm_medium } = queryString.parse(
    window.location.search,
  );

  track('HEADCOUNT_VIEW_CHOOSE_FLOW', {
    activity_id: activity?.id,
    activity_title: activity?.title,
    activity_type: activity?.type,
    campaign_id: activity?.campaign?.id,
    campaign_name: activity?.campaign?.name,
    team_id: team?.id,
    team_name: team?.name,
    utm_campaign: utm_campaign,
    utm_content: utm_content,
    utm_medium: utm_medium,
    utm_source: utm_source,
    utm_term: utm_term,
  });

  function renderFlow() {
    switch (selectedFlow) {
      case flows.CHECK_REGISTRATION:
        return <CheckRegistration {...pageProps} />;
      case flows.REGISTER_TO_VOTE:
        return <RegisterToVote {...pageProps} />;
      case flows.WANT_TO_HELP:
        return <WantToHelp {...pageProps} />;
      // Special case flow page for GA Runoff, it's being redirected to from ContactForm which is part of all forms
      // so the cleanest way to add it without much duplication is to extend this root headcount page instead.
      case flows.GA_RUNOFF_PAGE:
        return <GeorgiaRunoffPage {...pageProps} />;
      default:
        return <ChooseFlow flows={flows} setSelectedFlow={setSelectedFlow} {...pageProps} />;
    }
  }

  if (isDesktop) {
    return (
      <HeadcountLayoutDesktop
        activity={activity}
        backgroundColor={isPartner ? team?.action_page_background_color : null}
        chooseFlow={selectedFlow === null}
        team={team}
      >
        {renderFlow()}
      </HeadcountLayoutDesktop>
    );
  }

  return (
    <HeadcountLayoutMobile backgroundColor={isPartner ? team?.action_page_background_color : null}>
      {renderFlow()}
    </HeadcountLayoutMobile>
  );
}

Headcount.propTypes = {
  activity: PropTypes.object.isRequired,
  team: PropTypes.object,
};

Headcount.defaultProps = {
  team: null,
};

export default Headcount;
