import { RightOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { replaceDefaultCampaignImage, truncate } from '../../utils/string';
import { ArrowWrapper, Cause, Causes, Row, StyledListItem, StyledMeta } from './common';

const Activity = ({ id, campaign, description, title }) => {
  const { t } = useTranslation();
  const {
    name: campaignName,
    causes: campaignCauses,
    slug: campaignSlug,
    url_profile_img: campaignLogo,
  } = campaign;
  const titleText = `${campaignName} - ${title}`;

  // Add Activity as hardcoded cause
  const causes = [{ id: 0, name: t('common.activity') }, ...(campaignCauses || [])];

  return (
    <StyledListItem>
      <Row as={Link} to={`/campaigns/${campaignSlug}/activities/${id}`}>
        <div className="flex-row align-items-center">
          <StyledMeta
            avatar={
              <Avatar size="large" src={replaceDefaultCampaignImage(campaignLogo)}>
                {campaignName}
              </Avatar>
            }
            title={<span>{titleText}</span>}
            description={truncate(description, 75)}
          />
        </div>
        <ArrowWrapper>
          <RightOutlined />
        </ArrowWrapper>
      </Row>
      <Causes as={Row}>
        {causes.map(cause => (
          <Cause key={`ca-${cause.id}`}>{cause.name}</Cause>
        ))}
      </Causes>
    </StyledListItem>
  );
};
Activity.propTypes = {
  campaign: PropTypes.shape({
    causes: PropTypes.array,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    url_profile_img: PropTypes.string,
  }).isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default Activity;
