import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DynamicGrid } from './DynamicGrid';
import ImpactiveButton from '@web/components/ImpactiveButton';
import ImpactiveLinkButton from '@web/components/ImpactiveLinkButton';

import theme from '@web/styles/theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 80px;

  background-color: ${theme.colors.white};
`;

const ButtonContainer = styled.div`
  padding: 0 15%;
`;

const PlanButtons = ({ plans, onPlanSelect }) => {
  return (
    <Container>
      <DynamicGrid columnCount={plans.length}>
        <div />
        {plans
          .filter(plan => plan.slug !== 'enterprise')
          .map((plan, index) => (
            <ButtonContainer>
              <ImpactiveButton
                teal={index !== plans.length - 1}
                size="full-width"
                style={{ color: '#23293e' }}
                onClick={() => onPlanSelect(plan.slug)}
              >
                Choose {plan.name}
              </ImpactiveButton>
            </ButtonContainer>
          ))}
        <ButtonContainer>
          <ImpactiveLinkButton
            secondary
            size="full-width"
            style={{ color: '#23293e' }}
            href="https://www.impactive.io/schedule-a-demo"
          >
            Learn More
          </ImpactiveLinkButton>
        </ButtonContainer>
      </DynamicGrid>
    </Container>
  );
};

PlanButtons.propTypes = {
  onPlanSelect: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PlanButtons;
