import * as React from 'react';
import axios from 'axios';

import { useSelector } from 'react-redux';
import useVoterRegistrationApi from '@web/components/Headcount/hooks/useVoterRegistrationApi';

function useFetchVoterRecord(activity) {
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const contactId = contactFormResult?.id;
  const encodeId = contactFormResult?.encode_id;
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { handleError } = useVoterRegistrationApi(activity);
  const [voterRecord, setVoterRecord] = React.useState(null);

  React.useEffect(() => {
    if (activity && contactId && encodeId) {
      axios
        .post(`/api/v2/client/voter_registrations/${contactId}/my_record`, {
          activity_id: activity?.id,
          campaign_id: activity?.campaign?.id,
          h: encodeId,
        })
        .then(({ data }) => {
          setVoterRecord(data);
        })
        .catch(err => {
          const errorMessage = handleError(err);
          setError(errorMessage);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  return { error, loading, voterRecord };
}

export default useFetchVoterRecord;
