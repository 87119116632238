import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInstructionItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 22px 0;
  max-width: 650px;
`;

const IconSection = styled.div`
  margin-bottom: 10px;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 24px;
  line-height: 34px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

function InstructionItem({ children, icon, title }) {
  return (
    <StyledInstructionItem>
      <IconSection>{icon}</IconSection>
      <Title>{title}</Title>
      <Description>{children}</Description>
    </StyledInstructionItem>
  );
}

InstructionItem.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
};

InstructionItem.defaultProps = {
  children: null,
};

export default InstructionItem;
