import * as React from 'react';
import axios from 'axios';

import { message } from 'antd';
import Pusher from 'pusher-js';
import sendError, { addBreadcrumb } from '@web/utils/sendError';
import { setWaitTime } from '@web/reducers/dialerReducer';

import { useDispatch, useSelector } from 'react-redux';
import { machineTransition } from '@web/reducers/dialerReducer';
import {
  QUEUE_CALL_ERROR,
  QUEUE_NEXT_CALL,
  VOLUNTEER_NOT_CONNECTED,
} from '@web/reducers/dialerReducer/dialerMachine';

function useQueueCall(activityId) {
  const pusherRef = React.useRef(null);
  const dispatch = useDispatch();
  const { callData } = useSelector(state => state.dialer);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    pusherRef.current = new Pusher(process.env.PUSHER_APP_KEY, {
      cluster: process.env.PUSHER_APP_CLUSTER,
    });
  }, []);

  // Only queue the call if the volunteer is indeed connected
  function queueCallIfConnected() {
    // first make sure that we are connected to Pusher
    const pusherState = pusherRef?.current?.connection?.state;
    if (pusherState !== 'connected') {
      addBreadcrumb({
        category: 'Dialer',
        message: `Prevented queueCall due to Pusher state: ${pusherState}`,
      });
      return message.error(
        'There is an issue connecting to the server. Please wait a few moments and try again.',
      );
    }

    setLoading(true);
    const url = `/api/v2/activities/${activityId}/volunteer_conference`;
    axios(url)
      .then(({ data }) => {
        const volunteerConnected = data?.volunteer_voice_call?.state === 'in_progress';
        if (volunteerConnected) {
          return queueCall();
        } else {
          setLoading(false);
          return dispatch(machineTransition(VOLUNTEER_NOT_CONNECTED));
        }
      })
      .catch(err => {
        setLoading(false);
        if (err?.response?.status === 404) {
          return dispatch(machineTransition(VOLUNTEER_NOT_CONNECTED));
        } else {
          sendError('Dialer Web - Unable to check volunteer connection status', err);
          message.error('Unable to retrieve connection status at this time. Please try again.');
        }
      });
  }

  async function queueCall() {
    // MTS - We still want to queue the call whether or not the authorize request
    // is successful.
    await authorize();
    const url = `/api/v2/volunteer_voice_calls/${callData?.call_id}/voter_voice_call`;
    axios
      .post(url, {
        activity_id: activityId,
        volunteer_voice_call_id: callData?.call_id,
      })
      .then(res => {
        const waitTime = parseInt(res.data.volunteer_average_calls_interval_seconds, 10);
        dispatch(setWaitTime(waitTime));
        dispatch(machineTransition(QUEUE_NEXT_CALL));
      })
      .catch(err => {
        // Go back to idle state
        dispatch(machineTransition(QUEUE_CALL_ERROR));
        message.error(
          'The system is experiencing a high volume at the moment. Please try again in a few minutes',
        );
        sendError('Dialer - Error readyForContact', {
          request: err?.request,
          response: err?.response,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function authorize() {
    const url = `/api/v2/activities/${activityId}/voice_call_pool/authorizations`;
    return axios
      .post(url)
      .then(() => {
        // Don't do anything here
      })
      .catch(err =>
        sendError('Dialer - error authorizing', {
          request: err?.request,
          response: err?.response,
        }),
      );
  }

  return { loading, queueCall, queueCallIfConnected, setLoading };
}

export default useQueueCall;
