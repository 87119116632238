import { Button, Input, List } from 'antd';
import debounce from 'lodash/debounce';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import { useDocumentTitle } from 'web/hooks/useDocumentTitle';
import { toObject, usePaginatedApi } from 'web/hooks/usePaginatedApi';
import { track } from 'web/services/analytics';
import { users } from 'web/services/api';
import { AuthContext } from 'web/utils/context';

import { colors, fonts } from '../../styles/theme';
import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import SyncContactsModal from '../ContactSync/components/SyncContactsModal';
import Loading from '../common/Loading';
import MaxWidthContainer from '../common/MaxWidthContainer';
import Title from '../common/Title';
import ContactListItem from './ContactListItem';
import Filters from './Filters';

const ContactsContainer = styled.div`
  padding-left: 24px;
`;

const SearchLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledContacts = styled.div`
  min-height: calc(100vh - 63px);
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-top: 11px;
  margin-bottom: 0px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.borderGray};
`;

const StyledTitle = styled(Title)`
  display: inline;
`;

const Description = styled.div`
  color: ${colors.black};
  font-size: 14px;
`;

const HeaderAndSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const ResyncContacts = styled(Button)`
  font-family: ${fonts.blackItalic};
`;

const NotSyncedMessage = styled.div`
  margin: 20px 0px;
  font-size: 16px;
`;

const ItemContent = styled.div`
  ${({ key }) => key % 2 === 0 && `background-color: ${colors.lightGray}`}
  border-radius: 10px;
`;

const StyledButton = styled(Button)`
  &&& {
    font-family: ${fonts.semibold};
    border-radius: 12.5px;
  }
`;

const Contacts = () => {
  const { t } = useTranslation();
  const { token } = useContext(AuthContext);
  const [foundContacts, setFoundContacts] = React.useState(false);
  const [contactsQuery, setContactsQuery] = React.useState(null);
  const [contactsScrollRef, setContactsScrollRef] = React.useState(null);
  const [shouldLoadContacts, setShouldLoadContacts] = React.useState(true);
  const [voterFrequency, setVoterFrequency] = React.useState(undefined);
  const [unmatched, setUnmatched] = React.useState(undefined);

  const [syncContactsModalVisible, setSyncContactsModalVisible] = React.useState(false);

  const title = t('titles.contacts');
  useDocumentTitle(title);

  // Track the page view
  useEffect(() => {
    track('VIEW_CONTACTS_SCREEN');
  }, []);

  const contactsPaginator = toObject(
    usePaginatedApi(users.getContacts, null, {
      search: contactsQuery,
      unmatched: unmatched || undefined,
      voter_frequency: voterFrequency,
    }),
  );

  // This prevents a flash of the sync button when searching
  React.useEffect(() => {
    if (!foundContacts && contactsPaginator.items.length > 0) {
      setFoundContacts(true);
    }
    // eslint-disable-next-line
  }, [contactsPaginator.items]);

  // Reset pagination on query change, voterFrequency change or unmatched filter change
  React.useEffect(() => {
    contactsPaginator.resetPagination();
    setShouldLoadContacts(true);
    // eslint-disable-next-line
  }, [contactsQuery, voterFrequency, unmatched]);

  // Load more contacts if the flag is set
  React.useEffect(() => {
    if (shouldLoadContacts) {
      contactsPaginator.loadMore();
      setShouldLoadContacts(false);
    }
    // eslint-disable-next-line
  }, [shouldLoadContacts]);

  // Debounced contact search - delay querying backend while user is still typing
  const debouncedHandleSearch = debounce(e => {
    const { value } = e.target;
    setContactsQuery(value);
  }, 200);

  const handleSearch = e => {
    e.persist();
    debouncedHandleSearch(e);
  };

  // This will update the search parameters to toggle a filter on or off
  const handleFrequencyChange = frequency => {
    // allow toggling filter off if the same button is pressed again
    const toFrequency = frequency !== voterFrequency ? frequency : undefined;
    setVoterFrequency(toFrequency);
  };

  const userNotSynced = !contactsPaginator.loading && !foundContacts;

  return (
    <>
      <AppHeader title={title} />
      <AppContainer>
        <MaxWidthContainer>
          <ContactsContainer>
            <HeaderAndSearch>
              <div>
                <div>
                  <StyledTitle>{t('contacts.title')}</StyledTitle>
                  {!userNotSynced && (
                    <ResyncContacts type="link" onClick={() => setSyncContactsModalVisible(true)}>
                      {t('contacts.resync')}
                    </ResyncContacts>
                  )}
                </div>
                <Description>{t('contacts.description')}</Description>
              </div>

              {!userNotSynced && (
                <SearchContainer>
                  <SearchLeft>
                    <Input.Search
                      placeholder={t('contacts.search_placeholder')}
                      onChange={handleSearch}
                    />
                  </SearchLeft>
                </SearchContainer>
              )}
            </HeaderAndSearch>

            {/* Render Sync button if a user hasn't synced yet */}
            {userNotSynced && (
              <>
                <NotSyncedMessage>{t('contact.sync.what')}</NotSyncedMessage>
                <StyledButton type="primary" onClick={() => setSyncContactsModalVisible(true)}>
                  {t('contacts.sync')}
                </StyledButton>
              </>
            )}

            {/* User has synced contacts */}
            {!userNotSynced && (
              <StyledContacts>
                <Filters
                  handleFrequencyChange={handleFrequencyChange}
                  setUnmatched={setUnmatched}
                  unmatched={unmatched}
                  voterFrequency={voterFrequency}
                />

                <div ref={contactsScrollRef}>
                  {(token && contactsPaginator.initialLoading) ||
                    (contactsPaginator.items && contactsPaginator.items.length > 0 && (
                      <InfiniteScroll
                        initialLoad={false}
                        pageStart={0}
                        threshold={0}
                        loadMore={contactsPaginator.loadMore}
                        hasMore={!contactsPaginator.loading && contactsPaginator.hasMore}
                        useWindow
                        getScrollParent={() => setContactsScrollRef}
                      >
                        <List>
                          {contactsPaginator.items.map((contact, ix) => (
                            <ItemContent key={ix}>
                              <ContactListItem key={contact.id} contact={contact} hideCheckbox />
                            </ItemContent>
                          ))}
                        </List>
                        {contactsPaginator.loading && <Loading centered />}
                      </InfiniteScroll>
                    ))}
                </div>
              </StyledContacts>
            )}
          </ContactsContainer>
          {syncContactsModalVisible && (
            <SyncContactsModal
              syncContactsModalVisible={syncContactsModalVisible}
              setSyncContactsModalVisible={setSyncContactsModalVisible}
            />
          )}
        </MaxWidthContainer>
      </AppContainer>
    </>
  );
};

export default Contacts;
