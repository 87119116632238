import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Autocomplete from 'react-google-autocomplete';
import { ErrorText, InputWrapper, Label, inputStyle } from './sharedStyles';

import { connect, getIn } from 'formik';
import parseAddress from '@web/utils/parseAddress';

const StyledAutocomplete = styled(Autocomplete)`
  ${inputStyle}
`;

function AddressInput({ formik, label, name, onChange, placeholder, ...rest }) {
  // MTS - Hardcoding 'address' in getIn because going through all of the locations where we'd
  // need to change address to searchAddress would be extremely error prone and not worth the
  // risk.
  // Reference: https://github.com/outvote/web-app/issues/8580#issuecomment-1184488355
  const error = getIn(formik.errors, 'address');
  const touched = getIn(formik.touched, 'address');
  const hasError = touched && error;

  const selectPlace = place => {
    const { street_name, street_number, ...parsedAddress } = parseAddress(place);
    onChange(parsedAddress);
  };

  const handleValueChange = event => {
    if (event.target.value === '') {
      onChange({
        address: '',
        city: '',
        county_name: '',
        state_abbrev: '',
        zip_code: '',
      });
    }
  };

  const options = {
    componentRestrictions: { country: 'us' },
    types: ['address'],
  };

  return (
    <InputWrapper>
      <Label htmlFor={name}>{label}</Label>
      <StyledAutocomplete
        autoComplete={'off'}
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
        defaultValue={''}
        $hasError={hasError}
        id={name}
        name={name}
        onChange={handleValueChange}
        onPlaceSelected={selectPlace}
        options={options}
        placeholder={placeholder ? placeholder : label}
        {...rest}
      />
      {hasError && <ErrorText>{error}</ErrorText>}
    </InputWrapper>
  );
}

AddressInput.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

AddressInput.defaultProps = {
  placeholder: null,
};

export default connect(AddressInput);
