import React from 'react';

import * as yup from 'yup';
import { HomeFilled, MailFilled, PhoneFilled } from '@ant-design/icons';
import i18n from '@web/components/i18n';
import Icon from '@web/components/common/Icon';
import dayjs from 'dayjs';

const { t } = i18n;

const PHONE_REGEX = /^(\1)?[0-9]{11}$/;
const ZIP_REGEX = /^\d{5}$/;

export const availableQuestions = [
  'first_name',
  'last_name',
  'email',
  'zip_code',
  'phone',
  'date_of_birth',
  'address',
];

export const getFont = font => (font === 'default' ? 'unset' : font);

export const getInitialSurveyValues = (
  formAttributes,
  activityQuestions,
  isOptInRequired,
  autofillForm,
) => {
  // Base form questions from the availableQuestions array above
  const questionEntries = availableQuestions
    .filter(question => !!formAttributes?.[question])
    // prefill data from local storage if available
    .map(q => formatContactFields(q, autofillForm))
    .concat([['opt_in', isOptInRequired]]);

  // Additional `question_answers` field for activity questions
  const questionAnswersField = activityQuestions.map(question => {
    return {
      activity_question_id: question.id,
      value: '',
    };
  });

  return Object.fromEntries([...questionEntries, ['question_answers', questionAnswersField]]);
};

const formatContactFields = (question, autofillForm) => {
  const contact = autofillForm ? JSON.parse(localStorage.getItem('vr_and_form_contact')) : {};

  if (contact?.[question]) {
    if (question === 'phone') {
      return [question, contact?.[question].replace(/\D/g, '')];
    }

    return [question, contact?.[question]];
  }

  return [question, question === 'date_of_birth' ? null : ''];
};

export const getValidationSchema = (formAttributes, activityQuestions) => {
  const questionEntries = availableQuestions
    .filter(question => !!formAttributes?.[question])
    .map(question => [question, requiredFieldsProps?.[question].validation]);

  // Not all activity questions are required, so we need to dynamically build the validation schema for each question
  const questionAnswersValidation = yup.array().of(
    yup.lazy(value => {
      const question = activityQuestions.find(
        question => question.id === value?.activity_question_id,
      );

      // We shouldn't be running into this, but if we do it most likely means there
      // is a gap in the question positions that should be sequential.
      if (!question) {
        throw new Error('Question not found.');
      }

      return yup.object().shape({
        activity_question_id: yup.number().required(),
        value: question.required
          ? yup.string().required('⚠ This is a required question!')
          : yup.string(),
      });
    }),
  );

  return yup
    .object()
    .shape(
      Object.fromEntries([...questionEntries, ['question_answers', questionAnswersValidation]]),
    );
};

export const requiredFieldsProps = {
  address: {
    label: t('idvoters.labels.address'),
    placeholder: t('idvoters.placeholders.address1'),
    prefix: <HomeFilled />,
    validation: yup.string().nullable().required('Address is required.'),
  },
  date_of_birth: {
    label: t('idvoters.labels.date_of_birth'),
    placeholder: t('idvoters.labels.date_of_birth'),
    prefix: <HomeFilled />,
    validation: yup
      .date()
      .typeError(i18n.t('idvoters.validation.valid_dob'))
      .nullable()
      .required('Date of Birth is required.')
      .min(dayjs().subtract(120, 'year'), i18n.t('idvoters.validation.valid_dob'))
      .max(dayjs().subtract(15, 'year'), i18n.t('idvoters.validation.max_date', { age: 16 })),
  },
  email: {
    label: t('placeholders.email'),
    placeholder: t('placeholders.default_email'),
    prefix: <MailFilled />,
    validation: yup
      .string()
      .email(t('check_registration.invalid_email'))
      .nullable()
      .required(t('idvoters.validation.emailRequired')),
  },
  first_name: {
    label: t('placeholders.first_name'),
    placeholder: t('idvoters.labels.firstname'),
    prefix: <Icon name="UserFilled" />,
    validation: yup.string().nullable().required(t('idvoters.validation.firstname')),
  },
  last_name: {
    label: t('placeholders.last_name'),
    placeholder: t('placeholders.last_name'),
    prefix: <Icon name="UserFilled" />,
    validation: yup.string().nullable().required(t('idvoters.validation.lastname')),
  },
  phone: {
    label: t('placeholders.phone_number'),
    placeholder: '(555) 345-6789',
    prefix: <PhoneFilled />,
    validation: yup
      .string()
      .matches(PHONE_REGEX, 'Phone number is not valid.')
      .required(t('idvoters.validation.phoneRequired')),
  },
  unit_number: {
    label: t('idvoters.labels.address2'),
    placeholder: t('idvoters.placeholders.address2'),
    prefix: <HomeFilled />,
    validation: yup.string().nullable(),
  },
  zip_code: {
    label: t('idvoters.labels.zip'),
    placeholder: t('idvoters.labels.zip'),
    prefix: <HomeFilled />,
    validation: yup
      .string()
      .matches(ZIP_REGEX, 'Zip code is not valid.')
      .nullable()
      .required('Zip code is required.'),
  },
};
