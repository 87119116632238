import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import headcountTheme from '@web/components/Headcount/theme';
import StyledSideHeroImage from '../SideHeroImage';
import useIsPartner from '../../hooks/useIsPartner';

import { replaceDefaultCampaignImage } from '@web/utils/string';

const StyledLayout = styled.div`
  align-items: center;
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.bgGray};
  display: flex;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
`;

const LeftSide = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
`;

const RightSideCentered = styled.div`
  align-items: center;
  display: flex;
  width: 50%;
`;

const RightSideTop = styled.div`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacing.wider};
  padding-top: ${({ theme }) => theme.spacing.wider};
  width: 50%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
`;

function HeadcountLayoutDesktop({
  activity,
  backgroundColor,
  children,
  chooseFlow,
  team,
  ...rest
}) {
  const isPartner = useIsPartner(team);

  // Admin can add multiple incentives to the activity, but as a short-term solution we use first incentive until find better solution
  const activityIncentive = React.useMemo(
    () =>
      activity?.activity_incentives_attributes?.length > 0
        ? activity?.activity_incentives_attributes[0]
        : null,
    [activity],
  );

  return (
    <ThemeProvider theme={headcountTheme}>
      <meta name="theme-color" content={backgroundColor || headcountTheme.colors.bgGray} />
      <StyledLayout backgroundColor={backgroundColor} {...rest}>
        <Wrapper>
          <LeftSide>
            {!isPartner && (
              <>
                <StyledSideHeroImage
                  alt={activity?.title}
                  maxWidth="720px"
                  margin
                  src={activity?.hero_img_url}
                />

                <StyledSideHeroImage
                  alt={activityIncentive?.name}
                  maxWidth="500px"
                  rounded
                  src={replaceDefaultCampaignImage(activityIncentive?.image_url)}
                />
              </>
            )}

            {isPartner && (
              <>
                <StyledSideHeroImage
                  alt={team?.name}
                  boxShadow
                  margin
                  maxWidth="500px"
                  rounded
                  src={team?.hero_img_url}
                />
                <StyledSideHeroImage
                  alt={team?.name}
                  maxWidth="500px"
                  rounded
                  src={team?.incentives_img_url}
                />
              </>
            )}
          </LeftSide>
          {chooseFlow && (
            <RightSideCentered>
              <Container>{children}</Container>
            </RightSideCentered>
          )}
          {!chooseFlow && (
            <RightSideTop>
              <Container>{children}</Container>
            </RightSideTop>
          )}
        </Wrapper>
      </StyledLayout>
    </ThemeProvider>
  );
}

HeadcountLayoutDesktop.propTypes = {
  activity: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
  children: PropTypes.any.isRequired,
  chooseFlow: PropTypes.bool.isRequired,
  team: PropTypes.object,
};

HeadcountLayoutDesktop.defaultProps = {};

export default HeadcountLayoutDesktop;
