import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useMedia, useModal } from '@web/hooks';

import { SyncOutlined, DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import {
  LeftOutlined,
  EllipsisOutlined,
  UserSwitchOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import { getFullName, getThreadTypeName } from './utils';
import ImpactiveButton from '@web/components/ImpactiveButton';
import theme, { sizes } from '@web/styles/theme';
import ButtonWithDropdown from '@web/components/ButtonWithDropdown';
import { useTranslation } from 'react-i18next';
import ImpactiveConfirmModal from '@web/components/common/ImpactiveConfirmModal';

const MessageHeader = styled.div`
  border-bottom: 1px solid ${theme.colors.borderGray};
  display: flex;
  flex-direction: row;
  box-shadow: -20px 16px 16px 0px rgb(255 255 255 / 93%);
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarName = styled.div`
  font-size: 1.125rem;
  margin-left: 10px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;
  @media (max-width: 415px) {
    white-space: normal;
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const InfoButtonContainer = styled.div`
  background: ${({ showRightSideBar }) => (showRightSideBar ? '#F8F8F8' : 'none')};
  cursor: pointer;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  &:hover {
    background: rgba(233, 234, 236);
  }
`;

const StyledButton = styled(Button)`
  &&& {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    color: ${({ theme, $archive }) => ($archive ? theme.colors.blue : theme.colors.black)};
    padding-right: 0;
    // #23293E
    margin-right: ${({ $archive }) => ($archive ? '10px' : 0)};
    font-size: 0.875rem;
    font-weight: 600;
    font-style: italic;
    &:hover {
      color: ${({ theme, $archive }) => ($archive ? theme.colors.cyan : theme.colors.black)};
    }
  }
`;

const ThreadType = styled.span`
  font-size: 1.125rem;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.blackL32};
  margin-right: 15px;
`;

const MessageThreadHeader = ({
  firstName,
  lastName,
  refreshing,
  handleRefresh,
  reassignToAdmin,
  reassignAllToAdmin,
  showRightSideBar,
  toggleSidebar,
  setMessageThreadParams,
  showFillReportButton,
  archiveThread,
  archived,
  teamName,
  kind,
  currentActivityId,
  currentThreadId,
}) => {
  const small = useMedia(`(max-width: ${sizes.lg})`);
  const { t } = useTranslation();

  const reassignableActivityTypes = ['peer_to_peer'];
  const isArchived = !!archived;
  const showReassignDropdown = reassignableActivityTypes.includes(kind);

  const [openReassignModal, closeReassignModal] = useModal(ImpactiveConfirmModal);

  const reassignmentMenuItems = [
    {
      icon: <UserSwitchOutlined />,
      onClick: () =>
        openReassignModal({
          cancelText: 'No, Cancel',
          confirmText: 'Yes, Reassign',
          onClose: closeReassignModal,
          onSubmit: () => {
            reassignToAdmin(currentThreadId);
            closeReassignModal();
          },
          text: (
            <>
              Are you sure you want to <strong>reassign</strong> this conversation{' '}
              <strong>to an admin?</strong> It will disappear from your inbox.
            </>
          ),
          title: 'Reassign Conversation to Admin',
        }),
      text: t('button.reassign'),
    },
    {
      icon: <UserSwitchOutlined />,
      onClick: () =>
        openReassignModal({
          cancelText: 'No, Cancel',
          confirmText: 'Yes, Reassign',
          onClose: closeReassignModal,
          onSubmit: () => {
            reassignAllToAdmin(currentActivityId);
            closeReassignModal();
          },
          text: (
            <>
              Are you sure you want to <strong>reassign</strong> all conversations from this
              activity <strong>to an admin?</strong> They will disappear from your inbox.
            </>
          ),
          title: 'Reassign All Conversations to Admin',
        }),
      text: t('button.reassign_all'),
    },
    {
      icon: <FolderOutlined />,
      onClick: () => archiveThread(),
      text: 'Archive message',
    },
  ];

  return (
    <MessageHeader>
      <AvatarContainer>
        {small && (
          <StyledButton type="link" onClick={() => setMessageThreadParams(null, null, null, null)}>
            <LeftOutlined />
          </StyledButton>
        )}

        <AvatarName>
          {teamName || getFullName(firstName, lastName)} •
          <ThreadType> {getThreadTypeName(kind, t)}</ThreadType>
        </AvatarName>
        {showFillReportButton && (
          <ImpactiveButton
            block
            size="medium"
            children={t('inbox.thread.outreach_report')}
            onClick={() => toggleSidebar(!showRightSideBar)}
          />
        )}
        <Button
          style={{ marginLeft: showFillReportButton ? 15 : 0 }}
          type="link"
          icon={<SyncOutlined />}
          disabled={refreshing}
          onClick={handleRefresh}
          aria-label="Refresh thread"
        />
      </AvatarContainer>
      <HeaderButtons>
        {!isArchived && !showReassignDropdown && (
          <StyledButton $archive disabled={refreshing} onClick={archiveThread}>
            {t('inbox.archive_message')}
          </StyledButton>
        )}
        {!isArchived && showReassignDropdown && (
          <ButtonWithDropdown
            secondary
            placement="bottomRight"
            size="large-square"
            icon={<EllipsisOutlined />}
            menuItems={reassignmentMenuItems}
          />
        )}
        {!teamName && (
          <InfoButtonContainer
            showRightSideBar={showRightSideBar}
            onClick={() => toggleSidebar(!showRightSideBar)}
          >
            {showRightSideBar ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          </InfoButtonContainer>
        )}
      </HeaderButtons>
    </MessageHeader>
  );
};

MessageThreadHeader.propTypes = {
  archived: PropTypes.bool,
  archiveThread: PropTypes.func.isRequired,
  currentActivityId: PropTypes.number.isRequired,
  currentThreadId: PropTypes.number.isRequired,
  firstName: PropTypes.string,
  handleRefresh: PropTypes.func,
  handleRefreshAll: PropTypes.func,
  kind: PropTypes.string,
  lastName: PropTypes.string,
  reassignAllToAdmin: PropTypes.func,
  reassignToAdmin: PropTypes.func,
  refreshing: PropTypes.bool,
  setMessageThreadParams: PropTypes.func,
  showFillReportButton: PropTypes.bool,
  showRightSideBar: PropTypes.bool,
  teamName: PropTypes.string,
  toggleSidebar: PropTypes.func,
};

export default MessageThreadHeader;
