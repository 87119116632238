import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import i18n, { setAppLanguage } from '../i18n';

const useSpecificTranslation = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const defaultLanguage = useMemo(() => {
    const defaultValue = query.get('language');
    if (!['en', 'es'].includes(defaultValue)) {
      return 'en';
    }
    return defaultValue;
  }, [query]);

  useEffect(() => {
    if (i18n.language !== defaultLanguage) {
      const url = new URL(window.location.href);
      url.searchParams.set('language', defaultLanguage);
      history.replace(`${pathname}?${url.searchParams.toString()}`);
      setAppLanguage(defaultLanguage, true);
    }
  }, [defaultLanguage, history, pathname]);
};

export default useSpecificTranslation;
