import { CheckOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SyncSuccess = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 15px;
`;

const NiceWork = styled.div`
  margin-bottom: 15px;
`;

const SyncToFrontline = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  &&& {
    font-family: ${({ theme }) => theme.fonts.semibolditalic};
  }
`;

function ImportSuccess({ count }) {
  const { t } = useTranslation();
  return (
    <Container>
      <SyncSuccess>
        <CheckOutlined style={{ marginRight: 5 }} /> {t('contact.sync.synced_message', { count })}
      </SyncSuccess>
      <NiceWork>{t('contact.sync.nice_work')}</NiceWork>
      <SyncToFrontline>
        <Link to="/frontline">
          <StyledButton type="primary" size="large">
            {t('contact.sync.to_frontline')}
          </StyledButton>
        </Link>
      </SyncToFrontline>
    </Container>
  );
}

ImportSuccess.propTypes = {
  count: PropTypes.number.isRequired,
};

ImportSuccess.defaultProps = {};

export default ImportSuccess;
