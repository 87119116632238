import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { users as usersAPI } from '@web/services/api';
import { useWindowVisibility } from '@web/hooks';

const NEEDS_ATTENTION_THREADS_COUNT_POLLING_INTERVAL = 120000;

// Hook to get needs attention messages count
function useNeedsAttentionMessagesCount() {
  const windowVisible = useWindowVisibility();
  const { selectedCampaign } = useSelector(state => state.oneCampaign);
  const [needsAttentionMessagesCount, setNeedsAttentionMessagesCount] = useState();

  // Get needs attention messages count from a server
  const getNeedsAttentionMessagesCount = React.useCallback(async campaignId => {
    return await usersAPI
      .getNeedsAttentionMessagesCount(campaignId)
      .then(({ data }) => {
        return data?.data?.needs_attention_message_threads_count;
      })
      .catch(() => {
        return 0;
      });
  }, []);

  // Poll for needs attention messages
  useEffect(() => {
    async function pollForChanges() {
      if (windowVisible) {
        const count = await getNeedsAttentionMessagesCount(selectedCampaign?.id);
        setNeedsAttentionMessagesCount(count);
      }
    }

    // Run it initially
    pollForChanges();

    // Set timer
    const timerFn = setInterval(pollForChanges, NEEDS_ATTENTION_THREADS_COUNT_POLLING_INTERVAL);

    // Set cleanup fn
    return () => clearInterval(timerFn);
  }, [
    getNeedsAttentionMessagesCount,
    setNeedsAttentionMessagesCount,
    windowVisible,
    selectedCampaign?.id,
  ]);

  return needsAttentionMessagesCount;
}

export default useNeedsAttentionMessagesCount;
