import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import VoterMatchCore from './VoterMatchCore';

const VoterMatch = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const {
    contactId,
    voter: voterParams,
    voterbaseId,
    isFriend,
    userActivityId,
    activityId,
    campaignId,
  } = state || {};

  useDocumentTitle(t('titles.voter_file'));

  const history = useHistory();
  const vfirst =
    voterParams && voterParams['vb.tsmart_first_name']
      ? voterParams && voterParams['vb.tsmart_first_name']
      : '';
  const vsecond =
    voterParams && voterParams['vb.tsmart_last_name']
      ? voterParams && voterParams['vb.tsmart_last_name']
      : '';

  return (
    <div>
      <AppHeader title={t('titles.voter_file')} />
      <AppContainer>
        <VoterMatchCore
          contactId={contactId}
          voterParams={voterParams}
          voterbaseId={voterbaseId}
          isFriend={isFriend}
          userActivityId={userActivityId}
          activityId={activityId}
          campaignId={campaignId}
          onBadMatch={() => {
            history.push({
              pathname: '/voters',
              state: {
                contact_id: contactId,
                firstName: vfirst,
                lastName: vsecond,
              },
            });
          }}
          onSaveMatchFinally={() => null}
          redirectIfNoVoterInfo
        />
      </AppContainer>
    </div>
  );
};

VoterMatch.propTypes = {};

export default VoterMatch;
