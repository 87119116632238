import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '@web/styles/theme';

const StyledPageLayout = styled.div`
  min-height: 100vh;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  min-height: 100vh;
  max-width: 742px;
  padding: 40px 16px 20px;
  // MTS - this will preserve the scrollbar so we do
  // not get weird content shifts in the tabbed view
  ${media.ns} {
    padding: 40px 32px 20px;
  }
`;

function PageLayout({ children, ...rest }) {
  return (
    <StyledPageLayout {...rest}>
      <Inner>{children}</Inner>
    </StyledPageLayout>
  );
}

PageLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

PageLayout.defaultProps = {};

export default PageLayout;
