import * as React from 'react';
import PropTypes from 'prop-types';
import { track } from '@web/services/analytics';
import styled from 'styled-components';

import Button from '@web/components/Headcount/components/Button';
import ListItem from './ListItem';
import Text from '@web/components/Headcount/components/Text';
import Banner from '@web/components/Headcount/components/Banner';
import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';

import icons from './icons';
import { useTranslation } from 'react-i18next';

const ReasonList = styled.ul`
  margin: 0;
  padding: 0;
  margin-bottom: ${({ theme }) => theme.spacing.wide};
`;

const ButtonArea = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.wider};
`;

const LastButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.normal};
`;

function NotRegistered({ backToStart, nextPage, team, activity, showBanner, setShowBanner }) {
  const { t } = useTranslation();
  const isPartner = useIsPartner(team);
  const cannabisRegistrationEnabled = activity?.campaign?.cannabis_registration_enabled;

  let cta_text = t('check_registration.headcount.notRegistered.cta');
  if (isPartner) {
    cta_text = t('check_registration.headcount.notRegistered.partnerCta');
  }
  if (cannabisRegistrationEnabled) {
    cta_text = t('check_registration.headcount.notRegistered.cannabisCta');
  }

  const handleRegisterToVote = React.useCallback(() => {
    setShowBanner(false);
    nextPage();
  }, [nextPage, setShowBanner]);

  track('HEADCOUNT_VIEW_NOT_REGISTERED', {
    activity_id: activity?.id,
    activity_title: activity?.title,
    activity_type: activity?.type,
    campaign_id: activity?.campaign?.id,
    campaign_name: activity?.campaign?.name,
    team_id: team?.id,
    team_name: team?.name,
  });

  return (
    <>
      <Text variant="f2">{t('check_registration.headcount.notRegistered.title')}</Text>
      {isPartner && showBanner && <Banner activity={activity} />}
      <ReasonList>
        <ListItem icon={icons.ballotBox}>
          {t('check_registration.headcount.notRegistered.reason1')}
        </ListItem>
        <ListItem icon={icons.person}>
          {t('check_registration.headcount.notRegistered.reason2')}
        </ListItem>
        <ListItem icon={icons.house}>
          {t('check_registration.headcount.notRegistered.reason3')}
        </ListItem>
        <ListItem icon={icons.stopwatch}>
          {t('check_registration.headcount.notRegistered.reason4')}
        </ListItem>
      </ReasonList>
      <Text variant="f2">{cta_text}</Text>
      <ButtonArea>
        <Button onClick={handleRegisterToVote}>{t('voting_information.register_to_vote')}</Button>
        <LastButton onClick={backToStart} variant="secondary">
          {t('check_registration.headcount.notRegistered.searchAgain')}
        </LastButton>
      </ButtonArea>
    </>
  );
}

NotRegistered.propTypes = {
  activity: PropTypes.object,
  backToStart: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  setShowBanner: PropTypes.func.isRequired,
  showBanner: PropTypes.bool,
  team: PropTypes.object,
};

NotRegistered.defaultProps = {};

export default NotRegistered;
