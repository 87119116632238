import React from 'react';

const Diamond = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path fill="#2A2E47" d="m8 3 5 5-5 5-5-5 5-5Z" />
    </svg>
  );
};

Diamond.propTypes = {};

Diamond.defaultProps = {};

export default Diamond;
