import styled from 'styled-components';

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 16px;
  margin-top: 35px;
`;

export const Description = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
`;
