import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { number, shape, string, bool } from 'prop-types';
import { useHistory, Link } from 'react-router-dom';

import Location from '@web/components/FanOut/components/icons/Location';
import Calendar from '@web/components/FanOut/components/icons/Calendar';
import Entries from '@web/components/FanOut/components/icons/Entries';
import ExitLink from '@web/components/FanOut/components/icons/ExitLink';
import { isBattleGroundState } from '@web/components/FanOut/helpers';

const BasicText = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 15px 20px;
  background: ${({ theme }) => theme.colors.white};
  gap: 5px;
`;

const Underline = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.transparent};
  height: 2px;
`;

const Box = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const RecruitBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

const RecruitDescriptionBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const NameText = styled(BasicText)`
  color: ${({ theme }) => theme.colors.trueBlack};
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: capitalize;
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const DescriptionText = styled(BasicText)`
  color: ${({ theme }) => theme.colors.black};
  margin-right: 1rem;
`;

const BattleGroundText = styled(BasicText)`
  color: #c00;
  font-weight: 600;
`;

const VSBox = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
`;

const VSContent = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  background: ${({ $isActive }) =>
    $isActive ? 'rgba(20, 201, 36, 0.10)' : 'rgba(245,34,45,0.10)'};
`;

const Icon = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
`;

const VSText = styled(BasicText)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`;

const EarnedContent = styled(VSContent)`
  background: rgba(255, 153, 0, 0.2);
`;

const StyledEntries = styled(Entries)`
  width: 24px;
  height: 24px;
`;

const SweepstakeLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  line-height: 1.5rem; /* 150% */
  font-weight: 600;
  text-decoration-line: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  cursor: pointer;

  :hover,
  :focus,
  .active {
    color: ${({ theme }) => theme.colors.black};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.black};
  }
`;

const StyledExitLink = styled(ExitLink)`
  margin-bottom: 2px;
`;

export const flex = [{ flex: 3 }, { flex: 2 }, { flex: 1.5 }, { flex: 1.2 }, { flex: 1.5 }];

const DesktopRecruit = ({ recruit }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    activity_slug: activitySlug,
    campaign_slug: campaignSlug,
    activity_title: linkText,
    points_earned_from_recruit: entries,
    voting_plan_submitted: isVotingPlanSubmitted,
  } = recruit;

  const {
    first_name: firsName,
    last_name: lastName,
    registration_status: status,
    voter_frequency: frequency,
    age_lower_bound: ageLowerBound,
    age_upper_bound: ageUpperBound,
    city,
    state_abbrev: stateAbbrev,
  } = recruit.contact;

  const fullName = useMemo(
    () =>
      [firsName, lastName]
        .filter(item => !!item)
        .join(' ')
        .toLowerCase(),
    [firsName, lastName],
  );
  const location = useMemo(
    () => [city, stateAbbrev].filter(item => !!item).join(', '),
    [city, stateAbbrev],
  );
  const age = useMemo(
    () => [ageLowerBound, ageUpperBound].filter(item => !!item).join(' - '),
    [ageLowerBound, ageUpperBound],
  );
  const isRegistered = useMemo(() => status?.toLowerCase() === 'registered', [status]);
  const isHightFrequency = useMemo(() => frequency > 0.49, [frequency]);

  const navigate = useCallback(() => {
    history.replace(`/campaigns/${campaignSlug}/activities/${activitySlug}`);
  }, [campaignSlug, activitySlug, history]);

  return (
    <>
      <Container>
        <Box style={flex[0]}>
          <RecruitBox>
            {(firsName || lastName) && <NameText>{fullName}</NameText>}
            <RecruitDescriptionBox>
              {location && (
                <IconBox>
                  <Location />
                  <DescriptionText>{location}</DescriptionText>
                </IconBox>
              )}
              {age && (
                <IconBox>
                  <Calendar />
                  <DescriptionText>{age}</DescriptionText>
                </IconBox>
              )}
              {isBattleGroundState(stateAbbrev) && (
                <IconBox>
                  <BattleGroundText>{t('fan_out.my_activity.battleground_state')}</BattleGroundText>
                </IconBox>
              )}
            </RecruitDescriptionBox>
          </RecruitBox>
        </Box>
        <Box style={flex[1]}>
          <VSBox>
            <VSContent $isActive={isRegistered}>
              <Icon>{isRegistered ? '✅' : '❌'}</Icon>
              <VSText>
                {isRegistered
                  ? t('voting_information.registered')
                  : t('voting_information.unregistered')}
              </VSText>
            </VSContent>
            <VSContent $isActive={isHightFrequency}>
              <Icon>{isHightFrequency ? '🌟' : '😴'}</Icon>
              <VSText>
                {isHightFrequency > 0.49
                  ? t('fan_out.friend_look_up.high_frequency')
                  : t('fan_out.friend_look_up.low_frequency')}
              </VSText>
            </VSContent>
          </VSBox>
        </Box>
        <Box style={flex[2]}>
          <VSBox>
            <VSContent $isActive={isVotingPlanSubmitted}>
              <Icon>{isVotingPlanSubmitted ? '✅' : '🚫'}</Icon>
              <VSText>
                {isVotingPlanSubmitted
                  ? t('fan_out.my_activity.complete')
                  : t('fan_out.my_activity.incomplete')}
              </VSText>
            </VSContent>
          </VSBox>
        </Box>
        <Box style={flex[3]}>
          <EarnedContent>
            <StyledEntries fullColor fill={'#121212'} />
            <VSText>{entries || 0}</VSText>
          </EarnedContent>
        </Box>
        <Box style={flex[4]}>
          <SweepstakeLink onClick={navigate} to={{}}>
            <StyledExitLink />
            {linkText}
          </SweepstakeLink>
        </Box>
      </Container>
      <Underline />
    </>
  );
};

DesktopRecruit.propTypes = {
  recruit: shape({
    activity_id: number.isRequired,
    activity_slug: string.isRequired,
    activity_title: string.isRequired,
    campaign_slug: string.isRequired,
    contact: shape({
      age_lower_bound: number,
      age_upper_bound: number,
      city: string,
      first_name: string,
      id: number,
      last_name: string,
      phone: string,
      registration_status: string,
      state_abbrev: string,
      voter_frequency: number,
    }).isRequired,
    id: number.isRequired,
    points_earned_from_recruit: number,
    voting_plan_submitted: bool,
  }).isRequired,
};

DesktopRecruit.defaultProps = {};

export default DesktopRecruit;
