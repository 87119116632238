import * as React from 'react';

function useDescriptionPosition() {
  const [bottom, setBottom] = React.useState(197);
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      getPosition();
      setReady(true);
    }, 500);
    getPosition();
    window.addEventListener('resize', getPosition);
    return () => window.removeEventListener('resize', getPosition);
  }, []);

  function getPosition() {
    const selector = '.description-text';
    const element = document.querySelector(selector);
    const position = element?.getBoundingClientRect();
    if (position?.bottom) {
      setBottom(position.bottom);
    }
  }
  return { bottom, ready };
}

export default useDescriptionPosition;
