import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckboxIcon from './CheckboxIcon';
import { connect, getIn } from 'formik';
import ErrorText from '@web/components/Headcount/components/Input/ErrorText';
import { Field } from 'formik';
import Text from '@web/components/Headcount/components/Text';

const CheckboxWrapper = styled.label`
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-gap: 0 ${({ theme }) => theme.spacing.tight};
  padding-top: 2px;
  margin-bottom: 0;
  margin-top: ${({ theme }) => theme.spacing.tight};
  svg {
    transform: scale(1.2);
  }
`;

const HiddenInput = styled(Field)`
  display: none;
`;

const Content = styled.span`
  transform: translateY(-5px);
`;

function Checkbox({ children, checked, formik, name, onClick, ...rest }) {
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);
  const hasError = Boolean(touched && error);

  return (
    <>
      <CheckboxWrapper htmlFor={name} onClick={onClick} {...rest}>
        <HiddenInput name={name} type="checkbox" />
        <CheckboxIcon checked={checked} hasError={hasError} style={{ cursor: 'pointer' }} />
        <Content>
          <Text as="span" color="textDarkGray" variant="small">
            {children}
          </Text>
        </Content>
      </CheckboxWrapper>
      {hasError && <ErrorText>{error}</ErrorText>}
    </>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  formik: PropTypes.shape({
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {};

export default connect(Checkbox);
