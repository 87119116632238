import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TitleWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  h1,
  p {
    color: #fff;
    text-align: center;
  }
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  clip-path: ${({ isFanOut }) => (isFanOut ? 'none' : 'polygon(0 0, 100% 0, 100% 88%, 0% 100%)')};
  padding: 60px 24px 45px;
`;

const FanOutTitleWrapper = styled.div`
  background: radial-gradient(
      64.5% 64.52% at 97.64% 1.3%,
      rgba(197, 55, 94, 0.2) 0%,
      rgba(197, 55, 94, 0) 100%
    ),
    radial-gradient(
      66.22% 90.3% at 98.11% 98.85%,
      rgba(140, 205, 217, 0.2) 0%,
      rgba(140, 205, 217, 0) 100%
    ),
    radial-gradient(
      64.49% 126.95% at 0% 100%,
      rgba(184, 59, 201, 0.2) 0%,
      rgba(184, 59, 201, 0) 100%
    ),
    radial-gradient(
      86.75% 134.97% at 0% 0%,
      rgba(152, 204, 201, 0.2) 0%,
      rgba(152, 204, 201, 0) 100%
    ),
    radial-gradient(
      53.76% 53.76% at 50% 46.24%,
      rgba(225, 159, 83, 0.2) 0%,
      rgba(225, 159, 83, 0) 100%
    ),
    #fff;

  h1,
  p {
    color: ${({ theme }) => theme.colors.blue};
  }
  // because of original modal paddings
  // faster and easier to do this than to change the modal styles on every screen
  margin: -28px -16px 0;
  width: calc(100% + 32px);

  padding: 15px 16px 20px;
`;

const Spacer = styled.div`
  margin-top: ${({ $isForm }) => ($isForm ? 180 : 140)}px;
`;

function MobileHero({ children, isForm, isFanOut, ...rest }) {
  return isFanOut ? (
    <>
      <FanOutTitleWrapper {...rest}>{children}</FanOutTitleWrapper>
    </>
  ) : (
    <>
      <TitleWrapper {...rest}>{children}</TitleWrapper>
      <Spacer $isForm={isForm} />
    </>
  );
}

MobileHero.propTypes = {
  children: PropTypes.any.isRequired,
  isFanOut: PropTypes.bool,
  isForm: PropTypes.bool,
};

MobileHero.defaultProps = {};

export default MobileHero;
