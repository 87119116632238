import dayjs from 'dayjs/';
import styled from 'styled-components';
import { StyledModal } from '@web/components/common/ImpactiveModal';

const moneyFormatter = new Intl.NumberFormat('en-us', { currency: 'USD', style: 'currency' });

export const ModalWithNoPadding = styled(StyledModal)`
  &&& {
    .ant-modal-content {
      padding: 0;
      .ant-modal-body {
        padding: 0;
      }
    }
  }
`;

export const getLines = plan => {
  const { activityPrices } = plan;
  const currentDate = new Date();
  return [
    {
      subtext: `Next billing date - ${dayjs(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
      ).format('MM/DD/YYYY')}`,
      text: moneyFormatter.format(plan.price),
      title: 'Subscription',
    },
    {
      subtext: 'per SMS segment/MMS message',
      text: `${moneyFormatter.format(activityPrices.p2pSms)} / ${moneyFormatter.format(
        activityPrices.p2pMms,
      )}`,
      title: 'Peer-to-Peer Texting',
    },
    {
      subtext: 'per SMS segment/MMS message',
      text: `${moneyFormatter.format(activityPrices.broadcastSms)} / ${moneyFormatter.format(
        activityPrices.broadcastMms,
      )}`,
      title: 'Broadcast Texting',
    },
    {
      subtext: 'per dial',
      text: moneyFormatter.format(activityPrices.phoneBanking),
      title: 'Phone Banking',
    },
    {
      subtext: 'per unique completion',
      text: moneyFormatter.format(activityPrices.voterRegistration),
      title: 'Forms and Voter Registration Fills',
    },
  ];
};
