import React from 'react';

import { Modal } from 'antd';

import styled from 'styled-components';
import PropTypes from 'prop-types';

export const StyledModal = styled(Modal)`
  &&& {
    top: 50px;
    .ant-modal-content {
      border-radius: 16px;
      padding: 1rem;
      max-height: 80vh;
      display: flex;
      flex-direction: column;
      .ant-modal-body {
        overflow-y: auto;
      }
      > button {
        right: -50px;
        background: ${({ theme }) => theme.colors.white};
        width: 32px;
        height: 32px;
        border-radius: 50%;
        span {
          vertical-align: unset;
          width: unset;
          height: unset;
          line-height: unset;
          font-size: 11px;
          color: ${({ theme }) => theme.colors.black};
        }
      }
    }

    @media screen and (max-width: 870px) {
      .ant-modal-content {
        > button {
          right: 0;
          top: -50px;
        }
      }
    }
  }
`;

const ImpactiveModal = props => {
  return (
    <StyledModal {...props} footer={props.footer}>
      {props.children}
    </StyledModal>
  );
};

ImpactiveModal.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
};

ImpactiveModal.defaultProps = {
  footer: null,
};

export default ImpactiveModal;
