import { replaceDefaultCampaignImage } from '@web/utils/string';
import PropTypes from 'prop-types';
import React from 'react';
import { Activity, Campaign, User } from '../ListItems';

/**
 * Option is a polymorphic component and renders any of types: Campaign, Activity or User passed as an option
 */
const Option = ({ option: { _type: type }, option, index = null }) => {
  if (type === 'users') {
    return <User user={option} index={index} />;
  }

  if (type === 'campaigns') {
    const { causes, name, slogan, slug, url_profile_img } = option;
    return (
      <Campaign
        name={name}
        slogan={slogan}
        slug={slug}
        logo={replaceDefaultCampaignImage(url_profile_img)}
        causes={causes}
      />
    );
  }

  if (type === 'activities') {
    const { campaign, description, id, title } = option;
    return <Activity campaign={campaign} description={description} id={id} title={title} />;
  }

  return null;
};

Option.propTypes = {
  index: PropTypes.number,
  option: PropTypes.shape({
    _type: PropTypes.string.isRequired,
    campaign: PropTypes.shape({}),
    causes: PropTypes.array,
    description: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    slogan: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    url_profile_img: PropTypes.string,
  }).isRequired,
};

Option.defaultProps = {
  index: null,
};

export default Option;
