// Adapted from SO
// https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
// It is needed for pages that have a scroll bar within this nested router
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}

ScrollToTop.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ScrollToTop);
