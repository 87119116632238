import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

const HeroImage = styled.img`
  object-fit: scale-down;
  border-radius: 16px;
  max-width: 740px;
  max-height: 740px;
  width: 100%;
  overflow: hidden;

  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.colors.blue};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 4px 4px 0px 0px ${({ theme }) => theme.colors.blue};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 4rem; /* 114.286% */
`;

const HeroImages = ({ activity }) => {
  return (
    <Wrapper>
      {activity.hero_img_url ? (
        <HeroImage alt={activity.title} src={activity.hero_img_url} />
      ) : (
        <Title>{activity.title}</Title>
      )}
    </Wrapper>
  );
};

HeroImages.propTypes = {
  activity: PropTypes.object.isRequired,
  team: PropTypes.object,
};

HeroImages.defaultProps = {};

export default HeroImages;
