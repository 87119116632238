import { PhoneOutlined, MailOutlined, LoadingOutlined } from '@ant-design/icons';
import { Checkbox, message, Row, Col, Spin } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import i18n from 'web/components/i18n';
import states from 'web/data/states';
import * as yup from 'yup';
import { track, trackPageViewWithDynamicIDs } from '../../services/analytics';
import { users, campaigns, teams } from '../../services/api';
import ImpactiveButton from '../ImpactiveButton';
import VoterRegistrationTemplate from '../templates/VoterRegistrationTemplate';
import {
  SplashWrapper,
  SplashTitle,
  SplashDescription,
  BigRoundButton,
  CannotVoteLink,
  FormContainer,
  ButtonsRow,
  TermsAndConditions,
} from './styles';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ContactFormSchema = yup.object().shape({
  email: yup.string().email(i18n.t('check_registration.invalid_email')),
  firstName: yup.string(),
  lastName: yup.string(),
  phone: yup.string().matches(phoneRegExp, i18n.t('check_registration.invalid_phone')),
  stateAbbv: yup.string().required(i18n.t('idvoters.validation.state')),
  zipCode: yup.number(i18n.t('idvoters.validation.zip')),
});

const formInitialValues = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  stateAbbv: '',
  zipCode: '',
};

const ScreenTypes = {
  PLEDGE_TO_VOTE: 'pledge_to_vote',
  SIGNUP_TO_HELP: 'signup_to_help',
  SPLASH: 'splash',
};

const PledgeToVote = props => {
  const { campaignId } = props.match.params; // can be either campaign `id` or `slug`
  const { contact_id, contact_referral_id, h, referral_hash, postmates_enabled } =
    queryString.parse(props.location.search);
  const { t } = useTranslation();
  const history = useHistory();

  const [campaign, setCampaign] = useState({});
  const [team, setTeam] = useState();
  const [loading, setLoading] = useState(true);
  const [optIn, setOptIn] = useState(true);
  const formRef = useRef(null);
  const [screenType, setScreenType] = useState(ScreenTypes.SPLASH);
  const isSignupToHelpScreen = screenType === ScreenTypes.SIGNUP_TO_HELP;
  const user_referral_id = team ? team.id : undefined;

  useEffect(() => {
    track('VIEW_READY_TO_VOTE');
    setLoading(true);
    campaigns
      .getPublicCampaign({ id: campaignId })
      .then(({ data: { data: campaign } }) => {
        setCampaign(campaign);

        trackPageViewWithDynamicIDs({
          fbPixelId: campaign.fb_pixel_id,
          gaTrackingId: campaign.ga_tracking_id,
          snapchatPixelId: campaign.snapchat_pixel_id,
        });

        if (referral_hash) {
          return users
            .getUserByReferralHash(referral_hash)
            .then(({ data: { data: user } }) => {
              return teams.getTeam({ filters: { campaign_id: campaign.id }, id: user.id });
            })
            .then(({ data: { data: team } }) => setTeam(team))
            .catch(error => {
              // Catch error and make this promise always resolved,
              // so we can render this view at least
              console.error('get team data >', error);
            });
        }
      })
      .then(() => setLoading(false));
  }, [campaignId, referral_hash]);

  const handleClickReady = useCallback(() => {
    setScreenType(ScreenTypes.PLEDGE_TO_VOTE);
  }, []);

  const handleClickCannotVote = useCallback(() => {
    setScreenType(ScreenTypes.SIGNUP_TO_HELP);
  }, []);

  const goToNextStep = useCallback(
    (contact, state_abbrev) => {
      const query = queryString.stringify({
        can_vote: !isSignupToHelpScreen,
        postmates_enabled,
        referral_hash,
        state_abbrev: state_abbrev,
      });
      const url = `/campaigns/${campaign.slug}/contacts/${contact.id}/state_voting_information?${query}`;
      history.push(encodeURI(url));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [campaign, referral_hash, isSignupToHelpScreen, history],
  );

  const handleContactFormSubmit = useCallback(
    (formData, { setSubmitting }) => {
      const { stateAbbv, email, phone, firstName, lastName } = formData;
      if (!stateAbbv || (!contact_id && !h && !email && !phone)) {
        message.error(t('check_registration.email_phone_required'));
        setSubmitting(false);
        return;
      }
      campaigns
        .submitPledge({
          campaign_id: campaign.id,
          contact_id,
          contact_referral_id,
          email,
          first_name: firstName,
          h,
          last_name: lastName,
          opt_in: optIn,
          phone,
          registration_status: isSignupToHelpScreen ? 'Unable to Vote' : 'Ready to Vote',
          state_abbrev: stateAbbv,
          user_referral_id,
        })
        .then(({ data }) => goToNextStep(data, stateAbbv))
        .catch(error => {
          // TODO: Improve error handling
          console.error(error);
          setSubmitting(false);
          message.error(t('errors.default'));
        });
    },
    [
      campaign,
      user_referral_id,
      contact_id,
      contact_referral_id,
      h,
      optIn,
      isSignupToHelpScreen,
      goToNextStep,
      t,
    ],
  );

  const termsAndConditions = campaign.terms ? (
    <TermsAndConditions>
      <p>{campaign.terms}</p>
      {campaign.terms_link && (
        <a href={campaign.terms_link} target="_blank" rel="noopener noreferrer">
          {t('check_registration.read_more')}
        </a>
      )}
    </TermsAndConditions>
  ) : (
    <TermsAndConditions>
      <div>
        {t('check_registration.by_proceeding')}
        <a href="/outvote/terms_of_service" target="_blank" rel="noopener noreferrer">
          {t('check_registration.tos')}
        </a>
        {'.'}
      </div>
    </TermsAndConditions>
  );

  if (loading) {
    return (
      <div className="flex-row justify-content-center mt-4">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  if (screenType === ScreenTypes.SPLASH) {
    return (
      <VoterRegistrationTemplate
        campaign={campaign}
        team={team}
        postmatesEnabled={campaign.postmates_integration_enabled}
      >
        <SplashWrapper>
          <SplashTitle>{t('pledge_to_vote.splash_title')}</SplashTitle>
          <SplashDescription>
            {t('pledge_to_vote.splash_description', {
              extra: campaign.postmates_integration_enabled
                ? "You'll get 30 days of Unlimited Free Delivery from Postmates when you get 3 friends to do the same."
                : '',
            })}
          </SplashDescription>
          <BigRoundButton onClick={handleClickReady} data-testid="already-registered-button">
            <span
              dangerouslySetInnerHTML={{
                __html: t('pledge_to_vote.splash_ready'),
              }}
            />
          </BigRoundButton>
          <CannotVoteLink onClick={handleClickCannotVote}>
            {t('check_registration.splash.cannot_vote')}
          </CannotVoteLink>
          {termsAndConditions}
        </SplashWrapper>
      </VoterRegistrationTemplate>
    );
  }

  const stepCount = 2;
  const title = t('pledge_to_vote.title');
  const instruction = t('pledge_to_vote.description');

  return (
    <VoterRegistrationTemplate
      campaign={campaign}
      team={team}
      postmatesEnabled={postmates_enabled && campaign.postmates_integration_enabled}
      stepCount={stepCount}
      currentStep={1}
      title={title}
      instruction={instruction}
      data-testid="find-possibilities"
    >
      <FormContainer>
        <Formik
          ref={formRef}
          initialValues={formInitialValues}
          validationSchema={ContactFormSchema}
          onSubmit={handleContactFormSubmit}
          render={({ isSubmitting, isValid, submitCount }) => (
            <Form>
              <Row gutter={30}>
                {!contact_id && (
                  <>
                    <Col span={24} md={12}>
                      <FormItem name="email">
                        <label htmlFor="email">{t('idvoters.labels.email')}</label>
                        <Input
                          name="email"
                          placeholder={t('placeholders.default_email')}
                          id="email"
                          prefix={<MailOutlined />}
                        />
                      </FormItem>
                    </Col>
                    <Col span={24} md={12}>
                      <FormItem name="phone">
                        <label htmlFor="phone">{t('idvoters.labels.phone')}</label>
                        <Input
                          name="phone"
                          placeholder={'(111) 222 - 3333'}
                          id="phone"
                          prefix={<PhoneOutlined />}
                        />
                      </FormItem>
                    </Col>
                  </>
                )}
                <Col span={24} md={12}>
                  <FormItem name="firstName">
                    <label htmlFor="firstName">{t('idvoters.labels.firstname')}</label>
                    <Input
                      name="firstName"
                      placeholder={t('idvoters.labels.firstname')}
                      id="firstName"
                    />
                  </FormItem>
                </Col>
                <Col span={24} md={12}>
                  <FormItem name="lastName">
                    <label htmlFor="lastName">{t('idvoters.labels.lastname')}</label>
                    <Input
                      name="lastName"
                      placeholder={t('idvoters.labels.lastname')}
                      id="lastName"
                    />
                  </FormItem>
                </Col>
                <Col span={24} md={12}>
                  <FormItem name="stateAbbv">
                    <label htmlFor="stateAbbv">{t('idvoters.labels.state')}</label>
                    <Select
                      data-testid="state-abbv"
                      name="stateAbbv"
                      autoComplete={'off'}
                      id="stateAbbv"
                      placeholder={t('idvoters.placeholders.state')}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {states.map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={24} md={12}>
                  <FormItem name="zipCode">
                    <label htmlFor="zipCode">{t('idvoters.labels.zip')}</label>
                    <Input name="zipCode" placeholder={t('idvoters.labels.zip')} id="zipCode" />
                  </FormItem>
                </Col>
              </Row>
              <ButtonsRow>
                <ImpactiveButton
                  type="submit"
                  disabled={isSubmitting || (!isValid && submitCount > 0)}
                >
                  {t('check_registration.submit')}
                </ImpactiveButton>
              </ButtonsRow>
            </Form>
          )}
        />
        <Checkbox
          style={{ marginTop: 10 }}
          disabled
          name="optIn"
          id="optIn"
          checked={optIn}
          onChange={e => {
            setOptIn(e.target.checked);
          }}
        >
          <span
            style={{ color: '#232322' }}
            dangerouslySetInnerHTML={{
              __html: t('check_registration.agreement', {
                campaign: campaign.name,
                privacy_link: campaign.privacy_link,
                terms_link: campaign.terms_link,
              }),
            }}
          ></span>
        </Checkbox>
      </FormContainer>
      {termsAndConditions}
    </VoterRegistrationTemplate>
  );
};

PledgeToVote.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default PledgeToVote;
