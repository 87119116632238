import * as React from 'react';
import PropTypes from 'prop-types';

import Dialing from '../ReceiveCall/pages/Dialing';
import EnterPhoneNumber from '../ReceiveCall/pages/EnterPhoneNumber';
import Header from '@web/components/DialerActivity/components/Header';
import Modal from '../ReceiveCall/components/Modal';
import PageLayout from '@web/components/DialerActivity/components/PageLayout';

import { machineTransition, volunteerReconnected } from '@web/reducers/dialerReducer';
import { RECONNECTED } from '@web/reducers/dialerReducer/dialerMachine';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const pages = {
  ENTER_PHONE_NUMBER: 'ENTER_PHONE_NUMBER',
  RECEIVING_CALL: 'RECEIVING_CALL',
};

function VolunteerDisconnected({ activity }) {
  const [currentPage, setCurrentPage] = React.useState(pages.ENTER_PHONE_NUMBER);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleReconnected() {
    dispatch(volunteerReconnected());
    dispatch(machineTransition(RECONNECTED));
  }

  const phoneNumberBody = t('activity.phone_bank.phone_connection_lost');
  return (
    <PageLayout>
      <Header activity={activity} backLink={`preview-script`} />
      <Modal modalOpen onClose={() => null} progress={null} style={{ maxWidth: 517 }}>
        {currentPage === pages.ENTER_PHONE_NUMBER && (
          <EnterPhoneNumber
            activity={activity}
            body={phoneNumberBody}
            onNext={() => setCurrentPage(pages.RECEIVING_CALL)}
            title={t('common.connection_lost')}
          />
        )}
        {currentPage === pages.RECEIVING_CALL && (
          <Dialing
            activity={activity}
            onBack={() => setCurrentPage(pages.ENTER_PHONE_NUMBER)}
            onNext={handleReconnected}
          />
        )}
      </Modal>
    </PageLayout>
  );
}

VolunteerDisconnected.propTypes = {
  activity: PropTypes.object.isRequired,
};

VolunteerDisconnected.defaultProps = {};

export default VolunteerDisconnected;
