import * as React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components';

import BackButton from '@web/components/VoterRegistration/newComponents/Button/BackButton';
import Button from '@web/components/VoterRegistration/newComponents/Button';
import ErrorText from '@web/components/VoterRegistration/newComponents/Input/ErrorText';
import { Description, Title } from '@web/components/VoterRegistration/newComponents/TextStyles';
import DotLoader from '@web/components/DotLoader';
import VoterItem from './VoterItem';

import {
  checkRegistrationNotRegistered,
  fanOutNotRegistered,
} from '@web/components/VoterRegistration/hooks/useFlows';
import { init } from '@web/reducers/voterRegistration';
import { largeBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import parseVoterObject from '@web/components/VoterRegistration/helpers/parseVoterObject';
import { setSelectedVoter } from '@web/reducers/voterRegistration';
import { track } from '@web/services/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useVoterRegistrationApi from '@web/components/Headcount/hooks/useVoterRegistrationApi';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';
import { vrDefaultColors } from '@web/styles/theme';

const Push = styled.div`
  flex: 1;
  ${largeBreakpoint} {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 1rem;

  ${largeBreakpoint} {
    flex-direction: row;
    justify-content: space-between;
    button {
      max-width: 250px;
    }
  }
`;

const StyledSelectVoter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const VoterList = styled.ul`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  margin: 40px 0 55px;
  padding: 0;
  width: 100%;

  ${largeBreakpoint} {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledBackButton = styled(BackButton)`
  text-transform: ${({ isFanOut }) => (isFanOut ? 'capitalize' : 'none')};
`;

function SelectVoter({ activity, nextPage, previousPage, setSelectedFlow, team, isFanOut }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [error, setError] = React.useState(null);
  const { contactFormResult, isRegistrationRecheck } = useSelector(
    state => state.voterRegistration,
  );
  const { handleError } = useVoterRegistrationApi(activity);
  const [loading, setLoading] = React.useState(true);
  const [voters, setVoters] = React.useState([]);

  React.useEffect(() => {
    fetchVoters();

    if (isFanOut) {
      amplitudeTrack({ activity, name: 'FAN_OUT_VIEW_CHECK_REGISTRATION_SELECT_VOTER', team });
    } else {
      track('VR_VIEW_CHECK_REGISTRATION_SELECT_VOTER', {
        activity_id: activity?.id,
        activity_title: activity?.title,
        activity_type: activity?.type,
        campaign_id: activity?.campaign?.id,
        campaign_name: activity?.campaign?.name,
        team_id: team?.id,
        team_name: team?.name,
      });
    }
    // eslint-disable-next-line
  }, []);

  function fetchVoters() {
    axios
      .post(`/api/v2/client/voter_registrations/${contactFormResult?.id}/possible_voters`, {
        activity_id: isFanOut && isRegistrationRecheck ? undefined : activity.id,
        campaign_id: activity.campaign.id,
        h: contactFormResult.encode_id,
        recheck: (isFanOut && isRegistrationRecheck) || undefined,
      })
      .then(({ data }) => {
        setVoters(data);
        // MTS - If we don't get any results then we switch to the
        // "Not Registered" flow.
        if (data.length === 0) {
          notRegistered();
        }
      })
      .catch(err => {
        const errorMessage = handleError(err);
        setError(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleSelect(voter) {
    setLoading(true);
    axios
      .patch(`/api/v2/client/voter_registrations/${contactFormResult?.id}/set_voter`, {
        activity_id: isFanOut && isRegistrationRecheck ? undefined : activity.id,
        campaign_id: activity.campaign.id,
        h: contactFormResult?.encode_id,
        recheck: (isFanOut && isRegistrationRecheck) || undefined,
        team_id: team?.id,
        voter_id: voter.voterbaseId,
      })
      .then(({ data }) => {
        const newData = { ...voter, ...data };
        dispatch(setSelectedVoter(newData));
        nextPage();
      })
      .catch(err => {
        const errorMessage = handleError(err);
        setError(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleSearchAgain() {
    setLoading(true);
    if (isFanOut) {
      dispatch(setSelectedVoter(null));
    } else {
      dispatch(init());
    }
    previousPage();
  }

  // MTS - This is triggered in two ways:
  // 1. We aren't able to find a voter record at the user's address
  // 2. The clicks the "None of these are me button" on this page.
  function notRegistered() {
    if (isFanOut) {
      setSelectedFlow(fanOutNotRegistered);
      dispatch(setSelectedVoter(null));
    } else {
      setSelectedFlow(checkRegistrationNotRegistered);
    }
  }

  const fontColor = React.useMemo(
    () => (isFanOut ? undefined : activity?.settings?.font_color ?? vrDefaultColors.fontColor),
    [activity?.settings?.font_color, isFanOut],
  );

  const bgColor = React.useMemo(
    () =>
      isFanOut
        ? undefined
        : activity?.settings?.background_color ?? vrDefaultColors.backgroundColor,
    [activity?.settings?.background_color, isFanOut],
  );

  if (loading) {
    return (
      <div style={{ minHeight: 342 }}>
        <DotLoader centered color="black" />
      </div>
    );
  }

  if (error) {
    return <ErrorText>{error}</ErrorText>;
  }

  const buttonLabel = t('check_registration.search_results.none_of_these');

  return (
    <StyledSelectVoter>
      <Title $fontColor={fontColor}>
        {isFanOut
          ? t('check_registration.search_results.title_fanOut')
          : t('check_registration.search_results.title')}
      </Title>
      <Description $fontColor={fontColor}>
        {isFanOut
          ? t('check_registration.search_results.description_fanOut')
          : t('check_registration.search_results.description', { buttonLabel })}
      </Description>
      <VoterList>
        {voters.map((voter, i) => (
          <VoterItem
            fontColor={fontColor}
            bgColor={bgColor}
            key={i}
            onSelect={handleSelect}
            voter={parseVoterObject(voter)}
          />
        ))}
      </VoterList>
      <Push />
      <Row>
        <StyledBackButton
          customVariant={isFanOut ? null : activity.settings}
          isFanOut={isFanOut}
          onClick={handleSearchAgain}
        >
          {t('check_registration.search_results.search_again')}
        </StyledBackButton>
        <Button
          customVariant={isFanOut ? null : activity.settings}
          isFanOut={isFanOut}
          onClick={notRegistered}
          variant={isFanOut ? 'fan_out' : 'blue'}
        >
          {buttonLabel}
        </Button>
      </Row>
    </StyledSelectVoter>
  );
}

SelectVoter.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    settings: PropTypes.shape({
      background_color: PropTypes.string,
      font_color: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  isFanOut: PropTypes.bool,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  setSelectedFlow: PropTypes.func.isRequired,
  team: PropTypes.object,
};

SelectVoter.defaultProps = {
  team: null,
};

export default SelectVoter;
