import { Spin } from 'antd';
import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
`;

function Loading() {
  return (
    <StyledLoading>
      <Spin />
    </StyledLoading>
  );
}

Loading.propTypes = {};

Loading.defaultProps = {};

export default Loading;
