import styled from 'styled-components';

export const CopyWrapper = styled.div`
  margin-bottom: 40px;
  margin-top: 16px;
`;

const Page = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
`;

export const PageCopy = styled.div`
  flex: 1;
`;

export default Page;
