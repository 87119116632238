import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContainer from '../components/AppContainer';
import AppHeader from '../components/AppHeader';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { AuthContext } from '../utils/context';

const NoAccess = ({ pageTitleKey }) => {
  const { t } = useTranslation();
  const title = t(pageTitleKey);
  useDocumentTitle(title);
  return (
    <>
      <AppHeader title={title} />
      <AppContainer>
        <div className="flex-row justify-content-center">
          <div className="default-page-max-width p-3">{t('common.cannot_access_this_page')}</div>
        </div>
      </AppContainer>
    </>
  );
};

NoAccess.propTypes = {
  pageTitleKey: PropTypes.string,
};

const withPermit = (pageTitleKey, hasPermission) => WrappedComponent => {
  return props => {
    const auth = useContext(AuthContext);

    if (!hasPermission(auth)) {
      return <NoAccess pageTitleKey={pageTitleKey} />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withPermit;
