import React from 'react';

import * as yup from 'yup';
import { HomeFilled, MailFilled, PhoneFilled } from '@ant-design/icons';
import i18n from '@web/components/i18n';
import Icon from '@web/components/common/Icon';
import dayjs from 'dayjs';

const { t } = i18n;

const PHONE_REGEX = /^(\1)?[0-9]{11}$/;
const ZIP_REGEX = /^\d{5}$/;

export const handleErrorMessage = err => {
  return err?.response?.data?.message || err?.message || t('common.something_went_wrong');
};

export const availableQuestions = [
  'first_name',
  'last_name',
  'email',
  'zip_code',
  'phone',
  'date_of_birth',
];

export const getInitialFormValues = (formAttributes, formVPAnswers = {}) => {
  // Base form questions from the availableQuestions array above
  const questionEntries = availableQuestions
    .filter(question => !!formAttributes?.[question])
    .map(q => formatContactFields(q, formVPAnswers));

  return Object.fromEntries([...questionEntries]);
};

export const getInitialQuestionValues = (activityQuestions, VPAnswers = []) => {
  // Additional `question_answers` field for activity questions

  const questionAnswersField = activityQuestions.map(question => {
    const answers = Object.entries(question.answer_mappings)
      .map(([key, value]) => ({ ...value, key }))
      .sort((a, b) => a.position - b.position);

    const value = VPAnswers?.find(answer => answer.activity_question_id === question.id)?.value;

    return {
      activity_question_id: question.id,
      possible_answers: answers,
      question_position: question.position,
      value: value || '',
    };
  });

  return Object.fromEntries([['question_answers', questionAnswersField]]);
};

const formatContactFields = (question, formVPAnswers) => {
  if (formVPAnswers?.[question]) {
    if (question === 'phone') {
      return [question, formVPAnswers?.[question].replace(/\D/g, '')];
    }

    return [question, formVPAnswers?.[question]];
  }

  return [question, question === 'date_of_birth' ? null : ''];
};

export const getValidationFormSchema = formAttributes => {
  const questionEntries = availableQuestions
    .filter(question => !!formAttributes?.[question])
    .map(question => [question, requiredFieldsProps?.[question].validation]);

  return yup.object().shape(Object.fromEntries([...questionEntries]));
};

export const getValidationQuestionsSchema = activityQuestions => {
  // Not all activity questions are required, so we need to dynamically build the validation schema for each question
  const questionAnswersValidation = yup.array().of(
    yup.lazy(value => {
      const question = activityQuestions.find(
        question => question.id === value?.activity_question_id,
      );

      // We shouldn't be running into this, but if we do it most likely means there
      // is a gap in the question positions that should be sequential.
      if (!question) {
        throw new Error('Question not found.');
      }

      return yup.object().shape({
        activity_question_id: yup.number().required(),
        value: question.required
          ? yup.string().required('⚠ This is a required question!')
          : yup.string(),
      });
    }),
  );

  return yup.object().shape(Object.fromEntries([['question_answers', questionAnswersValidation]]));
};

export const requiredFieldsProps = {
  date_of_birth: {
    label: t('idvoters.labels.date_of_birth'),
    placeholder: t('idvoters.labels.date_of_birth'),
    prefix: <HomeFilled />,
    validation: yup
      .date()
      .typeError(i18n.t('idvoters.validation.valid_dob'))
      .nullable()
      .required('Date of Birth is required.')
      .min(dayjs().subtract(120, 'year'), i18n.t('idvoters.validation.valid_dob'))
      .max(dayjs().subtract(15, 'year'), i18n.t('idvoters.validation.max_date', { age: 16 })),
  },
  email: {
    label: t('placeholders.email'),
    placeholder: t('placeholders.default_email'),
    prefix: <MailFilled />,
    validation: yup
      .string()
      .email(t('check_registration.invalid_email'))
      .nullable()
      .required(t('idvoters.validation.emailRequired')),
  },
  first_name: {
    label: t('placeholders.first_name'),
    placeholder: t('idvoters.labels.firstname'),
    prefix: <Icon name="UserFilled" />,
    validation: yup.string().nullable().required(t('idvoters.validation.firstname')),
  },
  last_name: {
    label: t('placeholders.last_name'),
    placeholder: t('placeholders.last_name'),
    prefix: <Icon name="UserFilled" />,
    validation: yup.string().nullable().required(t('idvoters.validation.lastname')),
  },
  phone: {
    label: t('placeholders.phone'),
    placeholder: '(555) 345-6789',
    prefix: <PhoneFilled />,
    validation: yup
      .string()
      .matches(PHONE_REGEX, 'Phone number is not valid.')
      .required(t('idvoters.validation.phoneRequired')),
  },
  zip_code: {
    label: t('idvoters.labels.zip'),
    placeholder: t('idvoters.labels.zip'),
    prefix: <HomeFilled />,
    validation: yup
      .string()
      .matches(ZIP_REGEX, 'Zip code is not valid.')
      .nullable()
      .required('Zip code is required.'),
  },
};

export const handleDisabledAnswers = (target, setDisabledAnswers) => {
  const { answerPosition, questionPosition } = target;

  // first index is the question position in the list (0, 1, 2) // questionPosition
  // second index is the answer position under the question [0, 1, 2] // answerPosition

  // for example: {0: [1], 1: [], 2: [0, 1]} disables second answer of the first question, nothing for the second question and first and second answers of the third question

  if (questionPosition === 0 && answerPosition === 0) {
    // picked first answer of the second question
    const alsoPicked10 =
      target.values.question_answers[1].value ===
      target.values.question_answers[1].possible_answers[0].key;
    // picked second answer of the second question
    const alsoPicked11 =
      target.values.question_answers[1].value ===
      target.values.question_answers[1].possible_answers[1].key;

    setDisabledAnswers(disabledAnswers => ({
      ...disabledAnswers,
      // disable first and third answers of the third question if first answer of the second question is picked
      // disable first and second answers of the third question if second answer of the second question is picked
      // disable first answer of the third question if nothing is picked from the second question
      2: alsoPicked10 ? [0, 2] : alsoPicked11 ? [0, 1] : [0],
    }));
  }

  if (questionPosition === 0 && answerPosition === 1) {
    setDisabledAnswers(disabledAnswers => ({
      ...disabledAnswers,
      // disable second and third answers of the third question
      2: [1, 2],
    }));
  }

  if (questionPosition === 1 && answerPosition === 0) {
    // picked first answer of the first question
    const alsoPicked00 =
      target.values.question_answers[0].value ===
      target.values.question_answers[0].possible_answers[0].key;
    setDisabledAnswers(disabledAnswers => ({
      ...disabledAnswers,
      // disable first ans third answer of the third question if first answer of the first question is picked
      // else disable third answer of the third question
      2: alsoPicked00 ? [0, 2] : [2],
    }));
  }

  if (questionPosition === 1 && answerPosition === 1) {
    // picked first answer of the first question
    const alsoPicked00 =
      target.values.question_answers[0].value ===
      target.values.question_answers[0].possible_answers[0].key;
    // picked second answer of the first question
    const alsoPicked01 =
      target.values.question_answers[0].value ===
      target.values.question_answers[0].possible_answers[1].key;

    setDisabledAnswers(disabledAnswers => ({
      ...disabledAnswers,
      // disable first ans second answer of the third question if first answer of the first question is picked
      // disable second and third answers of the third question if second answer of the first question is picked
      // disable second answer of the third question if nothing is picked from the first question
      2: alsoPicked00 ? [0, 1] : alsoPicked01 ? [1, 2] : [1],
    }));
  }

  if (questionPosition === 2 && answerPosition === 0) {
    setDisabledAnswers(disabledAnswers => ({
      ...disabledAnswers,
      // disable first answer of the first question
      0: [0],
      // unable all answers of the second question
      1: [],
    }));
  }

  if (questionPosition === 2 && answerPosition === 1) {
    setDisabledAnswers(disabledAnswers => ({
      ...disabledAnswers,
      // disable second answer of the first question
      0: [1],
      // unable all answers of the second question
      1: [1],
    }));
  }

  if (questionPosition === 2 && answerPosition === 2) {
    setDisabledAnswers(disabledAnswers => ({
      ...disabledAnswers,
      // disable second answer of the first question
      0: [1],
      // disable first answer of the second question
      1: [0],
    }));
  }
};

export const getTableText = () => {
  const data = t('fan_out.voting_plan.results.table', { returnObjects: true });

  const translations = Object.values(data).map(({ democrat, republican, title }) => {
    return {
      democrat: {
        ...democrat,
        examples: Object.values(democrat.examples),
      },
      republican: {
        ...republican,
        examples: Object.values(republican.examples),
      },
      title,
    };
  });
  return translations;
};
