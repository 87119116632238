import { replaceDefaultCampaignImage } from '@web/utils/string';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import logo from '../../images/app-logo-primary.png';
import { auth, campaigns } from '../../services/api';
import Loading from '../common/Loading';
import PasswordlessAuth from './Components/PasswordlessAuth';

const PasswordlessPage = ({ isSignup }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { campaign_slug } = useParams();

  const title = isSignup ? t('titles.sign_up') : t('titles.sign_in');
  useDocumentTitle(title);
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    if (!isSignup) {
      // Only have campaign headers when signing up
      setLoading(false);
      return;
    }

    if (!campaign_slug) {
      setLoading(false);
      return;
    }

    campaigns
      .getPublicCampaign({ id: campaign_slug })
      .then(({ data: { data } }) => {
        setCampaign(data);
        setLoading(false);
      })
      .catch(e => {
        setCampaign(null);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEmailSubmit = email => {
    if (!isSignup) {
      auth.sendMagicLinkEmail(email, 'login').then(() => {
        history.push('/auth/login/passwordless/confirm', { email });
      });
      return;
    }

    if (campaign_slug && campaign) {
      auth.sendMagicLinkEmail(email, campaign_slug, campaign_slug).then(() => {
        history.push(`/auth/signup/${campaign_slug}/passwordless/confirm`, { email });
      });
      return;
    }

    auth.sendMagicLinkEmail(email, 'signup').then(() => {
      history.push('/auth/signup/passwordless/confirm', { email });
    });
    return;
  };

  const onBackClick = () => {
    if (!isSignup) {
      history.push(`/auth/login`);
      return;
    }

    if (campaign_slug && campaign) {
      history.push(`/auth/signup/${campaign_slug}`);
      return;
    }

    history.push(`/auth/signup`);
  };

  const onOppositeClick = () => {
    !isSignup ? history.push(`/auth/signup`) : history.push(`/auth/login`);
  };

  if (loading) {
    return (
      <div className="d-flex flex-row justify-content-center">
        <Loading />
      </div>
    );
  }

  const modalTitle = () => {
    if (!isSignup) {
      return t('auth.sign_in_with_email');
    }

    return t('auth.sign_up_to_join_campaign');
  };

  const isCampaignPage = campaign && campaign.name;
  const showCampaignImg = campaign && replaceDefaultCampaignImage(campaign.url_profile_img);

  return (
    <div className="h-100 bg-auth-cyan d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex flex-row justify-content-center my-4">
        <img src={logo} alt="site-logo" height="27" />
      </div>

      <div className="card fixed-auth">
        {!!showCampaignImg && (
          <div className="d-flex flex-row justify-content-center my-4">
            <img
              style={{ maxHeight: '80px' }}
              src={showCampaignImg}
              className="img-fluid"
              alt={campaign.name}
              height="80"
            />
          </div>
        )}

        {!!isCampaignPage && <div className="pb-3 text-center">{campaign.name}</div>}

        <div className="card-title bolder">{modalTitle()}</div>

        {!isSignup && (
          <div className="pb-4 text-center">{t('auth.auth_modal.one_time_link_full')}</div>
        )}

        {!!isSignup && (
          <div className="pb-4 text-center">
            Enter your email address to receive <br />a one-time link to complete the sign up
            process.
          </div>
        )}

        <PasswordlessAuth onSubmitClick={onEmailSubmit} />
        <div className="line-break w-100 my-4"></div>

        <div className="d-flex flex-row align-items-center justify-content-center text-center">
          <div className="text-underline pointer" onClick={onBackClick}>
            {isSignup ? t('auth.back_to_sign_up') : t('auth.back_to_sign_in')}
          </div>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center mt-3 text-center">
          <div className="pr-2">
            {isSignup ? t('auth.existing_account_question') : t('auth.no_account_question')}
          </div>
          <div className="text-underline pointer" onClick={onOppositeClick}>
            {isSignup ? t('auth.login') : t('auth.sign_up')}
          </div>
        </div>
      </div>
    </div>
  );
};

PasswordlessPage.propTypes = {
  isSignup: PropTypes.bool,
};

PasswordlessPage.propTypes = {};

export default PasswordlessPage;
