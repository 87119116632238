import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { PhoneFilled } from '@ant-design/icons';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import Button from '@web/components/VoterRegistration/newComponents/Button';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import RefreshFormButton from '@web/components/common/RefreshFormIcon';
import Input from '@web/components/VoterRegistration/newComponents/Input';
import OptIns from '@web/components/VoterRegistration/newComponents/ContactForm/OptIns';
import SearchIcon from '@web/components/VoterRegistration/newComponents/icons/Search';

import { largeBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import sendError from '@admin/utils/sendError';
import { setContactFormResult } from '@web/reducers/voterRegistration';
import { track, trackEventViewWithDynamicIDs } from '@web/services/analytics';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useVoterRegistrationApi from '@web/components/VoterRegistration/hooks/useVoterRegistrationApi';
import Status from '@web/constants/contactRegistrationStatusTypes';
import { fanOut } from '@web/services/api';
import PhoneNumberInput from '@web/components/common/shared/PhoneNumberInput';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';
import theme, { vrDefaultColors } from '@web/styles/theme';

const FormWrapper = styled(Form)`
  &&& {
    margin-top: 2rem;
  }
`;

const AgeGroupWrapper = styled.div`
  margin-bottom: 1rem;
`;

const TeensText = styled.div`
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  ${largeBreakpoint} {
    grid-template-columns: 1fr 1fr;
  }
  position: relative;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 60px;

  ${largeBreakpoint} {
    justify-content: space-between;
    flex-direction: row;
    margin-top: 58px;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  margin-right: 10px;
  path {
    fill: ${({ $isDisabled }) => ($isDisabled ? '#b7b7b7' : '#fff')};
  }
`;

const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  gap: 1rem;
  :last-of-type {
    justify-content: flex-end;
  }
  ${largeBreakpoint} {
    flex-direction: row;
    justify-content: flex-start;
    button {
      max-width: 250px;
    }
  }
`;

function ReferralForm({
  activity,
  backButton,
  initialValues,
  nextPage,
  schema,
  secondaryButton,
  submitButtonChildren,
  team,
  isFanOut,
  fanOutRadioButtons,
}) {
  const { search } = useLocation();
  const { start } = queryString.parse(search);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleError, submitContactForm } = useVoterRegistrationApi(activity, team);
  const showOptIns = true;

  const handlePixelTracking = useCallback(() => {
    trackEventViewWithDynamicIDs({
      actionName: isFanOut ? 'fan_out_form_filled' : 'vr_form_filled',
      categoryName: isFanOut ? 'FanOut Form Filled' : 'Voter Registration Form Filled',
      fbPixelId: activity?.settings?.pixel_fb_id,
      gaTrackingId: activity?.settings?.pixel_ga_id,
      gtmPixelId: activity?.settings?.pixel_gtm_id,
      snapchatPixelId: activity?.settings?.pixel_sc_id,
    });
  }, [
    activity?.settings?.pixel_fb_id,
    activity?.settings?.pixel_ga_id,
    activity?.settings?.pixel_gtm_id,
    activity?.settings?.pixel_sc_id,
    isFanOut,
  ]);

  const submitReferralVrFrom = useCallback(
    (values, setSubmitting) => {
      values.auto_open_link = start === '1';
      submitContactForm({
        ...values,
        registrationStatus: isFanOut ? Status.REFERRAL_LINK : undefined,
      })
        .then(({ data }) => {
          // MTS - We add phone and dob here because the backend doesn't send this back for some reason.
          // RH - Looks like we already have this in the response
          dispatch(
            setContactFormResult({
              activity,
              contact: {
                ...data,
                // convert into the right format
                date_of_birth: dayjs(data.date_of_birth).format('MM/DD/YYYY'),
              },
            }),
          );
          handlePixelTracking();
          nextPage();
        })
        .catch(err => {
          const errorMessage = handleError(err);
          sendError(`VR Flow Contact form: ${errorMessage}`, err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [
      start,
      submitContactForm,
      isFanOut,
      dispatch,
      activity,
      handlePixelTracking,
      nextPage,
      handleError,
    ],
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      if (isFanOut) {
        amplitudeTrack({ activity, name: 'FAN_OUT_REFERRAL_FORM_SUBMISSION', team });
      } else {
        track('VR_REFERRAL_FORM_SUBMISSION');
      }
      setSubmitting(true);

      // if we are in the fan out flow, we want to check if combination of phone & email is valid
      if (isFanOut) {
        fanOut
          .validatePhoneAndEmail({
            campaign_id: activity.campaign.id,
            email: values.email,
            phone: values.phone.startsWith('+') ? values.phone : `+${values.phone}`,
          })
          .then(({ data }) => {
            setSubmitting(false);
            handlePixelTracking();
            // if the phone and email are valid, we can submit the form
            submitReferralVrFrom(values, setSubmitting);
          })
          .catch(err => {
            handleError(err);
            setSubmitting(false);
          });
      }
      // if we are not in the fan out flow, we can just submit the form
      else {
        submitReferralVrFrom(values, setSubmitting);
      }
    },
    [activity, handleError, isFanOut, submitReferralVrFrom, team, handlePixelTracking],
  );

  const isDisabled = React.useCallback(
    (errors, touched, values) => {
      const isTouched = !!Object.keys(touched).length;
      const hasErrors = !!Object.keys(errors).length;
      const { email, firstName, lastName, phone } = values;
      const isAllValues =
        !!email && !!firstName && !!lastName && !!phone && (isFanOut ? !!values.age_group : true);

      // RH - if all values are filled from initial values, then isTouched will be false, but we still want to enable the button
      return !!(hasErrors || (isAllValues && !isTouched) ? !isAllValues : !isTouched);
    },
    [isFanOut],
  );

  const refreshForm = React.useCallback(setFieldValue => {
    const fieldsToClear = ['firstName', 'lastName', 'phone', 'email'];
    fieldsToClear.forEach(f => setFieldValue(f, ''));
  }, []);

  const stylesSettings = useMemo(
    () => (isFanOut ? null : activity.settings),
    [activity.settings, isFanOut],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      render={({ errors, isSubmitting, isValid, setFieldValue, touched, values }) => {
        return (
          <FormWrapper>
            {isFanOut && (
              <AgeGroupWrapper>
                <Input
                  label={t('idvoters.labels.age_group')}
                  name="age_group"
                  required
                  radioButtons={fanOutRadioButtons}
                  setFieldValue={setFieldValue}
                />
                {values.age_group === '13-17' && (
                  <TeensText
                    dangerouslySetInnerHTML={{
                      __html: t('check_registration.referral.fanOutTeen'),
                    }}
                  />
                )}
              </AgeGroupWrapper>
            )}
            <Grid>
              <RefreshFormButton
                color={isFanOut ? null : activity.settings.button_color}
                position={{ right: 0, top: '-30px' }}
                clearData={() => refreshForm(setFieldValue)}
              />
              <Input
                autoComplete="given-name"
                icon="person"
                label={t('idvoters.labels.firstname')}
                name="firstName"
                placeholder={t('idvoters.labels.firstname')}
                required
                settings={stylesSettings}
              />
              <Input
                autoComplete="family-name"
                icon="person"
                label={t('idvoters.labels.lastname')}
                name="lastName"
                placeholder={t('idvoters.labels.lastname')}
                required
                settings={stylesSettings}
              />
              <Input
                autoComplete="email"
                icon="email"
                label={t('idvoters.labels.email')}
                name="email"
                placeholder={t('idvoters.labels.emailPlaceholder')}
                type="email"
                required
                settings={stylesSettings}
              />
              <PhoneNumberInput
                name="phone"
                isFanOut
                formik={{ errors, touched, values }}
                label={t('idvoters.labels.phone')}
                placeholder="(555) 345-6789"
                onChange={e => setFieldValue('phone', e)}
                value={values.phone}
                prefixIcon={<PhoneFilled />}
                shouldFocusIcon
                backgroundColor={isFanOut ? theme.colors.white : activity.settings.background_color}
                fontColor={activity.settings.font_color}
                labelFontColor={activity.settings.font_color ?? vrDefaultColors.fontColor}
                outlineColor={activity.settings.field_outline_color}
                focusedColor={activity.settings.field_outline_color}
                isRequired
                labelStyles={{
                  color: isFanOut ? 'black' : activity.settings.font_color,
                  opacity: '0.85',
                  textTransform: 'capitalize',
                  ...(isFanOut && { marginBottom: '0.4rem' }),
                }}
              />
            </Grid>
            {showOptIns && (
              <OptIns
                activity={activity}
                setFieldValue={setFieldValue}
                team={team}
                values={values}
                isFanOut={isFanOut}
              />
            )}
            <ButtonRow>
              <ButtonGroup>{backButton}</ButtonGroup>
              <ButtonGroup>
                {secondaryButton}

                <Button
                  htmlType="submit"
                  isFanOut={isFanOut}
                  loading={isSubmitting}
                  customVariant={stylesSettings}
                  variant={
                    isDisabled(errors, touched, values) ? 'disabled' : isFanOut ? 'fan_out' : 'blue'
                  }
                >
                  <div key="buttonContent">
                    {submitButtonChildren && submitButtonChildren}
                    {!submitButtonChildren && (
                      <>
                        <StyledSearchIcon $isDisabled={isDisabled(errors, touched, values)} />
                        {t('idvoters.search')}
                      </>
                    )}
                  </div>
                </Button>
              </ButtonGroup>
            </ButtonRow>
          </FormWrapper>
        );
      }}
    />
  );
}

ReferralForm.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number,
    }),
    settings: PropTypes.shape({
      background_color: PropTypes.string,
      button_color: PropTypes.string,
      field_outline_color: PropTypes.string,
      font_color: PropTypes.string,
      pixel_fb_id: PropTypes.string,
      pixel_ga_id: PropTypes.string,
      pixel_gtm_id: PropTypes.string,
      pixel_sc_id: PropTypes.string,
    }),
  }).isRequired,
  backButton: PropTypes.any,
  fanOutRadioButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
  initialValues: PropTypes.object.isRequired,
  isFanOut: PropTypes.bool,
  nextPage: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
  secondaryButton: PropTypes.any,
  submitButtonChildren: PropTypes.any,
  team: PropTypes.shape({}),
};

ReferralForm.defaultProps = {
  backButton: null,
  extraButton: null,
};

export default ReferralForm;
