import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Spinner } from '@web/components/common/Loading';

import styled, { css } from 'styled-components';

const variants = {
  gray: {
    bgColor: 'transparent',
    color: 'rgba(35, 41, 62, .5)', // darkblue with opacity
  },
  outline: {
    bgColor: '#F4F4F4',
    color: '#93969F', // gray
  },
  primary: {
    bgColor: 'darkBlue',
    color: 'white',
  },
  secondary: {
    bgColor: 'transparent',
    color: 'red',
  },
  teal: {
    bgColor: 'darkTeal',
    color: 'white',
  },
};

const outline = css`
  background-color: ${({ theme }) => theme.colors.whiteSmoke};
  text-decoration-color: rgba(147, 150, 159, 0);
  &&& {
    color: #93969f;
    border: 1px solid #93969f;
  }
  :hover {
    text-decoration-color: rgba(147, 150, 159, 1);
  }
`;

const gray = css`
  background-color: transparent;
  text-decoration-color: rgba(35, 41, 62, 0);
  &&& {
    color: rgba(35, 41, 62, 0.5);
  }
  :hover {
    text-decoration-color: rgba(35, 41, 62, 0.5);
  }
`;

const primary = css`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  text-decoration-color: rgba(255, 255, 255, 0);
  &&& {
    color: ${({ theme }) => theme.colors.white};
  }
  :hover {
    text-decoration-color: rgba(255, 255, 255, 1);
  }
`;

const secondary = css`
  background-color: transparent;
  &&& {
    color: ${({ theme }) => theme.colors.red};
  }
  text-decoration-color: rgba(228, 72, 82, 0);
  :hover {
    text-decoration-color: rgba(228, 72, 82, 1);
  }
`;

const teal = css`
  background-color: ${({ theme }) => theme.colors.darkTeal};
  text-decoration-color: rgba(255, 255, 255, 0);
  &&& {
    color: ${({ theme }) => theme.colors.white};
  }
  :hover {
    text-decoration-color: rgba(255, 255, 255, 1);
  }
`;

const StyledButton = styled.button`
  align-items: center;
  border: none;
  border-radius: 2px;
  display: flex;
  font-family: ${({ theme }) => theme.fonts.boldItalic};
  font-size: 16px;
  height: 44px;
  justify-content: center;
  letter-spacing: 1.4px;
  padding: 0 16px;
  text-decoration: underline;
  transition: text-decoration-color 150ms;
  white-space: nowrap;
  ${({ $variant }) => $variant} :hover {
    cursor: pointer;
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

function Button(props) {
  const { children, loading, style, to, variant, ...rest } = props;

  function getVariantStyles() {
    switch (props.variant) {
      case 'primary':
        return primary;
      case 'secondary':
        return secondary;
      case 'gray':
        return gray;
      case 'teal':
        return teal;
      case 'outline':
        return outline;
      default:
        return primary;
    }
  }

  const buttonProps = {
    $variant: getVariantStyles(),
    as: to ? Link : 'button',
    // MTS - This prevents a bug where the text decoration is blue
    style: to ? { color: variants[props.variant].color, ...style } : style,
    to: to || undefined,
    ...rest,
  };

  const loadingColor = variants[props.variant].color;
  return (
    <StyledButton {...buttonProps}>
      {loading ? <Spinner color={loadingColor} /> : children}
    </StyledButton>
  );
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  style: PropTypes.object,
  to: PropTypes.string,
  variant: PropTypes.oneOf(['gray', 'outline', 'primary', 'secondary', 'teal']),
};

Button.defaultProps = {
  loading: false,
  style: {},
  to: null,
  variant: 'primary',
};

export default Button;
