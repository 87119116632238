import { get, find } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Autocomplete from 'react-google-autocomplete';

const AddressInput = ({ onChange, ...rest }) => (
  <Autocomplete
    {...rest}
    className="ant-input"
    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
    onPlaceSelected={place => {
      const street_number = find(place.address_components, e => e.types.includes('street_number'));
      const route = find(place.address_components, e => e.types.includes('route'));
      const city = find(place.address_components, e => e.types.includes('locality'));
      const zip_code = find(place.address_components, e => e.types.includes('postal_code'));
      const county_name = find(place.address_components, e =>
        e.types.includes('administrative_area_level_2'),
      );
      const state_abbrev = find(place.address_components, e =>
        e.types.includes('administrative_area_level_1'),
      );
      onChange({
        address:
          street_number || route
            ? `${get(street_number, 'short_name', '')} ${get(route, 'short_name', '')}`
            : undefined,
        city: get(city, 'short_name'),
        county_name: get(county_name, 'short_name'),
        state_abbrev: get(state_abbrev, 'short_name'),
        zip_code: get(zip_code, 'short_name'),
      });
    }}
    options={{
      componentRestrictions: { country: 'us' },
      types: ['address'],
    }}
  />
);

AddressInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default AddressInput;
