import { message } from 'antd';
import { TwitterOutlined } from '@ant-design/icons';
import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import theme from '@web/styles/theme';
import Icon from '@web/components/common/Icon';
import ImpactiveButton from '@web/components/ImpactiveButton';

const buttonStyle = css`
  background: #fff;
  color: ${theme.colors.darkBlue};
  box-shadow: 8px 8px 32px rgba(0, 41, 64, 0.08);
  border-radius: 4px;
  border: none;
  font-weight: 800;

  padding: 15px 30px;
  white-space: nowrap;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  width: 100%;
  flex: 1 1 calc(25% - 14px);

  transition: transform 0.2s ease;

  :hover {
    transform: scale(1.02);
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  gap: 14px;
`;

const StyledFacebookShareButton = styled(FacebookShareButton)`
  ${buttonStyle}
  background: ${({ $backgroundColor }) =>
    $backgroundColor || theme.formDefaultColors.sectionBackgroundColor};
  color: ${({ $fontColor }) => $fontColor ?? theme.formDefaultColors.fontColor};
  border: 1px ${({ $borderColor }) => $borderColor || theme.formDefaultColors.buttonColor} solid;
`;
const StyledTwitterShareButton = styled(TwitterShareButton)`
  ${buttonStyle}
  background: ${({ $backgroundColor }) =>
    $backgroundColor || theme.formDefaultColors.sectionBackgroundColor};
  color: ${({ $fontColor }) => $fontColor ?? theme.formDefaultColors.fontColor};
  border: 1px ${({ $borderColor }) => $borderColor || theme.formDefaultColors.buttonColor} solid;
`;
const StyledLinkedinShareButton = styled(LinkedinShareButton)`
  ${buttonStyle}
  background: ${({ $backgroundColor }) =>
    $backgroundColor || theme.formDefaultColors.sectionBackgroundColor};
  color: ${({ $fontColor }) => $fontColor ?? theme.formDefaultColors.fontColor};
  border: 1px ${({ $borderColor }) => $borderColor || theme.formDefaultColors.buttonColor} solid;
`;

const StyledCopyButton = styled.button`
  ${buttonStyle}
  background: ${({ $backgroundColor }) => $backgroundColor ?? theme.formDefaultColors.buttonColor};
  color: ${({ $fontColor }) => $fontColor ?? theme.formDefaultColors.buttonFontColor};

  svg {
    color: ${({ $fontColor }) => $fontColor ?? theme.formDefaultColors.buttonFontColor} !important;
  }
`;

function ShareButtons({ link, settings }) {
  const { t } = useTranslation();

  const supportsNativeShare = !!navigator.share;
  const showNativeShareButton = isMobileOnly && supportsNativeShare;

  const handleCopy = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        message.success('Link copied successfully!');
      })
      .catch(() => {
        message.error('Error copying link!');
      });
  };

  const handleMobileShare = () => {
    navigator.share({ url: link }).catch(err => {
      if (err.toString().includes('AbortError')) {
        // The share api throws this error when a user decides not to share.
        // It isn't a real error so we just need to ignore it.
        return null;
      }
      throw err;
    });
  };

  const { section_background_color, main_font_color, secondary_color, button_font_color } =
    settings ?? {};

  return showNativeShareButton ? (
    <ImpactiveButton onClick={handleMobileShare} color="coral" size="medium" block>
      <Icon name="Share" style={{ color: 'white' }} /> {t('button.share')}
    </ImpactiveButton>
  ) : (
    <Flex>
      <StyledFacebookShareButton
        $fontColor={main_font_color}
        $backgroundColor={section_background_color}
        $borderColor={secondary_color}
        url={link}
        resetButtonStyle={false}
      >
        <Icon name="Facebook" /> Facebook
      </StyledFacebookShareButton>
      <StyledTwitterShareButton
        $fontColor={main_font_color}
        $backgroundColor={section_background_color}
        $borderColor={secondary_color}
        url={link}
        resetButtonStyle={false}
      >
        <TwitterOutlined /> Twitter
      </StyledTwitterShareButton>
      <StyledLinkedinShareButton
        $fontColor={main_font_color}
        $backgroundColor={section_background_color}
        $borderColor={secondary_color}
        url={link}
        resetButtonStyle={false}
      >
        <Icon name="LinkedIn" /> LinkedIn
      </StyledLinkedinShareButton>
      <StyledCopyButton
        onClick={handleCopy}
        primary
        $fontColor={button_font_color}
        $backgroundColor={secondary_color}
      >
        <Icon name="Copy" /> Copy Link
      </StyledCopyButton>
    </Flex>
  );
}

ShareButtons.propTypes = {
  link: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    button_font_color: PropTypes.string,
    main_font_color: PropTypes.string,
    secondary_color: PropTypes.string,
    section_background_color: PropTypes.string,
  }),
};

export default ShareButtons;
