import styled, { css } from 'styled-components';

import pxToRem from '@admin/utils/pxToRem';

const ActivityWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  padding: 8px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  opacity: ${({ closed }) => (closed ? '0.5' : '1')};
  cursor: pointer;
`;

const ActivityBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const MediaWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ActivityType = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${pxToRem(12)};
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const Media = styled.div`
  ${({ isModal }) =>
    isModal
      ? css`
          width: 100%;
          height: 100%;
          height: 350px;
          margin-bottom: 1rem;
        `
      : css`
          width: 100%;
          height: 100%;
          max-width: 204px;
          max-height: 204px;
        `};

  background-image: url(${({ src }) => src});
  border-radius: 12px;
  background-size: cover;
  background-position: center;
`;

const MediaContentWrapper = styled.div`
  position: relative;
  width: 50%;
  > span {
    position: absolute;
    right: 10px;
    top: 15px;
    background: red;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    color: white;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }
`;

const Body = styled.div`
  ${({ theme, isModal }) => css`
    color: ${theme.colors.blackL32};
    font-family: ${theme.fonts.regular};
    margin: ${isModal ? '2rem 0' : '0 0 3px'};
  `};
  font-size: 14px;
  line-height: 16px;
`;

const Date = styled.div`
  color: ${({ theme }) => theme.colors.blue60};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  padding-left: 2px;
`;
const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blue};
    font-family: ${theme.fonts.semibold};
  `};
  margin-bottom: 6px;
  font-size: 18px;
  line-height: 1.25;
`;

const GeneralActivityData = styled.div`
  padding: 16px 10px 16px 16px;
  width: 50%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export {
  ActivityBody,
  ActivityType,
  ActivityWrapper,
  Body,
  Date,
  GeneralActivityData,
  Media,
  MediaContentWrapper,
  MediaWrapper,
  Title,
};
