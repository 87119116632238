import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Dropdown, Menu } from 'antd';
import IconButton from './IconButton';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

import Facebook from '../../icons/Facebook';
import LinkedIn from '../../icons/LinkedIn';
import Twitter from '../../icons/Twitter';

const StyledMenu = styled(Menu)`
  &&& {
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 1rem;
    width: 130px;
    background: ${({ $bgColor }) => $bgColor};
    color: ${({ $color }) => $color};
  }
`;

const MenuItem = styled.li`
  cursor: pointer;
  display: flex;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 10px;
  width: 16px;
`;

const ItemWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 600;
`;

function SocialShare({ referralLink, bgColor, buttonColor, fontColor }) {
  const menu = (
    <StyledMenu $bgColor={bgColor} $color={fontColor}>
      <MenuItem>
        <FacebookShareButton url={referralLink}>
          <ItemWrapper>
            <IconWrapper>
              <Facebook color={fontColor} />
            </IconWrapper>
            Facebook
          </ItemWrapper>
        </FacebookShareButton>
      </MenuItem>
      <MenuItem>
        <TwitterShareButton url={referralLink}>
          <ItemWrapper>
            <IconWrapper>
              <Twitter color={fontColor} />
            </IconWrapper>
            Twitter
          </ItemWrapper>
        </TwitterShareButton>
      </MenuItem>
      <MenuItem>
        <LinkedinShareButton url={referralLink}>
          <ItemWrapper>
            <IconWrapper>
              <LinkedIn color={fontColor} />
            </IconWrapper>
            LinkedIn
          </ItemWrapper>
        </LinkedinShareButton>
      </MenuItem>
    </StyledMenu>
  );
  return (
    <Dropdown overlay={menu} placement="topRight" trigger={['click']}>
      <IconButton additionalStyles={{ backgroundColor: buttonColor, borderColor: buttonColor }}>
        <svg
          width="19"
          height="17"
          viewBox="0 0 19 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.6836 7.23047L11.4961 12.5391C10.9688 12.9961 10.125 12.6094 10.125 11.9062V8.84766C4.64062 8.91797 2.32031 10.2539 3.90234 15.3516C4.07812 15.9141 3.375 16.3711 2.91797 16.0195C1.37109 14.8945 0 12.75 0 10.6055C0 5.26172 4.46484 4.10156 10.125 4.03125V1.25391C10.125 0.515625 10.9688 0.128906 11.4961 0.585938L17.6836 5.89453C18.0703 6.28125 18.0703 6.87891 17.6836 7.23047Z"
            fill={bgColor}
          />
        </svg>
      </IconButton>
    </Dropdown>
  );
}

SocialShare.propTypes = {
  bgColor: PropTypes.string,
  buttonColor: PropTypes.string,
  fontColor: PropTypes.string,
  referralLink: PropTypes.string.isRequired,
};

SocialShare.defaultProps = {};

export default SocialShare;
