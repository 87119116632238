import React from 'react';

// example of a query arg: (max-width: 600px)
function useMedia(query) {
  if (!query || !typeof window) {
    console.error('must pass a query argument to useMedia');
  }

  const [matches, setMatches] = React.useState(false);

  function handleResize(e) {
    setMatches(window.matchMedia(query).matches);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  return matches;
}

export default useMedia;
