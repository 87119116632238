import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FormField from '@web/components/common/shared/FormField';
import ImpactiveButton, { ImpactiveTextButton } from '@web/components/ImpactiveButton';
import { Formik } from 'formik';
import { Header, PrimaryText } from '../TextComponents';

const ButtonContainer = styled.div`
  margin-top: 48px;

  @media (min-width: 768px) {
    max-width: 200px;
  }
`;

const StyledHeader = styled(Header)`
  ::first-letter {
    text-transform: capitalize;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin: 16px 0;

  > * {
    width: 100%;
  }

  // on mobile display vertically
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const HorizontalFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const TellUsMoreStep = ({ onNext, onBack, values, handleChange, isSubmitting, isValid }) => {
  const { t } = useTranslation();

  const handleClick = e => {
    e.preventDefault();
    onNext();
  };

  return (
    <Wrapper>
      <Flex>
        <div>
          <StyledHeader>{t('check_registration.tellUs.title').toLowerCase()} ✍️</StyledHeader>
          <PrimaryText>{t('auth.youll-be-added-as-the-first-administrator')}</PrimaryText>
        </div>
        <SideBySide>
          <FormField
            name="first_name"
            label="First Name"
            placeholder="John"
            value={values.first_name}
            onChange={handleChange}
            labelProps={{ bold: true, required: true }}
          />
          <FormField
            name="last_name"
            label="Last Name"
            placeholder="Doe"
            value={values.last_name}
            onChange={handleChange}
            labelProps={{ bold: true, required: true }}
          />
        </SideBySide>
        <FormField
          name="phone"
          label="Phone Number"
          placeholder="(555) 345-67-89"
          value={values.phone}
          onChange={handleChange}
          labelProps={{ bold: true, required: true }}
        />
        <HorizontalFlex>
          <ButtonContainer>
            <ImpactiveTextButton htmlType="button" onClick={onBack}>
              ← {t('common.back')}
            </ImpactiveTextButton>
          </ButtonContainer>
          <ButtonContainer>
            <ImpactiveButton
              size="full-width"
              disabled={isSubmitting || !isValid}
              onClick={handleClick}
            >
              {t('common.next')} →
            </ImpactiveButton>
          </ButtonContainer>
        </HorizontalFlex>
      </Flex>
    </Wrapper>
  );
};

TellUsMoreStep.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  ...Formik.propTypes,
};

export default TellUsMoreStep;
