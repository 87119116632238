import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledHeroImage = styled.img`
  border-radius: 12px;
  box-shadow: 0px 0px 24px 4px rgba(160, 160, 160, 0.42);
  margin-bottom: ${({ theme }) => theme.spacing.wide};
  width: 100%;
`;

function HeroImage(props) {
  if (!props.src) {
    return null;
  }

  return <StyledHeroImage {...props} />;
}

HeroImage.propTypes = {
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired,
};

HeroImage.defaultProps = {};

export default HeroImage;
