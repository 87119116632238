import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { useDocumentTitle } from '@web/hooks/useDocumentTitle';
import { toObject, usePaginatedApi } from '@web/hooks/usePaginatedApi';
import { useQuery } from '@web/hooks';
import { getLocalizedOnboardingData } from '@web/utils/other';
import { campaigns, teams as teamsApi } from '@web/services/api';

import AppContainer from '../AppContainer';
import AppHeader from '../AppHeader';
import Loading from '../common/Loading';
import MaxWidthContainer from '../common/MaxWidthContainer';
import Title from '../common/Title';
import TableSection from './TableSection';
import Team from './Team';
import { message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import OneCampaignLoading from '../common/OneCampaignLoading';
import SwitchCampaignAlert from '../common/SwitchCampaignAlert';

const StyledTitle = styled(Title)`
  margin-top: 32px;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TeamsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  gap: 1.5rem;
  margin-top: 2rem;
`;
const PageTitle = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 3rem;
`;

const CampaignLeaderboard = () => {
  const { t } = useTranslation();
  const firstUpdate = useRef(true);
  const firstCampaignChange = useRef(true);
  const dispatch = useDispatch();
  const history = useHistory();

  //if there is a query with campaign id, use it instead of selected campaign from redux
  const query = useQuery();
  const queryCampaignId = query.get('campaignId');

  const title = t('campaign.leaderboard.title');
  useDocumentTitle(title);

  const [campaign, setCampaign] = useState();
  const selectedCampaign = useSelector(state => state.oneCampaign.selectedCampaign);

  //check for 'campaignId' query parameter, and fetch leaderboard for that instead of selected campaign
  useEffect(() => {
    if (!queryCampaignId || selectedCampaign?.id === parseInt(queryCampaignId)) {
      setCampaign(selectedCampaign);
    } else {
      campaigns
        .getCampaign({ id: parseInt(queryCampaignId) })
        .then(({ data: { data } }) => {
          const localizedData = getLocalizedOnboardingData(data);
          setCampaign(localizedData);
        })
        .catch(err => {
          message.error(err?.message ?? 'Failed to fetch this campaign.');
          setCampaign(selectedCampaign);
        });
    }
  }, [queryCampaignId, dispatch, selectedCampaign]);

  // Infinite Scroller parent reference
  const [scrollRef, setScrollRef] = useState(null);

  // Fetch campaign teams paginated
  const leaderboardPaginator = toObject(
    usePaginatedApi(campaigns.getCampaignLeaderboard, campaign?.id ?? selectedCampaign?.id),
  );

  //When  user switches the campaign, we have to remove the query param in order to start fetching the new campaign's leaderboard
  useEffect(() => {
    if (firstCampaignChange.current && !!selectedCampaign) {
      firstCampaignChange.current = false;
      return;
    }

    if (selectedCampaign?.id !== campaign?.id) {
      query.has('campaignId') && query.delete('campaignId');
      history.replace({ search: query.toString() });
    }
  }, [campaign?.id, history, query, selectedCampaign]);

  useEffect(() => {
    // Prevent calling on initial render to reduce number of API calls
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!campaign?.id) return;

    leaderboardPaginator.resetPagination({ refetch: true });

    // Do not change this as it will cause queries to loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign?.id, selectedCampaign]);

  const handlePressJoin = team => {
    const action = teamsApi.joinTeam;

    action(team).then(() => {
      // Get updated teams
      leaderboardPaginator.resetPagination({ refetch: true });
      message.success(t('teams.joined_message', team));
    });
  };

  const { items: teams } = leaderboardPaginator;

  return (
    <>
      <AppHeader title={title} extra={campaign?.name} />
      <AppContainer grayBackground>
        <OneCampaignLoading>
          <MaxWidthContainer>
            <SwitchCampaignAlert campaign={campaign} />
            <PageTitle>{t('menu.leaderboard')}</PageTitle>
            {campaign && teams?.length > 0 && (
              <>
                <StyledTitle>
                  {t('campaign.leaderboard.top_teams')}
                  <small>{t('campaign.leaderboard.last_30_days')}</small>
                </StyledTitle>

                <TeamsSection>
                  {teams.slice(0, 3).map((team, index) => (
                    <Team
                      place={index + 1}
                      joinTeam={handlePressJoin}
                      key={team.id}
                      team={team}
                      campaignId={campaign.id}
                    />
                  ))}
                </TeamsSection>
              </>
            )}
            <StyledTitle>{t('campaign.leaderboard.title')}</StyledTitle>

            <div ref={setScrollRef}>
              <InfiniteScroll
                initialLoad
                pageStart={0}
                threshold={0}
                loadMore={leaderboardPaginator.loadMore}
                hasMore={!leaderboardPaginator.loading && leaderboardPaginator.hasMore}
                useWindow
                getScrollParent={() => scrollRef}
              >
                {campaign && <TableSection teams={teams} campaignId={campaign.id} />}
                {leaderboardPaginator.loading && <Loading centered />}
              </InfiniteScroll>
            </div>
          </MaxWidthContainer>
        </OneCampaignLoading>
      </AppContainer>
    </>
  );
};

CampaignLeaderboard.propTypes = {};

export default CampaignLeaderboard;
