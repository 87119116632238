import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import SuggestionMessage from './SuggestionMessage';

const StyledListTitle = styled.div`
  p:first-of-type {
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.17em;
    line-height: 1.2;
    margin-top: 10px;
  }
`;

const Selected = styled.p`
  color: ${({ theme }) => theme.colors.black};
  margin-top: 16px;
  font-size: 14px;
  opacity: 0.8;
`;

function ListTitle({ contactsType, selectedCount, step, stepDescription }) {
  const { t } = useTranslation();
  const text1 = t('contact.sync.list_title_1', {
    contactsType,
    step,
  });

  const text2 = t('contact.sync.list_title_2', {
    contactsType,
    selectedCount,
  });

  return (
    <StyledListTitle>
      <p>{text1}</p>
      {stepDescription && (
        <SuggestionMessage style={{ margin: '2rem 0' }}>{stepDescription}</SuggestionMessage>
      )}
      {!stepDescription && <Selected>{stepDescription || text2}</Selected>}
    </StyledListTitle>
  );
}

ListTitle.propTypes = {
  contactsType: PropTypes.oneOf(['Google', 'Gmail']).isRequired,
  selectedCount: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  stepDescription: PropTypes.any,
};

ListTitle.defaultProps = {
  stepDescription: null,
};

export default ListTitle;
