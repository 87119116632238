import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ActivityCTA from './ActivityCTA';
import ActivityGoals from './ActivityGoals';
import BoxLogo from './BoxLogo';
import CampaignProfileImage from './CampaignProfileImage';
import MainContainer from './MainContainer';
import { replaceDefaultCampaignImage } from '@web/utils/string';

const StyledActivityCTA = styled(ActivityCTA)`
  margin-bottom: 54px;
`;

const StyledCampaignProfileImage = styled(CampaignProfileImage)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledMainContainer = styled(MainContainer)`
  height: 448px;
  margin-bottom: 130px;
`;

const StyledActivityGoals = styled(ActivityGoals)`
  margin-bottom: 120px;
`;

const PROFILE_IMG_SIZE = 216;

function DesktopHero({ activity, campaign, goals, handleGetReferral, handleGetStarted, referrer }) {
  // The + 3 at the end comes from half of the border size.
  const profileOffset = (PROFILE_IMG_SIZE / 2) * -1 + 3;
  return (
    <>
      <StyledMainContainer backgroundImage={activity.hero_img_url}>
        <BoxLogo />
        <StyledCampaignProfileImage
          alt="campaign-profile"
          size={PROFILE_IMG_SIZE}
          src={replaceDefaultCampaignImage(campaign.url_profile_img)}
          style={{ bottom: profileOffset }}
        />
      </StyledMainContainer>
      <StyledActivityCTA
        activity={activity}
        campaign={campaign}
        handleGetStarted={handleGetStarted}
        handleGetReferral={handleGetReferral}
        referrer={referrer}
      />
      <StyledActivityGoals activity={activity} campaign={campaign} goals={goals} />
    </>
  );
}

DesktopHero.propTypes = {
  activity: PropTypes.shape({
    hero_img_url: PropTypes.string.isRequired,
  }).isRequired,
  campaign: PropTypes.shape({
    url_profile_img: PropTypes.string.isRequired,
  }).isRequired,
  goals: PropTypes.object.isRequired,
  handleGetReferral: PropTypes.func.isRequired,
  handleGetStarted: PropTypes.func.isRequired,
  referrer: PropTypes.object,
};

DesktopHero.defaultProps = {};

export default DesktopHero;
