import * as React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { track } from '@web/services/analytics';

import BackToStart from '@web/components/Headcount/components/BackToStart';
import ShareLink from '@web/components/Headcount/components/ShareLink';
import Text from '@web/components/Headcount/components/Text';
import LinkButton from '@web/components/Headcount/components/LinkButton';
import HeroImage from '@web/components/Headcount/components/HeroImage';

import HeaderAndProgress from '@web/components/Headcount/components/HeaderAndProgress';
import useIsDesktop from '@web/components/Headcount/hooks/useIsDesktop';
import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';
import VotingDatesGA from './components/VotingDatesGA';

const Wrapper = styled.div`
  margin: 1rem 0;
`;

const Buttons = styled.div`
  display: grid;
  gap: 24px;
  margin: 18px 0;
`;

function GeorgiaRunoffPage({ activity, backToStart, team }) {
  const pageRef = React.useRef(null);
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const isDesktop = useIsDesktop();
  const isPartner = useIsPartner(team);

  track('GA_RUNOFF_PAGE_VIEW', {
    activity_id: activity?.id,
    activity_title: activity?.title,
    activity_type: activity?.type,
    campaign_id: activity?.campaign?.id,
    campaign_name: activity?.campaign?.name,
    team_id: team?.id,
    team_name: team?.name,
  });

  // VR pages don't utilize react roter and hence keep the scroll position
  // on page transitions. We want to scroll the page content into view.
  React.useEffect(() => {
    pageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Wrapper ref={pageRef}>
      <HeaderAndProgress activity={activity} progress={1} team={team} />
      <Text variant="f2">Key voting dates for Georgia Runoff:</Text>

      <VotingDatesGA stateAbbreviation={'GA'} team={team} activity={activity} />

      <Buttons>
        <LinkButton href="https://mvp.sos.ga.gov/s/" size="large">
          Find Your Polling Place
        </LinkButton>

        <LinkButton href="https://georgia.gov/vote-absentee-ballot" variant="outlined" size="large">
          Request Absentee Ballot
        </LinkButton>

        <LinkButton href="https://georgia.gov/election-2022" variant="outlined" size="large">
          See Georgia Voting Information
        </LinkButton>
      </Buttons>

      {!isDesktop && isPartner && <HeroImage alt={team?.name} src={team?.incentives_img_url} />}

      <ShareLink activity={activity} referralLink={contactFormResult?.referral_link} team={team} />
      <BackToStart onClick={backToStart} />
    </Wrapper>
  );
}

GeorgiaRunoffPage.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      cannabis_registration_enabled: PropTypes.bool,
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }).isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
  backToStart: PropTypes.func.isRequired,
  team: PropTypes.object,
};

GeorgiaRunoffPage.defaultProps = {};

export default GeorgiaRunoffPage;
