import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Line = styled.div`
  background-color: #cfcfcf;
  bottom: 0px;
  display: flex;
  height: 1px;
  position: absolute;
  width: 100%;
  z-index: 0;
`;

const Tab = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.mediumGray};
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 15px;
  justify-content: center;
  letter-spacing: 1.2px;
  margin-right: 14px;
  min-width: 50px;
  padding: 0px 4px;
  position: relative;
  height: 32px;
  text-transform: uppercase;
  z-index: 1;
  ${({ $active, theme }) =>
    $active &&
    `
    border-bottom: 3px solid ${theme.colors.black};
    color: ${theme.colors.black};
  `};
`;

const TabContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const TabRow = styled.div`
  display: flex;
  position: relative;
`;

function Tabs({ pages }) {
  const [activeIndex, setActiveIndex] = React.useState(0);

  if (!pages || !pages.length) {
    return null;
  }

  return (
    <>
      <TabRow>
        {pages.map((el, i) => (
          <Tab $active={i === activeIndex} key={i} onClick={() => setActiveIndex(i)}>
            {el.title}
          </Tab>
        ))}
        <Line />
      </TabRow>
      <TabContent>{pages[activeIndex]?.content}</TabContent>
    </>
  );
}

Tabs.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.any,
      title: PropTypes.string,
    }),
  ),
};

Tabs.defaultProps = {};

export default Tabs;
