import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, fonts } from '../../styles/theme';
import FilterButtonUnstyled from '../common/FilterButton';

const StyledFilters = styled.div`
  margin: 1em 0 1em 0;
`;

const FilterLabel = styled.div`
  font-family: ${fonts.boldItalic};
  font-size: 10px;
  color: ${colors.black};
  letter-spacing: 1px;
  margin-right: 8px;
  opacity: 0.7;
`;

const FiltersContainer = styled.div``;

const FilterButton = styled(FilterButtonUnstyled)`
  width: 150px;
`;

function Filters({ handleFrequencyChange, setUnmatched, unmatched, voterFrequency }) {
  const { t } = useTranslation();
  return (
    <StyledFilters>
      <FilterLabel>{t('filters.filters')}: </FilterLabel>
      <FiltersContainer>
        <FilterButton
          type={voterFrequency === 'super' ? 'primary' : 'default'}
          shape="round"
          icon="super"
          onClick={() => handleFrequencyChange('super')}
        >
          {t('filters.super_voter')}
        </FilterButton>
        <FilterButton
          type={voterFrequency === 'frequent' ? 'primary' : 'default'}
          shape="round"
          icon="frequent"
          onClick={() => handleFrequencyChange('frequent')}
        >
          {t('filters.frequent')}
        </FilterButton>
        <FilterButton
          type={voterFrequency === 'infrequent' ? 'primary' : 'default'}
          shape="round"
          icon="infrequent"
          onClick={() => handleFrequencyChange('infrequent')}
        >
          {t('filters.infrequent')}
        </FilterButton>
        <FilterButton
          type={unmatched ? 'primary' : 'default'}
          shape="round"
          icon="unmatched"
          onClick={() => setUnmatched(!unmatched)}
        >
          {t('filters.unmatched')}
        </FilterButton>
      </FiltersContainer>
    </StyledFilters>
  );
}

Filters.propTypes = {
  handleFrequencyChange: PropTypes.func.isRequired,
  setUnmatched: PropTypes.func.isRequired,
  unmatched: PropTypes.bool,
  voterFrequency: PropTypes.string,
};

Filters.defaultProps = {};

export default Filters;
