import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { init, setContactFormResult } from '@web/reducers/voterRegistration';
import AboutUsSection from '@web/components/common/AboutUsSection';
import ActivityHero from './ActivityHero';
import IncentivesSection from '@web/components/common/IncentivesSection';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import TeamLeaderboardSection from '@web/components/common/TeamLeaderboardSection';
import VolunteerLeaderboardSection from '@web/components/common/VolunteerLeaderboardSection';
import VoterRegistration from '@web/components/VoterRegistration';

import { activities } from '../../services/api';
import { track, trackPageViewWithDynamicIDs } from '../../services/analytics';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StyledRegistrationActivity = styled.div`
  height: 100%;
  width: 100%;
`;

const RegistrationActivity = ({ activity }) => {
  const { t } = useTranslation();
  const { activitySlug, campaignSlug } = useParams();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { referral_hash, start } = queryString.parse(search);
  const [goalsLoading, setGoalsLoading] = useState(false);
  const [referrerLoading, setReferrerLoading] = useState(false);
  const [goals, setGoals] = useState({});
  const [referrer, setReferrer] = useState(null);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [showVoterModal, setShowVoterModal] = useState(start === '1');

  useEffect(() => {
    // get contact from local storage and prefill forms
    const contact = start !== '1' ? {} : JSON.parse(localStorage.getItem('vr_and_form_contact'));
    dispatch(setContactFormResult({ activity, contact: contact || {} }));
  }, [activity, dispatch, start]);

  useEffect(() => {
    track('VIEW_CHECK_REGISTRATION');
    setGoalsLoading(true);

    activities
      .getActivityGoals({ activitySlug, campaignSlug })
      .then(({ data }) => {
        setGoals(data);
      })
      .finally(() => setGoalsLoading(false));
  }, [activitySlug, campaignSlug]);

  useEffect(() => {
    if (referral_hash) {
      setReferrerLoading(true);

      axios
        .get(
          `/api/v2/client/voter_registrations/campaigns/${campaignSlug}/referrer/${referral_hash}`,
        )
        .then(({ data }) => {
          setReferrer(data);
        })
        .finally(() => setReferrerLoading(false));
    }
  }, [activitySlug, campaignSlug, referral_hash]);

  useEffect(() => {
    trackPageViewWithDynamicIDs({
      fbPixelId: activity?.settings?.pixel_fb_id,
      gaTrackingId: activity?.settings?.pixel_ga_id,
      gtmPixelId: activity?.settings?.pixel_gtm_id,
      snapchatPixelId: activity?.settings?.pixel_sc_id,
    });
  }, [
    activity?.settings?.pixel_fb_id,
    activity?.settings?.pixel_ga_id,
    activity?.settings?.pixel_sc_id,
    activity?.settings?.pixel_gtm_id,
  ]);

  if (goalsLoading || referrerLoading) {
    return (
      <div className="flex-row justify-content-center mt-4">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  function openVoterModal() {
    setShowVoterModal(true);
  }

  function openReferralModal() {
    setShowReferralModal(true);
  }

  function closeModal() {
    setShowReferralModal(false);
    setShowVoterModal(false);
    if (start !== '1') {
      dispatch(init());
    }
  }

  const mediaUrl = activity.public_join_video_url || activity.media_url;
  const mediaContentType = activity.public_join_video_url ? 'video' : 'image';

  return (
    <StyledRegistrationActivity>
      <VoterRegistration
        activity={activity}
        closeModal={closeModal}
        modalVoterOpen={showVoterModal}
        modalReferralOpen={showReferralModal}
        openVoterModal={openVoterModal}
        openReferralModal={openReferralModal}
      />

      <ActivityHero
        activity={activity}
        campaign={activity.campaign}
        goals={goals}
        handleGetStarted={openVoterModal}
        handleGetReferral={openReferralModal}
        referrer={referrer}
      />

      <AboutUsSection
        background
        description={activity.description}
        mediaContentType={mediaContentType}
        mediaUrl={mediaUrl}
        title={t('campaign.activity.about')}
      />

      <IncentivesSection
        incentives={activity.activity_incentives_attributes}
        campaign={activity?.campaign}
      />

      {![784, 3876].includes(activity?.campaign?.id) && (
        <TeamLeaderboardSection activity={activity} />
      )}

      {![784, 3876].includes(activity?.campaign?.id) && (
        <VolunteerLeaderboardSection activity={activity} />
      )}
    </StyledRegistrationActivity>
  );
};

RegistrationActivity.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default RegistrationActivity;
