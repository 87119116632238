import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  :hover {
    text-decoration-color: ${({ theme }) => theme.colors.black};
  }
`;

const Svg = styled.svg`
  path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const Text = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.bold};
  margin-left: 9px;
`;

function BackLink({ backLink, backText }) {
  return (
    <StyledLink to={backLink}>
      <Svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.171875 6.5002L0.532805 6.90332L5.93281 12.9033L6.82813 12.0971L1.78903 6.50027L6.82813 0.903472L5.93281 0.097222L0.532805 6.09722L0.171875 6.5002Z"
          fill="#2D343C"
        />
      </Svg>
      <Text>{backText}</Text>
    </StyledLink>
  );
}

BackLink.propTypes = {
  backLink: PropTypes.string.isRequired,
  backText: PropTypes.any,
};

BackLink.defaultProps = {
  backText: 'Back',
};

export default BackLink;
