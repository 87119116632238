import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { connect, Field, getIn } from 'formik';
import { ErrorText, InputWrapper, Label, inputStyle } from './sharedStyles';

export const StyledInput = styled(Field)`
  ${inputStyle}
`;

function Input({ formik, label, name, placeholder, ...rest }) {
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);
  const hasError = touched && error;
  return (
    <InputWrapper>
      <Label htmlFor={name}>{label}</Label>
      <StyledInput
        $hasError={hasError}
        id={name}
        name={name}
        placeholder={placeholder ? placeholder : label}
        {...rest}
      />
      {hasError && <ErrorText children={error} />}
    </InputWrapper>
  );
}

Input.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

Input.defaultProps = {
  placeholder: null,
};

export default connect(Input);
