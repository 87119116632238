import * as React from 'react';
// import PropTypes from 'prop-types';

function Email(props) {
  return (
    <svg height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.9845 0.8125H1.3595C1.04836 0.8125 0.796997 1.06387 0.796997 1.375V12.625C0.796997 12.9361 1.04836 13.1875 1.3595 13.1875H15.9845C16.2956 13.1875 16.547 12.9361 16.547 12.625V1.375C16.547 1.06387 16.2956 0.8125 15.9845 0.8125ZM14.5642 2.72676L9.01829 7.04219C8.88118 7.14941 8.68958 7.14941 8.55247 7.04219L3.00481 2.72676C2.9839 2.71062 2.96856 2.68834 2.96094 2.66305C2.95333 2.63775 2.95382 2.61071 2.96235 2.58571C2.97087 2.5607 2.98701 2.539 3.0085 2.52363C3.02998 2.50826 3.05574 2.5 3.08215 2.5H14.4868C14.5133 2.5 14.539 2.50826 14.5605 2.52363C14.582 2.539 14.5981 2.5607 14.6066 2.58571C14.6152 2.61071 14.6157 2.63775 14.6081 2.66305C14.6004 2.68834 14.5851 2.71062 14.5642 2.72676V2.72676Z"
        fill="#23293E"
      />
    </svg>
  );
}

Email.propTypes = {};

Email.defaultProps = {};

export default Email;
