import { useEffect } from 'react';

export const useDocumentTitle = title => {
  useEffect(() => {
    const calculatedTitle = title ? `Impactive · ${title}` : `Impactive`;
    document.title = calculatedTitle;
  }, [title]);
};

export default useDocumentTitle;
