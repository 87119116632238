import * as React from 'react';
import PropTypes from 'prop-types';
import { track } from '@web/services/analytics';

import DotLoader from '@web/components/DotLoader';
import BackToStart from '@web/components/Headcount/components/BackToStart';
import ShareLink from '@web/components/Headcount/components/ShareLink';
import Text from '@web/components/Headcount/components/Text';
import VoterBox from '@web/components/Headcount/components/VoterBox';
import VotingDates from '@web/components/Headcount/components/VotingDates';
import HeroImage from '@web/components/Headcount/components/HeroImage';

import useIsDesktop from '@web/components/Headcount/hooks/useIsDesktop';
import useIsPartner from '@web/components/Headcount/hooks/useIsPartner';
import useFetchVoterRecord from '@web/components/Headcount/hooks/useFetchVoterRecord';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { automaticVBMStates } from '../helpers';

function VoteFromAddressResult({ activity, backToStart, team }) {
  const { contactFormResult, selectedVoter } = useSelector(state => state.voterRegistration);
  const { error, loading, voterRecord } = useFetchVoterRecord(activity);
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const isPartner = useIsPartner(team);

  const headcountMtvEnabled = React.useMemo(
    () => activity.campaign.headcount_mtv_enabled,
    [activity.campaign.headcount_mtv_enabled],
  );

  track('HEADCOUNT_VIEW_VOTE_FROM_ADDRESS', {
    activity_id: activity?.id,
    activity_title: activity?.title,
    activity_type: activity?.type,
    campaign_id: activity?.campaign?.id,
    campaign_name: activity?.campaign?.name,
    team_id: team?.id,
    team_name: team?.name,
  });

  const isAutomaticVBMState = React.useMemo(
    () => automaticVBMStates.includes(contactFormResult?.state_abbrev),
    [contactFormResult?.state_abbrev],
  );

  const title = React.useMemo(
    () =>
      t(
        isAutomaticVBMState
          ? 'check_registration.headcount.voteByMail.automaticVBMTitle'
          : 'check_registration.headcount.voteFromAddress.title',
        { state: t(`states.${contactFormResult?.state_abbrev}`) },
      ),
    [contactFormResult?.state_abbrev, isAutomaticVBMState, t],
  );

  if (loading) {
    return <DotLoader centered color="black" />;
  }

  if (error) {
    return (
      <Text color="red" variant="f3">
        {error}
      </Text>
    );
  }

  const stateAbbreviation = selectedVoter?.addressLine2?.split(',')[1].trim();

  const address = {
    line1: selectedVoter?.addressLine1,
    // MTS - The voter database concats the first 2 address lines into
    // addressLine1, therefore the "unit/apt" will always be part of line 1
    line2: null,
    line3: `${selectedVoter?.addressLine2} ${selectedVoter?.zip}`,
  };

  return (
    <>
      {headcountMtvEnabled && (
        <ShareLink
          activity={activity}
          referralLink={contactFormResult?.referral_link}
          team={team}
        />
      )}
      <Text
        variant="f2"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />

      <VoterBox
        address={address}
        label={{ bgColor: 'green', text: t('check_registration.headcount.statusRegistered') }}
        voterName={contactFormResult?.fullname}
      />
      <VotingDates
        stateAbbreviation={stateAbbreviation}
        activity={activity}
        team={team}
        votingInfo={voterRecord?.voting_info}
      />
      {!isDesktop && isPartner && !headcountMtvEnabled && (
        <HeroImage alt={team?.name} src={team?.incentives_img_url} />
      )}
      {!headcountMtvEnabled && (
        <ShareLink
          activity={activity}
          referralLink={contactFormResult?.referral_link}
          team={team}
        />
      )}
      <BackToStart onClick={backToStart} />
    </>
  );
}

VoteFromAddressResult.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      headcount_mtv_enabled: PropTypes.bool,
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }).isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
  backToStart: PropTypes.func.isRequired,
  team: PropTypes.object,
};

VoteFromAddressResult.defaultProps = {};

export default VoteFromAddressResult;
