import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@web/components/VoterRegistration/newComponents/Button';
import Input from '@web/components/VoterRegistration/newComponents/Input';
import { largeBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import sendError from '@admin/utils/sendError';
import { setFriendLookUpResult } from '@web/reducers/voterRegistration';
import useVoterRegistrationApi from '@web/components/VoterRegistration/hooks/useVoterRegistrationApi';
import { ageRanges } from '@web/components/AddReport/utils';
import states from 'web/data/states';
import { users } from '@web/services/api';

const FormWrapper = styled(Form)`
  &&& {
    margin-top: 2rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  ${largeBreakpoint} {
    grid-template-columns: 1fr 1fr;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 60px;

  ${largeBreakpoint} {
    justify-content: space-between;
    flex-direction: row;
    margin-top: 58px;
  }
`;

const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  gap: 1rem;
  :last-of-type {
    justify-content: flex-end;
  }
  ${largeBreakpoint} {
    flex-direction: row;
    justify-content: flex-start;
    button {
      max-width: 200px;
    }
  }
`;

const StyledButton = styled(Button)`
  text-align: center;
  font-size: 1rem;
  font-weight: 700;

  &&& {
    height: 64px;
    background: ${({ $isDisabled, theme }) =>
      $isDisabled ? theme.colors.whiteSmoke : theme.colors.newBlue};
    border-color: ${({ theme, $isDisabled }) =>
      $isDisabled ? theme.colors.blackL72 : theme.colors.blue};
    color: ${({ theme, $isDisabled }) => ($isDisabled ? theme.colors.blackL72 : theme.colors.blue)};
  }
`;

const FriendLookUpFrom = ({ activity, initialValues, nextPage, schema, team }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleError } = useVoterRegistrationApi(activity, team);

  const submitForm = useCallback(
    async (formData, { setSubmitting }) => {
      users
        .searchVoters(formData)
        .then(({ data }) => {
          dispatch(setFriendLookUpResult(data));
          nextPage();
        })
        .catch(err => {
          const errorMessage = handleError(err);
          sendError(`VR Flow Contact form: ${errorMessage}`, err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [dispatch, handleError, nextPage],
  );

  const isDisabled = useCallback((errors, touched) => {
    const isTouched = !!Object.keys(touched).length;
    const hasErrors = !!Object.keys(errors).length;

    return !!(hasErrors || !isTouched);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={schema}
      render={({ errors, isSubmitting, touched }) => (
        <FormWrapper>
          <Grid>
            <Input
              autoComplete="given-name"
              label={t('fan_out.friend_look_up.first_name')}
              name="firstName"
              placeholder={t('fan_out.friend_look_up.first')}
              required
            />
            <Input
              autoComplete="family-name"
              label={t('fan_out.friend_look_up.last_name')}
              name="lastName"
              placeholder={t('fan_out.friend_look_up.last')}
              required
            />
            <Input
              autoComplete="off"
              label={t('fan_out.friend_look_up.age_range')}
              name="ageRange"
              placeholder={t('fan_out.friend_look_up.age_range')}
              defaultValue={'18-255'}
              options={ageRanges(t)}
              required
            />
            <Input
              autoComplete="off"
              label={t('fan_out.friend_look_up.state')}
              name="stateAbbv"
              placeholder={t('fan_out.friend_look_up.select_one')}
              options={states}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              required
            />
          </Grid>
          <ButtonRow>
            <ButtonGroup>
              <StyledButton
                $isDisabled={isDisabled(errors, touched)}
                htmlType="submit"
                isFanOut
                loading={isSubmitting}
                variant="fan_out"
              >
                {t('fan_out.friend_look_up.look_up')}
              </StyledButton>
            </ButtonGroup>
          </ButtonRow>
        </FormWrapper>
      )}
    />
  );
};

FriendLookUpFrom.propTypes = {
  activity: PropTypes.shape({}),
  backButton: PropTypes.any,
  initialValues: PropTypes.object.isRequired,
  isFanOut: PropTypes.bool,
  nextPage: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
  secondaryButton: PropTypes.any,
  submitButtonChildren: PropTypes.any,
  team: PropTypes.shape({}),
};

FriendLookUpFrom.defaultProps = {
  backButton: null,
  extraButton: null,
};

export default FriendLookUpFrom;
