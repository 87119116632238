import { FlagFilled, FolderFilled } from '@ant-design/icons';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { fonts } from '@web/styles/theme';
import UnreadIcon from '../UnreadIcon';
import { FilterTypes } from '../consts';
import { StateContext } from '@web/utils/context';

import { useMedia } from '../../../hooks';
import useNeedsAttentionMessagesCount from '../hooks/needsAttentionMessagesCount';

const Filters = styled(Radio.Group)`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
`;

const StyledRadioButton = styled(Radio.Button)`
  &&& {
    &:not(:first-child) {
      padding-left: 30px;
    }
    &:first-child {
      padding-left: 0px;
    }
    padding-right: 0px;
    border: none;
    color: ${({ theme }) => theme.colors.dimGray};
    font-size: 14px;
    font-weight: 500;
    font-family: ${fonts.regular};
    display: flex;
    &.ant-radio-button-wrapper-checked {
      color: ${({ theme, checked }) => (checked ? theme.colors.darkBlack : theme.colors.dimGray)};
      > span:nth-child(2) {
        border-bottom: ${({ theme, checked }) =>
          `solid ${checked ? theme.colors.darkBlack : 'transparent'}`};
      }
      &:focus-within {
        box-shadow: none;
      }
    }
    :before {
      width: 0;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.black};
    }

    > span {
      display: flex;
      align-items: center;
      white-space: nowrap;
      > :nth-child(1) {
        margin-right: 6px;
      }
    }
  }
`;

const InboxFilters = ({ onFilterChange, filter }) => {
  const { t } = useTranslation();

  const { unreadMessagesCount } = React.useContext(StateContext);
  const needsAttentionMessagesCount = useNeedsAttentionMessagesCount();
  const isMobile = useMedia('(max-width: 470px)');

  const displayCount = data => {
    if (data > 0) {
      return ` (${data})`;
    }

    return null;
  };

  return (
    <Filters
      value={filter}
      size="large"
      onChange={e => {
        onFilterChange(e.target.value);
      }}
    >
      <Buttons>
        <StyledRadioButton
          checked={filter === FilterTypes.ALL}
          value={FilterTypes.ALL}
          tabIndex={0}
          role="radio"
          aria-checked={filter === FilterTypes.ALL}
          aria-label="All"
        >
          {t('inbox.all')}
        </StyledRadioButton>
        <StyledRadioButton
          checked={filter === FilterTypes.UNREAD}
          data-testid="unread-filter"
          buttonStyle="solid"
          value={FilterTypes.UNREAD}
          tabIndex={-1}
          role="radio"
          aria-checked={filter === FilterTypes.UNREAD}
          aria-label="Unread"
        >
          {unreadMessagesCount ? <UnreadIcon /> : null} {t('inbox.unread')}
          {displayCount(unreadMessagesCount)}
        </StyledRadioButton>

        <StyledRadioButton
          checked={filter === FilterTypes.NEEDS_ATTENTION}
          data-testid="needs-attention-filter"
          value={FilterTypes.NEEDS_ATTENTION}
          tabIndex={-1}
          role="radio"
          aria-checked={filter === FilterTypes.NEEDS_ATTENTION}
          aria-label="Need attention"
        >
          <FlagFilled /> {t('inbox.needs_attention')}
          {displayCount(needsAttentionMessagesCount)}
        </StyledRadioButton>
      </Buttons>
      <StyledRadioButton
        checked={filter === FilterTypes.ARCHIVED}
        data-testid="archived-filter"
        value={FilterTypes.ARCHIVED}
        tabIndex={-1}
        role="radio"
        aria-checked={filter === FilterTypes.ARCHIVED}
        aria-label="Archived"
      >
        <FolderFilled /> {!isMobile && t('inbox.archive')}
      </StyledRadioButton>
    </Filters>
  );
};

InboxFilters.propTypes = {
  filter: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
};

export default InboxFilters;
