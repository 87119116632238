import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const StyledJoinButton = styled(Button)`
  &&& {
    display: inline;
    max-width: 387px;
    width: 100%;
    height: 76px;
    background-color: ${({ theme }) => theme.colors.salmon};
    border-radius: 12px;
    border: none;
    margin-left: 0;

    color: ${({ theme }) => theme.colors.white};
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const JoinButton = ({ children, joined, onClick, ...rest }) => {
  return (
    <StyledJoinButton
      data-testid="join-button"
      icon={joined ? <CheckOutlined /> : null}
      onClick={onClick}
      type="primary"
      {...rest}
    >
      {children}
    </StyledJoinButton>
  );
};

JoinButton.propTypes = {
  children: PropTypes.string.isRequired,
  joined: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default JoinButton;
