import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import iconFile from '../../images/my-file-icon-unfocused.png';
import iconSearch from '../../images/search-icon-unfocused.png';
import theme from '../../styles/theme';
import Note from '../Note';

const BoldItalic = styled.span`
  font-family: ${theme.fonts.blackItalic};
`;

const ListEmpty = () => {
  const { t } = useTranslation();
  return (
    <Note title={t('frontline.welcome')}>
      {t('frontline.use_search_tab')} <img src={iconSearch} width="11" height="11" alt="" />{' '}
      {t('frontline.discover_and_join')} <BoldItalic>{t('frontline.allies')}</BoldItalic>
      {t('frontline.campaign_volunteers')} <img src={iconFile} width="11" height="11" alt="" />{' '}
      {t('frontline.tab')}
    </Note>
  );
};

ListEmpty.propTypes = {};

export default ListEmpty;
