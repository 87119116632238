import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import cardBg from '@web/images/blue-card-bg.png';
import actionCardSkeleton from '@web/images/action-card-skeleton.png';
import MaxWidthContainer from '@web/components/common/MaxWidthContainer';

import ImpactiveButton from '@web/components/ImpactiveButton';

import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  padding: 40px 40px 0;
  width: 100%;

  @media (max-width: 600px) {
    padding: 20px 20px 0;
  }
`;

const BlueWrapper = styled.div`
  background-image: url(${cardBg});
  background-size: cover;

  width: 100%;
  padding: 34px 0 40px 0;

  border-radius: 20px;

  margin-bottom: 1rem;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-top: 0;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  margin-bottom: 2.75rem;
`;

const Skeleton = styled.img`
  border-radius: 20px;
  width: 100%;
  height: auto;
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const ButtonLink = styled(Link)`
  :hover {
    text-decoration: none;
  }
`;

const NoCampaign = ({ withSkeletons }) => {
  return (
    <Wrapper>
      <BlueWrapper>
        <MaxWidthContainer>
          <Title>
            Select <br /> Campaign
          </Title>
          <Description>
            You havent yet selected any campaign. <br /> Please join one to start using impactive!
          </Description>
          <ButtonLink to="/explore">
            <ImpactiveButton size="medium">Explore Campaigns</ImpactiveButton>
          </ButtonLink>
        </MaxWidthContainer>
      </BlueWrapper>

      {withSkeletons && (
        <MaxWidthContainer>
          <Grid>
            <Skeleton src={actionCardSkeleton} alt="action placeholder" />
            <Skeleton src={actionCardSkeleton} alt="action placeholder" />
            <Skeleton src={actionCardSkeleton} alt="action placeholder" />
            <Skeleton src={actionCardSkeleton} alt="action placeholder" />
          </Grid>
        </MaxWidthContainer>
      )}
    </Wrapper>
  );
};

NoCampaign.propTypes = {
  withSkeletons: PropTypes.bool,
};

NoCampaign.defaultProps = {
  withSkeletons: false,
};

export default NoCampaign;
