import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';

const Wrapper = styled.div`
  display: flex;
  background-color: #ececec;
  height: 12px;
`;

const ProgressBar = styled(animated.div)`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 12px;
`;

function Progress({ progress }) {
  const width = Math.ceil(progress * 100);
  const isLast = width === 100;

  const style = useSpring({
    borderBottomRightRadius: isLast ? 0 : 6,
    borderTopRightRadius: isLast ? 0 : 6,
    width: `${width}%`,
  });

  return (
    <>
      <Wrapper>
        <ProgressBar style={style} />
      </Wrapper>
    </>
  );
}

Progress.propTypes = {
  progress: PropTypes.number.isRequired,
};

Progress.defaultProps = {};

export default Progress;
