import * as React from 'react';
import { AuthContext } from '@web/utils/context';

function useCanQueueCalls(activity) {
  const { user } = React.useContext(AuthContext);
  const authorizedQueuers = activity?.dial_authorizer_user_ids || [];
  const userCanQueueCalls = authorizedQueuers.includes(user?.id);

  return userCanQueueCalls;
}

export default useCanQueueCalls;
